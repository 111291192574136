<template>
  <div>
    <SearchInput @onSearch="searchAll" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SearchInput from "./search/SearchInput";

export default {
  components: { SearchInput },
  data() {
    return {};
  },
  watch: {},
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    searchAll(inputText) {
      const keyword = {
        keyword: inputText
      };
      this.$router.push({
        name: "board_search",
        params: {
          type: "all",
          query: JSON.stringify(keyword)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
