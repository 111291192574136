<template>
  <div v-if="hide" class="response-wrap" :class="wrapClass">
    <div v-if="emptyMessage && !isLoading" class="empty py-5">
      <v-icon x-large>mdi-robot-outline</v-icon>
      <div class="response-title pl-3 pt-3">질문을 입력하세요.</div>
    </div>
    <div v-if="!emptyMessage" style="height: 100%">
      <div class="response-title pl-3 pt-3">생성된 답변입니다.</div>
      <div
        class="response-body px-3 pb-2"
        v-text="getAnswer"
        ref="responseContainer"
      ></div>
      <v-progress-linear
        :indeterminate="isLoading"
        color="primary"
      ></v-progress-linear>

      <!-- 버튼 -->
      <v-card-actions class="mt-0">
        <v-btn
          v-show="isSubject"
          elevation="0"
          color="primary"
          @click="applySubject"
        >
          {{ $t("mail.586") }}
        </v-btn>
        <v-spacer />
        <v-btn
          elevation="0"
          color="primary"
          :disabled="disableButton"
          @click="copyTextToClipboard"
        >
          <v-icon small class="mr-1">
            mdi-content-copy
          </v-icon>
          복사하기
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("mailCompose", ["subject"]),
    ...mapGetters("ai", [
      "maximize",
      "isLoading",
      "getAnswer",
      "isLoading",
      "getSelected"
    ]),
    isSubject() {
      return this.getSelected?.type === "SUBJECT";
    },
    emptyMessage() {
      return isBlank(this.getAnswer);
    },
    disableButton() {
      return this.isLoading || this.getAnswer.length === 0;
    },
    hide() {
      return !(!this.maximize && this.emptyMessage && !this.isLoading);
    },
    wrapClass() {
      let margin = "mt-3 ml-0";
      let mode = "";
      if (!this.$vuetify.breakpoint.mdAndDown) {
        margin = this.maximize ? "mt-0 ml-3" : "mt-3 ml-0";
        if (this.maximize) {
          mode = "full";
        }
      }

      return margin + " " + mode;
    }
  },
  watch: {
    getAnswer() {
      const container = this.$refs.responseContainer;
      if (container) {
        container.scrollTop = container?.scrollHeight ?? 0;
      }
    }
  },
  methods: {
    ...mapMutations("mailCompose", ["SET_SUBJECT"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    copyTextToClipboard() {
      let text = this.getAnswer;
      text = text.replaceAll("\n", "\n\n");

      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      let message = "복사 되었습니다.";
      let type = "SUCCESS";
      this.openSnackbar({ message, type });
    },
    applySubject() {
      let text = this.getAnswer;
      let subject = this.subject ?? "";

      if (subject.includes(this.$t("mail.580"))) {
        subject = subject.replace(this.$t("mail.580"), text);
      } else {
        subject = text;
      }

      this.SET_SUBJECT({
        subject: subject
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.response-wrap::v-deep {
  border-radius: 4px 4px 0 0;
  background-color: rgba(0, 0, 0, 0.06);
  &:hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
  .response-title {
    font-size: 12px;
    color: var(--v-primary-base);
    user-select: none;
  }
  .response-body {
    user-select: text;
    white-space: pre-line;

    height: calc(100% - 78px);
    overflow: hidden;
    overflow-y: auto;
  }
  .v-card__actions {
    background-color: #fff;
  }
  //
  .empty {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
