var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"400px","max-width":"400px"},on:{"input":_vm.handleDialog}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("calendar.32"))+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"right":"","offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"40px"},attrs:{"block":"","depressed":"","color":"blue-grey lighten-5"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.selectedColor}},[_vm._v(" mdi-circle ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{staticClass:"pa-2",attrs:{"width":"198px"}},[_c('v-chip-group',{staticClass:"calendar-swatches",attrs:{"mandatory":"","column":""},model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}},_vm._l((_vm.swatches),function(color){return _c('v-chip',{key:color,attrs:{"color":color,"value":color,"active-class":"elevation-1","pill":""}})}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{ref:"title",attrs:{"autofocus":"","dense":"","filled":"","rules":[_vm.titleRules.required, _vm.titleRules.counter],"placeholder":_vm.$t('calendar.캘린더_이름'),"maxLength":"80","counter":"80","background-color":"blue-grey lighten-5","append-icon":"mdi-calendar-edit"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addCalendar.apply(null, arguments)}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{ref:"icsFileInput",attrs:{"accept":"text/calendar","prepend-icon":"","append-icon":"mdi-calendar-import","dense":"","filled":"","clearable":"","small-chips":"","full-width":"","show-size":1000,"rules":[
                function (file) { return !file || file.size < 10000000 || _vm.$t('calendar.27'); }
              ],"label":_vm.$t('calendar.25'),"hint":_vm.$t('calendar.26'),"persistent-hint":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var text = ref.text;
return [_c('v-chip',{attrs:{"color":"accent","label":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.icsFile),callback:function ($$v) {_vm.icsFile=$$v},expression:"icsFile"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","outlined":""},on:{"click":function($event){return _vm.handleDialog(false)}}},[_vm._v(" "+_vm._s(_vm.$t("common.취소"))+" ")]),_c('v-btn',{attrs:{"outlined":"","color":"accent"},on:{"click":_vm.addCalendar}},[_vm._v(" "+_vm._s(_vm.$t("common.저장"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }