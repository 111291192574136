const state = {
  // 메일 메인 화면 크기
  mainWidth: 0,
  // navi 열려있는지
  showNavi: true,
  // 네비게이션 가로길이
  naviWidth: 0,
  // 검색 상세
  showSearchDetail: false,
  // 검색 히스토리 저장 여부
  useSearchHistory: true,
  // 환경설정(오른쪽 네비)
  showConfigDrawer: false,
  configDrawerWidth: 320
};

const getters = {
  mainWidth: ({ mainWidth }) => mainWidth,
  naviInfo: ({ showNavi, naviWidth }) => ({ showNavi, naviWidth }),
  showConfigDrawer: ({ showConfigDrawer }) => showConfigDrawer,
  configDrawerWidth: ({ configDrawerWidth }) => configDrawerWidth,
  showSearchDetail: ({ showSearchDetail }) => showSearchDetail,
  useSearchHistory: ({ useSearchHistory }) => useSearchHistory
};

const mutations = {
  SET_MAIN_WIDTH: (state, width) => {
    state.mainWidth = width;
  },
  SET_NAVI_INFO: (state, { show, width }) => {
    state.showNavi = show;
    state.naviWidth = width;
  },
  SET_SHOW_CONFIG_DRAWER: (state, showConfigDrawer) => {
    state.showConfigDrawer = showConfigDrawer;
  },
  TOGGLE_SHOW_SEARCH_DETAIL: (state, show) => {
    const isUndefined = show === undefined;
    state.showSearchDetail = isUndefined ? !state.showSearchDetail : show;
  },
  TOGGLE_USE_SEARCH_HISTORY: state => {
    state.useSearchHistory = !state.useSearchHistory;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
