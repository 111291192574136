import i18n from "@/_locales";

const state = {
  show: false,
  title: "",
  type: "",
  params: {},
  // 다이얼로그 하단 우측 취소버튼(우측기준 두번째버튼)
  cancelBtn: {
    show: true,
    text: i18n.t("common.취소")
  },
  // 다이얼로그 하단 우측 저장버튼(우측기준 첫번째버튼)
  confirmBtn: {
    show: true,
    text: i18n.t("common.저장")
  },
  confirmCallbackFunc: () => {}
};

const getters = {
  showDialog: ({ show }) => show,
  dialogTitle: ({ title }) => title,
  dialogType: ({ type }) => type,
  getParams: ({ params }) => params,
  getCancelBtn: ({ cancelBtn }) => cancelBtn,
  getConfirmBtn: ({ confirmBtn }) => confirmBtn
};

const mutations = {
  SET_DIALOG: (
    state,
    {
      title,
      type,
      params,
      cancelBtn,
      confirmBtn,
      confirmCallbackFunc = () => {}
    }
  ) => {
    state.show = true;
    state.title = title;
    state.type = type || state.type;
    state.params = params || state.params;
    state.cancelBtn = cancelBtn || {
      show: true,
      text: i18n.t("common.취소")
    };
    state.confirmBtn = confirmBtn || {
      show: true,
      text: i18n.t("common.저장")
    };
    state.confirmCallbackFunc = confirmCallbackFunc;
  },
  CLOSE_DIALOG: state => {
    state.show = false;
    state.title = "";
    state.type = "";
    state.params = {};
    state.cancelBtn = {
      show: true,
      text: i18n.t("common.취소")
    };
    state.confirmBtn = {
      show: true,
      text: i18n.t("common.저장")
    };
    state.confirmCallbackFunc = () => {};
  }
};

const actions = {
  // 콜백함수
  confirmCallback({ state }, item) {
    state.confirmCallbackFunc(item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
