<template>
  <div>
    <MailNavigationDrawer v-bind="$props" v-if="route == 'mail'" ref="drawer" />
    <CalNavigationDrawer
      v-else-if="route == 'cal'"
      v-bind="$props"
      ref="drawer"
    />
    <ContactNavigationDrawer
      v-else-if="route == 'contact'"
      v-bind="$props"
      ref="drawer"
    />
    <DriveNavigationDrawer
      v-else-if="route == 'drive'"
      v-bind="$props"
      ref="drawer"
    />
    <BoardNavigationDrawer
      v-else-if="route == 'board'"
      v-bind="$props"
      ref="drawer"
    />
    <ToDoNavigationDrawer
      v-else-if="route == 'todo'"
      v-bind="$props"
      ref="drawer"
    />
    <FlowNavigationDrawer
      v-else-if="route == 'flow'"
      v-bind="$props"
      ref="drawer"
    />
    <ResourceNavigationDrawer
      v-else-if="route == 'resource'"
      v-bind="$props"
      ref="drawer"
    />
    <ApprovalNavigationDrawer
      v-else-if="route == 'approval'"
      v-bind="$props"
      ref="drawer"
    />
    <CustomerNavigationDrawer
      v-else-if="route == 'customer'"
      v-bind="$props"
      ref="drawer"
    />
  </div>
</template>

<script>
import MailNavigationDrawer from "@/mail/views/layout/NavigationDrawer";
import CalNavigationDrawer from "@/calendar/views/layout/NavigationDrawer.vue";
import ContactNavigationDrawer from "@/contact/views/components/NavigationDrawer";
import DriveNavigationDrawer from "@/drive/views/layout/NavigationDrawer";
import BoardNavigationDrawer from "@/board/views/layout/NavigationDrawer";
import ToDoNavigationDrawer from "@/todo/views/layout/NavigationDrawer";
import FlowNavigationDrawer from "@/flow/views/layout/NavigationDrawer";
import ResourceNavigationDrawer from "@/resource/views/layout/NavigationDrawer";
import ApprovalNavigationDrawer from "@/approval/views/layout/NavigationDrawer";
import CustomerNavigationDrawer from "@/customer/views/layout/NavigationDrawer"

export default {
  name: "NavigationDrawer",
  props: ["route"],
  components: {
    MailNavigationDrawer,
    CalNavigationDrawer,
    ContactNavigationDrawer,
    DriveNavigationDrawer,
    BoardNavigationDrawer,
    ToDoNavigationDrawer,
    FlowNavigationDrawer,
    ResourceNavigationDrawer,
    ApprovalNavigationDrawer,
    CustomerNavigationDrawer
  }
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
</style>
