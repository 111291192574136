<template>
  <div>
    <v-toolbar flat class="cr-resource-toolbar ml-3">
      <v-item-group
        v-if="!$vuetify.breakpoint.xs"
        class="v-btn-toggle transparent"
      >
        <v-btn
          outlined
          text
          @click="goToday()"
          v-text="$t('calendar.오늘')"
        ></v-btn>
      </v-item-group>

      <!-- YYYYMM -->
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="getDate"
            class="date-search-picker ml-4"
            readonly
            hide-details
            flat
            solo
            single-line
            v-bind="attrs"
            v-on="on"
            append-icon="mdi-calendar-month"
            @click:append="showDatePicker = true"
          >
          </v-text-field>
        </template>
        <v-date-picker
          :value="getCurrentDate"
          no-title
          :day-format="formatDay"
          @input="changeDate"
        />
      </v-menu>

      <!-- 이전/다음 -->
      <v-btn
        small
        icon
        color="grey darken-2"
        @click="goDate('prev')"
        class="ml-4"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        small
        icon
        color="grey darken-2"
        @click="goDate('next')"
        class="mr-4"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      showDatePicker: false
    };
  },
  computed: {
    ...mapGetters("resource", ["getCurrentDate", "getCurrentType"]),
    getDate: {
      get() {
        return moment(this.getCurrentDate).format("YYYY-MM-DD (ddd)");
      }
    }
  },
  methods: {
    goDate(direction) {
      const currentDateStr = this.getCurrentDate;
      let currentDate = moment(currentDateStr, "YYYY-MM-DD");
      if (direction === "prev") {
        currentDate.subtract(1, "days");
      } else {
        currentDate.add(1, "days");
      }
      this.pageMove(currentDate.format("YYYY-MM-DD"));
    },
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    changeDate(val) {
      this.showDatePicker = false;
      this.pageMove(val);
    },
    pageMove(searchDateStr) {
      const type = this.getCurrentType;
      this.$router.push({
        name: "resource_list",
        params: {
          date: searchDateStr,
          type: type
        }
      });
    },
    goToday() {
      const type = this.getCurrentType;
      let currentDate = moment().format("YYYY-MM-DD");
      this.$router.push({
        name: "resource_list",
        params: {
          date: currentDate,
          type: type
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-resource-toolbar ::v-deep {
  background-color: transparent !important;
  .v-toolbar__content {
    padding-left: 0px !important;
  }
  .date-search-picker {
    font-size: 1rem;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
    .v-input__control {
      min-height: 36px;
    }
  }
}

.v-btn-toggle .v-btn {
  height: 36px !important;
}

.menu-list .v-list-item {
  min-height: 40px;
}

.blank {
  visibility: hidden;
}
</style>
