<template>
  <div class="d-flex align-center">
    <!-- 검색 창 -->
    <SearchInput v-if="!$vuetify.breakpoint.xs" @updateCls="updateCls" />
    <!-- 폴더 정보 -->
    <div
      class="hidden-sm-and-down"
      style="padding-left: 16px; font-size: 14px;"
    >
      <b>
        {{ isSearch ? $t("mail.4", { value: nameFolder || "" }) : nameFolder }}
      </b>
      <!-- 검색아닐때 -->
      <b v-if="showNewCntTotalCnt" class="primary--text text--darken-1 pl-2">
        <span>{{ newCount }}</span>
        <span>{{ `${" / "}` }}</span>
        <span style="color: #333;">{{ totalCount }}</span>
      </b>
      <!-- 검색일때 -->
      <b v-else class="pl-2">
        <span>{{ getTotalElements }}</span>
      </b>
    </div>
    <!-- 리스트 새로고침 -->
    <v-btn
      x-small
      text
      plain
      :ripple="false"
      class="hidden-sm-and-down"
      @click="refresh"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss">
.v-application {
  > .v-application--wrap {
    .v-app-bar.cr-focus-search {
      // 메일모듈에서 검색창 포커스시 스타일조정
      .v-toolbar__content {
        padding-left: 8px;
        .v-app-bar__nav-icon,
        .v-toolbar__title {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { virtualFolderConverter } from "@/mail/constant/folderType";
import SearchInput from "@/mail/views/components/search";

export default {
  components: { SearchInput },
  props: {
    addCls: {
      type: String,
      default: ""
    }
  },
  data({ $store }) {
    const shareFolder = $store.getters["folder/currentShareFolder"];
    let currentFolder = $store.getters["folder/currentFolder"];
    let nameFolder = currentFolder.nameFolder || "";
    if ($store.getters["folder/isShare"]) {
      currentFolder = shareFolder || currentFolder;
      nameFolder = `${shareFolder?.owner}'s ${shareFolder?.nameFolder}`;
    }

    return {
      nameFolder,
      newCount: currentFolder?.newCount || 0,
      totalCount: currentFolder?.totalCount || 0
    };
  },
  computed: {
    ...mapGetters("mail", ["getTotalElements"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("folder", [
      "currentFolder",
      "isShare",
      "isTag",
      "isSent",
      "isDrafts",
      "currentShareFolder"
    ]),
    isSearch() {
      if (this.isTag) return false;
      return (
        this.getRouteListInfo.actionObj?.search?.type &&
        this.getRouteListInfo.actionObj?.search?.type.length > 0
      );
    },
    showNewCntTotalCnt() {
      if (this.isTag || this.isSent || this.isDrafts) return false;
      if (this.currentFolder?.id <= 0) return false;
      if (this.isSearch) return false;

      return true;
    }
  },
  watch: {
    currentFolder(currentFolder) {
      this.nameFolder = currentFolder?.nameFolder || "";
      this.newCount = currentFolder?.newCount || 0;
      this.totalCount = currentFolder?.totalCount || 0;
      if (this.isShare) {
        const { owner, nameFolder, newCount, totalCount } =
          this.currentShareFolder || {};
        this.nameFolder = `${owner}'s ${nameFolder}`;
        this.newCount = newCount || 0;
        this.totalCount = totalCount || 0;
      }
    },
    getRouteListInfo() {
      if (this.isShare) {
        const { owner, nameFolder, newCount, totalCount } =
          this.currentShareFolder || {};
        this.nameFolder = `${owner}'s ${nameFolder}`;
        this.newCount = newCount || 0;
        this.totalCount = totalCount || 0;
      }
    }
  },
  methods: {
    ...mapActions("mail", ["getList"]),
    refresh() {
      const { name, params } = this.$route;
      let { folderId, page, actionObj = "{}" } = params ?? {};
      folderId = virtualFolderConverter(folderId);
      if (name === "mail_view") {
        const { list } = JSON.parse(actionObj);
        actionObj = JSON.stringify(list);
      }

      this.getList({ page, actionObj, folderId });
    },
    updateCls(cls) {
      this.$emit("update:addCls", cls);
    }
  }
};
</script>
