// 결재선 타입
export const LINE_TYPE = {
  APPROVAL: "APPROVAL", // 결재
  HELP: "HELP", // 협조
  HELP_ORGAN: "HELP_ORGAN", // 부서 협조
  RECEIVE: "RECEIVE", // 수신
  RECEIVE_ORGAN: "RECEIVE_ORGAN", // 부서 수신
  CC: "CC", // 참조
  SHARE: "SHARE", // 공람
  SHARE_ORGAN: "SHARE_ORGAN" // 부서 공람
};

// 결재선 그룹
export const LINE_GROUP = {
  APPROVAL: "APPROVAL", // 결재
  RECEIVE: "RECEIVE", //수신
  CC: "CC", // 참조
  SHARE: "SHARE" // 공람
};

// [결재선 타입]이 속해있는 [결재선 그룹] 반환
export const getGroupByType = lineType => {
  const type = LINE_TYPE[lineType];
  switch (type) {
    case "APPROVAL":
    case "HELP":
    case "HELP_ORGAN":
      return LINE_GROUP.APPROVAL;
    case "RECEIVE":
    case "RECEIVE_ORGAN":
      return LINE_GROUP.RECEIVE;
    case "CC":
      return LINE_GROUP.CC;
    case "SHARE":
    case "SHARE_ORGAN":
      return LINE_GROUP.SHARE;
    default:
      return undefined;
  }
};

// [결재선 그룹]의 [결재선 타입] 목록 반환
export const getTypesByGroup = lineGroup => {
  const group = LINE_GROUP[lineGroup];
  switch (group) {
    case "APPROVAL":
      return [LINE_TYPE.APPROVAL, LINE_TYPE.HELP, LINE_TYPE.HELP_ORGAN];
    case "RECEIVE":
      return [LINE_TYPE.RECEIVE, LINE_TYPE.RECEIVE_ORGAN];
    case "CC":
      return [LINE_TYPE.CC];
    case "SHARE":
      return [LINE_TYPE.SHARE, LINE_TYPE.SHARE_ORGAN];
    default:
      return [];
  }
};

export default { LINE_TYPE, LINE_GROUP, getGroupByType, getTypesByGroup };
