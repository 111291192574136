<template>
  <v-hover v-slot:default="{ hover }">
    <div
      :data-id="item.id"
      :data-type="item.boardType"
      class="cr-tree-item cr-draggable-tree cr-dropzone-tree"
    >
      <div class="cr-tree-click-area" :title="item.title" @click="nodeClick" />
      <Title :item="item" />
      <RightBtn v-if="hover && !item.edit" :item="item" />
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-tree-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1px;

  .cr-tree-click-area {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import Title from "./Title.vue";
import RightBtn from "./RightBtn.vue";

export default {
  components: { Title, RightBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("todoRoute", ["routeBoard"]),
    nodeClick() {
      const { id: boardId, workspaceId, boardType } = this.item;
      if (boardType !== "BOARD") return;

      this.routeBoard({ workspaceId, boardId });
    }
  }
};
</script>
