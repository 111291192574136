import API from "@/approval/api/approval.api";
import router from "@/commons/router";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import {
  mergeValidationErrors,
  resetRuleErrors,
  resetServerErrors,
  validateApproverByServer,
  validateDraftOrgan,
  validateDraftUser,
  validateLastLineType,
  validateSortOrder
} from "@/approval/utils/ApprovalLineValidator";
import { validateDuplicateApprover } from "@/approval/utils/ApprovalLineValidator";

// state 초기값
const initialState = {
  // 기안 상신 타입 (DRAFT, INNER_DRAFT, EDIT, REUSE)
  draftType: "DRAFT",
  loading: false,
  loadingText: "불러오는중",
  formVersionMismatch: false,
  form: {},
  docNum: "",
  keepYear: "",
  openType: "",
  draftUser: {},
  draftOrgan: {},
  approvalLines: [],
  title: "",
  content: "",
  files: [],
  refDocs: [],
  docId: null,
  originDoc: null,
  originLine: null,
  templateFrames: [],
  templateBody: {},
  showApprovalLineSelectDialog: false,
  showFileUploadDialog: false,
  // 페이지 이탈 방지 적용 여부
  leaveLock: true
};

const state = { ...initialState };

const getters = {
  isInnerDraft: ({ originLine }) => !!originLine?.id,
  getLineErrors: ({ approvalLines }) => {
    let result = [];
    approvalLines.forEach(
      ({ errors = [] }) => (result = [...result, ...errors])
    );
    return result;
  },
  isAllLinesValid: (_, { getLineErrors }) => {
    return getLineErrors.length === 0;
  }
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_DRAFT_TYPE: (state, draftType) => (state.draftType = draftType),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_LOADING_TEXT: (state, text) => (state.loadingText = text),
  SET_FORM_VERSION_MISMATCH: (state, formVersionMismatch) => {
    state.formVersionMismatch = formVersionMismatch;
  },
  SET_FORM: (state, form) => {
    state.form = { ...form };
    state.openType = form.openType;
    state.keepYear = form.keepYear;
  },
  SET_TITLE: (state, title) => (state.title = title),
  SET_CONTENT: (state, content) => (state.content = content),
  SET_FILES: (state, payload) => (state.files = payload),
  SET_REF_DOCS: (state, refDocs) => (state.refDocs = [...refDocs]),
  SET_OPEN_TYPE: (state, openType) => (state.openType = openType),
  SET_DRAFT_USER: (state, draftUser) => (state.draftUser = { ...draftUser }),
  SET_DRAFT_ORGAN: (state, draftOrgan) =>
    (state.draftOrgan = { ...draftOrgan }),
  SET_APPROVAL_LINES: (state, payload) => {
    state.approvalLines = new ApprovalLines(payload).sortAsc().get();
  },
  SET_TEMPLATE_FRAMES: (state, templateFrames) => {
    state.templateFrames = [...templateFrames];
  },
  SET_TEMPLATE_BODY: (state, templateBody) => {
    state.templateBody = { ...templateBody };
  },
  SET_DOC_ID: (state, docId) => (state.docId = docId),
  SET_ORIGIN_DOC: (state, docSimple) => (state.originDoc = { ...docSimple }),
  SET_ORIGIN_LINE: (state, originLine) => {
    state.originLine = { ...originLine };
  },
  SET_SHOW_APPROVAL_LINE_SELECT_DIALOG: (state, show) => {
    state.showApprovalLineSelectDialog = show;
  },
  SET_SHOW_FILE_UPLOAD_DIALOG: (state, show) => {
    state.showFileUploadDialog = show;
  },
  SET_LEAVE_LOCK: (state, lock) => {
    state.leaveLock = lock;
  }
};

const actions = {
  /**
   * 페이지 초기화
   */
  async init(
    { commit, dispatch, state, rootGetters },
    { draftType, formId, originDocId, originLineId, docId }
  ) {
    commit("RESET");
    commit("approvalFileUploader/RESET", {}, { root: true });
    commit("SET_LOADING", true);
    commit("SET_LOADING_TEXT", "불러오는중");
    commit("SET_DRAFT_TYPE", draftType);

    // 각 결재 유형별 양식 및 데이타 로드
    switch (draftType) {
      case "DRAFT": // 일반 결재 유형
        if (!formId) return;
        await dispatch("fetchForm", formId);
        await dispatch("fetchApprovalLinesByPreset", state.form.presetId);
        await dispatch("fetchTemplate", state.form.templateId);
        commit("SET_CONTENT", state?.form?.contentTemplate ?? "");
        break;
      case "INNER_DRAFT": // 내부 결재 유형
        if (!originDocId || !originLineId) return;
        await dispatch("fetchInnerForm");
        await dispatch("fetchOriginDoc", originDocId);
        await dispatch("fetchOriginLine", originLineId);
        await dispatch("fetchTemplate", state.form.templateId);
        break;
      case "EDIT": // 기안 수정
      case "REUSE": // 재기안
        await dispatch("fetchDocumentToEdit", docId);
        break;
    }

    // [기안자]를 [본인]으로 설정
    const draftUser = {
      id: rootGetters["auth/getUserInfo"]?.id,
      email: rootGetters["auth/getUserInfo"]?.username,
      name: rootGetters["auth/getUserInfo"]?.accountName
    };
    commit("SET_DRAFT_USER", draftUser);

    // [기안 부서]를 작성자의 [대표 조직]으로 기본 설정
    if (state.draftType === "DRAFT") {
      const { organId, organizationName: name } = rootGetters[
        "auth/getRepresentativeOrgan"
      ];
      commit("SET_DRAFT_ORGAN", { organId, name });
    }

    commit("SET_LOADING", false);
  },
  /**
   * 기안 양식 로드
   */
  async fetchForm({ commit, dispatch }, formId) {
    const { status, data } = await API.getFormById(formId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안양식이 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
    }
    commit("SET_FORM", data);
  },
  /**
   * 내부 결재 양식 로드
   */
  async fetchInnerForm({ commit, dispatch }) {
    const { status, data } = await API.getInnerForm();
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안양식이 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
    }
    commit("SET_FORM", data);
  },
  /**
   * 템플릿 로드
   */
  async fetchTemplate({ commit, dispatch }, templateId) {
    const { status, data } = await API.getTemplateById(templateId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "템플릿이 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
    }
    commit("SET_TEMPLATE_FRAMES", data.fields);
  },
  /**
   * 원본 문서 로드 (내부 결재일때 사용)
   */
  async fetchOriginDoc({ commit, dispatch }, originDocId) {
    const { status, data } = await API.getDocumentSimple(originDocId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "원본 문서가 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
    }
    commit("SET_ORIGIN_DOC", data);
  },
  /**
   * 원본 결재선 로드
   */
  async fetchOriginLine({ commit, dispatch }, originLineId) {
    const { status, data } = await API.getApprovalLine(originLineId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "접수할 결재선이 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
    }
    commit("SET_ORIGIN_LINE", data);
    commit("SET_DRAFT_ORGAN", {
      organId: data?.approver?.organId,
      name: data?.approver?.organName
    });
  },
  /**
   * 기존 문서의 데이타 로드 (수정일 경우)
   */
  async fetchDocumentToEdit({ state, commit, dispatch }, docId) {
    const { status, data } = await API.getDocument(docId);
    if (status !== 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기존 문서를 불러오는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      return false;
    }
    await dispatch("fetchForm", data.formId);
    await dispatch("fetchApprovalLines", docId);
    commit(
      "SET_FORM_VERSION_MISMATCH",
      state.form?.version !== data.formVersion
    );
    commit("SET_DOC_ID", docId);
    commit("SET_DRAFT_ORGAN", data.draftOrgan);
    commit("SET_TITLE", data.title);
    commit("SET_REF_DOCS", data.refDocs);
    commit("SET_FILES", data.files);
    commit("SET_TEMPLATE_FRAMES", data.templateFrame.fields);
    commit("SET_TEMPLATE_BODY", data.templateBody);
    commit("editor/SET_CONTENT", replaceAccessToken(data.content), {
      root: true
    });
    commit("approvalFileUploader/SET_UPLOADED_FILES", data.files, {
      root: true
    });
    if (data.originDocPreview?.id) {
      await dispatch("fetchOriginDoc", data.originDocPreview?.id);
    }
    if (data.originLineId) {
      await dispatch("fetchOriginLine", data.originLineId);
    }
  },
  /**
   * 기존 문서의 결재선 목록 로드 (수정일 경우)
   */
  async fetchApprovalLines({ commit, dispatch }, docId) {
    const { status, data } = await API.getApprovalLines(docId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재선 조회에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    commit("SET_APPROVAL_LINES", data);
    dispatch("validateByRules");
    await dispatch("validateByServer");
    return true;
  },
  /**
   * 공용결재선 조회 및 적용
   */
  async fetchApprovalLinesByPreset({ commit }, presetId) {
    if (!presetId) return false;
    const { data } = await API.getApprLinePreset(presetId);
    commit("SET_APPROVAL_LINES", data?.approvalLines ?? []);
    return true;
  },
  /**
   * 기안 상신
   */
  async draft({ dispatch, commit, state, rootGetters }) {
    commit("SET_LOADING", true);
    commit("SET_LOADING_TEXT", "저장중");

    const payload = {
      approvalLines: state.approvalLines.map(convertLinePayload),
      draftOrganId: state.draftOrgan.organId,
      fileIds: rootGetters["approvalFileUploader/getLoadedFileIds"],
      formId: state.form.id,
      openType: state.openType,
      refDocIds: state.refDocs.map(ref => ref.id),
      templateBody: state.templateBody,
      content: state.content,
      title: state.title,
      originLineId: state.originLine?.id
    };
    // 수정일 경우 기존 문서 아이디 추가
    if (state.draftType === "EDIT") payload.docId = state.docId;

    // 기안 상신
    const { status } = await API.draftDocument(payload);

    // 실패시 return
    if (status !== 201) {
      commit("SET_LOADING", false);
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안 상신에 실패했습니다. 양식을 다시 확인해주세요",
          type: "ERROR"
        },
        { root: true }
      );
      return false;
    }
    // 성공시 기안함/상신한 목록으로 이동
    commit("approvalFileUploader/RESET", {}, { root: true });
    commit("SET_LOADING", false);
    commit("SET_LEAVE_LOCK", false);
    await router.push({
      name: "approval_document_list",
      params: { boxKey: "dd" }
    });
    return true;
  },
  /**
   * 기안 임시저장
   */
  async saveTemp({ dispatch, commit, state, rootGetters }) {
    commit("SET_LOADING", true);
    commit("SET_LOADING_TEXT", "저장중");

    let payload = {
      approvalLines: state.approvalLines.map(convertLinePayload),
      draftOrganId: state.draftOrgan.organId,
      fileIds: rootGetters["approvalFileUploader/getLoadedFileIds"],
      formId: state.form.id,
      openType: state.openType,
      refDocIds: state.refDocs.map(ref => ref.id),
      templateBody: state.templateBody,
      content: state.content,
      title: state.title
    };
    // 수정일 경우 기존 문서 아이디 추가
    if (state.draftType === "EDIT") {
      payload = { ...payload, docId: state.docId };
    }

    // 임시저장
    const { status } = await API.saveDocument(payload);

    // 싦패시 return
    if (status !== 201) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "임시 저장에 실패했습니다. 양식을 다시 확인해주세요",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시 기안함/저장한 목록으로 이동
    commit("approvalFileUploader/RESET", {}, { root: true });
    commit("SET_LOADING", false);
    commit("SET_LEAVE_LOCK", false);
    await router.push({
      name: "approval_document_list",
      params: { boxKey: "dt" }
    });
    return false;
  },
  /**
   * 문서 삭제
   */
  async deleteDoc({ dispatch, commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_LOADING_TEXT", "삭제중");

    const { status } = await API.deleteDocument(state.docId);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "문서 삭제에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "문서를 삭제하였습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );

    // 문서 삭제 후 기존 목록으로 이동
    commit("SET_LOADING", false);
    commit("SET_LEAVE_LOCK", false);
    await router.push({
      name: "approval_document_list",
      params: router.currentRoute.params,
      query: router.currentRoute.query
    });
    return true;
  },
  /**
   * 결재선 목록 수정
   */
  updateApprovalLines({ commit, dispatch }, newLines = []) {
    commit("SET_APPROVAL_LINES", newLines);
    dispatch("validateByRules");
  },
  /**
   * 기안부서 변경
   */
  changeDraftOrgan({ dispatch, commit }, draftOrgan = {}) {
    commit("SET_DRAFT_ORGAN", draftOrgan);
    dispatch("validateByRules");
  },
  /**
   * 결재선 규칙 검사 및 검사결과 업데이트
   * (서버 교차검증 제외한 모든 검증)
   */
  validateByRules({ commit, state }) {
    // 유효성 검사 결과를 병합한 결재선 목록
    let resultLines = resetRuleErrors(state.approvalLines);

    // {resultLines}에 유효성 검사 결과 병합 (모든 error를 누적합니다.)
    const putErrors = validationResults => {
      resultLines = mergeValidationErrors(resultLines, validationResults);
    };

    // 기안자가 결재선에 포함된 경우 체크
    putErrors(validateDraftUser(resultLines, state.draftUser.id));
    // 중복된 결재자 등록 체크
    putErrors(validateDuplicateApprover(resultLines));
    // 중복된 결재선 순서 체크
    putErrors(validateSortOrder(resultLines));
    // 최종결재선 유형 체크
    putErrors(validateLastLineType(resultLines));
    // 기안 부서를 등록한 경우 체크
    putErrors(validateDraftOrgan(resultLines, state.draftOrgan?.organId));

    commit("SET_APPROVAL_LINES", resultLines);
  },
  /**
   * 결재선의 [결재자]들이 유효한지 체크 (서버 교차 검증)
   */
  async validateByServer({ commit, state }) {
    // 실제 존재하는 결재자인지 검증
    const lines = resetServerErrors(state.approvalLines);
    const validationResults = await validateApproverByServer(lines);
    const resultLines = mergeValidationErrors(lines, validationResults);

    commit("SET_APPROVAL_LINES", resultLines);
  }
};

// 결재선 생성 페이로드로 변환
const convertLinePayload = approvalLine => {
  const { type, sortOrder, approver } = approvalLine;
  return {
    type,
    sortOrder,
    ...(approver.isUser
      ? { organId: approver.organId, userId: approver.userId }
      : { organId: approver.organId })
  };
};
// @ACCESS_TOKEN@ 치환
const replaceAccessToken = content => {
  const regex = /@ACCESS_TOKEN@/g;
  return content?.replace(regex, localStorage.getItem("access_token"));
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
