const state = {
  show: false
};

const getters = {
  isShow: ({ show }) => show
};

// state값 수정
const mutations = {
  SET_SHOW: (state, show) => {
    state.show = show;
  }
};

// mutations 호출 및 비동기 처리
const actions = {
  setLoaderShow({ commit }, show) {
    commit("SET_SHOW", show);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
