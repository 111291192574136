import http from "@/commons/api/http";

const prefix = "api/mail/config/forward";

/**
 * 메일 자동 전달 설정 조회
 */
const getForwardConfig = () => http.get(`${prefix}`);

/**
 * 설정 저장
 */
const saveForward = params => http.post(`${prefix}`, params);

/**
 * 승인 요청 메일 재전송
 */
const resendApprovalMail = params =>
  http.post(`${prefix}/resend_approval_mail`, params);

export { getForwardConfig, saveForward, resendApprovalMail };
