const virtualBoardConverter = boardId => {
  const types = [
    { value: -1, name: "all" },
    { value: -2, name: "my" },
    { value: -3, name: "mention" },
    { value: -4, name: "trash" },
    { value: -5, name: "notice" }
  ];

  if (boardId == undefined) return boardId;

  const result = types.filter(({ value, name }) =>
    isNaN(parseInt(boardId)) ? name == boardId.toLowerCase() : value == boardId
  );

  if (result.length == 0) return boardId > 0 ? boardId : NaN;
  // boardId가 name에 해당하면 value를
  // boardId가 value에 해당하면 name을 반환
  else return isNaN(parseInt(boardId)) ? result[0].value : result[0].name;
};

export { virtualBoardConverter };
