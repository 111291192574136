import Vue from "vue";
import { getMessage } from "@/flow/store/utils/messageUtils";
import { deleteTasks, deleteAllTask } from "@/flow/api/task.api";

const state = {};

const getters = {};

const mutations = {
  DELETE_TASK: (state, tasks) => {
    tasks.forEach(({ parentId, taskId, groupId }) => {
      // 상위 업무일때
      if (!parentId) {
        // 하위 업무 데이터도 삭제
        state.taskDataMap[taskId].subTasks?.forEach(id => {
          Vue.delete(state.taskDataMap, id);
        });
        // 리스트 목록에서 업무 삭제
        const listInfo = state.LIST[groupId];
        listInfo.ids = listInfo.ids.filter(id => id !== taskId);
        listInfo.total -= 1;
        state.LIST = { ...state.LIST };
        // 업무 데이터 삭제
        return Vue.delete(state.taskDataMap, taskId);
      }

      // 하위 업무일때
      const parentTask = state.taskDataMap[parentId];
      parentTask.subTasks = parentTask.subTasks?.filter(id => id !== taskId);
      parentTask.subCnt = parentTask.subTasks?.length || 0;
      Vue.delete(state.taskDataMap, taskId);
      if (parentTask.subCnt > 0) return;

      const openIndex = state.openSubTasks.indexOf(parentId);
      if (openIndex !== -1) Vue.delete(state.openSubTasks, openIndex);
    });
  }
};

const actions = {
  async deleteTasks({ rootState, commit, dispatch }, { tasks, callback }) {
    const { projectId } = rootState.flowRoute;
    const _callback = async () => {
      const taskIds = tasks.map(t => t.taskId);
      const { status } = await deleteTasks(projectId, taskIds);
      const message = getMessage(status);
      if (message) return dispatch("snackbar", { message });

      commit("DELETE_TASK", tasks);
      if (callback) callback();
    };

    const message = `해당 업무를 삭제 하시겠습니까?<br />삭제된 업무는 복구할 수 없습니다.`;
    return dispatch("confirm", { message, callback: _callback });
  },
  async deleteAllTask({ rootState, commit, dispatch }, callback) {
    const { projectId } = rootState.flowRoute;
    const _callback = async () => {
      const { status } = await deleteAllTask(projectId);
      const message = getMessage(status);
      if (message) return dispatch("snackbar", { message });

      commit("RESET_TASKS");
      commit("SET_LOADING", false);
      if (callback) callback();
    };

    const message = `해당 프로젝트의 모든 업무를 삭제 하시겠습니까?<br />삭제된 업무는 복구할 수 없습니다.`;
    return dispatch("confirm", { message, callback: _callback });
  }
};

export default { state, getters, mutations, actions };
