const state = {
  show: false,
  message: "",
  timeout: 2500,
  color: "",
  icon: "",
  position: {
    top: false,
    right: false
  }
};

const getters = {
  isShow: ({ show }) => show,
  getMessage: ({ message }) => message,
  getTimeOut: ({ timeout }) => timeout,
  getColor: ({ color }) => color,
  getIcon: ({ icon }) => icon,
  getPosition: ({ position }) => position
};

const mutations = {
  INIT: (state, { message, color, icon, setTop }) => {
    state.message = message;
    state.color = color;
    state.icon = icon;
    state.show = true;
    if (setTop) {
      state.position.top = true;
      state.position.right = false;
    } else {
      state.position.top = false;
      state.position.right = true;
    }
  },

  SET_SHOW: (state, show) => (state.show = show),
  CLOSE_SNACKBAR: state => {
    state.show = false;
    state.message = "";
    state.color = "";
    state.icon = "";
  }
};

const actions = {
  async openSnackbar(
    { commit },
    { message, type = "VALIDATION", setTop = false }
  ) {
    await commit("SET_SHOW", false);

    const colors = {
      SUCCESS: "light-blue accent-4",
      ERROR: "red accent-4",
      VALIDATION: "blue-grey darken-3"
    };

    const icon = {
      SUCCESS: "mdi-check",
      ERROR: "mdi-alert-circle-outline",
      VALIDATION: "mdi-information-outline"
    };

    await commit("INIT", {
      message,
      color: colors[type],
      icon: icon[type],
      setTop
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
