import Vue from "vue";
import {
  getShareMembers,
  addShare,
  updateShare,
  shareCancel,
  deleteShare
} from "../../api/share.api";
import i18n from "@/_locales";

const state = {
  show: false,
  isShared: false,
  shareId: 0,
  moduleType: "",
  shareItem: {
    id: 0,
    name: ""
  },
  members: [],
  permissionList: [], // 권한 리스트
  sendMessage: true,
  deleteList: [],
  addCallback: null,
  deleteCallback: null
};

const getters = {
  isShow: ({ show }) => show,
  getIsShared: ({ isShared }) => isShared,
  getShareId: ({ shareId }) => shareId,
  getMembers: ({ members }) => {
    if (!members) return;
    const result = members.map(
      (
        {
          id,
          memberId,
          userName,
          name,
          permission,
          isNew,
          isUpdated,
          isDeleted
        },
        index
      ) => {
        return {
          index,
          id,
          memberId,
          userName,
          name,
          permission,
          isNew,
          isUpdated,
          isDeleted
        };
      }
    );
    return result;
  },
  getShareType: ({ moduleType }) => {
    switch (moduleType) {
      case "MAIL":
        return i18n.t("common.메일함");
      case "DRIVE":
        return i18n.t("common.폴더");
      case "CONTACT":
        return i18n.t("common.주소록");
      case "CAL":
        return i18n.t("common.일정");
    }
  },
  getPermissionList: ({ permissionList }) => permissionList,
  getShareItem: ({ shareItem }) => shareItem,
  getSendMessage: ({ sendMessage }) => sendMessage,
  getDeleteList: ({ deleteList }) => deleteList
};

const mutations = {
  // 초기값 설정
  INIT: (
    state,
    {
      isShared,
      moduleType,
      shareItem,
      sendMessage,
      addCallback,
      deleteCallback
    }
  ) => {
    state.show = true;
    state.deleteList = [];
    state.isShared = isShared;
    state.moduleType = moduleType;
    state.shareItem = shareItem;
    state.sendMessage = sendMessage;
    state.permissionList =
      moduleType == "CAL"
        ? [
            { text: i18n.t("common.제목만보기"), value: 1 },
            { text: i18n.t("common.읽기"), value: 2 },
            { text: i18n.t("common.편집"), value: 3 }
          ]
        : [
            { text: i18n.t("common.읽기"), value: 2 },
            { text: i18n.t("common.편집"), value: 3 }
          ];
    state.addCallback = addCallback ? addCallback : null;
    state.deleteCallback = deleteCallback ? deleteCallback : null;
  },
  // 해당 아이템 공유 멤버, id값 설정
  SET_SHARE_INFO: (state, { list, shareId, isShared = false }) => {
    state.members = list;
    state.shareId = shareId;
    state.isShared = isShared;
  },
  SET_SHOW: (state, show) => (state.show = show),
  // 멤버 권한 수정
  SET_UPDATE_PERMISSON: (state, { index, permission }) => {
    const member = state.members[index];
    member.permission = permission;
    if (!member.isNew && !member.isUpdated) {
      member.isUpdated = true;
    }
  },
  // 공유 멤버 삭제(기존에 있는 멤버인 경우 deleteList에 추가)
  SET_DELETE: (state, index) => {
    const member = state.members[index];
    if (!member.isNew) {
      member.isDeleted = true;
      member.isUpdated = false;
      state.deleteList.push(member);
    }
    Vue.delete(state.members, index);
  },
  // 공유 멤버 추가
  SET_ADD_MEMBER: (state, member) => {
    const members = state.members;
    members.unshift(member);
  },
  SET_CLOSE: state => {
    state.show = false;
    state.isShared = false;
    state.shareId = 0;
    state.moduleType = "";
    state.shareItem = {
      id: 0,
      name: ""
    };
    state.members = [];
    state.permissionList = [];
    state.sendMessage = true;
    state.deleteList = [];
    state.addCallback = null;
    state.deleteCallback = null;
  }
};

const actions = {
  // shareDialog 초기값 세팅
  async shareDialog(
    { commit },
    {
      isShared,
      moduleType,
      shareItem,
      sendMessage,
      addCallback,
      deleteCallback
    }
  ) {
    await commit("INIT", {
      isShared,
      moduleType,
      shareItem,
      sendMessage,
      addCallback,
      deleteCallback
    });

    const { data } = await getShareMembers({
      moduleType,
      objectId: shareItem.id
    });
    const { list, shareId } = data;

    // 공유정보가 없는경우
    if (shareId == 0 || list.length == 0) {
      await commit("SET_SHARE_INFO", { list, shareId });
      return;
    }

    // 기존 공유정보가 있는경우
    const result = data.list.map(({ id, memberId, name, permission }) => {
      return {
        id,
        memberId,
        name,
        permission,
        isNew: false,
        isUpdated: false,
        isDeleted: false
      };
    });

    await commit("SET_SHARE_INFO", { list: result, shareId, isShared: true });
  },

  // 멤버 추가
  async addMember({ commit }, item) {
    const member = {
      memberId: item.userId,
      name: item.name,
      permission: 2,
      isNew: true,
      isUpdated: false,
      isDeleted: false
    };
    await commit("SET_ADD_MEMBER", member);
  },

  // 권한 업데이트
  async changePermission({ commit }, { index, permission }) {
    await commit("SET_UPDATE_PERMISSON", { index, permission });
  },

  // 멤버 삭제
  async deleteMember({ commit }, index) {
    await commit("SET_DELETE", index);
  },

  // 공유 추가
  async addShareItems({ state, dispatch }) {
    const addList = state.members.map(({ memberId, permission }) => {
      return {
        memberId,
        permission
      };
    });
    const { status } = await addShare({
      moduleType: state.moduleType,
      objectId: state.shareItem.id,
      objectName: state.shareItem.name,
      addList
    });

    let message = i18n.t("common.10");
    let type = "SUCCESS";

    if (status == 200 && state.addCallback) {
      await state.addCallback(state.shareItem.id);
    }

    if (status != 200) {
      message = i18n.t("common.7");
      type = "ERROR";
    }

    await dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  // 공유 내용 수정
  async updateShareItems(
    { getters, dispatch },
    { addList, updateList, deleteList }
  ) {
    const { data, status } = await updateShare({
      shareId: getters.getShareId,
      moduleType: state.moduleType,
      objectId: state.shareItem.id,
      addList,
      updateList,
      deleteList
    });

    // let callbackResult = true;
    let type = "SUCCESS";
    let message = i18n.t("common.11");

    if (status == 200) {
      if (data == -2) {
        await dispatch("disagree");
        return;
      }

      switch (data) {
        case -1:
          type = "ERROR";
          message = i18n.t("common.12");
          break;
        case 0:
          type = "SUCCESS";
          message = i18n.t("common.13");
          break;
        case 1:
          if (state.deleteCallback) {
            await state.deleteCallback(state.shareItem.id);
          }
          break;
      }
    }
    if (status != 200) {
      type = "ERROR";
      message = i18n.t("common.7");
    }

    await dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  // 공유 취소 (공유 받는 사람 쪽에서의 취소)
  async toCancelShare({ dispatch }, { cancelList, moduleType }) {
    const { status } = await shareCancel({ cancelList, moduleType });

    let type = "SUCCESS";
    let message = i18n.t("common.11");

    if (status != 200) {
      type = "ERROR";
      message = i18n.t("common.7");
    }
    await dispatch("snackbar/openSnackbar", { message, type }, { root: true });

    return status == 200 ? true : false;
  },

  // 공유한 공유 삭제 및 메타정보 삭제
  async toDeleteShare({ dispatch }, { objectIds, moduleType }) {
    const { status } = await deleteShare({ objectIds, moduleType });

    let type = "SUCCESS";
    let message = i18n.t("common.14");
    if (status !== 204) {
      type = "ERROR";
      message = i18n.t("common.7");
    }
    await dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return status == 204;
  },

  // 다이얼로그 닫기
  async disagree({ commit }) {
    await commit("SET_CLOSE");
    // await commit("SET_SHOW", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
