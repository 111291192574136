<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    class="pt-0"
  >
    <!-- 기안작성 버튼 -->
    <div class="pa-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
      <v-btn large block depressed color="primary" @click="goFormSelect">
        <v-icon left>mdi-plus</v-icon>
        <h3>{{ $t("approval.기안작성") }}</h3>
      </v-btn>
    </div>
    <!-- 문서함 트리 -->
    <TreeView />
  </v-navigation-drawer>
</template>

<script>
import TreeView from "@/approval/views/layout/TreeView";

export default {
  components: { TreeView },
  data() {
    return {
      drawer: true
    };
  },
  methods: {
    // 기안 양식 선택 페이지 이동
    goFormSelect() {
      this.$router.push({
        name: "approval_form_select"
      });
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped></style>
