<template>
  <div class="cr-flow-project-appbar px-1">
    <ProjectInfo />
    <Actions />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-project-appbar {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}
</style>

<script>
import ProjectInfo from "./ProjectInfo.vue";
import Actions from "./Actions.vue";

export default {
  components: { ProjectInfo, Actions }
};
</script>
