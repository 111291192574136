import http from "@/commons/api/http";

const prefix = "api/customer/card";

// 연락처 리스트 조회
const fetchCardList = params => http.get(prefix, { params });

// 연락처 리스트 검색
const searchCardList = params => http.get(`${prefix}/search`, { params });

// 연락처 상세 조회
const fetchCardDetail = ({ id }) => http.get(`${prefix}/detail/${id}`);

// 연락처 추가
const createCard = cardData => http.post(prefix, cardData);

// 연락처 인쇄 리스트 조회
const fetchPrintCardList = params => http.get(`${prefix}/print`, { params });

/**
 * 연락처 이동
 */
const moveCards = params => {
  const formData = new FormData();
  formData.append("targetGroupId", params.targetGroupId);
  formData.append("cardIdList", params.cardIdList);
  return http.patch(`${prefix}/move`, formData);
};

// 연락처 일괄 추가
const createAllCard = cardData => http.post(`${prefix}/all`, cardData);

// 연락처 수정
const updateCard = ({ cardData }) => http.put(`${prefix}`, cardData);

// 연락처 리스트 삭제
const deleteCardList = param => {
  const formData = new FormData();
  formData.append("groupId", param.groupId);
  formData.append("cardIdList", param.cardIdList);

  return http.delete(prefix, { data: formData });
};

const importCards = params => http.post(`${prefix}/import/save`, params);

// 개인 연락처 고객주소록으로 복사 or 이동
const copyPrivateCardsToCustomer = params =>
  http.post(`${prefix}/copy/private`, params);

export {
  fetchCardList,
  searchCardList,
  fetchCardDetail,
  createCard,
  createAllCard,
  updateCard,
  deleteCardList,
  moveCards,
  importCards,
  fetchPrintCardList,
  copyPrivateCardsToCustomer
};
