<template>
  <v-text-field
    ref="textField"
    outlined
    dense
    color="grey"
    hide-details
    :placeholder="useAddItem ? $t('board.40') : $t('board.121')"
    prepend-inner-icon="mdi-magnify"
    class="cr-search-wrapper py-2 px-3"
    v-model="value"
    @click.stop
    @keydown.stop
    @keydown.esc="openMenu = false"
  />
</template>

<script>
export default {
  props: {
    openMenu: {
      default: false,
      type: Boolean
    },
    inputValue: {
      default: "",
      type: String
    },
    useAddItem: {
      default: false,
      type: Boolean,
      description: "검색 시 없을경우 생성 여부"
    }
  },
  computed: {
    value: {
      get() {
        return this.inputValue;
      },
      set(value) {
        this.$emit("update:inputValue", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input ::v-deep {
  position: absolute;
  top: 0px;
  bottom: 244px;
  right: 0px;
  left: 0px;
  background: #fafafb;
  .v-input__control {
    background-color: #fff;
  }
}
</style>
