<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on: { click } }">
      <Activator :avatar="avatar" :disabled="disabled" @click="click" />
    </template>

    <Contents
      :avatar="avatar"
      @update:avatar="avatar => $emit('update:avatar', avatar)"
    />
  </v-menu>
</template>

<script>
import Activator from "./activator";
import Contents from "./contents";

export default {
  components: { Activator, Contents },
  props: {
    avatar: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
