<template>
  <div class="cr-todo-select">
    <!-- v-select closeOnContentClick 버그로인해 v-menu 이용 -->
    <v-menu
      ref="menu"
      offset-overflow
      offset-y
      disable-keys
      close-on-content-click
      content-class="mt-3"
      v-model="openMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <SelectFrame
          v-bind="attrs"
          v-on="on"
          :workspaceTitle="workspaceTitle"
          :openMenu="openMenu"
        />
      </template>

      <div class="cr-todo-menu-wrapper">
        <SearchInput
          ref="searchInput"
          :inputValue.sync="inputValue"
          :openMenu="openMenu"
        />
        <List
          v-bind="$props"
          :openMenu="openMenu"
          :selectedWorkspaceId="selectedWorkspaceId"
          :inputValue="inputValue"
          @select="select"
        />
        <BtnGroups />
      </div>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
// 전체
.cr-todo-select {
  width: 100%;
  cursor: pointer;

  // 목록 호출 버튼
  button {
    width: 100%;
  }
}

// 목록 부분
.v-menu__content ::v-deep {
  border-radius: 0px;
  overflow-y: hidden;

  .v-btn.cr-todo-menu-btn:not(.v-btn--round).v-size--default {
    padding: 0px 6px;
  }

  .cr-todo-menu-wrapper {
    position: relative;
    background: #fff;
    height: 334px;
    width: 300px;
  }

  .cr-todo-menu-btn-wrapper {
    position: absolute;
    width: 100%;
    top: 285px;
    padding: 6px 6px;
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
}

.v-input.v-text-field--outlined fieldset ::v-deep {
  border-color: var(--v-primary-base);
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectFrame from "./SelectFrame.vue";
import SearchInput from "./SearchInput.vue";
import BtnGroups from "./BtnGroups.vue";
import List from "./List.vue";

export default {
  components: { SelectFrame, SearchInput, BtnGroups, List },
  data({ $store }) {
    const { getters } = $store;
    const selectedWorkspaceId =
      parseInt(getters["todoRoute/routeWorkspaceId"]) || 0;

    return {
      openMenu: false,
      inputValue: "",
      selectedWorkspaceId
    };
  },
  computed: {
    ...mapGetters("todoWorkspace", ["allWorkspaces"]),
    ...mapGetters("todoRoute", ["routeWorkspaceId"]),
    workspaceTitle() {
      if (this.routeWorkspaceId > 0) {
        const index = this.allWorkspaces.findIndex(
          w => w.id == this.routeWorkspaceId
        );
        if (index > -1) {
          return this.allWorkspaces[index]?.title ?? "";
        }
      }

      return "모든 워크스페이스";
    }
  },
  watch: {
    routeWorkspaceId(id) {
      this.selectedWorkspaceId = parseInt(id) || 0;
    },
    openMenu(n) {
      this.inputValue = "";

      if (n) {
        setTimeout(() => {
          // 검색 인풋 포커스
          this.$refs.searchInput?.$refs?.textField?.focus();
        }, 100);
      }
    }
  },
  methods: {
    ...mapActions("todoRoute", ["routeWorkspace"]),
    // 트리에서 폴더 선택
    select(workspace) {
      const { id: workspaceId } = workspace || {};
      this.routeWorkspace({ workspaceId });
    },
    activateMenu() {
      this.openMenu = true;
    },
    closeMenu() {
      this.openMenu = false;
    }
  }
};
</script>
