<template>
  <v-hover v-slot:default="{ hover }">
    <div :data-id="item.id" :class="draggableClass">
      <!-- 노드 클릭 영역 -->
      <div class="cr-click-area" :title="item.nameFolder" @click="nodeClick" />
      <!-- 폴더이름, 수정 텍스트 필드 -->
      <FolderName :item="item" />
      <!-- 새메일 뱃지 -->
      <Badge :item="item" :hover="hover" />
      <!-- 폴더 메뉴, 설정, 휴지통비우기, 생성 버튼 -->
      <RightBtn :item="item" :hover="hover" />
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-mt-1 {
  /* margin-top: 1px; */
  .cr-click-area {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
import { rootFolderType, privateFolderType } from "@/mail/constant/folderType";
import FolderName from "./FolderName.vue";
import Badge from "./Badge.vue";
import RightBtn from "./RightBtn.vue";

export default {
  components: { FolderName, Badge, RightBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    draggableClass() {
      const { id, folderType } = this.item;
      let cls = "d-flex align-center justify-space-between cr-mt-1";
      if (privateFolderType[folderType]) {
        cls += " cr-draggable-tree cr-dropzone-tree";
      }
      if (
        (id > 0 || `${id}`.startsWith("t_")) &&
        folderType !== "DRAFTS" &&
        folderType !== "TOME" &&
        folderType !== "SEARCH" &&
        folderType !== "TAG_ROOT"
      ) {
        cls += " cr-dropzone-list";
      }

      return cls;
    }
  },
  methods: {
    ...mapActions("folder", ["getFolderById"]),
    ...mapActions("mailRoute", ["routeMailList", "routeTag"]),
    // 메일함 클릭시 라우팅
    async nodeClick() {
      if (!this.item || rootFolderType[this.item.folderType]) return;

      const { id: folderId, folderType, nameFolder } = this.item;
      const params = { folderId, page: 1, name: "mail_list", actionObj: {} };
      if (folderType === "TAG") return this.routeTag(`t_${nameFolder}`);
      if (folderType === "SEARCH") {
        const { options } = await this.getFolderById(folderId);
        options.tags = options.tags?.map(t => t.tag)?.join(",");
        options.timeRange = options.timeRange?.map(t =>
          getDateWithoutTime(new Date(t).getTime(), "YYYY-MM-DD")
        );

        params.name = "mail_list_action";
        params.actionObj["search"] = { ...options };
      }
      this.routeMailList(params);
    }
  }
};
</script>
