const state = {
  // 환경설정(오른쪽 네비)
  showSetting: false
};

const getters = {
  getShowSetting: ({ showSetting }) => showSetting
};

const mutations = {
  SET_SHOW_SETTING: (state, showSetting) => {
    state.showSetting = showSetting;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
