<template>
  <v-text-field
    outlined
    hide-details
    dense
    class="mr-3"
    style="max-width: 400px;"
    prepend-inner-icon="mdi-magnify"
    :placeholder="$t('common.76')"
    :error="search.error"
    :label="search.label"
    v-model="value"
    @keydown="keydown = true"
    @keydown.enter="addTag({ ...search, callback })"
  >
    <template v-if="showAddBtn" v-slot:append>
      <v-btn icon color="primary" @click="addTag({ ...search, callback })">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
          </template>

          {{ $t("mail.23") }}
        </v-tooltip>
      </v-btn>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-text-field::v-deep {
  .v-input__append-inner {
    margin-top: 2px !important;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    search: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { timeout: null, keydown: false };
  },
  computed: {
    ...mapGetters("tag", ["validTag"]),
    value: {
      get() {
        return this.search.tag;
      },
      set(tag) {
        // 2byte 문자버그로 인해 텍스트 입력 후 페이지 클릭시 두번탐 -> keydown이벤트로 감지
        if (!this.keydown) return;
        const { error, label } = this.validTag(tag);
        this.$emit("setSearch", { tag, error, label });
      }
    },
    showAddBtn() {
      return this.value && !this.search.error;
    }
  },
  watch: {
    search({ tag: n }, { tag: p }) {
      if (n === p) return;
      this.SET_LOADING(true);
      this.SET_SEARCH_TAGS([]);
      this.getSearchTags();
    }
  },
  methods: {
    ...mapMutations("tag", ["SET_SEARCH_TAGS", "SET_LOADING"]),
    ...mapActions("tag", ["addTag", "searchTags"]),
    callback() {
      this.$emit("setSearch", { tag: "", error: false, label: "" });
    },
    getSearchTags() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(() => {
        this.searchTags(this.search);
        this.keydown = false;
      }, 400);
    }
  }
};
</script>
