import http from "@/commons/api/http";

const prefix = "api/todo/item";

/**
 * 상세정보 불러오기
 * @param {*} itemId 아이템 id
 * @param {*} boardId 보드 id
 */
const getItemDetail = (itemId, boardId) =>
  http.get(`${prefix}/${itemId}/${boardId}/detail`);

/**
 * 상세내용 작성
 * @param {*} itemId 아이템 id
 * @param {*} boardId 보드 id
 * @param {*} content 상세내용
 */
const saveContent = (itemId, boardId, content) => {
  const formData = new FormData();
  formData.append("content", content);

  return http.post(`${prefix}/${itemId}/${boardId}/detail/content`, formData);
};

/**
 * 체크리스트 추가
 */
const addCheckList = (itemId, boardId, title) => {
  const formData = new FormData();
  formData.append("title", title);

  return http.post(`${prefix}/${itemId}/${boardId}/detail/checklist`, formData);
};

/**
 * 체크리스트 타이틀 수정
 */
const updateDetailCheckListTitle = (id, itemId, boardId, title) => {
  const formData = new FormData();
  formData.append("title", title);

  return http.put(
    `${prefix}/${itemId}/${boardId}/detail/checklist/${id}/title`,
    formData
  );
};

/**
 * 체크리스트 체크여부 수정
 */
const updateDetailCheckListChecked = (id, itemId, boardId, checked) => {
  const formData = new FormData();
  formData.append("checked", checked);

  return http.put(
    `${prefix}/${itemId}/${boardId}/detail/checklist/${id}/checked`,
    formData
  );
};

/**
 * 체크리스트 삭제
 */
const deleteDetailCheckList = (id, itemId, boardId) =>
  http.delete(`${prefix}/${itemId}/${boardId}/detail/checklist/${id}`);

/**
 * 파일 업로드
 */
const uploadFile = (itemId, boardId, uploadType, file, config) => {
  const formData = new FormData();
  formData.append("part", file);
  formData.append("uploadType", uploadType);

  return http.post(
    `${prefix}/${itemId}/${boardId}/detail/file/upload`,
    formData,
    config
  );
};

/**
 * 파일 삭제
 */
const deleteFile = (itemId, boardId, id) =>
  http.delete(`${prefix}/${itemId}/${boardId}/detail/file/${id}`);

/**
 * 댓글 추가
 */
const addComment = (itemId, boardId, content) => {
  const formData = new FormData();
  formData.append("content", content);

  return http.post(`${prefix}/${itemId}/${boardId}/detail/comment`, formData);
};

/**
 * 댓글 수정
 */
const updateComment = (itemId, boardId, id, content) => {
  const formData = new FormData();
  formData.append("content", content);

  return http.put(
    `${prefix}/${itemId}/${boardId}/detail/comment/${id}`,
    formData
  );
};

/**
 * 댓글 삭제
 */
const deleteComment = (itemId, boardId, id) =>
  http.delete(`${prefix}/${itemId}/${boardId}/detail/comment/${id}`);

export {
  getItemDetail,
  saveContent,
  addCheckList,
  updateDetailCheckListTitle,
  updateDetailCheckListChecked,
  deleteDetailCheckList,
  uploadFile,
  deleteFile,
  addComment,
  updateComment,
  deleteComment
};
