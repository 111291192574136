import http from "@/commons/api/http";

const prefix = "api/alias";

/**
 * 내가 속한 그룹 계정 조회
 */
const getMyAlias = () => http.get(`${prefix}/me`);

export { getMyAlias };
