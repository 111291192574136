<template>
  <v-navigation-drawer app v-model="drawer" :clipped="lgAndUp">
    <div class="pa-3 d-flex" :class="{ 'pt-0': lgAndUp }">
      <WorkspaceSelect />
    </div>

    <div v-if="showBtns" style="display: contents;">
      <AddBtns :class="{ 'pt-0': lgAndUp }" />
      <TreeView />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import WorkspaceSelect from "@/todo/views/components/navigation/workspaceSelect";
import AddBtns from "@/todo/views/components/navigation/AddBtns.vue";
import TreeView from "@/todo/views/components/navigation/treeview";

export default {
  components: { WorkspaceSelect, AddBtns, TreeView },
  data({ $store: { getters } }) {
    const workspaceId = getters["todoRoute/routeWorkspaceId"];
    return { drawer: !!parseInt(workspaceId) };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeWorkspaceId"]),
    showBtns() {
      return this.$route.name !== "todo";
    },
    lgAndUp() {
      return this.$vuetify.breakpoint.lgAndUp;
    }
  },
  watch: {
    routeWorkspaceId(id) {
      this.drawer = id !== 0;
    }
  }
};
</script>
