<template>
  <div>
    <v-row v-for="(alarm, index) in value" :key="index" dense>
      <v-col cols="12" class="d-flex align-center">
        <FlatSelect
          v-model="alarm.action"
          :items="actionTypes"
          item-text="name"
          :menu-props="{
            top: true,
            offsetY: true
          }"
          :btn-props="{ text: false, depressed: true }"
        />

        <FlatTextField
          v-model="alarm.time"
          class="ml-1"
          type="number"
          min="5"
          step="5"
          style="max-width: 72px"
        />

        <FlatSelect
          v-model="alarm.duration"
          :items="durationTypes"
          item-text="name"
          :menu-props="{
            top: true,
            offsetY: true
          }"
          :btn-props="{ class: 'ml-1', text: false, depressed: true }"
        />

        <v-btn class="ml-1" icon @click="deleteAlarm(index)">
          <v-icon dense>
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/_locales";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";
import FlatTextField from "@/calendar/components/common/FlatTextField.vue";

export default {
  components: { FlatTextField, FlatSelect },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      actionTypes: [{ name: i18n.t("calendar.이메일"), value: "EMAIL" }],
      durationTypes: [
        { name: i18n.t("calendar.44"), value: "M" },
        { name: i18n.t("calendar.45"), value: "H" },
        { name: i18n.t("calendar.46"), value: "D" }
      ]
    };
  },
  methods: {
    deleteAlarm(index) {
      this.$emit(
        "input",
        this.value.filter((_, i) => i !== index)
      );
    }
  }
};
</script>
