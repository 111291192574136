const getMessage = (status, additionalMsg) => {
  return {
    400: "잘못된 요청입니다.",
    401: "인증 정보가 유효하지 않은 사용자입니다.",
    403: "권한이 없는 사용자입니다.",
    404: "해당 정보를 찾을 수 없습니다.",
    500: "서버 에러가 발생했습니다. 관리자에게 문의하세요.",
    ...(additionalMsg || {})
  }[status];
};

export { getMessage };
