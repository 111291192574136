<template>
  <div>
    <v-toolbar v-if="!showSearch" flat class="cr-cal-toolbar">
      <v-item-group
        v-if="!$vuetify.breakpoint.xs"
        class="v-btn-toggle transparent"
      >
        <v-btn
          outlined
          text
          @click="setToday"
          v-text="$t('calendar.오늘')"
        ></v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn outlined text v-on="on" class="cr-tutorial-daymenu">
              <span>{{ $t(`calendar.ViewType.${calendarType}`) }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>

          <v-list class="menu-list">
            <!-- 캘린더 표시 방식 -->
            <v-list-item
              v-for="viewType in CALENDAR_VIEW_TYPE"
              :key="viewType"
              @click="changeViewType(viewType)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t(`calendar.ViewType.${viewType}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-1"></v-divider>

            <!-- 필터 옵션 -->
            <v-list-item
              v-for="optionName in filterOptionNames"
              :key="optionName"
              @click="
                SET_FILTER_OPTION({
                  option: optionName,
                  value: !filterOption[optionName].value
                })
              "
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <v-icon
                    small
                    class="mr-2"
                    :class="{ blank: !filterOption[optionName].value }"
                  >
                    mdi-check
                  </v-icon>
                  {{ $t(`calendar.FilterOption.${optionName}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-item-group>

      <!-- 이전/다음 -->
      <v-btn small icon color="grey darken-2" @click="move(false)" class="ml-4">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn small icon color="grey darken-2" @click="move(true)" class="mr-4">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <!-- YYYYMM -->
      <v-toolbar-title>
        {{ calendarHeaderTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <EventSearchBar v-else />
    <UserLeave
      v-if="showLeaveDialog"
      @closeDialog="SET_SHOW_LEAVE_DIALOG(false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UserLeave from "@/calendar/components/event/leave/LeaveManageDialog.vue";
import moment from "moment";
import { CALENDAR_VIEW_TYPE } from "@/calendar/constant/calConstants";
import EventSearchBar from "@/calendar/components/EventSearchBar.vue";

export default {
  components: { EventSearchBar, UserLeave },
  data() {
    return {
      message: false,
      calendarType: CALENDAR_VIEW_TYPE.MONTH
    };
  },
  computed: {
    CALENDAR_VIEW_TYPE() {
      return CALENDAR_VIEW_TYPE;
    },
    ...mapState("cal", ["filterOption", "calendarHeaderTitle"]),
    ...mapGetters("calLayout", ["showSearch", "showLeaveDialog"]),
    ...mapGetters("calRoute", ["getCalendarType"]),
    ...mapGetters("auth", ["getPlanType"]),
    filterOptionNames() {
      const options = Object.keys(this.filterOption);
      return options.filter(optionName => {
        return (
          !this.filterOption[optionName].isAdvanced ||
          this.getPlanType === "ADVANCED"
        );
      });
    }
  },
  watch: {
    getCalendarType(val) {
      this.calendarType = val || "month";
    }
  },
  mounted() {
    const type = this.$route?.params?.type;
    this.calendarType = type || CALENDAR_VIEW_TYPE.MONTH;
  },
  methods: {
    ...mapMutations("cal", ["SET_FILTER_OPTION"]),
    ...mapMutations("calRoute", ["SET_ROUTE_FOCUS"]),
    ...mapMutations("calLayout", ["SET_SHOW_SEARCH", "SET_SHOW_LEAVE_DIALOG"]),
    ...mapActions("cal", ["searchEventList"]),
    setToday() {
      const type = this.$route?.params?.type;
      const focus = moment().format("YYYY-MM-DD");
      this.pushRoute(type, focus);
    },
    move(isNext) {
      const dateFormat = "YYYY-MM-DD";
      const type = this.$route.params.type || "month";
      const focus = this.$route.params.focus
        ? moment(this.$route.params.focus, dateFormat)
        : moment();
      switch (type) {
        case "month":
          focus.add(isNext ? 1 : -1, "months");
          break;
        case "week":
          focus.add(isNext ? 7 : -7, "days");
          break;
        case "4days":
          focus.add(isNext ? 4 : -4, "days");
          break;
        default:
          focus.add(isNext ? 1 : -1, "days");
          break;
      }

      this.pushRoute(type, moment(focus).format(dateFormat));
    },
    changeViewType(viewType) {
      const focus = this.$route?.params?.focus;
      const toFocus = focus || moment().format("YYYY-MM-DD");
      this.calendarType = viewType;
      this.pushRoute(viewType, toFocus);
    },
    pushRoute(type, focus) {
      // 미니 캘린더만 이동하고 오늘 버튼을 클릭하면
      // router 감지 안되서 오늘로 이동 안하는 현상 수정.
      this.SET_ROUTE_FOCUS(focus);
      this.$router.push({
        name: "cal_frame",
        params: {
          type: type || CALENDAR_VIEW_TYPE.MONTH,
          focus: focus
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar ::v-deep {
  background-color: transparent !important;
  .v-toolbar__content {
    padding-left: 0 !important;
  }
}

.v-btn-toggle .v-btn {
  height: 36px !important;
}

.menu-list .v-list-item {
  min-height: 40px;
}

.blank {
  visibility: hidden;
}
</style>
