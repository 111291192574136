<template>
  <span class="cr-tree-label-text" v-if="!item.isEdit">
    {{ item.nameFolder }}
  </span>
  <v-text-field
    v-else
    autofocus
    autocomplete="off"
    :loading="editTextLoading"
    :label="item.nameFolder"
    v-on:blur="editFolder"
    v-on:keyup.enter="editFolder"
    v-on:keyup.esc="UPDATE_IS_EDIT(item.id)"
  />
</template>

<style lang="scss" scoped>
.cr-tree-label-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  min-width: 0px;
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { editTextLoading: false };
  },
  methods: {
    ...mapMutations("folder", ["UPDATE_IS_EDIT"]),
    ...mapActions("folder", ["updateFolderName"]),
    // 내메일함 이름 변경 서버 요청
    async editFolder(e) {
      if (!this.item) return;

      const { id: folderId, nameFolder } = this.item;
      const value = e.target.value.trim();
      if (!value || nameFolder == value) {
        this.UPDATE_IS_EDIT(folderId);
        this.editTextLoading = false;
        return;
      }
      if (this.editTextLoading) return;

      this.editTextLoading = true;
      await this.updateFolderName({ folderId, nameFolder: value });
      this.UPDATE_IS_EDIT(folderId);
      this.editTextLoading = false;
    }
  }
};
</script>
