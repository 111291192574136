<template>
  <v-menu v-model="showMenu" v-bind="menuProps">
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="{ ...attrs, ...btnProps }"
        v-on="{ ...on, ...$listeners }"
        class="px-2"
        :text="!btnProps || (btnProps && btnProps.text)"
        :disabled="menuProps && menuProps.disabled"
      >
        <div class="d-flex align-center text-body-2 text-none">
          <slot name="selection" v-bind:selection="selection">
            {{ getItemText(selection) }}
          </slot>
        </div>
        <v-icon right class="ml-1">
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item-group :value="currentValue" mandatory @change="handleChange">
        <v-list-item
          :key="idx"
          v-for="(item, idx) in items"
          :disabled="item.disabled"
          :value="getItemValue(item)"
        >
          <v-list-item-title class="d-flex align-center text-body-2">
            <slot name="item" v-bind:item="item">
              {{ getItemText(item) }}
            </slot>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object
    },
    btnProps: {
      type: Object
    }
  },
  computed: {
    currentValue() {
      return this.returnObject ? this.getItemValue(this.value) : this.value;
    },
    selection() {
      return this.items.find(
        item => this.getItemValue(item) === this.currentValue
      );
    },
    isObject() {
      return this.items.length > 0 && typeof this.items[0] === "object";
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    getItemText(item) {
      return this.isObject ? item[this.itemText] : item;
    },
    getItemValue(item) {
      return this.isObject ? item[this.itemValue] : item;
    },
    handleChange(value) {
      this.$emit(
        "input",
        this.returnObject
          ? this.items.find(item => this.getItemValue(item) === value)
          : value
      );
    }
  }
};
</script>
