export default [
  {
    path: "/drive/share",
    name: "drive_share",
    component: () =>
      import(/* webpackChunkName: "driveShare" */ "../views/DriveMain.vue"),
    children: [
      {
        path: ":page",
        name: "drive_share_list"
      },
      {
        path: ":page/:actionObj",
        name: "drive_share_list_action"
      }
    ]
  },
  {
    path: "/drive/shared",
    name: "drive_shared",
    component: () =>
      import(/* webpackChunkName: "driveShare" */ "../views/DriveMain.vue"),
    children: [
      {
        path: ":folderId/:page",
        name: "drive_shared_list"
      },
      {
        path: ":folderId/:page/:actionObj",
        name: "drive_shared_list_action"
      }
    ]
  },
  {
    path: "/drive/external/:externalType",
    name: "drive_external",
    component: () =>
      import(/* webpackChunkName: "driveExternal" */ "../views/DriveMain.vue"),
    children: [
      {
        path: ":tabId",
        name: "drive_external_list_by_tab"
      },
      {
        path: ":tabId/:folderId",
        name: "drive_external_list_by_folderId"
      },
      {
        path: "search/:actionObj",
        name: "drive_external_list_by_search"
      }
    ]
  },
  {
    path: "/drive",
    name: "drive",
    component: () =>
      import(/* webpackChunkName: "drive" */ "../views/DriveMain.vue"),
    children: [
      {
        path: ":folderId/:page",
        name: "drive_list"
      },
      {
        path: ":folderId/:page/:actionObj",
        name: "drive_list_action"
      }
    ]
  }
];
