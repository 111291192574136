import Vue from "vue";
import router from "@/commons/router";
import { getMailView, viewEmlFile } from "@/mail/api/mail.api";
import { getFullDate } from "@/commons/utils/moment";

const state = {
  mails: [],
  basedMail: {},
  totalElements: 0
};

// state, ??, rootState, rootGetters
const getters = {
  getViewMails: ({ mails }) => mails,
  getBasedMail: ({ basedMail }) => basedMail,
  getBasedMailId: ({ basedMail: bm }) => parseInt(bm?.mailId),
  getAttachPosition: ({ basedMail: bm }) => {
    const parsedPosition = parseInt(bm?.attachPosition);
    return isNaN(parsedPosition) ? -1 : parsedPosition;
  },
  requestParams: (x, y, { mailRoute }) => {
    const { mail_view } = mailRoute;
    const { list = {}, view = {} } = JSON.parse(mail_view.actionObj);
    const { sfi, owi, search } = list || {};
    const { basedId, selectedDataId, printable, attachPosition } = view || {};
    if (!basedId || !selectedDataId) return {};

    let [mailId] = `${selectedDataId}`.split("_ref_");
    mailId = parseInt(mailId);
    const includesReference = !printable;
    const basedMailId = parseInt(basedId);
    const isShare = !!(parseInt(sfi) && parseInt(owi));

    const params = { includesReference, basedMailId, fields: "", query: "" };
    if (isShare) {
      params["folderId"] = parseInt(sfi);
      params["ownerId"] = parseInt(owi);
    }

    // 검색한 경우 하이라이트 처리요청
    const {
      type,
      query,
      querySubjectContentAttach,
      querySubjectContent,
      querySubject,
      queryContent
    } = search || {};

    switch (type) {
      case "all":
      case "all.content":
        params["fields"] = "subject,content";
        params["query"] = query;
        break;
      case "detail":
        if (querySubjectContent || querySubjectContentAttach) {
          params["fields"] = "subject,content";
          params["query"] = querySubjectContent || querySubjectContentAttach;
        } else if (querySubject) {
          params["fields"] = "subject";
          params["query"] = querySubject;
        } else if (type == "detail" && queryContent) {
          params["fields"] = "content";
          params["query"] = queryContent;
        }
        break;

      default:
        break;
    }

    return { mailId, basedMailId, position: attachPosition, isShare, params };
  }
};

const mutations = {
  SET_MAIL: (state, mail) => {
    const index = state.mails.findIndex(m => m.mailId === mail.mailId);
    if (index === -1) return;

    Vue.set(state.mails, index, mail);
  },
  SET_MAILS: (state, { mails, basedMailId }) => {
    state.mails = mails;
    state.basedMail = mails.find(m => m.mailId == basedMailId);
  },
  RESET_VIEW_STATE: state => {
    state.mails = [];
    state.basedMail = {};
  },
  REMOVE_ATTACH_SIZE: (state, { mailId, position }) => {
    const mail = state.mails.find(m => m.mailId == mailId);
    if (!mail) return;

    const attach = mail.attachList.find(a => a.position == position);
    if (!attach) return;

    attach.size = 0;
  },
  SET_MAIL_VIEW_LOADING: (state, { mailId, loading }) => {
    const mail = state.mails.find(m => m.mailId == mailId);
    if (!mail) return;

    mail.loading = loading;
  },
  SET_MAIL_VIEW_TAGS: (state, { mailId, tags }) => {
    const mail = state.mails.find(m => m.mailId == mailId);
    if (!mail) return;

    mail.tags = tags;
  },
  SET_VIEW_IMPORTANT_STATUS: (state, { mails = [], rollback = false }) => {
    mails.forEach(({ mailId, nextImportantStatus, prevImportantStatus }) => {
      const index = state.mails.findIndex(mail => mail.mailId == mailId);
      if (index > -1) {
        state.mails[index].importantStatus = rollback
          ? prevImportantStatus
          : nextImportantStatus;
      }
    });
  },
  SET_VIEW_READ_STATUS: (state, { mails = [], rollback = false }) => {
    mails.forEach(({ mailId, nextReadStatus, prevReadStatus }) => {
      const index = state.mails.findIndex(mail => mail.mailId == mailId);
      if (index > -1) {
        state.mails[index].readStatus = rollback
          ? prevReadStatus
          : nextReadStatus;
      }
    });
  },
  SET_MAIL_TYPE: (state, { mailId, mailType }) => {
    const mail = state.mails.find(mail => mail.mailId == mailId);
    if (!mail) return;

    mail.mailType = mailType;
    if (mailType !== "REPORT_MAIL") {
      mail.content = "";
      mail.attachList = [];
    }
  },
  SET_UNBLOCK_MAIL: (
    state,
    { mailId, mailType, attachList = [], content, quoteContent }
  ) => {
    const mail = state.mails.find(mail => mail.mailId == mailId);
    if (!mail) return;

    mail.mailType = mailType;
    mail.attachList = attachList;
    mail.content = content;
    mail.quoteContent = quoteContent;
  }
};

const actions = {
  async getMailView({ dispatch, commit, getters }, id) {
    let { mailId, basedMailId: bId, isShare, params } = getters.requestParams;
    if (!mailId) return;
    if (id > 0) mailId = id;

    commit("SET_MAIL_VIEW_LOADING", { mailId, loading: true });
    params["includesReference"] = false;
    const { status, data } = await getMailView(isShare, mailId, params);
    if (status !== 200) return;

    const [v] = data;
    const regex = /@ACCESS_TOKEN@/g;
    const accessToken = localStorage.getItem("access_token");

    commit("SET_MAIL", {
      ...v,
      readStatus: 1,
      loading: false,
      dataId: v.mailId == bId ? v.mailId : `${v.mailId}_ref_${bId}`,
      from: v?.from[0],
      to: v?.to,
      cc: v?.cc,
      bcc: v?.bcc,
      subject: v?.subject,
      attachList: v?.attachList || [],
      content: v?.content?.replace(regex, accessToken),
      quoteContent: v?.quoteContent?.replace(regex, accessToken),
      receivedTimeMillis: getFullDate(v?.receivedTimeMillis || 0),
      readTimeMillis: getFullDate(v?.readTimeMillis || 0)
    });
    dispatch("changeReadStatus", { mails: data, mailId, isShare });
  },
  async viewEmlFile({ getters, commit }) {
    commit("RESET_VIEW_STATE");

    const { isShare, mailId, position, params } = getters.requestParams;
    const { status, data } = await viewEmlFile(
      isShare,
      mailId,
      position,
      params
    );
    if (status !== 200) {
      alert("페이지를 열 수 없습니다.");

      commit("mailRoute/RESET_ROUTE_VIEW", null, { root: true });
      commit("mailView/RESET_VIEW_STATE", null, { root: true });
      return router.push({ name: "mail" });
    }
    const regex = /@ACCESS_TOKEN@/g;
    const accessToken = localStorage.getItem("access_token");
    commit("SET_MAILS", {
      basedMailId: mailId,
      mails: [
        {
          ...data,
          readStatus: 1,
          loading: false,
          dataId: data.mailId,
          from: data?.from[0],
          to: data?.to,
          cc: data?.cc,
          bcc: data?.bcc,
          subject: data?.subject,
          attachList: data?.attachList || [],
          content: data?.content?.replace(regex, accessToken),
          quoteContent: data?.quoteContent?.replace(regex, accessToken),
          receivedTimeMillis: getFullDate(data?.receivedTimeMillis || 0)
        }
      ]
    });
  },
  async getMailViews({ dispatch, commit, getters }) {
    const { requestParams, getBasedMailId, getAttachPosition } = getters;
    const { mailId, basedMailId, position, isShare, params } = requestParams;
    if (position > -1) return await dispatch("viewEmlFile");
    if (
      (position ?? -1) === getAttachPosition &&
      (!mailId || basedMailId === getBasedMailId)
    ) {
      return;
    }

    commit("RESET_VIEW_STATE");

    const { status, data } = await getMailView(isShare, mailId, params);
    if (status !== 200) return;

    const regex = /@ACCESS_TOKEN@/g;
    const accessToken = localStorage.getItem("access_token");
    const mails = data.map(v => ({
      ...v,
      readStatus: 1,
      prevReadStatus: v.id == basedMailId ? 1 : v.readStatus,
      loading: false,
      dataId:
        v.mailId == basedMailId ? v.mailId : `${v.mailId}_ref_${basedMailId}`,
      from: v?.from[0],
      to: v?.to,
      cc: v?.cc,
      bcc: v?.bcc,
      subject: v?.subject,
      attachList: v?.attachList || [],
      content: v?.content?.replace(regex, accessToken),
      quoteContent: v?.quoteContent?.replace(regex, accessToken),
      receivedTimeMillis: getFullDate(v?.receivedTimeMillis || 0),
      readTimeMillis: getFullDate(v?.readTimeMillis || 0)
    }));

    commit("SET_MAILS", { mails, basedMailId });
    dispatch("changeReadStatus", { mails, mailId, isShare });
  },
  async changeReadStatus({ commit }, { mails, mailId, isShare }) {
    // cors 검사
    let _commit = commit;
    try {
      if (router.currentRoute.name === "popup_mail_view") {
        _commit = window.opener?.vxc || commit;
      }
    } catch (e) {
      _commit = commit;
    }

    // 읽음처리
    const readMails = mails
      .filter(m => m.mailId == mailId)
      .map(({ mailId, folderId, prevReadStatus }) => ({
        mailId,
        folderId,
        prevReadStatus,
        nextReadStatus: 1
      }));
    _commit("mail/SET_READ_STATUS", { mails: readMails }, { root: true });
    if (isShare) return;

    _commit(
      "folder/UPDATE_FOLDER_NEWCOUNT",
      { mails: readMails, readStatus: 1 },
      { root: true }
    );
  },
  // 이전메일보기
  async prevMail({ state, rootState, commit, dispatch }) {
    const { mailId: basedMailId } = state.basedMail;
    const { mail: mailVuex, mailRoute } = rootState;
    const { mail_list, mail_view } = mailRoute;
    if (mail_list.folderId != mail_view.folderId) return;

    const { length: mailListLength } = mailVuex.mails;
    let index = mailVuex.mails.findIndex(m => m.mailId == basedMailId);
    if (index == -1) return;
    index += 1;
    const loadNextPage = index >= mailListLength;

    if (loadNextPage) {
      if (mailVuex.totalPages == mail_list.page) {
        dispatch(
          "snackbar/openSnackbar",
          { message: "마지막 페이지입니다.", type: "ERROR" },
          { root: true }
        );
        return;
      }
      const mailListParams = {
        ...mail_list,
        page: parseInt(mail_list.page) + 1
      };
      await dispatch("mail/getList", mailListParams, { root: true });
      await commit(
        "mailRoute/SET_ROUTE_PARAMS",
        { mail_list: mailListParams },
        { root: true }
      );
      index = 0;
    }
    const { mailId: prevMailId, dataId: prevDataId } = mailVuex.mails[index];

    const params = {
      ...mail_view,
      page: loadNextPage ? parseInt(mail_view.page) + 1 : mail_view.page,
      basedId: prevMailId,
      selectedDataId: prevDataId
    };
    dispatch("mailRoute/routeMailView", params, { root: true });
  },
  async nextMail({ state, rootState, commit, dispatch }) {
    const { mailId: basedMailId } = state.basedMail;
    const { mail: mailVuex, mailRoute } = rootState;
    const { mail_list, mail_view } = mailRoute;
    if (mail_list.folderId != mail_view.folderId) return;

    const { length: mailListLength } = mailVuex.mails;
    let index = mailVuex.mails.findIndex(m => m.mailId == basedMailId);
    if (index == -1) return;
    index -= 1;
    const loadPrevPage = index < 0;
    if (loadPrevPage) {
      if (index == -1 && mail_list.page == 1) {
        dispatch(
          "snackbar/openSnackbar",
          { message: "마지막 메일입니다.", type: "ERROR" },
          { root: true }
        );
        return;
      }

      const mailListParams = {
        ...mail_list,
        page: parseInt(mail_list.page) - 1
      };
      await dispatch("mail/getList", mailListParams, { root: true });
      await commit(
        "mailRoute/SET_ROUTE_PARAMS",
        { mail_list: mailListParams },
        { root: true }
      );
      index = mailListLength - 1;
    }
    const { mailId: prevMailId, dataId: prevDataId } = mailVuex.mails[index];

    const params = {
      ...mail_view,
      page: loadPrevPage ? parseInt(mail_view.page) - 1 : mail_view.page,
      basedId: prevMailId,
      selectedDataId: prevDataId
    };
    dispatch("mailRoute/routeMailView", params, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
