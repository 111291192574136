import http from "@/commons/api/http";

const dashboardPrefix = "/api/approval/dashboard";
const formBoxPrefix = "/api/approval/form/box";
const formPrefix = "/api/approval/form";
const documentPrefix = "/api/approval/document";
const templatePrefix = "/api/approval/template";
const filePrefix = "/api/approval/file";
const boxPrefix = "/api/approval/box";
const opinionPrefix = "/api/approval/opinion";
const approvalLinePrefix = "/api/approval/appr-line";
const configPrefix = "/api/approval/config";
const exportPrefix = "/api/approval/export";

// 기안양식함 목록 조회
const getFormBoxList = (
  simple = false,
  { page = 0, size = 50, sort = "sortOrder", dir = "ASC" } = {}
) =>
  simple
    ? http.get(`${formBoxPrefix}/list/simple`, {
        params: { page, size, sort, dir }
      })
    : http.get(`${formBoxPrefix}/list`, {
        params: { page, size, sort, dir }
      });

// 기안양식 목록 조회
const getFormList = (
  simple = false,
  { boxId, page = 0, size = 50, sort = "sortOrder", dir = "ASC" } = {}
) =>
  simple
    ? http.get(`${formPrefix}/list/simple`, {
        params: { boxId, page, size, sort, dir }
      })
    : http.get(`${formPrefix}/list`, {
        params: { boxId, page, size, sort, dir }
      });

// 모든 기안양식 목록 조회
const getAllFormList = () => http.get(`${formPrefix}/list/all`);

// 기안 양식 단건 조회
const getFormById = formId => http.get(`${formPrefix}/${formId}`);

// 내부 기안 양식 조회
const getInnerForm = () => http.get(`${formPrefix}/inner`);

// 템플릿 단건 조회
const getTemplateById = templateId =>
  http.get(`${templatePrefix}/${templateId}`);

// 기안 상신
const draftDocument = payload => http.post(`${documentPrefix}/draft`, payload);

// 기안 임시저장
const saveDocument = payload =>
  http.post(`${documentPrefix}/draft/temp`, payload);

// 기안 회수
const retrieveDocument = docId =>
  http.put(`${documentPrefix}/${docId}/retrieve`);

// 기안 공개 유형 변경
const changeOpenType = (docId, openType) =>
  http.patch(`${documentPrefix}/${docId}/openType`, { openType });

// 파일 업로드
const uploadFile = (part, config) => {
  const formData = new FormData();
  formData.append("part", part);
  return http.post(filePrefix, formData, config);
};

// 문서 목록 조회
const getDocumentList = (
  path,
  { page, size, sort, dir },
  { draftFromDate, draftToDate, formId, keywordFlag, keyword } = {}
) => {
  // 요청 파라미터
  const params = {
    page,
    size,
    sort,
    dir,
    draftFromDate,
    draftToDate,
    formId,
    keywordFlag,
    keyword
  };
  return http.get(`${boxPrefix}/${path}`, { params });
};

// 문서 단건 조회
const getDocument = docId => http.get(`${documentPrefix}/${docId}`);

// 문서 단건 조회 (경량형 모델)
const getDocumentSimple = docId =>
  http.get(`${documentPrefix}/${docId}/simple`);

// 문서 삭제
const deleteDocument = docId => http.delete(`${documentPrefix}/${docId}`);

// 결재의견 목록 조회
const getOpinionList = docId => {
  return http.get(`${opinionPrefix}/list`, { params: { docId } });
};

// 결재의견 수정
const updateOpinion = (opinionId, comment) => {
  return http.put(opinionPrefix, { opinionId, comment });
};

// 결재 > 승인
const lineApprove = (lineId, preApproval, comment) => {
  return http.patch(`${approvalLinePrefix}/approve`, {
    lineId,
    preApproval,
    comment
  });
};

// 결재 > 반려
const lineReject = (lineId, preReject, comment) => {
  return http.patch(`${approvalLinePrefix}/reject`, {
    lineId,
    preReject,
    comment
  });
};

// 결재취소
const lineCancel = lineId => {
  return http.patch(`${approvalLinePrefix}/cancel`, { lineId });
};

// 수신확인
const lineReceive = lineId => {
  return http.patch(`${approvalLinePrefix}/receive`, { lineId });
};

// 반송
const lineReturn = (lineId, comment) => {
  return http.patch(`${approvalLinePrefix}/return`, { lineId, comment });
};

// 결재선 단건 조회
const getApprovalLine = lineId => {
  return http.get(`${approvalLinePrefix}/${lineId}`);
};

// 결재선 목록 조회
const getApprovalLines = docId => {
  return http.get(`${approvalLinePrefix}/list`, { params: { docId } });
};

// 상위 결재선 변경
const changeUpperLine = (docId, newUpperLines) => {
  return http.put(`${approvalLinePrefix}/upper`, {
    docId,
    newUpperLines
  });
};

// 공람자 추가
const addShareLine = (docId, newShareLines) => {
  return http.put(`${approvalLinePrefix}/share`, {
    docId,
    newShareLines
  });
};

// 결재자 존재 여부 체크
const validateApprover = (payload = {}) => {
  return http.post(`${approvalLinePrefix}/approver/validate`, payload);
};

// 결재자 존재 여부 체크 (여러명)
const validateApproverList = (payload = []) => {
  return http.post(`${approvalLinePrefix}/approver/list/validate`, payload);
};

// 기본설정 조회
const getBasicConfig = () => http.get(`${configPrefix}/basic`);

// 기본설정 수정
const updateBasicConfig = (payload = {}) => {
  return http.put(`${configPrefix}/basic`, payload);
};

// 문서담당중인 조직 목록 조회
const getManagedOrgans = () => http.get(`${configPrefix}/manager/managed/list`);

// 대시보드 현황 갯수 조회
const getDashboardCount = () => http.get(`${dashboardPrefix}/count`);

// 최근 기안 문서 목록 조회
const getRecentlyDraftDocumentList = ({ page, size }) => {
  return http.get(`${dashboardPrefix}/doc/draft/list`, {
    params: { page, size }
  });
};

// 결재할 문서 목록 조회
const getBeforeApproveDocumentList = ({ page, size }) => {
  return http.get(`${dashboardPrefix}/doc/approve/list`, {
    params: { page, size }
  });
};

// 최근 사용한 양식 목록 조회
const getRecentlyUseFormList = ({ page, size }) => {
  return http.get(`${dashboardPrefix}/form/list`, {
    params: { page, size }
  });
};

// 문서대장 내보내기 (엑셀)
const exportDocumentList = ({
  includeHeaders,
  sort,
  dir,
  draftFromDate,
  draftToDate,
  formId,
  keywordFlag,
  keyword
} = {}) => {
  return http.get(`${exportPrefix}/download/excel`, {
    responseType: "arraybuffer",
    params: {
      includeHeaders,
      sort,
      dir,
      draftFromDate,
      draftToDate,
      formId,
      keywordFlag,
      keyword
    }
  });
};

// 결재선 프리셋 목록 조회
const getApprLinePreset = presetId => {
  return http.get(`${configPrefix}/preset/appr-line/${presetId}`);
};

// 결재선 프리셋 목록 조회
const getApprLinePresetList = () => {
  return http.get(`${configPrefix}/preset/appr-line/list`);
};

// 결재선 프리셋 생성
const addApprLinePreset = payload => {
  return http.post(`${configPrefix}/preset/appr-line`, payload);
};

// 결재선 프리셋 수정
const updateApprLinePreset = (payload = {}) => {
  return http.put(`${configPrefix}/preset/appr-line/${payload.id}`, payload);
};

// 결재선 프리셋 이름변경
const renameApprLinePreset = (presetId, name) => {
  return http.put(`${configPrefix}/preset/appr-line/${presetId}/name`, {
    id: presetId,
    name
  });
};

// 결재선 프리셋 삭제
const deleteApprLinePreset = presetId => {
  return http.delete(`${configPrefix}/preset/appr-line/${presetId}`);
};

// 결재선 프리셋 일괄 삭제
const deleteApprLinePresetInBulk = (presetIds = []) => {
  return http.delete(`${configPrefix}/preset/appr-line/bulk`, {
    params: { presetIds }
  });
};

export default {
  getFormBoxList,
  getFormList,
  getAllFormList,
  getFormById,
  getInnerForm,
  getTemplateById,
  getDocumentList,
  draftDocument,
  saveDocument,
  retrieveDocument,
  changeOpenType,
  getDocument,
  getDocumentSimple,
  deleteDocument,
  getOpinionList,
  updateOpinion,
  lineApprove,
  lineReject,
  lineCancel,
  lineReceive,
  lineReturn,
  getApprovalLine,
  getApprovalLines,
  changeUpperLine,
  addShareLine,
  validateApprover,
  validateApproverList,
  uploadFile,
  getBasicConfig,
  updateBasicConfig,
  getManagedOrgans,
  getDashboardCount,
  getRecentlyDraftDocumentList,
  getBeforeApproveDocumentList,
  getRecentlyUseFormList,
  exportDocumentList,
  getApprLinePreset,
  getApprLinePresetList,
  addApprLinePreset,
  updateApprLinePreset,
  renameApprLinePreset,
  deleteApprLinePreset,
  deleteApprLinePresetInBulk
};
export {
  getFormBoxList,
  getFormList,
  getAllFormList,
  getFormById,
  getInnerForm,
  getTemplateById,
  draftDocument,
  saveDocument,
  retrieveDocument,
  changeOpenType,
  getDocumentList,
  getDocument,
  getDocumentSimple,
  deleteDocument,
  getOpinionList,
  updateOpinion,
  lineApprove,
  lineReject,
  lineCancel,
  lineReceive,
  lineReturn,
  getApprovalLine,
  getApprovalLines,
  changeUpperLine,
  addShareLine,
  validateApprover,
  validateApproverList,
  uploadFile,
  getBasicConfig,
  updateBasicConfig,
  getManagedOrgans,
  getDashboardCount,
  getRecentlyDraftDocumentList,
  getBeforeApproveDocumentList,
  getRecentlyUseFormList,
  exportDocumentList,
  getApprLinePreset,
  getApprLinePresetList,
  addApprLinePreset,
  updateApprLinePreset,
  renameApprLinePreset,
  deleteApprLinePreset,
  deleteApprLinePresetInBulk
};
