import Vue from "vue";
import { getMessage } from "@/flow/store/utils/messageUtils";
import { getInitialData, getTasks, getSubTasks } from "@/flow/api/task.api";

const state = {
  LIST: {
    // [groupId]: { ids: [], total: 0 }
  },
  GANTT: {},
  KANBAN: {
    REQUESTED: {},
    IN_PROGRESS: {},
    FEEDBACK: {},
    COMPLETED: {},
    ON_HOLD: {}
  },

  pageSize: 20,
  taskDataMap: {}
};

const getters = {
  LIST_total: ({ LIST }) =>
    Object.keys(LIST).reduce((cnt, key) => cnt + (LIST[key]?.total || 0), 0),
  LIST_tasks: ({ LIST, taskDataMap }) => {
    return Object.keys(LIST).reduce((acc, groupId) => {
      const tasks = [...new Set(LIST[groupId].ids)].map(id => taskDataMap[id]);
      return { ...acc, [groupId]: tasks.filter(t => t?.groupId == groupId) };
    }, {});
  },
  KANBAN_tasks: ({ KANBAN, taskDataMap }) => {
    return Object.keys(KANBAN).reduce((acc, status) => {
      const tasks = [...new Set(KANBAN[status].ids)].map(id => taskDataMap[id]);
      return { ...acc, [status]: tasks.filter(t => t?.status == status) };
    }, {});
  }
};

const mutations = {
  INIT_TASKS: (state, { viewType, data }) => {
    state.groups = data.groupList;
    Object.keys(data).forEach(key => {
      if (viewType === "LIST" && !parseInt(key)) return;
      if (viewType === "KANBAN" && !state.KANBAN[key]) return;

      const { content, total } = data[key];
      const ids = [];
      content.forEach(task => {
        ids.push(task.id);
        state.taskDataMap = { ...state.taskDataMap, [task.id]: task };
      });

      state[viewType][key] = { ids, total };
    });

    state[viewType] = { ...state[viewType] };
    state.loading = false;
  },
  SET_TASKS: (state, { viewType, key, total, content }) => {
    let ids = [];
    content.forEach(task => {
      ids.push(task.id);
      state.taskDataMap = { ...state.taskDataMap, [task.id]: task };

      if (viewType === "KANBAN" || !state.nextPageSelect) return;

      const index = state.checkedTasks.findIndex(t => t.id === task.id);
      if (index !== -1) return;

      // 추가로 넣어준만큼 빈값 제거 (infinity header Checkbox component 참고)
      state.checkedTasks.push(task);
      const idx = state.checkedTasks.findIndex(t => t.isEmpty);
      Vue.delete(state.checkedTasks, idx);
    });

    ids = [...(state[viewType][key]?.ids || []), ...ids];
    state[viewType][key] = { ids, total };
  },
  SET_SUB_TASKS: (state, { parentId, subTasks }) => {
    const ids = [];
    subTasks.forEach(task => {
      state.taskDataMap[task.id] = task;
      ids.push(task.id);
    });
    state.taskDataMap[parentId]["subTasks"] = ids;
  },
  RESET_TASKS: state => {
    // 공통
    state.loading = true;
    state.nextPageSelect = false;
    state.checkedTasks = [];
    state.openSubTasks = [];
    // 상세
    state.detailTaskId = null;
    state.isDetailCollapsed = true;
    // fetch
    state.taskDataMap = {};
    state.LIST = {};
    state.GANTT = {};
    state.KANBAN = {
      REQUESTED: {},
      IN_PROGRESS: {},
      FEEDBACK: {},
      COMPLETED: {},
      ON_HOLD: {}
    };
  }
};

const actions = {
  async genFetchParams({ state, rootGetters }) {
    const viewType = rootGetters["flowRoute/viewType"];
    const params = { pageSize: state.pageSize, viewType };
    params["assignedToMe"] = rootGetters["flowRoute/filterId"] === "my";
    params["requestedByMe"] = rootGetters["flowRoute/filterId"] === "request";
    params["title"] = rootGetters["flowRoute/searchWord"];

    return params;
  },
  async getInitialData({ rootState, commit, dispatch }) {
    const { projectId } = rootState.flowRoute;
    const params = await dispatch("genFetchParams");

    const { status, data } = await getInitialData(projectId, params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("INIT_TASKS", { viewType: params.viewType, data });
  },
  async getTasks(actions, { sortOrder, groupId, status }) {
    const { rootState, commit, dispatch } = actions;
    const { projectId } = rootState.flowRoute;
    let params = await dispatch("genFetchParams");
    params = { ...params, sortOrder, groupId, status };

    const { status: _status, data } = await getTasks(projectId, params);
    const message = getMessage(_status);
    if (message) return dispatch("snackbar", { message });
    commit("SET_TASKS", { viewType: params.viewType, ...data });
  },
  async getSubTasks({ rootState, commit, dispatch }, parentId) {
    const { projectId } = rootState.flowRoute;
    const { status, data: subTasks } = await getSubTasks(projectId, parentId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_SUB_TASKS", { parentId, subTasks });
  }
};

export default { state, getters, mutations, actions };
