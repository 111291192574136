export const ICON_TEXT = {
  ROOT: "mdi-account-multiple",
  TRASH: "mdi-trash-can",
  CUSTOMER: "mdi-folder",
  CUSTOMER_OPEN: "mdi-folder-open",
  CUSTOMER_REMOVED: "mdi-folder-remove"
};

export const GROUP_TYPES = {
  ROOT: "ROOT",
  TRASH: "TRASH",
  CUSTOMER: "CUSTOMER"
};

export const MEMO_DIALOG_TYPES = {
  VIEW: "VIEW",
  UPDATE: "UPDATE",
  ADD: "ADD"
};

export const searchTypes = {
  ALL: "ALL",
  DETAIL: "DETAIL"
};

export const handleError = (dispatch, message) => {
  dispatch("snackbar/openSnackbar", { message, type: "ERROR" }, { root: true });
  return false;
};

export const hasPermissionInChildren = groups => {
  return groups.some(node => {
    if (node.id > 0 && node.hasPermission) {
      return true; // 현재 노드가 권한을 가지면 true 반환
    }
    if (Array.isArray(node.children)) {
      return hasPermissionInChildren(node.children); // 자식 노드 배열에 대해 재귀 호출
    }

    return false; // 자식 노드가 없거나 권한이 없으면 false 반환
  });
};

export const CARD_HEADERS = [
  {
    text: "",
    value: "importantStatus",
    use: true,
    default: true,
    sortable: false,
    width: "44px",
    class: "px-1",
    cellClass: "px-1 importantStatus"
  },
  {
    text: "이름",
    value: "userName",
    use: true,
    default: true,
    sortable: true,
    width: "130px",
    class: "min-width"
  },
  {
    text: "이메일",
    value: "email",
    use: true,
    default: true,
    sortable: true,
    class: "min-width"
  },
  {
    text: "전화번호",
    value: "tel",
    use: true,
    sortable: true,
    width: "130px"
  },
  {
    text: "회사",
    value: "company",
    use: true,
    sortable: true,
    width: "100px"
  },
  {
    text: "부서",
    value: "organ",
    use: true,
    sortable: true,
    width: "80px"
  },
  {
    text: "직급",
    value: "position",
    use: true,
    sortable: true,
    width: "80px"
  },
  {
    text: "홈페이지",
    value: "url",
    use: true,
    width: "160px",
    sortable: false
  },
  {
    text: "메모",
    value: "memo",
    use: true,
    width: "80px",
    sortable: false
  },
  {
    text: "그룹",
    value: "contactGroups",
    use: true,
    width: "200px",
    sortable: false
  }
];
