<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      class="pt-0"
    >
      <!-- 버튼 -->
      <div
        class="pa-2 d-flex"
        :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''"
      >
        <v-btn
          class="mr-1 primary"
          large
          depressed
          width="118"
          @click="e => openBoardAddDialog(e)"
        >
          <h4 v-html="$t('board.105')"></h4>
        </v-btn>
        <v-btn
          large
          depressed
          width="118"
          class="v-btn-light"
          @click="e => openPostDialog(e)"
        >
          <h4 v-text="$t('board.글쓰기')"></h4>
        </v-btn>
      </div>
      <TreeView />
    </v-navigation-drawer>
  </div>
</template>

<script>
import TreeView from "@/board/views/components/treeview/TreeView";
import { mapGetters, mapMutations, mapActions } from "vuex";
import i18n from "@/_locales";

export default {
  components: {
    TreeView
  },
  data: () => ({
    drawer: true
  }),
  computed: {
    ...mapGetters("board", ["getActiveItems"])
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    // 게시판 추가 Dialog 열기
    openBoardAddDialog(e) {
      e.stopPropagation();

      this.SET_DIALOG({
        title: i18n.t("board.105"),
        type: "addBoard",
        params: {
          mode: "SAVE"
        }
      });
    },
    // 게시글 작성 Dialog열기
    openPostDialog(e) {
      e.stopPropagation();
      const [activeItem = {}] = this.getActiveItems;
      if (activeItem.id > 0) {
        if (activeItem.isDeleted) {
          return this.openSnackbar({
            message: i18n.t("board.78")
          });
        }

        if (activeItem.userPrivilege == "READ") {
          return this.openSnackbar({
            type: "ERROR",
            message: i18n.t("board.8")
          });
        }
      }
      this.SET_DIALOG({
        title: i18n.t("board.게시글"),
        type: "addPost"
      });
    }
  }
};
</script>

<style scoped>
.bottom_navi >>> .v-item-group {
  justify-content: left;
}
.cr-toggle {
  font-size: 10px;
}
.v-card--link:focus:before {
  opacity: 0;
}
.cr-tree-icon-subtext {
  font-size: 10px;
}
</style>
