import API from "@/approval/api/approval.api";

// state 초기값
const initialState = {
  count: {
    loading: false,
    beforeApproveCount: 0,
    beforeReceiveCount: 0,
    draftedCount: 0,
    rejectedCount: 0
  },
  toBeApproveList: {
    loading: false,
    page: 0,
    size: 5,
    totalElements: 0,
    documents: []
  },
  recentlyDraftList: {
    loading: false,
    page: 0,
    size: 6,
    totalElements: 0,
    documents: []
  },
  recentlyUseFormList: {
    loading: false,
    page: 0,
    size: 6,
    totalElements: 0,
    forms: []
  }
};

const state = { ...initialState };

const getters = {};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_COUNT: (state, count) => {
    state.count = {
      ...state.count,
      ...count
    };
  },
  SET_TO_BE_APPROVE_LIST: (
    state,
    { path, page, size, documents, totalElements }
  ) => {
    state.toBeApproveList = {
      ...state.toBeApproveList,
      path,
      page,
      size,
      totalElements,
      documents
    };
  },
  SET_RECENTLY_DRAFT_LIST: (
    state,
    { path, page, size, documents, totalElements }
  ) => {
    state.recentlyDraftList = {
      ...state.recentlyDraftList,
      path,
      page,
      size,
      totalElements,
      documents
    };
  },
  SET_RECENTLY_USE_FORM_LIST: (
    state,
    { path, page, size, forms, totalElements }
  ) => {
    state.recentlyUseFormList = {
      ...state.recentlyUseFormList,
      path,
      page,
      size,
      totalElements,
      forms
    };
  },

  SET_COUNT_LOADING: (state, loading) => {
    state.count = { ...state.count, loading };
  },
  SET_TO_BE_APPROVE_LIST_LOADING: (state, loading) => {
    state.toBeApproveList = { ...state.toBeApproveList, loading };
  },
  SET_RECENTLY_DRAFT_LIST_LOADING: (state, loading) => {
    state.recentlyDraftList = { ...state.recentlyDraftList, loading };
  },
  SET_RECENTLY_USE_FORM_LIST_LOADING: (state, loading) => {
    state.recentlyUseFormList = { ...state.recentlyUseFormList, loading };
  }
};

const actions = {
  init({ commit, dispatch, state }) {
    const { toBeApproveList, recentlyDraftList, recentlyUseFormList } = state;
    commit("RESET");
    dispatch("fetchCount");
    dispatch("fetchToBeApproveList", {
      page: toBeApproveList.page,
      size: toBeApproveList.size
    });
    dispatch("fetchRecentlyDraftList", {
      page: recentlyDraftList.page,
      size: recentlyDraftList.size
    });
    dispatch("fetchRecentlyUseFormList", {
      page: recentlyUseFormList.page,
      size: recentlyUseFormList.size
    });
  },
  // 결재 현황 갯수 불러오기
  async fetchCount({ commit, dispatch }) {
    commit("SET_COUNT_LOADING", true);
    const { status, data } = await API.getDashboardCount();
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재 현황 조회에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_COUNT_LOADING", false);
      return;
    }
    commit("SET_COUNT", data);
    commit("SET_COUNT_LOADING", false);
  },
  /**
   * 결재할 문서 목록 불러오기
   */
  async fetchToBeApproveList({ commit, dispatch }, { page, size }) {
    commit("SET_TO_BE_APPROVE_LIST_LOADING", true);
    const { status, data } = await API.getBeforeApproveDocumentList({
      page,
      size
    });
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "문서 목록을 불러오는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_TO_BE_APPROVE_LIST_LOADING", false);
      return;
    }
    const {
      content: documents,
      totalElements,
      number: resPage,
      size: resSize
    } = data;
    commit("SET_TO_BE_APPROVE_LIST", {
      documents,
      totalElements,
      page: resPage,
      size: resSize
    });
    commit("SET_TO_BE_APPROVE_LIST_LOADING", false);
  },
  /**
   * 최근 기안 문서 목록 불러오기
   */
  async fetchRecentlyDraftList({ commit, dispatch }, { page, size }) {
    commit("SET_RECENTLY_DRAFT_LIST_LOADING", true);
    const { status, data } = await API.getRecentlyDraftDocumentList({
      page,
      size
    });
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "문서 목록을 불러오는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_RECENTLY_DRAFT_LIST_LOADING", false);
      return;
    }
    const {
      content: documents,
      totalElements,
      number: resPage,
      size: resSize
    } = data;
    commit("SET_RECENTLY_DRAFT_LIST", {
      documents,
      totalElements,
      page: resPage,
      size: resSize
    });
    commit("SET_RECENTLY_DRAFT_LIST_LOADING", false);
  },
  /**
   * 최근 사용한 양식 불러오기
   */
  async fetchRecentlyUseFormList({ commit, dispatch }, { page, size }) {
    commit("SET_RECENTLY_USE_FORM_LIST_LOADING", true);
    const { status, data } = await API.getRecentlyUseFormList({ page, size });
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "최근 사용한 양식 목록을 불러오는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_RECENTLY_USE_FORM_LIST_LOADING", false);
      return;
    }
    const {
      content: forms,
      totalElements,
      number: resPage,
      size: resSize
    } = data;
    commit("SET_RECENTLY_USE_FORM_LIST", {
      forms,
      totalElements,
      page: resPage,
      size: resSize
    });
    commit("SET_RECENTLY_USE_FORM_LIST_LOADING", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
