<template>
  <!-- 주메뉴 drawer -->
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <div style="flex: 0 1 auto;">
      <!-- 이벤트 등록 버튼 -->
      <div class="pa-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
        <v-btn
          class="cr-tutorial-event-btn"
          color="primary"
          block
          large
          depressed
          @click="createEventOnFocusedDate"
        >
          <h4 v-text="$t('calendar.16')"></h4>
        </v-btn>
      </div>
      <v-date-picker
        v-model="picker"
        full-width
        class="mini-calendar"
        no-title
        reactive
        show-adjacent-months
        year-icon="mdi-calendar-blank"
        color="primary"
        :picker-date.sync="pickedDate"
        @click:month="date => pickerClick('month', date)"
        @click:date="date => pickerClick('day', date)"
        :day-format="getDatePickerDays"
        :events="datePickerEvents"
      />
    </div>
    <!-- 캘린더 목록
      네비게이션에 clipped 속성 적용 시 높이를 64px만큼 더 계산해야 합니다.
    -->
    <div
      style="flex: 1 1 auto; overflow-y: auto;"
      :style="{ marginBottom: $vuetify.breakpoint.lgAndUp ? '64px' : '' }"
    >
      <CalendarList />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import CalendarList from "@/calendar/components/calendar/CalendarList.vue";
import { getEvents } from "@/calendar/api/cal.api";

export default {
  components: {
    CalendarList
  },
  data: () => ({
    drawer: true,
    pickedDate: null,
    datePickerEvents: [],
    picker: moment().format("YYYY-MM-DD")
  }),
  watch: {
    pickedDate(newVal) {
      this.fetchPickerEvents(newVal);
    },
    getMyCalendars() {
      // 캘린더에 변화 발생 시 일정 다시 불러오기
      this.fetchPickerEvents(this.picker);
    },
    getCalendarFocus(newVal) {
      this.picker = newVal;
    },
    "$route.params": {
      handler: function({ focus }) {
        if (!focus) {
          return;
        }
        const lastDashIdx = focus.lastIndexOf("-");
        if (lastDashIdx > -1) {
          this.pickedDate = focus.substr(0, lastDashIdx);
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState("cal", ["createEventOnFocusedDate"]),
    ...mapGetters("cal", ["getMyCalendars"]),
    ...mapGetters("calRoute", ["getCalendarFocus"])
  },
  created() {
    this.$nextTick(() => {
      this.fetchPickerEvents(this.picker);
    });
  },
  methods: {
    ...mapMutations("calRoute", ["SET_ROUTE_FOCUS"]),
    // day 표시 방식
    getDatePickerDays(date) {
      return moment(date).date();
    },
    // 트리 상단 캘린더에 이벤트 존재여부 표시 위해 이벤트 로딩
    async fetchPickerEvents(date) {
      const pickerDate = moment(date);
      const params = {
        calendars: this.getMyCalendars,
        dtStart: pickerDate.startOf("month").valueOf(),
        dtEnd: pickerDate.endOf("month").valueOf()
      };

      const response = await getEvents(params);
      if (response.message) {
        return;
      }

      this.datePickerEvents = this.getScheduledDates(response.data);
    },
    getScheduledDates(events) {
      const result = [];
      for (const event of events) {
        const start = moment(event.detail.dtStart);
        const end = moment(event.detail.dtEnd);
        result.push(start.format("YYYY-MM-DD"));

        const diff = end.diff(start, "days");
        for (let i = 0; i < diff; i++) {
          result.push(start.add(1, "days").format("YYYY-MM-DD"));
        }
      }

      return result;
    },
    pickerClick(type, date) {
      if (type == "month") {
        date = date + "-01";
      }
      this.pushRoute(type, date);
    },
    pushRoute(type, focus) {
      // 미니 캘린더만 이동하고 오늘 버튼을 클릭하면
      // router 감지 안되서 오늘로 이동 안하는 현상 수정.
      this.SET_ROUTE_FOCUS(focus);
      this.$router.push({
        name: "cal_frame",
        params: {
          type: type || "month",
          focus: focus
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/simple-scrollbar.scss";

.v-navigation-drawer ::v-deep {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }
}

/* 주메뉴:달력 */
.v-picker ::v-deep {
  background-color: transparent;

  .v-picker__body {
    background-color: transparent;
  }
  .v-date-picker-header {
    padding: 0 16px;
  }
  .v-date-picker-header__value {
    flex: 0.6;
    font-size: 14px;
    text-align: left;
  }
  .v-date-picker-header .v-btn:first-child {
    position: absolute;
    top: 0;
    right: 52px;
  }
  .v-date-picker-header .v-btn:last-child {
    position: absolute;
    top: 0;
    right: 16px;
  }

  .v-date-picker-table__events > div.warning {
    background-color: #ef5350 !important;
    width: 5px;
    height: 5px;
    margin-bottom: 2px;
  }
}
</style>
