import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ko from "vuetify/src/locale/ko.ts";
import en from "vuetify/src/locale/en.ts";
import id from "vuetify/src/locale/id.ts";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  lang: {
    locales: { ko, en, id },
    current: "ko"
  },
  theme: {
    // style 에서 color 사용
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#FFA624",
        secondary: "#FF6E40",
        accent: "#F15C26",
        error: colors.red.accent3
      },

      // CUSTOM
      orange: {
        primary: "#FFA624",
        secondary: "#FF6E40",
        accent: "#F15C26",
        error: colors.red.accent3
      },
      blue: {
        primary: "#6a8fff",
        secondary: "#64B5F6",
        accent: "#1976D2",
        error: colors.red.accent3
      },
      red: {
        primary: "#F44336",
        secondary: "#FF5252",
        accent: "#FFA726",
        error: colors.red.accent3
      },
      pink: {
        primary: "#E91E63",
        secondary: "#F06292",
        accent: "#42A5F5",
        error: colors.red.accent3
      },
      purple: {
        primary: "#673AB7",
        secondary: "#512DA8",
        accent: "#2196F3",
        error: colors.red.accent3
      },
      green: {
        primary: "#8BC34A",
        secondary: "#9CCC65",
        accent: "#FF8A65",
        error: colors.red.accent3
      },
      teal: {
        primary: "#009688",
        secondary: "#00897B",
        accent: "#4DD0E1",
        error: colors.red.accent3
      },
      brown: {
        primary: "#795548",
        secondary: "#A1887F",
        accent: "#FFCA28",
        error: colors.red.accent3
      },
      tigris: {
        primary: "#009EFF",
        secondary: "#EEF6FF",
        accent: "#005BAE",
        error: colors.red.accent3
      },
      seegene: {
        primary: "#CC0000",
        secondary: "#FFFFFF",
        accent: "#000000",
        error: colors.red.accent3
      }
    }
  }
});
