<template>
  <v-btn icon small @click.stop="click">
    <v-icon :size="size">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isMdiCog() {
      const { folderType } = this.item;
      return ["TAG_ROOT"].includes(folderType);
    },
    icon() {
      if (this.isMdiCog) return "mdi-cog";
      return "";
    },
    size() {
      if (this.isMdiCog) return "18";
      return "";
    }
  },
  methods: {
    click() {}
  }
};
</script>
