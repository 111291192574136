import Vue from "vue";
import router from "@/commons/router";
import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  inviteMembers,
  resendInvitation,
  applyProject,
  resendApplication,
  updateFavoriteStatus,
  updateMemberAuthority,
  delegateProject,
  acceptProjectMember,
  approveProjectMembers,
  deleteProjectMembers,
  leaveProject
} from "@/flow/api/projectMember.api";

const state = {
  managedLoading: false,
  managedProjectId: null
};

const getters = {
  managedProject: ({ projects, managedProjectId }) => {
    return projects.find(p => p.id === managedProjectId) || {};
  },
  managedMembers: (state, getters) => {
    const { members } = getters.managedProject;
    return (members || []).filter(m => m.authority !== "MASTER");
  }
};

const mutations = {
  SET_MANAGED_PROJECT_ID: (state, projectId) => {
    state.managedLoading = false;
    state.managedProjectId = projectId;
  },
  SET_MANAGED_LOADING: (state, loading) => {
    state.managedLoading = loading;
  },
  UPDATE_FAVORITE_STATUS: (state, { id, sortOrder, favoriteStatus }) => {
    const idx = state.projects.findIndex(p => p.id == id);
    if (idx === -1) return;

    const project = state.projects[idx];
    Vue.set(state.projects, idx, { ...project, sortOrder, favoriteStatus });
  },
  UPDATE_MEMBER_AUTHORITY: (state, { projectId, memberId, authority }) => {
    const idx = state.projects.findIndex(p => p.id == projectId);
    if (idx === -1) return;

    const { members } = state.projects[idx];
    const memberIdx = members.findIndex(m => m.userId == memberId);
    if (memberIdx === -1) return;

    members[memberIdx].authority = authority;
  },
  UPDATE_JOIN_REQUEST_TIMEMILLIS: (state, member) => {
    const { projectId, userId, joinRequestTimeMillis } = member;
    const idx = state.projects.findIndex(p => p.id == projectId);
    if (idx === -1) return;

    const { members } = state.projects[idx];
    const memberIdx = members.findIndex(m => m.userId == userId);
    if (memberIdx === -1) return;

    members[memberIdx].joinRequestTimeMillis = joinRequestTimeMillis;
  },
  DELEGATE_PROJECT: (state, { projectId, memberId }) => {
    const idx = state.projects.findIndex(p => p.id == projectId);
    if (idx === -1) return;

    const project = state.projects[idx];
    const master = project.members.find(m => m.authority == "MASTER");
    const newMaster = project.members.find(m => m.userId == memberId);
    master.authority = "ADMIN";
    newMaster.authority = "MASTER";

    Vue.set(state.projects, idx, { ...project });
  },
  DELETE_MEMBER: (state, { projectId, userId }) => {
    const idx = state.projects.findIndex(p => p.id == projectId);
    if (idx === -1) return;

    const project = state.projects[idx];
    const members = [...project.members].filter(m => m.userId !== userId);
    Vue.set(state.projects, idx, { ...project, members });
  }
};

const actions = {
  async inviteMembers({ state, commit, dispatch }, { params, callback }) {
    const { managedProjectId: projectId } = state;
    const { status, data: project } = await inviteMembers(projectId, params);
    const message = getMessage(status);
    if (message) {
      dispatch("snackbar", { message });
      return callback(false);
    }

    callback(true);
    commit("SET_PROJECT", project);
    commit("flowFolder/SET_PROJECT", project, { root: true });
  },
  async resendInvitation({ state }, ids) {
    const { managedProjectId: projectId } = state;
    const { status, data } = await resendInvitation(projectId, ids);
    console.log("status, data = ", status, data);
    alert("resendInvitation");
  },
  async applyProject({ state, commit, dispatch }, projectId) {
    const callback = async () => {
      const { status, data } = await applyProject(projectId);
      const additionalMsg = { 409: "해당 프로젝트는 이미 참여 중입니다." };
      const message = getMessage(status, additionalMsg);
      if (message) {
        if (status === 409) dispatch("getProjectDetail", projectId);
        return dispatch("snackbar", { message });
      }

      const favoriteStatus = data.privateStatus ? 0 : 1;
      commit("SET_PROJECT", { ...data, favoriteStatus });
      if (favoriteStatus) {
        commit("flowFolder/ADD_PROJECT", data, { root: true });
      }
    };

    const project = state.projects.find(p => p.id == projectId);
    if (!project) return;
    if (!project.privateStatus) return callback();

    const message = `해당 프로젝트는 <b>"비공개"</b> 프로젝트입니다.<br/>프로젝트 운영자의 <b>"수락"</b> 후 참여 가능합니다.<br/>프로젝트 운영자에게 참여 신청을 하시겠습니까?`;
    return dispatch("confirm", { message, callback });
  },
  async resendApplication({ commit, dispatch }, projectId) {
    const { status, data } = await resendApplication(projectId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("UPDATE_JOIN_REQUEST_TIMEMILLIS", data);
  },
  async updateFavoriteStatus({ commit, dispatch }, params) {
    const { status, data: project } = await updateFavoriteStatus(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const mutation = params.favoriteStatus ? "ADD_PROJECT" : "DELETE_PROJECT";
    commit(`flowFolder/${mutation}`, project, { root: true });
    commit("UPDATE_FAVORITE_STATUS", project);
  },
  async updateMemberAuthority({ state, commit, dispatch }, params) {
    commit("SET_MANAGED_LOADING", true);
    params = { ...params, projectId: state.managedProjectId };
    const { status } = await updateMemberAuthority(params);
    commit("SET_MANAGED_LOADING", false);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("UPDATE_MEMBER_AUTHORITY", params);
  },
  async delegateProject({ commit, dispatch }, params) {
    const callback = async () => {
      const { status } = await delegateProject(params);
      const additionalMsg = { 409: "해당 멤버에게는 위임할 수 없습니다." };
      const message = getMessage(status, additionalMsg);
      if (message) return dispatch("snackbar", { message });

      commit("DELEGATE_PROJECT", params);
      commit("flowDialog/CLOSE_DIALOG", null, { root: true });
    };
    const closeCallback = () => {
      commit("flowDialog/SET_CONFIRMED", false, { root: true });
    };

    const message = `"${params.userName}" 님에게 프로젝트를 위임하시겠습니까?`;
    return dispatch("confirm", { message, callback, closeCallback });
  },
  async acceptProjectMember({ commit, dispatch }, projectId) {
    const { status, data: project } = await acceptProjectMember(projectId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_PROJECT", project);
  },
  async approveProjectMembers({ state, commit, dispatch }, memberIds) {
    commit("SET_MANAGED_LOADING", true);
    const params = { memberIds, projectId: state.managedProjectId };
    const { status, data: project } = await approveProjectMembers(params);
    commit("SET_MANAGED_LOADING", false);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_PROJECT", project);
  },
  async deleteProjectMembers({ state, commit, dispatch }, memberIds) {
    const callback = async () => {
      commit("SET_MANAGED_LOADING", true);
      const params = { memberIds, projectId: state.managedProjectId };
      const { status, data: project } = await deleteProjectMembers(params);
      commit("SET_MANAGED_LOADING", false);
      const message = getMessage(status);
      if (message) return dispatch("snackbar", { message });

      commit("SET_PROJECT", project);
    };

    let message = "해당 멤버들을 프로젝트에서 제외하겠습니까?";
    if (memberIds.length === 1) {
      message = "해당 멤버를 프로젝트에서 제외하겠습니까?";
    }
    return dispatch("confirm", { message, callback });
  },
  async leaveProject({ state, rootState, commit, dispatch }, projectId) {
    const callback = async () => {
      const { status } = await leaveProject(projectId);
      const message = getMessage(status);
      if (message) return dispatch("snackbar", { message });

      const { id: userId } = rootState.auth?.userInfo || {};
      commit("DELETE_MEMBER", { projectId, userId });
      const { name: routeName } = router.currentRoute;
      if (routeName === "flow_projects" && state.showMyProjects) {
        commit("DELETE_PROJECT", projectId);
      }
    };

    const message = `해당 프로젝트를 정말 나가시겠습니까?`;
    return dispatch("confirm", { message, callback });
  }
};

export default { state, getters, mutations, actions };
