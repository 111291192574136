<script>
import { mapActions } from "vuex";
import {
  connectExternalOAuth2,
  EXTERNAL_SERVICE
} from "@/commons/api/external/external.api";

export default {
  data: () => ({
    loading: true
  }),
  created() {
    this.setShowSplash(false);
    const { params, query } = this.$route;
    const { registrationId } = params;
    // registrationId: 연결 서비스
    // query: 외부 서비스가 인가 코드 리다이렉트 시 포함한 요청 매개변수
    if (!registrationId || query.error || !query.code) {
      this.showError(this.$t("login.외부_서비스.UNKNOWN"));
      return;
    }

    this.connect(registrationId, query);
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    showError(message) {
      const dispatch = window.opener?.vxd;
      if (!dispatch) return;

      dispatch(
        "snackbar/openSnackbar",
        { message: message, type: "ERROR" },
        { root: true }
      );

      window.close();
    },
    async connect(registrationId, query) {
      const { data } = await connectExternalOAuth2(registrationId, query);
      if (data.errors) {
        const errorKey = `login.외부_서비스.${data.errors[0].reason}`;
        this.showError(
          this.$te(errorKey)
            ? this.$t(errorKey)
            : this.$t("login.외부_서비스.UNKNOWN")
        );
        return;
      }

      const dispatch = window.opener?.vxd;
      const router = window.opener?.vr;
      if (!router || !dispatch) return;

      const { username, serviceType } = data;

      const currentRouteName = router.currentRoute.name;
      if (serviceType === EXTERNAL_SERVICE.googleCalendar.name) {
        if (currentRouteName === "cal" || currentRouteName === "cal_frame") {
          dispatch("extCal/syncExternalData", undefined, { root: true });
        } else {
          router.push({
            name: "cal",
            query: { connected_username: username }
          });
        }
      }

      dispatch(
        "snackbar/openSnackbar",
        { message: this.$t("login.외부_서비스.연결_성공"), type: "SUCCESS" },
        { root: true }
      );

      this.loading = false;
      window.close();
    }
  }
};
</script>

<template>
  <div
    class="d-flex align-center justify-center"
    style="height:100%; width:100%;"
  >
    <v-progress-circular
      v-if="loading"
      color="primary"
      indeterminate
      size="120"
      width="15"
    ></v-progress-circular>
  </div>
</template>
