import i18n from "@/_locales";
import { updateAwayConfig, getAwayData } from "@/mail/api/away.api";

const state = {
  useAway: 0,
  startTimeMillis: "",
  endTimeMillis: "",
  contents: "",
  updatedTimeMillis: ""
};

// state, getters, rootState, rootGetters
const getters = {
  useAway: ({ useAway }) => useAway,
  startTimeMillis: ({ startTimeMillis }) => startTimeMillis,
  endTimeMillis: ({ endTimeMillis }) => endTimeMillis,
  contents: ({ contents }) => contents,
  updatedTimeMillis: ({ updatedTimeMillis }) => updatedTimeMillis,
  config: state => ({ ...state })
};

// state값 수정
const mutations = {
  SET_DATA(
    state,
    { useAway, startTimeMillis, endTimeMillis, contents, updatedTimeMillis }
  ) {
    state.useAway = useAway;
    state.startTimeMillis = startTimeMillis;
    state.endTimeMillis = endTimeMillis;
    state.contents = contents;
    state.updatedTimeMillis = updatedTimeMillis;
  }
};

const actions = {
  /**
   * 초기 데이터
   */
  async getAwayData({ commit, dispatch }) {
    const { data, status } = await getAwayData();
    if (status === 200) return commit("SET_DATA", data);

    const message = { message: i18n.t("common.7"), type: "ERROR" };
    dispatch("snackbar/openSnackbar", message, { root: true });
  },
  /**
   * 수정
   */
  async updateAwayConfig({ commit, dispatch }, params) {
    const { data = {}, status } = await updateAwayConfig(params);

    let message = { message: i18n.t("mail.542"), type: "ERROR" };
    if (status == 201) {
      commit("SET_DATA", data);
      message = { message: i18n.t("mail.543"), type: "SUCCESS" };
    }

    dispatch("snackbar/openSnackbar", message, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
