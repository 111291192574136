import Vue from "vue";
import {
  getBoards,
  enterBoard,
  addBoard,
  updateBoardTitle,
  moveBoard,
  deleteBoard
} from "@/todo/api/board.api";

const boardTypeObj = { FOLDER: "폴더", BOARD: "보드" };
// 보드id를 가지고 해당 보드 찾기
const findBoard = (boards, boardId) => {
  for (let i = 0; i < boards.length; i += 1) {
    const board = boards[i];
    if (board.id == boardId) return board;

    if (board.children) {
      const childrenResult = findBoard(board.children, boardId);
      if (childrenResult) return childrenResult;
    }
  }
};

const state = {
  boards: [],
  openFolders: []
};

const getters = {
  isEmpty: ({ boards }) => boards.length == 0,
  currentBoard: ({ boards }, getters, { todoRoute }) =>
    findBoard(boards, todoRoute.boardId) ?? {},
  findBoard: ({ boards }) => boardId => findBoard(boards, boardId),
  boards: ({ boards }) => boards,
  openFolders: ({ openFolders }) => openFolders
};

const mutations = {
  RESET_STATE: state => {
    state.boards = [];
    state.openFolders = [];
  },
  SET_BOARDS: (state, boards) => {
    state.boards = boards;
  },
  SET_OPEN_FOLDERS: (state, openFolders) => {
    state.openFolders = openFolders;
  },
  ADD_BOARD: (state, { board, parentBoard }) => {
    if (board.parentId == 0) {
      state.boards.push(board);
      return;
    }
    if (!parentBoard) return;

    parentBoard.childCount += 1;
    if (Array.isArray(parentBoard.children)) {
      parentBoard.children.push(board);
      return;
    }

    parentBoard.children = [board];
  },
  UPDATE_BOARD: (state, { boardId, edit, title }) => {
    const board = findBoard(state.boards, boardId);
    if (board) {
      board["edit"] = edit ?? board.edit;
      board["title"] = title ?? board.title;
    }
  },
  DELETE_BOARD: (state, id) => {
    const board = findBoard(state.boards, id);
    if (!board) return;

    if (board.parentId == 0) {
      const index = state.boards.findIndex(b => b.id == id);
      Vue.delete(state.boards, index);
    } else {
      const parentBoard = findBoard(state.boards, board.parentId);
      if (!parentBoard || !Array.isArray(parentBoard?.children)) return;

      const index = parentBoard.children.findIndex(b => b.id == id);
      if (index > -1) {
        Vue.delete(parentBoard.children, index);
        parentBoard.childCount -= 1;
      }
    }
  }
};

const actions = {
  async getBoards({ commit, dispatch }, workspaceId) {
    const { data, status } = await getBoards(workspaceId);

    let message = "보드 목록을 받아오는데 실패했습니다.";
    let type = "ERROR";
    if (status == 200) {
      commit("SET_BOARDS", data);
      return;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async enterBoard({ commit, dispatch, rootState }, id) {
    const { organId } = rootState.auth.userInfo;
    const { data, status } = await enterBoard(id, organId);
    if (status == 200) {
      const {
        groups,
        header,
        items,
        organUserList = [],
        filters: savedFilters = [],
        defaultFilterId
      } = data;

      commit("todoGroup/SET_GROUPS", groups, { root: true });
      commit("todoHeader/SET_HEADER", header, { root: true });
      commit("todoItem/RESET_STATE", null, { root: true });
      commit("todoItem/SET_ITEMS", items, { root: true });
      commit("todoItem/SET_ORGAN_USERS", organUserList, { root: true });
      commit(
        "todoFilter/SET_SAVED_FILTERS",
        { savedFilters, defaultFilterId },
        { root: true }
      );
      return;
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: "보드 정보를 받아오는데 실패했습니다.", type: "ERROR" },
      { root: true }
    );
  },
  async addBoard(
    { state, commit, dispatch },
    { workspaceId, title, boardType, parentId, header, groups, items }
  ) {
    const params = { title, boardType, parentId, header, groups, items };
    const { data: board, status } = await addBoard(workspaceId, params);

    let message = `${boardTypeObj[boardType]}를 추가하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 201: {
        const parentBoard = findBoard(state.boards, parentId);
        commit("ADD_BOARD", { board, parentBoard });
        setTimeout(() => {
          // 상위 보드 존재시 열려있지 않다면 열어준다.
          if (parentBoard) {
            const index = state.openFolders.findIndex(f => f.id == parentId);
            if (index == -1) {
              commit("SET_OPEN_FOLDERS", [...state.openFolders, parentBoard]);
            }
          }

          // 타입이 보드일때 만들어진 보드로 이동시킨다.
          if (boardType === "BOARD") {
            dispatch(
              "todoRoute/routeBoard",
              { workspaceId, boardId: board.id },
              { root: true }
            );
          }
        }, 0);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateBoardTitle({ commit, dispatch }, { boardId, title, boardType }) {
    const { data, status } = await updateBoardTitle(boardId, title);

    let message = `${boardTypeObj[boardType]} 이름을 변경하는데 실패했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `${boardTypeObj[boardType]}가 존재하지 않습니다.`;
        break;
      case 201: {
        if (data) {
          commit("UPDATE_BOARD", { boardId, edit: false, title });
          return;
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async moveBoard(
    { commit, dispatch },
    { boardId, targetBoardId, position, boardType }
  ) {
    const { data, status } = await moveBoard({
      boardId,
      targetBoardId,
      position
    });

    let message = "순서변경에 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `${boardTypeObj[boardType]}가 존재하지 않습니다.`;
        break;
      case 201: {
        if (data) {
          commit("SET_BOARDS", data);
          return;
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteBoard({ commit, dispatch, rootState }, { boardId, boardType }) {
    const { data, status } = await deleteBoard(boardId);

    let message = `${boardTypeObj[boardType]}를 삭제하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `${boardTypeObj[boardType]}가 존재하지 않습니다.`;
        break;
      case 200: {
        if (data) {
          commit("DELETE_BOARD", boardId);
          if (boardId == rootState.todoRoute.boardId) {
            await dispatch(
              "todoRoute/routeWorkspace",
              { workspaceId: rootState.todoRoute.workspaceId },
              { root: true }
            );
          }

          return;
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
