import ApprovalLineUtils, { getKey } from "@/approval/utils/ApprovalLineUtils";

/**
 * ApprovalLines Class
 * 관련 유틸을 메서드 체이닝 방식으로
 * 사용 하고 싶다면 인스턴스 만들어 사용
 *
 * @see ApprovalLineUtils
 */
export class ApprovalLines {
  constructor(approvalLines) {
    this.approvalLines = approvalLines.map(line => {
      const key = getKey(line);
      return { ...line, key, approver: { ...line?.approver, key } };
    });
  }

  /** filter */
  filter(predicate) {
    this.approvalLines = this.approvalLines.filter(predicate);
    return this;
  }
  filterByType(...lineTypes) {
    this.approvalLines = ApprovalLineUtils.filterByType(
      this.approvalLines,
      ...lineTypes
    );
    return this;
  }
  filterByGroup(...lineGroups) {
    this.approvalLines = ApprovalLineUtils.filterByGroup(
      this.approvalLines,
      ...lineGroups
    );
    return this;
  }
  filterByStatus(...lineStatuses) {
    this.approvalLines = ApprovalLineUtils.filterByStatus(
      this.approvalLines,
      ...lineStatuses
    );
    return this;
  }

  /** sort */
  sort(compareFn) {
    this.approvalLines = this.approvalLines = compareFn
      ? this.approvalLines.sort(compareFn)
      : ApprovalLineUtils.sortAsc(this.approvalLines, "sortOrder");
    return this;
  }
  sortAsc(sortBy = "sortOrder") {
    this.approvalLines = ApprovalLineUtils.sortAsc(this.approvalLines, sortBy);
    return this;
  }
  sortDesc(sortBy = "sortOrder") {
    this.approvalLines = ApprovalLineUtils.sortDesc(this.approvalLines, sortBy);
    return this;
  }

  /** etc mutations */
  removeLine(key) {
    this.approvalLines = this.approvalLines.filter(line => line?.key !== key);
    return this;
  }

  /** getter */
  get() {
    return [...this.approvalLines];
  }
  getFirst() {
    return this.approvalLines.slice(0, 1)?.[0];
  }
  getLast() {
    return this.approvalLines.slice(-1)?.[0];
  }
  getCount() {
    return this.approvalLines.length;
  }
}
