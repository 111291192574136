<template>
  <v-list v-bind="$attrs">
    <v-list-item
      v-for="event in events"
      :key="event.id"
      :three-line="isOrganEvent"
      link
      @click.stop="$emit('click-item', $event, event)"
    >
      <v-list-item-icon
        class="mr-0"
        :style="{
          height: isOrganEvent ? '56px' : '38px'
        }"
      >
        <div
          class="event-color-box"
          :style="{ 'background-color': event.calendar.color }"
        ></div>
      </v-list-item-icon>
      <v-list-item-content>
        <!-- 부서 일정 정보 -->
        <v-list-item-subtitle v-if="isOrganEvent" class="text--primary">
          {{ event.calendar.title }}
          ·
          {{ event.source.owner.accountName }}
        </v-list-item-subtitle>
        <v-list-item-title class="font-weight-medium">
          {{ event.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-row>
            <!-- 시간 정보 -->
            <v-col cols="auto" class="d-flex align-center">
              <v-icon small class="mr-1">
                mdi-clock-time-four-outline
              </v-icon>
              <span>
                {{ getFormattedDateTimeText(event, dateOptions) }}
              </span>
            </v-col>
            <!-- 참석자 정보 -->
            <v-col
              v-if="hasAttendeeInfo(event)"
              cols="auto"
              class="d-flex align-center"
            >
              <v-icon small class="mr-1">
                mdi-account-multiple-outline
              </v-icon>
              <span>
                {{ getAttendeeString(event) }}
              </span>
            </v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { getFormattedDateTimeText } from "../../utils/DateUtils";

export default {
  props: {
    events: {
      type: Array,
      required: true
    },
    isOrganEvent: {
      type: Boolean,
      default: false
    },
    dateOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getFormattedDateTimeText,
    hasAttendeeInfo(event) {
      return event.detail?.attendees?.length > 0;
    },
    getAttendeeString(event) {
      return `${this.$t("calendar.주최자")} ${
        event.organizer.name ? event.organizer.name : event.organizer.email
      } · ${this.$t("calendar.64", {
        value: event.attendees.length
      })}`;
    }
  }
};
</script>

<style scoped lang="scss">
.event-color-box {
  height: 100%;
  width: 4px;
  border-radius: 2px;
}

.row {
  margin: 0;
}

.col {
  padding: 0 12px 0 0;
}
</style>
