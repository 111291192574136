<template>
  <div class="d-flex align-center">
    <v-text-field
      dense
      outlined
      hide-details
      class="cr-search-field"
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('mail.103')"
      v-model="_searchWord"
      @focus="color = 'primary'"
      @blur="color = 'grey lighten-2'"
      @keydown.enter="search"
    />
    <v-btn
      tile
      depressed
      height="40"
      class="cr-search-btn"
      :color="color"
      :outlined="color !== 'primary'"
      @click="search"
    >
      검색
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-search-field::v-deep {
  fieldset {
    border-right: none !important;
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    selectorType: {
      type: String,
      default: "contact"
    },
    searchWord: {
      type: String,
      default: ""
    }
  },
  data() {
    return { color: "grey lighten-2" };
  },
  computed: {
    _searchWord: {
      get() {
        return this.searchWord;
      },
      set(_searchWord) {
        this.$emit("update:searchWord", _searchWord);
      }
    }
  },
  watch: {
    selectorType() {
      this.$emit("update:searchWord", "");
    }
  },
  methods: {
    ...mapMutations("organ", ["SET_ACTIVE_NODE"]),
    ...mapActions("organ", [
      "getCardList",
      "getOrganUserList",
      "searchCustomerCardList"
    ]),
    search() {
      const { selectorType, searchWord } = this;

      this.SET_ACTIVE_NODE({ id: "search", name: this.$t("mail.검색") });

      switch (selectorType) {
        case "organ":
          this.getOrganUserList({ organId: "0", page: 1, searchWord });
          break;
        case "customer":
          this.searchCustomerCardList({
            customerGroupId: -1,
            page: 1,
            keyword: searchWord
          });
          break;
        default:
          this.getCardList({ contactGroupId: -4, page: 1, searchWord });
      }

      /*
      if (isOrgan) this.getOrganUserList({ organId: "0", page: 1, searchWord });
      else this.getCardList({ contactGroupId: -4, page: 1, searchWord });

       */
    }
  }
};
</script>
