<template>
  <v-text-field
    v-model="selectedItem.name"
    class="font-weight-regular cr-select"
    outlined
    hide-details
    readonly
    :height="height"
    :placeholder="label"
    clearable
    :append-icon="openMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
    @click="$listeners.click"
    @click:clear="$emit('update:selectedItem', {})"
    @click:append="$listeners.click"
  />
</template>

<style lang="scss" scoped>
.v-btn:before {
  opacity: 0 !important;
}
// 셀렉트처럼 보이는 부분
.cr-select ::v-deep {
  .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    .v-input__append-inner {
      margin-top: 0px;
      align-self: auto;
      .v-input__icon.v-input__icon--append {
        .v-icon {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  input {
    cursor: pointer;
  }
}
</style>
<script>
export default {
  props: {
    label: {
      default: "",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    },
    selectedItem: {
      default: () => {},
      type: Object
    },
    height: {
      default: 40,
      type: Number,
      description: "SELECTOR의 높이 설정"
    }
  },
  methods: {}
};
</script>
