<template>
  <v-btn
    tile
    text
    depressed
    :class="cls"
    :block="block"
    :outlined="outlined"
    @click="click"
  >
    <v-icon size="20" class="mr-1">mdi-plus</v-icon>
    워크스페이스 추가
  </v-btn>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    cls: {
      type: String,
      default: ""
    },
    block: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["SET_DIALOG"]),
    click() {
      this.SET_DIALOG({
        headline: "워크스페이스 만들기",
        show: true,
        type: "addWorkspace"
      });
    }
  }
};
</script>
