import { findBooking } from "@/resource/api/resource.api";
import i18n from "@/_locales";

const state = {
  show: false,
  x: 0,
  y: 0,
  resource: {},
  booking: {},
  selectedElement: null
};

const getters = {
  getShow: ({ show }) => show,
  getPositionX: ({ x }) => x,
  getPositionY: ({ y }) => y,
  getBooking: ({ booking }) => booking,
  getResource: ({ resource }) => resource,
  getSelectedElement: ({ selectedElement }) => selectedElement
};

const mutations = {
  SET_SHOW: (state, show) => (state.show = show),
  SET_POSITION: (state, { x, y }) => {
    state.x = x;
    state.y = y;
  },
  SET_RESOURCE: (state, resource) => (state.booking = resource),
  SET_BOOKING: (state, booking) => (state.booking = booking),
  SET_ELEMENT: (state, selectedElement) =>
    (state.selectedElement = selectedElement)
};

const actions = {
  async openResourceBookingView({ commit, dispatch }, { x, y, bookingId }) {
    if (!bookingId) {
      return;
    }

    const { status, data = {} } = await findBooking(bookingId);

    if (status != 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.1"), type: "ERROR" },
        { root: true }
      );
      return;
    }

    const resourceId = data.resourceId;
    const resource = await dispatch(
      "resource/findResourceById",
      { resourceId },
      { root: true }
    );
    data["resource"] = resource;
    await commit("SET_BOOKING", data);
    await commit("SET_POSITION", { x, y });
    await commit("SET_SHOW", true);
  },
  async closeResourceBookingView({ commit }) {
    await commit("SET_SHOW", false);
    await commit("SET_BOOKING", null);
    await commit("SET_POSITION", { x: 0, y: 0 });
    await commit("SET_RESOURCE", 0);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
