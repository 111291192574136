<template>
  <div class="cr-selector-wrapper" :style="`width: ${width};`">
    <!-- v-select closeOnContentClick 버그로인해 v-menu 이용 -->
    <v-menu
      ref="menu"
      offset-overflow
      offset-y
      disable-keys
      close-on-content-click
      v-model="openMenu"
    >
      <!-- 선택한 아이템의 이름 표시 부분 -->
      <template v-slot:activator="{ on: { click }, attrs }">
        <SelectFrame
          v-bind="attrs"
          v-on="$listeners"
          :height="height"
          :selectedItem="selectedItem"
          :openMenu="openMenu"
          :label="label"
          @click="click"
        />
      </template>

      <div style="position: relative; background: #fff; height: 300px;">
        <SearchInput
          ref="searchInput"
          :inputValue.sync="inputValue"
          :openMenu="openMenu"
          :useAddItem="useAddItem"
        />
        <List
          v-bind="$props"
          v-on="$listeners"
          ref="refList"
          :openMenu="openMenu"
          :inputValue="inputValue"
          :initItems="initItems"
        />
      </div>
    </v-menu>
  </div>
</template>

<script>
import SelectFrame from "./SelectFrame.vue";
import SearchInput from "./SearchInput.vue";
import List from "./list";
export default {
  components: { SelectFrame, SearchInput, List },
  data: () => ({
    openMenu: false,
    inputValue: ""
  }),
  props: {
    width: {
      default: "100%",
      type: String,
      description: "SELECTOR의 크기 설정"
    },
    height: {
      default: 40,
      type: Number,
      description: "SELECTOR의 높이 설정"
    },
    label: {
      default: "",
      type: String,
      description: "라벨 이름"
    },
    // TODO
    useAddItem: {
      default: false,
      type: Boolean,
      description: "검색 시 없을경우 생성 여부"
    },
    initItems: {
      description: "초기 아이템"
    },
    selectedItem: {
      type: Object,
      description: "선택한 아이템"
    }
  },
  watch: {
    openMenu(open) {
      this.inputValue = "";
      if (open) {
        setTimeout(() => {
          this.$refs.searchInput?.$refs?.textField?.focus();
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 전체
.cr-selector-wrapper {
  cursor: pointer;

  // 목록 호출 버튼
  button {
    width: 100%;
  }
}

// 목록 부분
.v-menu__content ::v-deep {
  border-radius: 0px;
  overflow-y: hidden;
}

// appbar에서 padding-left 8px일때 v-menu버그로인해 최소 left가 12px임
.v-menu__content.cr-appbar {
  left: 8px !important;
}
</style>
