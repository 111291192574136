<template>
  <v-data-table
    show-select
    fixed-header
    hide-default-footer
    v-model="selectedItems"
    :item-class="() => 'item-row'"
    :loading="getLoading"
    :headers="headers"
    :hide-default-header="hideHeader"
    :items="getList"
    :item-key="chooseDataTableKey"
    :items-per-page="getPageSize"
    :server-items-length="getTotalElements"
    @page-count="pageCount = $event"
    @item-selected="itemSelected"
    @toggle-select-all="selectAll"
    @click:row="(r, { select, isSelected }) => select(!isSelected)"
  >
    <template v-slot:[`header.name`]>
      <ListHeader v-bind="$props" v-on="$listeners" />
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <ListItem :item="item" v-bind="$props" />
    </template>
    <template v-slot:footer>
      <Pagination
        v-if="pageCount > 0"
        type="list"
        :page.sync="page"
        :pageSize="getPageSize"
        :blockSize="3"
        :totalElements="getTotalElements"
        @pageClick="pageClick"
      />
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
.v-data-table::v-deep {
  height: calc(100% - 84px);
  .v-data-table__wrapper {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    td,
    th {
      &.text-start {
        min-width: 45px !important;
        width: 45px;
        padding: 0px 0px 0px 12px;

        &.name {
          width: 350px;
          padding: 0px 0px 0px 4px;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import ListHeader from "@/mail/views/components/dialog/recipient/list/ListHeader.vue";
import ListItem from "@/mail/views/components/dialog/recipient/list/ListItem.vue";
import Pagination from "@/commons/views/Pagination";

export default {
  components: { ListHeader, ListItem, Pagination },
  props: {
    selectedListAttendee: {
      type: Array,
      default: () => []
    },
    selectorType: {
      type: String,
      default: "contact"
    },
    searchWord: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      selectedItems: [],
      headers: [
        {
          text: "이름",
          value: "name",
          class: "name",
          cellClass: "name",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters("organ", [
      "activeNode",
      "getPage",
      "getList",
      "getTotalElements",
      "getLoading",
      "getPageSize"
    ]),
    chooseDataTableKey() {
      switch (this.selectorType) {
        case "organ":
          return "userId";
        case "customer":
          return "customerCardId";
        default:
          return "contactId";
      }
    },
    hideHeader() {
      // 고객 주소록에서는 전체 주소록의 개념이 없기 때문에
      // 최소 1개의 그룹을 선택해야만 헤더 표시
      return (
        this.selectorType === "customer" &&
        Object.keys(this.activeNode).length === 0
      );
    }
  },
  watch: {
    getPage(page) {
      this.page = page;
    },
    getList() {
      this.selectedItems = [];
      this.syncSelectedItems();
    }
  },
  methods: {
    ...mapActions("organ", [
      "getCardList",
      "getOrganUserList",
      "searchCustomerCardList"
    ]),
    async pageClick(page) {
      let { id } = this.activeNode;
      const { selectorType, searchWord } = this;
      if (id === "search") {
        this.selectorType === "organ"
          ? (id = "0")
          : this.selectorType === "customer"
          ? (id = -1)
          : (id = -4);
      }
      switch (selectorType) {
        case "organ":
          await this.getOrganUserList({ organId: id, page, searchWord });
          break;
        case "customer":
          await this.searchCustomerCardList({
            customerGroupId: id,
            page,
            keyword: searchWord
          });
          break;
        default:
          await this.getCardList({ contactGroupId: id, page, searchWord });
      }
    },
    equalsValue(value1, value2) {
      const regex = /[\s"<>]/g;
      return value1.replace(regex, "") === value2.replace(regex, "");
    },
    syncSelectedItems() {
      const selectedAttendee = [...this.selectedListAttendee];
      if (!selectedAttendee.length) return;

      selectedAttendee.forEach(r => {
        const attendee = this.getList.find(i =>
          this.equalsValue(`${i.name}${i.email}`, r.value)
        );

        if (attendee) this.selectedItems.push(attendee);
      });
    },
    // 선택, 전체선택 (해제)
    selectAll({ items, value: isSelected }) {
      items.forEach(item => this.itemSelected({ item, value: isSelected }));
    },
    itemSelected({ item: r, value: isSelected }) {
      if (isSelected) {
        return this.$emit("addAttendee", r);
      }
      this.$emit("removeAttendee", r);
    }
  }
};
</script>
