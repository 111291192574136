let dragInfo = {};
const dragAndDrop = {
  //el, binding, vnode
  componentUpdated: async function(rootEl, binding, vnode) {
    const { getters } = vnode.context.$store;
    if (await getters["dragDrop/dragMode"]) {
      binding.value?.genDropzone && binding.value?.genDropzone(dragInfo);
    }
  },
  inserted: async function(rootEl, binding, vnode) {
    const { commit, getters } = vnode.context.$store;
    await commit("dragDrop/SET_DRAG_MODE", false);
    const { DRAG_COMP } = binding.value || {};
    let helper = null;

    rootEl.draggable = true;
    rootEl.addEventListener("dragstart", async function(e) {
      e.stopPropagation();
      e.preventDefault();

      if (helper && helper.style.display != "block") {
        Object.assign(helper.style, { display: "block", zIndex: "999" });
        // dragstart 함수 호출
        binding.value?.dragstart &&
          binding.value?.dragstart(e, obj => (dragInfo = obj), dragInfo);
        // 드래그모드 on
        await commit("dragDrop/SET_DRAG_MODE", true);
        await commit("dragDrop/SET_DRAG_COMP", DRAG_COMP);
        // 드랍영역 생성 함수 호출
        binding.value?.genDropzone && binding.value?.genDropzone(dragInfo);

        // tinymce 에디터 존재시 가림막
        const editorIfr = document.getElementById("tiny_ifr");
        if (editorIfr) {
          const div = document.createElement("div");
          div.id = "editorGuard";
          Object.assign(div.style, {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          });
          editorIfr.parentNode.appendChild(div);
        }
      }
    });
    // 헬퍼 생성 / 이동 / 제거
    rootEl.addEventListener(
      "mousedown",
      function(e) {
        helper = binding.value?.genHelper(e, obj => (dragInfo = obj));
        if (!helper) return;

        const crHelper = document.getElementById("crHelper");
        if (crHelper && crHelper.parentNode) {
          crHelper.parentNode.removeChild(crHelper);
        }
        helper.id = "crHelper";
        Object.assign(helper.style, { position: "absolute", display: "none" });

        document.querySelector(".v-application--wrap").appendChild(helper);
        // document.body.appendChild(helper);

        // 헬퍼 이동 함수
        const moveHelper = async function(e) {
          if (!(await getters["dragDrop/dragMode"])) return;

          // 드랍가능 영역 표시함수 호출
          binding.value?.markDropzone &&
            binding.value?.markDropzone(e, dragInfo);

          // 헬퍼가 마우스 가리면 안됨
          helper.style.left = e.clientX + 10 + "px";
          helper.style.top = e.clientY + 10 + "px";
        };

        // 헬퍼, dropzone 제거 함수
        const removeHelper = async function(e) {
          document.body.removeEventListener("mousemove", moveHelper);
          document.body.removeEventListener("mouseup", removeHelper);

          if (helper && helper.parentNode) {
            helper.parentNode.removeChild(helper);
            helper = null;
          }
          binding.value.mouseup && binding.value.mouseup(e, dragInfo);
          await commit("dragDrop/SET_DRAG_MODE", false);
          await commit("dragDrop/SET_DRAG_COMP", "");

          // editorGuard 존재시 제거
          const editorGuard = document.getElementById("editorGuard");
          if (editorGuard && editorGuard.parentNode) {
            editorGuard.parentNode.removeChild(editorGuard);
          }
        };

        // 이벤트 바인딩
        document.body.addEventListener("mousemove", moveHelper);
        document.body.addEventListener("mouseup", removeHelper);
      },
      true
    );
  }
};

export default dragAndDrop;
