import {
  EXTERNAL_SERVICE,
  fetchConnections
} from "@/commons/api/external/external.api";
import {
  fetchExternalCalendars,
  fetchExternalEvents,
  updateExternalCalendar
} from "@/calendar/api/externalCal.api";
import { wrapExternalCalendar } from "@/calendar/utils/CalendarUtils";
import { wrapGoogleEvents } from "@/calendar/utils/EventUtils";
import DomainType from "@/commons/constants/DomainType";
import i18n from "@/_locales";
import moment from "moment/moment";
import Vue from "vue";

/*
 * 외부( 구글 ) 캘린더 상태를 관리하는 모듈입니다.
 * 외부 일정의 경우 API 호출을 줄이기 위해 사전 가져오기는 수행하지 않습니다.
 */

const state = {
  connections: [],
  monthlyEventCache: {}
};

const getters = {
  getExternalEventsInDateRange: (state, getters, rootState) => {
    if (rootState.cal.currentStartDt === 0 || rootState.cal.currentEndDt === 0)
      return [];

    const result = new Map();
    const collectEvents = month => {
      (state.monthlyEventCache[month]?.events || []).forEach(event => {
        // 4일/주 단위 보기 시 VCalendar 최적화를 위해 기간 내에 포함되는 이벤트만 필터링
        if (
          event.start.getTime() <= rootState.cal.currentEndDt &&
          event.end.getTime() >= rootState.cal.currentStartDt
        ) {
          // 이벤트 기간이 여러 달에 걸치는 경우 각 월에 이벤트가 중복으로 존재하기 때문에
          // 이벤트 ID + 시작 시간을 기준으로 중복을 제거합니다.
          result.set(event.id + event.start.getTime(), event);
        }
      });
    };

    const startMonth = moment(rootState.cal.currentStartDt).format("YYYY-MM");
    const endMonth = moment(rootState.cal.currentEndDt).format("YYYY-MM");

    collectEvents(startMonth);
    if (startMonth !== endMonth) {
      collectEvents(endMonth);
    }

    return Array.from(result.values());
  },
  hasCachedEvents: state => month => {
    return (
      state.monthlyEventCache[month] && !state.monthlyEventCache[month].fetching
    );
  },
  hasValidCachedEvents: state => (month, validTime = 60000) => {
    return (
      state.monthlyEventCache[month] &&
      !state.monthlyEventCache[month].fetching &&
      // 이벤트가 캐싱된지 일정 시간( 기본 1분 ) 지난 경우 유효하지 않은 데이터로 판단
      Date.now() - state.monthlyEventCache[month].lastSyncTime < validTime
    );
  }
};

const mutations = {
  SET_EXTERNAL_CONNECTIONS(state, connections) {
    state.connections = connections;
  },
  SET_EXTERNAL_CONNECTIONS_LOADED(state, status) {
    state.connections.forEach(connection => {
      connection.loaded = status;
    });
  },
  SET_EXTERNAL_CONNECTION(state, connection) {
    const idx = state.connections.findIndex(item => item.id === connection.id);
    if (idx < 0) return;

    state.connections.splice(idx, 1, connection);
  },
  SET_EXTERNAL_CONNECTION_ERROR(state, { connection, errors }) {
    let errorCode = "UNKNOWN";
    if (errors[0].domain === DomainType.EXTERNAL) {
      errorCode = errors[0].reason;
    }
    const errorKey = `calendar.외부_오류.${errorCode}`;

    const idx = state.connections.findIndex(item => item.id === connection.id);
    if (idx < 0) return;

    state.connections.splice(idx, 1, {
      ...connection,
      errorMessage: i18n.te(errorKey)
        ? i18n.t(errorKey)
        : i18n.t("calendar.외부_오류.UNKNOWN")
    });
  },
  SET_MONTHLY_EVENTS(state, { month, eventsInfo }) {
    Vue.set(state.monthlyEventCache, month, eventsInfo);
  },
  /**
   * 대상 월에 속하지 않는 이벤트를 캐시에서 삭제합니다.
   * @param months 삭제에서 제외할 월 목록. 없으면 전체 삭제
   */
  DELETE_EVENTS_EXCLUDING_MONTHS: (state, months) => {
    if (!months?.length) state.monthlyEventCache = {};

    Object.keys(state.monthlyEventCache).forEach(month => {
      if (!month.includes(month)) {
        delete state.monthlyEventCache[month];
      }
    });
  },
  /**
   * 캘린더 ID가 일치하는 이벤트를 캐시에서 삭제합니다.
   */
  DELETE_EVENTS_IN_CALENDAR: (state, calendarId) => {
    const months = Object.keys(state.monthlyEventCache);
    for (const month of months) {
      const filteredEvents = state.monthlyEventCache[month].events.filter(
        event => event.calendar.id !== calendarId
      );

      if (
        state.monthlyEventCache[month].events.length !== filteredEvents.length
      ) {
        state.monthlyEventCache[month].events = filteredEvents;
      }
    }
  },
  /**
   * 이벤트 캐시가 저장 한도를 넘은 경우 현재 조회 범위 밖의 이벤트부터 삭제합니다.
   */
  DELETE_OUTSIDE_EVENTS: (state, { currentStartDt, currentEndDt }) => {
    const MAX_MONTHS = 4;
    const start = moment(currentStartDt);
    const end = moment(currentEndDt);
    const monthOfViewRange = [
      start.format("YYYY-MM"),
      end.format("YYYY-MM"),
      start.subtract(1, "month").format("YYYY-MM"),
      end.add(1, "month").format("YYYY-MM")
    ];

    const months = Object.keys(state.monthlyEventCache);
    if (months.length <= MAX_MONTHS) return;

    let itemsToDeleteCount = months.length - MAX_MONTHS;
    for (let i = 0; i < months.length; i++) {
      // 현재 조회 범위 근처의 월은 삭제하지 않습니다.
      if (!monthOfViewRange.includes(months[i])) {
        delete state.monthlyEventCache[months[i]];
        if (--itemsToDeleteCount === 0) break;
      }
    }
  }
};

const actions = {
  async syncExternalData({ commit, dispatch }) {
    commit("DELETE_EVENTS_EXCLUDING_MONTHS");

    await dispatch("fetchGoogleCalendarConnections");
    await dispatch("fetchExternalCalendars", true);
    await dispatch("loadExternalEvents");

    commit("SET_EXTERNAL_CONNECTIONS_LOADED", true);
  },
  async loadExternalEvents({ commit, dispatch, rootState }) {
    if (!state.connections.length) {
      await dispatch("fetchGoogleCalendarConnections");
      if (!state.connections.length) return;

      await dispatch("fetchExternalCalendars", false);
    }
    commit("SET_EXTERNAL_CONNECTIONS_LOADED", false);

    const startMonth = moment(rootState.cal.currentStartDt).format("YYYY-MM");
    const endMonth = moment(rootState.cal.currentEndDt).format("YYYY-MM");

    await dispatch("fetchAndUpdateEventsIfNeeded", startMonth);

    // 4일/주 보기는 시작, 종료 월이 다를 수 있습니다. 이 경우 종료 월의 이벤트까지 조회합니다.
    if (startMonth !== endMonth) {
      await dispatch("fetchAndUpdateEventsIfNeeded", endMonth);
    }

    commit("SET_EXTERNAL_CONNECTIONS_LOADED", true);
  },
  async fetchGoogleCalendarConnections({ commit }) {
    const response = await fetchConnections(
      EXTERNAL_SERVICE.googleCalendar.name
    );
    if (!response.message) {
      commit(
        "SET_EXTERNAL_CONNECTIONS",
        response.data.map(connection => {
          const { id, username, email } = connection;
          return { id, username, email, loaded: false };
        })
      );
    }
  },
  async fetchExternalCalendars({ commit }, sync) {
    for (const connection of state.connections) {
      const response = await fetchExternalCalendars(connection.id, sync);
      if (response.message) {
        commit("SET_EXTERNAL_CONNECTION_ERROR", {
          connection,
          errors: response.data?.errors
        });

        continue;
      }

      commit("SET_EXTERNAL_CONNECTION", {
        ...connection,
        calendars: response.data.map(calendar => ({
          connection,
          ...wrapExternalCalendar(calendar)
        }))
      });
    }
  },
  /**
   * 이벤트의 캐싱 상태에 따라 이벤트를 조회, 혹은 갱신합니다.
   */
  async fetchAndUpdateEventsIfNeeded({ getters, dispatch }, month) {
    /*
     * - 캐시가 있지만 유효하지 않으면 비동기 조회하여 데이터 최신화
     * - 캐시가 없지만 조회 중인 작업이 있으면 대기, 없으면 동기 조회
     * - 유효한 캐시가 있는 경우 조회하지 않음
     */
    if (
      getters.hasCachedEvents(month) &&
      !getters.hasValidCachedEvents(month)
    ) {
      dispatch("fetchAndCacheMonthlyEvents", {
        month: month
      });
    } else if (!getters.hasCachedEvents(month)) {
      if (state.monthlyEventCache[month]) {
        await state.monthlyEventCache[month].fetching;
      } else {
        await dispatch("fetchAndCacheMonthlyEvents", {
          month: month
        });
      }
    }
  },
  async fetchAndCacheMonthlyEvents({ commit, dispatch, rootState }, { month }) {
    const date = moment(month);
    commit("SET_MONTHLY_EVENTS", {
      month,
      eventsInfo: {
        fetching: dispatch("fetchAllExternalEvents", {
          dtStart: date.startOf("month").valueOf(),
          dtEnd: date.endOf("month").valueOf()
        }),
        // 이전 데이터가 있으면 조회되기 전까지 유지
        lastSyncTime: state.monthlyEventCache[month]
          ? state.monthlyEventCache[month].lastSyncTime
          : 0,
        events: state.monthlyEventCache[month]
          ? state.monthlyEventCache[month].events
          : []
      }
    });

    const events = await state.monthlyEventCache[month].fetching;
    commit("SET_MONTHLY_EVENTS", {
      month,
      eventsInfo: {
        fetching: null,
        lastSyncTime: Date.now(),
        events
      }
    });

    commit("DELETE_OUTSIDE_EVENTS", {
      currentStartDt: rootState.cal.currentStartDt,
      currentEndDt: rootState.cal.currentEndDt
    });
  },
  async fetchAllExternalEvents({ commit }, { dtStart, dtEnd }) {
    const result = [];
    for (const connection of state.connections) {
      if (connection.errorMessage) continue;

      const response = await fetchExternalEvents(connection.id, dtStart, dtEnd);
      if (response.message) {
        commit("SET_EXTERNAL_CONNECTION_ERROR", {
          connection,
          errors: response.data?.errors
        });

        continue;
      }

      result.push(
        ...wrapGoogleEvents(connection.calendars, response.data, dtStart, dtEnd)
      );
    }

    return result;
  },
  async fetchAndMergeCalendarEvents({ dispatch, rootState }, { calendar }) {
    const startMonth = moment(rootState.cal.currentStartDt).format("YYYY-MM");
    const endMonth = moment(rootState.cal.currentEndDt).format("YYYY-MM");

    await dispatch("fetchExternalEvents", { calendar, month: startMonth });

    if (startMonth !== endMonth) {
      await dispatch("fetchExternalEvents", { calendar, month: endMonth });
    }

    // 비동기로 캐싱된 월까지 조회 후 병합
    const cachedMonths = Object.keys(state.monthlyEventCache).filter(
      month => month !== startMonth && month !== endMonth
    );
    for (const month of cachedMonths) {
      dispatch("fetchExternalEvents", { calendar, month });
    }
  },
  async fetchExternalEvents({ commit }, { calendar, month }) {
    const date = moment(month);
    const start = date.startOf("month").valueOf();
    const end = date.endOf("month").valueOf();

    const response = await fetchExternalEvents(
      calendar.connection.id,
      start,
      end,
      [calendar.id]
    );

    if (response.message) {
      commit("SET_EXTERNAL_CONNECTION_ERROR", {
        connection: calendar.connection,
        errors: response.data?.errors
      });

      return;
    }

    const events = wrapGoogleEvents([calendar], response.data, start, end);
    commit("SET_MONTHLY_EVENTS", {
      month,
      eventsInfo: {
        fetching: null,
        lastSyncTime: Date.now(),
        events: state.monthlyEventCache[month].events.concat(events)
      }
    });
  },
  async updateExternalCalendarSelection(
    { commit, dispatch },
    { calendar, value }
  ) {
    await updateExternalCalendar(calendar.id, value);
    await dispatch("fetchExternalCalendars");

    if (!value) {
      commit("DELETE_EVENTS_IN_CALENDAR", calendar.id);
    } else {
      await dispatch("fetchAndMergeCalendarEvents", { calendar });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
