const fileIcons = {
  folder: "mdi-folder",
  share: "mdi-folder-account",
  pdf: "mdi-file-pdf-box",
  xlsx: "mdi-file-excel",
  jpg: "mdi-file-image",
  png: "mdi-image",
  pptx: "mdi-file-powerpoint",
  zip: "mdi-folder-zip",
  docx: "mdi-file-word",
  sheet: "mdi-google-spreadsheet",
  presentation: "mdi-file-presentation-box",
  form: "mdi-list-box",
  txt: "mdi-file-document",
  video: "mdi-video-box",
  file: "mdi-file"
};

const fileIconColors = {
  pdf: "red darken-2",
  xlsx: "green",
  sheet: "green",
  jpg: "red",
  png: "red",
  pptx: "red",
  presentation: "yellow darken-2",
  zip: "brown darken-4",
  docx: "blue",
  form: "deep-purple",
  video: "red",
  txt: "blue",
  file: "blue"
};

const mailboxIcons = {
  ALL: "mdi-mailbox",
  IMPORTANT: "mdi-star",
  UNREAD: "mdi-email",
  READ: "mdi-email-open",
  ATTACH: "mdi-paperclip",
  INBOX: "mdi-inbox",
  TOME: "mdi-note",
  SENT: "mdi-send",
  DRAFTS: "mdi-file",
  RESERVE: "mdi-clock-time-seven",
  DELETED: "mdi-trash-can",
  SHARED_ROOT: "mdi-account-arrow-left",
  SHARING_ROOT: "mdi-account-arrow-right",
  SEARCH_ROOT: "mdi-folder-search",
  TAG_ROOT: "mdi-tag-multiple",
  EXCHANGED: "mdi-folder-swap",
  INTERNAL: "mdi-domain",
  CONTACT: "mdi-account-box",
  SPAM: "mdi-alert-octagon-outline",
  SMART: "mdi-layers-outline"
};

const boardIcons = {
  BOARD: "mdi-folder-outline",
  ALL: "mdi-bulletin-board",
  MY: "mdi-account-edit-outline",
  MENTION: "mdi-at",
  TRASH: "mdi-trash-can",
  NOTICE: "mdi-bullhorn-outline",
  TAG: "mdi-tag-multiple"
};

export { fileIcons, fileIconColors, mailboxIcons, boardIcons };
