import Vue from "vue";
import router from "@/commons/router";
import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  addProject,
  updateProject,
  deleteProject
} from "@/flow/api/project.api";

const state = {};

const getters = {};

const mutations = {
  ADD_PROJECT: (state, project) => {
    // 내 프로젝트에서는 맨 마지막에 추가
    if (state.showMyProjects) {
      if (state.nextPageExists) return;
      return state.projects.push(project);
    }

    state.projects.unshift(project);
  },
  DELETE_PROJECT: (state, projectId) => {
    const projects = state.projects.filter(p => p.id !== projectId);
    Vue.set(state, "projects", projects);

    if (state.selectedProjectId === projectId) {
      state.selectedProjectId = null;
    }
  }
};

const actions = {
  async addProject({ commit, dispatch }, params) {
    const { status, data: project } = await addProject(params);
    const message = getMessage(status);
    if (message) {
      dispatch("snackbar", { message });
      return commit("flowDialog/SET_CONFIRMED", false, { root: true });
    }

    const { name: routeName } = router.currentRoute;
    if (routeName === "flow_projects") commit("ADD_PROJECT", project);
    commit("flowFolder/ADD_PROJECT", project, { root: true });
    commit("flowDialog/CLOSE_DIALOG", null, { root: true });
  },
  async updateProject({ commit, dispatch }, { projectId, params }) {
    const { status, data } = await updateProject({ projectId, params });
    const message = getMessage(status);
    if (message) {
      dispatch("snackbar", { message });
      return commit("flowDialog/SET_CONFIRMED", false, { root: true });
    }

    commit("SET_PROJECT", data);
    commit("flowFolder/SET_PROJECT", data, { root: true });
    commit("flowDialog/CLOSE_DIALOG", null, { root: true });
  },
  async deleteProject({ commit, dispatch }, projectId) {
    const callback = async () => {
      const { status } = await deleteProject(projectId);
      const additionalMsg = { 409: "참여중인 멤버가 존재합니다." };
      const message = getMessage(status, additionalMsg);
      if (message) {
        if (status == 409) dispatch("getProjectDetail", projectId);
        return dispatch("snackbar", { message });
      }

      commit("DELETE_PROJECT", projectId);
      commit("flowFolder/DELETE_PROJECT", { id: projectId }, { root: true });
    };

    const message = `해당 프로젝트를 삭제 하시겠습니까?<br />삭제된 프로젝트는 복구할 수 없습니다.`;
    return dispatch("confirm", { message, callback });
  }
};

export default { state, getters, mutations, actions };
