<template>
  <v-dialog
    :value="value"
    max-width="400px"
    hide-overlay
    persistent
    @input="val => $emit('input', val)"
    @keydown.enter.stop="confirm"
  >
    <v-card>
      <v-card-title>
        {{ $t("calendar.13") }}
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="send" column hide-details mandatory>
          <v-radio :value="false" :label="$t('calendar.19')"> </v-radio>
          <v-radio
            :label="isInvitation ? $t('calendar.17') : $t('calendar.18')"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="cancel">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="primary" @click="confirm">
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isInvitation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      send: true
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.send);
      this.$emit("input", false);
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("input", false);
    }
  }
};
</script>
