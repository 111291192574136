<template>
  <v-dialog
    :value="dialog"
    no-click-animation
    width="550px"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-bold">{{
          $t("resource.MEETING_ROOM")
        }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container class="pa-1">
          <!-- 자원 선택 -->
          <v-row>
            <v-col cols="12">
              <v-hover v-slot="{ hover }">
                <v-select
                  :value="resource"
                  :items="resources"
                  item-text="name"
                  return-object
                  class="simple-select"
                  :background-color="
                    hover ? `grey lighten-3` : 'grey lighten-4'
                  "
                  hide-details
                  solo
                  flat
                  :item-disabled="excludeHeader"
                  @input="changeResource"
                >
                  <template #item="{ item }">
                    <span>
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-hover>
            </v-col>
          </v-row>
          <!-- 예약 시간 -->
          <v-row no-gutters>
            <v-card flat>
              <v-container class="overflow-x-hidden">
                <div class="time-table mb-2" style="width: 480px;">
                  <table class="table-time-header">
                    <tr>
                      <td colspan="16">
                        <div class="freebusy-subheader">
                          <div class="info-wrap">
                            <div class="box selected"></div>
                            <span
                              class="subheader-text"
                              v-text="$t('calendar.68')"
                            ></span>
                            <div class="box hasEvent"></div>
                            <span
                              class="subheader-text"
                              v-text="$t('calendar.69')"
                            ></span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="header-time">8</td>
                      <td class="header-time">9</td>
                      <td class="header-time">10</td>
                      <td class="header-time">11</td>
                      <td class="header-time">12</td>
                      <td class="header-time">13</td>
                      <td class="header-time">14</td>
                      <td class="header-time">15</td>
                      <td class="header-time">16</td>
                      <td class="header-time">17</td>
                      <td class="header-time">18</td>
                      <td class="header-time">19</td>
                      <td class="header-time">20</td>
                      <td class="header-time">21</td>
                      <td class="header-time">22</td>
                    </tr>
                  </table>

                  <div class="resource-freebusy-wrap overflow-hidden"></div>
                </div>
                <strong
                  v-if="isConflictTime"
                  class="red--text text--lighten-1"
                  >{{ $t("resource.alert.conflict") }}</strong
                >
              </v-container>
            </v-card>
            <div class="hidden-div d-none">
              <table class="resource-table-time-freebusy toCopy">
                <tr>
                  <td class="freebusy-time 0 08:00"></td>
                  <td class="freebusy-time 1 08:30"></td>
                  <td class="freebusy-time 2 09:00"></td>
                  <td class="freebusy-time 3 09:30"></td>
                  <td class="freebusy-time 4 10:00"></td>
                  <td class="freebusy-time 5 10:30"></td>
                  <td class="freebusy-time 6 11:00"></td>
                  <td class="freebusy-time 7 11:30"></td>
                  <td class="freebusy-time 8 12:00"></td>
                  <td class="freebusy-time 9 12:30"></td>
                  <td class="freebusy-time 10 13:00"></td>
                  <td class="freebusy-time 11 13:30"></td>
                  <td class="freebusy-time 12 14:00"></td>
                  <td class="freebusy-time 13 14:30"></td>
                  <td class="freebusy-time 14 15:00"></td>
                  <td class="freebusy-time 15 15:30"></td>
                  <td class="freebusy-time 16 16:00"></td>
                  <td class="freebusy-time 17 16:30"></td>
                  <td class="freebusy-time 18 17:00"></td>
                  <td class="freebusy-time 19 17:30"></td>
                  <td class="freebusy-time 20 18:00"></td>
                  <td class="freebusy-time 21 18:30"></td>
                  <td class="freebusy-time 22 19:00"></td>
                  <td class="freebusy-time 23 19:30"></td>
                  <td class="freebusy-time 24 20:00"></td>
                  <td class="freebusy-time 25 20:30"></td>
                  <td class="freebusy-time 26 21:00"></td>
                  <td class="freebusy-time 27 21:30"></td>
                  <td class="freebusy-time 28 22:00"></td>
                  <td class="freebusy-time 29 22:30"></td>
                </tr>
              </table>
            </div>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text outlined @click="closeDialog">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn
          outlined
          color="accent"
          @click="adjustLocation"
          :disabled="isConflictTime"
        >
          {{ $t("calendar.적용") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { findCompanyResourcesByType } from "@/resource/api/resource.api";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";
import moment from "moment-timezone";

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    startTime: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
    allDay: {
      type: Boolean,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    isUpdateMode: {
      type: Boolean,
      required: true
    },
    resourceId: {
      type: Number,
      required: true,
      default: 0
    },
    bookingId: {
      type: Number,
      required: false,
      default: 0
    },
    resourceName: {
      type: String,
      required: false
    }
  },
  async created() {
    await this.initData();
    await this.appendResourceFreebusy();
    await this.adjustTimeDivs();
  },
  watch: {},
  data: () => ({
    eventId: 0,
    resources: [],
    //currentDate: null,
    isConflictTime: false,
    resource: {}
  }),
  computed: {
    ...mapGetters("resource", ["getResources"]),
    watchTimeChanged() {
      return (
        this.startDate,
        this.endDate,
        this.startTime,
        this.endTime,
        this.allDay,
        Date.now()
      );
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("resource", ["fetchBookingList"]),
    onEnter() {
      this.isUpdateMode ? this.updateResource() : this.bookResource();
    },
    async initData() {
      const { status, data = {} } = await findCompanyResourcesByType({
        type: "MEETING_ROOM"
      });

      if (status !== 200) {
        this.openSnackbar({
          type: "ERROR",
          message: i18n.t("resource.1")
        });
        return;
      }

      let resource;
      if (this.resourceId) {
        resource = data.find(item => item.id === this.resourceId);
      } else {
        resource = data[0];
        this.$emit("update:resourceId", resource.id);
      }
      this.resource = resource;
      this.resources = data;
    },
    async updateBooking() {
      const booking = {
        id: this.id,
        resourceId: this.resourceId,
        bookerId: this.bookerId,
        bookerName: this.bookerName,
        reason: this.reason,
        startDate: this.startDate,
        startTime: this.allDay ? "00:00" : this.startTime,
        endDate: this.endDate,
        endTime: this.allDay ? "00:00" : this.endTime,
        allDay: this.allDay,
        description: this.description,
        eventId: null
      };
      await this.updateResourceBooking(booking);
    },
    closeDialog() {
      this.$emit("update:resourceId", 0);
      this.$emit("update:resourceName", "");
      this.$emit("closeDialog");
    },
    excludeHeader(item) {
      if (item.type === "ITEM_HEADER") {
        return true;
      }
      return false;
    },
    setSelectedTimeToDialog(event) {
      const selectedTimeEl = document.querySelector(".resource-selected-time");
      selectedTimeEl.classList.remove("d-none");
      const selectableTimeEl = document.querySelector(".resource-selectable-time");
      selectedTimeEl.style.height = selectableTimeEl.style.height;
      selectedTimeEl.style.left = selectableTimeEl.style.left;
      selectedTimeEl.style.width = selectableTimeEl.style.width;

      // 이벤트 dialog (부모)의 시간값 변경
      const tempStart = moment(this.startTime, "HH:mm"); // 기존 시작시간
      const tempEnd = moment(this.endTime, "HH:mm"); // 기존 시작시간
      const tempDiff = tempEnd.diff(tempStart, "minutes"); // 기존 시작시간과 종료시간의 차이

      const toStartTime = moment(event.target.classList[2], "HH:mm"); // 유저가 클릭한 시작 시간
      let toEndTime = toStartTime.clone().add(tempDiff, "minutes"); // 종료시간 계산

      this.$emit("updateTimeData", {
        currentDate: this.startDate,
        startTime: toStartTime.format("HH:mm"),
        endTime: toEndTime.format("HH:mm")
      });
      event.preventDefault();
      event.stopPropagation();
    },
    expandTimeRange(event) {
      const tbOrderNum = event.target.classList[1];
      const timeRangeDivEl = document.querySelector(".resource-selectable-time");
      timeRangeDivEl.classList.remove("d-none");
      timeRangeDivEl.style.left = tbOrderNum * 16 + "px";
    },
    async adjustTimeDivs() {
      const selectedTimeEl = document.querySelector(".resource-selected-time");
      const selectableTimeEl = document.querySelector(".resource-selectable-time");
      if (selectableTimeEl) {
        selectableTimeEl.remove();
      }
      if (selectedTimeEl) {
        selectedTimeEl.remove();
      }

      const freebusyWrap = document.querySelector(".resource-freebusy-wrap");

      if (this.allDay || this.startDate != this.endDate) {
        // freebusy에 선택시간 div overlay하기 위해 이벤트 리스너 셋팅
        freebusyWrap.querySelectorAll(".freebusy-time").forEach(el => {
          el.removeEventListener("mouseover", this.expandTimeRange);
          el.removeEventListener("mouseup", this.setSelectedTimeToDialog);
        });
        return;
      }

      const startTimeNum = this.startTime.replace(":", "");
      let toOrder = (startTimeNum - 800) / 100;
      let orderNum = Math.floor(toOrder) / 0.5;
      let left = toOrder % 1;
      if (left > 0) {
        orderNum = orderNum + 1;
      }

      const tempStart = moment(this.startTime, "HH:mm"); // 기존 시작시간
      let tempEnd = moment(this.endTime, "HH:mm"); // 기존 시작시간
      let tempDiff = tempEnd.diff(tempStart, "minutes"); // 기존 시작시간과 종료시간의 차이
      if (tempDiff < 0) {
        tempEnd.add(1, "days");
        tempDiff = tempEnd.diff(tempStart, "minutes");
      }
      let diffNum = tempDiff / 30; // 30으로 나눠서 td 갯수 산출

      const freeBusyTables = document.querySelectorAll(
        ".resource-freebusy-wrap > .resource-table-time-freebusy"
      );

      const selectedTime = document.createElement("div");

      selectedTime.classList.add("resource-selected-time");
      selectedTime.style.position = "absolute";
      selectedTime.style.pointerEvents = "none";
      selectedTime.style.border = "2px solid rgba(0,0,255,.6)";
      selectedTime.style.top = "0px";
      selectedTime.style.zIndex = 2;
      selectedTime.style.height = 31 * freeBusyTables.length + "px";
      selectedTime.style.left = orderNum * 16 + "px";
      selectedTime.style.width = 16 * diffNum + "px";

      freebusyWrap.appendChild(selectedTime);

      const selectableTime = document.createElement("div");
      selectableTime.classList.add("resource-selectable-time");
      selectableTime.style.position = "absolute";
      selectableTime.style.pointerEvents = "none";
      selectableTime.style.border = "2px dashed rgba(255,0,0,.6)";
      selectableTime.style.top = "0px";
      selectableTime.style.height = 31 * freeBusyTables.length + "px";
      selectableTime.style.left = orderNum * 16 + "px";
      selectableTime.style.width = 16 * diffNum + "px";

      freebusyWrap.appendChild(selectableTime);

      // 8:00 이전이 시작 시간이면 타임 div 표시 안 함.
      if (moment(this.startTime, "HH:mm").isBefore(moment("08:00", "HH:mm"))) {
        selectedTime.classList.add("d-none");
        selectableTime.classList.add("d-none");
      }

      // freebusy에 선택시간 div overlay하기 위해 이벤트 리스너 셋팅
      freebusyWrap.querySelectorAll(".freebusy-time").forEach(el => {
        el.addEventListener("mouseover", this.expandTimeRange);
        el.addEventListener("mouseup", this.setSelectedTimeToDialog);
      });
    },
    async searchResourceFreeBusy() {
      const result = await this.fetchBookingList({
        resourceId: this.resource.id,
        searchDate: this.startDate
      });
      if (result) {
        this.isConflictTime = false;
        let isConflict = false;
        result.some(booking => {
          isConflict = this.checkTimeConflict(booking);
          if (isConflict) {
            this.isConflictTime = isConflict;
            return true; // break
          }
        });
      }

      return result;
    },
    async appendResourceFreebusy() {
      const bookingList = await this.searchResourceFreeBusy();
      const existFreebusys = document.querySelectorAll(
        ".resource-freebusy-wrap > .resource-table-time-freebusy"
      );
      existFreebusys.forEach(el => {
        el.parentNode.removeChild(el);
      });

      //this.toSearchUsers.forEach(user => {
      const freebusyWrap = document.querySelector(".resource-freebusy-wrap");
      const freebusyTable = document.querySelector(
        ".hidden-div > .resource-table-time-freebusy"
      );
      const existUserFreeBusy = document.querySelector(
        ".resource-table-time-freebusy.resource-" + this.resource.id
      );

      if (existUserFreeBusy) {
        existUserFreeBusy.remove();
      }
      const resourceFreeBusy = freebusyTable.cloneNode(true);
      resourceFreeBusy.classList.remove("d-none");
      resourceFreeBusy.classList.remove("toCopy");
      resourceFreeBusy.classList.add("resourceId-" + this.resource.id);
      if (bookingList) {
        let timeStrSet = new Set();
        let allDay = false;
        const startRange = moment(this.startDate + " 01:00");
        bookingList
          //.filter(event => event.userId == user.userId)
          .forEach(booking => {
            if (booking.allDay) {
              allDay = true;
              return false;
            }
            const bookingStart = moment(
              `${booking.startDate} ${booking.startTime}`
            );

            const bookingEnd = moment(`${booking.endDate} ${booking.endTime}`);

            if (bookingStart.isBefore(startRange)) {
              return false;
            }

            if (allDay === false) {
              for (
                let time = bookingStart;
                time < bookingEnd;
                time.add("30", "minutes")
              ) {
                timeStrSet.add(CAL_CONSTANTS.findNearestTime(time, "HH:mm"));
              }
            }
          });

        resourceFreeBusy
          .querySelectorAll(".freebusy-time")
          .forEach(freebusyTime => {
            if (allDay) {
              freebusyTime.classList.add("hasEvent");
            } else {
              timeStrSet.forEach(time => {
                if (freebusyTime.classList.contains(time)) {
                  freebusyTime.classList.add("hasEvent");
                }
              });
            }
          });
      }
      freebusyWrap.appendChild(resourceFreeBusy);
    },
    async bookResource() {
      this.$emit("closeDialog", false);
    },
    checkTimeConflict(booking) {
      const selectedStartTime = moment(this.startTime, "HH:mm");
      const selectedEndTime = moment(this.endTime, "HH:mm");
      const bookingStartTime = moment(booking.startTime, "HH:mm");
      const bookingEndTime = moment(booking.endTime, "HH:mm");
      // 수정모드 이면서, 해당 예약데이터일 경우
      if (this.isUpdateMode && booking.id == this.bookingId) {
        return false;
      }
      if (selectedStartTime.isBetween(bookingStartTime, bookingEndTime)) {
        return true;
      }
      if (selectedEndTime.isBetween(bookingStartTime, bookingEndTime)) {
        return true;
      }
      if (bookingStartTime.isBetween(selectedStartTime, selectedEndTime)) {
        return true;
      }
      if (bookingEndTime.isBetween(selectedStartTime, selectedEndTime)) {
        return true;
      }
      return false;
    },
    adjustLocation() {
      this.$emit("update:resourceId", this.resource.id);
      this.$emit("update:resourceName", this.resource.name);
      this.$emit("closeDialog");
    },
    async changeResource(resource) {
      this.resource = resource;
      await this.appendResourceFreebusy();
      await this.adjustTimeDivs();
    }
  }
};
</script>
<style lang="scss" scoped>
.freebusy-border {
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.table-time-header,
.resource-table-time-freebusy {
  border: 1px solid #ddd;
  border-collapse: collapse;
  .header-title,
  .freebusy-title {
    border-top: 1px solid #ddd;
    width: 100px;
    padding-left: 10px;
    font-size: 13px;
    color: #222;
    font-weight: bold;
  }
  .header-time {
    width: 32px;
    height: 30px;
    text-align: center;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  .freebusy-time {
    width: 16px;
    height: 30px;
    text-align: center;
    border-left: 1px solid #ddd;
  }
}
.resource-freebusy-wrap {
  position: relative;
}
.resource-selectable-time {
  position: absolute;
  pointer-events: none;
  width: 16px;
  height: 31px;
  border: 1px dashed red;
  top: 0px;
  left: 100px;
}
.resource-selected-time {
  position: absolute;
  pointer-events: none;
  border: 1px Dotted blue;
  top: 0px;
}
.hasEvent {
  background-color: #c9c9c9;
}
.freebusy-subheader {
  height: 33px;
  .info-wrap {
    display: flex;
    align-items: center;
    height: inherit;
    .box {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      &.selected {
        border: 2px solid rgba(0, 0, 255, 0.6);
      }
    }
    .subheader-text {
      font-weight: bold;
      font-size: 12px;
      color: #424242;
      padding-left: 10px;
    }
  }
}
.date-arrow-wrap {
  font-size: 20px;
}
</style>
