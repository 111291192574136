import { render, staticRenderFns } from "./DeactivatedBtn.vue?vue&type=template&id=a65b53ac&scoped=true&"
import script from "./DeactivatedBtn.vue?vue&type=script&lang=js&"
export * from "./DeactivatedBtn.vue?vue&type=script&lang=js&"
import style0 from "./DeactivatedBtn.vue?vue&type=style&index=0&id=a65b53ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a65b53ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VHover,VIcon,VTooltip})
