<template>
  <div
    class="py-2 cr-user-quota-bar"
    :class="$vuetify.breakpoint.lgAndUp ? 'cr-lg-and-up' : ''"
    @click="
      $router.push({
        name: 'account',
        params: { tabType: 'info', goQuota: true }
      })
    "
  >
    <div
      class="mb-1 text-caption font-weight-medium d-flex justify-space-between"
    >
      <div class="grey--text">
        <span>
          {{ displayQuota(getUserInfo.usedSize) }}
        </span>
        <span> &nbsp;/&nbsp; </span>
        <span>{{ displayQuota(getUserInfo.totalQuota) }}</span>
      </div>
      <div class="font-weight-bold grey--text text--darken-2">
        {{ usedPercent + "%" }}
      </div>
    </div>
    <v-progress-linear
      :color="quotaColor"
      background-color="grey lighten-3"
      height="7"
      :value="usedPercent"
    >
    </v-progress-linear>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    usedPercent() {
      const percent = Math.floor(
        (this.getUserInfo.usedSize / this.getUserInfo.totalQuota) * 100
      );
      return percent;
    },
    quotaColor() {
      if (this.usedPercent >= 90) return "red";
      else if (this.usedPercent >= 70 && this.usedPercent < 90) {
        return "orange";
      }
      return "#6a8fff";
    }
  },
  methods: {
    displayQuota(byte) {
      return byteCalculation(byte);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-user-quota-bar {
  position: absolute;
  bottom: 10px;
  right: 12px;
  left: 27px;

  &.cr-lg-and-up {
    bottom: 74px;
  }

  cursor: pointer;
  .v-progress-linear {
    border-radius: 5px;
  }
}
</style>
