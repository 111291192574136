const systemFolderType = {
  ALL: "ALL",
  IMPORTANT: "IMPORTANT",
  UNREAD: "UNREAD",
  READ: "READ",
  ATTACH: "ATTACH",
  EXCHANGED: "EXCHANGED",
  INTERNAL: "INTERNAL",
  CONTACT: "CONTACT",
  INBOX: "INBOX",
  TOME: "TOME",
  SENT: "SENT",
  DRAFTS: "DRAFTS",
  DELETED: "DELETED",
  SHARED_ROOT: "SHARED_ROOT",
  SHARING_ROOT: "SHARING_ROOT",
  PRIVATE_ROOT: "PRIVATE_ROOT",
  SEARCH_ROOT: "SEARCH_ROOT",
  TAG_ROOT: "TAG_ROOT",
  SPAM: "SPAM",
  SMART: "SMART",
  PROJECT_ROOT: "PROJECT_ROOT"
};

const rootFolderType = {
  PRIVATE_ROOT: "PRIVATE_ROOT",
  SEARCH_ROOT: "SEARCH_ROOT",
  TAG_ROOT: "TAG_ROOT",
  PROJECT_ROOT: "PROJECT_ROOT"
};

const privateFolderType = {
  PRIVATE: "PRIVATE",
  PRIVATE_SECURE: "PRIVATE_SECURE",
  PRIVATE_PERMANENT: "PRIVATE_PERMANENT"
};

const shareFolderType = {
  SHARED_ROOT: "SHARED_ROOT",
  SHARING_ROOT: "SHARING_ROOT"
};

const quickMenuEnum = {
  IMPORTANT: -2, // 중요 메일
  UNREAD: -3, // 안읽은 메일
  READ: -6, // 읽은 메일
  ATTACH: -4, // 첨부파일 메일
  EXCHANGED: -12, // 주고받은 메일
  INTERNAL: -13, // 내부 메일
  CONTACT: -14 // 주소록 메일
};

const virtualFolderConverter = folderId => {
  const types = [
    { value: -1, name: "all" },
    { value: -2, name: "important" },
    { value: -3, name: "unread" },
    { value: -6, name: "read" },
    { value: -4, name: "attach" },
    { value: -5, name: "spam" },
    { value: -9, name: "shared" },
    { value: -15, name: "sharing" },
    { value: -10, name: "share" },
    { value: -12, name: "exchange" },
    { value: -13, name: "internal" },
    { value: -14, name: "contact" }
  ];

  if (folderId == undefined) return folderId;

  const result = types.filter(({ value, name }) =>
    isNaN(parseInt(folderId))
      ? name == folderId.toLowerCase()
      : value == folderId
  );

  if (result.length == 0) return folderId;
  else return isNaN(parseInt(folderId)) ? result[0].value : result[0].name;
};

export {
  systemFolderType,
  rootFolderType,
  privateFolderType,
  shareFolderType,
  quickMenuEnum,
  virtualFolderConverter
};
