<template>
  <v-row v-show="isShow" class="mt-0">
    <v-col cols="12" sm="6">
      <v-select
        v-model="before"
        :items="getLanguages"
        item-value="code"
        outlined
        dense
        hide-details
      />
    </v-col>

    <v-col cols="12" sm="6" class="pl-0">
      <v-select
        v-model="after"
        :items="getLanguages"
        item-value="code"
        outlined
        dense
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("ai", ["getSelected", "getLanguages", "getTranslate"]),
    isShow() {
      return this.getSelected?.type === "TRANSLATE";
    },
    before: {
      get() {
        return this.getTranslate[0];
      },
      set(value) {
        let data = [...this.getTranslate];
        if (data[1] === value) data[1] = data[0];
        data[0] = value;
        this.SET_TRANSLATE(data);
      }
    },
    after: {
      get() {
        return this.getTranslate[1];
      },
      set(value) {
        let data = [...this.getTranslate];
        if (data[0] === value) data[0] = data[1];
        data[1] = value;
        this.SET_TRANSLATE(data);
      }
    }
  },
  methods: {
    ...mapMutations("ai", ["SET_TRANSLATE"])
  }
};
</script>
