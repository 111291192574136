<template>
  <div
    v-bind="$attrs"
    v-on="$listeners"
    class="icon-field-content"
    :style="{
      padding: sizeInfo.padding
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  computed: {
    sizeInfo() {
      switch (this.$parent.spacing) {
        case "xs":
          return {
            padding: "0"
          };
        case "sm":
          return {
            padding: "2px 0"
          };
        case "dense":
          return {
            padding: "4px 0"
          };
        case "lg":
          return {
            padding: "8px 0"
          };
        case "md":
        default:
          return {
            padding: "6px 0"
          };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.icon-field-content {
  line-height: 20px;

  overflow-wrap: anywhere;
  white-space: pre-line;
}
</style>
