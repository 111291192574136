<template>
  <v-list dense elevation="0" class="py-0" min-width="300">
    <v-list-item
      @click="$router.push({ name: 'todo' })"
      :class="selectedWorkspaceId == 0 ? 'cr-selected' : ''"
    >
      <v-list-item-content>
        <v-list-item-title class="d-flex">
          <v-avatar color="primary" size="38">
            <span class="text-h7">
              <v-icon size="20" color="white">mdi-view-grid-outline</v-icon>
            </span>
          </v-avatar>
          <div class="d-flex cr-todo-title">
            <span class="d-inline-block text-truncate">
              모든 워크스페이스
            </span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div v-if="showCurrentWorkspace" class="py-2 px-3 caption">
      현재 워크스페이스
    </div>

    <v-list-item
      v-if="showCurrentWorkspace"
      :class="selectedWorkspaceId == currentWorkspace.id ? 'cr-selected' : ''"
      @click="$emit('select', currentWorkspace)"
    >
      <v-list-item-content>
        <v-list-item-title class="d-flex">
          <v-avatar
            size="38"
            :class="currentWorkspace.emoji ? 'cr-workspace-avatar-emoji' : ''"
            :color="
              currentWorkspace.emoji ? 'transparent' : currentWorkspace.color
            "
          >
            <span
              class="text-h7"
              :style="`color: ${invertColor(currentWorkspace.color)};`"
            >
              {{ currentWorkspace.avatar }}
            </span>
          </v-avatar>
          <div class="d-flex cr-todo-title">
            <span class="d-inline-block text-truncate">
              {{ currentWorkspace.title }}
            </span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="py-2 px-3 caption">
      나의 워크스페이스
    </div>

    <v-list-item
      v-for="workspace in workspaceList"
      :key="workspace.id"
      :class="selectedWorkspaceId == workspace.id ? 'cr-selected' : ''"
      @click="$emit('select', workspace)"
    >
      <v-list-item-content>
        <v-list-item-title class="d-flex">
          <v-avatar
            :class="workspace.emoji ? 'cr-workspace-avatar-emoji' : ''"
            :color="workspace.emoji ? 'transparent' : workspace.color"
            size="38"
          >
            <span
              class="text-h7"
              :style="`color: ${invertColor(workspace.color)};`"
            >
              {{ workspace.avatar }}
            </span>
          </v-avatar>
          <div class="d-flex cr-todo-title">
            <span class="d-inline-block text-truncate">
              {{ workspace.title }}
            </span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<style lang="scss" scoped>
.v-list ::v-deep {
  position: absolute;
  top: 55px;
  left: 0px;
  right: 0px;
  bottom: 50px;
  overflow-y: auto;

  .v-list-item.cr-selected {
    background: rgba(0, 0, 0, 0.1);
  }

  .v-list-item__title {
    font-size: 0.875rem !important;

    .cr-todo-title {
      min-width: 0px;
      line-height: 38px;
      padding: 0 10px;
    }

    .v-avatar.cr-workspace-avatar-emoji {
      overflow: unset;
      span {
        font-size: 30px;
        line-height: 0;
      }
    }
  }

  .cr-todo-workspace-no-data {
    width: 100%;
    height: 229px;
    padding: 20px 27px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    selectedWorkspaceId: {
      default: 0,
      type: Number
    },
    inputValue: {
      default: "",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeWorkspaceId"]),
    ...mapGetters("todoWorkspace", ["myWorkspaces", "currentWorkspace"]),
    showCurrentWorkspace() {
      return this.routeWorkspaceId > 0;
    },
    workspaceList() {
      if (!isBlank(this.inputValue)) {
        return this.myWorkspaces.filter(
          ({ title }) =>
            title
              .toLocaleLowerCase()
              .indexOf(this.inputValue.toLocaleLowerCase()) > -1
        );
      }

      return this.myWorkspaces;
    }
  },
  methods: {
    invertColor(color) {
      return invertColor(color);
    }
  }
};
</script>
