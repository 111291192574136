<!-- 
  mounted 순서: custom vue -> router-view -> appbar -> navi -> footer
 -->
<template>
  <v-app :class="$vuetify.breakpoint.mdAndDown ? 'cr-md-and-down' : ''">
    <Splash v-if="showSplash" />
    <Appbar v-if="showAppbar" :route="route" @toggle-drawer="toggleDrawer" />
    <NavigationDrawer :route="route" ref="drawer" />
    <SidePanelDrawer v-if="showSidePanel" :route="route" />

    <v-main>
      <router-view />
      <Confirm />
      <PositioningMenu />
      <Snackbar />
      <Share />
      <LinkShare />
    </v-main>

    <Loader />
    <AnnounceDialog />
    <TagDialog v-if="showTagDialog" />
    <Messenger v-if="useMessenger" />
    <ThemeDialog v-if="isThemeDialogVisible" />

    <!-- AI -->
    <AIDialog />

    <!-- 비밀번호 변경 요청 팝업 -->
    <PasswordChangeDialog />

    <!-- 알림 (메신저/메일) -->
    <Notification />
    <Footer v-if="getCompanyInfo.footer" :route="route" />

    <!-- 외부(관리자) 연결 폼 -->
    <form id="directForm" method="post" target="_blank">
      <input type="hidden" name="accessToken" id="accessToken" />
      <input type="hidden" name="refreshToken" id="refreshToken" />
    </form>
  </v-app>
</template>

<style lang="scss">
html {
  overflow-y: hidden;
}
body.no-drop {
  * {
    cursor: no-drop !important;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// ipad/ iphone 브라우저 vh 버그로인해 추가
.v-application--wrap {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;
}
.v-main {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

/* 스크롤바 */
::-webkit-scrollbar {
  background: transparent;
}
.v-menu__content::-webkit-scrollbar {
  background: white;
}
::-webkit-scrollbar-thumb {
  border: solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.1);
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &:horizontal {
    border-width: 4px 6px;
    min-width: 40px;
  }
  &:vertical {
    border-width: 6px 4px;
    min-height: 40px;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}
/* 데이터 테이블 hover시 테두리 둥근부분 제거 */
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:first-child:hover
  td:first-child,
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr:first-child:hover
  td:last-child {
  border-radius: 0px;
}
.v-data-table > .v-data-table__wrapper tbody tr:last-child:hover td:first-child,
.v-data-table > .v-data-table__wrapper tbody tr:last-child:hover td:last-child {
  border-radius: 0px;
}
/* D&D style */
.dnd-ghost {
  min-height: 35px;
  white-space: nowrap;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);

  /* 폴더 */
  .v-treeview-node__prepend {
    margin-right: 5px;
  }
  .v-treeview-node__content {
    margin-top: 1px;
  }

  /* 메일 목록 */
  .dnd-node {
    min-width: 150px;
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
  }
  .dnd-icon {
    min-width: 24px;
    margin-right: 6px;
  }
  .dnd-text {
    flex: 1;
    font-size: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<style lang="scss" scoped>
/* 기본 색상 */
.theme--light.v-application {
  background-color: #f9f9f9;
  /* 앱바 */
  ::v-deep .v-app-bar.v-toolbar.v-sheet {
    background-color: transparent;
  }
}

.v-application {
  user-select: none;
}
.v-application ::v-deep .user-select-text {
  user-select: text;
}
.v-application ::v-deep .v-text-field__slot {
  caret-color: #333 !important;
}
/* 상단 app-bar */
.v-application ::v-deep .v-toolbar {
  box-shadow: none !important;
  /* 햄버거 메뉴 위치 */
  .v-toolbar__content .v-app-bar__nav-icon {
    margin-left: -3px;
  }
}
/* */
.v-application ::v-deep .v-btn-light {
  background-color: #f1f2f5;
}
/* 주메뉴 */
.v-application {
  ::v-deep .v-navigation-drawer {
    background-color: transparent;
  }
  &.cr-md-and-down {
    ::v-deep .v-navigation-drawer {
      background-color: #f9f9f9;
    }
  }
}
/* 주메뉴 구분선 */
.v-application ::v-deep .v-navigation-drawer__border {
  background-color: transparent !important;
}
/* 본문영역 */
.v-application ::v-deep .v-main {
  height: 100vh;
  transition: none;
  .cr-mail-main,
  .cr-contact-main,
  .cr-drive-main,
  .cr-todo-main,
  .cr-board-main,
  .cr-calendar-main,
  .cr-customer-main,
  .cr-resource-main,
  .cr-approval-main,
  .cr-flow-main {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  }
}
/* 페이지네이션 */
.v-application ::v-deep .cr-pagination {
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: 4px;
  height: 54px;
  .v-pagination {
    .v-pagination__navigation {
      box-shadow: none;
    }
    .v-pagination__item {
      box-shadow: none;
    }
  }
}
/* input UI */
::v-deep .v-input.v-text-field.v-text-field--outlined {
  border-radius: 0px;
}
::v-deep .v-input.v-text-field--outlined fieldset {
  border-color: rgba(0, 0, 0, 0.12);
}
::v-deep .v-input.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid gray;
}
</style>

<script>
import { mapGetters } from "vuex";
import Appbar from "@/commons/views/Appbar";
import Confirm from "@/commons/views/Confirm";
import PositioningMenu from "@/commons/views/PositioningMenu";
import Share from "@/commons/views/share/Share";
import Snackbar from "@/commons/views/Snackbar";
import NavigationDrawer from "@/commons/views/NavigationDrawer";
import SidePanelDrawer from "@/commons/views/sidePanel";
import Footer from "./commons/views/Footer";
import LinkShare from "@/commons/views/share/LinkShare.vue";
import AnnounceDialog from "@/commons/views/announce/AnnounceDialog";
import Loader from "@/commons/views/loader/Loader";
import Messenger from "@/commons/views/messenger";
import Notification from "@/commons/views/Notification";
import PasswordChangeDialog from "@/commons/views/PasswordChangeDialog";
import Splash from "@/commons/views/Splash";
import TagDialog from "@/commons/views/tag";
import ThemeDialog from "@/commons/views/theme/ThemeDialog";
import AIDialog from "@/commons/views/ai/dialog";
import { setLocale } from "@/commons/utils/moment";

export default {
  name: "App",
  components: {
    Confirm,
    PositioningMenu,
    Share,
    LinkShare,
    Snackbar,
    Appbar,
    NavigationDrawer,
    SidePanelDrawer,
    Footer,
    Loader,
    AnnounceDialog,
    Messenger,
    Notification,
    Splash,
    TagDialog,
    ThemeDialog,
    PasswordChangeDialog,
    AIDialog
  },
  created() {
    if (process.env.NODE_ENV !== "production") {
      console.log("Vue App created.", process.env);
    }

    setLocale("ko");
  },
  mounted() {
    // ipad/ iphone 브라우저 vh 버그로인해 추가
    this.setVhProperty();
    window.addEventListener("resize", this.setVhProperty);
  },
  updated() {
    const { name } = this.$route;
    if (!name || name?.indexOf("print") >= 0) return;
    this.route = name?.split("_")[0];
  },
  data() {
    return {
      route: "",
      modules: [
        "home",
        "account",
        "mail",
        "contact",
        "cal",
        "drive",
        "todo",
        "flow",
        "board",
        "resource",
        "customer",
        "approval"
      ]
    };
  },
  computed: {
    ...mapGetters("tag", ["showTagDialog"]),
    ...mapGetters("splash", ["showSplash"]),
    ...mapGetters("auth", ["getCompanyInfo"]),
    ...mapGetters("messenger", ["useMessenger"]),
    ...mapGetters("theme", ["isThemeDialogVisible"]),
    ...mapGetters("gpt", ["isAIDialogVisible"]),
    showAppbar() {
      return this.modules.includes(this.route);
    },
    showSidePanel() {
      const { name, path } = this.$route;
      if (!name || !path) return false;

      const module = path.split("/")[1];

      /**
       * 사이드 페널 예외 페이지
       * - 팝업 메일보기(인쇄), 팝업 메일쓰기, 원본 메일 보기, 주소록 인쇄
       */
      const exclude_path = [
        "popup_mail_view",
        "popup_mail_write",
        "popup_original_view",
        "contact_print",
        "approval_document_view_print",
        "customer_print"
      ];

      return this.modules.includes(module) && !exclude_path.includes(name);
    }
  },
  methods: {
    setVhProperty() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    toggleDrawer() {
      const {
        $refs: { drawer }
      } = this;

      if (!drawer) return;
      drawer.$refs.drawer.drawer = !drawer.$refs.drawer.drawer;
    }
  }
};
</script>
