import i18n from "@/_locales";
import Vue from "vue";
import {
  getCardList,
  getPrintCardList,
  deleteCardList,
  updateImportantStatus,
  addCard,
  updateCard,
  mergeCard
} from "../../api/card.api";
import { getContactDetail, getContactDetailList } from "../../api/detail.api";
import { updateContactRelation } from "../../api/relation.api";
import { copyPrivateCardsToCustomer } from "@/customer/api/card.api";

const state = {
  headers: [
    {
      text: "",
      value: "importantStatus",
      use: true,
      default: true,
      sortable: false,
      width: "44px",
      class: "px-1",
      cellClass: "px-1 importantStatus"
    },
    {
      text: "이름",
      value: "userName",
      use: true,
      default: true,
      sortable: true,
      width: "130px",
      class: "min-width"
    },
    {
      text: "이메일",
      value: "email",
      use: true,
      default: true,
      sortable: true,
      class: "min-width"
    },
    {
      text: "전화번호",
      value: "tel",
      use: true,
      sortable: true,
      width: "130px"
    },
    {
      text: "회사",
      value: "company",
      use: true,
      sortable: true,
      width: "100px"
    },
    {
      text: "부서",
      value: "organ",
      use: true,
      sortable: true,
      width: "80px"
    },
    {
      text: "직급",
      value: "position",
      use: true,
      sortable: true,
      width: "80px"
    },
    {
      text: "홈페이지",
      value: "url",
      use: true,
      width: "160px",
      sortable: false
    },
    {
      text: "메모",
      value: "memo",
      use: true,
      width: "80px",
      sortable: false
    },
    {
      text: "그룹",
      value: "contactGroups",
      use: true,
      width: "200px",
      sortable: false
    }
  ],
  cardList: [],
  totalCount: 0,
  loading: false
};

const getters = {
  // getDefaultHeaders: (state, getters, rootState) => {
  //   const { hideColumns = [] } = rootState.config;
  //   const headers = [];
  //   if (Object.keys(hideColumns).length > 0) {
  //     state.headers.forEach(header => {
  //       if (!header.default) {
  //         header.use = hideColumns.indexOf(header.value) < 0;
  //       }
  //       headers.push(header);
  //     });
  //   }
  //   return headers;
  // },
  getDefaultHeaders: ({ headers }) =>
    headers.map(h => ({ ...h, text: i18n.t(`contact.${h.text}`) })),
  getHeaders: ({ headers }) => {
    return headers
      .filter(({ use }) => use)
      .map(h => ({ ...h, text: h.text ? i18n.t(`contact.${h.text}`) : "" }));
  },
  isLoading: ({ loading }) => loading,
  getCardList: ({ cardList }) => cardList,
  getTotalCount: ({ totalCount }) => totalCount,
  // getPageCount: ({ totalCount }) => Math.ceil(totalCount / 10),
  getSort: ({ sort }) => sort,
  getRouteParams: (state, getters, rootState) =>
    rootState.contactRoute.contact_list,
  getConfig: (state, getters, rootState) => {
    return {
      pageSize: rootState.contactConfig.pageSize,
      sort: rootState.contactConfig.sort,
      dir: rootState.contactConfig.dir
    };
  },
  initConfig: (state, getters, rootState) => rootState.contactConfig.initConfig
};

const mutations = {
  SET_CARD_LIST: (state, cardList) => (state.cardList = cardList),
  SET_CARD: (state, { index, card }) => Vue.set(state.cardList, index, card),
  SET_TOTAL_COUNT: (state, totalCount) => (state.totalCount = totalCount),
  ADD_HEADER: (state, { index, header }) =>
    state.headers.splice(index, 0, header),
  SET_USE_HEADER: (state, { index, use }) => (state.headers[index].use = use),
  TOGGLE_LOADING: (state, loading) => (state.loading = loading),
  SET_IMPORTANT_STATUS: (state, { cardIdList, importantStatus, index }) => {
    if (index >= 0) {
      state.cardList[index].importantStatus = importantStatus;
    } else {
      cardIdList.map(cardId => {
        const index = state.cardList.findIndex(({ id }) => id === cardId);
        if (index >= 0) state.cardList[index].importantStatus = importantStatus;
      });
    }
  },
  UPDATE_DEFAULT_HEADER: (state, hideColumns) => {
    if (!hideColumns) return;

    state.headers.forEach(header => {
      if (header.default) return;
      header.use = hideColumns.indexOf(header.value) < 0;
    });
  },

  SET_UPDATE_CARD: (state, card) => {
    const index = state.cardList.findIndex(c => c.id == card.id);
    if (index == -1) return;

    Vue.set(state.cardList, index, card);
  }
};

const actions = {
  /**
   * 헤더 설정 정보 저장
   * @param {*} param0
   * @param {*} param1
   */
  async setUseHeader({ commit }, { index, use }) {
    commit("SET_USE_HEADER", { index, use });
  },

  /**
   * 연락처 리스트 조회
   *
   * @param {*} param0
   * @param {*} param1
   */
  async getCardList(
    { commit, dispatch, getters },
    {
      contactGroupId = 0,
      pageSize: _pageSize,
      sort: _sort,
      dir: _dir,
      page = 1,
      importantStatus = 0,
      consonant,
      isNew = false,
      searchWord = ""
    }
  ) {
    if (!getters.initConfig) {
      // 주소록 설정 가져오기
      await dispatch("contactConfig/getConfig", false, { root: true });
    }

    let config = {
      ...getters.getConfig,
      ...{
        pageSize: _pageSize || getters.getConfig.pageSize,
        sort: _sort || getters.getConfig.sort,
        dir: _dir || getters.getConfig.dir
      }
    };

    commit("TOGGLE_LOADING", true);
    try {
      const { data = {} } = await getCardList({
        groupId: contactGroupId,
        page: page - 1,
        importantStatus,
        consonant,
        searchWord,
        ...config
      });

      const cardList = data.content.map(
        ({
          id,
          userName,
          email,
          tel,
          importantStatus,
          company,
          organ,
          position,
          url,
          memo,
          contactGroupList
        }) => {
          const contactGroups = contactGroupList
            .map(g => `${contactGroups ? ", " : ""}${g.groupName}`)
            .join(", ");
          const contactGroupIdList = contactGroupList.map(g => g.id);
          return {
            id,
            userName,
            email,
            tel,
            importantStatus,
            company,
            organ,
            position,
            url,
            memo,
            contactGroups,
            contactGroupIdList
          };
        }
      );

      commit("SET_TOTAL_COUNT", isNew ? 10 : data.totalElements);
      commit("SET_CARD_LIST", cardList);
      commit("TOGGLE_LOADING", false);
    } catch (e) {
      // console.log("getCardList e = ", e);
    }
  },

  /**
   * 연락처 상세 정보 조회
   *
   * @param {*} actions
   * @param {*} id
   * @returns
   */
  async getContactDetail(actions, id) {
    try {
      const { data } = await getContactDetail({ id });
      const {
        firstName,
        lastName,
        emailList,
        telList,
        telTypeList,
        contactGroupList,
        company,
        organ,
        position,
        addrTypeList,
        postList,
        localityList,
        streetAddressList,
        urlList,
        memo
      } = data;
      return {
        id,
        firstName: firstName || "",
        lastName: lastName || "",
        emailList: emailList || [],
        telList: telList || [],
        telTypeList: telTypeList || [],
        contactGroupList: contactGroupList || [],
        company: company || "",
        organ: organ || "",
        position: position || "",
        addrTypeList: addrTypeList || [],
        postList: postList || [],
        localityList: localityList || [],
        streetAddressList: streetAddressList || [],
        urlList: urlList || [],
        memo: memo || ""
      };
    } catch (e) {
      // console.log("getContactDetail e = ", e);
    }
  },

  /**
   * 연락처 삭제
   *
   * @param {*} param0
   * @param {*} cardIdList
   */
  async delete({ dispatch, getters }, cardIdList) {
    try {
      const response = await deleteCardList(cardIdList);
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.51"),
        SUCCESS: i18n.t("contact.52")
      };
      let type = "ERROR";

      if (status === 200) {
        const routeParams = getters.getRouteParams;
        const params = {
          ...routeParams,
          ...routeParams.actions
        };
        type = "SUCCESS";
        await dispatch("getCardList", params);
      }

      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("delete e = ", e);
    }
  },

  /**
   * 연락처 중요표시 변경
   *
   * @param {*} param0
   * @param {*} param1
   */
  async updateImportantStatus(
    { commit },
    { cardIdList, importantStatus, index }
  ) {
    try {
      const response = await updateImportantStatus({
        cardIdList,
        importantStatus
      });
      const { status } = response;

      if (status === 200) {
        commit("SET_IMPORTANT_STATUS", { cardIdList, importantStatus, index });
      }
    } catch (e) {
      // console.log("updateImportantStatus e = ", e);
    }
  },

  /**
   * 연락처 추가
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async addCard({ dispatch, getters }, cardData) {
    try {
      const response = await addCard(cardData);
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.53"),
        SUCCESS: i18n.t("contact.54")
      };
      let type = "ERROR";

      if (status === 201) {
        type = "SUCCESS";
        const routeParams = getters.getRouteParams;
        const params = {
          ...routeParams,
          ...routeParams.actions
        };
        await dispatch("getCardList", params);
      }

      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("addCard e = ", e);
    }
  },

  /**
   * 연락처 수정
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async updateCard({ dispatch, getters }, cardData) {
    try {
      const response = await updateCard({ id: cardData.id, cardData });
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.55"),
        SUCCESS: i18n.t("contact.56")
      };
      let type = "ERROR";

      if (status === 200) {
        type = "SUCCESS";
        const routeParams = getters.getRouteParams;
        const params = {
          ...routeParams,
          ...routeParams.actions
        };
        await dispatch("getCardList", params);
      }
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("updateCard e = ", e);
    }
  },
  /**
   * 연락처 수정
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async updateCard2({ dispatch, commit }, cardData) {
    try {
      const response = await updateCard({ id: cardData.id, cardData });
      const { status, data } = response;

      const messages = {
        ERROR: i18n.t("contact.55"),
        SUCCESS: i18n.t("contact.56")
      };
      let type = "ERROR";

      if (status === 200) {
        type = "SUCCESS";
        commit("SET_UPDATE_CARD", data);
      }
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("updateCard e = ", e);
    }
  },

  /**
   * 인쇄 연락처 리스트 조회
   *
   * @param {*} actions
   * @param {*} param1
   * @returns
   */
  async getPrintCardList(
    actions,
    { contactGroupId = 0, sort = "id", dir = "desc", consonant }
  ) {
    try {
      const { data = [] } = await getPrintCardList({
        groupId: contactGroupId,
        sort,
        dir,
        consonant
      });
      return data.map(
        ({
          id,
          userName,
          email,
          tel,
          importantStatus,
          company,
          organ,
          position,
          url,
          memo,
          contactGroupList
        }) => {
          const contactGroups = contactGroupList
            .map(g => `${contactGroups ? ", " : ""}${g.groupName}`)
            .join(", ");
          const contactGroupIdList = contactGroupList.map(g => g.id);
          return {
            id,
            userName,
            email,
            tel,
            importantStatus,
            company,
            organ,
            position,
            url,
            memo,
            contactGroups,
            contactGroupIdList
          };
        }
      );
    } catch (e) {
      // console.log("getPrintCardList e = ", e);
    }
  },

  /**
   * 연락처 그룹 관계 수정
   *
   * @param {*} param0
   * @param {*} params
   */
  async updateContactRelation({ dispatch, getters }, params) {
    try {
      const response = await updateContactRelation(params);
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.57"),
        SUCCESS: i18n.t("contact.58")
      };
      let type = "ERROR";

      if (status === 200) {
        type = "SUCCESS";
        const routeParams = getters.getRouteParams;
        const params = {
          ...routeParams,
          ...routeParams.actions
        };
        await dispatch("getCardList", params);
      }
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("updateContactRelation e = ", e);
    }
  },

  /**
   * 연락처 상세 리스트 조회
   *
   * @param {*} actions
   * @param {*} cardIdList
   * @returns
   */
  async getContactDetailList(actions, cardIdList) {
    try {
      const { status, data = [] } = await getContactDetailList({
        cardIdList: cardIdList
      });

      if (status === 200) {
        const { list, contactGroupIdList } = data;
        const mergeCard = {
          cardIdList,
          nameList: [],
          emailList: [],
          telList: [],
          telTypeList: [],
          companyList: [],
          urlList: [],
          addrTypeList: [],
          postList: [],
          localityList: [],
          streetAddressList: [],
          memo: "",
          contactGroupIdList: [...new Set(contactGroupIdList)]
        };

        for (const {
          firstName,
          lastName,
          emailList,
          telList,
          telTypeList,
          company,
          organ,
          position,
          urlList,
          addrTypeList,
          postList,
          localityList,
          streetAddressList,
          memo
        } of list) {
          mergeCard.nameList = [...mergeCard.nameList, { firstName, lastName }];

          mergeCard.emailList = [...mergeCard.emailList, ...emailList];

          if (telTypeList) {
            mergeCard.telTypeList = [...mergeCard.telTypeList, ...telTypeList];
            mergeCard.telList = [...mergeCard.telList, ...telList];
          }

          if (company || organ || position) {
            mergeCard.companyList = [
              ...mergeCard.companyList,
              { company, organ, position }
            ];
          }

          if (urlList) {
            mergeCard.urlList = [...mergeCard.urlList, ...urlList];
          }

          if (addrTypeList) {
            mergeCard.addrTypeList = [
              ...mergeCard.addrTypeList,
              ...addrTypeList
            ];
            mergeCard.postList = [...mergeCard.postList, ...postList];
            mergeCard.localityList = [
              ...mergeCard.localityList,
              ...localityList
            ];
            mergeCard.streetAddressList = [
              ...mergeCard.streetAddressList,
              ...streetAddressList
            ];
          }

          if (memo) {
            if (mergeCard.memo.length <= 0) {
              mergeCard.memo = memo;
            } else {
              mergeCard.memo = `${mergeCard.memo}
${memo}`;
            }
          }
        }

        return mergeCard;
      }
    } catch (e) {
      // console.log("getContactDetailList e = ", e);
    }
  },

  /**
   * 연락처 합치기
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async mergeCard({ dispatch, getters }, cardData) {
    cardData.cardIdList.splice(cardData.cardIdList.indexOf(cardData.id), 1);
    try {
      const response = await mergeCard({ id: cardData.id, cardData });
      const { status } = response;

      const messages = {
        ERROR: i18n.t("contact.59"),
        SUCCESS: i18n.t("contact.60")
      };
      let type = "ERROR";

      if (status === 200) {
        type = "SUCCESS";
        const routeParams = getters.getRouteParams;
        const params = {
          ...routeParams,
          ...routeParams.actions
        };
        await dispatch("getCardList", params);
      }
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("mergeCard e = ", e);
    }
  },
  updateHeader({ commit }, hideColumns) {
    try {
      commit("UPDATE_DEFAULT_HEADER", hideColumns);
    } catch (e) {
      // console.log("updateHeader e = ", e);
    }
  },

  /**
   * 개인 연락처 고객 주소록으로 복사
   *
   * @param {*} param0
   * @param {*} groupId
   * @param {*} cardIdList
   */
  async copyCardsToCustomer(
    { dispatch, getters },
    { targetGroupId, cardIdList, shouldDeleteOriginalData }
  ) {
    const { status } = await copyPrivateCardsToCustomer({
      targetGroupId,
      cardIdList,
      shouldDeleteOriginalData
    });

    const messages = {
      ERROR: i18n.t("contact.88"),
      SUCCESS: i18n.t("contact.89")
    };

    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: messages.ERROR, type: "ERROR" },
        { root: true }
      );
      return false;
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );

    const routeParams = getters.getRouteParams;
    const params = {
      ...routeParams,
      ...routeParams.actions
    };
    await dispatch("getCardList", params);
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
