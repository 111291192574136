<template>
  <div class="px-3">
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("flowProjects", ["showMyProjects"]),
    title() {
      switch (this.$route.name) {
        case "flow":
          return "대시보드";
        case "flow_projects":
          if (this.showMyProjects) return "내 프로젝트";
          return "프로젝트 검색";

        default:
          return "";
      }
    }
  }
};
</script>
