var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{attrs:{"expand":"","dense":""}},[_vm._l((_vm.getNavigationCalendarGroups),function(item){return _c('v-list-group',{key:item.tutorialId,class:"cr-tutorial-calendar-" + item.tutorialId,attrs:{"value":item.tutorialId,"color":"grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-2 grey--text text--darken-3"},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.title)+" "),(hover && item.type === 'GOOGLE')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":""}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("calendar.구글_캘린더.도움말")))])]):_vm._e()],1)])],1)],1),(item.type === 'MY')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showCreateDialog = true}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1):_vm._e(),(item.type === 'SUBS')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showPublicDialog = true}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1):_vm._e(),(item.type === 'ORGAN')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();_vm.showOrganDialog = true}}},[_vm._v(" mdi-plus ")])],1)],1):_vm._e(),(item.type === 'GOOGLE')?_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-connection ")])],1):_vm._e()],1)]}}],null,true)})]},proxy:true}],null,true)},[(item.type !== 'GOOGLE')?_c('div',_vm._l((item.children),function(child){return _c('v-hover',{key:child.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"pr-2",attrs:{"link":"","ripple":false}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":child.selected,"color":child.color,"dense":""},on:{"click":function($event){return _vm.checkNode(child)}}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-regular"},[_c('span',[_vm._v(_vm._s(child.title))]),(_vm.isOrganCalendar(child))?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-domain")]):_vm._e()],1)],1),_c('CalendarEditMenu',{attrs:{"showButton":hover,"calendar":child}})],1)]}}],null,true)})}),1):_c('div',_vm._l((_vm.connections),function(connection){return _c('v-list-group',{key:connection.id,attrs:{"value":true,"color":"grey darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center pl-2",attrs:{"cols":"9"}},[_c('GoogleLogo',{attrs:{"size":"16"}}),_c('v-list-item-title',{staticClass:"ml-1 grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.getIdFromEmail(connection.email))+" ")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"1"}},[(connection.errorMessage)?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-sync-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(connection.errorMessage))])]):(!connection.loaded)?_c('v-progress-circular',{attrs:{"size":"16","width":"2","indeterminate":"","color":"grey"}}):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.syncExternalData}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("calendar.구글_캘린더.재동기화"))+" ")])],1),_c('v-list-item',{on:{"click":function () { return _vm.openDisconnectDialog(connection); }}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("login.연결_해제"))+" ")])],1)],1)],1)],1)],1)]},proxy:true}],null,true)},[_vm._l((connection.calendars),function(calendar){return [_c('v-list-item',{key:calendar.id,attrs:{"link":"","ripple":false}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"dense":"","color":calendar.color,"input-value":calendar.selected},on:{"click":function () { return _vm.updateExternalCalendarSelection({
                        calendar: calendar,
                        value: !calendar.selected
                      }); }}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(calendar.title)+" ")])],1)],1)]})],2)}),1)])}),(_vm.connections.length === 0)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.openGoogleCalendarConnectionWindow}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('GoogleLogo',{attrs:{"size":"18"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("calendar.구글_캘린더.연동"))+" ")])],1)],1),_c('v-list-item-action',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v(" mdi-connection ")])],1)],1):_vm._e()],2),(_vm.showCreateDialog)?_c('CreateCalendarDialog',{model:{value:(_vm.showCreateDialog),callback:function ($$v) {_vm.showCreateDialog=$$v},expression:"showCreateDialog"}}):_vm._e(),(_vm.showPublicDialog)?_c('PublicCalendarDialog',{attrs:{"persistent":"","max-width":"200px"},model:{value:(_vm.showPublicDialog),callback:function ($$v) {_vm.showPublicDialog=$$v},expression:"showPublicDialog"}}):_vm._e(),(_vm.showOrganDialog)?_c('OrganCalendarDialog',{attrs:{"show":_vm.showOrganDialog,"persistent":"","max-width":"200px"},on:{"closeSearchDialog":function($event){_vm.showOrganDialog = false}}}):_vm._e(),(_vm.oauth2DisconnectDialog)?_c('ExternalOAuth2DisconnectDialog',{attrs:{"connection":_vm.selectedConnection},on:{"disconnected":_vm.syncExternalData},model:{value:(_vm.oauth2DisconnectDialog),callback:function ($$v) {_vm.oauth2DisconnectDialog=$$v},expression:"oauth2DisconnectDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }