<template>
  <v-dialog
    v-model="show"
    hide-overlay
    persistent
    activator
    transition="scroll-y-transition"
    @keydown.esc="closeDialog"
  >
    <div class="cr-leave-contents">
      <v-card height="90vh" class="d-block overflow-auto">
        <v-card-title class="d-flex justify-space-between">
          <div>
            <span
              class="mr-2 text-h6 font-weight-bold"
              v-text="$t('calendar.연차관리')"
            ></span>
            <v-tooltip :right="!$vuetify.breakpoint.xs">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="openPrintConfirm()"
                  v-text="$t('calendar.101')"
                ></v-btn>
              </template>
              {{ $t("calendar.102") }}
            </v-tooltip>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="closeDialog">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            {{ $t("common.닫기") }}
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-row class="cr-option-box white box">
            <!-- 조직도 -->
            <v-col
              v-if="drawer"
              class="cr-organ-wrap"
              cols="12"
              lg="2"
              md="3"
              sm="6"
            >
              <v-treeview
                dense
                hoverable
                activatable
                transition
                return-object
                :items="organList"
                :open.sync="openItems"
                :active.sync="activeItem"
                :load-children="getOrganChildren"
                @update:active="onActive"
              >
                <template v-slot:label="{ item, active }">
                  <div class="d-flex">
                    <v-icon
                      v-if="item.parentId == 'ROOT'"
                      class="mr-2"
                      :color="active ? 'accent' : 'grey darken-3'"
                    >
                      mdi-domain
                    </v-icon>
                    <div v-text="item.name" />
                  </div>
                </template>
              </v-treeview>
              <v-divider v-if="$vuetify.breakpoint.xs" class="mt-3"></v-divider>
            </v-col>
            <!-- 리스트 -->
            <v-col class="cr-list-wrap" cols="12" lg="4" md="4" sm="6">
              <!-- 검색 툴바 -->
              <v-col class="cr-list-toolbar align-center pa-0" cols="12">
                <div class="d-flex flex-column">
                  <v-text-field
                    class="mb-2"
                    :type="searchByWord ? 'text' : 'number'"
                    min="1"
                    solo
                    hide-details
                    flat
                    background-color="grey lighten-3"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="
                      searchByWord ? $t('calendar.103') : $t('calendar.104')
                    "
                    @keydown.enter="searchUser"
                    v-model="searchWord"
                  >
                    <template slot="append">
                      <v-btn
                        icon
                        @click="toggleSearchType"
                        :title="$t('calendar.전환')"
                      >
                        <v-icon>
                          mdi-swap-horizontal
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <div class="d-flex mb-2">
                    <span
                      class="font-weight-bold ml-1 d-block"
                      v-text="
                        activeItem[0]
                          ? activeItem[0].name
                          : $t('calendar.검색결과')
                      "
                    />
                    <span
                      class="ml-2 blue--text text--darken-1"
                      v-text="userList.totalElements"
                    />
                  </div>
                </div>
              </v-col>
              <!-- 유저 목록 -->
              <v-col class="cr-table-wrap pa-0">
                <v-data-table
                  v-model="userList.selectedItems"
                  hide-default-header
                  hide-default-footer
                  single-select
                  :no-data-text="$t('calendar.105')"
                  item-class="item-row"
                  item-key="userId"
                  :loading="userList.loading"
                  :headers="userTableHeaders"
                  :items="userList.list"
                  :items-per-page="userList.pageSize"
                  :server-items-length="userList.totalElements"
                  @page-count="userList.pageCount = $event"
                  @click:row="clickRow"
                >
                  <template v-slot:[`item.accountName`]="{ item }">
                    <div class="py-2 text-left">
                      <div>
                        <span class="font-weight-bold">{{
                          item.accountName
                        }}</span>
                        <span> {{ item.jobGrade }} </span>
                      </div>
                      <span>
                        {{ item.username }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <!-- 
                      페이징
                      type : style (list, autocomplete)
                      page : 현재페이지
                      pageSize : 한페이지당 아이템 수
                      blockSize : 페이지 한 블럭당 갯수
                      totalElements : 아이템 총 갯수
                      pageClick : 페이지 이동시 라우팅 이벤트
                    -->
                    <Pagination
                      v-if="userList.pageCount > 0"
                      type="list"
                      :page.sync="userList.page"
                      :pageSize="userList.pageSize"
                      :blockSize="5"
                      :totalElements="userList.totalElements"
                      @pageClick="pageClick"
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-divider v-if="$vuetify.breakpoint.xs" class="mt-3"></v-divider>
            </v-col>
            <v-col class="cr-leave-wrap pa-0" cols="12" lg="6" md="5" sm="12">
              <LeaveInfo
                v-if="isUserSelected"
                :userId="selectedUserId"
                :searchMilli="searchMilli"
                :specialLeaveCnt.sync="specialLeaveCnt"
                :restSpecialLeaveCnt.sync="restSpecialLeaveCnt"
                :annualLeaveCnt.sync="annualLeaveCnt"
                :restLeaveCnt.sync="restLeaveCnt"
                userType="manager"
                @updateSearchMilli="updateSearchMilli"
              ></LeaveInfo>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import {
  getDefaultOrganList,
  getOrganListByParentId,
  getOrganUserList,
  getUserListWithJoinTime
} from "@/mail/api/organ.api";
import { exportUserLeaveExcel } from "@/calendar/api/leave.api";
import LeaveInfo from "@/calendar/components/event/leave/LeaveInfo.vue";
import Pagination from "@/commons/views/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";

export default {
  components: {
    LeaveInfo,
    Pagination
  },
  props: ["dialog"],
  data: () => ({
    organList: [],
    openItems: [],
    activeItem: [],
    prevActiveItem: {},
    //isSearch: false,
    drawer: true,
    isUserSelected: false,
    selectedUserId: 0,
    searchMilli: 0,
    searchByWord: true,
    searchMenuWidth: 100,
    userList: {
      list: [],
      page: 1,
      pageSize: 10,
      pageCount: 0,
      totalElements: 0,
      selectedItems: [],
      loading: false,
      selectedId: -1,
      isSearch: false
    },
    userTableHeaders: [
      {
        text: i18n.t("calendar.이름"),
        value: "accountName",
        class: "accountName ml-1"
      }
    ],
    searchWord: "",
    searchTime: "",

    specialLeaveCnt: 0,
    restSpecialLeaveCnt: 0,
    annualLeaveCnt: 0,
    restLeaveCnt: 0
  }),
  async created() {
    this.searchMilli = new Date().getTime();
    this.getOrganList();
  },
  computed: {
    ...mapGetters("calLayout", ["showLeaveDialog"]),
    show: {
      get() {
        return this.showLeaveDialog;
      },
      set() {
        this.$emit("closeDialog");
      }
    }
  },
  watch: {
    searchMenuOpened(val) {
      if (val == true) {
        const fieldWidth = this.$refs.searchField.$el.clientWidth;
        this.searchMenuWidth = fieldWidth;
      }
    }
  },
  async mounted() {},
  methods: {
    ...mapActions("confirm", ["confirm", "disagree"]),
    async getOrganList() {
      const { data = [] } = await getDefaultOrganList();

      const defaultTree = data.map(
        ({ id, parentId, name, email, childCount, children = [] }) => {
          let childrenObj = {};
          if (childCount > 0) {
            const mappedChildren = children.map(
              ({ id, parentId, name, email, childCount }) => {
                const children = childCount > 0 ? { children: [] } : {};
                return {
                  id,
                  parentId,
                  name,
                  email,
                  childCount,
                  ...children
                };
              }
            );
            childrenObj = { children: mappedChildren };
          }
          return {
            id,
            parentId,
            name,
            email,
            childCount,
            ...childrenObj
          };
        }
      );

      this.organList = defaultTree;
    },
    async getOrganChildren(item) {
      const { data = [] } = await getOrganListByParentId({ parentId: item.id });
      const children = data.map(({ id, parentId, name, email, childCount }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          name,
          email,
          childCount,
          ...childrenObj
        };
      });
      item.children = children;
    },

    /**
     * 트리의 노드 선택 시 실행되는 함수
     * 1. 트리에서 선택된 노드의 효과
     * 2. 해당 노드의 해당하는 리스트 호출
     */
    async onActive([n = {}]) {
      const { id } = n;

      // 선택된 노드를 한번 더 클릭할 시 액티브 사라짐을 방지
      if (!id) {
        // 검색 상태일 경우
        if (this.userList.isSearch) return;
        // 이전 노드 선택일경우
        this.activeItem = Object.assign({}, this.prevActiveItem);
        //return this.SET_ACTIVE_ITEM([this.getPrevActiveItem]);
      }

      this.userList.isSearch = false;
      this.searchWord = "";
      //this.selectedStatus = 0; // 사용자 상태 필터 초기화
      await this.getOrganUserList({
        page: 1,
        organId: n.id
      });
      this.prevActiveItem = n;
    },
    async getOrganUserList({
      organId,
      //pageSize = 20,
      page = 1,
      searchWord = ""
    }) {
      this.userList.loading = true;
      //commit("SET_LOADING", true);

      //pageSize = getters.getPageSize;

      const { data = [] } = await getOrganUserList({
        organId,
        pageSize: this.userList.pageSize,
        page: page - 1,
        searchWord
      });

      const { content, totalElements } = data;

      const list = content.map(
        ({ id, jobGrade, accountName, organizationName, username }) => {
          return {
            userId: id,
            valid: true,
            value: `"${accountName}" <${username}>`,
            type: "MEMBER",
            accountName: accountName,
            username: username,
            organization: organizationName,
            jobGrade
          };
        }
      );
      this.userList.list = list;
      this.userList.totalElements = totalElements;
      this.userList.loading = false;
      this.userList.page = page;
      //commit("SET_LIST", { list, totalElements, page });
    },
    clickRow(item, row) {
      this.selectedId = item.userId;
      row.select(true);
      //this.userList.selectedItems = item;
      this.selectedUserId = item.userId;
      this.searchMilli = new Date().getTime();
      this.isUserSelected = true;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    updateSearchMilli(searchMilli) {
      this.searchMilli = searchMilli;
    },
    async searchUser() {
      this.userList.isSearch = true;
      this.activeItem = [];
      this.prevSearchWord = this.searchWord;
      this.searchWord = this.searchWord.trim();
      if (this.searchWord.length == 0) {
        // 빈값일 경우 ROOT로 이동
        this.activeRoot();
        return;
      }
      await this.getUserList();
    },
    activeRoot() {
      // 처음에 root 폴더를 선택 해준다.
      const root = this.organList.filter(group => group.parentId == "ROOT");
      this.activeItem = root;

      if (root[0].children?.length > 0) this.openItems = root;
    },
    async pageClick(page) {
      this.userList.page = page;
      await this.getUserList("word");
    },
    async getUserList() {
      let result;
      if (this.searchByWord) {
        result = await getOrganUserList({
          organId: "0",
          page: this.userList.page - 1,
          pageSize: this.userList.pageSize,
          searchWord: this.userList.isSearch ? this.searchWord : ""
        });
      } else {
        result = await getUserListWithJoinTime({
          page: this.userList.page - 1,
          pageSize: this.userList.pageSize,
          searchTime: this.searchWord
        });
      }

      const { content, totalElements } = result.data;

      this.userList.list = content.map(
        ({ id, jobGrade, accountName, organizationName, username }) => {
          return {
            userId: id,
            valid: true,
            value: `"${accountName}" <${username}>`,
            type: "MEMBER",
            accountName: accountName,
            username: username,
            organization: organizationName,
            jobGrade
          };
        }
      );
      this.userList.totalElements = totalElements;
      this.isUserSelected = false;
      this.userList.selectedItems = [];
    },
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    openPrintConfirm() {
      this.openConfirmDialog("", i18n.t("calendar.102"), async () => {
        await exportUserLeaveExcel().then(result => {
          const url = window.URL.createObjectURL(
            new Blob([result.data], { type: result.headers["content-type"] })
          );
          const link = document.createElement("a");
          link.href = url;
          let fileName = this.getFileName(
            result.headers["content-disposition"]
          );
          link.download = decodeURI(fileName);
          link.click();
          window.URL.revokeObjectURL(url);
        });
      });
    },
    toggleSearchType() {
      this.searchWord = "";
      this.searchByWord == true
        ? (this.searchByWord = false)
        : (this.searchByWord = true);
    },
    getFileName(contentDisposition) {
      let fileName = contentDisposition
        .split(";")
        .filter(ele => {
          return ele.indexOf("filename") > -1;
        })
        .map(ele => {
          return ele.replace(/"/g, "").split("=")[1];
        });
      return fileName[0] ? fileName[0] : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.cr-leave-contents {
  .box {
    min-height: calc(90vh - 140px);
  }
}
</style>
