const l_state = {
  showBcc: false,
  showAttachList: false,
  showSendResult: false,
  showOnlySubject: false,
  showReserveModal: false,
  showSecureModal: false,
  // 0: 숨김, 1: 살짝보이기, 2: 완전보이기
  showRcptSelector: 1
};

// state, getters, rootState, rootGetters
const l_getters = {
  // 숨은참조 보기
  showBcc: ({ showBcc }) => showBcc,
  // 첨부파일 목록 보기
  showAttachList: ({ showAttachList }) => showAttachList,
  // 메일발송 결과 보기
  showSendResult: ({ showSendResult }) => showSendResult,
  // 제목만 보기
  showOnlySubject: ({ showOnlySubject }) => showOnlySubject,
  // 예약메일 모달창 보기
  showReserveModal: ({ showReserveModal }) => showReserveModal,
  // 보안메일 모달창 보기
  showSecureModal: ({ showSecureModal }) => showSecureModal,
  showRcptSelector: ({ showRcptSelector }) => showRcptSelector
};

const l_mutations = {
  SET_SHOW_BCC: (state, showBcc) => {
    state.showBcc = showBcc;
  },
  SET_SHOW_ATTACH_LIST: (state, showAttachList) => {
    state.showAttachList = showAttachList;
  },
  SET_SHOW_SEND_RESULT: (state, showSendResult) => {
    state.showSendResult = showSendResult;
  },
  SET_SHOW_RESERVE_MODAL: (state, showReserveModal) => {
    state.showReserveModal = showReserveModal;
  },
  SET_SHOW_SECURE_MODAL: (state, showSecureModal) => {
    state.showSecureModal = showSecureModal;
  },
  TOGGLE_SHOW_ONLY_SUBJECT: state => {
    state.showOnlySubject = !state.showOnlySubject;
  },
  SET_SHOW_RCPT_SELECTOR: (state, showRcptSelector) => {
    state.showRcptSelector = showRcptSelector;
  }
};

export { l_state, l_getters, l_mutations };
