<template>
  <v-btn
    v-if="!activated"
    v-bind="btnProps"
    class="text-body-2 grey--text"
    block
    text
    @click="activated = true"
  >
    {{ placeholder }}
  </v-btn>
  <v-textarea
    v-else-if="area"
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    ref="input"
    rows="1"
    :autofocus="autofocus"
    auto-grow
    hide-details
    filled
    dense
    class="flat-text-input rounded text-body-2"
    :placeholder="placeholder"
    @input="val => $emit('input', val)"
  ></v-textarea>
  <v-text-field
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    ref="input"
    :autofocus="autofocus"
    hide-details
    filled
    dense
    class="flat-text-input rounded text-body-2"
    :placeholder="placeholder"
    @input="val => $emit('input', val)"
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    btnProps: {
      type: Object
    },
    area: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    activate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activated: false,
      autofocus: false
    };
  },
  watch: {
    value: {
      handler(val) {
        // 한 번이라도 유효한 값이 설정되었다면 활성화 상태 유지
        if (val) {
          this.activated = this.activate || val.length > 0;
        }
      },
      immediate: true
    }
  },
  created() {
    this.autofocus = !this.activated || this.activate;
  }
};
</script>

<style scoped lang="scss">
.v-btn {
  padding: 0 8px !important;

  ::v-deep .v-btn__content {
    text-transform: none;
    justify-content: flex-start;
  }
}

.flat-text-input.v-text-field {
  &.v-input:not(.v-input--is-focused):not(.v-input--has-state) {
    ::v-deep .v-input__control .v-input__slot:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.v-input ::v-deep {
    .v-input__slot {
      background: rgba(0, 0, 0, 0.04);
      min-height: 36px !important;
      padding: 0 8px !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: hidden;

      &::after {
        bottom: 0 !important;
      }
      &::before {
        border: none;
      }
    }

    textarea {
      margin-top: 4px;
    }
  }
}
</style>
