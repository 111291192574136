<template>
  <v-hover v-slot:default="{ hover }">
    <div
      :data-id="item.id"
      :class="draggableClass"
      class="d-flex align-center justify-space-between"
    >
      <div
        class="cr-tree_node_click_area"
        :id="item.id"
        :class="`${item.id <= 0 ? 'cr-drag-drop-disabled' : ''}`"
        @click="() => nodeClick(item)"
      />
      <!-- 주소록 그룹 이름 -->
      <span
        class="cr-tree-label-text"
        v-if="!item.isEdit"
        v-text="item.groupName"
      />
      <!-- 주소록 그룹 이름 수정 필드 -->
      <v-text-field
        autofocus
        :loading="editTextLoading"
        v-if="item.isEdit"
        :label="item.groupName"
        v-on:blur="e => editContactGroup(e, item)"
        v-on:keyup.enter="e => editContactGroup(e, item)"
        v-on:keyup.esc="e => editBtnClick(e, item)"
      />
      <!-- 주소록 메뉴 -->
      <v-btn
        icon
        small
        @click.stop="e => openPositioningMenu(e, item)"
        v-if="hover && useContextMenu(item) && !item.isEdit && !editTextLoading"
      >
        <v-icon v-text="'mdi-dots-vertical'" />
      </v-btn>
    </div>
  </v-hover>
</template>

<script>
import i18n from "@/_locales";
import { privateContactGroupType } from "@/contact/constant/contactGroupType";
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    editTextLoading: false
  }),

  computed: {
    draggableClass() {
      if (["ALL", "IMPORTANT"].includes(this.item.contactGroupType))
        return "cr-dropzone-list";
      if (!privateContactGroupType[this.item.contactGroupType]) return "";
      return "cr-dropzone-list";
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("group", [
      "getContactGroupListByParentId",
      "updateIsEdit",
      "updateContactGroupName",
      "deleteContactGroup"
    ]),
    ...mapMutations("contactDialog", ["SET_DIALOG"]),

    // 트리 노드 클릭
    nodeClick({ id }) {
      let contactGroupId = id;
      switch (contactGroupId) {
        case -1:
          contactGroupId = "all";
          break;
        case -2:
          contactGroupId = "important";
          break;
        case -3:
          contactGroupId = "new";
          break;
      }

      this.$router.push({
        name: "contact_list",
        params: { contactGroupId, page: 1 }
      });
    },
    useContextMenu(item) {
      return privateContactGroupType[item.contactGroupType];
    },

    // 주소록 그룹 메뉴
    openPositioningMenu(e, item) {
      if (!privateContactGroupType[item.contactGroupType]) return;

      e.preventDefault();
      this.closePositioningMenu();
      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList: [
          {
            label: i18n.t("contact.44"),
            func: e => {
              this.editBtnClick(e, item);
            }
          },
          {
            label: i18n.t("contact.45"),
            func: e => {
              this.addContactGroup(e, item);
            }
          },
          {
            label: i18n.t("contact.46"),
            func: () => {
              this.removeContactGroup(item);
            }
          }
        ]
      });
    },

    // 그룹 수정 버튼
    editBtnClick(e, { id }) {
      e.stopPropagation();
      this.closePositioningMenu();
      this.updateIsEdit(id);
    },
    // 그룹 이름 수정
    async editContactGroup(e, { id, groupName }) {
      const value = e.target.value.trim();
      if (!value || groupName === value) {
        await this.updateIsEdit(id);
        this.editTextLoading = false;
      } else if (!this.editTextLoading) {
        this.editTextLoading = true;
        const result = await this.updateContactGroupName({
          groupName: e.target.value,
          contactGroupId: id
        });

        if (result) {
          await this.updateIsEdit(id);
          this.editTextLoading = false;
        }
      }
    },
    // 하위 그룹 추가 버튼
    addContactGroup(e, parentContactGroup) {
      e.stopPropagation();
      this.closePositioningMenu();

      this.SET_DIALOG({
        title: i18n.t("contact.47"),
        type: "addContactGroup",
        params: {
          parentContactGroup
        },
        confirmCallbackFunc: async () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          const {
            contactGroupType,
            childCount,
            children,
            id
          } = parentContactGroup;

          if (
            privateContactGroupType[contactGroupType] &&
            childCount > 0 &&
            childCount !== children.length
          ) {
            await this.getContactGroupListByParentId({ parentId: id });
          }
          this.$emit("addOpenItem", parentContactGroup);
        }
      });
    },
    // 개인 주소록 그룹 삭제
    removeContactGroup({ groupName, children, id: groupId, parentId }) {
      if (children) {
        // 얼럿
        this.confirm({
          message: i18n.t("contact.48"),
          showCloseBtn: false
        });
        return;
      }

      this.confirm({
        message: i18n.t("contact.49", { value: groupName }),
        callback: async () => {
          await this.deleteContactGroup({
            groupId,
            parentId
          });
        },
        showCloseBtn: true
      });
    },

    // Dialog 닫기
    closeDialog() {
      this.dialog.showDialog = false;
    }
  }
};
</script>
