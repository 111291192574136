const state = {
  show: false,
  editorId: "",
  content: "",
  height: 619
};

const getters = {
  isShow: ({ show }) => show,
  getEditorId: ({ editorId }) => editorId,
  getContent: ({ content }) => content,
  getHeight: ({ height }) => height
};

// state값 수정
const mutations = {
  SET_SHOW: (state, show) => {
    state.show = show;
  },
  SET_INIT: (state, { editorId, height, content }) => {
    state.editorId = editorId;
    state.height = height;
    state.content = content;
    state.show = true;
  },
  SET_HEIGHT: (state, height) => {
    state.height = height;
  },
  SET_CONTENT: (state, content) => {
    state.content = content;
  },
  RESET_EDITOR: state => {
    state.show = false;
    state.editorId = "";
    state.content = "";
    state.height = 619;
  }
};

// mutations 호출 및 비동기 처리
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
