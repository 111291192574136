<template>
  <v-btn
    icon
    :disabled="disabled"
    class="cr-avatar-activator"
    @click="e => $emit('click', e)"
  >
    <div v-if="isEmoji" :style="emojiStyle" class="black--text">
      {{ avatar.emoji }}
    </div>
    <v-avatar v-else :size="avatar.size" :color="avatar.color">
      <span :style="textStyle">
        {{ title }}
      </span>
    </v-avatar>
  </v-btn>
</template>

<style lang="scss" scoped>
.cr-avatar-activator::v-deep {
  .v-avatar {
    border-radius: 16%;
  }
}
</style>

<script>
import { invertColor } from "@/commons/utils/invertColor";

export default {
  props: {
    avatar: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      return this.avatar?.title?.slice?.(0, 2) || "";
    },
    isEmoji() {
      return this.avatar?.emoji;
    },
    emojiStyle() {
      const fontSize = this.avatar.size + 2;
      return `font-size: ${fontSize}px; margin-top: -3px;`;
    },
    textStyle() {
      const { size, color } = this.avatar;
      let fontSize = size / 3;
      if (fontSize < 10) fontSize = 10;

      return `font-size: ${fontSize}px; color: ${invertColor(color)};`;
    }
  }
};
</script>
