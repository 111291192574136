<template>
  <div>
    <div class="px-2 py-0 d-flex">
      <v-btn
        large
        block
        depressed
        height="48"
        class="mr-1 primary"
        :disabled="!hasEditPermission"
        @click="addTask"
      >
        <h4 class="d-flex align-center">
          <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
          업무등록
        </h4>
      </v-btn>

      <TreeView />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TreeView from "./treeview";

export default {
  components: { TreeView },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapMutations("flowTasks", ["RESET_NEW_TASK"]),
    addTask() {
      this.RESET_NEW_TASK();
      this.SHOW_DIALOG({ headline: "업무 등록", type: "addTask" });
    }
  }
};
</script>
