<template>
  <v-btn icon small @click.stop="menuClick">
    <v-icon>mdi-dots-vertical</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { boardTypeObj: { FOLDER: "폴더", BOARD: "보드" } };
  },
  methods: {
    ...mapMutations("todoDialog", ["SET_DIALOG"]),
    ...mapMutations("todoBoard", ["UPDATE_BOARD"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("todoBoard", ["deleteBoard"]),
    ...mapActions("positioningMenu", ["positioningMenu"]),
    // 보드, 폴더 추가
    addBoard(type) {
      const { id: parentId, workspaceId } = this.item;
      const dParams = { show: true, type: "addBoard" };
      dParams["headline"] = `${this.boardTypeObj[type]} 추가`;
      dParams["fullscreen"] = type === "BOARD";
      dParams["showConfirmBtn"] = type === "FOLDER";
      dParams["params"] = { type, workspaceId, parentId };

      this.SET_DIALOG(dParams);
    },
    // 보드, 폴더 삭제
    _deleteBoard() {
      const { id: boardId, title, boardType, childCount } = this.item;
      let message = "하위 요소가 존재하면 삭제할 수 없습니다.";
      if (childCount > 0) return this.openSnackbar({ message });

      message = `${title} ${this.boardTypeObj[boardType]}를 삭제 하시겠습니까?`;
      const callback = () => this.deleteBoard({ boardId, boardType });
      this.confirm({ message, callback });
    },
    // 이름변경 토글버튼
    toggleEdit() {
      const { id: boardId, edit } = this.item;
      this.UPDATE_BOARD({ boardId, edit: !edit });
    },
    menuClick(e) {
      const { boardType } = this.item;
      const hideItem = boardType === "BOARD";
      const itemList = [
        {
          label: "하위 폴더 추가",
          hideItem,
          func: () => this.addBoard("FOLDER")
        },
        {
          label: "하위 보드 추가",
          hideItem,
          func: () => this.addBoard("BOARD")
        },
        { label: "이름 변경", func: this.toggleEdit },
        { label: "삭제", func: this._deleteBoard }
      ];

      const { left: x, top: y } = e.target.getBoundingClientRect();
      this.positioningMenu({ x, y, itemList });
    }
  }
};
</script>
