export default [
  {
    path: "/customer/list",
    name: "customer",
    component: () =>
      import(/* webpackChunkName: "mail-view" */ "../views/CustomerMain.vue"),
    children: [
      {
        path: ":type/:groupId/:page",
        name: "customer_list"
      },
      {
        path: ":type/:groupId/:page/:actionObj",
        name: "customer_list_action"
      }
    ]
  },
  {
    path: "/customer/print/:groupId/:page/:actionObj",
    name: "customer_print",
    component: () =>
      import(
        /* webpackChunkName: "mail-view" */ "../views/components/list/PrintCardList.vue"
      )
  },
  {
    path: "/customer/config/:tabType",
    name: "customer_config",
    component: () =>
      import(/* webpackChunkName: "mail-view" */ "../views/CustomerMain.vue")
  }
];
