import googleHttp from "@/commons/api/external/googleHttp";

const drivePrefix = "/drive/v3/files";

// 조회시 쿼리 String
const DEFAULT_ORDER_BY = "folder,modifiedTime desc";
const DEFALUT_FIELDS =
  "files(id,name,mimeType,parents,modifiedTime,owners(displayName),size,webViewLink,exportLinks,createdTime,shared,ownedByMe,shortcutDetails)";
const DEFAULT_QUERY = "trashed = false";
const IN_ROOT = "'root' in parents";
const IN_SHARED = "sharedWithMe and not 'me' in owners";

// 드라이브 정보 가져오기
const getDriveDetailById = id => {
  let query = `fileId=${id}`;
  return googleHttp.get(`${drivePrefix}/fileId?${query}`);
};

// 기본 드라이브폴더 목록 조회 ok
const getRootDrive = () => {
  let query = `orderBy=${DEFAULT_ORDER_BY}`;
  query += `&q=${DEFAULT_QUERY} and ${IN_ROOT}`;
  query += `&fields=${DEFALUT_FIELDS}`;

  return googleHttp.get(`${drivePrefix}?${query}`);
};

// 기본 드라이브폴더 목록 조회 ok
const getShareDrive = () => {
  let query = `orderBy=${DEFAULT_ORDER_BY}`;
  query += `&q=${DEFAULT_QUERY} and ${IN_SHARED}`;
  query += `&fields=${DEFALUT_FIELDS}`;

  return googleHttp.get(`${drivePrefix}?${query}`);
};

// 해당 아이디의 드라이브 리스트 조회
const getFilesByParentId = parentId => {
  let query = `orderBy=${DEFAULT_ORDER_BY}`;
  query += `&q=${DEFAULT_QUERY} and '${parentId}' in parents`;
  query += `&fields=${DEFALUT_FIELDS}`;
  return googleHttp.get(`${drivePrefix}?${query}`);
};

// 검색
const getSearchDrive = ({
  fileType,
  title,
  includeWord,
  startDate,
  endDate
}) => {
  let q = `q=${DEFAULT_QUERY}`;
  // case "document":
  switch (fileType) {
    case "image":
      q += ` and (mimeType contains 'image/')`;
      break;
    case "folder":
      q += ` and (mimeType = 'application/vnd.google-apps.folder')`;
      break;
    case "document":
      q += ` and (mimeType = 'application/vnd.google-apps.document' or mimeType = 'application/msword' or mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' or mimeType = 'application/vnd.oasis.opendocument.text' or mimeType = 'text/plain' or mimeType = 'rtp' or mimeType = 'text/x-tex' or mimeType = 'application/x-tex' or mimeType = 'application/vnd.ms-works' or mimeType = 'application/wordperfect' or mimeType = 'application/vnd.wordperfect' or mimeType = 'application/x-iwork-pages-sffpages' or mimeType = 'application/x-hwp' or mimeType = 'application/haansofthwp' or mimeType = 'application/vnd.hancom.hwp')`;
      break;
    case "video":
      q += ` and (mimeType contains 'video/')`;
      break;
    case "pdf":
      q += ` and (mimeType = 'application/pdf')`;
      break;
    case "archive":
      q += ` and (mimeType = 'application/zip' or mimeType = 'application/x-7z-compressed' or mimeType = 'application/arj' or mimeType = 'application/x-deb' or mimeType = 'application/x-debian-package' or mimeType = 'application/x-newton-compatible-pkg' or mimeType = 'application/rar' or mimeType = 'application/x-rpm' or mimeType = 'application/x-tar' or mimeType = 'application/x-zip' or mimeType = 'application/x-gzip' or mimeType = 'application/x-compress')`;
      break;
    case "presentation":
      q += ` and (mimeType = 'application/vnd.google-apps.presentation' or mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation' or mimeType = 'application/vnd.ms-powerpoint.presentation.macroenabled.12' or mimeType = 'application/vnd.ms-powerpoint' or mimeType = 'application/vnd.ms-xpsdocument' or mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.template' or mimeType = 'application/vnd.ms-powerpoint.template.macroenabled.12' or mimeType = 'application/vnd.ms-officetheme' or mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' or mimeType = 'application/vnd.ms-powerpoint.slideshow.macroenabled.12' or mimeType = 'application/vnd.ms-powerpoint.addin.macroenabled.12' or mimeType = 'application/x-iwork-keynote-sffkey' or mimeType = 'application/vnd.apple.keynote' or mimeType = 'application/vnd.oasis.opendocument.presentation')`;
      break;
    case "spreadsheet":
      q += ` and (mimeType = 'application/vnd.google-apps.spreadsheet' or mimeType = 'application/vnd.oasis.opendocument.spreadsheet' or mimeType = 'text/tab-separated-values' or mimeType = 'application/vnd.ms-excel' or mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' or mimeType = 'text/csv' or mimeType = 'text/tab-separated-values' or mimeType = 'application/x-iwork-numbers-sffnumbers')`;
      break;

    default:
      break;
  }

  if (title) q += ` and name contains '${title}'`;
  if (includeWord) q += ` and fullText contains '${includeWord}'`;
  if (title) q += ` and name contains '${title}'`;
  if (startDate) {
    q += ` and modifiedTime > '${startDate}'`;
    if (endDate) {
      q += ` and modifiedTime < '${endDate}'`;
    }
  }

  let query = `orderBy=${includeWord ? "" : DEFAULT_ORDER_BY}`;
  if (q.length > 0) query += `&${q}`;
  query += `&fields=${DEFALUT_FIELDS}`;

  return googleHttp.get(`${drivePrefix}?${query}`);
};

export {
  getDriveDetailById,
  getRootDrive,
  getShareDrive,
  getFilesByParentId,
  getSearchDrive
};
