<template>
  <v-card flat :loading="isLoading">
    <template #progress>
      <v-overlay absolute color="transparent">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </template>
    <v-card-text>
      <v-row dense>
        <!-- [타이틀] 연차 적용 기간 -->
        <v-col cols="auto">
          <v-icon>
            mdi-calendar-outline
          </v-icon>
          <span class="subtitle-1">
            {{ $t("calendar.86") }}<span class="mx-1">:</span>{{ calcRange }}
          </span>
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <!-- 관리자 메뉴 -->
          <div class="d-flex justify-end">
            <v-btn
              v-if="userType === 'manager'"
              icon
              :disabled="!hasPrev"
              @click="go('prev')"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn v-if="userType === 'manager'" icon @click="go('next')"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
        </v-col>

        <!-- 일반 정보 -->
        <v-col cols="12" class="">
          <v-card outlined>
            <v-row no-gutters>
              <v-col cols="4" class="text-center pa-3">
                <div>
                  <div
                    class="font-weight-bold grey--text text--darken-1"
                    style="min-height:22px;"
                  >
                    {{ joinDate }}
                  </div>
                  <v-chip
                    small
                    class="ma-2 mb-0"
                    color="primary"
                    v-text="$t('calendar.입사일')"
                  ></v-chip>
                </div>
              </v-col>
              <v-col cols="4" class="text-center pa-3">
                <div>
                  <div
                    class="font-weight-bold grey--text text--darken-1"
                    style="min-height:22px;"
                  >
                    {{ yearsWithCompany }}
                  </div>
                  <v-chip
                    small
                    class="ma-2 mb-0"
                    v-text="$t('calendar.년차')"
                  ></v-chip>
                </div>
              </v-col>
              <v-col cols="4" class="text-center pa-3">
                <div>
                  <div
                    class="font-weight-bold grey--text text--darken-1"
                    style="min-height:22px;"
                  >
                    {{ companyLeaveType }}
                  </div>
                  <v-chip
                    small
                    class="ma-2 mb-0"
                    v-text="$t('calendar.휴가기준')"
                  ></v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card outlined id="leave-info" class="card-wrap">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="blue-grey fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-3 blue-grey--text">
                <h5
                  class="text-truncate"
                  style="line-height:normal;"
                  v-text="$t('calendar.지급연차')"
                ></h5>
                <div class="d-flex mt-2">
                  <h1>{{ annualLeaveCnt }}</h1>
                </div>

                <v-btn
                  v-if="hasModifyPrivilege"
                  absolute
                  small
                  depressed
                  @click="correctionDialog = true"
                  style="top:8px; right:8px;"
                >
                  {{ $t("calendar.보정") }}
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card outlined class="card-wrap">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-3 primary--text">
                <h5
                  class="text-truncate text-uppercase"
                  style="line-height:normal;"
                  v-text="$t('calendar.88')"
                ></h5>
                <div class="d-flex mt-2">
                  <h1>{{ restLeaveCnt }}</h1>
                </div>
              </div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card outlined id="special-leave" class="card-wrap">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="blue-grey fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-3 blue-grey--text">
                <h5
                  class="text-truncate"
                  style="line-height:normal;"
                  v-text="$t('calendar.특별휴가')"
                ></h5>
                <div class="d-flex mt-2">
                  <h1>{{ specialLeaveCnt }}</h1>
                </div>

                <v-btn
                  absolute
                  small
                  right
                  top
                  depressed
                  @click="() => openSpecialLeaveDialog()"
                  style="top:8px; right:8px;"
                >
                  {{ $t("calendar.신청") }}
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card outlined class="card-wrap">
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-3 primary--text">
                <h5
                  class="text-truncate text-uppercase"
                  style="line-height:normal;"
                  v-text="$t('calendar.138')"
                ></h5>
                <div class="d-flex mt-2">
                  <h1>{{ restSpecialLeaveCnt }}</h1>
                </div>
              </div>
            </v-row>
          </v-card>
        </v-col>

        <!-- 사용 내역 -->
        <v-col cols="12">
          <v-card outlined>
            <v-tabs color="primary accent-4">
              <v-tab v-text="$t('calendar.89')"></v-tab>
              <v-tab v-text="$t('calendar.90')"></v-tab>
              <v-tab-item>
                <v-container fluid>
                  <v-card flat class="vacation-list">
                    <v-simple-table
                      class="font-weight-bold grey--text text--darken-1"
                      fixed-header
                      dense
                      height="256px"
                      v-if="usedLeaves.length > 0"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left"
                              v-text="$t('calendar.일자')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.사용일수')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.유형')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.상태')"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in usedLeaves" :key="item.name">
                            <td class="text-left">
                              {{
                                dateFormatRange({
                                  format: "MMMM Do",
                                  leave: item
                                })
                              }}
                            </td>
                            <td class="text-left">{{ item.count }}</td>
                            <td class="text-left">
                              <span
                                v-if="item.type == 'SPECIAL'"
                                v-text="$t('calendar.특별휴가')"
                              ></span>
                              <span v-else v-text="$t('calendar.연차')"></span>
                            </td>
                            <td>
                              {{ $t(`calendar.연차_상태.${item.status}`) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      class="text-center grey--text pt-4"
                      v-else
                      v-text="$t('calendar.91')"
                    ></div>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container fluid>
                  <v-card flat class="vacation-list overflow-auto">
                    <v-simple-table
                      class="font-weight-bold grey--text text--darken-1"
                      fixed-header
                      dense
                      height="256px"
                      v-if="specialLeaves.length > 0"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left"
                              style="width: 40%;"
                              v-text="$t('calendar.사유')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.수량')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.발생일')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.만료일')"
                            ></th>
                            <th
                              class="text-left"
                              v-text="$t('calendar.상태')"
                            ></th>
                            <th class="text-center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in specialLeaves" :key="item.id">
                            <td>{{ item.reason }}</td>
                            <td>{{ item.amount }}</td>
                            <td>
                              {{
                                dateFormat({
                                  format: "LL",
                                  date: item.createdDate
                                })
                              }}
                            </td>
                            <td>
                              {{
                                dateFormat({
                                  format: "LL",
                                  date: item.expiredDate
                                })
                              }}
                            </td>
                            <td>
                              {{ $t(`calendar.연차_상태.${item.status}`) }}
                            </td>
                            <td>
                              <v-btn
                                small
                                icon
                                :title="$t('common.삭제')"
                                @click="deleteSpecialLeave(item)"
                                ><v-icon>mdi-trash-can</v-icon></v-btn
                              >
                              <v-btn
                                small
                                icon
                                class="ml-2"
                                :title="$t('common.수정')"
                                v-if="item.status === 'READY'"
                                @click="openSpecialLeaveDialog(item)"
                                ><v-icon>mdi-pencil</v-icon></v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      class="text-center grey--text pt-4"
                      v-else
                      v-text="$t('calendar.91')"
                    ></div>
                  </v-card>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- 특별휴가 신청 다이얼로그 -->
    <SpecialLeaveApplicationDialog
      v-if="specialDialog"
      v-model="specialDialog"
      :userId="userId"
      :specialLeaveToUpdate="specialLeaveToUpdate"
      @confirm="fetchLeaveInfo"
    />

    <!-- 보정 연차 다이얼로그 -->
    <LeaveCorrectionDialog
      v-if="correctionDialog"
      v-model="correctionDialog"
      :userId="userId"
      :leaveCorrection="leaveCorrection"
      :searchMilli="searchMilli"
      @correct="fetchLeaveInfo"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { deleteSpecialLeave, getLeave } from "@/calendar/api/leave.api";
import LeaveCorrectionDialog from "@/calendar/components/event/leave/LeaveCorrectionDialog.vue";
import SpecialLeaveApplicationDialog from "@/calendar/components/event/leave/SpecialLeaveApplicationDialog.vue";

export default {
  components: { SpecialLeaveApplicationDialog, LeaveCorrectionDialog },
  props: [
    "userId",
    "searchMilli",
    "userType",
    "specialLeaveCnt",
    "restSpecialLeaveCnt",
    "annualLeaveCnt",
    "restLeaveCnt"
  ],
  data() {
    return {
      isLoading: false,
      joinDate: "",
      yearsWithCompany: "",
      companyLeaveType: "",
      settlementDate: "",
      calcRange: "",
      usedLeaves: [],
      specialLeaves: [],
      usedLeave: 0,
      leaveCorrection: 0.0,
      specialDialog: false,
      specialLeaveToUpdate: null,
      correctionDialog: false,
      hasModifyPrivilege: false,
      hasPrev: false
    };
  },
  watch: {
    userId: {
      handler() {
        if (!this.userId) return;

        this.fetchLeaveInfo();
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"])
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("cal", ["SHOW_LOADING_OVERLAY", "HIDE_LOADING_OVERLAY"]),
    async fetchLeaveInfo() {
      this.isLoading = true;
      const param = {
        userId: this.userId,
        searchMilli: this.searchMilli
      };
      const { data } = await getLeave(param);
      if (!data) return;

      const {
        joinDate,
        companyLeaveType,
        settlementDate,
        calcStart,
        calcEnd,
        usedLeaves,
        specialLeaves,
        annualLeave,
        usedLeave,
        leaveCorrection,
        usedSpecialLeave
      } = data;

      this.hasModifyPrivilege = this.getUserInfo.isHr;
      const join = moment(joinDate);
      this.joinDate = join.format("LL");
      this.yearsWithCompany = this.$t("calendar.93", {
        value: (moment().diff(join, "month") / 12.0).toFixed(1)
      });
      if (companyLeaveType === "ACCOUNT_DATE") {
        this.companyLeaveType = `${this.$t("calendar.회계년도")}( ${moment(
          settlementDate
        ).format("MMMM Do")} )`;
      } else {
        this.companyLeaveType = `${this.$t("calendar.입사일")}( ${moment(
          joinDate
        ).format("MMMM Do")} )`;
      }
      this.settlementDate = settlementDate;

      this.calcRange = `${moment(calcStart).format("LL")} ~ ${moment(
        calcEnd
      ).format("LL")}`;
      this.usedLeaves = usedLeaves.map(item => ({
        startDt: item.dtStart,
        endDt: item.dtEnd,
        count: item.leaveCnt,
        type: item.leaveType,
        status: item.leaveStatus
      }));
      this.specialLeaves = specialLeaves;
      this.usedLeave = usedLeave;

      const totalSpecialLeave = specialLeaves
        .filter(specialLeave => specialLeave.status === "CONFIRMED")
        .map(specialLeave => specialLeave.amount)
        .reduce((prev, next) => prev + next, 0);

      this.$emit("update:annualLeaveCnt", annualLeave);
      this.$emit("update:restLeaveCnt", annualLeave - usedLeave);
      this.$emit("update:specialLeaveCnt", totalSpecialLeave);
      this.$emit(
        "update:restSpecialLeaveCnt",
        totalSpecialLeave - usedSpecialLeave
      );

      this.hasPrev = join.isBefore(calcStart);
      this.leaveCorrection = leaveCorrection;
      this.isLoading = false;
    },
    async go(direction) {
      let searchDate = moment(this.searchMilli);
      if (direction === "next") {
        searchDate.add(1, "years");
      } else {
        searchDate.subtract(1, "years");
      }
      const searchMilli = searchDate.valueOf();
      this.$emit("updateSearchMilli", searchMilli);
      this.$nextTick(() => {
        this.fetchLeaveInfo();
      });
    },
    openSpecialLeaveDialog(specialLeaveToUpdate) {
      this.specialLeaveToUpdate = specialLeaveToUpdate
        ? specialLeaveToUpdate
        : null;
      this.specialDialog = true;
    },
    dateFormat({ date, format = "MMMM Do" }) {
      return moment(date).format(format);
    },
    deleteSpecialLeave(item) {
      this.confirm({
        headline: "",
        message: this.$t("calendar.98", { value: item.reason }),
        callback: async () => {
          this.SHOW_LOADING_OVERLAY();
          const response = await deleteSpecialLeave(item.id);
          this.HIDE_LOADING_OVERLAY();

          const result = response.status === 200;
          this.openSnackbar({
            message: result ? this.$t("calendar.99") : this.$t("calendar.100"),
            type: result ? "SUCCESS" : "ERROR"
          });

          if (result) {
            this.fetchLeaveInfo();
          }
        }
      });
    },
    dateFormatRange({ leave, format = "MMMM Do" }) {
      let startDt = moment(leave.startDt);
      let endDt = moment(leave.endDt);

      // 기간이 하루 일때 'MMMM Do'
      if (startDt.format("MMMM Do") === endDt.format("MMMM Do")) {
        return startDt.format(format);
      }

      // 기간 하루 이상일때 'MMMM Do ~ MMMM Do'
      return (
        moment(startDt).format(format) + " ~ " + moment(endDt).format(format)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
/* 지급 연차, 특별휴가, 잔여 연차 */
.card-wrap {
  overflow: hidden;
}
/* 사용 내역 */
.vacation-list {
  min-height: 140px;
  .v-data-table ::v-deep td {
    font-size: 12px;
  }
}

.fixed-tabs-bar .v-tabs__bar {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 2;
}
</style>
