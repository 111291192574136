import {
  availableUrl,
  getLinkShare,
  addLinkShare,
  updateLinkShare,
  deleteLinkShare,
  sendVerificationCode,
  getMailView
} from "../../api/linkShare.api";
import router from "@/commons/router";
import { getFullDate } from "@/commons/utils/moment";

const state = {
  showView: false,
  linkShare: {},
  mail: {}
};

const getters = {
  showView: ({ showView }) => showView,
  linkShare: ({ linkShare }) => linkShare,
  mail: ({ mail }) => mail
};

const mutations = {
  SET_LINK_SHARE: (state, linkShare) => {
    state.linkShare = linkShare;
  },
  SET_MAIL: (state, mail) => {
    state.showView = true;
    state.mail = mail;
  }
};

const actions = {
  // 다이얼로그
  async getLinkShare({ dispatch }, mailId) {
    const { data, status } = await getLinkShare(mailId);
    if (status === 200) return data;

    dispatch(
      "snackbar/openSnackbar",
      { message: "링크를 만들 수 없습니다.", type: "ERROR" },
      { root: true }
    );
  },
  async addLinkShare(action, params) {
    const { status } = await addLinkShare(params);
    return status === 201;
  },
  async updateLinkShare(action, params) {
    const { status } = await updateLinkShare(params);
    return status === 201;
  },
  deleteLinkShare({ dispatch }, { id, callback }) {
    dispatch(
      "confirm/confirm",
      {
        message: "링크를 삭제 하시겠습니까?",
        callback: async () => {
          const { status } = await deleteLinkShare(id);

          let message = "링크를 삭제할 수 없습니다.";
          let type = "ERROR";
          if (status === 204) {
            message = "링크를 삭제 했습니다.";
            type = "SUCCESS";
            callback();
          }

          dispatch("snackbar/openSnackbar", { message, type }, { root: true });
        }
      },
      { root: true }
    );
  },

  // 공유 화면 시작
  async availableUrl({ commit, dispatch }) {
    const {
      params: { params }
    } = router.currentRoute;
    const { data, status } = await availableUrl(params);

    switch (status) {
      case 403:
      case 404:
        router.push({ name: "notFound" });
        break;
      case 200: {
        const { linkShare, mail } = data;

        commit("SET_LINK_SHARE", linkShare);
        if (mail) {
          commit("SET_MAIL", {
            ...mail,
            from: mail?.from[0],
            to: mail?.to,
            cc: mail?.cc,
            bcc: mail?.bcc,
            subject: mail?.subject,
            receivedTimeMillis: getFullDate(mail?.receivedTimeMillis || 0),
            attachList: mail?.attachList || [],
            readStatus: 1
          });
        }

        dispatch("splash/setShowSplash", false, { root: true });
      }
    }
  },
  /** 인증 부분 */
  async sendVerificationCode(action, email) {
    const {
      params: { params }
    } = router.currentRoute;
    return await sendVerificationCode(email, params);
  },
  /** 메일보기 */
  async getMailView({ commit, dispatch }, { email, verificationCode }) {
    const {
      params: { params }
    } = router.currentRoute;
    // 404, 403, 409, 200
    const { data: mail, status } = await getMailView(
      email,
      params,
      verificationCode
    );

    let message = "인증 코드가 잘못되었거나 시간이 만료되었습니다.";
    let type = "ERROR";
    switch (status) {
      case 200: {
        commit("SET_MAIL", {
          ...mail,
          from: mail?.from[0],
          to: mail?.to,
          cc: mail?.cc,
          bcc: mail?.bcc,
          subject: mail?.subject,
          receivedTimeMillis: getFullDate(mail?.receivedTimeMillis || 0),
          attachList: mail?.attachList || [],
          readStatus: 1
        });
        return true;
      }
      case 404:
        message = "공유 정보를 찾을 수 없습니다.";
        break;
      case 403:
        message = "접근 권한이 없습니다.";
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
