<template>
  <v-btn icon small @click.stop="click">
    <v-icon :size="size">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    icon() {
      return "mdi-plus";
    },
    size() {
      return "";
    }
  },
  methods: {
    click() {}
  }
};
</script>
