<template>
  <!-- 상단 app-bar -->
  <v-app-bar app :class="appbarCls" :clipped-left="$vuetify.breakpoint.lgAndUp">
    <v-app-bar-nav-icon
      v-show="showNavIcon"
      @click.stop="$emit('toggle-drawer')"
    />
    <v-toolbar-title
      v-if="!$vuetify.breakpoint.xs"
      style="width: 187px; height: 48px; cursor: pointer;"
      class="ml-0 pl-1 mr-2"
      @click="$router.push({ name: route })"
    >
      <div class="cr-toolbar-title-wrapper d-flex align-center" :style="style">
        <span class="hidden-sm-and-down title ml-0 mr-1" v-text="companyName" />
        <span class="font-weight-light" v-text="moduleName" />
      </div>
    </v-toolbar-title>

    <!-- 각 모듈별 앱바 -->
    <MailAppbar v-if="route == 'mail'" :addCls.sync="addCls" />
    <CalAppbar v-if="route == 'cal'" :addCls.sync="addCls" />
    <ContactAppbar v-if="route == 'contact'" :addCls.sync="addCls" />
    <DriveAppbar v-if="route == 'drive'" :addCls.sync="addCls" />
    <BoardAppbar v-if="route == 'board'" :addCls.sync="addCls" />
    <ToDoAppbar v-if="route == 'todo'" :addCls.sync="addCls" />
    <FlowAppbar v-if="route == 'flow'" :addCls.sync="addCls" />
    <ResourceAppbar v-if="route == 'resource'" :addCls.sync="addCls" />
    <ApprovalAppbar v-if="route == 'approval'" :addCls.sync="addCls" />
    <CustomerAppbar v-if="route == 'customer'" :addCls.sync="addCls" />
    <v-spacer />

    <!-- 앱바 오른쪽 메뉴 -->
    <AppbarMenu :route="route" />
  </v-app-bar>
</template>

<style lang="scss">
// side panel 때문에 right는 무조건 0으로
.v-application {
  > .v-application--wrap {
    .v-app-bar {
      z-index: 0;
      right: 0px !important;

      &.appbar--mail {
        z-index: 1;
      }
      .cr-toolbar-title-wrapper {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import AppbarMenu from "./appbarMenu";
import MailAppbar from "@/mail/views/layout/Appbar";
import CalAppbar from "@/calendar/views/layout/Appbar.vue";
import ContactAppbar from "@/contact/views/components/Appbar";
import DriveAppbar from "@/drive/views/layout/Appbar";
import BoardAppbar from "@/board/views/layout/Appbar";
import ToDoAppbar from "@/todo/views/layout/Appbar";
import FlowAppbar from "@/flow/views/layout/Appbar";
import ResourceAppbar from "@/resource/views/layout/Appbar";
import ApprovalAppbar from "@/approval/views/layout/Appbar";
import CustomerAppbar from "@/customer/views/layout/Appbar";

export default {
  components: {
    AppbarMenu,
    MailAppbar,
    CalAppbar,
    ContactAppbar,
    DriveAppbar,
    BoardAppbar,
    ToDoAppbar,
    FlowAppbar,
    ResourceAppbar,
    ApprovalAppbar,
    CustomerAppbar
  },
  props: {
    route: {
      type: String,
      default: ""
    }
  },
  data() {
    return { addCls: "" };
  },
  computed: {
    ...mapGetters("auth", ["getCompanyInfo"]),
    appbarCls() {
      let str = "";
      if (this.route === "mail") str += " appbar--mail";
      if (this.addCls) str += ` ${this.addCls}`;
      if (["account", "home"].includes(this.route)) str += ` white`;

      return str;
    },
    style() {
      if (this.route === "home") {
        const sirteamLogo = require("@/commons/assets/logo-text.png");
        return `margin-top: 12px; background-image: url(${sirteamLogo})`;
      }

      const { logoWebAppBar } = this.getCompanyInfo;
      if (!logoWebAppBar) return "";

      return `background-image: url(data:image/png;base64,${logoWebAppBar})`;
    },
    showNavIcon() {
      return this.route !== "account" && this.route !== "home";
    },
    companyName() {
      if (this.style) return "";
      return this.getCompanyInfo.companyNameEng || "Crinity";
    },
    moduleName() {
      if (this.style) return "";
      switch (this.route) {
        case "mail":
          return "Mail";
        case "cal":
          return "Calendar";
        case "contact":
          return "Contacts";
        case "drive":
          return "Drive";
        case "board":
          return "Board";
        case "todo":
          return "Workflow";
        case "account":
          return "Account";
        case "home":
          return "Home";
        case "resource":
          return "Resource";
        case "approval":
          return "Approval";
        case "customer":
          return "Customer";
        default:
          return "";
      }
    }
  },
  watch: {
    route() {
      this.addCls = "";
    }
  }
};
</script>
