import i18n from "@/_locales";
import {
  getExternalList,
  addExternalMail,
  updateExternalMail,
  deleteExternalMail,
  fetchExternalMail
} from "../../api/external.api";

const state = {
  init: false,
  loading: false,
  list: [],
  totalElements: 0,
  serverNames: [
    { text: i18n.t("mail.32"), value: "custom" },
    { text: i18n.t("mail.576"), value: "hiworks" },
    { text: i18n.t("mail.343"), value: "gmail" },
    { text: i18n.t("mail.344"), value: "naver" },
    { text: i18n.t("mail.345"), value: "daum" },
    { text: i18n.t("mail.346"), value: "nate" }
  ],
  fetchServerTypes: [
    { text: i18n.t("mail.555"), value: "POP3" },
    { text: i18n.t("mail.556"), value: "IMAP" }
  ]
};

const getters = {
  serverNames: ({ serverNames }) => serverNames,
  externalList: ({ list }) => list,
  externalLoading: ({ loading }) => loading,
  externalTotalElements: ({ totalElements }) => totalElements,
  externalSenders: ({ list }) =>
    list.filter(
      e => e.useSmtp === 1 && e.smtpStatus == "SUCCEEDED_CERTIFICATE"
    ),
  getServerName: ({ serverNames }) => val => {
    if (!val) return serverNames[0];
    return serverNames.find(s => s.value === val) || serverNames[0];
  },
  fetchServerTypes: ({ fetchServerTypes }) => fetchServerTypes,
  getFetchServerType: ({ fetchServerTypes }) => val => {
    if (!val) return fetchServerTypes[0];
    return fetchServerTypes.find(s => s.value === val) || fetchServerTypes[0];
  }
};

const mutations = {
  SET_EXTERNAL_LIST: (state, list) => {
    state.init = true;
    state.loading = false;
    state.list = list;
    state.totalElements = list.length;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  DELETE_EXTERNAL_MAIL: (state, ids) => {
    state.list = state.list.filter(({ id }) => ids.indexOf(id) == -1);
  }
};

const actions = {
  async getExternalList({ commit }) {
    commit("SET_LOADING", true);
    const { data, status } = await getExternalList();
    if (status !== 200) return;

    commit("SET_EXTERNAL_LIST", data.content);
  },
  async addExternalMail({ state, commit, dispatch }, params) {
    if (state.totalElements >= 100) return;

    dispatch("loader/setLoaderShow", true, { root: true });
    let snackbar = { message: i18n.t("mail.419"), type: "ERROR" };
    const { data, status } = await addExternalMail(params);
    switch (status) {
      case 201:
        snackbar = null;
        commit("SET_EXTERNAL_LIST", data.content);
        break;
      case 226:
        // 자기 자신 등록
        snackbar = { message: i18n.t("mail.558"), type: "ERROR" };
        break;
      case 409:
        // 동일한 외부메일 존재
        snackbar = { message: i18n.t("mail.418"), type: "ERROR" };
        break;
      default:
        break;
    }

    dispatch("_snackbar", snackbar);
    dispatch("loader/setLoaderShow", false, { root: true });
    return status === 201;
  },
  async updateExternalMail({ commit, dispatch }, params) {
    dispatch("loader/setLoaderShow", true, { root: true });
    let snackbar = { message: i18n.t("mail.420"), type: "ERROR" };
    const { data, status } = await updateExternalMail(params);
    if (status === 201) {
      snackbar = null;
      commit("SET_EXTERNAL_LIST", data.content);
    }

    // 자기 자신 등록
    if (status === 226) {
      snackbar = { message: i18n.t("mail.558"), type: "ERROR" };
    }

    dispatch("_snackbar", snackbar);
    dispatch("loader/setLoaderShow", false, { root: true });
    return status == 201;
  },
  deleteExternalMail({ commit, dispatch }, externalIds) {
    const callback = async () => {
      const { status } = await deleteExternalMail(externalIds);
      if (status !== 204) {
        dispatch("_snackbar", { message: i18n.t("mail.421"), type: "ERROR" });
        return;
      }

      commit("DELETE_EXTERNAL_MAIL", externalIds);
    };

    const confirm = { message: i18n.t("mail.269"), callback };
    dispatch("confirm/confirm", confirm, { root: true });
  },
  async fetchExternalMail({ dispatch }, externalIds) {
    dispatch("_snackbar", { message: i18n.t("mail.274") });
    await fetchExternalMail(externalIds);
  },
  _snackbar({ dispatch }, params) {
    if (!params) return;

    dispatch("snackbar/openSnackbar", params, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
