<template>
  <v-navigation-drawer
    app
    right
    :class="naviCls"
    :width="sidePanelWidth"
    v-model="_drawer"
  >
    <div style="height:100%;">
      <div
        class="d-flex flex-column align-center justify-center pb-1 cr-modules"
      >
        <ModuleBtn v-for="(item, index) in items" :key="index" :item="item" />
      </div>
      <!-- custom -->
      <div
        class="d-flex flex-column align-center justify-center pb-1 cr-modules"
        v-if="useCustomMenu"
      >
        <div class="sep"></div>

        <CustomMenu
          v-for="(item, index) in customItems"
          :key="index"
          :item="item"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.v-navigation-drawer::v-deep {
  top: 64px !important;

  .v-navigation-drawer__content {
    max-height: calc(100% - 196px) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }

  &.cr-mobile {
    top: 0px !important;
    .v-navigation-drawer__content {
      padding-top: 14px;
      max-height: calc(100% - 140px) !important;
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    }
  }

  .sep {
    border-top: 1px solid #ccc;
    width: 50%;
    margin-bottom: 20px;
  }
}
.v-navigation-drawer.cr-ai::v-deep {
  .v-navigation-drawer__content {
    max-height: calc(100% - 226px) !important;
  }
  .cr-mobile .v-navigation-drawer__content {
    max-height: calc(100% - 160px) !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import ModuleBtn from "./ModuleBtn.vue";
import CustomMenu from "./CustomMenu.vue";

export default {
  components: { ModuleBtn, CustomMenu },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "getCompanyInfo"]),
    ...mapGetters("sidePanel", ["sidePanelWidth"]),
    ...mapGetters("ai", ["useAI"]),
    _drawer: {
      get() {
        return this.drawer;
      },
      set(drawer) {
        this.$emit("update:drawer", drawer);
      }
    },
    naviCls() {
      let cls = "";
      if (["account", "home"].includes(this.route)) cls += " white";
      if (this.$vuetify.breakpoint.mdAndDown) cls += " cr-mobile";
      if (this.useAI) cls += " cr-ai";

      return cls;
    },
    items() {
      let title = {
        home: this.$t("common.대시보드"),
        mail: this.$t("common.메일"),
        cal: this.$t("common.캘린더"),
        contact: this.$t("common.주소록"),
        drive: this.$t("common.드라이브"),
        todo: this.$t("common.워크플로"),
        flow: this.$t("common.워크플로"),
        board: this.$t("common.게시판"),
        resource: this.$t("common.시설예약"),
        approval: this.$t("common.전자결재"),
        customer: this.$t("common.공용주소록")
      };

      // FIXME: 알파테스트 서비스는 크리니티만 임시적으로 오픈.
      const companyInfo = this.getCompanyInfo;
      if (companyInfo.id !== 1) {
        delete title.approval;
      }

      return Object.keys(this.getUsedModule)
        .filter(key => this.getUsedModule[key])
        .map(key => ({
          title: title[key],
          name: key,
          selected: (this.$route.name || "").startsWith(key) ? "selected" : ""
        }));
    },
    useCustomMenu() {
      const companyInfo = this.getCompanyInfo;
      const { customMenuList = [] } = companyInfo.ui;
      return customMenuList.length > 0;
    },
    customItems() {
      const companyInfo = this.getCompanyInfo;
      const { customMenuList = [] } = companyInfo.ui;
      return customMenuList;
    }
  }
};
</script>
