import i18n from "@/_locales";
import {
  getForwardConfig,
  saveForward,
  resendApprovalMail
} from "@/mail/api/forward.api";

const state = {
  // 사용 여부
  useYN: 0,
  // 원본 저장
  saveOriginal: 1,
  // 전달 받을 메일 주소
  targets: []
};

const getters = {
  getForwardConfig: ({ useYN, saveOriginal, targets }) => ({
    useYN,
    saveOriginal,
    targets
  })
};

const mutations = {
  SET_FORWARD_CONFIG: (state, data) => {
    state.useYN = data.options.useYN;
    state.saveOriginal = data.options.saveOriginal;
    state.targets = data.targets;
  }
};

const actions = {
  async initialize({ commit }) {
    const { data = [] } = await getForwardConfig();
    commit("SET_FORWARD_CONFIG", data);
  },
  async saveForward({ dispatch }, params) {
    const { status } = await saveForward(params);
    let type = "ERROR";
    let msg = i18n.t("common.62");
    if (status == 201) {
      dispatch("initialize");
      type = "SUCCESS";
      msg = i18n.t("mail.527");
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: msg, type: type },
      { root: true }
    );
    return status == 201;
  },
  async resendApprovalMail({ dispatch }, address) {
    const { status, data = [] } = await resendApprovalMail({
      targets: [
        {
          address: address
        }
      ]
    });
    let type = "ERROR";
    let msg = i18n.t("mail.529");
    if (status == 200 && data.success) {
      dispatch("initialize");
      type = "SUCCESS";
      msg = i18n.t("mail.528", { value: address });
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: msg, type: type },
      { root: true }
    );
    return status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
