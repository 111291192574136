import http from "@/commons/api/http";

const prefix = "api/mails/v2";
const sharePrefix = "api/share/mails/v2";

//메일 보내기
const sendMail = params => http.post(`${prefix}/send`, params);

// 편지쓰기 초기화
const initCompose = () => http.get(`${prefix}/compose`);

// 답장 메일 가져오기
const replyCompose = ({ isShare, mailId, all, params }) => {
  if (!isShare) return http.get(`${prefix}/reply/${mailId}/${all}`);
  return http.get(`${sharePrefix}/reply/${mailId}/${all}`, { params });
};

// 전달 메일 가져오기
const forwardCompose = ({ isShare, params }) => {
  if (!isShare) return http.get(`${prefix}/forward`, { params });
  return http.get(`${sharePrefix}/forward`, { params });
};

// 다시보내기
const resendCompose = mailId => http.get(`${prefix}/resend/${mailId}`);

// 주소록에서 주소가져오기
const contactCompose = params => http.get(`${prefix}/contact`, { params });

// 고객주소록에서 주소가져오기
const customerCompose = params => http.get(`${prefix}/customer`, { params });

// 임시저장 메일 가져오기
const getDraftMail = draftId => http.get(`${prefix}/draft/${draftId}`);

// 임시저장
const saveDraftMail = params => http.post(`${prefix}/draft`, params);

// 임시저장 수정
const putDraftMail = params => http.put(`${prefix}/draft`, params);

// 임시저장 메일 삭제
const deleteDraftMail = mails => {
  const formData = new FormData();
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));

  return http.delete(`${prefix}/draft`, { data: formData });
};

export {
  sendMail,
  initCompose,
  replyCompose,
  forwardCompose,
  resendCompose,
  contactCompose,
  getDraftMail,
  saveDraftMail,
  putDraftMail,
  deleteDraftMail,
  customerCompose
};
