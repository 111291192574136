<template>
  <div>
    <div class="d-flex align-center">
      <v-card-subtitle class="pa-0">
        {{ $t("common.70") }}
      </v-card-subtitle>
      <v-icon color="red" class="pb-1">
        mdi-new-box
      </v-icon>
    </div>

    <Tag
      v-for="(tag, idx) in newTags"
      :key="tag.tag"
      :tag="tag"
      :lastChild="idx === newTags.length - 1"
    />
    <v-divider class="mt-2 mb-5" />
  </div>
</template>

<script>
import Tag from "./Tag.vue";

export default {
  components: { Tag },
  props: {
    newTags: {
      type: Array,
      default: () => []
    }
  }
};
</script>
