const sortArr = ["receivedTimeMillis", "emlSize", "subject", "adrFrom"];
const dirArr = ["desc", "asc"];

// actionObj 기본값
const listDefaultAction = JSON.stringify({
  sort: "receivedTimeMillis",
  dir: "desc",
  search: {}
});
const viewDefaultAction = JSON.stringify({
  // dataId: 기준이 되는 메일, selectedId: 메일목록에서 선택된 메일
  view: { dataId: undefined, selectedDataId: undefined },
  list: {
    sort: "receivedTimeMillis",
    dir: "desc",
    search: {}
  }
});
const writeDefaultAction = JSON.stringify({ r: 1, t: 0, i: 0 });

export {
  sortArr,
  dirArr,
  listDefaultAction,
  viewDefaultAction,
  writeDefaultAction
};
