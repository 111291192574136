<template>
  <v-btn
    text
    class="pa-0"
    height="40"
    style="display:block"
    :ripple="false"
    v-on="$listeners"
  >
    <v-text-field
      outlined
      dense
      hide-details
      v-model="selectedFolderName"
      :append-icon="openMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
      :label="label"
    />
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn:before {
  opacity: 0 !important;
}
// 셀렉트처럼 보이는 부분
.v-input.v-text-field ::v-deep {
  pointer-events: none;

  .v-input__append-inner {
    .v-input__icon.v-input__icon--append {
      .v-icon {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    label: {
      default: "",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    },
    selectedFolderName: {
      default: "",
      type: String
    }
  }
};
</script>
