<template>
  <v-dialog
    :value="value"
    max-width="720px"
    persistent
    scrollable
    @keydown.esc="$emit('input', false)"
    @input="val => $emit('input', val)"
  >
    <v-card :loading="loading">
      <v-card-title>
        {{ $t("calendar.검색결과") }}
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <SlideToggleGroup
            v-model="sortType"
            :items="sortTypes"
            :props="{
              class: 'mr-4',
              height: '36px'
            }"
          />
          <v-btn icon class="mr-n2" @click.stop="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div
          v-if="searchedList.length <= 0"
          class="text-center text-subtitle-1"
        >
          {{ $t("common.79") }}
        </div>
        <EventList
          :events="searchedList"
          :date-options="dateOptions"
          :is-organ-event="searchParam.calendarType === 'DEPT'"
          @click-item="openEventMenu"
        />

        <Pagination
          v-if="totalPages > 0"
          :page.sync="page"
          :pageSize="pageSize"
          :blockSize="blockSize"
          :totalElements="totalElements"
          class="pagingArea"
          @pageClick="newPage => (page = newPage)"
        />
      </v-card-text>
    </v-card>

    <EventMenu v-model="showEventMenu" @open:eventEditor="openEventEditor" />
    <EventEditorMenu v-model="showEventEditor" />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import Pagination from "@/commons/views/Pagination.vue";
import EventEditorMenu from "@/calendar/components/event/EventEditorMenu.vue";
import EventMenu from "@/calendar/components/event/EventMenu.vue";
import { getElementPosition } from "@/calendar/utils/ViewUtils";
import EventList from "@/calendar/components/event/EventList.vue";
import SlideToggleGroup from "@/calendar/components/common/SlideToggleGroup.vue";

const SORT_TYPE = {
  DEFAULT: "DEFAULT",
  ACCURACY: "ACCURACY",
  START_DATE_DESC: "START_DATE_DESC"
};

export default {
  components: {
    SlideToggleGroup,
    EventList,
    EventMenu,
    EventEditorMenu,
    Pagination
  },
  props: {
    value: {
      type: Boolean
    },
    searchParam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      pageSize: 5,
      totalPages: 0,
      blockSize: 5,
      totalElements: 0,
      searchedList: [],
      showEventMenu: false,
      showEventEditor: false,
      loading: true,
      dateOptions: Object.freeze({
        alwaysLongDate: true
      }),
      sortType: SORT_TYPE.DEFAULT,
      sortTypes: [
        {
          value: SORT_TYPE.DEFAULT,
          text: this.$t("calendar.sortType.default")
        },
        {
          value: SORT_TYPE.ACCURACY,
          text: this.$t("calendar.sortType.accuracy")
        },
        {
          value: SORT_TYPE.START_DATE_DESC,
          text: this.$t("calendar.sortType.startDt")
        }
      ]
    };
  },
  watch: {
    page: {
      handler: function() {
        this.loading = true;
        this.fetchData();
      },
      immediate: true
    },
    sortType() {
      this.loading = true;
      if (this.page === 1) {
        this.fetchData();
      } else {
        this.page = 1;
      }
    }
  },
  methods: {
    ...mapMutations("cal", [
      "SET_EVENT_MENU",
      "SET_EVENT_EDITOR",
      "SET_EDIT_EVENT",
      "SET_ACTIVE_EVENT_EDITOR"
    ]),
    ...mapActions("cal", ["searchEventList"]),
    async fetchData() {
      const param = {
        sortType: this.sortType,
        ...this.searchParam,
        page: this.page - 1,
        size: this.pageSize
      };
      const {
        eventList,
        totalElements,
        totalPages
      } = await this.searchEventList(param);

      this.searchedList = eventList;
      this.totalPages = totalPages;
      this.totalElements = totalElements;
      this.loading = false;
    },
    openEventMenu(nativeEvent, event) {
      nativeEvent.stopPropagation();
      if (this.showEventEditor) return;

      const open = () => {
        this.SET_EVENT_MENU({
          event,
          element: nativeEvent.target
        });
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.showEventMenu = true))
        );
      };

      if (this.showEventMenu) {
        this.showEventMenu = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    openEventEditor({ event, element, isExceptionRequest }) {
      this.SET_EVENT_MENU({
        event: null,
        element: null
      });
      this.SET_EVENT_EDITOR({
        ...getElementPosition(element),
        isExceptionRequest,
        fixed: true
      });
      this.SET_ACTIVE_EVENT_EDITOR("SearchDialog");

      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.SET_EDIT_EVENT(event);
            this.showEventEditor = true;
          })
        );
      };

      if (this.showEventEditor) {
        this.showEventEditor = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/simple-scrollbar.scss";

::v-deep .pagingArea .cr-custom-pagination {
  border: none;
}
</style>
