<template>
  <div>
    <MailFooter v-if="route == 'mail'" />
    <CalFooter v-else-if="route == 'cal'" />
    <ContactFooter v-else-if="route == 'contact'" />
    <DriveFooter v-else-if="route == 'drive'" />
    <BoardFooter v-else-if="route == 'board'" />
    <ResourceFooter v-else-if="route == 'resource'" />
  </div>
</template>

<script>
import MailFooter from "@/mail/views/layout/Footer";
import CalFooter from "@/calendar/views/layout/Footer.vue";
import ContactFooter from "@/contact/views/components/Footer";
import DriveFooter from "@/drive/views/layout/Footer";
import BoardFooter from "@/board/views/layout/Footer";
import ResourceFooter from "@/resource/views/layout/Footer";

export default {
  name: "Footer",
  props: ["route"],
  components: {
    MailFooter,
    CalFooter,
    ContactFooter,
    DriveFooter,
    BoardFooter,
    ResourceFooter
  }
};
</script>
