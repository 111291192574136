const TITLE = "TITLE";
const PERSON = "PERSON";
const STATUS = "STATUS";
const TIMELINE = "TIMELINE";
const NUMBER = "NUMBER";
const CREATED_LOG = "CREATED_LOG";
const ADDITIONAL = "ADDITIONAL";
const TEXT = "TEXT";
const LINK = "LINK";
const CHECK = "CHECK";

export default {
  TITLE: () => ({
    type: TITLE,
    title: "todo.아이템",
    labels: [],
    value: `${TITLE}_${new Date().getTime()}`,
    format: "default",
    width: 300,
    minWidth: 300
  }),
  PERSON: () => ({
    type: PERSON,
    title: "todo.사람",
    labels: [],
    value: `${PERSON}_${new Date().getTime()}`,
    format: "default",
    width: 90,
    minWidth: 90
  }),
  STATUS: () => ({
    type: STATUS,
    title: "todo.상태",
    labels: [],
    value: `${STATUS}_${new Date().getTime()}`,
    format: "default",
    width: 90,
    minWidth: 90
  }),
  TIMELINE: () => ({
    type: TIMELINE,
    title: "todo.타임라인",
    labels: [],
    value: `${TIMELINE}_${new Date().getTime()}`,
    format: "default",
    width: 150,
    minWidth: 150
  }),
  NUMBER: () => ({
    type: NUMBER,
    title: "todo.숫자",
    labels: [],
    value: `${NUMBER}_${new Date().getTime()}`,
    format: "default",
    width: 90,
    minWidth: 90
  }),
  TEXT: () => ({
    type: TEXT,
    title: "todo.텍스트",
    labels: [],
    value: `${TEXT}_${new Date().getTime()}`,
    format: "default",
    width: 90,
    minWidth: 90
  }),
  CREATED_LOG: () => ({
    type: CREATED_LOG,
    title: "todo.생성로그",
    labels: [],
    value: `${CREATED_LOG}_${new Date().getTime()}`,
    format: "default",
    width: 150,
    minWidth: 150
  }),
  LINK: () => ({
    type: LINK,
    title: "todo.링크",
    labels: [],
    value: `${LINK}_${new Date().getTime()}`,
    format: "default",
    width: 360,
    minWidth: 150
  }),
  CHECK: () => ({
    type: CHECK,
    title: "todo.확인",
    labels: [],
    value: `${CHECK}_${new Date().getTime()}`,
    format: "default",
    width: 62,
    minWidth: 62
  }),
  ADDITIONAL: () => ({
    type: ADDITIONAL,
    title: "todo.추가",
    labels: [],
    value: `${ADDITIONAL}_${new Date().getTime()}`,
    format: "default",
    width: 100,
    minWidth: 100
  })
};
