<template>
  <div>
    <v-skeleton-loader
      v-if="activated && !editorInitialized"
      class="mx-2"
      type="sentences"
    />
    <v-btn
      v-else-if="!activated"
      class="activator text-body-2 grey--text"
      block
      text
      @click="activate"
    >
      {{ placeholder }}
    </v-btn>
    <v-expand-transition>
      <div v-show="activated && editorInitialized">
        <Editor
          :key="editorKey"
          :value="value"
          plugins="lists autolink autoresize paste link quickbars"
          toolbar="bold italic underline | bullist numlist | link"
          @onFocus="onFocus"
          @onBlur="onBlur"
          @input="val => $emit('input', val)"
          :init="{
            width: '100%',
            height: '100%',
            // 불필요한 UI 제거
            resize: false,
            menubar: false,
            branding: false,
            elementpath: false,
            // HTML 태그 정리( \n\r, 불필요한 루트 태그 제거 )
            indent: false,
            forced_root_block: false,
            // 붙여넣기 시 서식 제거( 배경색 등 허용하지 않은 서식 방지 )
            paste_as_text: true,
            // 이미지 붙여넣기 제한
            paste_data_images: false,
            placeholder: placeholder,
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar:
              'bold italic underline | bullist numlist | link',
            link_quicklink: true,
            base_url: '/js',
            content_style:
              'body { margin: 4px 12px; line-height: 1.25rem; font-size: 14px; font-family: Noto Sans KR, -apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', sans-serif }' +
              ' p, ul, ol { margin: 0; } ul, ol { padding-left: 24px }',
            // 불필요한 하단 공백 제거
            autoresize_bottom_margin: 0,
            setup: function(ed) {
              editor = ed;
            }
          }"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";

import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/paste";
import "tinymce/plugins/quickbars";

import Editor from "@tinymce/tinymce-vue";

export default {
  components: { Editor },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activated: this.value?.length > 0,
      editorInitialized: false,
      editor: null,
      editorKey: 0,
      showLinkDialog: false,
      linkUrl: "",
      linkError: ""
    };
  },
  mounted() {
    if (this.activated) {
      this.$emit("activate");
    }
    this.checkInit();
  },
  methods: {
    /**
     * 일정 편집기에서 TinyMCE를 즉시 렌더링하는 경우 TinyMCE가 정상적으로 초기화되지 않는 버그가 있습니다.
     *  - 예를 들어 이미 설명 데이터가 존재하여 처음부터 TinyMCE로 데이터를 표시하려는 경우 데이터가 표시되지 않습니다.
     *  - 메일/게시판이 사용 중인 TinymceEditor를 배치해도 동일한 문제가 발생하므로 Vue, Vuetify 연관 문제로 추정됩니다.
     * 이를 해결하기 위해 TinyMCE가 초기화되지 않은 경우 주기적으로 초기화를 시도합니다.
     */
    checkInit() {
      if (this.editor.initialized) {
        this.editorInitialized = true;
        return;
      }

      this.editorInitialized = false;
      setTimeout(() => {
        this.editorKey++;

        setTimeout(() => {
          this.checkInit();
        }, 250);
      }, 250);
    },
    activate() {
      this.activated = true;
      this.$nextTick(() => {
        this.editor.focus(false);
      });
      this.$emit("activate");
    },
    onFocus() {
      const editArea = document.querySelector(".tox-edit-area");
      if (!editArea.classList.contains("focused")) {
        editArea.classList.add("focused");
      }
    },
    onBlur() {
      const editArea = document.querySelector(".tox-edit-area");
      if (editArea.classList.contains("focused")) {
        editArea.classList.remove("focused");
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "vuetify/src/styles/styles";

.activator.v-btn {
  padding: 0 8px !important;

  ::v-deep .v-btn__content {
    text-transform: none;
    justify-content: flex-start;
  }
}

::v-deep .tox-tinymce {
  min-height: 72px;
  border: none;
  border-radius: map-get($rounded, null);

  // 툴바
  .tox-toolbar__primary {
    background: map-get($grey, lighten-4);
  }

  .tox-toolbar__group:not(:last-of-type) {
    border-right: none !important;
  }

  .tox-toolbar__group:not(:last-of-type)::after {
    content: "";
    display: block;
    height: 70%;
    border-right: 1px solid map-get($grey, lighten-2);
    position: relative;
    margin-left: 8px;
  }

  // 툴바 버튼
  .tox-tbtn {
    &:hover,
    &:active,
    &:focus {
      background: map-get($grey, lighten-3);
    }

    width: 32px;
    height: 32px;
    border-radius: map-get($rounded, circle);

    .tox-icon svg {
      fill: rgba(0, 0, 0, 0.54);
    }
  }

  .tox-tbtn--enabled {
    background: map-get($grey, lighten-2);

    &:hover,
    &:active,
    &:focus {
      background: map-get($grey, lighten-2);
    }
  }

  .tox-edit-area__iframe {
    background: map-get($grey, lighten-4);
  }

  .tox-edit-area {
    &.focused:after {
      transform: scaleX(1);
    }

    &:after {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
      border-style: solid;
      border-width: thin 0 thin 0;
      transform: scaleX(0);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      width: 100%;
    }
  }

  // 에디터 하단 상자 제거
  .tox-statusbar {
    display: none !important;
  }
}
</style>
