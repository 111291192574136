import http from "@/commons/api/http";

const prefix = "api/mail/config/away";

/**
 * 부재중 응답 데이터 조회
 * @returns
 */
const getAwayData = () => http.get(prefix);

/**
 * 부재 중 응답 내용 수정
 * @param {*} param0
 * @returns
 */
const updateAwayConfig = ({
  useAway,
  startTimeMillis,
  endTimeMillis,
  contents
}) => {
  const formData = new FormData();
  formData.append("useAway", useAway);
  formData.append("startTimeMillis", startTimeMillis);
  formData.append("endTimeMillis", endTimeMillis);
  formData.append("contents", contents);
  return http.put(`${prefix}`, formData);
};

export { updateAwayConfig, getAwayData };
