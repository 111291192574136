import http from "@/commons/api/http";
import { privateFolderType } from "@/mail/constant/folderType";
import i18n from "@/_locales";

const prefix = "api/folders";

// 기본 메일함 목록 조회
const getDefaultFolders = () => http.get(prefix);

/**
 * 메일함 조회
 *
 * @param {*} id 메일함 id
 */
const getFolderById = id => http.get(`${prefix}/${id}`);

/**
 * 메일함 생성
 * @param {*} nameFolder 메일함 이름
 * @param {*} folderType 메일함 타입
 * @param {*} parentId   부모 메일함 id
 * @param {*} options    메일함 옵션
 */
const addFolder = ({
  nameFolder = "이름 없음",
  folderType = privateFolderType.PRIVATE,
  parentId = "",
  options
}) =>
  http.post(prefix, {
    nameFolder,
    folderType,
    parentId,
    options
  });

/**
 * 개인 메일함명 수정
 *
 * @param {*} nameFolder 메일함 이름
 * @param {*} folderId   메일함 id
 */
const updateFolderName = ({ nameFolder = i18n.t("common.8"), folderId }) => {
  const formData = new FormData();
  formData.append("nameFolder", nameFolder);
  return http.put(`${prefix}/${folderId}/nameFolder`, formData);
};

/**
 * 개인 메일함 색상 수정
 *
 * @param {*} color 메일함 색상
 * @param {*} folderId   메일함 id
 */
const updateFolderColor = ({ color, folderId }) => {
  const formData = new FormData();
  formData.append("color", color);
  return http.put(`${prefix}/${folderId}/color`, formData);
};

/**
 * 검색 메일함 수정
 *
 * @param {*} folderId   메일함 id
 * @param {*} nameFolder 메일함 이름
 * @param {*} options    검색 옵션
 */
const updateSearchFolder = ({ folderId, nameFolder, options }) =>
  http.put(`${prefix}/${folderId}/search`, { nameFolder, options });

/**
 * 태그 수정
 *
 * @param {*} folderId   메일함 id
 * @param {*} options    옵션 (태그)
 */
const updateTagFolder = ({ folderId, tag, color, type }) =>
  http.put(`${prefix}/${folderId}/tag`, { tag, color, type });

/**
 * 메일함 삭제
 *
 * @param {*} folderId 메일함 id
 */
const deleteFolder = folderId => http.delete(`${prefix}/${folderId}`);

/**
 *
 * 메일함 순서 이동
 */
const moveFolder = ({ id, targetFolderId, position }) =>
  http.put(`${prefix}/move/${id}/${targetFolderId}/${position}`);

/**
 * 메일함 카운트 동기화
 */
const syncFolder = () => http.put(`${prefix}/sync`);

export {
  getDefaultFolders,
  getFolderById,
  addFolder,
  updateFolderName,
  updateFolderColor,
  updateSearchFolder,
  updateTagFolder,
  deleteFolder,
  moveFolder,
  syncFolder
};
