import moment from "moment";
import i18n from "@/_locales";
import router from "@/commons/router";
import storage from "@/commons/api/storage";
import { composeTypeConverter } from "@/mail/constant/composeType";
import { isEmail } from "@/commons/utils/validation";
import { makeLinkContent } from "@/drive/api/drive.api";
import {
  initCompose,
  replyCompose,
  forwardCompose,
  resendCompose,
  contactCompose,
  customerCompose
} from "../../../api/write.api";

// 메일쓰기 관련 스토어 모듈들
import { l_state, l_getters, l_mutations } from "./layout";
import { r_state, r_getters, r_mutations } from "./reserve";
import { secure_state, secure_getters, secure_mutation } from "./secure";
import { s_actions } from "./send";
import { d_actions } from "./draft";

const state = {
  loading: true,

  ...l_state,
  ...r_state,
  ...secure_state,
  // draftId, mailId, MailIds 는 임시저장에서 사용
  draftId: 0,
  mailId: 0,
  mailIds: [],

  composeType: "DEFAULT",
  senderName: "",
  senderEmail: "",
  senderType: "DOMAIN",
  senderId: 0,

  to: [],
  cc: [],
  bcc: [],
  subject: "",
  subjectFirst: i18n.t("mail.107"),
  content: "<p></p>",
  contentObserver: false,

  eachSend: 0,
  priority: 3,

  sendResult: {}
};

// state, getters, rootState, rootGetters
const getters = {
  ...l_getters,
  ...r_getters,
  ...secure_getters,
  loading: ({ loading }) => loading,
  senderName: ({ senderName }) => senderName,
  senderEmail: ({ senderEmail }) => senderEmail,
  senderType: ({ senderType }) => senderType,
  senderId: ({ senderId }) => senderId,
  to: ({ to }) => to,
  cc: ({ cc }) => cc,
  bcc: ({ bcc }) => bcc,
  subject: ({ subject }) => subject,
  subjectFirst: ({ subjectFirst }) => subjectFirst,
  content: ({ content }) => content,

  eachSend: ({ eachSend }) => eachSend,
  priority: ({ priority }) => priority,

  sendResult: ({ sendResult }) => sendResult,

  // 자동임시저장 감시자
  ASObserver: state => ({
    senderName: state.senderName,
    senderEmail: state.senderEmail,
    subject: state.subject,
    subjectFirst: state.subjectFirst,
    to: state.to,
    cc: state.cc,
    bcc: state.bcc,
    // content: state.content,
    contentObserver: state.contentObserver,
    eachSend: state.eachSend,
    priority: state.priority
  }),
  // 메일쓰기 에디터 크기 조절 감시자
  MWEObserver: state => ({
    to: state.to,
    cc: state.cc,
    bcc: state.bcc,
    showBcc: state.showBcc,
    isReserve: state.isReserve,
    isSecure: state.isSecure,
    showAttachList: state.showAttachList,
    showOnlySubject: state.showOnlySubject
  }),
  // 내게쓰기 여부
  isWriteToMe: (s, g, rs, rg) =>
    rg["mailRoute/getRouteWriteInfo"]?.actionObj?.t == 6 ||
    s.composeType == "TOME"
};

const mutations = {
  ...l_mutations,
  ...r_mutations,
  ...secure_mutation,
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_DRAFT_ID: (state, draftId) => {
    state.draftId = draftId;
  },
  SET_MAIL_ID: (state, { draftId, mailId, mailIds }) => {
    state.draftId = draftId || 0;
    state.mailId = mailId;
    state.mailIds = mailIds;
  },
  SET_COMPOSE_TYPE: (state, { composeType }) => {
    state.composeType = composeType || "DEFAULT";
  },
  SET_SENDER: (state, sender) => {
    const { senderName, senderEmail, senderType, senderId } = sender;
    state.senderName = senderName || state.senderName;
    state.senderEmail = senderEmail || state.senderEmail;
    state.senderType = senderType || state.senderType;
    state.senderId = senderId ?? state.senderId;

    if (state.senderType === "EXTERNAL") {
      state.isReserve = false;
      state.reserveDate = "";
    }
  },
  SET_RECIPIENT: (state, recipient) => {
    const { to, cc, bcc } = recipient;
    state.to = to || state.to;
    state.cc = cc || state.cc;
    state.bcc = bcc || state.bcc;
  },
  SET_ROUTE_PARAMS_RCPT: (state, recipient) => {
    Object.keys(recipient).forEach(k => {
      if (!recipient[k]) return;

      recipient[k]?.split(",")?.forEach(value => {
        const rcpts = state[k];
        const key = (parseInt(Math.max(...rcpts.map(r => r.key))) || 0) + 1;
        const rcpt = { key, valid: isEmail(value), value };

        state[k] = [...rcpts, rcpt];
        if (k == "bcc") state.showBcc = true;
      });
    });
  },
  SET_INCLUDE_ME: (state, { includeMe, includeMeEmail: value }) => {
    if (!includeMe || !value || state.composeType == "TOME") return;

    const rcpts = includeMe === 1 ? state.cc : state.bcc;
    const idx = rcpts.findIndex(r => r.value.includes(value));
    if (idx !== -1) return;

    const key = (parseInt(Math.max(...rcpts.map(r => r.key))) || 0) + 1;
    const rcpt = { key, valid: isEmail(value), value };

    if (includeMe === 1) {
      state.cc.push(rcpt);
    } else {
      state.bcc.push(rcpt);
      state.showBcc = true;
    }
  },
  SET_SUBJECT_FIRST: (state, { subjectFirst }) => {
    state.subjectFirst = subjectFirst || i18n.t("mail.107");
  },
  SET_SUBJECT: (state, { subject }) => {
    state.subject = subject;
  },
  SET_CONTENT: (state, content) => {
    state.content = content;
  },
  TOGGLE_CONTENT_OBSERVER: state => {
    state.contentObserver = !state.contentObserver;
  },
  SET_EACHSEND: (state, { eachSend }) => {
    state.eachSend = eachSend ? 1 : 0;
  },
  SET_PRIORITY: (state, { priority }) => {
    state.priority = priority;
  },
  SET_RESULT: (state, result) => {
    state.showSendResult = true;
    state.sendResult = result;
  },
  RESET_COMPOSE: (state, { senderName, senderEmail }) => {
    state.loading = true;
    state.draftId = 0;
    state.mailId = 0;
    state.mailIds = [];
    state.composeType = "DEFAULT";

    state.senderName = senderName;
    if (state.senderEmail !== senderEmail) {
      state.senderEmail = senderEmail;
      state.senderType = "DOMAIN";
      state.senderId = 0;
    }

    state.to = [];
    state.cc = [];
    state.bcc = [];
    state.subject = "";
    state.subjectFirst = i18n.t("mail.107");
    state.content = "<p></p>";

    state.eachSend = 0;
    state.priority = 3;

    state.isReserve = false;
    state.reserveDate = "";

    state.isSecure = false;
    state.securePw = "";
    state.securePwHint = "";

    state.showBcc = false;
    state.showAttachList = false;
    state.showSendResult = false;
    state.showOnlySubject = false;
    state.showReserveModal = false;
    state.showSecureModal = false;

    state.sendResult = {};
  }
};

const actions = {
  ...s_actions,
  ...d_actions,
  async initCompose({ rootState, commit, dispatch }) {
    const { mailRoute, mailConfig, auth } = rootState;
    const { actionObj } = mailRoute.mail_write;
    const { t, i: mailId, is: mailIds, to, cc, bcc } = JSON.parse(actionObj);
    const { defaultSenderName: senderName } = mailConfig;
    const { username: senderEmail } = auth.userInfo;
    commit("RESET_COMPOSE", { senderName, senderEmail });
    commit("mailFile/UPLOAD_RESET", null, { root: true });

    /**
     * 1: 답장 2: 전달 3: 전체답장 4: 임시저장 5: 다시보내기 6: 내게쓰기
     * 7: 주소록에서 메일쓰기 8: 드라이브에서 메일쓰기 9: 외부드라이브에서 메일쓰기
     */
    await dispatch(`_${composeTypeConverter(t)}Compose`, { mailId, mailIds });

    /**
     * route parameter로 넘어온 수신자 세팅
     */
    commit("SET_ROUTE_PARAMS_RCPT", { to, cc, bcc });

    /**
     * 메일쓰기 설정
     * 1. 나를 항상 참조(숨은참조)에 포함
     */
    commit("SET_INCLUDE_ME", mailConfig);

    commit("SET_LOADING", false);
  },
  async _initCompose({ commit, dispatch }) {
    const { status, data } = await initCompose();
    if (status !== 200) return;

    const { content } = data;

    dispatch("initSubject", { subject: "" });
    dispatch("initContent", content);
    commit("SET_COMPOSE_TYPE", { composeType: "DEFAULT" });
  },
  async _allReplyCompose({ dispatch }, { mailId }) {
    await dispatch("_replyCompose", { mailId, all: 1 });
  },
  async _replyCompose({ rootGetters, dispatch }, { mailId, all = 0 }) {
    const isShare = rootGetters["folder/isShare"];
    const shareInfo = rootGetters["mailRoute/getRouteShareInfo"];
    const params = { isShare, mailId, all, params: shareInfo };
    const response = await replyCompose(params);

    dispatch("responseProcessing", response);
  },
  async _resendCompose({ dispatch }, { mailId }) {
    const response = await resendCompose(mailId);

    dispatch("responseProcessing", response);
  },
  async _forwardCompose({ rootGetters, dispatch }, { mailIds }) {
    const isShare = rootGetters["folder/isShare"];
    const shareInfo = rootGetters["mailRoute/getRouteShareInfo"];
    const params = { isShare, params: { mailIds } };
    if (isShare) params.params = { mailIds, ...shareInfo };
    const response = await forwardCompose(params);

    dispatch("responseProcessing", response);
  },
  _tomeCompose({ state, commit, dispatch }) {
    commit("SET_COMPOSE_TYPE", { composeType: "TOME" });

    const { senderName, senderEmail } = state;
    const value = `"${senderName}" <${senderEmail}>`;
    const to = [{ key: 0, valid: true, value }];
    commit("SET_RECIPIENT", { to, cc: [], bcc: [] });
    dispatch("initContent", "");
  },
  async _contactCompose({ dispatch }, { mailIds: cardIds }) {
    const response = await contactCompose({ cardIds });
    dispatch("responseProcessing", response);
  },
  async _customerCompose({ dispatch }, { mailIds: cardIds }) {
    const response = await customerCompose({ cardIds });
    dispatch("responseProcessing", response);
  },
  async _driveCompose({ commit, dispatch }) {
    await dispatch("_initCompose");

    // 드라이브에서 첨부 다이얼로그 오픈
    const dialog = { show: true, type: "driveAttach" };
    commit("mailDialog/SET_DIALOG", dialog, { root: true });
  },
  // TODO:: 메일쓰기 초안과 외부드라이브 링크생성 한번에 불러오는 컨트롤러 필요
  async _googleDriveCompose({ state, rootState, commit, dispatch }) {
    await dispatch("_initCompose");

    const { actionObj } = rootState.mailRoute.mail_write;
    const { cp } = JSON.parse(actionObj);
    const params = cp?.split(",")?.map(i => {
      const [name, linkUrl] = i.split("=");
      return { name, linkUrl };
    });

    const { data } = await makeLinkContent(params);
    const content = data + "<p></p><p></p>" + state.content;
    commit("SET_CONTENT", content);
  },

  // 본문 초기화 -- cid 이미지 access_token 입력
  initContent({ rootState, commit }, content) {
    const { mailRoute, mailConfig } = rootState;
    const { actionObj } = mailRoute.mail_write;
    const { t: type } = JSON.parse(actionObj);
    const { initFontStyle, initFontSize } = mailConfig;
    const root_block = `<p style="font-family: ${initFontStyle}; font-size: ${initFontSize};"></p>`;
    const regex = /@ACCESS_TOKEN@/g;
    content = content?.replace(regex, storage.getAccessToken()) || "";
    if (type == 5) {
      // 다시 보내기
    } else if (type != 4) {
      content = root_block + root_block + root_block + content;
    }

    commit("SET_CONTENT", content);
  },
  // 메일쓰기 응답 처리
  responseProcessing({ dispatch }, response) {
    const { status, data } = response;

    let message = i18n.t("mail.361");
    switch (status) {
      case 200:
        dispatch("setComposeData", data);
        return;
      case 423:
        // 차단된 메일
        message = i18n.t("mail.359");
        break;
      case 403:
        // 권한없음
        message = i18n.t("mail.360");
        break;

      default:
        break;
    }

    dispatch("_snackbar", { message, type: "ERROR" });
    dispatch("mailRoute/routeMailWrite", { t: 0 }, { root: true });
  },
  // 메일쓰기 폼 입력
  setComposeData({ commit, dispatch }, data) {
    commit("SET_COMPOSE_TYPE", data);
    commit("SET_MAIL_ID", data);
    commit("SET_SENDER", data);
    commit("SET_SUBJECT_FIRST", data);
    commit("SET_EACHSEND", data);
    commit("SET_PRIORITY", data);

    // commit("SET_SUBJECT", data);
    dispatch("initSubject", data);

    let { to, cc, bcc, content, attachments } = data;

    // 본문 세팅
    dispatch("initContent", content);

    // 수신자 세팅
    to = to?.map((value, key) => ({ value, key, valid: isEmail(value) }));
    cc = cc?.map((value, key) => ({ value, key, valid: isEmail(value) }));
    bcc = bcc?.map((value, key) => ({ value, key, valid: isEmail(value) }));
    commit("SET_RECIPIENT", { to: to || [], cc: cc || [], bcc: bcc || [] });

    // 첨부파일
    dispatch("mailFile/setFiles", attachments, { root: true });
  },
  // 제목 설정
  initSubject({ rootGetters, commit }, data) {
    let { subjectFormat = "" } = rootGetters["mailConfig/getWriteConfig"];
    if (!subjectFormat || !subjectFormat.includes("{")) {
      commit("SET_SUBJECT", { subject: data.subject });
      return;
    }

    const { composeType = "INIT" } = data;
    if (!["INIT", "REPLY", "FORWARD"].includes(composeType)) {
      commit("SET_SUBJECT", { subject: data.subject });
      return;
    }

    const { companyName = "" } = rootGetters["auth/getCompanyInfo"];
    const { accountName = "", organizationName = "" } = rootGetters[
      "auth/getUserInfo"
    ];
    const generatedSubject = i18n.t("mail.580");
    const today = moment().format("YYYY-MM-DD");

    const dataMap = {
      "{COMPANY_NAME}": companyName,
      "{ACCOUNT_NAME}": accountName,
      "{ORGANIZATION_NAME}": organizationName,
      "{SUBJECT}": generatedSubject,
      "{YYYY-MM-DD}": today
    };

    for (const k in dataMap) {
      if (subjectFormat.includes(k)) {
        subjectFormat = subjectFormat.replace(k, dataMap[k]);
      }
    }

    commit("SET_SUBJECT", {
      subject: `${subjectFormat} ${data.subject ? "- " + data.subject : ""}`
    });
  },

  // 부모창의 getters
  _getters({ rootGetters }, action) {
    let _getters = str => rootGetters[str];
    try {
      _getters = window.opener?.vxg || _getters;
    } catch (e) {
      //
    }

    return _getters(action);
  },
  // 현재창, 부모창의 dispatch
  _dispatch({ dispatch }, { action, params }) {
    dispatch(action, params, { root: true });
    if (router.currentRoute.name?.includes("popup_mail_")) {
      try {
        window.opener?.vxd(action, params, { root: true });
      } catch (e) {
        //
      }
    }
  },
  _snackbar({ dispatch }, params) {
    dispatch("_dispatch", { action: "snackbar/openSnackbar", params });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
