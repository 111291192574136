const state = {
  // 앱바 검색창 토글
  showSearch: false,
  // 연차관리 다이얼로그
  showLeaveDialog: false
};

const getters = {
  showSearch: ({ showSearch }) => showSearch,
  showLeaveDialog: ({ showLeaveDialog }) => showLeaveDialog
};

const mutations = {
  SET_SHOW_SEARCH: (state, showSearch) => {
    state.showSearch = showSearch;
  },
  SET_SHOW_LEAVE_DIALOG: (state, showLeaveDialog) => {
    state.showLeaveDialog = showLeaveDialog;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
