import Vue from "vue";
import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  moveTask,
  updateTitle,
  updateStatus,
  updatePriority,
  updateContent,
  updateGroupId,
  updateAssignee,
  updateStartDate,
  updateDueDate,
  updateRangeDate,
  updateProgress
} from "@/flow/api/task.api";

const state = {};

const getters = {};

const mutations = {
  UPDATE_TASK_ATTRS: (state, task) => {
    state.taskDataMap[task.id] = { ...state.taskDataMap[task.id], ...task };
    state.taskDataMap = { ...state.taskDataMap };
  },
  UPDATE_GROUP_ID: (state, task) => {
    const { id, groupId } = task;
    const prevTask = state.taskDataMap[id];

    // 기존 업무 삭제
    if (!prevTask.parentId) {
      const prevListInfo = state.LIST[prevTask.groupId];
      prevListInfo.ids = prevListInfo.ids.filter(_id => _id !== id);
      prevListInfo.total -= 1;
    } else {
      const taskIdx = state.taskDataMap[prevTask.parentId].subTasks.indexOf(id);
      Vue.delete(state.taskDataMap[prevTask.parentId].subTasks, taskIdx);
      state.taskDataMap[prevTask.parentId].subCnt -= 1;

      if (state.taskDataMap[prevTask.parentId].subCnt <= 0) {
        const openIndex = state.openSubTasks.indexOf(prevTask.parentId);
        if (openIndex !== -1) Vue.delete(state.openSubTasks, openIndex);
      }
    }

    // 업무 데이터 변경
    state.taskDataMap[task.id] = { ...prevTask, ...task };
    // 해당 그룹의 업무가 아직 load 되지 않았을 때
    const listInfo = state.LIST[groupId];
    if (!listInfo.ids.length && listInfo.total > 0) {
      return (state.LIST = { ...state.LIST });
    }

    // 옮기는 그룹에 업무 추가
    listInfo.ids.push(id);
    listInfo.total += 1;
    state.LIST = { ...state.LIST };
  },
  UPDATE_STATUS: (state, task) => {
    const { id, status, kanbanSortOrder } = task;
    const prevTask = state.taskDataMap[id];

    // 기존 업무 삭제
    const prevKanbanInfo = state.KANBAN[prevTask.status];
    prevKanbanInfo.ids = prevKanbanInfo.ids.filter(_id => _id !== id);
    prevKanbanInfo.total -= 1;

    // 업무 데이터 변경
    state.taskDataMap[id] = { ...prevTask, status, kanbanSortOrder };

    // 옮기는 상태에 업무 추가
    const kanbanInfo = state.KANBAN[status];
    if (!kanbanInfo.ids.length && kanbanInfo.total > 0) {
      return (state.KANBAN = { ...state.KANBAN });
    }

    kanbanInfo.ids.push(id);
    kanbanInfo.total += 1;
    state.KANBAN = { ...state.KANBAN };
  },
  MOVE_TASK: (state, params) => {
    const { viewType, task, targetId, position } = params;
    const {
      id,
      parentId,
      groupId,
      status,
      listSortOrder,
      kanbanSortOrder
    } = task;
    const prevTask = state.taskDataMap[id];
    const isList = viewType === "LIST";

    const key = isList ? groupId : status;
    const prevKey = isList ? prevTask.groupId : prevTask.status;
    const changedData = isList
      ? { parentId, groupId, listSortOrder }
      : { parentId, status, kanbanSortOrder };

    // 기존 데이터 삭제
    if (!prevTask.parentId) {
      const taskIdx = state[viewType][prevKey].ids.indexOf(id);
      Vue.delete(state[viewType][prevKey].ids, taskIdx);
      state[viewType][prevKey].total -= 1;
    } else {
      const taskIdx = state.taskDataMap[prevTask.parentId].subTasks.indexOf(id);
      Vue.delete(state.taskDataMap[prevTask.parentId].subTasks, taskIdx);
      state.taskDataMap[prevTask.parentId].subCnt -= 1;

      if (state.taskDataMap[prevTask.parentId].subCnt <= 0) {
        const openIndex = state.openSubTasks.indexOf(prevTask.parentId);
        if (openIndex !== -1) Vue.delete(state.openSubTasks, openIndex);
      }
    }

    // 기존 데이터 변경
    state.taskDataMap[id] = { ...prevTask, ...changedData };

    // 이동
    if (!parentId) {
      if (!state[viewType][key]) {
        state[viewType][key] = { ids: [], total: 0 };
      }
      const targetIdx = state[viewType][key].ids.indexOf(parseInt(targetId));
      let index = position === "before" ? targetIdx : targetIdx + 1;
      if (targetIdx === -1) index = 0;

      state[viewType][key].ids.splice(index, 0, id);
      state[viewType][key].total += 1;

      state[viewType] = { ...state[viewType] };
    } else {
      const targetIdx = state.taskDataMap[parentId].subTasks.indexOf(
        parseInt(targetId)
      );
      let index = position === "before" ? targetIdx : targetIdx + 1;
      if (targetIdx === -1) index = 0;

      state.taskDataMap[parentId].subTasks.splice(index, 0, id);
      state.taskDataMap[parentId].subCnt += 1;

      state.taskDataMap = { ...state.taskDataMap };
    }
  }
};

const actions = {
  async moveTask(
    { rootState, getters, rootGetters, commit, dispatch },
    { taskId, targetId, targetKey, position }
  ) {
    const viewType = rootGetters["flowRoute/viewType"];
    const taskList = getters[`${viewType}_tasks`][targetKey] || [];
    if (!taskList.length) {
      const key = viewType === "LIST" ? "groupId" : "status";
      const action = viewType === "LIST" ? "updateGroupId" : "updateStatus";
      return dispatch(action, { taskId, [key]: targetKey });
    }

    const params = { taskId, targetId, targetKey, position, viewType };
    params["projectId"] = rootState.flowRoute.projectId;
    if (targetId === "top") {
      params["targetId"] = taskList.at(0)?.id || 0;
      params["position"] = "before";
    }

    if (targetId === "bottom") {
      params["targetId"] = taskList.at(-1)?.id || 0;
      params["position"] = "after";
    }

    if (params.taskId == params.targetId) return;

    const { status, data: task } = await moveTask(params);
    const additionalMsg = { 409: "하위 업무는 이동할 수 없습니다." };
    const message = getMessage(status, additionalMsg);
    if (message) return dispatch("snackbar", { message });

    commit("MOVE_TASK", { task, ...params });
  },
  async updateGroupId({ rootState, rootGetters, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status: _status, data: task } = await updateGroupId(params);
    const message = getMessage(_status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, groupId, status, listSortOrder } = task;
    if (rootGetters["flowRoute/viewType"] === "KANBAN") {
      return commit("UPDATE_TASK_ATTRS", { id, parentId, status });
    }
    commit("UPDATE_GROUP_ID", { id, parentId, groupId, listSortOrder });
  },
  async updateStatus(actions, params) {
    const { rootState, rootGetters, commit, dispatch } = actions;
    const viewType = rootGetters["flowRoute/viewType"];

    const { projectId } = rootState.flowRoute;
    const { status, data: task } = await updateStatus({ ...params, projectId });
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    if (viewType === "LIST" || task.parentId > 0) {
      const { id, parentId, status } = task;
      return commit("UPDATE_TASK_ATTRS", { id, parentId, status });
    }
    commit("UPDATE_STATUS", task);
  },
  async updateTitle({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateTitle(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, title } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, title });
  },
  async updatePriority({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updatePriority(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, priority } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, priority });
  },
  async updateContent({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateContent(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, content } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, content });
  },
  async updateAssignee({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateAssignee(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, assignees } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, assignees });
  },
  async updateStartDate({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateStartDate(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, startDateTimeMillis } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, startDateTimeMillis });
  },
  async updateDueDate({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateDueDate(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, dueDateTimeMillis } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, dueDateTimeMillis });
  },
  async updateRangeDate({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateRangeDate(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, startDateTimeMillis, dueDateTimeMillis } = data;
    commit("UPDATE_TASK_ATTRS", {
      id,
      parentId,
      startDateTimeMillis,
      dueDateTimeMillis
    });
  },
  async updateProgress({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await updateProgress(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { id, parentId, progress } = data;
    commit("UPDATE_TASK_ATTRS", { id, parentId, progress });
  }
};

export default { state, getters, mutations, actions };
