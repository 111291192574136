export default [
  {
    path: "/todo",
    name: "todo",
    component: () =>
      import(/* webpackChunkName: "todo-main" */ "../views/ToDoMain.vue"),
    children: [
      {
        path: ":workspaceId",
        name: "todo_workspace"
      },
      {
        path: "/todo/:workspaceId/:boardId",
        name: "todo_board"
      },
      {
        path: "/todo/:workspaceId/:boardId/:actionObj",
        name: "todo_board_action"
      }
    ]
  }
];
