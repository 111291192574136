<template>
  <div
    class="cr-tree-wrapper"
    :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
  >
    <v-treeview
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      item-text="title"
      :items="folders"
      :active.sync="activeItems"
      :open.sync="openFolders"
      @update:active="onActive"
    >
      <!-- 아이콘 -->
      <template v-slot:prepend="props">
        <Prepend v-bind="props" />
      </template>

      <!-- 폴더 이름 / 버튼 / 뱃지 -->
      <template v-slot:label="{ item }">
        <TreeItem :item="item" />
      </template>
    </v-treeview>
  </div>
</template>

<style lang="scss" scoped>
.cr-tree-wrapper {
  overflow-y: auto;
  position: absolute;
  top: 52px;
  bottom: 60px;
  left: 0px;
  right: 8px;

  &.mobile {
    top: 60px;
    bottom: 0px;
  }

  .v-treeview ::v-deep {
    /* 트리 노드 */
    > .v-treeview-node.v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          cursor: pointer;
          margin-left: 0px;
        }
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }
      }

      /*
         * 트리 속성 중 transition, shaped 를 같이 설정할 경우
         * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
         */
      .v-treeview-node__children {
        overflow: hidden;
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Prepend from "./prepend";
import TreeItem from "./item";

export default {
  components: { Prepend, TreeItem },
  mounted() {
    this.changeActiveItems();
  },
  data() {
    return {
      folders: [
        { id: "all", title: "전체 업무", folderType: "ALL", children: [] },
        { id: "my", title: "내 업무", folderType: "MY", children: [] },
        {
          id: "request",
          title: "요청한 업무",
          folderType: "REQUEST",
          children: []
        },
        { id: "filter", title: "필터", folderType: "FILTER_ROOT", children: [] }
      ],
      openFolders: [],
      activeItems: [],
      prevActiveItem: null,
      isActiveChanged: false
    };
  },
  computed: {
    ...mapGetters("flowRoute", ["filterId"])
  },
  watch: {
    $route() {
      this.changeActiveItems();
    }
  },
  methods: {
    changeActiveItems() {
      if (!this.$route.name.includes("flow_tasks")) return;

      this.isActiveChanged = true;
      this.activeItems = [{ id: this.filterId || "all" }];
    },
    setActiveItems() {
      setTimeout(() => {
        if (!this.prevActiveItem) return (this.activeItems = []);
        this.activeItems = [this.prevActiveItem];
      }, 0);
    },
    onActive([n]) {
      if (!n && this.isActiveChanged) {
        this.activeItems = [];
        this.prevActiveItem = null;
        this.isActiveChanged = false;
        return;
      }
      if (!n || !n?.folderType) {
        return this.setActiveItems();
      }

      const { id, folderType } = n;
      if (folderType.includes("_ROOT")) {
        const index = this.openFolders.findIndex(f => f.id == id);
        if (index === -1) this.openFolders.push(n);
        else this.openFolders.splice(index, 1);
        return this.setActiveItems();
      }

      this.prevActiveItem = n;
      this.isActiveChanged = false;
    }
  }
};
</script>
