<template>
  <SearchInput />
</template>

<script>
import SearchInput from "./search/SearchInput";

export default {
  components: { SearchInput },
  data() {
    return {};
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
