import manage from "./manage";
import fetch from "./fetch";
import member from "./member";

const state = {
  ...fetch.state,
  ...manage.state,
  ...member.state
};

const getters = {
  ...fetch.getters,
  ...manage.getters,
  ...member.getters
};

const mutations = {
  ...fetch.mutations,
  ...manage.mutations,
  ...member.mutations
};

const actions = {
  ...fetch.actions,
  ...manage.actions,
  ...member.actions,

  snackbar({ dispatch }, { message, type = "ERROR" }) {
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  confirm({ dispatch }, { message, callback, closeCallback }) {
    dispatch(
      "confirm/confirm",
      { message, callback, closeCallback },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
