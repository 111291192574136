import i18n from "@/_locales";
import router from "@/commons/router";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import {
  getDraftMail,
  saveDraftMail,
  putDraftMail
} from "../../../api/write.api";

const d_actions = {
  async _draftCompose({ dispatch }, { mailId: draftId }) {
    const response = await getDraftMail(draftId);
    dispatch("responseProcessing", response);
  },
  async saveDraftMail(actions, { openPopup }) {
    const { state, rootGetters: RG, commit, dispatch } = actions;
    dispatch("loader/setLoaderShow", true, { root: true });

    const attachments = RG["mailFile/attachments"];
    const { folderId, page, actionObj } = RG["mailRoute/getRouteWriteInfo"];
    const { t: type, i: draftId } = actionObj;
    const params = {
      ...state,
      attachments,
      subject: state.subject || `[${i18n.t("mail.임시저장")}]`,
      to: state.to.map(({ value }) => value),
      cc: state.cc.map(({ value }) => value),
      bcc: state.bcc.map(({ value }) => value),
      content: getTinymce().activeEditor.getContent()
    };

    let request = saveDraftMail;
    if (type == 4) {
      request = putDraftMail;
      params["draftId"] = draftId;
    }

    const { status, data } = await request(params);
    if (status !== 201) {
      dispatch("loader/setLoaderShow", false, { root: true });
      dispatch("_snackbar", { message: i18n.t("mail.363"), type: "ERROR" });
      return;
    }

    commit("SET_DRAFT_ID", data);
    const { name: routeName } = router.currentRoute;
    // 탭 사용중 && 메일쓰기탭이 아닐때 route parmas 만 변경
    const useTab = RG["mailConfig/getUseTab"];
    const activatedTab = RG["mailTab/activatedTab"];
    if (useTab && activatedTab != "write" && routeName != "popup_mail_write") {
      const stringifyObj = JSON.stringify({ t: 4, i: data });
      const mail_write = { folderId, page, actionObj: stringifyObj };
      commit("mailRoute/SET_ROUTE_PARAMS", { mail_write }, { root: true });
    }

    // 현재 메일쓰기 화면일때 임시저장 메일쓰기로 라우팅
    if (routeName.includes("mail_write")) {
      const routeParams = { i: data, t: 4, draftSave: true, openPopup };
      dispatch("mailRoute/routeMailWrite", routeParams, { root: true });

      // 에디터 포커스
      getTinymce().activeEditor.focus();
    }

    dispatch("loader/setLoaderShow", false, { root: true });

    // 임시저장 목록 리로딩
    if (dispatch("_getters", "folder/isDrafts")) {
      dispatch("_dispatch", { action: "mail/reloadList" });
    }
  }
};

export { d_actions };
