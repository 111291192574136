<template>
  <v-fab-transition>
    <v-btn
      fab
      dark
      large
      absolute
      color="accent"
      class="fab-messenger cr-tutorial-messenger"
      :style="style"
      @click="SET_MINIMIZE(!minimize)"
    >
      <v-badge color="red" :content="unread" :value="unread > 0" bordered>
        <v-icon>mdi-chat-processing-outline</v-icon>
      </v-badge>
    </v-btn>
  </v-fab-transition>
</template>

<style lang="scss" scoped>
.fab-messenger {
  bottom: 55px;
  ::v-deep .v-badge__badge::after {
    border-color: white;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("messenger", ["unread", "minimize"]),
    ...mapGetters("sidePanel", ["sidePanelInfo"]),
    style() {
      const lgAndUp = this.$vuetify.breakpoint.lgAndUp;
      const { showSidePanel, sidePanelWidth } = this.sidePanelInfo;
      if (showSidePanel && lgAndUp) {
        return `right: ${sidePanelWidth + 15}px`;
      }

      return `right: ${sidePanelWidth - 35}px`;
    }
  },
  methods: {
    ...mapMutations("messenger", ["SET_MINIMIZE"])
  }
};
</script>
