<template>
  <v-menu offset-y @input="v => $emit('update:clickedMenuBtn', v)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on">
        <v-icon size="18">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list dense class="pa-0 py-1">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  destroyed() {
    this.$emit("update:clickedMenuBtn", false);
  },
  computed: {
    items() {
      const { folderType } = this.item;
      if (folderType == "FAVORITE") {
        return [{ title: "즐겨찾기 해제", action: this.unfavorite }];
      }

      return [];
    }
  },
  methods: {
    ...mapActions("flowProjects", ["updateFavoriteStatus"]),
    unfavorite() {
      const { id: projectId } = this.item;
      this.updateFavoriteStatus({ projectId, favoriteStatus: 0 });
    }
  }
};
</script>
