import vuetify from "@/commons/plugins/vuetify";
import { updateTheme } from "@/commons/api/auth.api";

const state = {
  isThemeDialogVisible: false, // 테마 다이얼로그 표시 여부
  currentTheme: "blue" // 현재 테마
};

const getters = {
  isThemeDialogVisible: ({ isThemeDialogVisible }) => isThemeDialogVisible,
  getCurrentTheme: ({ currentTheme }) => currentTheme
};

const mutations = {
  SET_IS_THEME_DIALOG_VISIBLE: (state, value) =>
    (state.isThemeDialogVisible = value),
  SET_CURRENT_THEME: (state, value) => (state.currentTheme = value)
};

const actions = {
  // 테마 초기화
  async resetTheme() {
    const theme = vuetify.framework.theme.defaults[this.theme];
    Object.keys(theme).forEach(i => {
      vuetify.framework.theme.themes.light[i] = theme[i];
    });
  },
  // 테마 다이얼로그 숨기기
  async hideThemeDialog({ commit }) {
    commit("SET_IS_THEME_DIALOG_VISIBLE", false);
  },
  // 테마 다이얼로그 보이기
  async showThemeDialog({ dispatch, commit }) {
    this.theme = await dispatch("auth/getTheme", null, { root: true });
    commit("SET_IS_THEME_DIALOG_VISIBLE", true);
  },
  // 테마 적용
  async applyTheme({ commit }, value) {
    await updateTheme({ theme: value });
    await commit("SET_CURRENT_THEME", value);
    await commit("auth/SET_THEME", value, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
