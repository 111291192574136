import { getGroupByType } from "@/approval/constant/approvalLine";

export const filterByType = (approvalLines, ...lineTypes) => {
  return approvalLines.filter(({ type }) => lineTypes.includes(type));
};
export const filterByGroup = (approvalLines, ...lineGroups) => {
  return approvalLines.filter(({ type }) =>
    lineGroups.includes(getGroupByType(type))
  );
};
export const filterByStatus = (approvalLines, ...lineStatuses) => {
  return approvalLines.filter(({ status }) => lineStatuses.includes(status));
};
export const sortAsc = (approvalLines, sortBy = "sortOrder") => {
  return [...approvalLines].sort((a, b) => a[sortBy] - b[sortBy]);
};
export const sortDesc = (approvalLines, sortBy = "sortOrder") => {
  return [...approvalLines].sort((a, b) => b[sortBy] - a[sortBy]);
};
export const getGroup = line => {
  return getGroupByType(line.type);
};
export const getKey = approvalLine => {
  if (approvalLine?.key) return approvalLine?.key;
  return approvalLine?.approver?.isUser
    ? `USER_${approvalLine?.approver?.userId}`
    : approvalLine?.approver?.isOrgan
    ? `ORGAN_${approvalLine?.approver?.organId}`
    : null;
};

export default {
  filterByType,
  filterByGroup,
  filterByStatus,
  sortAsc,
  sortDesc,
  getGroup
};
