import http from "./http";
const prefix = "/api/tag";

// 태그 추가
const addTag = tag => http.post(`${prefix}/${tag}`);

// 태그 목록 조회
const getTags = params => http.get(`${prefix}`, { params });

// 태그 검색
const searchTags = (tag, params) => http.get(`${prefix}/${tag}`, { params });

export { addTag, getTags, searchTags };
