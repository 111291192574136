<template>
  <v-list>
    <v-subheader v-text="title"></v-subheader>

    <v-list-item dense v-for="calendar in calendars" :key="calendar.id">
      <v-list-item-action>
        <v-checkbox
          v-model="calendar.isSubscribed"
          @click="$emit('click-item', calendar)"
        ></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-text="calendar.title"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    calendars: {
      type: Array,
      required: true
    }
  }
};
</script>
