<template>
  <v-tooltip bottom nudge-bottom="-8">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-on="on"
        v-bind="attrs"
        close
        label
        :color="color"
        class="mr-1 mb-1"
        close-icon="mdi-close"
        @click:close="close"
      >
        {{ name }}
      </v-chip>
    </template>

    {{ chip.value }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    chip: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    name() {
      const { value } = this.chip;
      const [name, address] = value.split("<");
      if (address && (address.startsWith("C#") || address.startsWith("O#"))) {
        return "@" + (name?.replace(/"/g, "") || "");
      }

      return name?.replace(/"/g, "") || "";
    },
    color() {
      const { value } = this.chip;
      const address = value?.split("<")?.at(-1);

      if (address.startsWith("C#")) return "cyan lighten-4";
      if (address.startsWith("O#")) return "deep-orange lighten-4";
      return "grey lighten-3";
    }
  },
  methods: {
    close() {
      this.$emit("removeAttendee", this.chip);
    }
  }
};
</script>
