<template>
  <v-dialog
    scrollable
    no-click-animation
    :fullscreen="$vuetify.breakpoint.smAndDown"
    width="1000px"
    :value="showDialog"
    @input="SET_RESET()"
  >
    <v-card>
      <v-card-title class="py-5 px-6">
        <span
          class="text-h6 font-weight-bold"
          v-text="$t('common.업데이트_내역')"
        ></span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="SET_RESET()">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-10 pb-5"
        :style="getType == 'LIST' ? 'height: 700px' : 'height: 624px'"
      >
        <AnnounceList v-if="getType == 'LIST'" />
        <AnnounceView v-if="getType == 'VIEW'" />
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions class="py-5 px-6" v-if="getType == 'VIEW'">
        <v-btn
          :ripple="false"
          color="primary"
          text
          outlined
          v-text="$t('common.목록')"
          @click="goToList"
        />
        <!-- <v-spacer></v-spacer>
        <v-checkbox
          class="pa-0 ma-0"
          v-model="seeAgain"
          :ripple="false"
          label="오늘 하루 보지 않음"
          color="primary"
          hide-details
        ></v-checkbox> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AnnounceList from "@/commons/views/announce/AnnounceList";
import AnnounceView from "@/commons/views/announce/AnnounceView";
export default {
  components: {
    AnnounceList,
    AnnounceView
  },

  // data: () => ({
  //   seeAgain: false
  // }),

  computed: {
    ...mapGetters("announce", [
      "showDialog",
      "getType",
      "getNowPage",
      "getList"
    ])
  },
  methods: {
    ...mapMutations("announce", ["SET_TYPE", "SET_RESET"]),
    ...mapActions("announce", ["getAnnounceList"]),
    async goToList() {
      if (this.getNowPage == 0) await this.getAnnounceList();
      this.SET_TYPE("LIST");
    }
  }
};
</script>
