<template>
  <div class="d-flex">
    <v-menu
      v-if="date"
      v-bind="dateMenuProps"
      v-model="showDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...attrs, ...dateBtnProps }"
          v-on="on"
          class="px-2 text-body-2 text-none"
          text
          @keydown.stop="handleKeyDown"
        >
          {{ dateText }}
        </v-btn>
      </template>

      <v-date-picker
        :value="date"
        no-title
        :day-format="formatDay"
        @input="changeDate"
      />
    </v-menu>
    <v-menu v-if="time" auto>
      <template #activator="{ attrs, on }">
        <v-btn
          v-bind="{ ...attrs, ...timeBtnProps }"
          v-on="on"
          class="px-2 text-body-2"
          text
        >
          {{ selectedTime.text }}
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item-group mandatory :value="time" @change="changeTime">
          <v-list-item
            :key="time.value"
            v-for="time in times"
            :value="time.value"
            link
          >
            <v-list-item-title>
              {{ time.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import i18n from "@/_locales";
import moment from "moment";

export default {
  props: {
    date: {
      type: String
    },
    time: {
      type: String
    },
    dateMenuProps: {
      type: Object
    },
    dateBtnProps: {
      type: Object
    },
    timeBtnProps: {
      type: Object
    }
  },
  computed: {
    dateText() {
      const now = moment();
      const date = moment(this.date);

      return date.isSame(now, "year")
        ? date.format("Mo Do")
        : date.format("LL");
    },
    selectedTime() {
      return this.times.find(time => time.value === this.time);
    }
  },
  data() {
    return {
      showDatePicker: false,
      times: []
    };
  },
  methods: {
    formatDay(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    changeDate(e) {
      this.showDatePicker = false;
      this.$emit("update:date", e);
    },
    changeTime(e) {
      this.$emit("update:time", e);
    },
    handleKeyDown(e) {
      if (e.key === "Escape") {
        this.showDatePicker = false;
      } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        const date = moment(this.date)
          .add(e.key === "ArrowUp" ? -1 : 1, "days")
          .format("YYYY-MM-DD");
        this.$emit("update:date", date);
      }
    }
  },
  created() {
    const am = i18n.locale === "ko" ? "오전" : "AM";
    const pm = i18n.locale === "ko" ? "오후" : "PM";

    for (let i = 0; i < 24; i++) {
      const hour = ("" + i).padStart(2, "0");
      const hour12 = hour === "12" ? hour : hour - 12;
      this.times.push(
        {
          value: `${hour}:00`,
          // 오전 00:00~11:30, 오후 12:00~11:30 문자열 구성
          text: i < 12 ? `${am} ${hour}:00` : `${pm} ${hour12}:00`
        },
        {
          value: `${hour}:30`,
          text: i < 12 ? `${am} ${hour}:30` : `${pm} ${hour12}:30`
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/simple-scrollbar.scss";

.v-btn ::v-deep .v-btn__content {
  line-height: 20px;
}
</style>
