<template>
  <v-row justify="space-around">
    <v-dialog
      v-model="isShow"
      @click:outside="disagree"
      @keydown.esc="disagree"
      max-width="680px"
    >
      <v-card>
        <v-card-title>
          <span class="headline" v-text="$t('common.22')"></span>
        </v-card-title>
        <v-card-text>
          <v-list class="mb-2">
            <v-list-item class="pr-0">
              <v-icon
                class="mr-2"
                size="60"
                :color="getShareItem.iconColor"
                v-text="getShareItem.icon"
              >
              </v-icon>

              <v-list-item-content>
                <v-list-item-title
                  class="title"
                  v-text="getShareItem.name"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action style="display: inline;">
                <v-btn
                  outlined
                  text
                  :ripple="false"
                  color="primary"
                  @click="copyLink"
                  v-text="$t('common.23')"
                >
                </v-btn>
                <v-btn
                  class="ml-2"
                  outlined
                  text
                  :ripple="false"
                  color="red"
                  @click="deleteLink"
                  v-text="$t('common.24')"
                >
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-text-field
            class="body-1"
            dense
            outlined
            readonly
            hide-details
            id="urlLink"
            :value="link"
          ></v-text-field>
        </v-card-text>
        <div class="cr-link-option-wrap mx-7">
          <table style="width:100%;">
            <colgroup>
              <col style="width:25%;" />
              <col />
            </colgroup>
            <tbody>
              <tr style="border-bottom: white solid 12px;">
                <td
                  class="mr-3"
                  style="vertical-align: top;"
                  v-text="$t('common.25')"
                ></td>
                <td>
                  <v-radio-group
                    mandatory
                    hide-details
                    active-class="label-Active"
                    v-model="isLinkScope"
                  >
                    <v-radio
                      :ripple="false"
                      :label="$t('common.26')"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      :ripple="false"
                      :label="$t('common.27')"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      class="mb-2"
                      :ripple="false"
                      :label="$t('common.28')"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-text-field
                      v-show="usedPw"
                      style="width: 360px;"
                      dense
                      outlined
                      persistent-hint
                      ref="inputPw"
                      prepend-icon="mdi-lock-outline"
                      :hint="$t('common.29')"
                      v-model="password"
                    >
                    </v-text-field>
                  </v-expand-transition>
                </td>
              </tr>
              <tr>
                <td
                  class="mr-3"
                  style="vertical-align: top;"
                  v-text="$t('common.30')"
                ></td>
                <td>
                  <v-radio-group
                    row
                    mandatory
                    active-class="label-Active"
                    v-model="isPermisson"
                  >
                    <v-radio
                      :ripple="false"
                      :label="$t('common.31')"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      :ripple="false"
                      :label="$t('common.32')"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                </td>
              </tr>
              <tr style="height: 30px;">
                <td
                  class="pb-5"
                  style="vertical-align: top;"
                  v-text="$t('common.33')"
                ></td>
                <td style="vertical-align: top;">
                  <div v-if="editDateFlag">
                    {{
                      getShareInfo.validityDate > 0
                        ? $t("common.사용함")
                        : $t("common.사용안함")
                    }}
                    <span
                      class="red--text"
                      v-if="getShareInfo.validityDate > 0"
                      v-text="showValidityDate"
                    >
                    </span>
                    <v-btn
                      style="font-size: 14px; padding-bottom: 2px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      @click="editDateFlag = false"
                      v-text="$t('common.34')"
                    ></v-btn>
                  </div>

                  <div style="display: inline-flex;" v-else>
                    <v-radio-group
                      active-class="label-Active"
                      v-model="selectValidityDate"
                      row
                      mandatory
                      hide-details
                    >
                      <v-radio
                        :ripple="false"
                        :label="$t('common.36', { value: '7' })"
                        :value="7"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        :label="$t('common.36', { value: '30' })"
                        :value="30"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        :label="$t('common.36', { value: '90' })"
                        :value="90"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        :label="$t('common.사용안함')"
                        :value="0"
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      style="font-size: 14px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      color="primary"
                      @click="editValidityDate"
                      v-text="$t('common.수정')"
                    ></v-btn>
                    <v-btn
                      style="font-size: 14px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      color="red darken-1"
                      @click="editDateFlag = true"
                      v-text="$t('common.취소')"
                    ></v-btn>
                  </div>
                </td>
              </tr>
              <tr style="height: 30px;">
                <td style="vertical-align: top;" v-text="$t('common.35')"></td>
                <td style="vertical-align: top;">
                  <div class="primary--text" v-if="editCountFlag">
                    <span
                      v-text="
                        $t('common.37', { value: getShareInfo.accessCount })
                      "
                    ></span>
                    <v-btn
                      class="pb-1"
                      style="font-size: 14px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      @click="editCountFlag = false"
                      v-text="$t('common.변경')"
                    ></v-btn>
                  </div>
                  <div style="display: inline-flex;" v-else>
                    <v-radio-group
                      row
                      mandatory
                      hide-details
                      active-class="label-Active"
                      v-model="selectAccessCnt"
                    >
                      <v-radio
                        :ripple="false"
                        :label="$t('common.37', { value: '10' })"
                        :value="10"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        :label="$t('common.37', { value: '50' })"
                        :value="50"
                      ></v-radio>
                      <v-radio
                        :ripple="false"
                        :label="$t('common.37', { value: '100' })"
                        :value="100"
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      class="pt-1"
                      style="font-size: 14px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      color="primary"
                      @click="editAccessCount"
                      v-text="$t('common.수정')"
                    ></v-btn>
                    <v-btn
                      class="pt-1"
                      style="font-size: 14px;"
                      :ripple="false"
                      x-small
                      plain
                      text
                      color="red darken-1"
                      @click="editCountFlag = true"
                      v-text="$t('common.취소')"
                    ></v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <v-card-actions class="pb-4 px-6">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            v-text="$t('common.확인')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
.v-input {
  margin-top: 0px;
  padding-top: 0px;
}

.label-Active >>> .v-label {
  color: #1976d2 !important;
}

.v-input.body-1
  >>> .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: inherit;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
import i18n from "@/_locales";

export default {
  data: () => ({
    editDateFlag: true,
    editCountFlag: true,
    selectValidityDate: 30,
    selectAccessCnt: 100,
    password: ""
  }),
  watch: {
    isShow() {
      this.editDateFlag = true;
      this.editCountFlag = true;
      this.selectValidityDate = 30;
      this.selectAccessCnt = 100;
      this.password = "";
    },
    getInitInfo(initInfo) {
      this.password = initInfo.pw;
    }
  },
  computed: {
    ...mapGetters("linkShare", [
      "isShow",
      "getModuleType",
      "getShareItem",
      "getShareInfo",
      "getInitInfo"
    ]),
    // 링크
    link() {
      const moudleType = this.getModuleType.toLowerCase();
      return `http://localhost:9090/#/${moudleType}/link/${this.getShareInfo.linkUrl}`;
    },
    // 링크 접근 권한
    isLinkScope: {
      get() {
        return this.getShareInfo.linkScope;
      },
      set(value) {
        // 비밀번호를 적용 라디오버튼을 클릭하지 않을 시에는 비밀번호 설정 초기화
        if (value !== 3) this.password = "";
        this.updateLinkScope(value);
      }
    },
    // 비밀번호 설정 사용 여부
    usedPw() {
      return this.getShareInfo.linkScope == 3 ? true : false;
    },
    // 사용자 권한
    isPermisson: {
      get() {
        return this.getShareInfo.permission;
      },
      set(value) {
        this.updatePermission(value);
      }
    },
    // 현재 남은 유효기간
    showValidityDate() {
      const validateDate = getDateWithoutTime(this.getShareInfo.validityDate);
      return i18n.t("common.38", { value: validateDate });
    }
  },
  methods: {
    ...mapActions("linkShare", [
      "disagree",
      "updateLinkScope",
      "updatePermission",
      "updateValidityDate",
      "updateAccessCount",
      "updatePw",
      "saveShareInfo",
      "deleteLinkShare"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),

    async changeLinkScope(value) {
      await this.updateLinkScope(value);
    },

    // URL 복사
    copyLink() {
      const urlLink = document.getElementById("urlLink");

      urlLink.select();
      document.execCommand("copy");

      this.openSnackbar({
        message: i18n.t("common.39"),
        type: "SUCCESS"
      });
    },

    // 링크 삭제
    async deleteLink() {
      const result = await this.deleteLinkShare();

      let message;
      let type;
      switch (result) {
        case -1:
          type = "ERROR";
          message = i18n.t("common.7");
          break;
        case 0:
          type = "ERROR";
          message = i18n.t("common.40");
          break;
        default:
          type = "SUCCESS";
          message = i18n.t("common.41");
          break;
      }

      this.openSnackbar({
        message,
        type
      });
      this.disagree();
    },
    // 접근횟수 수정
    async editAccessCount() {
      this.updateAccessCount(this.selectAccessCnt);
      this.editCountFlag = true;
    },
    // 유효기간 수정
    async editValidityDate() {
      if (this.selectValidityDate == 0) {
        this.updateValidityDate(0);
      } else {
        const date = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        const dateValue =
          date.getTime() + (this.selectValidityDate + 1) * 86400000;

        this.updateValidityDate(dateValue - 1);
      }
      this.editDateFlag = true;
    },
    // 링크정보 저장
    async save() {
      let type;
      let message;

      // 유효기간, 남은접근 횟수 수정버튼 확인
      if (!(this.editDateFlag && this.editCountFlag)) {
        this.openSnackbar({
          type: "VALIDATION",
          message: i18n.t("common.42")
        });
        return;
      }

      // 비밀번호 사용여부 조회
      if (this.usedPw) {
        // 비밀번호 사용한다면 유효성검사
        this.password = this.password.trim();
        if (!this.validationCheck()) {
          this.$refs.inputPw.focus();
          return;
        }

        this.updatePw(this.password);
      }

      // 처음에 저장해놓았던 공유링크 정보와 현재 정보를 비교 하여서 차이가 있다면 업데이트
      // 그렇지 않다면 그냥 팝업창을 닫는다.
      const {
        linkScope,
        permission,
        validityDate,
        accessCount,
        pw
      } = this.getShareInfo;
      const nowShareInfo = {
        linkScope,
        permission,
        validityDate,
        accessCount,
        pw
      };

      const isSame =
        Object.entries(nowShareInfo).toString() ===
        Object.entries(this.getInitInfo).toString();

      if (!isSame) {
        const result = await this.saveShareInfo(nowShareInfo);

        switch (result) {
          case -1:
            type = "ERROR";
            message = i18n.t("common.7");
            break;
          case 0:
            type = "ERROR";
            message = i18n.t("common.43");
            break;
          default:
            type = "SUCCESS";
            message = i18n.t("common.44");
            break;
        }

        this.openSnackbar({
          message,
          type
        });
      }
      this.disagree();
    },
    // 비밀번호 설정시 유효성 검사
    validationCheck() {
      // 공백 포함 안됨
      var pattern = /\s/g;
      if (this.password.match(pattern)) {
        this.openSnackbar({
          type: "VALIDATION",
          message: i18n.t("common.45")
        });

        return false;
      }

      // 비밀번호가 4~8자리 사이여야함.
      if (this.password.length < 4 || this.password.length > 8) {
        this.openSnackbar({
          type: "VALIDATION",
          message: i18n.t("common.46")
        });

        return false;
      }

      // 한글 포함 불가
      const reg = /[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]/g;
      if (reg.test(this.password)) {
        this.openSnackbar({
          type: "VALIDATION",
          message: i18n.t("common.47")
        });
        return false;
      }

      return true;
    }
  }
};
</script>
