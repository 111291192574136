export default [
  {
    path: "/mail/view/:folderId/:page/:actionObj", // 5
    name: "mail_view",
    component: () =>
      import(/* webpackChunkName: "mail_view" */ "../views/MailMain.vue")
  },
  {
    path: "/mail/write/:folderId/:page/:actionObj", // 5
    name: "mail_write",
    component: () =>
      import(/* webpackChunkName: "mail_write" */ "../views/MailMain.vue")
  },
  {
    path: "/mail/config/:folderId/:page/:tabId", // 5
    name: "mail_config",
    component: () =>
      import(/* webpackChunkName: "mail_config" */ "../views/MailMain.vue")
  },
  {
    path: "/mail/view/original/:actionObj", // 4
    name: "popup_original_view",
    component: () =>
      import(
        /* webpackChunkName: "popup_original_view" */ "../views/ViewOriginal.vue"
      )
  },
  {
    path: "/mail/view/popup/:actionObj", // 4
    name: "popup_mail_view",
    component: () =>
      import(
        /* webpackChunkName: "mail_popup_view" */ "../views/PopupWindow.vue"
      )
  },
  {
    path: "/mail/write/popup/:actionObj", // 4
    name: "popup_mail_write",
    component: () =>
      import(
        /* webpackChunkName: "mail_popup_view" */ "../views/PopupWindow.vue"
      )
  },
  {
    path: "/mail",
    name: "mail",
    component: () =>
      import(/* webpackChunkName: "mail-main" */ "../views/MailMain.vue"),
    children: [
      {
        path: ":folderId/:page",
        name: "mail_list"
      },
      {
        path: ":folderId/:page/:actionObj",
        name: "mail_list_action"
      }
    ]
  }
];
