const state = {
  showBox: false,
  selectBoxItems: []
};

const getters = {
  isShowBox: ({ showBox }) => showBox,
  getSelectBoxItems: ({ selectBoxItems }) => selectBoxItems
};

const mutations = {
  SET_SHOW: (state, isShow) => {
    state.showBox = isShow;
  },
  /* 
   item 선택시 selectBox에서의 설정
   box안에 있는경우 -> 선택해제, box안에 없는경우 -> 선택
  */
  SET_SELECT_ITEM: (state, item) => {
    const index = state.selectBoxItems.findIndex(
      boxItem => boxItem.id == item.id
    );

    if (index < 0) {
      state.selectBoxItems.push(item);
    } else {
      state.selectBoxItems.splice(index, 1);
    }
  },
  SET_SELECT_ITEM_ARRAY: (state, items) => (state.selectBoxItems = items),
  // 전체 선택 / 해제
  SET_SELECT_ITEM_All: (state, { checked, list }) => {
    // 전체 선택했을 경우
    if (checked) {
      list.forEach(item => {
        const index = state.selectBoxItems.findIndex(
          boxItem => boxItem.id == item.id
        );
        if (index < 0) state.selectBoxItems.push(item);
      });
    } else {
      // 전체 해제 했을 경우
      list.forEach(item => {
        const index = state.selectBoxItems.findIndex(
          boxItem => boxItem.id == item.id
        );
        if (index >= 0) state.selectBoxItems.splice(index, 1);
      });
    }
  },
  RESET_SELECT_BOX: state => {
    state.selectBoxItems = [];
    state.showBox = false;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
