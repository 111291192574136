import Vue from "vue";
import itemFilter from "../utils/filter";

import {
  saveFilter,
  updateFilter,
  updateDefaultFilterId,
  deleteFilter
} from "@/todo/api/board.api";

const state = {
  // 현재 적용중인 필터조건
  filters: [],
  // 서버에 저장된 필터목록
  savedFilters: [],
  defaultFilterId: 0
};

// state, getters, rootState, rootGetters
const getters = {
  filters: ({ filters }) => filters,
  filtersIds: ({ filters }, getters, { todoItem: { items } }) =>
    itemFilter(items, filters),
  savedFilters: ({ savedFilters }) => savedFilters,
  defaultFilterId: ({ defaultFilterId }) => defaultFilterId,
  findSavedFilter: ({ savedFilters }) => filterId =>
    savedFilters.filter(f => f.id == filterId)
};

const mutations = {
  RESET_STATE: state => {
    state.filters = [];
    state.savedFilters = [];
  },
  SET_SAVED_FILTERS: (state, { savedFilters, defaultFilterId }) => {
    state.filters = [];
    state.savedFilters = savedFilters;
    state.defaultFilterId = defaultFilterId;
  },
  ADD_SAVED_FILTER: (state, savedFilter) => {
    state.savedFilters.push(savedFilter);
  },
  UPDATE_SAVED_FILTER: (state, { id, savedFilter }) => {
    const index = state.savedFilters.findIndex(f => f.id == id);
    if (index > -1) {
      Vue.set(state.savedFilters, index, savedFilter);
    }
  },
  CHANGE_DEFAULT_FILTER: (state, defaultFilterId) => {
    state.defaultFilterId = defaultFilterId;
  },
  DELETE_SAVED_FILTER: (state, id) => {
    const index = state.savedFilters.findIndex(f => f.id == id);
    if (index > -1) {
      Vue.delete(state.savedFilters, index);
    }
  },
  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  }
};

const actions = {
  async saveFilter(
    { state, dispatch, commit, rootState },
    { boardId, filterName, isDefault }
  ) {
    const enabledFilters = state.filters.filter(
      ({ column, condition, values }) =>
        column.value &&
        condition.value &&
        Array.isArray(values) &&
        values.length > 0
    );
    const { data, status } = await saveFilter(
      boardId,
      filterName,
      isDefault,
      enabledFilters
    );

    let message = `필터를 저장하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 201: {
        if (data) {
          if (isDefault == 1) commit("CHANGE_DEFAULT_FILTER", data.id);
          commit("ADD_SAVED_FILTER", data);
          message = `필터를 저장했습니다.`;
          type = "SUCCESS ";
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    if (status == 201) {
      const { viewType } = rootState.todoRoute;
      dispatch(
        "todoRoute/routeBoardAction",
        {
          ...rootState.todoRoute,
          actionObj: JSON.stringify({ filterId: data.id, viewType })
        },
        { root: true }
      );
    }
  },
  async updateFilter(
    { state, commit },
    { boardId, id, filterName, isDefault }
  ) {
    const enabledFilters = state.filters.filter(
      ({ column, condition, values }) =>
        column.value &&
        condition.value &&
        Array.isArray(values) &&
        values.length > 0
    );
    const { status, data } = await updateFilter(
      boardId,
      id,
      filterName,
      isDefault,
      enabledFilters
    );
    if (status == 201) {
      commit("UPDATE_SAVED_FILTER", { id, savedFilter: data });
      commit("todoModal/CLOSE_MODAL", null, { root: true });

      if (state.defaultFilterId !== id && isDefault === 0) return;
      commit("CHANGE_DEFAULT_FILTER", isDefault === 1 ? id : 0);
    }
  },
  async updateDefaultFilterId({ state, commit, dispatch }, { boardId, id }) {
    const isDefault = state.defaultFilterId === id ? 0 : id;
    const { status } = await updateDefaultFilterId(boardId, id, isDefault);

    let message = `기본 필터를 등록하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 201: {
        commit("CHANGE_DEFAULT_FILTER", isDefault ? id : 0);
        message = `기본 필터를 변경했습니다.`;
        type = "SUCCESS ";
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteFilter({ commit, dispatch, rootState }, { boardId, id }) {
    const { status } = await deleteFilter(boardId, id);
    if (status == 204) {
      // 삭제처리
      commit("DELETE_SAVED_FILTER", id);
      dispatch(
        "todoRoute/routeBoard",
        { ...rootState.todoRoute },
        { root: true }
      );
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
