<template>
  <AvatarMenu v-if="isProject" :avatar="avatar" disabled />
  <v-icon v-else :class="{ 'ml-3': leaf, 'primary--text': active }">
    {{ `mdi-${icons[item.folderType] || "file-document"}` }}
  </v-icon>
</template>

<script>
import AvatarMenu from "@/flow/views/components/commons/avatarMenu";

export default {
  components: { AvatarMenu },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    },
    leaf: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Object,
      default: () => ({
        DASHBOARD: "view-dashboard",
        COLLECTION_ROOT: "text-box",
        FAVORITE_ROOT: "star",
        TAG_ROOT: "tag-multiple"
      })
    }
  },
  computed: {
    isProject() {
      return this.item.folderType === "FAVORITE";
    },
    avatar() {
      return { ...this.item, size: 22 };
    }
  }
};
</script>
