<template>
  <div>
    <!-- 카테고리 -->
    <CategoryField />
    <!-- 번역 -->
    <TranslateField />
    <!-- 대상/어투 -->
    <TargetField />
    <!-- 질문 입력 -->
    <PromptField />

    <!-- 버튼 -->
    <v-card-actions class="mt-0">
      <v-spacer />
      <v-btn text @click="close">
        닫기
      </v-btn>
      <v-btn
        elevation="0"
        color="primary"
        :disabled="disableButton"
        :loading="isLoading"
        @click="send"
      >
        생성하기
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CategoryField from "./CategoryField";
import TranslateField from "./TranslateField";
import TargetField from "./TargetField";
import PromptField from "./PromptField";

export default {
  components: { CategoryField, TranslateField, TargetField, PromptField },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("ai", ["isLoading", "getMaxLength", "getQuestion"]),
    disableButton() {
      return (
        this.getMaxLength !== 0 &&
        (this.isLoading ||
          this.getQuestion.length === 0 ||
          this.getQuestion.length > this.getMaxLength)
      );
    }
  },
  watch: {},
  methods: {
    ...mapActions("ai", ["hideAIDialog", "promptWithSirTEAM"]),
    close() {
      this.hideAIDialog();
    },
    send() {
      this.promptWithSirTEAM();
    }
  }
};
</script>
