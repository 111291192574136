import http from "@/commons/api/http";

const prefix = "api/todo/group";

/**
 * group 생성
 * @param {*} boardId 보드 id
 */
const addGroup = ({ boardId, title }) => {
  const formData = new FormData();
  formData.append("title", title);

  return http.post(`${prefix}/${boardId}`, formData);
};

/**
 * group 타이틀 변경
 * @param {*} boardId 보드 id
 * @param {*} groupId 그룹 id
 * @param {*} title 그룹 타이틀
 */
const updateGroupTitle = (boardId, groupId, title) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("boardId", boardId);

  return http.put(`${prefix}/${groupId}/title`, formData);
};
/**
 * group 색상 변경
 * @param {*} boardId 보드 id
 * @param {*} groupId 그룹 id
 * @param {*} color 그룹 색상
 */
const updateGroupColor = (boardId, groupId, color) => {
  const formData = new FormData();
  formData.append("color", color);
  formData.append("boardId", boardId);

  return http.put(`${prefix}/${groupId}/color`, formData);
};
/**
 * 그룹 순서 변경
 * @param {*} groupId 그룹 id
 * @param {*} boardId 보드 id
 * @param {*} targetGroupId 옮기려는 위치의 그룹
 * @param {*} position 옮기는 위치
 */
const moveGroup = (groupId, boardId, targetGroupId, position) => {
  const formData = new FormData();
  formData.append("boardId", boardId);
  formData.append("targetGroupId", targetGroupId);
  formData.append("position", position);

  return http.put(`${prefix}/${groupId}/move`, formData);
};
/**
 * group 삭제
 * @param {*} groupId 그룹 id
 * @param {*} boardId 보드 id
 */
const deleteGroup = (boardId, groupId) => {
  const formData = new FormData();
  formData.append("boardId", boardId);

  return http.delete(`${prefix}/${groupId}`, { data: formData });
};

export { addGroup, updateGroupTitle, updateGroupColor, moveGroup, deleteGroup };
