import i18n from "@/_locales";

const r_state = {
  isReserve: false,
  reserveDate: "",
  gmt: { title: i18n.t("mail.90"), value: i18n.t("mail.91") }
};

// state, getters, rootState, rootGetters
const r_getters = {
  isReserve: ({ isReserve }) => isReserve,
  reserveDate: ({ reserveDate }) => reserveDate,
  gmt: ({ gmt }) => gmt
};

const r_mutations = {
  SET_RESERVE: (state, { isReserve, reserveDate }) => {
    state.isReserve = isReserve;
    state.reserveDate = reserveDate;
  }
};

export { r_state, r_getters, r_mutations };
