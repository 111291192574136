const state = {
  show: false,
  msg: "",
  position: "bottom",
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  pageX: 0,
  pageY: 0
};

const getters = {
  show: ({ show }) => show,
  msg: ({ msg }) => msg,
  position: ({ position }) => position,
  width: ({ width }) => width,
  height: ({ height }) => height,
  top: ({ top }) => top,
  left: ({ left }) => left,
  pageX: ({ pageX }) => pageX,
  pageY: ({ pageY }) => pageY
};

const mutations = {
  SET_TOOLTIP: (state, { msg, position = "bottom", event }) => {
    // 대상이 되는 엘리먼트에 cr-tooltip 클래스 넣어줘야한다.
    const target = event.target.closest(".cr-tooltip");
    if (!target) {
      state.show = false;
      return;
    }

    const { width, height, top, left } = target.getBoundingClientRect();
    state.show = true;
    state.msg = msg;
    state.position = position;
    state.width = width;
    state.height = height;
    state.top = top;
    state.left = left;
  },
  SET_MSG: (state, msg) => {
    state.msg = msg;
  },
  SET_MOVING_TOOLTIP: (state, { msg, position, event }) => {
    state.msg = msg;
    state.position = `moving_${position}`;
    state.pageX = event.pageX;
    state.pageY = event.pageY;
  },
  SET_MOVING_POSITION: (state, { pageX, pageY }) => {
    state.pageX = pageX;
    state.pageY = pageY;
  },
  HIDE_TOOLTIP: state => {
    state.show = false;
    state.msg = "";
    state.position = "bottom";
    state.width = 0;
    state.height = 0;
    state.top = 0;
    state.left = 0;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
