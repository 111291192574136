<template>
  <v-row justify="space-around">
    <v-dialog
      max-width="400"
      :value="isShow"
      @input="$emit('update:dialog', false)"
      @keydown.esc="disagree"
      @keydown.enter="agree"
      @click:outside="disagree"
    >
      <v-card style="min-width: 400px; max-width: 400px;">
        <v-card-title>
          <span class="headline text-h6 font-weight-bold">
            {{ getHeadline }}
          </span>
        </v-card-title>
        <v-card-text>
          <div v-html="getMessage" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="isShowCloseBtn" text outlined @click="disagree">
            {{ getCancelButtonTitle }}
          </v-btn>
          <v-btn outlined color="accent" @click="agree">
            {{ getOkButtonTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("confirm", [
      "isShow",
      "getHeadline",
      "getMessage",
      "getCancelButtonTitle",
      "getOkButtonTitle",
      "isShowCloseBtn"
    ])
  },
  methods: {
    ...mapActions("confirm", ["agree", "disagree", "confirm"])
  }
};
</script>
