import { getContactDetail } from "../../api/detail.api";

const state = {
  detail: {},
  isEdit: false,
  loading: false
};

const getters = {
  isEdit: ({ isEdit }) => isEdit,
  loading: ({ loading }) => loading,
  isEmpty: ({ detail, loading }) => !detail?.id && !loading,
  detail: ({ detail }) => detail
};

const mutations = {
  SET_IS_EDIT: (state, isEdit) => {
    state.isEdit = isEdit;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_CARD_DETAIL: (state, detail) => {
    state.detail = detail;
    state.loading = false;
  }
};

const actions = {
  async getContactDetail({ commit }, id) {
    commit("SET_LOADING", true);
    commit("SET_IS_EDIT", false);
    const { status, data } = await getContactDetail({ id });
    if (status !== 200) {
      // 알림 창
      return;
    }

    commit("SET_CARD_DETAIL", data);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
