const highlight = {
  bind(el, binding) {
    el.innerText = binding.value;
    let html = el.innerHTML;
    if (html.indexOf("&lt;em class='cr-hlt'&gt;") > -1) {
      html = html
        .replace(/&lt;em class='cr-hlt'&gt;/gi, "<em class='cr-hlt'>")
        .replace(/&lt;\/em&gt;/gi, "</em>");
      el.innerHTML = html;
    }
  },
  update(el, binding) {
    el.innerText = binding.value;
    let html = el.innerHTML;
    if (html.indexOf("&lt;em class='cr-hlt'&gt;") > -1) {
      html = html
        .replace(/&lt;em class='cr-hlt'&gt;/gi, "<em class='cr-hlt'>")
        .replace(/&lt;\/em&gt;/gi, "</em>");
      el.innerHTML = html;
    }
  }
};

export default highlight;
