import API from "@/approval/api/approval.api";
import router from "@/commons/router";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { LINE_GROUP, LINE_TYPE } from "@/approval/constant/approvalLine";

// state 초기값
const initialState = {
  loading: false,
  document: {},
  approvalLines: [],
  opinions: [],
  form: {}
};

const state = { ...initialState };

const getters = {
  getNextApproveLine: ({ approvalLines }) => {
    return approvalLines.find(line => line.status === "PENDING_APPROVAL");
  },
  getLastApproveLine: ({ approvalLines }) => {
    return new ApprovalLines(approvalLines)
      .filterByGroup(LINE_GROUP.APPROVAL)
      .sortAsc()
      .getLast();
  }
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_DOCUMENT: (state, payload) => (state.document = { ...payload }),
  SET_APPROVAL_LINES: (state, payload) => {
    state.approvalLines = new ApprovalLines(payload).sortAsc().get();
  },
  SET_FORM: (state, payload) => {
    state.form = { ...payload };
  },
  SET_OPINIONS: (state, opinions) => {
    state.opinions = [...opinions];
  },
  UPDATE_OPINION: (state, opinion) => {
    state.opinions = state.opinions.map(o => {
      if (o.id === opinion.id) {
        return opinion;
      }
      return o;
    });
  }
};

const actions = {
  // 페이지 초기화
  async init({ commit, dispatch }, docId) {
    commit("RESET");
    const fetchDocument = await dispatch("fetchDocument", docId);
    if (fetchDocument) {
      await dispatch("fetchApprovalLines");
      await dispatch("fetchForm");
      await dispatch("fetchOpinions");
    }
  },
  // 문서 로드
  async fetchDocument({ commit, dispatch }, docId) {
    commit("SET_LOADING", true);
    const { status, data } = await API.getDocument(docId);
    // 실패시
    if (status !== 200) {
      switch (status) {
        case 403:
          await router.push({ name: "approval_not_allowed" });
          break;
        case 404:
          await router.push({ name: "approval_not_found" });
          break;
        default:
          dispatch(
            "snackbar/openSnackbar",
            {
              message: "문서를 불러오는데 실패했습니다.",
              type: "ERROR"
            },
            { root: true }
          );
      }
      commit("SET_LOADING", false);
      return false;
    }
    commit("SET_DOCUMENT", data);
    commit("SET_LOADING", false);
    return true;
  },
  // 기안 양식 로드
  async fetchForm({ commit, dispatch, state }) {
    commit("SET_LOADING", true);
    const { status, data } = await API.getFormById(state.document.formId);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안양식이 존재하지 않습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    commit("SET_FORM", data);
    commit("SET_LOADING", false);
  },
  // 결재의견 로드
  async fetchOpinions({ commit, dispatch, state }) {
    commit("SET_LOADING", true);
    const { status, data } = await API.getOpinionList(state.document.id);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재의견 조회에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    commit("SET_OPINIONS", data);
    commit("SET_LOADING", false);
  },
  // 결재선 목록 로드
  async fetchApprovalLines({ commit, dispatch, state }) {
    commit("SET_LOADING", true);
    const { status, data } = await API.getApprovalLines(state.document.id);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재선 조회에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    commit("SET_APPROVAL_LINES", data);
    commit("SET_LOADING", false);
  },
  // 결재 의견 수정
  async editComment({ commit, dispatch }, { id, comment }) {
    commit("SET_LOADING", true);
    const { status, data } = await API.updateOpinion(id, comment);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재의견 수정에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    commit("UPDATE_OPINION", data);
    commit("SET_LOADING", false);
    return true;
  },
  // 기안 회수
  async retrieveDocument({ commit, dispatch }, docId) {
    commit("SET_LOADING", true);
    const { status } = await API.retrieveDocument(docId);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안 회수에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "기안을 회수했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 기안함 > 회수된 목록으로 이동
    await router.push({
      name: "approval_document_list",
      params: { boxKey: "de" }
    });
    commit("SET_LOADING", false);
    return true;
  },
  // 문서 삭제
  async deleteDocument({ dispatch, commit, state }) {
    commit("SET_LOADING", true);
    const { status } = await API.deleteDocument(state.document.id);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "문서 삭제에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "문서를 삭제하였습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 문서 삭제 후 리디렉션
    const isPreview = router.currentRoute.name === "approval_document_preview";
    if (isPreview) {
      await router.push({ name: "approval_home" });
    } else {
      await router.push({
        name: "approval_document_list",
        params: router.currentRoute.params,
        query: router.currentRoute.query
      });
    }
    commit("SET_LOADING", false);
    return true;
  },
  // 결재 승인
  async approveLine(
    { getters, commit, dispatch },
    { lineId, preApproval, comment }
  ) {
    commit("SET_LOADING", true);
    const { status } = await API.lineApprove(lineId, preApproval, comment);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "결재를 완료 하였습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 승인 처리한 결재선 유형에 따라 목록으로 리디렉션
    const lineType = getters.getNextApproveLine?.type;
    switch (lineType) {
      case LINE_TYPE.APPROVAL:
      case LINE_TYPE.HELP:
        if (
          preApproval ||
          getters.getLastApproveLine.id === getters.getNextApproveLine.id
        ) {
          // [결재함 > 완료된] 목록으로 이동
          await router.push({
            name: "approval_document_list",
            params: { boxKey: "ac" }
          });
        } else {
          // [결재함 > 진행중] 목록으로 이동
          await router.push({
            name: "approval_document_list",
            params: { boxKey: "ap" }
          });
        }
        break;
      case LINE_TYPE.HELP_ORGAN:
        // [부서문서함 > 부서협조] 목록으로 이동
        await router.push({
          name: "approval_document_list",
          params: { boxKey: "oh" }
        });
        break;
      default:
        // 대시보드로 이동
        await router.push({ name: "approval_home" });
    }
    commit("SET_LOADING", false);
  },
  // 결재 반려
  async rejectLine(
    { getters, commit, dispatch },
    { lineId, preReject, comment }
  ) {
    commit("SET_LOADING", true);
    const { status } = await API.lineReject(lineId, preReject, comment);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "결재를 완료 하였습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 반려 처리한 결재선 유형에 따라 목록으로 리디렉션
    const lineType = getters.getNextApproveLine?.type;
    switch (lineType) {
      case LINE_TYPE.APPROVAL:
      case LINE_TYPE.HELP:
        if (preReject) {
          // [결재함 > 결재전] 목록으로 이동
          await router.push({
            name: "approval_document_list",
            params: { boxKey: "ab" }
          });
        } else {
          // [결재함 > 반려된] 목록으로 이동
          await router.push({
            name: "approval_document_list",
            params: { boxKey: "ar" }
          });
        }
        break;
      case LINE_TYPE.HELP_ORGAN:
      default:
        // 대시보드로 이동
        await router.push({ name: "approval_home" });
    }
    commit("SET_LOADING", false);
  },
  // 결재 취소
  async cancelLine({ commit, dispatch, state }, lineId) {
    commit("SET_LOADING", true);
    const { status } = await API.lineCancel(lineId);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "결재를 취소하는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "결재를 취소했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    commit("SET_LOADING", false);
    dispatch("init", state.document.id);
  },
  // 수신확인
  async receiveLine({ dispatch, commit }, lineId) {
    commit("SET_LOADING", true);
    const { status } = await API.lineReceive(lineId);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "수신확인에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "수신 확인 처리했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 결재선 및 결재의견 리로드
    await dispatch("fetchApprovalLines");
    await dispatch("fetchOpinions");
    commit("SET_LOADING", false);
    return true;
  },
  // 반송
  async returnLine({ dispatch, commit }, { lineId, comment }) {
    commit("SET_LOADING", true);
    const { status } = await API.lineReturn(lineId, comment);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "반송 처리에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "반송 처리했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 결재선 및 결재의견 리로드
    await dispatch("fetchApprovalLines");
    await dispatch("fetchOpinions");
    commit("SET_LOADING", false);
    return true;
  },
  /**
   * 상위 결재선 변경
   * @param upperLines  변경할 상위 결재선 목록
   */
  async changeUpperLines({ state, commit, dispatch }, upperLines) {
    commit("SET_LOADING", true);
    const { status } = await API.changeUpperLine(state.document.id, upperLines);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "상위결재선 변경에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "상위 결재선을 변경했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 결재선 및 결재의견 리로드
    await dispatch("fetchApprovalLines");
    await dispatch("fetchOpinions");
    commit("SET_LOADING", false);
    return true;
  },
  /**
   * 공람자 추가
   * @param shareLines  추가할 공람 결재선 목록
   */
  async addShareLines({ state, commit, dispatch }, shareLines) {
    commit("SET_LOADING", true);
    const { status } = await API.addShareLine(state.document.id, shareLines);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "공람 추가에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "공람을 추가했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 결재선 리로드
    await dispatch("fetchApprovalLines");
    commit("SET_LOADING", false);
    return true;
  },
  /**
   * 공개 설정 변경
   * @param openType  변경할 공개 유형
   */
  async changeOpenType({ state, commit, dispatch }, openType) {
    commit("SET_LOADING", true);
    const { status } = await API.changeOpenType(state.document.id, openType);
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "공개 유형 변경에 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return false;
    }
    // 성공시
    dispatch(
      "snackbar/openSnackbar",
      {
        message: "공개 유형을 변경했습니다.",
        type: "SUCCESS"
      },
      { root: true }
    );
    // 변경된 공개유형 반영
    commit("SET_DOCUMENT", { ...state.document, openType });
    commit("SET_LOADING", false);
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
