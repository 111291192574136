<template>
  <v-icon :color="color" :class="cls">
    {{ icon }}
  </v-icon>
</template>

<script>
import { privateFolderType } from "@/mail/constant/folderType";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      const { icon } = this.item || {};
      if (icon) return icon;
      if (this.open) return "mdi-folder-open";
      return "mdi-folder";
    },
    color() {
      const { color } = this.item || {};
      if (color) return color;
      if (this.active) return "primary";
      return "";
    },
    cls() {
      const { childCount, folderType } = this.item;
      if (
        !childCount &&
        (privateFolderType[folderType] ||
          folderType === "SEARCH" ||
          folderType === "TAG")
      ) {
        return "ml-3";
      }

      return "";
    }
  }
};
</script>
