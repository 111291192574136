import http from "@/commons/api/http";

const prefix = "api/mail/config/sign";

/**
 * 사용자 서명 리스트 조회
 * @returns
 */
const getSignList = () => http.get(prefix);

/**
 * 서명 추가
 * @param {*} param0
 * @returns
 */
const addSign = ({ title, signStyle, contents }) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("signStyle", signStyle);
  formData.append("contents", contents);

  return http.post(prefix, formData);
};

/**
 * 서명 수정
 * @param {*} param0
 * @returns
 */
const editSign = ({ id, title, signStyle, contents }) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("signStyle", signStyle);
  formData.append("contents", contents);
  return http.put(`${prefix}/${id}`, formData);
};

/**
 * 새 메일 서명 사용 여부 변경
 * @param {*} id
 * @returns
 */
const updateUseNewMail = id => http.put(`${prefix}/${id}/useNewMail`);

/**
 * 답장, 전달 메일 서명 사용 여부 변경
 * @param {*} id
 * @returns
 */
const updateUseReplyForward = id => http.put(`${prefix}/${id}/useReplyForward`);

/**
 * 서명 삭제
 * @param {*} id
 * @returns
 */
const deleteSign = id => http.delete(`${prefix}/${id}`);

export {
  getSignList,
  addSign,
  editSign,
  updateUseNewMail,
  updateUseReplyForward,
  deleteSign
};
