import http from "@/commons/api/http";

const prefix = "/api/external/token";

// 승인 URL 가져오기
const getExternalAuthUrl = externalType => {
  return http.get(`/api/external/auth/url/${externalType}`);
};

// 해당 모듈의 외부연동 토큰 정보 가져오기
const getExternalTokensByModule = moduleType => {
  return http.get(`${prefix}/${moduleType}`);
};

// 토큰 저장
const saveToken = ({ code, externalType }) => {
  const formData = new FormData();
  if (code) formData.append("code", code);
  return http.post(`${prefix}/${externalType}`, formData);
};

// 엑세스 권한 해제
const revokeToken = externalType => http.delete(`${prefix}/${externalType}`);

export {
  getExternalAuthUrl,
  getExternalTokensByModule,
  saveToken,
  revokeToken
};
