<template>
  <v-system-bar
    window
    class="pr-3"
    color="#dee1e6"
    @mousedown="e => $emit('mousedown', e, 'move')"
    @dblclick="SET_MAXIMIZE(!maximize)"
  >
    <div class="header">팀챗</div>
    <v-spacer></v-spacer>

    <div v-on:mousedown.stop="" style="width:100px;z-index:1;" class="mr-2">
      <v-slider
        v-model="opacity"
        color="grey darken-2"
        track-color="grey lighten-1"
        thumb-color="orange darken-3"
        min="2"
        max="10"
        dense
        hide-details
        thumb-label
        @mousedown="$emit('update:dragStart', true)"
        @mouseup="$emit('update:dragStart', false)"
      ></v-slider>
    </div>

    <v-btn icon x-small class="pl-2 mr-1" @click="SET_MINIMIZE(true)">
      <v-icon>mdi-window-minimize</v-icon>
    </v-btn>
    <v-btn icon x-small class="pl-2 mr-1" @click="SET_MAXIMIZE(!maximize)">
      <v-icon>
        {{ maximize ? "mdi-window-restore" : "mdi-window-maximize" }}
      </v-icon>
    </v-btn>
    <v-btn icon x-small class="pl-2 mr-1" @click="$emit('closeWindow')">
      <v-icon>mdi-window-close</v-icon>
    </v-btn>
  </v-system-bar>
</template>

<style lang="scss" scoped>
.header {
  color: #333;
  height: 28px;
  line-height: 1;
  padding: 9px 16px 3px 14px;
  margin-top: 3px;
  background-color: #fff;
  border-radius: 4px 4px 0px 0px;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    dragStart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("messenger", ["maximize"]),
    opacity: {
      get() {
        return this.tcOpacity();
      },
      set(v) {
        this.SET_OPACITY(v);
      }
    }
  },
  methods: {
    ...mapMutations("messenger", [
      "SET_MAXIMIZE",
      "SET_MINIMIZE",
      "SET_OPACITY"
    ]),
    ...mapGetters("messenger", { tcOpacity: "opacity" })
  }
};
</script>
