import i18n from "@/_locales";
import {
  fetchCustomerGroupList,
  fetchFlatCustomerGroupList,
  createCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup,
  fetchCustomerAdminList,
  restoreCanceledGroup
} from "../../api/group.api";
import { handleError } from "@/customer/constant/constants";
import moment from "moment";

const state = {
  customerGroupList: [],
  activeItems: [],
  openItems: [],
  currentGroup: {},
  adminList: []
};

const addField = (array, field, value) => {
  for (const element of array) {
    if (Array.isArray(element)) {
      addField(element, field, value);
    } else {
      element[field] = value;
    }
  }
};

const collator = new Intl.Collator("ko", {
  numeric: true,
  sensitivity: "base"
});

const recursiveSort = arr => {
  // 기본 탈출 조건
  if (!arr) return arr;
  // 자식 노드 정렬
  arr.forEach(item => {
    if (item.childCount > 0) {
      item.children = recursiveSort(item.children);
    }
  });
  // 현재 배열 정렬
  return arr.sort((p, n) => collator.compare(p.groupName, n.groupName));
};

const getCustomerGroup = (customerGroupList, customerGroupId) => {
  for (let i = 0; i < customerGroupList.length; i += 1) {
    const contactGroup = customerGroupList[i];
    if (contactGroup.id === customerGroupId) return contactGroup;

    if (contactGroup.children) {
      const childrenResult = getCustomerGroup(
        contactGroup.children,
        customerGroupId
      );
      if (childrenResult) return childrenResult;
    }
  }
};

/**
 * 계층 리스트 순환 조회하여, 부모 group 들만 리턴
 * @param contactGroupList
 * @param contactGroupId
 * @param result
 * @returns {*[]|*}
 */
const extractParents = (contactGroupList, contactGroupId, result = []) => {
  const group = getCustomerGroup(contactGroupList, contactGroupId);
  if (group.parentId) {
    result.push(getCustomerGroup(contactGroupList, group.parentId));
  } else {
    if (result.length === 0) {
      result.push(state.customerGroupList[0]);
    }
    return result;
  }
  return extractParents(contactGroupList, group.parentId, result);
};

const rearrangeObjects = groupList => {
  // id를 키로 객체를 맵에 저장
  const idMap = new Map();
  groupList.forEach(obj => {
    idMap.set(obj.id, obj);
  });

  // 새로운 배열을 위한 결과 배열
  const result = [];
  const added = new Set();

  function addWithChildren(obj, depth = 0) {
    if (added.has(obj.id)) return;
    obj.depth = depth;
    result.push(obj);
    added.add(obj.id);
    // 자식들을 추가
    groupList.forEach(child => {
      if (child.parentId === obj.id) {
        addWithChildren(child, depth + 1);
      }
    });
  }

  // parentId가 없는 최상위 객체들을 먼저 추가
  groupList.forEach(obj => {
    if (!obj.parentId || !idMap.has(obj.parentId)) {
      addWithChildren(obj);
    }
  });

  return result.filter(group => group.hasPermission);
};

const getters = {
  getRootGroup: ({ customerGroupList }) => customerGroupList[0],
  getCustomerGroupList: ({ customerGroupList }) => customerGroupList,
  getActiveItems: ({ activeItems }) => activeItems,
  getOpenItems: ({ openItems }) => openItems,
  getCurrentGroup: ({ activeItems }) => activeItems[0],
  getCustomerGroup: ({ customerGroupList }) => contactGroupId =>
    getCustomerGroup(customerGroupList, contactGroupId),
  getFullParentGroups: ({ customerGroupList }) => customerGroupId =>
    extractParents(customerGroupList, customerGroupId),
  getCustomerAdminList: ({ adminList }) => adminList,
  getHasWritePermission: ({ activeItems }) => {
    const [activeItem = {}] = activeItems;
    if (activeItem) {
      return activeItem.hasPermission;
    }
    return false;
  }
};

const mutations = {
  SET_CUSTOMER_GROUP_LIST: (state, customerGroupList) =>
    (state.customerGroupList = customerGroupList),
  SET_ACTIVE_ITEMS: (state, items) => (state.activeItems = items),
  SET_OPEN_ITEMS: (state, items) => (state.openItems = items),
  SET_CUSTOMER_ADMIN_LIST: (state, adminList) => (state.adminList = adminList),
  UPDATE_CURRENT_GROUP_STATUS: (state, isDeleted) => {
    state.activeItems[0].isDeleted = isDeleted;
    state.activeItems[0].deletedTimeMillis = isDeleted ? moment().valueOf() : 0;
  }
};

const actions = {
  async loadCustomerGroupList({ commit }) {
    const { data = [] } = await fetchCustomerGroupList();
    addField(data, "isEdit", false);
    commit("SET_CUSTOMER_GROUP_LIST", recursiveSort(data));
  },

  async loadCustomerAdminList({ commit }) {
    const { data = [] } = await fetchCustomerAdminList();
    commit("SET_CUSTOMER_ADMIN_LIST", data);
  },

  /**
   * 고객사 주소록 그룹 리스트 조회
   * @returns {Promise<*[]>}
   */
  async getFlatCustomerGroupList() {
    const { data = [] } = await fetchFlatCustomerGroupList();
    return rearrangeObjects(data);
  },

  /**
   * 주소록 그룹 추가
   *
   * @param {*} param0
   * @param newGroup
   */
  async addCustomerGroup({ dispatch }, newGroup) {
    const { status, data } = await createCustomerGroup(newGroup);

    const messages = {
      ERROR: i18n.t("contact.65"),
      SUCCESS: i18n.t("contact.66")
    };

    if (status !== 201) {
      return handleError(dispatch, messages.ERROR);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    //await dispatch("loadCustomerGroupList");
    return { result: true, id: data.id };
  },
  /**
   * 주소록 그룹 추가
   *
   * @param {*} param0
   * @param group
   */
  async updateCustomerGroup({ commit, dispatch }, group) {
    const { status, data } = await updateCustomerGroup(group);

    const messages = {
      ERROR: i18n.t("customer.message.fail", {
        action: i18n.t("customer.modify")
      }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("customer.modify")
      }),
      NO_PERMISSION: i18n.t("customer.validate.permission")
    };

    if (status !== 200) {
      return handleError(
        dispatch,
        status === 403 ? messages.NO_PERMISSION : messages.ERROR
      );
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    await dispatch("loadCustomerGroupList");
    commit("SET_ACTIVE_ITEMS", [data]);
    return true;
  },
  /**
   * 고객 그룹 삭제
   *
   * @param {*} param0
   * @param {*} customerGroupId
   * @returns
   */
  async deleteCustomerGroup({ commit, dispatch }, customerGroupId) {
    const { status } = await deleteCustomerGroup(customerGroupId);

    const messages = {
      ERROR: i18n.t("contact.69"),
      SUCCESS: i18n.t("contact.70")
    };

    if (status !== 200) {
      return handleError(
        dispatch,
        status === 403 ? messages.NO_PERMISSION : messages.ERROR
      );
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    await dispatch("loadCustomerGroupList");
    commit("UPDATE_CURRENT_GROUP_STATUS", true);
    return true;
  },
  /**
   * 삭제 그룹 복원
   *
   * @param {*} param0
   * @param {*} customerGroupId
   * @returns
   */
  async restoreCustomerGroup({ commit, dispatch }, customerGroupId) {
    const { status } = await restoreCanceledGroup(customerGroupId);

    const messages = {
      ERROR: i18n.t("customer.message.fail", {
        action: i18n.t("customer.restore.title")
      }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("customer.restore.title")
      })
    };

    if (status !== 200) {
      return handleError(
        dispatch,
        status === 403 ? messages.NO_PERMISSION : messages.ERROR
      );
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );

    await dispatch("loadCustomerGroupList");
    commit("UPDATE_CURRENT_GROUP_STATUS", false);
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
