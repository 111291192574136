<template>
  <div class="cr-tag-list">
    <v-list ref="list" dense outlined :max-height="listHeight">
      <v-list-item-group color="primary" v-model="select">
        <v-list-item
          ref="item"
          v-for="tag in list"
          :key="tag.tag"
          :class="{ 'cr-selected-tag': tag.isViewTag }"
          @click.stop="$emit('rowClick', tag)"
        >
          <v-list-item-title>
            {{ tag.tag }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.cr-tag-list {
  .v-list ::v-deep {
    overflow-y: auto;
  }
}

.cr-selected-tag::before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--v-primary-base);
  content: "";
  opacity: 0.095;
  z-index: 5;
}
</style>

<script>
export default {
  props: {
    listHeight: {
      type: Number,
      default: 400
    },
    listSelect: {
      type: Number,
      default: undefined
    },
    viewTags: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  destroyed() {
    this.$emit("update:listSelect", undefined);
  },
  computed: {
    select: {
      get() {
        return this.listSelect;
      },
      set(select) {
        this.$emit("update:listSelect", select);
      }
    }
  },
  watch: {
    listSelect(n, p) {
      this.scrollMove(n, p);
    }
  },
  methods: {
    // 키보드로 방향키 이동시 스크롤 이동
    scrollMove(idx, prevIdx = 0) {
      const { list, item } = this.$refs;
      if (!list || !item || !item[idx]?.$el) return;

      const { offsetHeight, scrollTop } = list.$el;
      const { offsetHeight: itemHeight } = item[idx].$el;
      const itemPosition = itemHeight * (idx + 1);

      if (
        (idx > prevIdx && itemPosition >= offsetHeight + scrollTop) ||
        (idx < prevIdx && itemPosition < scrollTop)
      ) {
        item[idx].$el.scrollIntoView();
      }
    }
  }
};
</script>
