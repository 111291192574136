<template>
  <v-hover v-slot="{ hover }">
    <div class="cr-floating-wrapper">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="cr-floating"
            :style="`width: ${hover ? sidePanelWidth : sidePanelWidth / 2}px;`"
            v-on="on"
            v-bind="attrs"
            @click="$emit('openDrawer')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </div>
        </template>

        <span>{{ $t("mail.491") }}</span>
      </v-tooltip>
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-floating-wrapper {
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 9px;
  z-index: 6;

  .cr-floating {
    padding: 8px 2px;
    background: #ffffff;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    box-shadow: 0 2px 2px 0 rgb(60 64 67 / 30%),
      0 2px 5px 3px rgb(60 64 67 / 15%);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("sidePanel", ["sidePanelWidth"])
  }
};
</script>
