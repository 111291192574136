<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon @click="show">
        <v-icon>
          mdi-creation-outline
        </v-icon>
      </v-btn>
    </template>

    <span>SirTEAM AI</span>
  </v-tooltip>
</template>

<style lang="scss" scoped>
@keyframes colorChange {
  0% {
    color: red;
  }
  16.66% {
    color: orange;
  }
  33.33% {
    color: yellow;
  }
  50% {
    color: green;
  }
  66.66% {
    color: blue;
  }
  83.33% {
    color: indigo;
  }
  100% {
    color: red;
  }
}

.v-btn {
  position: absolute;
  bottom: 108px;
  right: 23px;
  z-index: 6;
  i {
    animation: colorChange 5s infinite;
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("ai", ["showAIDialog"]),
    show() {
      this.showAIDialog({ type: "REQUEST" });
    }
  }
};
</script>
