<!-- 메일의 조직도 연동 컴포넌트 기반으로 작성.
 사용법과 데이터 형식이 약간씩 다르므로 복사하여 수정
 @See RecipientSelector.vue -->
<template>
  <v-row justify="space-around">
    <v-dialog
      max-width="1000px"
      :value="dialog"
      @input="$emit('update:dialog', false)"
    >
      <v-card>
        <v-card-text class="pb-0">
          <div class="cr-recpt-selector-container pt-6">
            <div class="cr-recpt-selector-wrapper">
              <TreeView
                :selectorType="selectorType"
                :isOrgan="isOrgan"
                @updateType="updateType"
              />
              <div class="cr-rcpt-list-wrapper">
                <List
                  v-bind="{ ...$props, ...$data }"
                  v-on="$listeners"
                  @addAttendee="addAttendee"
                  @removeAttendee="removeAttendee"
                />
              </div>
              <div class="cr-rcpt-select-panel">
                <div class="cr-cell cr-selected">
                  <div class="cr-seleted-title font-weight-bold">
                    <span>{{ $t("calendar.참석자") }}</span>
                    <span class="ml-1 blue--text text--darken-1">
                      {{ selectedListAttendee.length }}
                    </span>
                  </div>

                  <div class="py-1 cr-chips">
                    <Chip
                      v-bind="$props"
                      v-on="$listeners"
                      v-for="chip in selectedListAttendee"
                      :key="chip.key"
                      :chip="chip"
                      @removeAttendee="removeAttendee"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$emit('update:dialog', false)">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="confirm = true">
            {{ $t("common.확인") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import TreeView from "./tree";
import Chip from "./selectPanel/Chip.vue";
import List from "./list";

export default {
  components: { Chip, TreeView, List },
  props: {
    existAttendee: {
      type: Array,
      default: () => []
    },
    dialog: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedListAttendee: [],
      searchWord: "",
      selectorType: "organ"
    };
  },
  created() {
    this.selectedListAttendee = [...this.existAttendee];
  },
  computed: {
    ...mapGetters("mailDialog", ["params"]),
    isOrgan() {
      return this.selectorType === "organ";
    }
  },
  watch: {
    confirm(n) {
      if (!n) return;
      this.$emit("setSearchResultFromDialog", this.selectedListAttendee);
      this.$emit("closeDialog");
    }
  },
  methods: {
    equalsValue(value1, value2) {
      const regex = /[\s"<>]/g;
      return value1.replace(regex, "") === value2.replace(regex, "");
    },
    equalsEmail(email1, email2) {
      return this.getEmail(email1) === this.getEmail(email2);
    },
    getEmail(email) {
      const [name, address] = email.split("<");

      if (address) return address.replace(">", "");
      return name;
    },
    addAttendee(attendee) {
      this.selectedListAttendee.push(attendee);
    },
    removeAttendee(attendee) {
      let selectedAttendee = [...this.selectedListAttendee];
      this.selectedListAttendee = selectedAttendee.filter(
        r => r.value !== attendee.value
      );
    },
    updateType(val) {
      this.selectorType = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-recpt-selector-container {
  display: flex;
  flex-direction: column;
  height: 624px;

  .cr-recpt-selector-wrapper {
    display: flex;
    align-items: center;
    height: 600px;
    .cr-rcpt-list-wrapper {
      width: 400px;
      height: 100%;
      padding-right: 6px;
    }
    .cr-rcpt-select-panel ::v-deep {
      height: 100%;
      width: 440px;
      overflow: auto;

      .cr-cell {
        height: 100%;
        padding: 6px;
        border: 2px solid rgba(0, 0, 0, 0.06);
        &.cr-selected {
          border: 2px solid var(--v-primary-base);
        }
      }
    }
  }
}
</style>
