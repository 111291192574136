<template>
  <div class="cr-search-history-footer">
    <a
      v-show="useSearchHistory"
      class="ml-4 text-caption text--secondary"
      @click.prevent="$emit('deleteHistory', 0, true)"
    >
      {{ $t("common.18") }}
    </a>
    <!-- <a class="ml-auto mr-4 text-caption text--secondary" @click.prevent="">
      {{ useSearchHistory ? $t("common.19") : $t("common.20") }}
    </a> -->
  </div>
</template>

<style lang="scss" scoped>
.cr-search-history-footer {
  display: flex;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 26px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fafafb;
  padding: 3px 0px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("mailLayout", ["useSearchHistory"])
  }
};
</script>
