<template>
  <div class="cr-link-viewer-wrapper" :class="showView ? 'cr-view' : ''">
    <MailView v-if="showView" />
    <Verification v-else />
  </div>
</template>

<style lang="scss" scoped>
.cr-link-viewer-wrapper {
  height: 100%;
  overflow-y: auto;
  background: black;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Verification from "./components/linkViewer/Verification.vue";
import MailView from "./components/linkViewer/MailView.vue";

export default {
  components: { Verification, MailView },
  mounted() {
    // 공유 정보 요청
    this.availableUrl();
  },
  computed: {
    ...mapGetters("mailLinkShare", ["showView"])
  },
  methods: {
    ...mapActions("mailLinkShare", ["availableUrl"])
  }
};
</script>
