import Vue from "vue";

const state = {
  hideColumns: {},
  showAllItems: false,
  showTodayIndication: true
};

const getters = {
  hideColumns: ({ hideColumns }) => hideColumns,
  showAllItems: ({ showAllItems }) => showAllItems,
  showTodayIndication: ({ showTodayIndication }) => showTodayIndication
};

const mutations = {
  SET_SHOW_COLUMNS: (state, { value, isHide }) => {
    Vue.set(state.hideColumns, value, isHide);
  },
  SET_SHOW_ALL_ITEMS: (state, showAllItems) => {
    state.showAllItems = showAllItems;
  },
  SET_SHOW_TODAY_INDICATION: (state, showTodayIndication) => {
    state.showTodayIndication = showTodayIndication;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
