import moment from "moment";

const state = {
  date: "",
  type: ""
};
const getters = {};
const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    state.date = params.date;
    state.type = params.type;
  }
};
const actions = {
  async setParams({ commit, dispatch }, params) {
    let parameters = params;
    if (!params.date) parameters["date"] = moment().format("YYYY-MM-DD");
    if (!params.type) parameters["type"] = "ALL";
    await commit("SET_ROUTE_PARAMS", parameters);
    await commit("resource/SET_DATA", parameters, { root: true });
    // 리소스 타입 조회
    await dispatch("resource/loadTypes", "", { root: true });
    await dispatch("resource/loadResourceData", "", { root: true });
  },
  async initData({ dispatch }) {
    // Splash
    await dispatch("splash/setShowSplash", false, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
