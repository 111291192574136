<template>
  <v-row v-show="isShow" class="mt-0">
    <v-col class="d-flex" cols="12" sm="6">
      <v-select
        label="대상"
        :items="getTargets"
        dense
        outlined
        hide-details
      ></v-select>
    </v-col>
    <v-col class="d-flex pl-0" cols="12" sm="6">
      <v-select
        label="어투"
        :items="getTones"
        dense
        outlined
        hide-details
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("ai", ["getSelected", "getTargets", "getTones"]),
    isShow() {
      return this.getSelected?.type !== "TRANSLATE";
    }
  }
};
</script>
