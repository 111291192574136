import i18n from "@/_locales";
import { addTag, getTags } from "../../../api/tag.api";
import {
  searchState,
  searchGetters,
  searchMutations,
  searchActions
} from "./search";

const state = {
  ...searchState,
  show: false,
  loading: false,
  allTags: [],
  newTags: [],
  page: 0,
  totalPages: 0,
  pageSize: 130,

  // 각 모듈별 vuex 연결
  myTags: "",
  addAction: "",
  deleteAction: "",
  editAction: ""
};

const getters = {
  ...searchGetters,
  page: ({ page }) => page,
  totalPages: ({ totalPages }) => totalPages,
  showTagDialog: ({ show }) => show,
  loading: ({ loading }) => loading,
  allTags: ({ allTags }) => allTags,
  newTags: ({ newTags }) => newTags,
  myTags: ({ myTags }, g, rs, rg) => rg[myTags]
};

// state값 수정
const mutations = {
  ...searchMutations,
  /**
   * @param {*} myTags 각 모듈별 폴더 목록에 표시되는 태그목록
   * @param {*} addAction 각 모듈별 폴더 목록에 표시 추가 액션
   * @param {*} deleteAction 각 모듈별 폴더 목록에 표시 삭제 액션
   * @param {*} editAction 각 모듈별 폴더 목록에 표시 색상변경 액션
   *
   */
  SHOW_TAG_DIALOG: (state, params) => {
    state.show = true;
    state.myTags = params.myTags;
    state.addAction = params.addAction;
    state.deleteAction = params.deleteAction;
    state.editAction = params.editAction;
  },
  CLOSE_DIALOG: state => {
    state.show = false;
    state.myTags = "";
    state.addAction = "";
    state.deleteAction = "";
    state.editAction = "";
    state.allTags = [];
    state.newTags = [];
    state.searchTags = [];
  },
  SET_TAGS: (state, { number, content, totalPages }) => {
    state.page = number;
    state.totalPages = totalPages;
    content.forEach(t => {
      const index = state.allTags.findIndex(at => at.tag === t.tag);
      if (index !== -1) return;

      state.allTags.push(t);
    });
    state.loading = false;
  },
  SET_NEW_TAG: (state, tag) => {
    const index = state.newTags.findIndex(t => t.tag === tag.tag);
    if (index !== -1) return;

    state.newTags.unshift(tag);
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  }
};

// mutations 호출 및 비동기 처리
const actions = {
  ...searchActions,
  async getTags({ state, commit, dispatch }, page) {
    commit("SET_LOADING", true);
    const { pageSize } = state;
    const { data, status } = await getTags({ page, pageSize });

    if (status !== 200) {
      dispatch("_snackbar", { message: i18n.t("common.7"), type: "ERROR" });
      commit("SET_LOADING", false);
      return;
    }

    commit("SET_TAGS", data);
  },
  async addTag({ state, commit, dispatch }, { tag, error, callback }) {
    const { searchTags } = state;
    const index = searchTags.findIndex(t => t.tag === tag);
    if (!tag || error || index !== -1) return;

    dispatch(
      "confirm/confirm",
      {
        message: i18n.t("mail.134", { value: tag }),
        callback: async () => {
          const { data, status } = await addTag(tag);

          if (status !== 201) {
            dispatch("_snackbar", {
              message: i18n.t("common.64"),
              type: "ERROR"
            });
          }

          // 목록에 추가
          commit("SET_NEW_TAG", data);
          if (typeof callback === "function") await callback();
        }
      },
      { root: true }
    );
  },
  // 모듈별 태그 목록에 표시 추가
  async addModuleTagList({ state, dispatch }, tag) {
    dispatch(state.addAction, tag, { root: true });
  },
  // 모듈별 태그 목록에 표시 태그 색상 수정
  async updateModuleTagColor({ state, dispatch }, tag) {
    dispatch(state.editAction, tag, { root: true });
  },
  // 모듈별 태그 목록에 표시 삭제
  async deleteModuleTagList({ state, dispatch }, tag) {
    dispatch(state.deleteAction, tag, { root: true });
  },
  _snackbar({ dispatch }, { message, type }) {
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
