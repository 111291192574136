import { promptWithSirTEAM } from "../../api/gpt.api";

const state = {
  useAI: false,
  isAIDialogVisible: false, // 다이얼로그 표시 여부
  maximize: false,

  // 로딩
  loading: false,

  // 프롬프트 길이
  maxLength: 0,

  // 카테고리, 대상, 어투
  items: [
    {
      type: "SUBJECT",
      icon: "mdi-pencil-outline",
      text: "이메일 제목 생성기",
      description: "작성된 내용으로 최적화된 이메일 제목을 생성합니다.",
      placeholder: "메일 내용을 입력해주세요."
    },
    {
      type: "SPELLING",
      icon: "mdi-spellcheck",
      text: "맥락과 맞춤법 검사",
      description: "AI가 작성된 내용을 검토합니다.",
      placeholder: "메일 내용을 입력해주세요."
    },
    {
      type: "SUMMARY",
      icon: "mdi-arrow-collapse-vertical",
      text: "본문 요약 하기",
      description: "입력된 내용을 AI가 요약합니다.",
      placeholder: "메일 내용을 입력해주세요."
    },
    {
      type: "TRANSLATE",
      icon: "mdi-translate",
      text: "AI 번역",
      description: "메일 내용을 번역합니다.",
      placeholder: "변역할 내용을 입력해주세요."
    },
    {
      type: "REQUEST",
      icon: "mdi-email-edit-outline",
      text: "공지/안내 메일 템플릿",
      description: "공지/안내 메일 템플릿을 생성합니다.",
      placeholder: "ex: 서비스 패치에 대한 메일을 작성해줘."
    },
    {
      type: "GREETING",
      icon: "mdi-email-edit-outline",
      text: "인사 메일 템플릿",
      description: "인사 메일 템플릿을 생성합니다.",
      placeholder: "ex: 거래처에 보낼 인사 메일을 작성해줘."
    },
    {
      type: "APOLOGY",
      icon: "mdi-email-edit-outline",
      text: "사과 메일 템플릿",
      description: "사과 메일 템플릿을 생성합니다.",
      placeholder: "ex: 서비스 장애에 대한 사과 메일을 작성해줘."
    }
  ],
  targets: [
    {
      text: "고객사",
      value: "customer"
    },
    {
      text: "파트너",
      value: "partner"
    },
    {
      text: "동료",
      value: "comrade"
    },
    {
      text: "상사",
      value: "officer"
    }
  ],
  tones: [
    {
      text: "전문적인",
      value: "professional"
    },
    {
      text: "친숙한",
      value: "familiar"
    },
    {
      text: "자신감",
      value: "confidence"
    },
    {
      text: "공식적인",
      value: "official"
    }
  ],

  // 언어
  languages: [
    {
      text: "한국어",
      code: "ko"
    },
    {
      text: "영어",
      code: "en"
    },
    {
      text: "중국어",
      code: "cn"
    },
    {
      text: "일본어",
      code: "jp"
    },
    {
      text: "러시아어",
      code: "ru"
    },
    {
      text: "인도네시아어",
      code: "id"
    }
  ],

  // 번역
  translate: ["en", "ko", "cn", "jp", "ru", "id"],

  // 선택된 카테고리
  selected: {},

  // 질문, 답변
  question: "",
  answer: ""
};

const getters = {
  useAI: ({ useAI }) => useAI,
  isAIDialogVisible: ({ isAIDialogVisible }) => isAIDialogVisible,
  isLoading: ({ loading }) => loading,
  maximize: ({ maximize }) => maximize,

  getItems: ({ items }) => items,
  getTargets: ({ targets }) => targets,
  getTones: ({ tones }) => tones,

  getMaxLength: ({ maxLength }) => maxLength,

  getQuestion: ({ question }) => question,
  getAnswer: ({ answer }) => answer,

  getSelected: ({ selected }) => selected,

  getLanguages: ({ languages }) => languages,
  getTranslate: ({ translate }) => translate
};

const mutations = {
  SET_AI: (state, value) => (state.useAI = value),
  SET_IS_AI_DIALOG_VISIBLE: (state, value) => (state.isAIDialogVisible = value),
  SET_MAXIMIZE: (state, maximize) => (state.maximize = maximize),
  SET_LOADING: (state, loading) => (state.loading = loading),

  SET_QUESTION: (state, question) => (state.question = question),
  SET_ANSWER: (state, answer) => (state.answer = answer),
  APPEND_ANSWER: (state, answer) => (state.answer += answer),

  SET_TYPE: (state, selected) => {
    state.selected =
      state.items.find(i => i.type === selected) ?? state.items[0];
  },
  SET_TRANSLATE: (state, translate) => (state.translate = translate)
};

const actions = {
  async initAI({ commit }, value) {
    commit("SET_AI", value);
  },
  // 다이얼로그 숨기기
  async hideAIDialog({ commit }) {
    commit("SET_MAXIMIZE", false);
    commit("SET_IS_AI_DIALOG_VISIBLE", false);
  },
  // 다이얼로그 보이기
  async showAIDialog({ commit, dispatch }, { type, contents = "", run }) {
    commit("SET_TYPE", type);
    commit("SET_QUESTION", contents);
    commit("SET_ANSWER", "");
    commit("SET_IS_AI_DIALOG_VISIBLE", true);

    if (run) {
      dispatch("promptWithSirTEAM");
    }
  },
  setMaximize({ commit }, value) {
    commit("SET_MAXIMIZE", !!value);
  },
  async promptWithSirTEAM({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_ANSWER", "");

    await promptWithSirTEAM({
      promptType: state.selected.type,
      question: state.question,
      before: state.translate[0],
      after: state.translate[1],
      callback: text => {
        commit("SET_ANSWER", text);
      }
    });
    commit("SET_LOADING", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
