<template>
  <v-dialog
    scrollable
    persistent
    no-click-animation
    max-width="400px"
    width="400px"
    v-model="open"
  >
    <v-card max-height="400" :loading="loading">
      <v-card-title>
        <span
          class="text-h6 font-weight-bold"
          v-text="$t('calendar.77')"
        ></span>
      </v-card-title>
      <template slot="progress">
        <v-overlay absolute class="d-flex flex-column text-center">
          <div>
            <v-progress-circular color="primary" indeterminate>
            </v-progress-circular>
          </div>
        </v-overlay>
      </template>
      <v-card-text class="pb-0">
        <v-treeview
          ref="organTree"
          hoverable
          open-all
          color="accent"
          return-object
          item-key="id"
          multiple-active
          :items="organs"
          :load-children="getChildren"
          :open.sync="openItems"
        >
          <template v-slot:prepend="{ item }">
            <v-checkbox
              v-model="item.checked"
              @click="changeChecked(item, $event)"
            />
          </template>
        </v-treeview>
      </v-card-text>

      <Pagination
        v-if="totalPages > 1"
        :page.sync="page"
        :pageSize="pageSize"
        :blockSize="blockSize"
        :totalElements="totalElements"
        @pageClick="pageClick"
        class="pagingArea"
      />

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text outlined @click="closeDialog">
          {{ $t("common.닫기") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.row-pointer :hover {
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  height: 40px;
  &:first-child {
    width: 70%;
  }
  &:last-child {
    width: 30%;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/commons/views/Pagination.vue";
import { getOrganListByParentId } from "@/mail/api/organ.api";
import { subscribeOrganCalendar } from "@/calendar/api/cal.api";
import i18n from "@/_locales";

export default {
  props: ["show"],
  components: { Pagination },
  data() {
    return {
      page: 1,
      pageSize: 10,
      totalPages: 1,
      blockSize: 5,
      totalElements: 0,
      eventViewMenuOpen: false,
      searchedList: null,
      loading: true,
      openItems: [],
      organs: []
    };
  },
  async created() {
    await this.fetchInitialData();
  },
  async mounted() {},
  computed: {
    ...mapGetters("cal", ["getSubscribedCalendars"]),
    ...mapGetters("organ", ["getOrgan"]),
    open: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          this.closeDialog();
        }
      }
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("cal", [
      "syncSirTeamData",
      "findOrganCalendars",
      "findCalendarConfig"
    ]),
    async fetchInitialData() {
      const data = await this.findOrganCalendars();
      const root = this.getNode(data, "1");
      this.openItems.push(root);
      this.organs = data;
      this.loading = false;
    },
    closeDialog() {
      this.openItems = [];
      this.$emit("closeSearchDialog");
    },
    getNode(tree, nodeId) {
      for (let i = 0; i < tree.length; i += 1) {
        const node = tree[i];

        // 해당노드가 찾고자 하는 노드인 경우 리턴
        if (node.id == nodeId) return node;

        // 만약 children(하위노드)가 있는경우 하위노드에서 서치
        if (node.children) {
          const childrenResult = this.getNode(node.children, nodeId);
          if (childrenResult) return childrenResult;
        }
      }
    },
    async getChildren(item) {
      if (item.childCount > 0) {
        const { data = [] } = await getOrganListByParentId({
          parentId: item.id
        });

        const subscribedOrgan = await this.findCalendarConfig();

        const children = data.map(
          ({ id, parentId, name, email, childCount }) => {
            const childrenObj = childCount > 0 ? { children: [] } : {};
            let checked = subscribedOrgan.some(
              element => element.organId == id
            );
            return {
              id,
              parentId,
              name,
              email,
              checked,
              childCount,
              ...childrenObj
            };
          }
        );
        let node = this.getNode(this.organs, item.id);
        node.children = children;
      }
      return [];
    },
    async changeChecked(item, e) {
      this.loading = true;
      const response = await subscribeOrganCalendar({
        userId: item.userId,
        organId: item.id,
        checked: item.checked
      });

      if (response.message) {
        this.openSnackbar({ message: i18n.t("calendar.75"), type: "ERROR" });

        e.stopPropagation();
        e.preventDefault();

        this.loading = false;
        return false;
      }

      await this.syncSirTeamData();
      this.loading = false;

      this.openSnackbar({
        message: i18n.t(`calendar.${item.checked ? "76" : "74"}`, {
          value: item.name
        }),
        type: "SUCCESS"
      });
    }
  }
};
</script>
