const RESOURCE_CONSTANTS = {
  mode: {
    save: "SAVE",
    update: "UPDATE",
    delete: "DELETE"
  },
  typeIcons: {
    ALL: "mdi-check-all",
    MEETING_ROOM: "mdi-door-open",
    CAR: "mdi-car",
    EQUIPMENT: "mdi-package"
  },
  getIcon(type) {
    return this.typeIcons[type];
  }
};

export { RESOURCE_CONSTANTS };
