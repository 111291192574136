import {
  getReply,
  getChildReply,
  getReplyListById,
  deleteReply
} from "@/board/api/board.api";
import Vue from "vue";
import i18n from "@/_locales";

const state = {
  replyStore: {}
};
const getters = {
  getReplyList: ({ replyStore }) => postId => replyStore[postId]
};
const mutations = {
  // 해당 게시글 댓글 초기화
  RESET_REPLY: ({ replyStore }, postId) => {
    Vue.delete(replyStore, postId);
  },
  // 댓글 단건 저장
  SET_REPLY: ({ replyStore }, reply) => {
    const { postId, parentId } = reply;
    let targetReply = replyStore[postId];
    if (parentId > 0) {
      // SUB 리플 저장인 경우
      targetReply.list = targetReply.list.map(r => {
        if (r.id === parentId) {
          r.children = r.children ?? { page: 0, totalPages: 1, list: [] };
          // 부모 댓글 찾아서 답글 추가
          r.children.list.push(convertReplyformat(reply));
          r.childCount++;
        }
        return r;
      });
    } else {
      // ROOT 리플 저장인 경우
      targetReply.list.push(convertReplyformat(reply));
    }
    Vue.set(replyStore, postId, targetReply);
  },
  // 댓글 수정
  UPDATE_REPLY: (
    { replyStore },
    reply
    // { postId, parentId, replyId, replyContent, replyAttach }
  ) => {
    const { id: replyId, postId, parentId, replyContent, attach } = reply;
    let targetReply = replyStore[postId] ?? {};
    if (parentId > 0) {
      // SUB 리플 수정일 경우
      targetReply.list = targetReply.list.map(r => {
        if (r.id === parentId) {
          r.children.list = r.children.list.map(cr => {
            if (cr.id === replyId) cr = { ...cr, replyContent, attach };
            return cr;
          });
        }
        return r;
      });
    } else {
      // ROOT 리플 수정일 경우
      targetReply.list = targetReply.list.map(r => {
        if (r.id === replyId) r = { ...r, replyContent, attach };
        return r;
      });
    }
    Vue.set(replyStore, postId, targetReply);
  },
  // 댓글 목록(페이지) 저장
  SET_REPLY_LIST: ({ replyStore }, { postId, replyData }) => {
    const preData = replyStore[postId] ?? {};
    const converted = overwriteReplyData({ preData, newData: replyData });

    Vue.set(replyStore, postId, converted);
  },
  // 답글 목록(페이지) 저장
  SET_CHILD_REPLY_LIST: ({ replyStore }, { postId, parentId, replyData }) => {
    const targetReply = replyStore[postId] ?? {};
    const convertedList = targetReply.list.map(r => {
      if (r.id === parentId) {
        r.children = overwriteReplyData({
          preData: r.children,
          newData: replyData
        });
      }
      return r;
    });
    targetReply.list = convertedList;

    Vue.set(replyStore, postId, targetReply);
  },
  // 댓글 삭제
  DELETE_REPLY: ({ replyStore }, { postId, parentId, replyId }) => {
    let targetReply = replyStore[postId] ?? {};
    if (parentId > 0) {
      // SUB 리플 삭제일 경우
      targetReply.list = targetReply.list.map(r => {
        if (r.id === parentId) {
          r.children.list = r.children.list.filter(cr => cr.id != replyId);
          r.childCount--;
          // 부모 댓글이 isBlind일때 자식댓글 모두 삭제되었을 시 부모댓 삭제(*undefind)
          if (r.isBlind && r.children.list.length < 1) return;
        }
        return r;
      });
      // *undefind 처리된 요소 제거
      targetReply.list = targetReply.list.filter(r => r);
    } else {
      // ROOT 리플 삭제일 경우
      targetReply.list = targetReply.list.map(r => {
        if (r.id !== replyId) return r;
        // SUB 리플이 존재할 경우 isBlind 처리 / 아닐경우 삭제(*undefind)
        if (r.childCount > 0) {
          r.isBlind = true;
          return r;
        }
      });
      // *undefind 처리된 요소 제거
      targetReply.list = targetReply.list.filter(r => r);
    }
    Vue.set(replyStore, postId, targetReply);
  }
};
const actions = {
  // 댓글 리스트 가져오기
  async loadReplyList({ rootGetters, commit, dispatch }, { postId, page = 0 }) {
    const { status, data } = await getReply({
      postId,
      page: page,
      pageSize: rootGetters["boardConfig/getReplyPageSize"]
    });

    if (status != 200) {
      let type = "ERROR";
      let message = i18n.t("board.27");
      if (status === 403) message = i18n.t("board.8");
      return dispatch(
        "snackbar/openSnackbar",
        { message, type },
        { root: true }
      );
    }

    // 가져온 댓글 리스트 저장
    commit("SET_REPLY_LIST", {
      postId,
      replyData: data
    });
    // 첫페이지 로드시 댓글 갯수 최신화
    if (page === 0) {
      dispatch("boardPost/getReplyCount", postId, { root: true });
    }
  },
  // 답글 리스트 가져오기
  async loadSubReplyList(
    { rootGetters, commit, dispatch },
    { postId, parentId, page = 0 }
  ) {
    const { status, data } = await getChildReply({
      postId,
      parentId,
      page: page,
      pageSize: rootGetters["boardConfig/getReplyPageSize"]
    });

    if (status != 200) {
      let type = "ERROR";
      let message = i18n.t("board.27");
      if (status === 403) message = i18n.t("board.8");
      return dispatch(
        "snackbar/openSnackbar",
        { message, type },
        { root: true }
      );
    }

    // 가져온 댓글 리스트 저장
    commit("SET_CHILD_REPLY_LIST", {
      postId,
      parentId,
      replyData: data
    });
  },
  // 댓글 아이디로 리스트 가져오기
  async loadReplyListById(
    { rootGetters, commit, dispatch },
    { postId, replyId }
  ) {
    const pageSize = rootGetters["boardConfig/getReplyPageSize"];
    const { status, data } = await getReplyListById({ replyId, pageSize });

    if (status != 200) {
      let type = "ERROR";
      let message = i18n.t("board.27");
      if (status === 403) message = i18n.t("board.8");
      return dispatch(
        "snackbar/openSnackbar",
        { message, type },
        { root: true }
      );
    }

    // 가져온 댓글 리스트 저장
    commit("SET_REPLY_LIST", {
      postId,
      replyData: data
    });
    // 댓글 갯수 최신화
    dispatch("boardPost/getReplyCount", postId, { root: true });
  },

  /**
   * 댓글 추가/삭제는 ReplyWriter 컴포넌트에서 처리
   */

  // 댓글 삭제
  async deleteReply(
    { commit, dispatch, rootGetters },
    { postId, replyId, parentId = 0 }
  ) {
    const { status } = await deleteReply({ postId, replyId });

    if (status != 200) {
      let type = "ERROR";
      let message = i18n.t("board.30");
      if (status === 403) message = i18n.t("board.8");
      return dispatch(
        "snackbar/openSnackbar",
        { message, type },
        { root: true }
      );
    }

    // 가져온 댓글 리스트 저장
    commit("DELETE_REPLY", { postId, parentId, replyId });
    // 댓글 갯수 최신화
    const replyCount =
      rootGetters["boardPost/getPostById"](postId)?.replyCount ?? 0;
    commit(
      "boardPost/SET_REPLY_COUNT",
      { postId, count: replyCount - 1 },
      { root: true }
    );
  }
};

/* ================== 편의 함수 ================== */

// 댓글 리스트 덮어쓰기 (새로 추가된 데이터는 반영)
function overwriteReplyData({ preData = {}, newData }) {
  const {
    list: preList = [],
    page: prePage,
    firstPage: preFirstPage
  } = preData;
  const { totalPages, number: page, content: list } = newData;

  // store에 저장된 첫 페이지 보다 작다면 firstPage 갱신
  let firstPage = preFirstPage ?? page;
  firstPage = firstPage > page ? page : firstPage;
  // firstPage가 갱신되었다면 현재 페이지는 그대로
  const lastLoadedPage = firstPage === page ? prePage ?? page : page;

  // let result = { totalPages, page, firstPage, list: [...preList] };
  let result = {
    totalPages,
    page: lastLoadedPage,
    firstPage,
    list: [...preList]
  };

  // 새로 추가된 댓글 추가
  let unshiftList = [];
  list.forEach(r => {
    if (r.childCount > 0) {
      const subReply = r.children ?? {};
      const convertedSubReply = overwriteReplyData({ newData: subReply });
      r.children = convertedSubReply;
    }
    unshiftList.unshift(r);
  });

  // firstPage가 갱신되었을 경우
  if (firstPage === page) {
    result.list.push(...unshiftList);
  } else {
    result.list.unshift(...unshiftList);
  }

  return result;
}

// 댓글의 child 포맷팅 변경
function convertReplyformat(reply) {
  const { children } = reply;
  if (!children) return reply;

  const { totalPages, number: page, content: list } = children;
  let convertedChildren = { totalPages, page, list };
  reply.children = convertedChildren;

  return reply;
}

/* ================== 편의 함수 ================== */

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
