import { getMyAlias } from "../../api/alias.api";

const state = {
  init: false,
  loading: false,
  list: []
};

const getters = {
  aliasSenders: ({ list }) => list
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_MY_ALIAS_LIST: (state, list) => {
    state.init = true;
    state.loading = false;
    state.list = list;
  }
};

const actions = {
  async getMyAlias({ commit }) {
    commit("SET_LOADING", true);
    const { data, status } = await getMyAlias();
    if (status !== 200) return;

    commit("SET_MY_ALIAS_LIST", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
