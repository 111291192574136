import card from "./modules/card";
import cardDetail from "./modules/detail";
import group from "./modules/group";
import contactRoute from "./modules/route";
import contactConfig from "./modules/config";
import contactLayout from "./modules/layout";
import contactDialog from "./modules/dialog";

export default {
  card,
  cardDetail,
  group,
  contactRoute,
  contactConfig,
  contactLayout,
  contactDialog
};
