import API from "@/approval/api/approval.api";

// state 초기값
const initialState = {
  basicConfig: {
    pageSize: 10
  },
  managerConfig: {
    managedOrgans: []
  },
  presetConfig: {
    loading: false,
    apprLinePresets: []
  }
};

const state = { ...initialState };

const getters = {
  getManagedOrganIds: ({ managerConfig }) => {
    return managerConfig?.managedOrgans.map(({ organId }) => organId) ?? [];
  },
  getPageSize: ({ basicConfig }) => basicConfig?.pageSize ?? 10,
  getApprLinePresets: ({ presetConfig }) => presetConfig.apprLinePresets ?? []
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_BASIC_CONFIG: (state, basicConfig = {}) => {
    state.basicConfig = { ...basicConfig };
  },
  SET_MANAGED_ORGANS: (state, organs = []) => {
    state.managerConfig.managedOrgans = [...organs];
  },
  SET_PRESET_CONFIG: (state, presets = []) => {
    state.presetConfig.apprLinePresets = presets;
  },
  SET_PRESET: (state, preset = []) => {
    let presets = [...state.presetConfig.apprLinePresets] || [];
    const replaceIdx = presets.findIndex(({ id }) => id === preset.id);
    if (replaceIdx !== -1) {
      presets[replaceIdx] = preset;
    } else {
      presets = [preset, ...presets];
    }
    state.presetConfig.apprLinePresets = presets;
  },
  REMOVE_PRESET(state, presetId) {
    state.presetConfig.apprLinePresets = state.presetConfig.apprLinePresets.filter(
      ({ id }) => id !== presetId
    );
  }
};

const actions = {
  // 사용자의 기본 설정 로드
  async fetchBasicConfig({ commit, dispatch }) {
    const { status, data } = await API.getBasicConfig();

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      errorMessage: "기본설정을 불러오는데 실패했습니다."
    });
    if (status !== 200) return false;

    commit("SET_BASIC_CONFIG", data);
  },
  // 문서담당중인 조직 아이디 로드
  async fetchManagedOrgans({ commit, dispatch }) {
    const { status, data } = await API.getManagedOrgans();

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      errorMessage: "문서 담당 조직 조회에 실패했습니다."
    });
    if (status !== 200) return false;

    commit("SET_MANAGED_ORGANS", data);
  },
  // 사용자의 기본 설정 변경
  async updateBasicConfig({ state, commit, dispatch }, config = {}) {
    const payload = { ...state.basicConfig, ...config };
    const { status, data } = await API.updateBasicConfig(payload);

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      errorMessage: "사용자 설정을 수정하는데 실패했습니다."
    });
    if (status !== 200) return false;

    commit("SET_BASIC_CONFIG", data);
    return true;
  },
  // 사용자의 결재선 프리셋 목록 조회
  async fetchApprLinePresetList({ commit, dispatch }) {
    const { status, data } = await API.getApprLinePresetList();

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      errorMessage: "내 결재선을 불러오는데 실패했습니다."
    });
    if (status !== 200) return false;

    commit("SET_PRESET_CONFIG", data);
    return true;
  },
  // 결재선 프리셋 새로 추가
  async saveApprLinePreset(
    { commit, dispatch },
    { name, draftOrgan, approvalLines }
  ) {
    const { status, data } = await API.addApprLinePreset({
      name,
      draftOrganId: draftOrgan.organId,
      approvalLines: approvalLines.map(convertLinePayload)
    });

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      successMessage: "내 결재선을 추가했습니다.",
      errorMessage:
        "내 결재선을 추가하는데 실패했습니다. 최대 30개 까지 저장 가능합니다."
    });
    if (status !== 200) return false;

    commit("SET_PRESET", data);
    return true;
  },
  // 결재선 프리셋 수정
  async updateApprLinePreset(
    { commit, dispatch },
    { id, name, draftOrgan, approvalLines }
  ) {
    const payload = {
      id,
      name,
      draftOrganId: draftOrgan.organId,
      approvalLines: approvalLines.map(convertLinePayload)
    };
    const { status, data } = await API.updateApprLinePreset(payload);

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      successMessage: "내 결재선을 수정하였습니다.",
      errorMessage: "내 결재선 수정에 실패했습니다."
    });
    if (status !== 200) return false;

    commit("SET_PRESET", data);
    return true;
  },
  // 결재선 프리셋 이름 변경
  async renameApprLinePreset({ commit, dispatch, state }, { presetId, name }) {
    const { status } = await API.renameApprLinePreset(presetId, name);

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      successMessage: "이름을 수정하였습니다.",
      errorMessage: "이름을 변경하는데 실패했습니다."
    });
    if (status !== 200) return false;

    const updatedPreset = state.presetConfig.apprLinePresets.find(
      ({ id }) => id === presetId
    );
    commit("SET_PRESET", { ...updatedPreset, name });
    return true;
  },
  // 결재선 프리셋 삭제
  async deleteApprLinePreset({ commit, dispatch }, presetId) {
    const { status } = await API.deleteApprLinePreset(presetId);

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      successMessage: "내 결재선을 삭제했습니다..",
      errorMessage: "내 결재선 삭제에 실패했습니다."
    });
    if (status !== 200) return false;

    commit("REMOVE_PRESET", presetId);
    return true;
  },
  // 결재선 프리셋 일괄 삭제
  async deleteApprLinePresetInBulk({ commit, dispatch }, presetIds = []) {
    const { status } = await API.deleteApprLinePresetInBulk(presetIds);

    dispatch("openSnackbar", {
      isSuccess: status === 200,
      successMessage: "내 결재선을 삭제했습니다..",
      errorMessage: "내 결재선 삭제에 실패했습니다."
    });
    if (status !== 200) return false;

    presetIds.forEach(presetId => commit("REMOVE_PRESET", presetId));
    return true;
  },
  /**
   * 알림 편의 함수
   * @param dispatch        vuex
   * @param isSuccess       성공 여부
   * @param successMessage  성공시 메시지 (없을 경우 skip)
   * @param errorMessage    실패시 메시지 (없을 경우 skip)
   */
  openSnackbar({ dispatch }, { isSuccess, successMessage, errorMessage }) {
    if (!successMessage && isSuccess) return;
    if (!errorMessage && !isSuccess) return;
    dispatch(
      "snackbar/openSnackbar",
      {
        message: isSuccess ? successMessage : errorMessage,
        type: isSuccess ? "SUCCESS" : "ERROR"
      },
      { root: true }
    );
  }
};

// 결재선 생성 페이로드로 변환 함수
const convertLinePayload = approvalLine => {
  const { type, sortOrder, approver } = approvalLine;
  return {
    type,
    sortOrder,
    ...(approver.isUser
      ? { organId: approver.organId, userId: approver.userId }
      : { organId: approver.organId })
  };
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
