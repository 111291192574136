import mail from "./modules/mail";
import mailView from "./modules/view";
import mailCompose from "./modules/write/compose";
import folder from "./modules/folder";
import mailRoute from "./modules/route";
import mailLayout from "./modules/layout";
import mailFile from "./modules/file";
import mailReceipt from "./modules/receipt";
import organ from "./modules/organ";
import mailConfig from "./modules/config";
import mailConfigSign from "./modules/sign";
import mailConfigRule from "./modules/rule";
import mailConfigExternal from "./modules/external";
import mailConfigForward from "./modules/forward";
import mailConfigAway from "./modules/away";
import mailDialog from "./modules/dialog";
import mailLinkShare from "./modules/linkShare";
import mailTab from "./modules/tab";
import mailAlias from "./modules/alias";

export default {
  mail,
  mailView,
  folder,
  mailRoute,
  mailLayout,
  mailFile,
  mailReceipt,
  organ,
  mailConfig,
  mailConfigSign,
  mailConfigRule,
  mailConfigExternal,
  mailConfigForward,
  mailConfigAway,
  mailDialog,
  mailLinkShare,
  mailCompose,
  mailTab,
  mailAlias
};
