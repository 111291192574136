import http from "@/commons/api/http";

const prefix = "/api/autocomplete";

const getEmails = ({ page = 1, pageSize = 10, query = "", typeList = [] }) =>
  http.get(`${prefix}/email`, {
    params: {
      page,
      pageSize,
      query,
      typeList: typeList.join(",")
    }
  });

const getGroupUserCount = email =>
  http.get(`${prefix}/group/usercount`, {
    params: {
      email
    }
  });

export { getEmails, getGroupUserCount };
