<template>
  <div class="cr-text-field-wrapper" :class="editable ? 'editable' : ''">
    <div
      v-if="!editable"
      class="cr-title cr-tooltip text-h5"
      @click.stop="changeEditable"
      @mouseover="event => SET_TOOLTIP({ msg: '클릭하여 편집하기', event })"
      @mouseout="HIDE_TOOLTIP"
    >
      <span class="text-truncate">{{ inputValue }}</span>
    </div>
    <v-text-field
      v-else
      ref="textRef"
      dense
      outlined
      hide-details
      class="text-h5"
      :loading="loading"
      :disabled="loading"
      @focus="inputFocus"
      @blur="inputBlur"
      @keydown.enter="enter"
      @keydown.esc="$refs.textRef.blur()"
      :value="inputValue"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-text-field-wrapper {
  width: 100%;
  max-width: 700px;
  min-width: 0px;
  &.editable {
    margin-left: 7px;
  }

  .cr-title {
    display: inline-flex;
    max-width: 700px;
    min-width: 0px;
    font-size: 16px;
    padding: 7px 5px 3px 5px;
    margin: 0px 8px;
    cursor: text;
    &:hover {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 6px 5px 2px 5px;
      margin: 0px 7px;
    }
  }

  .v-input.v-text-field::v-deep {
    max-width: 700px;
    .v-input__control {
      .v-input__slot {
        padding: 0px 5px;
        fieldset {
          border: none;
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  data() {
    return {
      loading: false,
      editable: false
    };
  },
  computed: {
    ...mapGetters("todoBoard", ["currentBoard"]),
    inputValue() {
      return this.currentBoard.title ?? "";
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoBoard", ["updateBoardTitle"]),
    changeEditable() {
      this.editable = true;
      this.HIDE_TOOLTIP();
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    inputFocus() {
      this.$el.style.border = "1px solid var(--v-primary-base)";
    },
    inputBlur() {
      this.$el.style.border = "";
      this.editable = false;
      this.$refs.textRef?.reset();
    },
    async enter(e) {
      const title = e.target.value.trim();
      if (isBlank(title) || this.inputValue == title) {
        this.$refs.textRef?.blur();
        return;
      }

      this.loading = true;
      const { id: boardId, boardType } = this.currentBoard;
      await this.updateBoardTitle({ boardId, boardType, title });

      this.loading = false;
      this.$refs.textRef?.reset();
      this.$refs.textRef?.blur();
    }
  }
};
</script>
