var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-tree-wrap",class:_vm.$vuetify.breakpoint.lgAndUp ? '' : 'mobile'},[_c('v-treeview',{key:"customerTree",attrs:{"dense":"","hoverable":"","activatable":"","transition":"","shaped":"","return-object":"","item-key":"id","item-text":"groupName","items":_vm.getCustomerGroupList,"active":_vm.activeItems,"open":_vm.openItems,"search":_vm.groupSearchWord},on:{"update:active":function($event){_vm.activeItems=$event},"update:open":function($event){_vm.openItems=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.customerGroupType === 'CUSTOMER')?_c('v-icon',{class:item.childCount === 0 ? 'ml-3' : '',attrs:{"color":_vm.getIconColor(item)},domProps:{"textContent":_vm._s(_vm.getIconsText(open, item))}}):_c('v-icon',{class:item.customerGroupType === 'TRASH' ? 'ml-3' : '',attrs:{"color":_vm.getIconColor(item)}},[_vm._v(" "+_vm._s(_vm.ICON_TEXT[item.customerGroupType])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"cr-tree_node_click_area",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.nodeClick(item)}}}),_c('span',{staticClass:"cr-tree-label-text"},[_vm._v(" "+_vm._s(_vm.getGroupNameText(item))+" ")]),(hover && _vm.showContextMenu(item))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.openPositioningMenu(e, item); }).apply(null, arguments)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-dots-vertical')}})],1):_vm._e()],1)]}}],null,true)})]}}])}),_c('v-divider',{staticClass:"mt-1"}),(_vm.hasWritePermissionAtLeastOne)?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function () { return _vm.routeConfigTab({ tabId: 'import' }); }}},[_c('v-list-item-icon',{staticClass:"ml-2 mr-0"},[_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("contact.10")))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }