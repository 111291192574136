import http from "@/commons/api/http";
import i18n from "@/_locales";

const prefix = "/api/drive";

// 기본 드라이브폴더 목록 조회 ok
const getDefaultFolders = () => {
  return http.get(prefix);
};

// 기본 드라이브폴더 목록 조회 ok
const getRootFolder = () => {
  return http.get(`${prefix}/root`);
};

/**
 * 해당 parentId의 자식폴더 조회
 * @param {*} parentId 부모폴더 id
 */
const getFoldersByParentId = ({ parentId = "0" }) =>
  http.get(`${prefix}/${parentId}/children`);

/**
 * 해당 폴더 목록 조회
 * @param {*} folderId 폴더 id
 * @param {*} pageSize 페이지 사이즈
 * @param {*} page     페이지 번호
 * @param {*} sort     정렬 기준
 * @param {*} dir      오름차순 / 내림차순
 * @returns
 */
const getDriveList = ({ folderId, pageSize, page, sort, dir, searchWord }) =>
  http.get(`${prefix}/list`, {
    params: { folderId, pageSize, page, sort, dir, searchWord }
  });

/**
 * 해당 폴더 목록 조회(공유)
 * @param {*} folderId 폴더 id
 * @param {*} pageSize 페이지 사이즈
 * @param {*} page     페이지 번호
 * @param {*} sort     정렬 기준
 * @param {*} dir      오름차순 / 내림차순
 * @returns
 */
const getShareList = ({ folderId, pageSize, page, sort, dir, searchWord }) =>
  http.get(`${prefix}/share/list`, {
    params: { folderId, pageSize, page, sort, dir, searchWord }
  });

/**
 * 해당 폴더를 id를 하위폴더로 포함하고 있는 최상위 폴더 조회(root제외)
 * @param {*} id 최하위 폴더 id
 */
const getSuperParentFolder = id => http.get(`${prefix}/${id}/super/parent`);

/**
 * 폴더 생성
 * @param {*} fileName 폴더 이름
 * @param {*} parentId 부모폴더 id
 * @returns
 */
const addFolder = ({ name = i18n.t("drive.새폴더"), parentId = 1 }) => {
  const formData = new FormData();
  formData.append("fileName", name);
  formData.append("parentId", parentId);

  return http.post(prefix, formData);
};

/**
 * 파일(파일or폴더)명 수정
 * @param {*} fileId   파일id
 * @param {*} fileName 파일 명
 */
const updateFileName = ({ fileId, fileName = i18n.t("drive.제목없음") }) => {
  const formData = new FormData();
  formData.append("fileName", fileName);
  return http.put(`${prefix}/${fileId}/name`, formData);
};

/**
 * 이동가능한 폴더 조회
 */
const getMovableFolders = () => {
  return http.get(`${prefix}/moveable`);
};

/**
 * 폴더 or 파일 이동
 * @param {*} fileIds        이동할 폴더 or 파일 (from)
 * @param {*} targetFolderId 이동할 곳의 폴더id (to)
 */
const moveFile = ({ fileIds, targetFolderId }) => {
  const formData = new FormData();
  formData.append("fileIds", fileIds);
  return http.put(`${prefix}/${targetFolderId}/moved`, formData);
};

/**
 * 폴더 or 파일 복사
 * @param {*} fileIds        이동할 폴더 or 파일 (from)
 * @param {*} targetFolderId 이동할 곳의 폴더id (to)
 */
const copyFile = ({ fileIds, targetFolderId }) => {
  const formData = new FormData();
  formData.append("fileIds", fileIds);
  formData.append("targetFolderId", targetFolderId);
  return http.post(`${prefix}/copy`, formData);
};

/**
 * 별표 색 변경
 * @param {*} fileIds   변경할 파일 id
 * @param {*} important 중요표시
 */
const updateImportant = ({ fileIds, important }) => {
  const formData = new FormData();
  formData.append("fileIds", fileIds);
  formData.append("important", important);
  return http.put(`${prefix}/important`, formData);
};

/**
 * 폴더 삭제(휴지통)
 * @param {*} fileIds 삭제할 폴더 or 파일
 * @returns
 */
const deleteFile = ({ fileIds }) => {
  const formData = new FormData();
  formData.append("fileIds", fileIds);
  return http.delete(prefix, { data: formData });
};

// 휴지통비우기(완전삭제)
const cleanDeleted = deletedFolderId =>
  http.delete(`${prefix}/${deletedFolderId}/clean`);

const uploadFile = (file, folderId, config) => {
  const formData = new FormData();
  formData.append("part", file);
  formData.append("folderId", folderId);
  return http.post(`${prefix}/file/upload`, formData, config);
};

/**
 * 드라이브 폴더 조회
 * @param {*} id 드라이브 아이디
 */
const getDriveFolderById = id => http.get(`${prefix}/${id}`);

/**
 * 드라이브 파일 조회
 * @param {*} params 파일 ids
 * @returns
 */
const getFiles = params => http.get(`${prefix}/files`, { params });

/**
 * 파일 url 바로가기 만들기
 * @param {*} params
 * @returns
 */
const makeShortcut = params => {
  return http.post(`${prefix}/shortcut`, params);
};

/**
 * 바로가기 URL 보기
 * @param {*} id
 * @returns
 */
const showShortcut = id => http.get(`${prefix}/shortcut/${id}`);

/**
 * (공유) 바로가기 URL 보기
 * @param {*} id
 * @returns
 */
const shareShowShortcut = id => http.get(`${prefix}/share/shortcut/${id}`);

/**
 * 메일 본문에 들어갈 공유링크 생성
 * @param {*} params
 * @returns
 */
const makeLinkContent = params => {
  return http.post(`${prefix}/share/mail/link/content`, params);
};

export {
  getDefaultFolders,
  getRootFolder,
  getFoldersByParentId,
  getDriveList,
  getShareList,
  addFolder,
  updateFileName,
  updateImportant,
  getMovableFolders,
  moveFile,
  copyFile,
  cleanDeleted,
  deleteFile,
  getSuperParentFolder,
  uploadFile,
  getDriveFolderById,
  getFiles,
  makeShortcut,
  showShortcut,
  shareShowShortcut,
  makeLinkContent
};
