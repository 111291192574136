<template>
  <div>
    <FlatSelect
      v-model="selected"
      :items="selectList"
      return-object
      :menu-props="{
        disabled: disabled,
        offsetY: true,
        transition: 'slide-y-transition'
      }"
    >
      <template #selection="{ selection }">
        <span class="text-truncate" style="max-width: 208px;">
          {{ selection.text }}
        </span>
      </template>
    </FlatSelect>

    <RecurrenceSettingDialog
      v-if="showDetailDialog"
      :show="showDetailDialog"
      :is-all-day="allDay"
      :start-date="startDate"
      :recur-obj="value"
      persistent
      max-width="300px"
      @close="closeRecurDialog"
    ></RecurrenceSettingDialog>
  </div>
</template>
<script>
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import i18n from "@/_locales";
import RecurrenceSettingDialog from "@/calendar/components/event/RecurrenceSettingDialog.vue";
import moment from "moment/moment";
import {
  dayOfMonthToText,
  getRecurrenceDescriptionText
} from "@/calendar/utils/DateUtils";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";

const RECUR_INFINITE = {
  interval: 1,
  until: "",
  count: 0
};
const CUSTOM = { text: "", value: { type: "CUSTOM" } };

export default {
  components: { FlatSelect, RecurrenceSettingDialog },
  props: {
    value: {},
    allDay: {
      type: Boolean,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      showDetailDialog: false,
      NO_REPEAT: {
        text: this.$t("calendar.반복안함"),
        value: { type: "NONE" }
      },
      CUSTOM_DIALOG: {
        text: this.$t("calendar.맞춤설정"),
        value: { type: "CUSTOM_DIALOG" }
      },
      dayList: CAL_CONSTANTS.DAY_LIST.map(day => ({
        value: day,
        name: i18n.t(`calendar.recur.days.${day}`)
      })),
      customItem: null,
      selected: null,
      lastSelected: null
    };
  },
  computed: {
    selectList() {
      const result = [this.NO_REPEAT, ...this.presets];
      if (this.customItem) {
        result.push(this.customItem);
      }
      result.push(this.CUSTOM_DIALOG);
      return result;
    },
    presets() {
      return this.getPresets();
    }
  },
  created() {
    this.updateSelection();
  },
  watch: {
    value() {
      this.updateSelection();
    },
    selected(item) {
      if (!item.value.type) return;

      switch (item.value.type) {
        case this.NO_REPEAT.value.type:
          this.resetRecurringData();
          break;
        case this.CUSTOM_DIALOG.value.type:
          this.showDetailDialog = true;
          break;
        default:
          // value 속성과 선택된 반복 값이 동일할 때 이벤트를 발행하면 무한루프에 빠질 수 있으니 주의합니다.
          if (
            JSON.stringify(this.value) !== JSON.stringify(item.value.recurObj)
          ) {
            this.$emit("input", item.value.recurObj);
          }
          break;
      }
    }
  },
  methods: {
    getPresets() {
      const date = moment(this.startDate);
      const todayInfo = this.dayList[date.day()];
      const thisWeekOfMonth = Math.ceil(date.date() / 7);

      return [
        {
          text: i18n.t("calendar.recur.freq.DAILY"),
          value: {
            type: "EVERY_DAY",
            recurObj: {
              freq: "DAILY",
              ...RECUR_INFINITE,
              parts: {}
            }
          }
        },
        {
          text: `${i18n.t(
            "calendar.recur.freq.WEEKLY"
          )} ${i18n.t("calendar.118", { value: todayInfo.name })}`,
          value: {
            type: "EVERY_WEEK",
            recurObj: {
              freq: "WEEKLY",
              ...RECUR_INFINITE,
              parts: {
                BYDAY: [todayInfo.value]
              }
            }
          }
        },
        {
          text: `${i18n.t("calendar.recur.freq.MONTHLY")} ${i18n.t(
            "calendar.122",
            {
              weekNumber: thisWeekOfMonth,
              dayOfWeek: todayInfo.name
            }
          )}`,
          value: {
            type: "EVERY_MONTH",
            recurObj: {
              freq: "MONTHLY",
              ...RECUR_INFINITE,
              parts: {
                BYDAY: [`${thisWeekOfMonth}${todayInfo.value}`]
              }
            }
          }
        },
        {
          text: `${i18n.t("calendar.recur.freq.YEARLY")} ${i18n.t(
            "calendar.84",
            {
              month: i18n.d(date.toDate(), { month: "short" }),
              day: dayOfMonthToText(date.date())
            }
          )}`,
          value: {
            type: "EVERY_YEAR",
            recurObj: {
              freq: "YEARLY",
              ...RECUR_INFINITE,
              parts: {
                BYMONTH: date.month() + 1,
                BYMONTHDAY: date.date()
              }
            }
          }
        }
      ];
    },
    resetRecurringData() {
      this.$emit("input", null);
    },
    // 반복 등록 팝업창에서 적용한 rule 적용
    closeRecurDialog(recurObj) {
      this.showDetailDialog = false;

      if (!recurObj) {
        this.selected = this.lastSelected ? this.lastSelected : this.NO_REPEAT;
        return;
      }

      this.$emit("input", recurObj);
    },
    updateSelection() {
      if (!this.value) {
        this.selected = this.NO_REPEAT;
        return;
      }

      // 프리셋 중 동일 구성이 있으면 프리셋을, 없으면 사용자 정의 항목을 만들어 선택시킵니다.
      const preset = this.presets.find(
        preset =>
          JSON.stringify(preset.value.recurObj) === JSON.stringify(this.value)
      );
      if (preset) {
        this.selected = preset;
        this.lastSelected = preset;
        return;
      }

      this.customItem = this.makeCustomItem();
      this.selected = this.customItem;
      this.lastSelected = this.customItem;
    },
    makeCustomItem() {
      const recur = this.value;
      const desc = getRecurrenceDescriptionText(recur);

      return {
        text: desc,
        value: {
          type: CUSTOM.value.type,
          recurObj: {
            ...this.value
          }
        }
      };
    }
  }
};
</script>
<style scoped lang="scss">
.simple-select {
  ::v-deep .v-select__selection {
    max-width: 100%;
  }

  ::v-deep .v-select__selections {
    input {
      display: none;
    }
  }

  ::v-deep .v-input__control {
    min-height: 38px;
  }
}
</style>
