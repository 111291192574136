<template>
  <v-row class="pt-3" no-gutters>
    <v-col class="cr-announce-wrapper" cols="12">
      <div v-if="getList.length > 0" class="cr-list-wrapper">
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in getList"
            :key="index"
            class="px-3 py-2"
            :ripple="false"
            @click="getAnnounceView(item)"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-subtitle-1 d-flex align-center"
                :class="item.isTop == 1 ? 'font-weight-bold' : ''"
              >
                <div class="text-truncate cr-title" v-text="item.title">
                  <!-- style="background: linear-gradient(to top, var(--v-primary-base) 10%, transparent 20%)" -->
                </div>
                <v-icon
                  v-if="item.isNew"
                  class="ml-1 justify-start"
                  color="red accent-3"
                  v-text="'mdi-new-box'"
                ></v-icon>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="grey--text d-flex align-center">
                <span
                  class="cr-regTime"
                  v-text="getDateWithTime(item.registTimeMillis)"
                >
                </span>
                <v-icon v-text="'mdi-chevron-right'"></v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <div
        v-else
        class="cr-no-data d-flex justify-center align-center text-subtitle-1"
        v-text="$t('common.15')"
      ></div>

      <Pagination
        v-if="getTotalPages > 1"
        type="list"
        class="cr-custom-pagination-wrapper"
        :page.sync="getPage"
        :pageSize="getPageSize"
        :blockSize="getBlockSize"
        :totalElements="getTotalElements"
        @pageClick="getAnnounceList"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getDateWithTime } from "@/commons/utils/moment";
import Pagination from "@/commons/views/Pagination";
export default {
  components: {
    Pagination
  },
  data: () => ({}),
  async mounted() {
    if (this.getNowPage == 0) await this.getAnnounceList();
  },
  computed: {
    ...mapGetters("announce", [
      "getList",
      "getNowPage",
      "getPageSize",
      "getBlockSize",
      "getTotalElements",
      "getTotalPages"
    ]),
    getPage: {
      get() {
        return this.getNowPage;
      },
      set(page) {
        this.SET_PAGE(page);
      }
    }
  },
  methods: {
    ...mapMutations("announce", ["SET_PAGE"]),
    ...mapActions("announce", ["getAnnounceList", "getAnnounceView"]),
    getDateWithTime(time) {
      return getDateWithTime(time);
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
.row {
  height: 100%;
  .cr-announce-wrapper {
    position: relative;
    height: 100%;
    .cr-no-data {
      height: 100%;
    }
    .cr-list-wrapper {
      height: calc(100% - 44px);
      .v-list ::v-deep {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 44px;
        overflow-y: auto;
        overflow-x: hidden;
        .v-list-item {
          border-bottom: 1px solid #eee;
          .v-list-item__content .cr-title {
            max-width: calc(100% - 24px);
          }
          .v-list-item__action .cr-regTime {
            padding-top: 2px;
            margin-right: 8px;
          }
        }
      }
    }

    .cr-custom-pagination-wrapper {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
}
</style>
