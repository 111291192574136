<template>
  <v-btn icon outlined color="primary darken-2" @click="click">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">mdi-swap-horizontal</v-icon>
      </template>

      <span>네비게이션 전환</span>
    </v-tooltip>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn {
  position: absolute;
  top: 150px;
  left: 237px;
  z-index: 4;
  background-color: #fff;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowLayout", ["isNavigationSwapped"])
  },
  methods: {
    ...mapMutations("flowLayout", ["SET_IS_NAVIGATION_SWAPPED"]),
    click() {
      this.SET_IS_NAVIGATION_SWAPPED(!this.isNavigationSwapped);
    }
  }
};
</script>
