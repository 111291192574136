import Vue from "vue";
import {
  getWorkspaces,
  getWorkspaceUsers,
  addWorkspace,
  deleteWorkspace,
  updateWorkspace,
  delegateWorkspaceOwner,
  followWorkspace,
  unfollowWorkspace,
  unfollowWorkspaceUser
} from "@/todo/api/workspace.api";

const state = {
  allWorkspaces: [],
  myWorkspaces: [],
  workspaceUsers: []
};

const getters = {
  isEmpty: ({ allWorkspaces }) => allWorkspaces.length == 0,
  currentWorkspace: ({ allWorkspaces }, getters, { todoRoute: tr }) => {
    const [workspace] = allWorkspaces.filter(({ id }) => id == tr.workspaceId);
    return workspace ?? {};
  },
  existWorkspace: ({ allWorkspaces }) => id =>
    allWorkspaces.findIndex(w => w.id == id) !== -1,
  allWorkspaces: ({ allWorkspaces }) => allWorkspaces,
  otherWorkspaces: ({ allWorkspaces, myWorkspaces }) =>
    allWorkspaces.filter(({ id }) => myWorkspaces.indexOf(id) == -1),
  myWorkspaces: ({ allWorkspaces, myWorkspaces }) =>
    myWorkspaces
      .map(id => {
        const [workspace] = allWorkspaces.filter(w => w.id == id);
        return workspace ?? { id: -1 };
      })
      .filter(w => w.id !== -1),
  workspaceUsers: ({ workspaceUsers }) => workspaceUsers
};

const mutations = {
  SET_WORKSPACES: (state, { allWorkspaces, myWorkspaces }) => {
    if (!Array.isArray(allWorkspaces) || !Array.isArray(myWorkspaces)) return;

    state.allWorkspaces = allWorkspaces.map(w => ({
      ...w,
      avatar: w.emoji || w.title?.slice(0, 2) || ""
    }));
    state.myWorkspaces = myWorkspaces;
  },
  SET_WORKSPACE_USERS: (state, workspaceUsers) => {
    state.workspaceUsers = workspaceUsers;
  },
  ADD_WORKSPACE: (state, workspace) => {
    state.allWorkspaces.push(workspace);
    state.myWorkspaces.push(workspace.id);
  },
  DELETE_WORKSPACE: (state, workspaceId) => {
    state.myWorkspaces = state.myWorkspaces.filter(id => id != workspaceId);
    state.allWorkspaces = state.allWorkspaces.filter(
      ({ id }) => id != workspaceId
    );
  },
  UPDATE_WORKSPACE: (state, { id: workspaceId, title, emoji, color }) => {
    const index = state.allWorkspaces.findIndex(w => w.id == workspaceId);
    if (index > -1) {
      const prev = state.allWorkspaces[index];
      Vue.set(state.allWorkspaces, index, {
        ...prev,
        title: title ?? prev.title,
        avatar: emoji || title?.slice(0, 2) || prev.avatar,
        color: color ?? prev.color,
        emoji: emoji ?? prev.emoji
      });
    }
  },
  DELEGATE_WORKSPACE: (state, { workspaceId, userId }) => {
    const index = state.allWorkspaces.findIndex(w => w.id == workspaceId);
    if (index > -1) {
      state.allWorkspaces[index].ownerId = userId;
    }
  },
  ADD_MY_WORKSPACE: (state, { workspaceId, userInfo }) => {
    state.workspaceUsers.push(userInfo);
    state.myWorkspaces.push(workspaceId);
  },
  UNFOLLOW_WORKSPACE: (state, { workspaceId, userId }) => {
    state.myWorkspaces = state.myWorkspaces.filter(id => id != workspaceId);
    state.workspaceUsers = state.workspaceUsers.filter(u => u.id != userId);
  }
};

const actions = {
  async getWorkspaces({ commit, dispatch }) {
    const { data, status } = await getWorkspaces();

    let message = "워크스페이스 정보를 받아오는데 실패했습니다.";
    let type = "ERROR";
    if (status == 200) {
      commit("SET_WORKSPACES", data);
      return;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async getWorkspaceUsers({ state, commit, dispatch }, workspaceId) {
    const { data, status } = await getWorkspaceUsers(workspaceId);

    let message = "워크스페이스 팔로워를 조회하는데 실패했습니다.";
    let type = "ERROR";
    if (status == 200) {
      const [workspace] = state.allWorkspaces.filter(w => w.id == workspaceId);
      if (workspace) {
        // 해당 워크스페이스의 소유자를 맨위로 올려준다.
        const index = data.findIndex(u => u.id == workspace.ownerId);
        if (index > -1) {
          const [target] = data.splice(index, 1);
          data.unshift(target);
        }
      }
      commit("SET_WORKSPACE_USERS", data);
      return;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async addWorkspace({ commit, dispatch }, { title, emoji, color }) {
    const { data, status } = await addWorkspace({ title, emoji, color });

    let message = "워크스페이스를 생성하는데 실패했습니다.";
    let type = "ERROR";

    switch (status) {
      case 403:
        message = "워크스페이스를 최대 5개까지 생성할 수 있습니다.";
        break;
      case 201: {
        message = "워크스페이스를 생성했습니다.";
        type = "SUCCESS";

        const root = { root: true };
        const { emoji, title, id: workspaceId } = data;
        const avatar = emoji || title.slice(0, 2) || "";
        await commit("ADD_WORKSPACE", { ...data, avatar });
        await dispatch("todoRoute/routeWorkspace", { workspaceId }, root);
        await commit(
          "todoDialog/SET_DIALOG",
          {
            headline: "보드 추가",
            type: "addBoard",
            show: true,
            showConfirmBtn: false,
            fullscreen: true,
            preventClose: true,
            params: { type: "BOARD", parentId: 0, workspaceId }
          },
          root
        );
        break;
      }

      default:
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteWorkspace({ commit, dispatch }, workspaceId) {
    const { status } = await deleteWorkspace(workspaceId);

    let message = "워크스페이스를 삭제하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 403:
        message = "워크스페이스 삭제에 대한 권한이 없습니다.";
        break;
      case 409:
        message = "워크스페이스에 팔로워가 없어야 삭제할 수 있습니다.";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 204:
        message = "워크스페이스를 성공적으로 삭제했습니다.";
        type = "SUCCESS";
        commit("DELETE_WORKSPACE", workspaceId);
        dispatch("todoRoute/routeTodo", null, { root: true });
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  // 이모지 수정을 안하더라도 useEmoji 는 항상 params에 포함되어야함
  async updateWorkspace({ commit, dispatch }, params) {
    const { workspaceId } = params;
    const { data, status } = await updateWorkspace(workspaceId, params);

    let message = "워크스페이스를 수정하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 403:
        message = "워크스페이스 수정에 대한 권한이 없습니다.";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 201: {
        if (data) {
          message = "워크스페이스를 성공적으로 수정했습니다.";
          type = "SUCCESS";
          commit("UPDATE_WORKSPACE", data);
        }
        break;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async delegateWorkspaceOwner(
    { commit, dispatch },
    { name, workspaceId, userId }
  ) {
    const { data, status } = await delegateWorkspaceOwner(workspaceId, userId);

    let message = `${name}님에게 워크스페이스를 위임하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 403:
        message = "워크스페이스 수정에 대한 권한이 없습니다.";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 309:
        message = `${name}님은 워크스페이스에 존재하지 않는 사용자입니다.`;
        break;
      case 201: {
        if (data) {
          message = `${name}님에게 워크스페이스를 위임하였습니다.`;
          type = "SUCCESS";
          commit("DELEGATE_WORKSPACE", { workspaceId, userId });
        }
        break;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async followWorkspace(
    { commit, dispatch, rootState },
    { workspaceId, title }
  ) {
    const { status } = await followWorkspace(workspaceId);

    let message = `${title} 워크스페이스에 팔로우하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 304:
        message = `${title} 워크스페이스는 이미 팔로우 중입니다.`;
        type = "VALIDATION";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 201:
        message = `${title} 워크스페이스를 팔로우 했습니다.`;
        type = "SUCCESS";
        commit("ADD_MY_WORKSPACE", {
          workspaceId,
          userInfo: rootState.auth.userInfo
        });
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async unfollowWorkspace(
    { commit, dispatch, rootState },
    { workspaceId, title }
  ) {
    const { status } = await unfollowWorkspace(workspaceId);

    let message = `${title} 워크스페이스 팔로우를 취소하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 409:
        message = "워크스페이스 소유자는 팔로우를 취소할 수 없습니다.";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 204:
        message = `${title} 워크스페이스 팔로우를 취소했습니다.`;
        type = "SUCCESS";
        commit("UNFOLLOW_WORKSPACE", {
          workspaceId,
          userId: rootState.auth.userInfo.id
        });
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async unfollowWorkspaceUser(
    { commit, dispatch },
    { workspaceId, userId, name }
  ) {
    const { status } = await unfollowWorkspaceUser(workspaceId, userId);

    let message = `${name}님의 팔로우를 취소하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 403:
        message = "워크스페이스 수정에 대한 권한이 없습니다.";
        break;
      case 404:
        message = "워크스페이스가 존재하지 않습니다.";
        break;
      case 204:
        message = `${name}님의 팔로우를 취소했습니다.`;
        type = "SUCCESS";
        commit("UNFOLLOW_WORKSPACE", { workspaceId, userId });
        break;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
