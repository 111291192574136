import { getGroupByType, LINE_TYPE } from "@/approval/constant/approvalLine";
import {
  getDefaultOrganList,
  getOrganListByParentId,
  getOrganUserList
} from "@/mail/api/organ.api";
import i18n from "@/_locales";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import {
  validateDuplicateApprover,
  validateDraftOrgan,
  validateDraftUser,
  validateLastLineType,
  validateSortOrder,
  validateApproverByServer,
  mergeValidationErrors,
  resetRuleErrors,
  resetServerErrors
} from "@/approval/utils/ApprovalLineValidator";
import { getKey } from "@/approval/utils/ApprovalLineUtils";

const initialState = {
  // 편집유형: [DEFAULT, INNER_DRAFT, CHANGE_UPPER, CHANGE_SHARE, CHANGE_RECEIVE]
  selectType: "DEFAULT",
  draftUser: {},
  draftOrgan: {},
  organTreeItems: [],
  organTreeActiveItem: null,
  approvalLines: []
};

const state = { ...initialState };

const getters = {
  getNextApproveLine: ({ approvalLines }) => {
    return approvalLines.find(line => line.status === "PENDING_APPROVAL");
  },
  getErrors: ({ approvalLines }) => {
    let result = [];
    approvalLines.forEach(
      ({ errors = [] }) => (result = [...result, ...errors])
    );
    return result;
  },
  isAllValid: (_, { getErrors }) => {
    return getErrors.length === 0;
  }
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_SELECT_TYPE: (state, selectType) => {
    state.selectType = selectType;
  },
  SET_DRAFT_USER: (state, draftUser) => {
    state.draftUser = { ...draftUser };
  },
  SET_DRAFT_ORGAN: (state, draftOrgan) => {
    state.draftOrgan = { ...draftOrgan };
  },
  SET_APPROVAL_LINES: (state, payload) => {
    state.approvalLines = new ApprovalLines(payload).sortAsc().get();
  },
  RESET_RULE_ERRORS: state => {
    state.approvalLines = resetRuleErrors(state.approvalLines);
  },
  RESET_SERVER_ERRORS: state => {
    state.approvalLines = resetServerErrors(state.approvalLines);
  },
  SET_ORGAN_TREE_ACTIVE_ITEM: (state, activeItem) => {
    state.organTreeActiveItem = activeItem;
  },
  SET_ORGAN_TREE_ITEMS: (state, organTreeItems) => {
    state.organTreeItems = [...organTreeItems];
  },
  SET_ORGAN_TREE_CHILDREN_ITEMS: (state, { parentId, children }) => {
    // 재귀 호출을 통해 전체 탐색 및 children 맵핑
    const mapChildren = (id, list) => {
      return list.map(item => {
        if (item.type === "ORGAN" && item.id === parentId) {
          return { ...item, children };
        }
        if (item.children) {
          return { ...item, children: mapChildren(parentId, item.children) };
        }
        return item;
      });
    };
    state.organTreeItems = mapChildren(parentId, state.organTreeItems);
  }
};

const actions = {
  /**
   * 초기화
   * @param selectType    결재선 선택기 유형
   * @param approvalLines 결재선 목록 (수정일 경우)
   * @param draftUser     기안자
   * @param draftOrgan    기안 부서
   */
  init(
    { commit, dispatch },
    { selectType = "DEFAULT", approvalLines, draftUser, draftOrgan }
  ) {
    dispatch("loadOrganTreeRoot");
    commit("RESET");
    commit("SET_SELECT_TYPE", selectType);
    commit("SET_APPROVAL_LINES", approvalLines);
    commit("SET_DRAFT_USER", draftUser);
    commit("SET_DRAFT_ORGAN", draftOrgan);
    if (approvalLines?.length > 0) dispatch("validateByRules");
  },
  /**
   * 기안부서 변경
   * @param draftOrgan 변경할 기안부서
   */
  changeDraftOrgan({ dispatch, commit, rootGetters }, draftOrgan = {}) {
    // 사용자의 소속부서가 아닐경우 return
    const userOrgans = rootGetters["auth/getUserInfo"]?.organRelationList ?? [];
    const includeUserOrgans = userOrgans.some(
      ({ organId }) => organId === draftOrgan?.organId
    );
    if (!includeUserOrgans) return false;
    commit("SET_DRAFT_ORGAN", draftOrgan);
    dispatch("validateByRules");
    return true;
  },
  /**
   * 선택한 [사용자 | 조직]을 결재선 목록에 추가
   * @param lineType  추가할 결재선 유형
   */
  addLine({ commit, dispatch, state }, lineType) {
    if (!state.organTreeActiveItem) return;

    // 같은 그룹내 [마지막 순번]
    const lastSortOrderInGroup =
      new ApprovalLines(state.approvalLines)
        .filterByGroup(getGroupByType(lineType))
        .sortAsc()
        .getLast()?.sortOrder ?? 0;

    // 새로 추가할 결재선
    const newLine = {
      type: lineType,
      sortOrder: lastSortOrderInGroup + 1,
      approver: convertTreeItemToApprover(state.organTreeActiveItem)
    };

    // 이미 추가된 사용자를 추가한 경우
    const hasDuplicateApprover = (existLines, newLine) => {
      const existKeys = existLines.map(getKey);
      const newLineKey = getKey(newLine);
      return existKeys.includes(newLineKey);
    };
    if (hasDuplicateApprover(state.approvalLines, newLine)) {
      const message = "결재선 내 중복은 불가능합니다.";
      dispatch("snackbar/openSnackbar", { message }, { root: true });
      return;
    }

    // 기안자 본인을 추가한 경우
    if (
      newLine.approver.isUser &&
      newLine.approver.userId === state.draftUser.id
    ) {
      const message = "기안자는 결재선에 추가할 수 없습니다.";
      dispatch("snackbar/openSnackbar", { message }, { root: true });
      return;
    }

    // 기안부서를 [공람]외 다른 그룹으로 추가한 경우
    if (
      newLine?.approver?.isOrgan &&
      newLine?.approver?.organId === state.draftOrgan?.organId &&
      lineType !== LINE_TYPE.SHARE_ORGAN
    ) {
      const message = "기안부서와 동일한 부서는 추가할 수 없습니다.";
      dispatch("snackbar/openSnackbar", { message }, { root: true });
      return;
    }

    // 모든 유효성 검사 통과시 결재선 목록에 반영
    commit("SET_APPROVAL_LINES", [...state.approvalLines, newLine]);
    dispatch("validateByRules");
    return true;
  },
  /**
   * 결재선 목록에서 제거
   * @param line  제거할 결재선
   */
  removeLine({ commit, state, dispatch }, line) {
    commit(
      "SET_APPROVAL_LINES",
      state.approvalLines.filter(al => al?.key !== line?.key)
    );
    dispatch("validateByRules");
  },
  /**
   * 결재선 순서 이동
   * @param targetLine  이동할 결재선
   * @param newSortOrder    변경할 sortOrder
   */
  changeSortOrder({ commit, state, dispatch }, { targetLine, newSortOrder }) {
    if (targetLine?.sortOrder === newSortOrder) return;

    const targetGroup = getGroupByType(targetLine?.type);
    const isMoveDown = newSortOrder > targetLine?.sortOrder;
    const isMoveUp = !isMoveDown;

    const changedLines = state.approvalLines.map(line => {
      if (getGroupByType(line?.type) === targetGroup) {
        if (line.sortOrder === targetLine.sortOrder) {
          return { ...line, sortOrder: newSortOrder };
        }
        if (isMoveDown && line.sortOrder <= newSortOrder) {
          return { ...line, sortOrder: line.sortOrder - 1 };
        }
        if (isMoveUp && line.sortOrder >= newSortOrder) {
          return { ...line, sortOrder: line.sortOrder + 1 };
        }
      }
      return line;
    });

    commit("SET_APPROVAL_LINES", changedLines);
    dispatch("validateByRules");
  },
  /**
   * 결재선 규칙 검사 및 검사결과 업데이트
   * (서버 교차검증 제외한 모든 검증)
   */
  validateByRules({ commit, state, getters }) {
    // 유효성 검사 결과를 병합한 결재선 목록
    let resultLines = resetRuleErrors(state.approvalLines);

    // {resultLines}에 유효성 검사 결과 병합 (모든 error를 누적합니다.)
    const putErrors = validationResults => {
      resultLines = mergeValidationErrors(resultLines, validationResults);
    };

    // 중복된 결재자 등록 체크
    putErrors(validateDuplicateApprover(resultLines));
    // 중복된 결재선 순서 체크
    putErrors(validateSortOrder(resultLines));
    // 최종결재선 유형 체크
    putErrors(validateLastLineType(resultLines));
    // 기안자가 결재선에 포함된 경우 체크
    putErrors(validateDraftUser(resultLines, state.draftUser?.id));
    // 기안 부서를 등록한 경우 체크
    putErrors(validateDraftOrgan(resultLines, state.draftOrgan?.organId));

    commit("SET_APPROVAL_LINES", resultLines);
    return getters.isAllValid;
  },
  /**
   * 결재선의 [결재자]들이 유효한지 체크 (서버 교차 검증)
   */
  async validateByServer({ commit, state, getters }) {
    // 실제 존재하는 결재자인지 검증
    const lines = resetServerErrors(state.approvalLines);
    const validationResults = await validateApproverByServer(lines);
    const resultLines = mergeValidationErrors(lines, validationResults);

    commit("SET_APPROVAL_LINES", resultLines);
    return getters.isAllValid;
  },
  /**
   * 조직도 : ROOT 조직 목록 불러오기
   */
  async loadOrganTreeRoot({ commit, dispatch }) {
    const { status, data: organList } = await getDefaultOrganList(true);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: "조직도를 불러오는데 실패했습니다.", type: "ERROR" },
        { root: true }
      );
    }
    commit(
      "SET_ORGAN_TREE_ITEMS",
      organList.map(organ => convertOrganToTreeItem(organ))
    );
  },
  /**
   * 조직도 : 하위 노드 불러오기
   * @param parentNode  부모 노드
   */
  async loadOrganTreeChildren({ commit, dispatch }, parentNode) {
    const childOrganList = await dispatch("loadChildOrganList", parentNode.id);
    const userList = await dispatch("loadUserByOrganId", parentNode.id);
    const children = [
      ...childOrganList.map(organ => convertOrganToTreeItem(organ)),
      ...userList.map(user => convertUserToTreeItem(user, parentNode))
    ];
    commit("SET_ORGAN_TREE_CHILDREN_ITEMS", {
      parentId: parentNode.id,
      children
    });
  },
  /**
   * {parentId} 조직의 하위조직 목록 불러오기
   * @param parentId 부모 조직 아이디
   */
  async loadChildOrganList({ dispatch }, parentId) {
    // 하위 조직도 없을 경우 response body = void
    const { status, data } = await getOrganListByParentId({
      parentId,
      includeCount: true
    });
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("조직도를 불러오는데 실패했습니다."), type: "ERROR" },
        { root: true }
      );
      return [];
    }
    return [...data];
  },
  /**
   * {parentId} 조직의 사용자 목록 불러오기
   * @param parentId 부모 조직 아이디
   */
  async loadUserByOrganId({ dispatch }, parentId) {
    // 조직 내 모든 사용자 불러옴
    let page = 0;
    let last = false;
    let list = [];
    // 전체 페이지 fetch
    while (!last) {
      const { status, data } = await getOrganUserList({
        organId: parentId,
        pageSize: 100,
        page
      });
      // 실패시
      if (status !== 200) {
        dispatch(
          "snackbar/openSnackbar",
          { message: "사용자를 불러오는데 실패했습니다.", type: "ERROR" },
          { root: true }
        );
        return [];
      }
      // 성공시
      const { content: userList, last: isLastPage } = data;
      list = [...list, ...userList];
      last = isLastPage;
      page++;
    }
    return list;
  }
};

// ========== 컨버팅 함수 START ==========
function convertOrganToTreeItem(organ = {}) {
  const { id, name, email, userCount, childCount } = organ;
  return {
    key: `ORGAN_${id}`,
    type: "ORGAN",
    id,
    name,
    email,
    ...(childCount > 0 || userCount > 0 ? { children: [] } : {})
  };
}

function convertUserToTreeItem(user = {}, organ = {}) {
  const { id, accountName: name, username: email, jobTitle, jobGrade } = user;
  return {
    // 겸직을 고려하여 ${organId}를 키에 추가
    key: `USER_${id}_${organ?.id}`,
    type: "USER",
    id,
    name,
    email,
    jobTitle,
    jobGrade,
    organ: { id: organ.id, name: organ.name, email: organ.email }
  };
}

function convertTreeItemToApprover(treeItem = {}) {
  const { type, id, name, email, organ } = treeItem;
  return type === "USER"
    ? {
        key: `USER_${id}`,
        userId: id,
        userName: name,
        userEmail: email,
        organId: organ?.id,
        organName: organ?.name,
        isOrgan: false,
        isUser: true
      }
    : {
        key: `ORGAN_${id}`,
        userId: null,
        userName: null,
        userEmail: null,
        organId: id,
        organName: name,
        isOrgan: true,
        isUser: false
      };
}
// ========== 컨버팅 함수 END ==========

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
