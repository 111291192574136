<template>
  <v-snackbar
    v-model="showNotification"
    :timeout="getTimeOut"
    top
    color="#2a2a2a"
    class="mt-4"
    elevation="2"
  >
    <div @click="getClick" class="cr-content">
      <v-card
        class="mx-auto"
        max-width="350"
        min-width="350"
        light
        elevation="0"
        style="background-color:transparent;"
      >
        <v-list dense class="py-0" style="background-color:transparent;">
          <v-list-item class="px-0">
            <v-list-item-avatar rounded="true" color="black" v-if="getImage">
              <v-img contain :src="getImage"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="white--text font-weight-light text-subtitle-2 mb-1"
                >{{ getTitle }}</v-list-item-title
              >
              <v-list-item-title
                class="white--text font-weight-light text-subtitle-2 "
                >{{ getBody }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon stle="align-items:center; align-self:center;">
              <v-btn color="white" icon @click.stop="HIDE()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("notification", [
      "isShow",
      "getTimeOut",
      "getTitle",
      "getBody",
      "getImage",
      "getClick"
    ]),
    showNotification: {
      get() {
        return this.isShow;
      },
      set() {
        return this.HIDE();
      }
    }
  },
  methods: {
    ...mapMutations("notification", ["HIDE"])
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-snack__wrapper {
  border-radius: 3px;
  .v-snack__content {
    padding: 0px;
  }
  .cr-content {
    padding: 12px 16px;
  }
}
</style>
