import {
  createContactMemo,
  modifyContactMemo,
  removeContactMemo,
  fetchMemoList
} from "../../api/history.api";
import i18n from "@/_locales";
import { handleError } from "@/customer/constant/constants";

const state = {
  historyList: [],
  selectedMemo: {},
  totalPages: 0,
  page: 0,
  historyType: "ALL",
  loading: false
};

const getters = {
  getHistoryList: ({ historyList }) => historyList,
  getTotalPages: ({ totalPages }) => totalPages,
  getPage: ({ page }) => page,
  getHistoryType: ({ historyType }) => historyType
};

const mutations = {
  RESET_HISTORY_LIST: state => (state.historyList = []),
  SET_HISTORY_LIST: (state, historyList) => {
    state.historyList = [...state.historyList, ...historyList];
  },
  SET_TOTAL_PAGES: (state, totalPages) => (state.totalPages = totalPages),
  SET_PAGE: (state, page) => {
    state.page = page;
  },
  SET_HISTORY_TYPE: (state, historyType) => (state.historyType = historyType)
};

const actions = {
  /**
   * 해당 컨텐츠(그룹 or 주소록)에 맵핑 된 메모 리스트 조회
   *
   * @param {*} param0
   * @param {*} memo
   */
  async loadHistoryList(
    { commit },
    {
      groupId,
      historyType = "ALL",
      page = 0,
      pageSize = 10,
      sort = "updatedTimeMilli",
      shouldReset = false
    }
  ) {
    if (!groupId || groupId < 0) {
      commit("SET_HISTORY_LIST", []);
      return;
    }
    if (shouldReset) {
      commit("RESET_HISTORY_LIST", []);
    }

    const { status, data = {} } = await fetchMemoList({
      groupId,
      historyType,
      page,
      pageSize,
      sort
    });

    if (status === 200) {
      commit("SET_HISTORY_LIST", data.content);
      commit("SET_PAGE", data.number);
      commit("SET_TOTAL_PAGES", data.totalPages);
      commit("SET_HISTORY_TYPE", historyType);
    }
  },
  async searchHistoryList(
    _,
    { groupId, contentType, page = 0, pageSize = 10, sort = "updatedTimeMilli" }
  ) {
    if (groupId < 0) {
      return;
    }

    const { status, data = {} } = await fetchMemoList({
      groupId,
      contentType,
      page,
      pageSize,
      sort
    });

    if (status === 200) {
      return {
        historyList: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        page: data.number + 1
      };
    }
  },
  /**
   * 메모 추가
   *
   * @param {*} param0
   * @param formData
   */
  async addCustomerMemo({ dispatch }, formData) {
    const { status } = await createContactMemo(formData);

    const messages = {
      ERROR: i18n.t("customer.message.fail", { action: i18n.t("common.등록") }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("common.등록")
      })
    };

    if (status !== 201) {
      return handleError(dispatch, messages.ERROR);
    }

    await dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    await dispatch("loadHistoryList", {
      groupId: formData.get("groupId"),
      page: 0,
      shouldReset: true
    });
    return true;
  },
  /**
   * 메모 수정
   *
   * @param {*} param0
   * @param formData
   */
  async updateCustomerMemo({ state, dispatch }, formData) {
    const { status } = await modifyContactMemo(formData);

    const messages = {
      ERROR: i18n.t("customer.message.fail", { action: i18n.t("common.수정") }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("common.수정")
      })
    };

    if (status !== 200) {
      return handleError(dispatch, messages.ERROR);
    }

    await dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    await dispatch("loadHistoryList", {
      groupId: formData.get("groupId"),
      page: 0,
      historyType: state.historyType,
      shouldReset: true
    });
    return true;
  },
  /**
   * 메모 삭제
   *
   * @param {*} param0
   * @param memo
   */
  async deleteCustomerMemo({ dispatch }, memo) {
    const { status } = await removeContactMemo(memo.id);

    const messages = {
      ERROR: i18n.t("customer.message.fail", { action: i18n.t("common.삭제") }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("common.삭제")
      })
    };

    if (status !== 200) {
      return handleError(dispatch, messages.ERROR);
    }
    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    await dispatch("loadHistoryList", {
      groupId: memo.groupId,
      page: 0,
      historyType: state.historyType,
      shouldReset: true
    });
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
