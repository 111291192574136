import http from "@/commons/api/http";

const prefix = "api/board";
const postPrefix = "api/board/post";
const readPrefix = "api/board/post/read";
const replyPrefix = "api/board/post/reply";
const feelingPrefix = "api/board/post/feeling";
const filePrefix = "api/board/file";
const configPrefix = "api/board/config";

// ----------------- 게시판 APIs -------------------------

// 게시판 리스트 조회
const getBoards = () => http.get(`${prefix}/find`);

// 게시판 추가
const addBoard = params => http.post(prefix, params);

// 게시판 이름 수정
const renameBoard = params => http.patch(`${prefix}/rename`, params);

// 게시판 삭제
const deleteBoard = id => http.delete(`${prefix}/${id}`);

// 게시판 삭제 취소
const undeleteBoard = id => http.patch(`${prefix}/restore/${id}`);

// 게시판 상세 정보
const getBoardDetail = id => http.get(`${prefix}/detail/${id}`);

// 게시판 권한 조회
const findPrivileges = id => http.get(`${prefix}/privilege/${id}`);

// 게시판 정보 수정
const updateBoard = params => http.patch(prefix, params);

// 게시판 숨기기
const hideBoard = boardId => http.post(`${prefix}/hide/${boardId}`);

const cleanDeleted = () => http.delete(`${prefix}/trash/clean`);

// ----------------- 게시글 APIs -------------------------

// 게시글 리스트 조회
const getPostList = params => http.get(`${postPrefix}/find`, { params });

// 게시글 추가
const addPost = params => http.post(postPrefix, params);

// 게시글 수정
const updatePost = params => http.patch(postPrefix, params);

// 게시글 삭제
const deletePost = id => http.delete(`${postPrefix}/${id}`);

// 게시글 이동
const movePost = params => http.patch(`${postPrefix}/move`, params);

// 게시글 영구 삭제
const deletePostPermanently = postIds =>
  http.delete(`${postPrefix}/forever`, { data: postIds });

// 게시글 상세 조회
const getPostDetail = postId => http.get(`${postPrefix}/view/${postId}`);

// URL의 OG데이터 조회
const getOgData = params => http.get(`${postPrefix}/og`, { params });

// ----------------- 리플 APIs -------------------------

// 댓글 조회
const getReply = params => http.get(`${replyPrefix}/list`, { params });

// 답글 조회
const getChildReply = params =>
  http.get(`${replyPrefix}/child/list`, { params });

// 댓글 아이디로 조회
const getReplyListById = ({ replyId, pageSize }) =>
  http.get(`${replyPrefix}/list/target/${replyId}`, { params: { pageSize } });

// 댓글 등록
const addReply = (formData, config) => http.post(replyPrefix, formData, config);

// 댓글 수정
const updateReply = (formData, config) =>
  http.patch(replyPrefix, formData, config);

// 댓글 삭제
const deleteReply = param =>
  http.delete(`${replyPrefix}/${param.postId}/${param.replyId}`);

// 댓글 갯수 조회
const getReplyCount = postId => http.get(`${replyPrefix}/count/${postId}`);

// ----------------- 감정표현 APIs -------------------------

// 감정표현 목록 조회
const getFeeling = postId => http.get(`${feelingPrefix}/list/${postId}`);

// 감정표현 등록 & 수정
const saveFeeling = ({ postId, feelType }) => {
  const formData = new FormData();
  formData.append("postId", postId);
  formData.append("feelType", feelType);

  return http.post(feelingPrefix, formData);
};

// 감정표현 삭제
const deleteFeeling = postId => http.delete(`${feelingPrefix}/${postId}`);

// ----------------- 감정표현 APIs -------------------------

// 게시글 읽기
const readPost = postId => http.patch(`${readPrefix}/${postId}`);

// 게시글 읽음 목록 조회
const getRead = postId => http.get(`${readPrefix}/${postId}`);

// 게시글 안 읽은 사용자 목록 조회
const getUnRead = postId => http.get(`${readPrefix}/unread/${postId}`);

// ----------------- 첨부파일 APIs -------------------------

// 파일 업로드
const uploadFile = (file, uploadType, config) => {
  const formData = new FormData();
  formData.append("uploadType", uploadType);
  formData.append("part", file);

  return http.post(`${filePrefix}/upload`, formData, config);
};

// 파일 목록 조회
const getFileList = params => {
  return http.get(`${filePrefix}/find`, { params });
};

// ----------------- 공지사항 APIs -------------------------

// 공지사항 여부 수정
const updateIsNotice = params => http.patch(`${postPrefix}/notice`, params);

// ----------------- 환경설정 APIs -------------------------

// 게시판 설정 조회
const getBoardConfig = () => http.get(`${configPrefix}`);

// 숨김 게시판 리스트 조회
const findHideBoard = () => http.get(`${prefix}/list/hide/`);

// 게시판 숨기기 취소
const hideCancelBoard = boardId => http.delete(`${prefix}/hide/${boardId}`);

// 태그 목록 조회
const getTagList = () => http.get(`${configPrefix}/tag/list`);

// 태그 저장
const saveTag = params => http.post(`${configPrefix}/tag`, params);

// 태그 수정
const updateTag = params => http.patch(`${configPrefix}/tag`, params);

// 태그 삭제
const deleteTag = params => http.delete(`${configPrefix}/tag`, { params });

// ----------------- 검색 APIs -------------------------

// 게시글 검색
const searchPost = params => http.get(`${postPrefix}/search`, { params });

// 댓글 검색
const searchReply = params => http.get(`${replyPrefix}/search`, { params });

// 파일 검색
const searchFile = params => http.get(`${filePrefix}/search`, { params });

export {
  getBoards,
  addBoard,
  renameBoard,
  undeleteBoard,
  deleteBoard,
  getBoardDetail,
  findPrivileges,
  updateBoard,
  hideBoard,
  cleanDeleted,
  getPostList,
  readPost,
  addPost,
  updatePost,
  deletePost,
  movePost,
  deletePostPermanently,
  getReply,
  getChildReply,
  getReplyListById,
  addReply,
  updateReply,
  deleteReply,
  getReplyCount,
  getPostDetail,
  getOgData,
  getFeeling,
  saveFeeling,
  deleteFeeling,
  getRead,
  getUnRead,
  uploadFile,
  getFileList,
  hideCancelBoard,
  findHideBoard,
  updateIsNotice,
  searchPost,
  searchReply,
  searchFile,
  getBoardConfig,
  getTagList,
  saveTag,
  updateTag,
  deleteTag
};
