import approvalDashboard from "@/approval/store/module/dashboard";
import approvalDraft from "@/approval/store/module/draft";
import approvalConfig from "@/approval/store/module/config";
import approvalFileUploader from "@/approval/store/module/fileUploader";
import approvalLineSelector from "@/approval/store/module/approvalLineSelector";
import approvalDocumentList from "@/approval/store/module/documentList";
import approvalDocumentView from "@/approval/store/module/documentView";

export default {
  approvalDashboard,
  approvalDraft,
  approvalConfig,
  approvalFileUploader,
  approvalLineSelector,
  approvalDocumentList,
  approvalDocumentView
};
