import {
  getBoardConfig,
  findHideBoard,
  hideCancelBoard,
  saveTag,
  updateTag,
  deleteTag
} from "@/board/api/board.api.js";
import i18n from "@/_locales";

const state = {
  init: false,
  viewType: "CARD",
  postPageSize: 10,
  replyPageSize: 10,
  hideBoards: [],
  tags: []
};

const getters = {
  getConfigInit: ({ init }) => init,
  getViewType: ({ viewType }) => viewType,
  getPostPageSize: ({ postPageSize }) => postPageSize,
  getReplyPageSize: ({ replyPageSize }) => replyPageSize,
  getHideBoardList: ({ hideBoards }) => hideBoards,
  getTags: ({ tags }) => tags.map(t => ({ tag: t.name, color: t.color }))
};

const mutations = {
  INIT_CONFIG_STATE: state => {
    state.init = false;
    state.viewType = "CARD";
    state.postPageSize = 10;
    state.replyPageSize = 10;
    state.tags = [];
  },
  SET_INIT: (state, init) => (state.init = init),
  SET_POST_PAGE_SIZE: (state, size) => (state.postPageSize = size),
  SET_REPLY_PAGE_SIZE: (state, size) => (state.replyPageSize = size),
  SET_VIEW_TYPE: (state, type) => (state.viewType = type),
  SET_HIDE_BOARDS: (state, boards) => (state.hideBoards = boards),
  SET_TAG: (state, tag) => {
    // 존재 하지 않다면 추가
    if (state.tags.filter(t => t.name == tag.name).length > 0) return;
    state.tags.push(tag);
  },
  SET_TAGS: (state, tags) => {
    state.tags = tags;
  },
  UPDATE_TAG(state, tag) {
    state.tags = state.tags.map(t => {
      if (t.name == tag.name) return tag;
      return t;
    });
  },
  DELETE_HIDE_BOARD: (state, boardId) => {
    const index = state.hideBoards.findIndex(b => b.id == boardId);
    if (index != -1) state.hideBoards.splice(index, 1);
  },
  DELETE_TAG: (state, tagName) => {
    state.tags = state.tags.filter(t => t.name != tagName);
  }
};

const actions = {
  // 게시판 설정 로드
  async loadBoardConfig({ dispatch, commit }) {
    const { status, data } = await getBoardConfig();

    if (status != 200 && status != 201) {
      const type = "ERROR";
      const message = "게시판 설정을 불러오는데 실패했습니다.";
      dispatch("snackbar/openSnackbar", { type, message }, { root: true });
      return;
    }

    const { tags = [], postPageSize = 10, replyPageSize = 10 } = data;
    commit("SET_TAGS", tags);
    commit("SET_POST_PAGE_SIZE", postPageSize);
    commit("SET_REPLY_PAGE_SIZE", replyPageSize);
    commit("SET_INIT", true);
  },
  // 숨김 게시판 데이터 불러오기
  async loadHideBoards({ commit, dispatch }) {
    const { data = [], status = 401 } = await findHideBoard();
    if (status != 200) {
      dispatch(
        "snackbar/openSnackbar",
        { type: "ERROR", message: i18n.t("common.7") },
        { root: true }
      );
    }

    const hideBoards = data.map(b => {
      return {
        id: b.id,
        groupId: b.groupId,
        name: b.name
      };
    });
    commit("SET_HIDE_BOARDS", hideBoards);
  },
  // 숨김 해제
  async hideCancel({ commit, dispatch }, boardId) {
    const { status = 401 } = await hideCancelBoard(boardId);

    const messages = {
      SUCCESS: i18n.t("board.13"),
      ERROR: i18n.t("common.7")
    };
    const type = status == 200 ? "SUCCESS" : "ERROR";

    if (status == 200) {
      // 해제한 게시판 리스트에서 제거
      commit("DELETE_HIDE_BOARD", boardId);

      // 게시판 트리 업데이트
      await dispatch("board/loadBoards", null, { root: true });
    }

    dispatch(
      "snackbar/openSnackbar",
      { type, message: messages[type] },
      { root: true }
    );
  },
  // 태그 저장
  async addTag({ commit, dispatch }, { tag, color }) {
    const { status, data } = await saveTag({ name: tag, color });

    if (status != 200 && status != 201) {
      const type = "ERROR";
      const message = "태그 저장에 실패했습니다.";
      dispatch("snackbar/openSnackbar", { type, message }, { root: true });
      return;
    }

    commit("SET_TAG", data);
  },
  // 태그 수정
  async updateTag({ commit, dispatch }, { tag, color }) {
    const { status, data } = await updateTag({ name: tag, color });

    if (status != 200 && status != 201) {
      const type = "ERROR";
      const message = "태그 수정에 실패했습니다.";
      dispatch("snackbar/openSnackbar", { type, message }, { root: true });
      return;
    }

    commit("UPDATE_TAG", data);
  },
  // 태그 삭제
  async deleteTag({ commit, dispatch }, { tag: tagName }) {
    const { status } = await deleteTag({ tagName });

    if (status != 200 && status != 201) {
      const type = "ERROR";
      const message = "태그 삭제에 실패했습니다.";
      dispatch("snackbar/openSnackbar", { type, message }, { root: true });
      return;
    }

    commit("DELETE_TAG", tagName);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
