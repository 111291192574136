import moment from "moment";
import i18n from "@/_locales";
import { isBlank } from "@/commons/utils/validation";
import { getGroupUserCount } from "@/commons/api/autocomplete.api";
import { sendMail } from "@/mail/api/write.api";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";

const s_actions = {
  /**
   * 파라미터 검증
   * 도메인 상태, 용량 확인, 수신자 확인, 제목, 예약발송
   *
   */
  async sendParamsValidation({ state, getters, commit, dispatch }) {
    if (
      (await dispatch("auth/checkSystem", "domain", { root: true })) ||
      (await dispatch("auth/checkSystem", "quota", { root: true }))
    ) {
      return;
    }

    try {
      const { to, cc, bcc, subject, isReserve, reserveDate } = state;

      // 예약 발송 검사
      if (isReserve && moment(reserveDate).unix() <= moment().unix()) {
        throw new Error(i18n.t("mail.100"));
      }

      // 내게쓰기가 아닐때 수신자 검사
      if (!getters.isWriteToMe) {
        // 그룹 계정 검사
        let isThrow = false;
        const rcptList = {
          to: to.map(e => ({ ...e })),
          cc: cc.map(e => ({ ...e })),
          bcc: bcc.map(e => ({ ...e }))
        };

        const isGroup =
          [...to, ...cc, ...bcc].filter(
            e =>
              e.value.indexOf("G#") ||
              e.value.indexOf("T#") ||
              e.value.indexOf("C#") ||
              e.value.indexOf("O#") ||
              e.value.indexOf("O+")
          ).length > 0;
        if (isGroup) {
          dispatch("loader/setLoaderShow", true, { root: true });
          for (const k in rcptList) {
            for (const e of rcptList[k]) {
              const { data } = await getGroupUserCount(e.value);
              e.valid = data.result > 0;
            }
            if (rcptList[k].filter(e => !e.valid).length > 0) {
              const rcpt = {};
              rcpt[k] = rcptList[k];
              commit("SET_RECIPIENT", rcpt);
              isThrow = true;
            }
          }
          dispatch("loader/setLoaderShow", false, { root: true });

          if (isThrow) throw new Error(i18n.t("mail.575"));
        }

        if (to.length <= 0) {
          throw new Error(i18n.t("mail.367"));
        }
        if (to.filter(e => !e.valid).length) {
          throw new Error(i18n.t("mail.368"));
        }
        if (cc.filter(e => !e.valid).length) {
          throw new Error(i18n.t("mail.369"));
        }
        if (bcc.filter(e => !e.valid).length) {
          throw new Error(i18n.t("mail.370"));
        }
      }

      // 제목 검사
      if (isBlank(subject)) {
        const message = i18n.t("mail.371");
        const callback = () => {
          commit("SET_SUBJECT", { subject: `(${i18n.t("mail.109")})` });
          dispatch("checkAttach", { funcStr: "sendMail" });
        };

        dispatch("confirm/confirm", { message, callback }, { root: true });
        return;
      }

      dispatch("checkAttach", { funcStr: "sendMail" });
    } catch ({ message }) {
      const params = { message, type: "ERROR" };
      dispatch("snackbar/openSnackbar", params, { root: true });
    }
  },
  /**
   * 첨부파일 확인
   *
   * funcStr -- 메일발송, 임시메일 저장
   */
  checkAttach({ rootGetters, dispatch }, { funcStr, params = {} }) {
    if (
      rootGetters["mailFile/isNotEmptyFiles"] &&
      !rootGetters["mailFile/isUploadComplete"]
    ) {
      const callback = () => dispatch(funcStr, params);
      return dispatch("mailFile/uploadFile", callback, { root: true });
    }

    dispatch(funcStr, params);
  },
  /**
   * 메일 발송
   *
   */
  async sendMail({ state, rootGetters, commit, dispatch }) {
    dispatch("loader/setLoaderShow", true, { root: true });

    const attachments = rootGetters["mailFile/attachments"];
    const { actionObj } = rootGetters["mailRoute/getRouteWriteInfo"];
    const { t: type, i: mailId } = actionObj;
    const content = getTinymce().activeEditor.getContent();
    const params = {
      ...state,
      attachments,
      reserve: state.isReserve,
      secure: state.isSecure,
      to: state.to.map(({ value }) => value),
      cc: state.cc.map(({ value }) => value),
      bcc: state.bcc.map(({ value }) => value),
      content: `<div style="font-family: 나눔고딕; line-height: 1.5;" >${content}</div>`
    };

    // 1:답장 2:전달 3:전체답장 4:임시저장 5:다시보내기 6: 내게쓰기
    switch (type) {
      case 4:
        params["draftId"] = mailId;
        break;
      case 6:
        params["composeType"] = "TOME";
        break;

      default:
        break;
    }

    const { status, data } = await sendMail(params);
    commit("SET_RESULT", { status, data });

    if (status === 200) {
      // 용량 업데이트
      await dispatch("auth/setUserQuotaInfo", true, { root: true });
    }

    dispatch("loader/setLoaderShow", false, { root: true });
  }
};

export { s_actions };
