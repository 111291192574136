import router from "@/commons/router";
import storage from "@/commons/api/storage";

const state = {
  workspaceId: 0,
  boardId: 0,
  filterId: 0,
  groupId: 0,
  itemId: 0,
  parentId: 0,
  viewType: "table"
};

const getters = {
  routeState: state => state,
  routeWorkspaceId: ({ workspaceId }) => parseInt(workspaceId),
  routeBoardId: ({ boardId }) => parseInt(boardId),
  routeFilterId: ({ filterId }) => parseInt(filterId),
  routeGroupId: ({ groupId }) => parseInt(groupId),
  routeItemId: ({ itemId }) => parseInt(itemId),
  routeParentId: ({ parentId }) => parseInt(parentId),
  viewType: ({ viewType }) => viewType,
  viewTypes: () => [
    { type: "table", title: "테이블" },
    { type: "kanban", title: "칸반보드" },
    { type: "gantt", title: "간트차트" }
  ]
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    state.workspaceId = params.workspaceId ?? 0;
    state.boardId = params.boardId ?? 0;
    state.filterId = params.filterId ?? 0;
    state.groupId = params.groupId ?? 0;
    state.itemId = params.itemId ?? 0;
    state.parentId = params.parentId ?? 0;
    state.viewType = params.viewType ?? "table";
  }
};

const actions = {
  async setParams(
    { commit, dispatch, state, rootGetters: RG },
    { routeName, workspaceId = 0, boardId = 0, actionObj = "{}" }
  ) {
    const rootCommit = (a, p) => commit(a, p, { root: true });
    const rootDispatch = (a, p) => dispatch(a, p, { root: true });
    let routeParams = {};
    workspaceId = parseInt(workspaceId);
    boardId = parseInt(boardId);

    try {
      actionObj = JSON.parse(actionObj);
      const filterId = parseInt(actionObj?.filterId) || 0;
      const groupId = parseInt(actionObj?.groupId) || 0;
      const itemId = parseInt(actionObj?.itemId) || 0;
      const parentId = parseInt(actionObj?.parentId) || 0;
      const viewType = actionObj?.viewType || "table";
      const isItemDetail = groupId > 0 && itemId > 0;

      // 워크스페이스 목록 호출
      if (routeName === "todo" || RG["todoWorkspace/isEmpty"]) {
        await rootDispatch("todoWorkspace/getWorkspaces");
      }

      // workspaceId에 해당하는 워크스페이스가 없을때 모든 워크스페이스 화면으로 보낸다.
      if (
        routeName !== "todo" &&
        !RG["todoWorkspace/existWorkspace"](workspaceId)
      ) {
        storage.setTodoWorkspaceId(null);
        setTimeout(() => dispatch("routeTodo"), 0);
        return;
      }

      // workspaceId 다를때 데이터 초기화
      if (state.workspaceId !== workspaceId) {
        await Promise.all([
          rootCommit("todoFilter/RESET_STATE"),
          rootCommit("todoHeader/RESET_STATE"),
          rootCommit("todoBoard/RESET_STATE"),
          rootCommit("todoGroup/RESET_STATE"),
          rootCommit("todoItem/RESET_STATE")
        ]);
      }

      // 워크스페이스 메인화면이거나 보드 목록 없을시 서버로부터 받아온다.
      if (routeName === "todo_workspace" || RG["todoBoard/isEmpty"]) {
        await rootDispatch("todoBoard/getBoards", workspaceId);
      }
      // 워크스페이스 메인화면 이면 팔로워 데이터 서버로부터 받아온다.
      if (routeName === "todo_workspace") {
        await rootDispatch("todoWorkspace/getWorkspaceUsers", workspaceId);
      }

      storage.setTodoWorkspaceId(workspaceId);
      routeParams = {
        workspaceId,
        boardId,
        filterId,
        groupId,
        itemId,
        parentId,
        viewType
      };

      // 아래서 부터는 board, board_filter, board_detail 만 해당
      if (!routeName.includes("todo_board")) return;

      if (
        // viewType이 gantt인데 planType이 ADVANCED가 아니라면 메인화면으로 이동
        (viewType === "gantt" && RG["auth/getPlanType"] !== "ADVANCED") ||
        // 해당 boardId store에 없으면 메인화면으로 이동
        !RG["todoBoard/findBoard"](boardId)
      ) {
        setTimeout(() => dispatch("routeWorkspace", { workspaceId }), 0);
        return;
      }

      // boardId가 다르거나 header가 없을때 서버로부터 가져온다.
      if (state.boardId !== boardId || RG["todoHeader/isEmpty"]) {
        await rootDispatch("todoBoard/enterBoard", boardId);
      }

      if (routeName !== "todo_board_action" && filterId === 0) {
        routeParams.filterId = RG["todoFilter/defaultFilterId"];
      }

      if (state.filterId !== routeParams.filterId) {
        const [savedFilter] = RG["todoFilter/findSavedFilter"](
          routeParams.filterId
        );
        rootCommit("todoFilter/SET_FILTERS", savedFilter?.filters || []);
      }

      // 상세보기 DIALOG
      rootCommit("todoDetail/RESET_DETAIL");
      rootCommit("todoDetail/SET_DETAIL", { show: isItemDetail });
      // 상세보기 정보 불러오기
      if (isItemDetail) {
        if (!RG["todoItem/subItems"][parentId]) {
          rootDispatch("todoItem/getSubItems", { groupId, parentId });
        }
        rootDispatch("todoDetail/getItemDetail", {
          itemId,
          parentId,
          groupId,
          boardId
        });
      }
    } catch (e) {
      if (e.message.includes("JSON")) {
        rootDispatch("snackbar/openSnackbar", {
          message: "서버 주소(URL)가 잘못된 오류입니다.",
          type: "ERROR"
        });
        dispatch("routeBoard", workspaceId, boardId);
      }
    } finally {
      commit("SET_ROUTE_PARAMS", routeParams);
    }
  },
  routeTodo() {
    router.push({ name: "todo" });
  },
  routeWorkspace(action, { workspaceId = 0 }) {
    router.push({ name: "todo_workspace", params: { workspaceId } });
  },
  routeBoard(action, { workspaceId = 0, boardId = 0 }) {
    router.push({ name: "todo_board", params: { workspaceId, boardId } });
  },
  // actionObj 에 들어가는 요소
  // filterId, groupId, itemId, viewType
  routeBoardAction({ dispatch }, { workspaceId = 0, boardId = 0, actionObj }) {
    try {
      router.push({
        name: "todo_board_action",
        params: {
          workspaceId,
          boardId,
          actionObj: JSON.stringify(JSON.parse(actionObj))
        }
      });
    } catch (e) {
      dispatch("routeBoard", workspaceId, boardId);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
