import Vue from "vue";
import App from "./App.vue";
import i18n from "@/_locales";
import router from "@/commons/router";
import store from "@/commons/store";
import vuetify from "@/commons/plugins/vuetify";
import IconSvg from "@/commons/views/IconSvg";
import Fragment from "@/commons/views/Fragment";
import storage from "@/commons/api/storage";
import PortalVue from "portal-vue";

import { longPress, highlight, dragAndDrop } from "@/commons/directives";

Vue.config.productionTip = false;

// to: 이동하는곳, from: 시작점
router.beforeEach(async (to, from, next) => {
  // linkViewer 일땐 아래로직 안태움
  if (to.name === "linkViewer" || to.name === "notFound") return next();

  const { state, commit, dispatch, getters } = store;
  // '/'기준 첫 번째 부분이 module 이름이므로
  const module = to.path.split("/")[1];
  const modules = [
    "home",
    "mail",
    "contact",
    "cal",
    "drive",
    "todo",
    "flow",
    "board",
    "resource",
    "customer",
    "approval"
  ];
  const includesModule = modules.includes(module);

  // to / from module 다를때 splash on
  if (includesModule && module !== from.path.split("/")[1]) {
    commit("splash/SET_SPLASH_IMAGE", module);
    dispatch("splash/setShowSplash", true);
  }

  // 토큰이 없고 로그인 화면이 아닐 때 로그인 화면으로 보낸다.
  if (!storage.getAccessToken()) {
    commit("messenger/SET_USE", false);
    if (module !== "login") {
      return router.push({ name: "login" });
    }
    return next();
  }

  // 사용자 정보 불러오기
  if (!state.auth.init) {
    await dispatch("auth/initUserInfo", { routeName: to.name });
  }

  // 모듈 사용 여부
  const usedModule = getters["auth/getUsedModule"];

  // 서비스 시작 첫 화면(사용자 서비스 설정)
  let firstModule =
    getters["auth/getFirstScreenModule"]?.toLowerCase() ?? "mail";

  if (!usedModule[firstModule]) {
    firstModule = "mail";
  }

  // 이동하려는 모듈의 사용여부 검사
  if (includesModule && !usedModule[module]) {
    await router.push({ name: "mail" });
    return;
  }

  // 토큰이 있는데 로그인 화면일 때 서비스 시작 첫 화면으로 이동
  if (module === "login") return router.push({ name: firstModule });

  // modules에 속하지 않으면 메신저 제거
  const useMessenger = !includesModule ? false : getters["auth/useMessenger"];
  /**
   * 메신저 예외 페이지
   * - 팝업 메일보기(인쇄), 팝업 메일쓰기, 원본 메일 보기, 주소록 인쇄
   */
  const exclude_path = [
    "popup_mail_view",
    "popup_mail_write",
    "popup_original_view",
    "contact_print",
    "approval_document_view_print",
    "customer_print"
  ];
  commit("messenger/SET_USE", useMessenger && !exclude_path.includes(to.name));

  // 예외 처리할 모듈들
  const xcpt = [
    "mail",
    "account",
    "login",
    "404",
    "auth",
    "link",
    "home",
    "oauth2"
  ];
  // 해당 모듈이 사용하지 않는 상태이면 서비스 시작 첫 화면으로 이동
  if (!xcpt.includes(module) && !usedModule[module]) {
    return router.push({ name: firstModule });
  }

  // 해당 모듈의 route store 있을 때 param set
  if (state[`${module}Route`]) {
    await dispatch(`${module}Route/setParams`, {
      from,
      routeName: to.name,
      ...to.params
    });
  }

  next();
});

router.afterEach(to => {
  //페이지 이름 설정
  Vue.nextTick(() => {
    let title = store.getters["auth/getCompanyInfo"]?.pageTitle || "크리니티";
    if (to.name == "account") title = "계정설정";
    if (to.name == "login") title = "Login";

    document.title = title;
  });
});

Vue.component("icon-svg", IconSvg);
Vue.component("cr-fragment", Fragment);

Vue.directive("longPress", longPress);
Vue.directive("highlight", highlight);
Vue.directive("dragAndDrop", dragAndDrop);

Vue.use(PortalVue);
const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

const asg = app.$store.getters;
window.vxg = str => asg[str];
window.vxc = app.$store.commit;
window.vxd = app.$store.dispatch;

window.vr = app.$router;
