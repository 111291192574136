<template>
  <v-row justify="space-around">
    <v-dialog
      width="600px"
      v-model="isShow"
      @keydown.esc="disagree"
      @click:outside="disagree"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6 font-weight-bold">
            {{ $t("common.공유") }}
          </span>
        </v-card-title>
        <v-card-text class="my-2">
          <div>
            <span class="subtitle-1 font-weight-bold">{{ getShareType }}</span>
            <span class="ml-10 subtitle-2"> {{ getShareItem.name }} </span>

            <!-- <v-btn
              v-if="getIsShared"
              style="float: right;"
              outlined
              small
              text
              :ripple="false"
              color="red"
              @click="cancel"
            >
              공유취소
            </v-btn> -->
          </div>
          <v-divider class="my-2"></v-divider>

          <div>
            <span
              class="subtitle-1 font-weight-bold"
              v-text="$t('common.초대멤버')"
            ></span>
          </div>
          <div class="my-2 member">
            <!-- 자동완성 -->
            <Autocomplete
              ref="autoRef"
              key="share_target"
              :selection.sync="selection"
              :listHeight="208"
              :excludeLoginUser="true"
              :directInput="false"
              :useChips="false"
              :placeholder="$t('common.48')"
              :typeList="['MEMBER']"
            />
          </div>
          <div
            class="mb-2"
            style="height: 200px; overflow: auto; border: 1px solid #dfdfdf;"
          >
            <v-list single-line dense>
              <v-list-item v-for="member in getMembers" :key="member.id">
                <v-icon class="mr-2" v-if="member.isNew" color="red">
                  mdi-alpha-n-circle
                </v-icon>
                <v-icon class="mr-2" v-else color="indigo">
                  mdi-check
                </v-icon>

                <v-list-item-content>
                  <v-list-item-title class="subtitle-1">
                    {{ member.name }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <div style="display: inline-flex;">
                    <v-select
                      class="button"
                      :style="
                        $vuetify.breakpoint.xs
                          ? 'min-width: 90px;'
                          : 'min-width: 141px;'
                      "
                      v-model="member.permission"
                      :items="getPermissionList"
                      hide-details
                      dense
                      solo
                      flat
                      @change="selectPermission(member)"
                    ></v-select>

                    <v-btn icon @click="deleteColumn(member)">
                      <v-icon small color="grey lighten-1">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <span v-if="getSendMessage" class="caption" v-html="$t('common.49')">
          </span>
        </v-card-text>
        <v-card-actions class="pb-4 px-6">
          <v-spacer></v-spacer>
          <v-btn text outlined @click="disagree">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn outlined color="accent" @click="save">
            {{ $t("common.확인") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-input
  >>> .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections
  > input {
  display: none;
}
.v-list >>> .v-list-item {
  max-height: 40px;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";
import i18n from "@/_locales";

export default {
  components: { Autocomplete },
  data: () => ({
    selection: {}
  }),
  watch: {
    selection(selection) {
      if (!selection?.value) return;
      this.plusMember(selection);
    },
    isShow(show) {
      if (show) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.autoRef.$refs.textField.focus();
          }, 0);
        });
      }
    }
  },
  computed: {
    ...mapGetters("share", [
      "isShow",
      "getIsShared",
      "getMembers",
      "getShareType",
      "getPermissionList",
      "getShareItem",
      "getSendMessage",
      "getDeleteList"
    ])
  },
  methods: {
    ...mapActions("share", [
      "disagree",
      "deleteMember",
      "addMember",
      "changePermission",
      "addShareItems",
      "updateShareItems",
      "deleteShare"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),

    // 멤버 추가
    plusMember(item) {
      // 중복확인
      const memberCheck = this.getMembers.filter(
        ({ memberId }) => memberId == item.userId
      );
      const { length: validation } = memberCheck;

      if (validation > 0) {
        this.openSnackbar({
          message: i18n.t("common.50"),
          type: "VALIDATION"
        });
        return;
      }

      this.addMember(item);
    },

    // 권한 수정
    selectPermission(member) {
      this.changePermission({
        index: member.index,
        permission: member.permission
      });
    },

    // 멤버 삭제
    deleteColumn(member) {
      this.deleteMember(member.index);
    },

    /**
     * 해당 아이템이 공유되었던 아이템인 경우 공유정보 수정
     * 그렇지 않은경우 공유 추가 로직 실행
     */
    // 공유정보 저장
    async save() {
      // 공유추가
      if (!this.getIsShared) {
        // 추가멤버 유효성 체크
        if (this.getMembers.length == 0) {
          this.openSnackbar({
            message: i18n.t("common.51"),
            type: "VALIDATION"
          });
          return;
        }
        await this.addShareItems();
      } else {
        // 공유 정보 수정
        // 1. 새롭게 추가된 멤버리스트
        const addList = this.getMembers.filter(({ isNew }) => isNew == true);
        // 2. 수정될 멤버 리스트
        const updateList = this.getMembers.filter(
          ({ isUpdated }) => isUpdated == true
        );
        // 3. 삭제될 멤버 리스트
        const deleteList = this.getDeleteList;

        // 변경사항이 없다면 다이얼로그 닫기
        if (!(addList.length + updateList.length + deleteList.length)) {
          this.disagree();
          return;
        }

        await this.updateShareItems({
          addList,
          updateList,
          deleteList
        });
      }
      this.disagree();
    }
  }
};
</script>
