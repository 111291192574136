import http from "@/commons/api/http";

const prefix = "api/contact/relation";

/**
 * 연락처 그룹 관계 수정
 *
 * @param {*} params
 * @returns
 */
const updateContactRelation = ({ cardIdList, contactGroupIdList }) => {
  const formData = new FormData();
  cardIdList.forEach(cardId => {
    formData.append("cardIdList", cardId);
  });
  contactGroupIdList.forEach(contactGroupId => {
    formData.append("contactGroupIdList", contactGroupId);
  });

  return http.put(prefix, formData);
};

export { updateContactRelation };
