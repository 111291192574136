<template>
  <div>
    <v-list expand dense>
      <!-- 캘린더 그룹 -->
      <v-list-group
        v-for="item in getNavigationCalendarGroups"
        :key="item.tutorialId"
        :value="item.tutorialId"
        :class="`cr-tutorial-calendar-` + item.tutorialId"
        color="grey darken-2"
      >
        <template v-slot:activator>
          <v-hover>
            <template #default="{ hover }">
              <v-row align="center" no-gutters>
                <v-col cols="10">
                  <v-list-item-content>
                    <v-list-item-title class="pl-2 grey--text text--darken-3">
                      <div class="d-flex">
                        {{ item.title }}

                        <v-tooltip v-if="hover && item.type === 'GOOGLE'" top>
                          <template #activator="{ on }">
                            <v-icon v-on="on" small class="ml-2">
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>{{ $t("calendar.구글_캘린더.도움말") }}</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-col>
                <!-- 내 캘린더 -->
                <v-col cols="2" v-if="item.type === 'MY'" class="text-center">
                  <v-btn small icon @click.stop="showCreateDialog = true">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- 구독 캘린더 -->
                <v-col cols="2" v-if="item.type === 'SUBS'" class="text-center">
                  <v-btn small icon @click.stop="showPublicDialog = true">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- 부서 캘린더 -->
                <v-col
                  cols="2"
                  v-if="item.type === 'ORGAN'"
                  class="text-center"
                >
                  <v-btn small icon>
                    <v-icon @click.stop="showOrganDialog = true">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <!-- 구글 캘린더 -->
                <v-col
                  cols="2"
                  v-if="item.type === 'GOOGLE'"
                  class="text-center"
                >
                  <v-icon small>
                    mdi-connection
                  </v-icon>
                </v-col>
              </v-row>
            </template>
          </v-hover>
        </template>
        <div v-if="item.type !== 'GOOGLE'">
          <v-hover
            v-for="child in item.children"
            :key="child.id"
            v-slot:default="{ hover }"
          >
            <v-list-item link class="pr-2" :ripple="false">
              <v-list-item-action>
                <v-checkbox
                  :input-value="child.selected"
                  :color="child.color"
                  dense
                  @click="checkNode(child)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="font-weight-regular">
                  <span>{{ child.title }}</span>
                  <v-icon class="ml-2" small v-if="isOrganCalendar(child)"
                    >mdi-domain</v-icon
                  >
                </v-list-item-title>
              </v-list-item-content>
              <CalendarEditMenu :showButton="hover" :calendar="child" />
            </v-list-item>
          </v-hover>
        </div>
        <!-- 구글 캘린더 계정 별 그룹 -->
        <div v-else>
          <v-list-group
            v-for="connection in connections"
            :key="connection.id"
            :value="true"
            color="grey darken-2"
          >
            <template #activator>
              <v-row align="center" no-gutters>
                <v-col cols="9" class="d-flex align-center pl-2">
                  <GoogleLogo size="16" />
                  <v-list-item-title class="ml-1 grey--text text--darken-3">
                    {{ getIdFromEmail(connection.email) }}
                  </v-list-item-title>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-tooltip v-if="connection.errorMessage" top color="error">
                    <template #activator="{on, attrs}">
                      <v-icon small color="error" v-bind="attrs" v-on="on"
                        >mdi-sync-alert</v-icon
                      >
                    </template>
                    <span>{{ connection.errorMessage }}</span>
                  </v-tooltip>
                  <v-progress-circular
                    v-else-if="!connection.loaded"
                    size="16"
                    width="2"
                    indeterminate
                    color="grey"
                  ></v-progress-circular>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-menu offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item @click="syncExternalData">
                        <v-list-item-title>
                          {{ $t("calendar.구글_캘린더.재동기화") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="() => openDisconnectDialog(connection)"
                      >
                        <v-list-item-title>
                          {{ $t("login.연결_해제") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
            <template v-for="calendar in connection.calendars">
              <v-list-item :key="calendar.id" link :ripple="false">
                <v-list-item-action>
                  <v-checkbox
                    dense
                    :color="calendar.color"
                    @click="
                      () =>
                        updateExternalCalendarSelection({
                          calendar,
                          value: !calendar.selected
                        })
                    "
                    :input-value="calendar.selected"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-regular">
                    {{ calendar.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </div>
      </v-list-group>
      <v-list-item
        v-if="connections.length === 0"
        link
        @click="openGoogleCalendarConnectionWindow"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            <GoogleLogo size="18" />
            <span class="ml-2">
              {{ $t("calendar.구글_캘린더.연동") }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="mr-0">
          <v-icon small color="grey lighten-1">
            mdi-connection
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <CreateCalendarDialog v-if="showCreateDialog" v-model="showCreateDialog" />

    <PublicCalendarDialog
      v-if="showPublicDialog"
      v-model="showPublicDialog"
      persistent
      max-width="200px"
    />

    <OrganCalendarDialog
      v-if="showOrganDialog"
      :show="showOrganDialog"
      persistent
      max-width="200px"
      @closeSearchDialog="showOrganDialog = false"
    />

    <ExternalOAuth2DisconnectDialog
      v-if="oauth2DisconnectDialog"
      v-model="oauth2DisconnectDialog"
      :connection="selectedConnection"
      @disconnected="syncExternalData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PublicCalendarDialog from "./PublicCalendarDialog.vue";
import OrganCalendarDialog from "./OrganCalendarDialog.vue";
import {
  EXTERNAL_SERVICE,
  openExternalOAuth2Window
} from "@/commons/api/external/external.api";
import { isOrganCalendar } from "@/calendar/utils/CalendarUtils";
import GoogleLogo from "@/commons/views/icons/GoogleLogo.vue";
import ExternalOAuth2DisconnectDialog from "@/commons/views/ExternalOAuth2DisconnectDialog.vue";
import CalendarEditMenu from "@/calendar/components/calendar/CalendarEditMenu.vue";
import CreateCalendarDialog from "@/calendar/components/calendar/CreateCalendarDialog.vue";

export default {
  components: {
    CreateCalendarDialog,
    CalendarEditMenu,
    ExternalOAuth2DisconnectDialog,
    GoogleLogo,
    OrganCalendarDialog,
    PublicCalendarDialog
  },
  data: () => ({
    showCreateDialog: false,
    showPublicDialog: false,
    showOrganDialog: false,
    selectedConnection: null,
    oauth2DisconnectDialog: false
  }),
  computed: {
    ...mapState("extCal", ["connections"]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("cal", [
      "getMyCalendars",
      "getSubscribedCalendars",
      "getOrganCalendars"
    ]),
    getNavigationCalendarGroups() {
      const groups = [
        {
          tutorialId: 1,
          type: "MY",
          title: this.$t("calendar.내_캘린더"),
          children: this.getMyCalendars
        },
        {
          tutorialId: 2,
          type: "SUBS",
          title: this.$t("calendar.공용_캘린더"),
          children: this.getSubscribedCalendars
        },
        {
          tutorialId: 3,
          type: "ORGAN",
          title: this.$t("calendar.조직_캘린더"),
          children: this.getOrganCalendars
        },
        {
          tutorialId: 4,
          type: "GOOGLE",
          title: this.$t("calendar.구글_캘린더.타이틀")
        }
      ];

      return groups.filter(group => {
        // 조직도 사용 제한 시 부서 캘린더 그룹 표시 안함
        if (this.getUserInfo?.isOrgChartRestricted && group.type === "ORGAN") {
          return false;
        }

        // 구글 캘린더
        if (group.type === "GOOGLE") {
          // 연결된 계정이 없으면 표시 안함( 대신 연결 버튼 표시 )
          if (this.connections.length === 0) {
            return false;
          }
        }

        return true;
      });
    }
  },
  methods: {
    isOrganCalendar,
    ...mapActions("cal", ["updateTreeCalendarStatus"]),
    ...mapActions("extCal", [
      "syncExternalData",
      "updateExternalCalendarSelection"
    ]),
    checkNode(item) {
      this.updateTreeCalendarStatus({
        calendarId: item.id,
        status: !item.selected
      });
    },
    openGoogleCalendarConnectionWindow() {
      openExternalOAuth2Window(EXTERNAL_SERVICE.googleCalendar.registrationId);
    },
    openDisconnectDialog(connection) {
      this.selectedConnection = connection;
      this.oauth2DisconnectDialog = true;
    },
    getIdFromEmail(email) {
      return email.split("@")[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  .v-list-item {
    min-height: 36px;
    padding: 0 20px;
    .v-list-item__action {
      margin: 0 12px 0 0;
    }
  }

  ::v-deep .v-list-group__header__append-icon {
    margin-left: 0 !important;
    min-width: 24px !important;
  }
}
</style>
