<template>
  <div class="messenger-iframe-wrapper">
    <iframe
      ref="iframe"
      class="frame"
      src="about:blank"
      style="width:100%; height:100%; border:0px;"
      @load="login"
      frameborder="0"
    />
  </div>
</template>

<style lang="scss" scoped>
/* iframe */
.messenger-iframe-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  mounted() {
    this.initMessenger();
  },
  destroyed() {
    window.removeEventListener("message", this.messageEventListener);
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("messenger", ["minimize", "openRoomId", "tenantId"])
  },
  methods: {
    ...mapMutations("messenger", [
      "SET_UNREAD",
      "SET_MINIMIZE",
      "SET_OPEN_ROOM_ID",
      "SET_IFRAME_ELEMENT"
    ]),
    ...mapActions("messenger", ["init", "login"]),
    ...mapActions("notification", ["showNotification", "hideNotification"]),
    /**
     * 메신저 초기화
     * - 인증 시도
     */
    async initMessenger() {
      // 메시지 수신
      window.addEventListener("message", this.messageEventListener);

      // 초기화
      this.init(this.$refs.iframe);
    },
    messageEventListener({ data: { data, eventName } }) {
      // 안읽은 메시지 갯수
      if (eventName == "unread-changed") {
        this.SET_UNREAD(data ?? 0);
        return;
      }

      // 메시지 알림
      if (eventName == "notification") {
        this.pushMessage(data.notification);
        return;
      }

      // 대화방 오픈
      if (eventName == "room-opened") {
        if (this.openRoomId == data._id) {
          this.hideNotification();
        }
        this.SET_OPEN_ROOM_ID(data._id);
      }

      // 대화방 나가기
      if (eventName == "home") {
        this.SET_OPEN_ROOM_ID("home");
      }
    },
    /**
     * 메시지 알림
     */
    pushMessage({ title, text, payload }) {
      const {
        rid,
        type,
        name,
        sender: { username: avatar }
      } = payload;

      /**
       * 이미 열린 대화방일 경우
       * 알림을 보내지 않는다
       */
      if (!this.minimize && this.openRoomId == rid) return;

      /**
       * 푸시 정보 생성
       * - routingPath, avatar
       */
      const _roomType =
        type == "p" ? "group" : type == "c" ? "channel" : "direct";
      const _path = type == "p" ? name : rid;
      const path = `/${_roomType}/${_path}`;
      const img = `https://chat-${this.tenantId}.sirteam.net/avatar/${avatar}`;

      this.showNotification({
        title: title,
        body: text,
        img: img,
        click: () => {
          window.focus();
          const { contentWindow } = this.$refs.iframe;
          contentWindow.postMessage({ externalCommand: "go", path }, "*");
          this.SET_MINIMIZE(false);
          this.hideNotification();
        }
      });
    }
  }
};
</script>
