import i18n from "@/_locales";
import {
  getConfig,
  updateConfig,
  updateFirstScreen,
  updatePageSize,
  updateConfigHideColumns
} from "../../api/config.api";

const state = {
  initConfig: false,
  firstPage: null,
  sort: null,
  dir: null,
  pageSize: null,
  blockSize: null,
  useSelectBox: null,
  currentConfig: {
    firstPage: null,
    sort: null,
    pageSize: null
  },
  hideColumns: {}
};

const getters = {
  getFirstPage: ({ firstPage }) => firstPage,
  getSort: ({ sort }) => sort,
  getPageSize: ({ pageSize }) => pageSize,
  getDir: ({ dir }) => dir,
  getHideColumns: ({ hideColumns }) => hideColumns,
  getCurrentConfig: ({ currentConfig }) => currentConfig,
  getBlockSize: ({ blockSize }) => blockSize,
  isUseSelectBox: ({ useSelectBox }) => useSelectBox,
  initConfig: ({ initConfig }) => initConfig
};

const mutations = {
  SET_CONFIG: (
    state,
    { firstPage, sort, pageSize, blockSize, useSelectBox, hideColumns }
  ) => {
    state.firstPage = firstPage || state.firstPage;
    if (sort) {
      state.sort = sort.split("/")[0];
      state.dir = sort.split("/")[1];
    }
    state.pageSize = pageSize || state.pageSize;
    state.blockSize = blockSize || state.blockSize;
    state.hideColumns = hideColumns || state.hideColumns;
    state.useSelectBox = useSelectBox || state.useSelectBox;

    state.currentConfig.firstPage = state.firstPage;
    state.currentConfig.sort = state.sort;
    state.currentConfig.pageSize = state.pageSize;

    state.initConfig = true;
  },
  SET_CURRENT_CONFIG: (state, { firstPage, pageSize }) => {
    state.currentConfig = {
      firstPage,
      pageSize
    };
  },
  SET_FIRST_PAGE: (state, firstPage) => {
    state.firstPage = firstPage;
  },
  SET_PAGE_SIZE: (state, pageSize) => {
    state.pageSize = pageSize;
  },
  SET_CURRENT_FIRST_PAGE: (state, firstPage) => {
    state.currentConfig.firstPage = firstPage;
  },
  SET_CURRENT_PAGE_SIZE: (state, pageSize) => {
    state.currentConfig.pageSize = pageSize;
  }
};

const actions = {
  /**
   * 주소록 설정 조회
   *
   * @param {*} param0
   */
  async getConfig({ commit, dispatch }) {
    try {
      const { status, data = {} } = await getConfig();
      if (status === 200) {
        const { config } = data;
        commit("SET_CONFIG", config || {});
        dispatch("card/updateHeader", config.hideColumns, { root: true });
      }
    } catch (e) {
      // console.log("getConfig e = ", e);
    }
  },
  /**
   * 주소록 설정 수정
   *
   * @param {*} param0
   */
  async updateConfig({ commit, getters, dispatch }) {
    const { getFirstPage: firstPage, getPageSize: pageSize } = getters;
    try {
      const { status } = await updateConfig({
        firstPage,
        pageSize
      });

      let type = "ERROR";
      if (status === 200) {
        commit("SET_CURRENT_CONFIG", { firstPage, pageSize });
        type = "SUCCESS";
      }

      const messages = {
        ERROR: i18n.t("contact.61"),
        SUCCESS: i18n.t("contact.62")
      };
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("updateConfig e = ", e);
    }
  },
  /**
   * 주소록 숨김 컬럼 수정
   *
   * @param {*} param0
   * @param {*} param1
   */
  async updateConfigHideColumns({ dispatch }, { index, header }) {
    try {
      await dispatch(
        "card/setUseHeader",
        { index, use: !header.use },
        { root: true }
      );
      const { status } = await updateConfigHideColumns({
        columnName: header.value,
        hide: header.use
      });

      const messages = {
        ERROR: i18n.t("contact.63"),
        SUCCESS: i18n.t("contact.64")
      };
      const type = status === 200 ? "SUCCESS" : "ERROR";
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );
    } catch (e) {
      // console.log("updateConfigHideColumns e = ", e);
    }
  },

  /**
   * 주소록 첫 화면 수정
   * @param {*} param0
   */
  async updateFirstPage({ state, getters, commit, dispatch }) {
    // 변경에 실패했을 때를 대비하여 기존 firstPage 저장
    const prevFirstPage = getters.getCurrentConfig.firstPage;
    commit("SET_CURRENT_FIRST_PAGE", state.firstPage);

    const { status } = await updateFirstScreen(state.firstPage);
    if (status != 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("contact.61"), type: "ERROR" },
        { root: true }
      );
      commit("SET_FIRST_PAGE", prevFirstPage);
      commit("SET_CURRENT_FIRST_PAGE", prevFirstPage);
    }
  },
  /**
   * 주소록 목록 개수 수정
   * @param {*} param0
   */
  async updatePageSize({ getters, state, commit, dispatch }) {
    // 변경에 실패했을 때를 대비하여 기존 pageSize 저장
    const prevPageSize = getters.getCurrentConfig.pageSize;
    commit("SET_CURRENT_PAGE_SIZE", state.pageSize);

    const { status } = await updatePageSize(state.pageSize);
    if (status != 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("contact.61"), type: "ERROR" },
        { root: true }
      );
      commit("SET_PAGE_SIZE", prevPageSize);
      commit("SET_CURRENT_PAGE_SIZE", prevPageSize);
      return false;
    }
    return true;
  }
  /**
   * 주소록 정렬 수정
   * @param {*} param0
   */
  // async updateSort({ getters, commit, dispatch }, sort) {
  //   const prevSort = getters.getCurrentConfig.sort;
  //   commit("SET_SORT", sort);
  //   commit("SET_CURRENT_SORT", sort);

  //   // 변경에 실패했을 때를 대비하여 기존 pageSize 저장

  //   const { status } = await updateSort(
  //     `${sort}/${sort === "id" ? "desc" : "asc"}`
  //   );
  //   if (status != 200) {
  //     dispatch(
  //       "snackbar/openSnackbar",
  //       { message: "설정 변경에 실패했습니다.", type: "ERROR" },
  //       { root: true }
  //     );
  //     commit("SET_SORT", prevSort);
  //     commit("SET_CURRENT_SORT", prevSort);
  //   }
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
