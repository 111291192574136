import Vue from "vue";
import Vuex from "vuex";

import splash from "@/commons/store/modules/splash";
import auth from "@/commons/store/modules/auth";
import confirm from "@/commons/store/modules/confirm";
import positioningMenu from "@/commons/store/modules/positioningMenu";
import share from "@/commons/store/modules/share";
import linkShare from "@/commons/store/modules/linkShare";
import snackbar from "@/commons/store/modules/snackbar";
import dragDrop from "@/commons/store/modules/dragDrop";
import loader from "@/commons/store/modules/loader";
import selectBox from "@/commons/store/modules/selectBox";
import announce from "@/commons/store/modules/announce";
import editor from "@/commons/store/modules/editor";
import serviceConfig from "@/commons/store/modules/config";
import notification from "@/commons/store/modules/notification";
import messenger from "@/commons/store/modules/messenger";
import sidePanel from "@/commons/store/modules/sidePanel";
import tag from "@/commons/store/modules/tag";
import tutorial from "@/commons/store/modules/tutorial";
import theme from "@/commons/store/modules/theme";
import websocket from "@/commons/store/modules/websocket";
import ai from "@/commons/store/modules/ai";

import mail from "@/mail/store";
import contact from "@/contact/store";
import drive from "@/drive/store";
import calendar from "@/calendar/store";
import board from "@/board/store";
import account from "@/account/store";
import todo from "@/todo/store";
import flow from "@/flow/store";
import resource from "@/resource/store";
import approval from "@/approval/store";
import home from "@/home/store";
import customer from "@/customer/store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    splash,
    auth,
    confirm,
    positioningMenu,
    share,
    linkShare,
    snackbar,
    dragDrop,
    loader,
    selectBox,
    announce,
    editor,
    serviceConfig,
    notification,
    messenger,
    sidePanel,
    tag,
    tutorial,
    theme,
    websocket,
    ai,

    ...mail,
    ...contact,
    ...drive,
    ...calendar,
    ...board,
    ...account,
    ...todo,
    ...flow,
    ...resource,
    ...approval,
    ...home,
    ...customer
  }
});
