import Vue from "vue";
import i18n from "@/_locales";
import router from "@/commons/router";
import {
  getMailList,
  getSpamMailList,
  getSharedFolders,
  getSharingFolders,
  getMainMailList,
  getReferencesMailList,
  updateImportantStatus,
  updateReadStatus,
  updateFolderId,
  updateAllFolderId,
  searchMail,
  updateDeleteStatus,
  cleanTrash,
  updateAllRead,
  updateTags,
  deleteMailReserve,
  reportMail,
  unblockMail,
  unsubscribeSender,
  allowSender,
  getBlockDetail,
  recoveryMail
} from "../../api/mail.api";
import { deleteDraftMail } from "../../api/write.api";
import { quickMenuEnum, shareFolderType } from "@/mail/constant/folderType";
import { getDateWithTime, getDateWithDayOfWeek } from "@/commons/utils/moment";
import { byteCalculation } from "@/commons/utils/byteCalculation";

const root = { root: true };
const state = {
  mails: [],
  selectedMails: [],
  expanded: [],
  folderId: -1,
  page: 1,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0,
  loading: true
};

const getters = {
  getMails: ({ mails }) => mails,
  getSelectedMails: ({ selectedMails }) => selectedMails,
  getExpanded: ({ expanded }) => expanded,
  getPage: ({ page }) => page,
  getTotalPages: ({ totalPages }) => totalPages,
  getTotalElements: ({ totalElements }) => totalElements,
  getLoading: ({ loading }) => loading
};

const mutations = {
  SET_RELOAD_INFO: (state, { folderId, pageSize }) => {
    state.folderId = folderId;
    state.pageSize = pageSize;
  },
  INIT_PAGE: state => {
    state.loading = true;
    state.mails = [];
    state.page = 1;
    state.totalElements = 0;
    state.totalPages = 0;
  },
  SET_PAGE: (state, { mails, page, totalPages, totalElements }) => {
    state.mails = mails;
    state.totalElements = totalElements;
    state.page = page;
    state.totalPages = totalPages;
    state.loading = false;

    // selectedMail reference 갱신
    const selectedMails = [];
    state.selectedMails.forEach(m => {
      if (!m.dataId) return;

      const [mail] = state.mails.filter(m2 => m2.dataId == m.dataId);
      if (mail) selectedMails.push(mail);
      else if (m.dataId?.includes("_ref_")) selectedMails.push(m);
    });
    state.selectedMails = selectedMails;
  },
  SET_SELECTED_MAILS: (state, selectedMails) => {
    state.selectedMails = selectedMails;
  },
  SET_EXPANDED: (state, expanded) => {
    state.expanded = expanded;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_IMPORTANT_STATUS: (state, { mails = [], rollback = false }) => {
    mails.forEach(({ mailId, nextImportantStatus, prevImportantStatus }) => {
      const status = rollback ? prevImportantStatus : nextImportantStatus;
      state.mails.forEach(m => {
        if (m.mailId == mailId) m.importantStatus = status;
        if (!m.exchange) return;

        m.exchange?.forEach(exMail => {
          if (exMail.mailId !== mailId) return;
          exMail.importantStatus = status;
        });
      });
    });
  },
  SET_READ_STATUS: (state, { mails = [], rollback = false }) => {
    mails.forEach(({ mailId, nextReadStatus, prevReadStatus }) => {
      const status = rollback ? prevReadStatus : nextReadStatus;
      state.mails.forEach(mail => {
        if (mail.mailId == mailId) mail.readStatus = status;
        if (!mail.exchange) return;

        mail.exchange?.forEach(exMail => {
          if (exMail.mailId !== mailId) return;
          exMail.readStatus = rollback ? prevReadStatus : nextReadStatus;
        });
      });
    });
  },
  SET_ALL_READ: (state, folderId) => {
    state.mails.forEach(m => {
      if (folderId > 0 && m.folderId !== folderId) return;
      m.readStatus = 1;
    });
  },
  SET_EXCHANGE_MAIL: (state, { mailId, exchangeMails }) => {
    const idx = state.mails.findIndex(mail => mail.mailId == mailId);
    if (idx === -1) return;

    Vue.set(state.mails, idx, { ...state.mails[idx], exchange: exchangeMails });
    // 선택된메일 reference갱신
    const selectedIdx = state.selectedMails.findIndex(m => m.mailId == mailId);
    if (selectedIdx != -1) {
      Vue.set(state.selectedMails, selectedIdx, state.mails[idx]);
    }

    // 선택한 메일중 주고받은 메일과 동일한 메일이 존재시 selectedMails 에 넣어준다. (메일선택)
    state.selectedMails.forEach(m => {
      exchangeMails.forEach(eM => {
        if (m.mailId !== eM.mailId) return;

        const index = state.selectedMails.findIndex(m => m.dataId == eM.dataId);
        if (index == -1) state.selectedMails.push(eM);
        else Vue.set(state.selectedMails, index, eM);
      });
    });
  },
  SET_RECEIPT: (state, receipt) => {
    const index = state.mails.findIndex(mail => mail.mailId == receipt.mailId);
    if (index === -1) return;

    Vue.set(state.mails, index, { ...state.mails[index], receipt });
  },
  SET_MAIL_TYPE: (state, { mailId, mailType }) => {
    const index = state.mails.findIndex(mail => mail.mailId == mailId);
    if (index === -1) return;

    state.mails[index].mailType = mailType;
  },
  SET_MAIL_LIST_TAGS: (state, { mailId, tags }) => {
    state.mails.forEach(m => {
      // 주고받은 메일에도 set
      m?.exchange?.forEach(exM => {
        if (exM.mailId !== mailId) return;
        Vue.set(exM, "tags", tags);
      });

      if (m.mailId !== mailId) return;
      Vue.set(m, "tags", tags);
    });
  }
};

// 메일 데이터 데이터테이블에 맞게 변형
const convertMailData = mails =>
  mails.map(mail => ({
    ...mail,
    dataId: `${mail.mailId}`,
    group: getDateWithDayOfWeek(mail.receivedTimeMillis),
    receivedTimeMillis: getDateWithTime(mail.receivedTimeMillis),
    emlSize: byteCalculation(mail.emlSize),
    exchange: []
  }));

const actions = {
  // page 서버에서는 0부터, client에서는 1부터 commit,
  async getList({ commit, dispatch, rootState, rootGetters }, params) {
    const { folderId, page, pageSize: PS, actionObj = "{}" } = params;
    commit("SET_LOADING", true);

    // 해당 폴더가 존재하는지 검사
    const folder = rootGetters["folder/getFolder"](folderId);
    if (!folder) return router.push({ name: "mail" });

    const {
      sfi,
      owi,
      sort = "receivedTimeMillis",
      dir = "desc",
      readStatuses = [],
      importantStatuses = [],
      includesAttach = -1,
      search = {}
    } = JSON.parse(actionObj);
    const { folderType, shareFolders } = folder;
    const pageSize = PS || rootState.mailConfig.pageSize;
    params = { folderId, page: page - 1, pageSize, dir, sort };
    commit("SET_RELOAD_INFO", { folderId, pageSize });

    // 검색이거나 검색폴더일때
    if (!!search?.type || folderType === "SEARCH") {
      const id = search?.tags && isNaN(parseInt(folderId)) ? -1 : folderId;
      return dispatch("searchMail", { ...params, folderId: id, ...search });
    }

    // 주요 (주고받은, 내부, 주소록)
    if (
      folderId == quickMenuEnum.EXCHANGED ||
      folderId == quickMenuEnum.INTERNAL ||
      folderId == quickMenuEnum.CONTACT
    ) {
      return dispatch("getMainMailList", params);
    }

    // 스팸메일함
    if (folderType === "SPAM") {
      return dispatch("getSpamMailList", params);
    }

    // 공유받은 / 한
    let isShare = false;
    if (shareFolderType[folderType]) {
      let loadSharedFolders = true;
      if (parseInt(sfi) && shareFolders) {
        const [sFolder] = shareFolders?.filter(f => f.id == sfi) || [];
        loadSharedFolders = !sFolder;
      }

      isShare = !!parseInt(sfi) && !!parseInt(owi);
      if (loadSharedFolders) dispatch(`get${folderType}`, { isShare, params });
      if (!isShare) return;
    }

    // 기본 / 공유
    await dispatch("getMailList", {
      isShare,
      params: {
        ...params,
        ...(isShare ? { folderId: parseInt(sfi), ownerId: parseInt(owi) } : {}),
        includesAttach,
        readStatuses: readStatuses.join(","),
        importantStatuses: importantStatuses.join(",")
      }
    });
  },
  // 메일검색
  async searchMail({ commit, dispatch }, params) {
    const { timeRange: pTimeRange, sizeRange: pSizeRange } = params;

    let timeRange = null;
    if (Array.isArray(pTimeRange) && pTimeRange.length > 0) {
      let [start, end] = pTimeRange;
      if (start && end) {
        start = new Date(`${start} 00:00:00`).getTime();
        end = new Date(`${end} 23:59:59`).getTime();
        timeRange = [start, end].join(",");
      }
    }

    let sizeRange = null;
    if (Array.isArray(pSizeRange) && pSizeRange.length > 0) {
      sizeRange = pSizeRange.join(",");
    }

    params = { ...params, timeRange, sizeRange };
    const { status, data } = await searchMail(params);
    if (status !== 200) {
      const message = "메일 검색에 실패했습니다.";
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { content = [], number, totalPages, totalElements = 0 } = data;
    commit("SET_PAGE", {
      mails: convertMailData(content),
      page: number + 1,
      totalPages,
      totalElements
    });
  },
  // 주요메일목록 조회
  async getMainMailList({ state, commit, dispatch }, params) {
    switch (params.folderId) {
      case quickMenuEnum.EXCHANGED:
        params["exchangedMail"] = 1;
        break;
      case quickMenuEnum.INTERNAL:
        params["internalMail"] = 1;
        break;
      case quickMenuEnum.CONTACT:
        params["contactMail"] = 1;
        break;
      default:
        break;
    }
    const { status, data } = await getMainMailList(params);
    if (status !== 200) {
      const message = i18n.t("mail.395");
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { content = [], number, totalPages, totalElements = 0 } = data;
    commit("SET_PAGE", {
      mails: convertMailData(content),
      page: number + 1,
      totalPages,
      totalElements
    });

    // expanded된 메일이있으면 주고받은메일 호출
    state.expanded?.forEach(m => dispatch("getReferencesMailList", m.mailId));
  },
  // 공유받은 메일함 목록
  async getSHARED_ROOT({ commit, dispatch }, { isShare, params }) {
    const { data, status } = await getSharedFolders(params);
    if (status !== 200) {
      const message = i18n.t("mail.396");
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { content = [], number, totalPages, totalElements } = data;
    commit("folder/SET_SHARE_FOLDERS", content, root);
    if (isShare) return;

    commit("SET_PAGE", {
      mails: content.map(f => ({ ...f, dataId: `${f.id}` })),
      page: number + 1,
      totalPages,
      totalElements
    });
  },
  // 공유한 목록
  async getSHARING_ROOT({ commit, dispatch }, { params }) {
    const { data, status } = await getSharingFolders(params);
    if (status !== 200) {
      const message = i18n.t("mail.397");
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { content = [], number, totalPages, totalElements } = data;
    commit("SET_PAGE", {
      mails: content.map(f => ({ ...f, dataId: `${f.id}` })),
      page: number + 1,
      totalPages,
      totalElements
    });
  },
  // 기본/공유 메일 목록 호출
  async getMailList({ state, commit, dispatch }, { isShare, params }) {
    const { data = [], status } = await getMailList(isShare, params);
    if (status !== 200) {
      const message = i18n.t("mail.395");
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { content = [], folder, number, totalPages, totalElements } = data;
    commit("SET_PAGE", {
      mails: convertMailData(content),
      page: number + 1,
      totalPages,
      totalElements
    });
    // 해당 메일함 정보 업데이트
    if (folder) commit("folder/SET_FOLDER_META", folder, root);
    // expanded된 메일이있으면 주고받은메일 호출
    state.expanded?.forEach(m => dispatch("getReferencesMailList", m.mailId));
  },
  // 스팸메일함 목록 호출
  async getSpamMailList({ commit, dispatch }, params) {
    const { data = [], status } = await getSpamMailList(params);
    if (status !== 200 || !data?.result) {
      const message = i18n.t("mail.395");
      dispatch("_snackbar", { message, type: "ERROR" });
      return commit("SET_LOADING", false);
    }

    const { totalCount: totalElements, contents } = data;
    commit("SET_PAGE", {
      totalElements,
      page: params.page + 1,
      mails: contents.map(mail => {
        let [patternTag, p2] = mail.pattern?.split(":") || [];

        return {
          ...mail,
          dataId: `${mail.id}`,
          adrFrom: { address: mail.adrFrom },
          readStatus: 0,
          importantStatus: 0,
          patternTag: patternTag.trim(),
          isUserFilter: !!patternTag && !!p2,
          emlSize: byteCalculation(mail.msgSize),
          group: getDateWithDayOfWeek(mail.insertDate),
          receivedTimeMillis: getDateWithTime(mail.insertDate)
        };
      })
    });
  },
  // 주고받은 메일 호출
  async getReferencesMailList({ commit }, mailId) {
    const { data, status } = await getReferencesMailList({ mailId });
    if (status !== 200) return;

    commit("SET_EXCHANGE_MAIL", {
      mailId,
      exchangeMails: data?.content?.map(mail => ({
        ...mail,
        dataId: `${mail.mailId}_ref_${mailId}`,
        receivedTimeMillis: getDateWithTime(mail.receivedTimeMillis),
        emlSize: byteCalculation(mail.emlSize),
        parentMailId: mailId,
        isExchange: true
      }))
    });
  },
  // 현재 목록 리로딩
  async reloadList({ state, rootState, dispatch }) {
    const { mail_list } = rootState.mailRoute;
    const { actionObj } = mail_list;
    const { folderId, page, pageSize } = state;

    dispatch("getList", { folderId, page, pageSize, actionObj });
  },

  // 메일 중요표시 수정 (Important 컴포넌트)
  async updateImportantStatus(
    { commit, dispatch },
    { mails, importantStatus: nextImportantStatus, toServer }
  ) {
    mails = mails.map(({ mailId, importantStatus }) => ({
      mailId,
      nextImportantStatus,
      prevImportantStatus: importantStatus
    }));

    // 메일 목록/보기 업데이트
    const params = { mails, rollback: false };
    const listCommit = "mail/SET_IMPORTANT_STATUS";
    const viewCommit = "mailView/SET_VIEW_IMPORTANT_STATUS";
    // 현재창, 부모창 데이터 업데이트
    dispatch("_commit", { action: listCommit, params });
    dispatch("_commit", { action: viewCommit, params });

    // toServer 여부에 따라 서버로 요청
    if (!toServer) return;
    commit("SET_LOADING", true);
    const isShare = await dispatch("_getters", "folder/isShare");
    const shareInfo = await dispatch("_getters", "mailRoute/getRouteShareInfo");
    const { status } = await updateImportantStatus(isShare, mails, shareInfo);
    commit("SET_LOADING", false);
    if (status === 200) return;

    // 변경실패
    const message = status == 403 ? i18n.t("mail.398") : i18n.t("mail.399");
    dispatch("_snackbar", { message, type: "ERROR" });
    // 현재창, 부모창 데이터 업데이트 (롤백)
    params.rollback = true;
    dispatch("_commit", { action: listCommit, params });
    dispatch("_commit", { action: viewCommit, params });
  },
  // 메일 읽음/안읽음 수정
  async updateReadStatus({ dispatch }, { mails, readStatus: nextReadStatus }) {
    // readStatus 값 없을땐 mails에서 판단
    if (typeof nextReadStatus != "number") {
      const fM = mails.filter(({ readStatus }) => !readStatus);
      nextReadStatus = fM.length == 0 ? 0 : 1;
    }
    mails = mails.map(({ mailId, folderId, readStatus }) => ({
      mailId,
      folderId,
      nextReadStatus,
      prevReadStatus: readStatus
    }));

    // 메일 목록/보기/폴더 업데이트
    const params = { mails, readStatus: nextReadStatus, rollback: false };
    const listCommit = "mail/SET_READ_STATUS";
    const viewCommit = "mailView/SET_VIEW_READ_STATUS";
    const folderCommit = "folder/UPDATE_FOLDER_NEWCOUNT";
    // 현재창, 부모창 데이터 업데이트
    dispatch("_commit", { action: listCommit, params });
    dispatch("_commit", { action: viewCommit, params });
    dispatch("_commit", { action: folderCommit, params });

    const isShare = await dispatch("_getters", "folder/isShare");
    const shareInfo = await dispatch("_getters", "mailRoute/getRouteShareInfo");
    const { status } = await updateReadStatus(isShare, mails, shareInfo);
    if (status === 200) return;

    // 변경실패시 롤백
    const message = status == 403 ? i18n.t("mail.400") : i18n.t("mail.401");
    dispatch("_snackbar", { message, type: "ERROR" });
    // 현재창, 부모창 데이터 업데이트 (롤백)
    params.rollback = true;
    dispatch("_commit", { action: listCommit, params });
    dispatch("_commit", { action: viewCommit, params });
    dispatch("_commit", { action: folderCommit, params });
  },
  // 전체 읽음 (툴바, 트리 더보기 메뉴)
  updateAllRead({ commit, dispatch, rootGetters }, id) {
    dispatch(
      "confirm/confirm",
      {
        message: i18n.t("mail.188"),
        callback: async () => {
          const isShare = rootGetters["folder/isShare"];
          const shareInfo = rootGetters["mailRoute/getRouteShareInfo"];
          const { status } = await updateAllRead(isShare, id, shareInfo);

          let type = "ERROR";
          let message = i18n.t("mail.402");
          if (status === 200) {
            type = "SUCCESS";
            message = i18n.t("mail.403");

            // TODO:: 좌우분할시 메일보기켜져있을때 메일보기쪽도 읽음처리 필요
            commit("SET_ALL_READ", id);
            commit("folder/INIT_FOLDER_NEWCOUNT", id, root);
          }

          dispatch("_snackbar", { message, type });
        }
      },
      root
    );
  },

  // 예약메일/중요메일 삭제 시 컨펌, 보낸메일함으로 이동시 발신메일 확인
  checkMailsBeforeMove({ dispatch, rootGetters }, params) {
    const { targetFolderId } = params;
    if (targetFolderId == rootGetters["folder/getSentId"]) {
      return dispatch("checkSentMails", params);
    }
    if (targetFolderId == rootGetters["folder/getTrashId"]) {
      return dispatch("checkDeleteMails", params);
    }
    dispatch("updateFolderId", params);
  },
  async checkSentMails({ dispatch }, params) {
    const { selectedMails, callback = () => {} } = params;
    const sentType = ["SENT", "RESERVE", "RESERVE_CANCEL"];
    const sentMails = selectedMails.filter(m => sentType.includes(m.mailType));
    if (sentMails.length === selectedMails.length) {
      await dispatch("updateFolderId", params);
      return callback(true);
    }
    console.log("sentMails.length===>", sentMails.length);
    console.log("selectedMails.length===>", selectedMails.length);

    const confirmParams = {
      message: i18n.t("mail.299"),
      callback: () => callback(false),
      showCloseBtn: false
    };

    // 선택한 메일이 모두 발신메일이 아닌데 보낸메일함으로 이동시킬때
    if (sentMails.length && sentMails.length !== selectedMails.length) {
      confirmParams.showCloseBtn = true;
      confirmParams.message = i18n.t("mail.48");
      confirmParams.closeCallback = () => callback(false);
      confirmParams.callback = async () => {
        await dispatch("updateFolderId", params);
        callback(true);
      };
    }

    dispatch("confirm/confirm", confirmParams, { root: true });
  },
  async checkDeleteMails({ dispatch }, params) {
    const { selectedMails, callback = () => {} } = params;
    const reserveMail = selectedMails.find(m => m.mailType == "RESERVE");
    const importantMail = selectedMails.find(m => m.importantStatus);

    if (!reserveMail && !importantMail) {
      await dispatch("updateFolderId", params);
      return callback(true);
    }

    const length = selectedMails.length !== 1 ? 2 : 1;
    const i_messages = { 1: i18n.t("mail.566"), 2: i18n.t("mail.565") };
    const r_messages = { 1: i18n.t("mail.567"), 2: i18n.t("mail.186") };
    const confirmParams = {
      message: r_messages[length],
      closeCallback: () => callback(false),
      callback: async () => {
        await dispatch("updateFolderId", params);
        callback(true);
      }
    };

    if (!reserveMail && importantMail) {
      confirmParams.message = i_messages[length];
    } else if (reserveMail && importantMail) {
      confirmParams.confirmStack = [{ message: i_messages[length] }];
    }
    dispatch("confirm/confirm", confirmParams, { root: true });
  },

  // 메일 폴더 변경
  async updateFolderId(
    { rootState, dispatch },
    { targetFolderId: folderId, selectedMails: mails = [] }
  ) {
    const { data, status } = await updateFolderId(mails, folderId);
    if (status !== 200) return;

    // 기존/타겟 폴더 메타정보 변경, 셀렉트된 메일 해제
    dispatch("_commit", { action: "folder/UPDATE_FOLDER_META", params: data });
    dispatch("_commit", { action: "mail/SET_SELECTED_MAILS", params: [] });

    // 메일보기 메일이 있고, 탭이 있다면 닫는다.
    const { basedMail: bM } = rootState.mailView;
    const [bMail] = mails.filter(m => m.mailId === bM?.mailId);
    if (bMail) {
      const splitView = await dispatch("_getters", "mailConfig/getSplitView");
      dispatch("_dispatch", {
        action: "mailTab/deleteViewTabAndMoveList",
        params: { moveToTabId: "list", splitView }
      });
    }

    // 메일 보기 -> 목록으로 라우팅, 목록 -> 목록 리로드
    const { name } = router.currentRoute;
    if (name === "popup_mail_view") window.close();

    const isView = name === "mail_view";
    const action = isView ? "mailRoute/redirectMailList" : "mail/reloadList";
    dispatch("_dispatch", { action });
  },
  // 메일함내 메일 전체이동 (메일함 비우기(설정) -> 휴지통으로 이동)
  async updateAllFolderId(
    { commit, dispatch, rootState, rootGetters },
    { targetFolderId: targetId, prevFolderId: prevId }
  ) {
    const { status } = await updateAllFolderId(targetId, prevId);
    if (status !== 201) return;

    const prevFolder = rootGetters["folder/getFolder"](prevId);
    if (!prevFolder) return;

    const { newCount, totalCount, usedSize } = prevFolder;
    // 기존폴더 메타정보 초기화
    const pParams = { id: prevId, newCount: 0, totalCount: 0, usedSize: 0 };
    commit("folder/SET_FOLDER_META", pParams, root);
    // 이동된 폴더 정보 추가
    const tParams = { id: targetId, newCount, totalCount, usedSize };
    commit("folder/UPDATE_FOLDER_META", { [targetId]: tParams }, root);

    // 리스트 리로딩
    const { mail_list } = rootState.mailRoute;
    const { folderId } = mail_list;
    if (targetId !== folderId && prevId !== folderId) return;
    dispatch("getList", { ...mail_list });
  },
  // mailMeta tags 추가, 삭제
  async updateTags({ dispatch }, { mails, type, tag }) {
    const { status, data } = await updateTags(mails, type, tag);
    if (status !== 200) return;

    data.forEach(m => {
      const params = { mailId: m.mailId, tags: `${m.tags}` };
      dispatch("_commit", { action: "mailView/SET_MAIL_VIEW_TAGS", params });
      dispatch("_commit", { action: "mail/SET_MAIL_LIST_TAGS", params });
    });
  },

  // 임시저장 메일 삭제
  async deleteDraftMail({ commit, dispatch }, selectedMails) {
    const { status } = await deleteDraftMail(selectedMails);
    if (status !== 204) return;

    commit("mail/SET_SELECTED_MAILS", [], root);
    dispatch("reloadList");
  },
  // 휴지통 - 완전삭제 (목록, 보기, 팝업보기)
  async deleteMails({ dispatch, rootState, rootGetters }, selectedMails) {
    let params = { message: i18n.t("mail.404"), type: "ERROR" };
    const trashId = rootGetters["folder/getTrashId"];
    const { data, status } = await updateDeleteStatus(selectedMails, trashId);
    if (status === 200) {
      params = { message: i18n.t("mail.405"), type: "SUCCESS" };
      // 휴지통 메타정보
      dispatch("_commit", { action: "folder/SET_FOLDER_META", params: data });
      dispatch("_commit", { action: "mail/SET_SELECTED_MAILS", params: [] });

      // 메일보기 메일이 있고, 탭이 있다면 닫는다.
      const { basedMail: bM } = rootState.mailView;
      const [bMail] = selectedMails.filter(m => m.mailId === bM?.mailId);
      if (bMail) {
        const splitView = await dispatch("_getters", "mailConfig/getSplitView");
        dispatch("_dispatch", {
          action: "mailTab/deleteViewTabAndMoveList",
          params: { moveToTabId: "list", splitView }
        });
      }

      let action = "mailRoute/redirectMailList";
      const { name } = router.currentRoute;
      if (name === "popup_mail_view") {
        action = "mail/reloadList";
        window.close();
      }

      dispatch("_dispatch", { action });
    }

    dispatch("_snackbar", params);
  },
  // 휴지통 비우기
  async cleanTrash({ commit, dispatch, rootState, rootGetters }) {
    let params = { message: i18n.t("mail.406"), type: "ERROR" };
    const trashId = rootGetters["folder/getTrashId"];
    const { status } = await cleanTrash(trashId);
    if (status === 200) {
      params = { message: i18n.t("mail.407"), type: "SUCCESS" };
      // 리스트 리로딩
      const { mail_list } = rootState.mailRoute;
      const { folderId } = mail_list;
      if (trashId === folderId) {
        commit("SET_SELECTED_MAILS", []);
        dispatch("mail/getList", { ...mail_list, page: 1 }, root);
      }
    }

    dispatch("_snackbar", params);
  },
  // 예약메일 삭제
  async deleteMailReserve({ dispatch }, mailId) {
    const { data, status } = await deleteMailReserve(mailId);
    if (status !== 200) return null;

    const params = { mailId, mailType: "RESERVE_CANCEL" };
    dispatch("_commit", { action: "mailView/SET_MAIL_TYPE", params });
    return data;
  },

  // 악성메일 신고
  async reportMaiciousMail({ dispatch }, { mailId, reportContents }) {
    let snackbar = { message: i18n.t("mail.408"), type: "ERROR" };
    const { data, status } = await reportMail({ mailId, reportContents });
    if (status === 200 && data?.result) {
      snackbar = { message: i18n.t("mail.409"), type: "SUCCESS" };
      const params = { mailId, mailType: "REPORT_MAIL" };
      dispatch("_commit", { action: "mail/SET_MAIL_TYPE", params });
      dispatch("_commit", { action: "mailView/SET_MAIL_TYPE", params });
    }

    dispatch("_snackbar", snackbar);
  },
  // 메일 차단 해제
  async unblockMail({ dispatch }, mailId) {
    let snackbar = { message: i18n.t("mail.412"), type: "ERROR" };
    const { data, status } = await unblockMail(mailId);
    if (status === 200) {
      const { message, result } = data;
      if (!result) {
        if (message === "mailType") snackbar.message = i18n.t("mail.411");
        return dispatch("_snackbar", snackbar);
      }

      snackbar.message = i18n.t("mail.410");
      const params = { mailId, mailType: "NORMAL", ...data };
      dispatch("_commit", { action: "mail/SET_MAIL_TYPE", params });
      dispatch("_commit", { action: "mailView/SET_UNBLOCK_MAIL", params });
    }

    dispatch("_snackbar", snackbar);
  },
  // 해당 메일 주소 수신 거부
  async unsubscribe({ dispatch, commit }, sender) {
    let snackbar = { message: i18n.t("mail.431"), type: "ERROR" };
    const { status } = await unsubscribeSender(sender);
    if (status === 200) {
      snackbar = { message: i18n.t("mail.432"), type: "SUCCESS" };
      commit("mail/SET_SELECTED_MAILS", [], root);
    }

    dispatch("_snackbar", snackbar);
  },
  async showBlockDetailDialog({ commit, dispatch }, id) {
    const { data: params, status } = await getBlockDetail(id);
    if (status !== 200 || !params?.result) {
      dispatch("_snackbar", { message: i18n.t("mail.434"), type: "ERROR" });
      return;
    }

    const show = true;
    const type = "spamRestore";
    const headline = i18n.t("mail.메일복구");
    commit("mailDialog/SET_DIALOG", { show, headline, type, params }, root);
  },
  // 스팸 메일 복구
  async recoveryMail(
    { dispatch, commit },
    { id, fromEmail, subject, isUnblockChecked }
  ) {
    const { status } = await recoveryMail({ id, fromEmail, subject });
    if (isUnblockChecked) {
      return dispatch("unblockSender", [id]);
    }

    const type = status === 200 ? "SUCCESS" : "ERROR";
    const message = status === 200 ? i18n.t("mail.290") : i18n.t("mail.289");
    commit("mail/SET_SELECTED_MAILS", [], root);
    dispatch("_snackbar", { message, type });
  },
  // 해당 스팸 수신자 수신 허용
  async unblockSender({ dispatch, commit }, ids) {
    let snackbar = { message: i18n.t("mail.412"), type: "ERROR" };
    const { status } = await allowSender(ids);
    if (status === 200) {
      snackbar = { message: i18n.t("mail.410"), type: "SUCCESS" };
      commit("mail/SET_SELECTED_MAILS", [], root);
    }

    dispatch("_snackbar", snackbar);
  },

  // 부모창의 getters
  _getters({ rootGetters }, action) {
    let _getters = str => rootGetters[str];
    try {
      _getters = window.opener?.vxg || _getters;
    } catch (e) {
      //
    }

    return _getters(action);
  },
  // 현재창, 부모창 commit
  _commit({ commit }, { action, params }) {
    commit(action, params, root);
    if (router.currentRoute.name?.includes("popup_mail_")) {
      try {
        window.opener?.vxc(action, params, root);
      } catch (e) {
        //
      }
    }
  },
  // 현재창, 부모창의 dispatch
  _dispatch({ dispatch }, { action, params }) {
    dispatch(action, params, root);
    if (router.currentRoute.name?.includes("popup_mail_")) {
      try {
        window.opener?.vxd(action, params, root);
      } catch (e) {
        //
      }
    }
  },
  _snackbar({ dispatch }, params) {
    dispatch("_dispatch", { action: "snackbar/openSnackbar", params });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
