<template>
  <div class="cr_error_wrap">
    <table class="cr_body">
      <colgroup>
        <col width="310px" />
        <col width="*%" />
      </colgroup>
      <tbody>
        <tr>
          <td class="cr_left">
            <div class="cr_thumb">
              <img src="../assets/errorThumb_404.png" />
            </div>
            <p class="cr_title" v-text="$t('common.52')"></p>
            <p v-text="$t('common.53')"></p>
            <p v-text="$t('common.54')"></p>
            <p v-text="$t('common.55')"></p>

            <div class="cr_btn-group">
              <v-btn
                text
                outlined
                tile
                width="100"
                class="mr-3"
                v-text="$t('common.뒤로')"
                @click="$router.go(-1)"
              />
              <v-btn
                text
                outlined
                tile
                width="100"
                v-text="$t('common.홈으로')"
                @click="$router.push({ name: 'mail' })"
              />
            </div>
          </td>
          <td>
            <img src="../assets/illustErr_404.png" />
          </td>
        </tr>
      </tbody>
    </table>

    <div class="copy">
      Copyright © Crinity All Rights Reserved.
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr_error_wrap {
  width: 740px;
  margin: 50px auto;

  .cr_body {
    margin-top: 26px;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;

    td {
      vertical-align: top;
      &:first-child {
        padding-top: 34px;
        padding-left: 20px;
      }

      .cr_thumb {
        margin-bottom: 50px;
      }

      p {
        font-size: 14px;
        margin-bottom: 2px;
        user-select: none;

        &.cr_title {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }

      .cr_btn-group {
        margin-top: 35px;
      }
    }
  }

  .copy {
    color: #8d8d8d;
    font-size: 11px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid #e8e8e8;
    user-select: none;
  }
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.setShowSplash(false);
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"])
  }
};
</script>
