import {
  getBoards,
  addBoard,
  renameBoard,
  updateBoard,
  undeleteBoard,
  deleteBoard,
  hideBoard
} from "@/board/api/board.api";
import Vue from "vue";
import router from "@/commons/router";
import { boardIcons } from "@/commons/utils/icons";
import i18n from "@/_locales";

/**
 * 게시판 찾기
 * @param {*} boards  -- 게시판 트리
 * @param {*} boardId -- 게시판 아이디
 */
const getBoardItem = (boards, boardId) => {
  for (let i = 0; i < boards.length; i += 1) {
    const board = boards[i];
    if (board.id == boardId) return board;

    if (board.children) {
      const result = getBoardItem(board.children, boardId);
      if (result) return result;
    }
  }
};

const state = {
  boards: [],
  activeItems: [],
  openItems: []
};

const getters = {
  getBoards: ({ boards }) => boards,
  getBoardById: ({ boards }) => id => getBoardItem(boards, id),
  getActiveItems: ({ activeItems }) => activeItems,
  getOpenItems: ({ openItems }) => openItems,
  nowBoardIsDeleted: ({ activeItems }) => {
    const [item = {}] = activeItems;

    return item.isDeleted || false;
  }
};

const mutations = {
  SET_BOARDS: (state, boards) => (state.boards = boards),
  SET_ACTIVE_ITEMS: (state, items) => {
    state.activeItems = items;
  },
  SET_OPEN_ITEMS: (state, items) => (state.openItems = items),
  UPDATE_IS_EDIT: (state, id) => {
    const board = getBoardItem(state.boards, id);
    board.isEdit = !board.isEdit;
  },
  UPDATE_BOARD_NAME: (state, { id, name }) => {
    const board = getBoardItem(state.boards, id);
    board.name = name;
  },
  UPDATE_BOARD_INFO: (state, { id, name, description, privilege }) => {
    const board = getBoardItem(state.boards, id);
    board.name = name;
    board.description = description;
    // 게시판 공개 유형 업데이트
    board.memberType = privilege.memberType;
  },
  UPDATE_IS_DELTED_BOARD: (
    state,
    { boardId, isDeleted, deletedTimeMillis = 0 }
  ) => {
    const board = getBoardItem(state.boards, boardId);
    board.isDeleted = isDeleted;
    board.deletedTimeMillis = deletedTimeMillis;
    if (board.children) {
      board.children.forEach(child => {
        child.isDeleted = isDeleted;
        child.deletedTimeMillis = deletedTimeMillis;
      });
    }
  },
  UPDATE_HIDE_BOARD: (state, payload) => {
    const board = getBoardItem(state.boards, payload.id);

    // 상위게시판일 경우
    if (board.id == board.groupId) {
      const index = state.boards.findIndex(b => b.id == board.id);
      Vue.delete(state.boards, index);

      // openItems에서 제거해준다.
      const openIndex = state.openItems.findIndex(o => o.id == board.id);
      if (openIndex > -1) Vue.delete(state.openItems, openIndex);
      return;
    }

    // 하위 게시판인 경우
    const parent = getBoardItem(state.boards, board.groupId);
    // 남아있는 하위게시판의 갯수의 따른 처리
    if (parent.showChildCount == 1) {
      // 삭제시 하나도 남지않을경우 children전체를 삭제해준다. (화살표삭제)
      Vue.delete(parent, "children");
      // openItems에서 제거해준다.
      const openIndex = state.openItems.findIndex(o => o.id == parent.id);
      if (openIndex > -1) Vue.delete(state.openItems, openIndex);
    } else {
      // 그렇지 않은경우는 해당 게시판만 삭제
      const index = parent.children.findIndex(b => b.id == board.id);
      Vue.delete(parent.children, index);
    }

    // 상위게시판의 보여지는 하위게시판 숫자 업데이트
    parent.showChildCount = parent.children?.length || 0;
  },
  /** 삭제 로직 바뀌면서 삭제 예정 */
  DELETE_OPEN_BOARD: (state, boardId) => {
    const board = getBoardItem(state.boards, boardId);
    // 하위 게시판일 경우만 openItems에 상위 게시판이 있는지 조회
    if (board.id == board.groupId) return;
    const parentBoard = getBoardItem(state.boards, board.groupId);
    const index = state.openItems.findIndex(b => b.id == parentBoard.id);

    // 상위 게시판에 하위게시판이 한 개만 남아 있는 상태일 경우
    if (parentBoard.childCount == 1) {
      // openItems에서 제거해준다.
      Vue.delete(state.openItems, index);
    }
  },
  INIT_BOARD_STATE: state => {
    state.openItems = [];
    state.activeItems = [];
    state.boards = [];
  }
};

const actions = {
  // 게시판 데이터 로드
  async loadBoards({ commit, dispatch }) {
    const { status, data = [] } = await getBoards();

    if (status != 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.1"), type: "ERROR" },
        { root: true }
      );
      return;
    }
    const boards = data.map(
      ({
        id,
        userId,
        groupId,
        name,
        boardType,
        description,
        isDeleted,
        deletedTimeMillis,
        childCount,
        userPrivilege,
        memberType,
        children = []
      }) => {
        // 하위 폴더 존재시
        if (children?.length || 0 > 0) {
          const mappedChildren = children.map(
            ({
              id,
              userId,
              groupId,
              name,
              description,
              isDeleted,
              deletedTimeMillis,
              childCount,
              userPrivilege,
              memberType
            }) => {
              return {
                id,
                userId,
                groupId,
                name,
                description,
                isDeleted,
                deletedTimeMillis,
                childCount,
                boardType,
                userPrivilege,
                memberType,
                icon: boardIcons[boardType],
                isEdit: false
              };
            }
          );
          children = mappedChildren;
        }

        // 게시판 이름 다국어 처리
        if (["ALL", "MY", "TRASH"].indexOf(boardType) > -1) {
          name = i18n.t(`board.type_${boardType}`);
        }

        return {
          id,
          userId,
          groupId,
          name,
          description,
          isDeleted,
          deletedTimeMillis,
          boardType,
          userPrivilege,
          memberType,
          icon: boardIcons[boardType],
          isEdit: false,
          childCount,
          showChildCount: children?.length || 0,
          children
        };
      }
    );
    await commit("SET_BOARDS", boards);
  },
  // 트리 액티브, 오픈효과(라우팅)
  async setActiveAndOpenedItem({ state, rootGetters, commit }, boardId) {
    if (!rootGetters["boardRoute/getTreeActive"]) {
      return commit("SET_ACTIVE_ITEMS", []);
    }

    const target = getBoardItem(state.boards, boardId);
    // active 효과
    commit("SET_ACTIVE_ITEMS", [target]);
    // 그룹 하위 게시판일 경우, 상위 게시판을 open 해준다.
    if (target.groupId != target.id) {
      const parentBoard = getBoardItem(state.boards, target.groupId);
      commit("SET_OPEN_ITEMS", [...state.openItems, parentBoard]);
    }
  },
  // 게시판 추가
  async addBoard({ state, commit, dispatch }, param) {
    const { status } = await addBoard(param);

    let message, type;

    switch (status) {
      case 201:
        type = "SUCCESS";
        message = i18n.t("board.3");
        break;
      case 403: //무료 요금제
        type = "VALIDATION";
        message = i18n.t("board.165");
        break;
      default:
        type = "ERROR";
        message = i18n.t("board.2");
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });

    if (status == 201) {
      await dispatch("loadBoards");

      let items;
      // 하위그룹을 추가한 경우에는 openItem 항목에 추가해 노드를 open 해준다.
      if (param.parentId > 0) {
        const parentBoard = getBoardItem(state.boards, param.parentId);
        items = [...state.openItems, parentBoard];
      } else {
        items = [...state.openItems];
      }
      commit("SET_OPEN_ITEMS", items);
    }
  },
  // 게시판 이름 수정
  async updateBoardName({ commit, dispatch }, param) {
    const { status = 401 } = await renameBoard(param);

    const messages = {
      ERROR: i18n.t("board.4"),
      SUCCESS: i18n.t("board.5")
    };
    const type = status === 200 ? "SUCCESS" : "ERROR";

    if (status == 200) commit("UPDATE_BOARD_NAME", param);
    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type },
      { root: true }
    );
    commit("UPDATE_IS_EDIT", param.id);
  },
  // 게시판 정보 수정
  async updateBoard({ commit, dispatch }, param) {
    const { status = 401 } = await updateBoard(param);

    const messages = {
      ERROR: i18n.t("board.4"),
      SUCCESS: i18n.t("board.5")
    };
    const type = status === 200 ? "SUCCESS" : "ERROR";

    // state 상태 변경
    if (status == 200) commit("UPDATE_BOARD_INFO", param);
    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type },
      { root: true }
    );
  },
  // 게시판 숨기기
  async hideBoard({ commit, dispatch }, board) {
    const { name: routeName, params: routeParams } = router.app._route;

    const { status = 401 } = await hideBoard(board.id);

    const messages = {
      ERROR: i18n.t("board.6"),
      SUCCESS: i18n.t("board.5")
    };

    const type = status == 200 ? "SUCCESS" : "ERROR";

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type },
      { root: true }
    );

    if (status == 200) {
      commit("UPDATE_HIDE_BOARD", board);

      if (routeName === "board_config") {
        // 현재 페이지가 환경설정 페이지인 경우 숨김 게시판 리스트 갱신.
        return await dispatch("boardConfig/loadHideBoards", null, {
          root: true
        });
      } else {
        // 게시글 목록일 경우 0페이지로 초기화
        return router.push({
          name: routeName,
          params: routeParams
        });
      }
    }
  },
  // 게시판 삭제 rootGetters, commit,
  async deleteBoard({ commit, dispatch }, boardId) {
    const { status, data } = await deleteBoard(boardId);

    let type = "ERROR";
    let message = i18n.t("board.7");

    if (status == 200) {
      commit("UPDATE_IS_DELTED_BOARD", {
        boardId,
        isDeleted: true,
        deletedTimeMillis: data.deletedTimeMillis
      });
      type = "SUCCESS";
      message = i18n.t("board.9");
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  // 삭제 취소
  async deleteCancel({ commit, dispatch }, boardId) {
    const response = await undeleteBoard(boardId);

    let type = "ERROR";
    let message = i18n.t("board.10");

    if (response.status == 200) {
      commit("UPDATE_IS_DELTED_BOARD", { boardId, isDeleted: false });
      type = "SUCCESS";
      message = i18n.t("board.12");
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
