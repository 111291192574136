<template>
  <v-list dense ref="list" class="cr-search-history-list">
    <v-list-item-group color="primary" v-model="model">
      <v-list-item
        ref="item"
        v-for="history in searchHistory"
        :key="history.id"
        @click.stop="$parent.search(history.historyObj)"
      >
        <v-list-item-title>
          <span v-for="(historyChild, idx) in history.items" :key="idx">
            <span class="body-2">
              {{ `${idx > 0 ? ", " : ""}` }}
            </span>
            <span class="body-2 black--text">
              {{ historyChild.searchWord }}
            </span>
            <span class="body-2 blue-grey--text text--darken-1">
              ({{ historyChild.searchField }})
            </span>
          </span>
          <span class="body-2 blue-grey--text text--darken-1">
            - {{ history.nameFolder }}
          </span>
        </v-list-item-title>
        <v-list-item-action class="ma-0">
          <v-btn icon small @click.stop="$emit('deleteHistory', history.id)">
            <v-icon class="text-caption text--secondary">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<style lang="scss" scoped>
.cr-search-history-list.v-list ::v-deep {
  max-height: 300px;
  overflow-y: auto;
  .v-list-item-group > .v-list-item > .v-list-item__title {
    white-space: normal;
  }
}
</style>

<script>
export default {
  props: {
    listSelect: {
      type: Number,
      default: 0
    },
    searchHistory: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    model: {
      get() {
        return this.listSelect;
      },
      set() {}
    }
  },
  watch: {
    listSelect(n, p) {
      this.scrollMove(n, p);
    }
  },
  methods: {
    // 키보드로 방향키 이동시 스크롤 이동
    scrollMove(idx, prevIdx = 0) {
      const { list, item } = this.$refs;
      if (!list || !item || !item[idx]?.$el) return;

      const { offsetHeight, scrollTop } = list.$el;
      const { offsetHeight: itemHeight } = item[idx].$el;
      const itemPosition = itemHeight * (idx + 1);

      if (
        (idx > prevIdx && itemPosition >= offsetHeight + scrollTop) ||
        (idx < prevIdx && itemPosition < scrollTop)
      ) {
        item[idx].$el.scrollIntoView();
      }
    }
  }
};
</script>
