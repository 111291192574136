import { virtualContactGroupConverter } from "@/contact/constant/contactGroupType";

import { getGroupById } from "../../api/group.api";
import router from "@/commons/router";

const state = {
  contact_config: {
    show: false,
    tabType: ""
  },
  contact_list: {
    contactGroupId: null,
    page: 1,
    actionObj: {}
  },
  contact_print: {
    contactGroupId: null,
    actionObj: {}
  }
};

const getters = {
  getCardListInfo: ({ contact_list }) => contact_list,
  getPrintCardListInfo: ({ contact_print }) => contact_print,
  getPage: ({ contact_list }) => parseInt(contact_list.page),
  getFirstPage: (state, getters, rootState) =>
    rootState.contactConfig.firstPage,
  getDefaultAction: (state, getters, rootState) => {
    return {
      pageSize: rootState.contactConfig.pageSize,
      sort: rootState.contactConfig.sort,
      dir: rootState.contactConfig.dir
    };
  },
  getConfig: (state, getters, rootState) => {
    return {
      firstPage: rootState.contactConfig.firstPage,
      pageSize: rootState.contactConfig.pageSize,
      sort: rootState.contactConfig.sort,
      dir: rootState.contactConfig.dir
    };
  },
  getContactConfig: ({ contact_config }) => contact_config.show,
  getConfigTabType: ({ contact_config }) => contact_config.tabType,
  initConfig: (state, getters, rootState) => rootState.contactConfig.initConfig
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    const { contactGroupId, page, actionObj } = params;
    state[params.routeName] = { contactGroupId, page, actionObj };
  },
  SET_CONTACT_CONFIG: (state, contactConfig) => {
    state.contact_config.show = contactConfig;
  },
  SET_CONFIG_TAB_TYPE: (state, type) => {
    state.contact_config.tabType = type;
  }
};

const actions = {
  async setParams({ commit, dispatch, getters, rootGetters }, params) {
    // 다이얼로그 on 일 경우 닫기
    if (rootGetters["contactDialog/showDialog"]) {
      dispatch("contactDialog/close", {}, { root: true });
    }
    let parameter = params;

    // 환경설정 페이지일 경우
    if (parameter.routeName === "contact_config") {
      if (!rootGetters["contactConfig/initConfig"]) {
        await dispatch("contactConfig/getConfig", false, { root: true });
      }
      await commit("SET_CONTACT_CONFIG", true);
      await commit("SET_CONFIG_TAB_TYPE", params.tabType);
      return;
    }

    // actionObj가 있을 경우
    if (parameter.routeName === "contact_list_action") {
      parameter.routeName = "contact_list";
    }

    // 첫페이지일 경우
    if (params.routeName === "contact") {
      await dispatch("contactConfig/getConfig", false, { root: true });
      // 초기 파라미터 값 설정
      parameter = {
        routeName: "contact_list",
        contactGroupId: getters.getFirstPage,
        page: 1,
        actionObj: JSON.stringify(getters.getDefaultAction)
      };
    }

    if (!getters.initConfig) {
      // 주소록 설정
      await dispatch("contactConfig/getConfig", false, { root: true });
    }

    const {
      contactGroupId,
      page,
      actionObj = JSON.stringify(getters.getDefaultAction)
    } = parameter;
    parameter.actionObj = JSON.parse(actionObj);

    const listParams = {
      contactGroupId:
        contactGroupId < 0 || isNaN(parseInt(contactGroupId))
          ? 0
          : contactGroupId,
      page,
      pageSize: getters.getDefaultAction.pageSize,
      ...parameter.actionObj
    };

    // 폴더마다 추가적으로 넣어줄 parameter추가
    switch (contactGroupId) {
      case "important":
        listParams.importantStatus = 1;
        break;
      case "new":
        listParams.sort = "id";
        listParams.dir = "desc";
        parameter.actionObj.sort = "id";
        parameter.actionObj.dir = "desc";

        listParams.importantStatus = 0;
        listParams.isNew = true;
        break;
      default:
        listParams.importantStatus = 0;
    }

    // 폴더의 존재 여부 검사, 없을 경우 최초 폴더로 라우팅
    // 음수일 경우
    if (parameter.contactGroupId <= 0) return router.push({ name: "contact" });
    // 문자일 경우
    if (isNaN(parameter.contactGroupId)) {
      if (
        parameter.contactGroupId != "all" &&
        parameter.contactGroupId != "important" &&
        parameter.contactGroupId != "new" &&
        parameter.contactGroupId != "search"
      )
        return router.push({ name: "contact" });
    }
    // 사용자의 폴더인지의 경우
    if (parameter.contactGroupId > 0) {
      // store에서의 조회
      const group = rootGetters["group/getContactGroup"](
        parameter.contactGroupId
      );
      if (!group) {
        const { status } = await getGroupById(parameter.contactGroupId);
        if (status != 200) return router.push({ name: "contact" });
      }
    }

    // id가 문자일 경우 변경
    parameter.contactGroupId = virtualContactGroupConverter(contactGroupId);
    await commit("SET_CONTACT_CONFIG", false);
    await commit("SET_ROUTE_PARAMS", parameter);

    await dispatch("card/getCardList", listParams, { root: true });
  },
  async initData({ dispatch, getters }) {
    // // 주소록 설정
    // await dispatch("contactConfig/getConfig", false, { root: true });

    // 주소록 그룹
    const { contactGroupId } = getters.getCardListInfo;
    await dispatch("group/getDefaultContactGroupList", contactGroupId, {
      root: true
    });

    // Splash
    await dispatch("splash/setShowSplash", false, { root: true });
  },
  routeConfigTab(getters, { tabId }) {
    router.push({
      name: "contact_config",
      params: {
        tabType: tabId || "list"
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
