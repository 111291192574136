<template>
  <div class="cr-folder-select" :style="`width: ${width};`">
    <!-- v-select closeOnContentClick 버그로인해 v-menu 이용 -->
    <v-menu
      ref="menu"
      offset-overflow
      offset-y
      disable-keys
      :close-on-content-click="closeOnContentClick"
      :content-class="contentClass"
      v-model="openMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <SelectFrame
          v-bind="attrs"
          v-on="on"
          :selectedFolderName="selectedFolderName"
          :openMenu="openMenu"
          :label="label"
        />
      </template>

      <div style="position: relative; height: 300px; background: #fff;">
        <SearchInput
          ref="searchInput"
          :inputValue.sync="inputValue"
          :openMenu.sync="openMenu"
          :useAddFolder="useAddFolder"
        />
        <List
          v-bind="$props"
          :openMenu="openMenu"
          :selectedFolderId="selectedFolderId"
          :inputValue="inputValue"
          @select="select"
        />
      </div>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
// 전체
.cr-folder-select {
  width: 100%;
  cursor: pointer;

  // 목록 호출 버튼
  button {
    width: 100%;
  }
}

// 목록 부분
.v-menu__content ::v-deep {
  border-radius: 0px;
  overflow-y: hidden;
}

// appbar에서 padding-left 8px일때 v-menu버그로인해 최소 left가 12px임
.v-menu__content.cr-appbar {
  left: 8px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import SelectFrame from "./SelectFrame.vue";
import SearchInput from "./SearchInput.vue";
import List from "./list";

export default {
  components: { SelectFrame, SearchInput, List },
  props: {
    width: {
      default: "100%",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    folderId: {
      default: -1,
      type: [Number, String]
    },
    contentClass: {
      default: "",
      type: String
    },
    useAddFolder: {
      default: false,
      type: Boolean
    },
    showAllFolder: {
      default: true,
      type: Boolean
    },
    showTOMEFolder: {
      default: true,
      type: Boolean
    },
    showSENTFolder: {
      default: true,
      type: Boolean
    },
    showDELETEDFolder: {
      default: true,
      type: Boolean
    },
    selectedTrash: {
      default: false,
      type: Boolean,
      description: "sync필요"
    },
    closeOnContentClick: {
      default: true,
      type: Boolean
    }
  },
  emits: ["change"],
  data(vue) {
    const getters = vue.$store.getters;
    let folderId = vue.folderId;
    // 전체메일 안보여주는데 folderId가 전체메일이면 받은메일함으로 지정
    if (this.$route.name != "home" && folderId < 0) {
      folderId = vue.showAllFolder ? -1 : getters["folder/getInboxId"];
    }

    // folderId가 store에 없으면 기본 폴더 설정
    let folder = getters["folder/getFolder"](folderId);
    const typeChecked =
      folder.folderType == "TAG" ||
      folder.folderType == "DRAFTS" ||
      folder.folderType == "SEARCH";
    if (!folder || typeChecked) {
      if (vue.showAllFolder) folderId = -1;
      else folderId = getters["folder/getInboxId"];

      folder = getters["folder/getFolder"](folderId);
    }

    this.$emit(
      "update:selectedTrash",
      folder?.id == getters["folder/getTrashId"]
    );

    return {
      openMenu: false,
      inputValue: "",
      selectedFolderName: folder?.nameFolder,
      selectedFolderId: folder?.id
    };
  },
  computed: {
    ...mapGetters("folder", ["getFolder", "getInboxId", "getTrashId"])
  },
  watch: {
    openMenu(n) {
      this.inputValue = "";

      if (n) {
        setTimeout(() => {
          // 검색 인풋 포커스
          this.$refs.searchInput?.$refs?.textField?.focus();
        }, 100);
      }
    },
    selectedTrash(n, p) {
      let id = n ? this.getTrashId : this.getInboxId;
      if (p && !n && this.selectedFolderId !== this.getTrashId) {
        id = this.selectedFolderId;
      }

      const folder = this.getFolder(id);
      this.selectedFolderName = folder?.nameFolder;
      this.selectedFolderId = folder?.id;
    },
    folderId(folderId) {
      let id = folderId;
      if (this.$route.name != "home" && folderId < 0) {
        id = this.showAllFolder ? -1 : this.getInboxId;
      }

      let folder = this.getFolder(id);
      const typeChecked =
        folder.folderType == "TAG" ||
        folder.folderType == "DRAFTS" ||
        folder.folderType == "SEARCH";
      if (!folder || typeChecked) {
        if (this.showAllFolder) id = -1;
        else id = this.getInboxId;

        folder = this.getFolder(id);
      }

      this.$emit("update:selectedTrash", folder.id == this.getTrashId);
      this.selectedFolderName = folder.nameFolder;
      this.selectedFolderId = folder.id;
    },
    selectedFolderId(selectedFolderId) {
      this.$emit("update:selectedTrash", selectedFolderId == this.getTrashId);
    }
  },
  methods: {
    // 트리에서 폴더 선택
    select(folder) {
      this.selectedFolderName = folder?.nameFolder;
      this.selectedFolderId = folder?.id;

      this.$emit("change", folder);
    },
    activateMenu() {
      this.openMenu = true;
    },
    closeMenu() {
      this.openMenu = false;
    }
  }
};
</script>
