import http from "@/commons/api/http";
const prefix = "api/flow/projects";

// 프로젝트 추가
const addProject = params => http.post(prefix, params);

// 프로젝트 수정
const updateProject = ({ projectId, params }) =>
  http.put(`${prefix}/${projectId}`, params);

// 프로젝트 삭제
const deleteProject = projectId => http.delete(`${prefix}/${projectId}`);

// 내 프로젝트 조회
const getMyProjects = params => http.get(`${prefix}/my`, { params });

// 모든 프로젝트 조회
const getAllProjects = params => http.get(`${prefix}/all`, { params });

// 프로젝트 상세 조회
const getProjectDetail = pojectId => http.get(`${prefix}/${pojectId}`);

export {
  addProject,
  updateProject,
  deleteProject,
  getMyProjects,
  getAllProjects,
  getProjectDetail
};
