/**
 * treeview drag&drop directive
 */
const DRAG_TARGET_ROOT_CLS = "v-treeview-node__root";
const DRAGGABLE_CLS = "cr-draggable";

// 드랍 가능 영역 생성
const genDropZone = function(rootEl) {
  rootEl.getElementsByClassName(DRAG_TARGET_ROOT_CLS).forEach(el => {
    const draggableEl = el.querySelector(".cr-draggable.cr-dropzone");
    if (!draggableEl) return;
    const { length } = el.getElementsByClassName("cr-drop");
    if (length > 0) return;

    const dropzoneEl = document.createElement("div");
    Object.assign(dropzoneEl.style, {
      position: "absolute",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px"
    });
    dropzoneEl.id = draggableEl.getAttribute("data-id");
    dropzoneEl.classList.add("cr-drop");
    draggableEl.appendChild(dropzoneEl);

    dropzoneEl.addEventListener("mouseleave", async function(e) {
      e.target.style.border = null;
      e.target.style.background = null;
    });
  });
};

const treeviewDragDrop = {
  //el, binding, vnode
  componentUpdated: async function(rootEl, binding, vnode) {
    const { getters } = vnode.context.$store;
    if (await getters["dragDrop/dragMode"]) {
      genDropZone(rootEl);
    }
  },
  inserted: async function(rootEl, binding, vnode) {
    const { dispatch, getters } = vnode.context.$store;
    await dispatch("dragDrop/setDragMode", false);
    let dragInfo = {};
    let helper = null;

    // 헬퍼 생성 / 이동 / 제거
    const genHelper = function(dragTarget) {
      helper = binding.value?.genHelper(dragTarget);
      if (!helper) helper = dragTarget;
      Object.assign(helper.style, { position: "absolute", display: "none" });
      helper.id = "crDragHelper";
      document.body.appendChild(helper);

      // 헬퍼 이동 함수
      const moveHelper = async function(e) {
        if (!(await getters["dragDrop/dragMode"])) return;

        // 드랍영역이라면 드랍가능한 부분 표시
        if (
          e.target.classList.contains("cr-drop") &&
          e.target.id !== dragInfo.id
        ) {
          const { height } = e.target.getBoundingClientRect();

          if (e.offsetY <= 12) {
            e.target.style.border = null;
            e.target.style.borderTop = "3px solid #9E9E9E";
            e.target.style.background = "rgba(1, 1, 1, 0.1)";
            e.target.setAttribute("data-position", "before");
          } else if (e.offsetY > height - 12) {
            e.target.style.border = null;
            e.target.style.borderBottom = "3px solid #9E9E9E";
            e.target.style.background = "rgba(1, 1, 1, 0.1)";
            e.target.setAttribute("data-position", "next");
          } else {
            e.target.style.borderTop = "3px solid #9E9E9E";
            e.target.style.borderBottom = "3px solid #9E9E9E";
            e.target.style.background = "rgba(1, 1, 1, 0.1)";
            e.target.setAttribute("data-position", "inner");
          }
          // tree-node open
        } else if (
          e.target.classList.contains("v-treeview-node__toggle") &&
          !e.target.classList.contains("v-treeview-node__toggle--open")
        ) {
          e.target.click();
        }

        // 헬퍼가 마우스 가리면 안됨
        helper.style.left = e.clientX + 10 + "px";
        helper.style.top = e.clientY + 10 + "px";
      };

      // 헬퍼, dropzone 제거 함수
      const removeHelper = async function(e) {
        await dispatch("dragDrop/setDragMode", false);
        document.body.removeEventListener("mousemove", moveHelper);
        document.body.removeEventListener("mouseup", removeHelper);
        if (helper.parentNode) {
          helper.parentNode.removeChild(helper);
          helper = null;
        }

        // 현재 타겟이 드랍가능영역이라면 mouseup callback 호출
        if (
          e.target.classList.contains("cr-drop") &&
          binding.value.mouseup &&
          e.target.id != dragInfo.id
        ) {
          binding.value.mouseup(e, dragInfo, {
            id: e.target.id,
            position: e.target.getAttribute("data-position")
          });
        }

        // 드랍가능영역 제거
        const dropList = rootEl.getElementsByClassName("cr-drop");
        while (dropList.length > 0) {
          dropList[0].parentNode.removeChild(dropList[0]);
        }
      };

      // 이벤트 바인딩
      document.body.addEventListener("mousemove", moveHelper);
      document.body.addEventListener("mouseup", removeHelper);
    };

    // 트리뷰에 이벤트 바인딩
    rootEl.draggable = true;
    rootEl.addEventListener("dragstart", async function(e) {
      e.stopPropagation();
      e.preventDefault();

      if (helper && helper.style.display != "block") {
        Object.assign(helper.style, { display: "block", zIndex: "999" });
        // 드랍영역 생성
        genDropZone(rootEl);
        // 드래그모드 on
        await dispatch("dragDrop/setDragMode", true);
      }
    });
    rootEl.addEventListener("mousedown", function(e) {
      const dragTarget = e.target.closest("." + DRAG_TARGET_ROOT_CLS);
      const draggableEl = dragTarget?.querySelector("." + DRAGGABLE_CLS);

      // draggable component 있어야 드래그 가능
      if (draggableEl) {
        // drag node 정보 셋팅
        dragInfo = { id: draggableEl.getAttribute("data-id") };
        // 헬퍼 생성
        genHelper(dragTarget.cloneNode(true));
      }
    });
  }
};

export default treeviewDragDrop;
