import Vue from "vue";
import axios from "axios";
import i18n from "@/_locales";
import constant from "@/mail/constant/uploadType";
import { uploadFile, uploadFromDrive } from "@/mail/api/file.api";
const { TEMP_FILE, DRAFT_FILE, LARGE_TEMP_FILE } = constant;

const state = {
  // file 삭제, 순서변경에 사용되는 키값
  key: 0,
  // file 객체
  files: [],
  // 첨부파일 리스트 아이템 목록
  items: [],
  // 첨부파일 리스트 선택된 아이템 목록
  selectedItems: [],

  // 파일업로더 show
  showUploader: false,
  // 첨부된 file 전체 크기
  totalSize: 0,
  // 전체 전송량
  totalLoaded: 0,
  // 전송된 전체 퍼센트
  totalProgress: 0,
  // 남은시간
  remainingTime: 0,
  // axios 취소 (전송취소)
  cancelTokenSources: []
};

const getters = {
  items: ({ items }) => items,
  selectedItems: ({ selectedItems }) => selectedItems,

  isEmptyFiles: ({ items }) => items.length == 0,
  isNotEmptyFiles: ({ items }) => items.length > 0,
  disabledMove: ({ items, selectedItems }) =>
    items.length < 2 || selectedItems.length !== 1,
  totalTempSize: ({ items }) =>
    items.reduce((p, i) => {
      if (i.uploadType !== TEMP_FILE && i.uploadType !== DRAFT_FILE) {
        return p;
      }
      return p + i.size;
    }, 0),
  totalLargeTempSize: ({ items }) =>
    items.reduce((p, i) => {
      if (i.uploadType === TEMP_FILE || i.uploadType === DRAFT_FILE) {
        return p;
      }
      return p + i.size;
    }, 0),

  showUploader: ({ showUploader }) => showUploader,
  totalSize: ({ totalSize }) => totalSize,
  totalCount: ({ items }) => items.length,
  totalProgress: ({ totalProgress }) => totalProgress,
  totalLoaded: ({ totalLoaded }) => totalLoaded,
  remainingTime: ({ remainingTime }) => remainingTime,
  completedCount: ({ items }) => items.filter(i => i.status == 2).length,
  isUploadComplete: ({ items }) =>
    !items.length || items.length == items.filter(i => i.status > 1).length,

  // 업로드된 파일의 id값
  attachments: ({ items }) => items.map(({ id }) => ({ id }))
};

const mutations = {
  SET_FILE: (state, { file, uploadType }) => {
    const { key, id, name, size, status } = file;
    const item = { status: status || 0, loaded: 0, progress: 0, uploadType };

    state.key += 1;
    state.totalSize += size;
    state.files.push(file);
    state.items.push({ key, id, name, size, ...item });
  },
  SET_SELECTED_ITEMS: (state, selectedItems) => {
    state.selectedItems = selectedItems;
  },
  DELETE_FILE: (state, keys) => {
    state.files = state.files.filter(({ key }) => keys.indexOf(key) == -1);
    state.items = state.items.filter(({ key }) => keys.indexOf(key) == -1);

    state.totalSize = 0;
    if (state.items.length > 0) {
      state.totalSize = state.items.reduce((p, i) => p + i.size, 0);
    }
  },
  FILE_MOVE: (state, { key, type }) => {
    const index = state.files.findIndex(i => i.key === key);
    if (index === -1) return;

    let temp = state.files[index];
    if (type == "up" && index !== 0) {
      Vue.set(state.files, index, state.files[index - 1]);
      Vue.set(state.files, index - 1, temp);

      temp = state.items[index];
      Vue.set(state.items, index, state.items[index - 1]);
      Vue.set(state.items, index - 1, temp);
    }

    if (type == "down" && index != state.files.length - 1) {
      Vue.set(state.files, index, state.files[index + 1]);
      Vue.set(state.files, index + 1, temp);

      temp = state.items[index];
      Vue.set(state.items, index, state.items[index + 1]);
      Vue.set(state.items, index + 1, temp);
    }
  },
  CHANGE_UPLOAD_TYPE: (state, { idx, uploadType }) => {
    Vue.set(state.items, idx, { ...state.items[idx], uploadType });
  },
  SET_SHOW_UPLOADER: (state, showUploader) => {
    state.showUploader = showUploader;
  },
  SET_UPLOAD_STATE: (state, { totalProgress, totalLoaded }) => {
    state.totalProgress = totalProgress;
    state.totalLoaded = totalLoaded;
  },
  SET_UPLOAD_REMAINING_TIME: (state, remainingTime) => {
    const hour = parseInt(remainingTime / 3600);
    const min = parseInt((remainingTime % 3600) / 60);
    const sec = remainingTime % 60;

    if (hour) {
      state.remainingTime = i18n.t("mail.413", {
        hour: hour,
        min: min,
        sec: sec
      });
    } else if (min) {
      state.remainingTime = i18n.t("mail.414", {
        min: min,
        sec: sec
      });
    } else {
      state.remainingTime = i18n.t("mail.414", {
        sec: sec
      });
    }
  },
  SET_UPLOAD_FILE_STATE: (state, { idx, id, progress, loaded, status }) => {
    const {
      progress: stateProgress,
      loaded: stateLoaded,
      status: stateStatus
    } = state.items[idx];
    const fileState = {
      id,
      progress: progress == undefined ? stateProgress : progress,
      loaded: loaded == undefined ? stateLoaded : loaded,
      status: status == undefined ? stateStatus : status
    };

    Vue.set(state.items, idx, { ...state.items[idx], ...fileState });
  },
  RESET_UPLOAD_FILE_STATE: (state, idx) => {
    const fileState = { id: 0, progress: 0, loaded: 0, status: 0 };
    Vue.set(state.items, idx, { ...state.items[idx], ...fileState });
  },
  SET_CANCEL_TOKEN_SOURCES: (state, cancelTokenSources) => {
    state.cancelTokenSources = cancelTokenSources;
  },
  UPLOAD_RESET: state => {
    state.key = 0;
    state.files = [];
    state.items = [];
    state.selectedItems = [];

    state.showUploader = false;
    state.totalSize = 0;
    state.totalLoaded = 0;
    state.totalProgress = 0;
    state.remainingTime = 0;
    state.cancelTokenSources = [];
  }
};

const actions = {
  // 일반첨부 최대 25MB (26214400), 대용량 첨부 최대 4GB (4294967296)
  // 일반첨부 20MB로 변경 (20971520)
  setFiles({ state, rootState, getters: g, commit, dispatch }, files) {
    try {
      // 파일 개수 확인
      if (state.files.length + files.length > 20) {
        throw new Error(i18n.t("mail.130"));
      }

      Array.from(files).forEach(file => {
        // 첨부파일 최대 용량 확인
        let uploadType = file.uploadType ?? TEMP_FILE;

        if (!file.status || file.status < 2) {
          const totalTempSize = g.totalTempSize + file.size;
          const totalLargeTempSize = g.totalLargeTempSize + file.size;
          const { largeFileMaxSize: max } = rootState.serviceConfig.mailConfig;

          if (totalTempSize > 20971520 || file.size > 20971520) {
            uploadType = LARGE_TEMP_FILE;
            if (totalLargeTempSize > max) throw new Error(i18n.t("mail.131"));
          }
        }

        file.key = state.key;
        commit("SET_FILE", { file, uploadType });
      });
    } catch ({ message }) {
      dispatch("snackbar", { message, type: "ERROR" });
    }
  },
  deleteFile({ state, commit }) {
    const keys = state.selectedItems.map(({ key }) => key);
    commit("DELETE_FILE", keys);
    commit("SET_SELECTED_ITEMS", []);
  },
  fileMove({ state, commit }, type) {
    if (state.selectedItems.length !== 1) return;

    const [{ key }] = state.selectedItems;
    commit("FILE_MOVE", { key, type });
  },
  changeUploadType({ state, rootState, getters: g, commit, dispatch }, key) {
    try {
      const idx = state.items.findIndex(i => i.key === key);
      if (idx === -1) throw new Error(i18n.t("common.43"));

      let uploadType = TEMP_FILE;
      const { uploadType: uType, size } = state.items[idx];
      if (uType === TEMP_FILE) {
        uploadType = LARGE_TEMP_FILE;
        const { largeFileMaxSize: max } = rootState.serviceConfig.mailConfig;

        if (g.totalLargeTempSize + size > max) {
          throw new Error(i18n.t("mail.416"));
        }
      } else if (g.totalTempSize + size > 20971520) {
        throw new Error(i18n.t("mail.417"));
      }

      commit("CHANGE_UPLOAD_TYPE", { idx, uploadType });
    } catch ({ message }) {
      dispatch("snackbar", { message, type: "ERROR" });
    }
  },

  /**
   * 파일업로드
   */
  uploadFile({ commit, state, getters }, callback) {
    const { files, items, totalSize } = state;
    let { totalLoaded } = state;

    // file이 없으면 return;
    if (files.length == 0) return;
    // 전송된 퍼센트 구하는 함수
    const getTotalProgress = (x, y) => (x / y >= 1 ? 100 : (x / y) * 100);
    commit("SET_SHOW_UPLOADER", true);

    const startTime = new Date().getTime();
    const cancelTokenSources = [];
    const beforeLoadedArray = [];
    let beforeProgressTime = 0;

    files.forEach((file, idx) => {
      const { status: itemStatus, uploadType } = items[idx];
      if (itemStatus !== 0) return;

      // axios 취소
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSources.push(cancelTokenSource);

      // progress event
      const config = {
        cancelToken: cancelTokenSource.token,
        onUploadProgress: function(e) {
          const beforeLoaded = beforeLoadedArray[idx] || 0;
          totalLoaded += e.loaded - beforeLoaded;
          beforeLoadedArray[idx] = e.loaded;
          const totalProgress = getTotalProgress(totalLoaded, totalSize);
          commit("SET_UPLOAD_STATE", {
            totalProgress,
            totalLoaded: totalLoaded > totalSize ? totalSize : totalLoaded
          });

          // 전송시간
          const progressTime = Math.floor(
            (new Date().getTime() - startTime + 1000) / 1000
          );
          if (progressTime !== beforeProgressTime) {
            // 남은시간
            commit(
              "SET_UPLOAD_REMAINING_TIME",
              Math.min(
                Math.floor(
                  (totalSize - totalLoaded) / ((e.loaded - beforeLoaded) * 10)
                ),
                0
              )
            );
          }
          beforeProgressTime = progressTime;

          // 각 파일 progress 퍼센트
          commit("SET_UPLOAD_FILE_STATE", {
            idx,
            status: 1,
            loaded: e.loaded > file.size ? file.size : e.loaded,
            progress: e.loaded / e.total >= 1 ? 100 : (e.loaded / e.total) * 100
          });
        }
      };

      // 서버에 업로드
      uploadFile(file, uploadType, config)
        .then(({ status, data, message }) => {
          if (message == "cancel") {
            // 이미 전송완료된 파일 사이즈
            const totalLoaded = items.reduce(
              (p, i) => p + (i.status == 2 ? i.size : 0),
              0
            );
            // 이미 전송완료된 progress 퍼센트
            const totalProgress = getTotalProgress(totalLoaded, totalSize);

            commit("SET_UPLOAD_STATE", { totalProgress, totalLoaded });
            commit("RESET_UPLOAD_FILE_STATE", idx);
            return;
          }

          // TODO :: 업로드 실패 처리
          if (status === 200) {
            commit("SET_UPLOAD_FILE_STATE", { idx, ...data });

            // 모든 업로드가 끝나면
            if (!getters.isUploadComplete) return;
            commit("SET_SHOW_UPLOADER", false);
            callback();
          }
        })
        .catch(() => {
          //
        });
    });

    commit("SET_CANCEL_TOKEN_SOURCES", cancelTokenSources);
  },
  closeUploader({ commit }) {
    const { totalProgress, cancelTokenSources } = state;
    if (totalProgress < 100) {
      // 취소
      cancelTokenSources.forEach(c => c.cancel("cancel"));
    }

    commit("SET_SHOW_UPLOADER", false);
  },
  snackbar({ dispatch }, params) {
    dispatch("snackbar/openSnackbar", params, { root: true });
  },

  /**
   * 드라이브 파일 가져오기
   */
  async uploadFromDrive({ getters, commit, dispatch }, files) {
    commit("loader/SET_SHOW", true, { root: true });

    for (let i = 0; i < files.length; i += 1) {
      const { id, uploadType } = files[i];
      const { data, status } = await uploadFromDrive({
        fileId: parseInt(id),
        uploadType,
        tempFileSize: getters.totalTempSize,
        largeTempFileSize: getters.totalLargeTempSize
      });
      const { file, result, message: errorMsg } = data || {};

      // 실패
      if (result === "FAIL" || status !== 200) {
        let message = i18n.t("mail.364");
        if (errorMsg == "SIZE") message = i18n.t("mail.365");

        dispatch("snackbar", { message, type: "ERROR" });
        commit("loader/SET_SHOW", false, { root: true });
        return false;
      }

      dispatch("setFiles", [file]);
    }

    commit("loader/SET_SHOW", false, { root: true });
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
