import Vue from "vue";
import { isBlank } from "@/commons/utils/validation";
import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  getMyProjects,
  getAllProjects,
  getProjectDetail
} from "@/flow/api/project.api";

const state = {
  page: 0,
  pageSize: 20,
  searchWord: "",
  loading: true,
  showMyProjects: true,
  nextPageExists: false,
  excludeMyProjects: false,
  excludePrivateProjects: false,
  projects: [],

  selectedLoading: false,
  selectedProjectId: null
};

const getters = {
  // infinite scroll 시 데이터 중복 방지
  projects: ({ projects: pjt }) => {
    return pjt.filter((p, idx) => pjt.findIndex(p2 => p2.id == p.id) == idx);
  },
  selectedProject: ({ projects, selectedProjectId }) => {
    return projects.find(p => p.id == selectedProjectId) || {};
  }
};

const mutations = {
  SET_SHOW_MY_PROJECTS: (state, show) => (state.showMyProjects = show),
  SET_SELECTED_LOADING: (state, bool) => (state.selectedLoading = bool),
  SET_SELECTED_PROJECT_ID: (state, id) => (state.selectedProjectId = id),
  SET_EXCLUDE_MY_PROJECTS: (state, bool) => {
    state.projects = [];
    state.loading = true;
    state.page = 0;
    state.nextPageExists = false;
    state.selectedProjectId = null;
    state.excludeMyProjects = bool;
  },
  SET_EXCLUDE_PRIVATE_PROJECTS: (state, bool) => {
    state.projects = [];
    state.loading = true;
    state.page = 0;
    state.nextPageExists = false;
    state.selectedProjectId = null;
    state.excludePrivateProjects = bool;
  },
  INIT_PROJECTS: (state, projectId) => {
    let projects = [];
    if (projectId) projects = state.projects.filter(p => p.id == projectId);

    state.page = 0;
    state.searchWord = "";
    state.loading = true;
    state.showMyProjects = true;
    state.nextPageExists = false;
    state.projects = projects;
    state.selectedProjectId = null;
    state.excludeMyProjects = false;
    state.excludePrivateProjects = false;
  },
  SET_SEARCH_WORD: (state, searchWord) => {
    state.projects = [];
    state.loading = true;
    state.page = 0;
    state.nextPageExists = false;
    state.searchWord = searchWord;
    state.selectedProjectId = null;
  },

  SET_PROJECTS: (state, { total, content, pageable: { pageNumber } }) => {
    state.nextPageExists = Math.ceil(total / state.pageSize) > pageNumber + 1;
    if (!pageNumber) state.projects = content;
    else state.projects = [...state.projects, ...content];

    state.page += 1;
    state.loading = false;
  },
  SET_PROJECT: (state, project) => {
    let idx = state.projects.findIndex(p => p.id == project.id);
    if (idx === -1) idx = state.projects?.length || 0;

    Vue.set(state.projects, idx, project);
  }
};

const actions = {
  async toggleShowMyProjects({ state, commit, dispatch }) {
    const showMyProjects = !state.showMyProjects;
    commit("INIT_PROJECTS");
    commit("SET_SHOW_MY_PROJECTS", showMyProjects);
    dispatch(showMyProjects ? "getMyProjects" : "getAllProjects");
  },
  async getMyProjects({ state, commit, dispatch }) {
    const { page, pageSize } = state;
    const { status, data: projects } = await getMyProjects({ page, pageSize });
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_PROJECTS", projects);
  },
  async getAllProjects({ state, commit, dispatch }) {
    const { page, pageSize, excludeMyProjects, excludePrivateProjects } = state;
    const p = { page, pageSize, excludeMyProjects, excludePrivateProjects };
    if (!isBlank(state.searchWord)) p["title"] = state.searchWord;

    const { status, data } = await getAllProjects(p);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_PROJECTS", data);
  },
  async getProjectDetail({ commit, dispatch }, projectId) {
    const { status, data } = await getProjectDetail(projectId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_PROJECT", data);
  },
  async selectProject({ commit, dispatch }, projectId) {
    commit("SET_SELECTED_LOADING", true);
    commit("SET_SELECTED_PROJECT_ID", null);
    await dispatch("getProjectDetail", projectId);

    setTimeout(() => {
      commit("SET_SELECTED_LOADING", false);
      commit("SET_SELECTED_PROJECT_ID", projectId);
    }, 350);
  }
};

export default { state, getters, mutations, actions };
