<template>
  <v-treeview
    dense
    :open-all="!isSearch"
    hoverable
    activatable
    transition
    return-object
    item-text="nameFolder"
    :items="folders"
    :class="isSearch ? 'search' : ''"
    :active.sync="activeFolders"
    :open.sync="openFolders"
    @update:active="onActive"
  >
    <!-- 아이콘 -->
    <template v-slot:prepend="{ item, open }">
      <v-icon v-if="item.icon" v-text="item.icon" :color="getIconColor(item)" />
      <v-icon
        v-else
        v-text="open ? 'mdi-folder-open' : 'mdi-folder'"
        :class="item.childCount === 0 ? 'ml-3' : ''"
        :color="getIconColor(item)"
      />
    </template>
    <!-- 폴더 이름 -->
    <template v-slot:label="{ item }">
      <div
        class="cr-tree_node_click_area"
        @click="e => nodeClick(e, item)"
        :title="item.nameFolder"
      />
      {{ item.nameFolder }}
    </template>
  </v-treeview>
</template>

<style lang="scss" scoped>
.v-treeview::v-deep {
  .v-treeview-node__root {
    padding-left: 4px;
    padding-right: 4px;

    .v-treeview-node__level {
      width: 20px;
    }
    .v-treeview-node__toggle {
      z-index: 999;
      font-size: 20px;
      width: 20px;
    }
    .v-treeview-node__content {
      margin-left: 3px;
      cursor: pointer;
      .v-treeview-node__prepend {
        min-width: 20px;
        .v-icon {
          font-size: 20px;
        }
      }
      .v-treeview-node__label {
        height: 20px;
        .cr-tree_node_click_area {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }

  // 검색시 기존뎁스 제거
  &.search {
    .v-treeview-node__root
      .v-treeview-node__content
      .v-treeview-node__prepend
      .v-icon {
      margin-left: 0px !important;
    }
  }

  .v-treeview-node__children {
    .v-treeview-node__level {
      width: 12px;
    }
  }
}
</style>

<script>
import { rootFolderType } from "@/mail/constant/folderType";

export default {
  props: {
    selectedFolderId: {
      default: 0,
      type: Number
    },
    folders: {
      default: () => [],
      type: Array
    },
    useAddFolder: {
      default: false,
      type: Boolean
    },
    showAllFolder: {
      default: true,
      type: Boolean
    },
    isSearch: {
      default: false,
      type: Boolean
    }
  },
  data(vue) {
    const getters = vue.$store.getters;
    let folderId = vue.folderId;
    // 전체메일 안보여주는데 folderId가 전체메일이면 받은메일함으로 지정
    if (!vue.showAllFolder && folderId == -1) {
      folderId = getters["folder/getInboxId"];
    }
    // folderId가 store에 없으면 기본 폴더 설정
    let folder = getters["folder/getFolder"](folderId);
    if (!folder) {
      if (vue.showAllFolder) folderId = -1;
      else folderId = getters["folder/getInboxId"];

      folder = getters["folder/getFolder"](folderId);
    }

    return {
      activeFolders: [{ id: vue.selectedFolderId }],
      openFolders: [],
      prevActiveItem: undefined
    };
  },
  watch: {
    selectedFolderId(selectedFolderId) {
      this.activeFolders = [{ id: selectedFolderId }];
    }
  },
  methods: {
    // 트리노드 선택시 아이콘 색상 변경
    getIconColor(item) {
      if (item.color) return item.color;
      return this.activeFolders[0]?.id == item.id ? "primary" : "";
    },
    // 트리노트 선택
    onActive([n]) {
      // 이미 선택된 메일함을 또 선택했을때 active해제방지
      if (!n?.id) {
        if (!this.prevActiveItem) return;
        return (this.activeFolders = [this.prevActiveItem]);
      }

      this.prevActiveItem = n;

      // 검색시 동일한 id의 폴더가 나올경우 직접 클래스바꿔준다
      if (this.isSearch) {
        setTimeout(() => {
          this.$el
            .getElementsByClassName("v-treeview-node__root")
            .forEach(el => {
              el.classList.remove("v-treeview-node--active", "primary--text");
              if (
                el.querySelector(
                  ".v-treeview-node__prepend > .v-icon.primary--text"
                )
              ) {
                el.classList.add("v-treeview-node--active", "primary--text");
              }
            });
        }, 0);
      }
    },
    nodeClick(e, folder) {
      if (rootFolderType[folder.folderType]) {
        e.stopPropagation();
        const { length } = this.openFolders.filter(
          item => item.folderType == folder.folderType
        );
        if (length == 0) {
          this.openFolders = [...this.openFolders, folder];
        } else {
          this.openFolders = this.openFolders.filter(
            item => item.folderType != folder.folderType
          );
        }
        return;
      }

      this.$emit("select", folder);
    }
  }
};
</script>
