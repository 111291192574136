<template>
  <!-- 주소록일 경우 -->
  <div class="py-2" v-if="!isOrgan">
    <div class="font-weight-bold">
      {{ item.name }}
      <v-icon v-if="item.importantStatus" size="14" color="yellow darken-1">
        mdi-star
      </v-icon>
    </div>
    <div>{{ item.email }}</div>
  </div>
  <!-- 조직도일 경우 -->
  <div class="py-2" v-else>
    <div>
      <span class="font-weight-bold">{{ item.name }}</span>
      <span> {{ item.jobGrade }} </span>
    </div>
    <span>
      {{ item.email }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isOrgan: {
      type: Boolean,
      default: false
    }
  }
};
</script>
