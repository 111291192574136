import http from "@/commons/api/http";

/**
 * 비밀번호 변경
 * @returns {*}
 */
const updatePw = ({ currentPassword, newPassword }) => {
  const formData = new FormData();
  formData.append("currentPassword", currentPassword);
  formData.append("newPassword", newPassword);
  return http.put("api/users/pw", formData);
};

/**
 * 비밀번호 변경
 * @returns {*}
 */
const forceUpdatePw = (newPassword) => {
  const formData = new FormData();
  formData.append("newPassword", newPassword);
  return http.put("api/users/force/pw", formData);
};

/**
 * otp등록
 * @param password 비밀번호
 * @returns {*}
 */
const registOtp = (password) => {
  const formData = new FormData();
  formData.append("password", password);

  return http.post("api/users/otp", formData);
};

/**
 * 2차인증 해제
 * @returns {*}
 */
const unUsedTwoFactorAuth = () => {
  return http.put("api/users/twoFactorAuth");
};

export { updatePw, forceUpdatePw, registOtp, unUsedTwoFactorAuth };
