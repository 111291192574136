import Vue from "vue";
import i18n from "@/_locales";
import router from "@/commons/router";

const state = {
  // 탭 목록
  tabs: [{ tabId: "list", name: "" }],
  // 선택된 탭
  selectedTab: undefined,
  // 이동할 탭
  moveToTabId: ""
};

const getters = {
  tabs: ({ tabs }) => tabs,
  selectedTab: ({ selectedTab }) => selectedTab,
  moveToTabId: ({ moveToTabId }) => moveToTabId,
  existWriteTab: ({ tabs }) => !!tabs.find(t => t.tabId == "write"),
  activatedTab: ({ tabs, selectedTab }) => tabs[selectedTab]?.tabId
};

const mutations = {
  RESET_TAB: state => {
    state.tabs = [{ tabId: "list", name: "" }];
  },
  SET_TAB: (state, tab) => {
    const index = state.tabs.findIndex(({ tabId }) => tabId == tab.tabId);
    if (index > -1) return (state.selectedTab = index);

    Vue.set(state.tabs, state.tabs.length, tab);
    state.selectedTab = state.tabs.length - 1;
  },
  DELETE_TAB: (state, tabId) => {
    if (!tabId || tabId === "list") return;

    const { selectedTab, moveToTabId } = state;
    const currentTabId = state.tabs[selectedTab]?.tabId;
    if (moveToTabId) {
      const index = state.tabs.findIndex(t => t.tabId === moveToTabId);
      if (index > -1) state.selectedTab = index;
    }

    state.tabs = state.tabs.filter(tab => tab.tabId !== tabId);
    if (!moveToTabId && currentTabId) {
      const index = state.tabs.findIndex(t => t.tabId === currentTabId);
      if (index > -1) state.selectedTab = index;
    }
  },
  SET_MOVE_TO_TAB_ID: (state, tabId) => {
    state.moveToTabId = tabId;
  }
};

const actions = {
  useTabChange({ commit }, splitView) {
    const { name } = router.currentRoute;
    if (!name) return;

    ["view", "write", "config"].forEach(t => commit("DELETE_TAB", t));

    const tabId = name.split("_")[1];
    let tabName = i18n.t("mail.메일보기");
    // 목록보기이고 메일보기화면일때 || 메일보기가 아닐때
    if ((tabId === "view" && !splitView) || tabId !== "view") {
      if (tabId === "write") tabName = i18n.t("mail.메일쓰기");
      if (tabId === "config") tabName = i18n.t("mail.메일설정");
      commit("SET_TAB", { tabId, name: tabName });
    }
  },
  deleteViewTabAndMoveList({ commit }, { moveToTabId, splitView }) {
    commit("SET_MOVE_TO_TAB_ID", moveToTabId);
    commit("DELETE_TAB", "view");

    // 좌우분할보기일때
    if (splitView) {
      commit("mailView/RESET_VIEW_STATE", null, { root: true });
      commit("mailRoute/RESET_ROUTE_VIEW", null, { root: true });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
