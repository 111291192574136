const state = {
  showSplash: true,
  splashImage: "mail"
};

const getters = {
  showSplash: ({ showSplash }) => showSplash,
  splashImage: ({ splashImage }) => splashImage
};

// state값 수정
const mutations = {
  SET_SHOW_SPLASH: (state, showSplash) => {
    state.showSplash = showSplash;
  },
  SET_SPLASH_IMAGE: (state, module) => {
    state.splashImage = module;
  }
};

// mutations 호출 및 비동기 처리
const actions = {
  setShowSplash({ commit }, showSplash) {
    commit("SET_SHOW_SPLASH", showSplash);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
