<!-- 고객주소록 검색 -->
<template>
  <div class="cr-search-wrapper">
    <v-text-field
      color="dark"
      flat
      solo
      hide-details
      v-model="inputText"
      maxlength="50"
      ref="searchInputRef"
      class="cr-search-input"
      background-color="#f1f1f3"
      :placeholder="$t('customer.search.all')"
      @keydown="inputKeydown"
      clearable
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import i18n from "@/_locales";
import { searchTypes } from "@/customer/constant/constants";

export default {
  props: {},
  mounted() {
    this.inputText = this.getKeyword;
  },
  data() {
    return {
      inputText: "",
      searchUserName: "",
      searchTel: "",
      searchEmail: "",
      page: 1,
      pageSize: 10,
      totalPages: 0
    };
  },
  watch: {},
  computed: {},
  methods: {
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    inputKeydown(e) {
      if (e.key === "Enter") {
        if (this.validation()) {
          // 고객주소록 검색 후, 리스트 dialog에 표시
          this.openSearchDialog();
        }
      }
    },
    async openSearchDialog() {
      await this.SET_DIALOG({
        title: i18n.t("customer.search.result"),
        type: "customerSearch",
        showButtons: false,
        params: {
          searchType: searchTypes.ALL,
          keyword: this.inputText
        }
      });
    },
    closeSearchMenu() {
      this.showDetail = false;
    },
    validation() {
      // 앞뒤 공백 제거
      this.inputText = this.inputText.trim();
      // 글자 수 검증
      if (this.inputText.length < 1) {
        this.openSnackbar({
          message: "최소 한 글자 이상 입력해야 합니다.",
          type: "VALIDATION"
        });
        return false;
      }
      if (this.inputText.length > 50) {
        this.openSnackbar({
          message: "최대 50 글자까지 입력 가능합니다.",
          type: "VALIDATION"
        });
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-search-wrapper ::v-deep {
  display: flex;
  flex: 1 1 auto;
  width: 400px;
  max-width: 400px;

  .v-text-field--filled {
    border-radius: 4px;
  }

  .v-input ::v-deep {
    .v-input__slot {
      min-height: 36px !important;
      padding: 0 4px 0 8px !important;

      &::before {
        border: none;
      }
    }
  }

  .v-input.v-text-field.cr-search-input {
    .cr-detail-btn {
      padding: 0px;
    }
  }
}

/* 상세 검색 */
.cr-search-detail-wrap ::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    &:before {
      border-color: transparent !important;
    }
    &:after {
      bottom: 0px !important;
    }
  }
}
</style>
