import http from "@/commons/api/http";
const prefix = "api/flow/projects";

// 업무 초기 데이터 조회
const getInitialData = (projectId, params) =>
  http.get(`${prefix}/${projectId}/tasks/initial`, { params });

// 업무 조회
const getTask = (projectId, taskId) =>
  http.get(`${prefix}/${projectId}/tasks/${taskId}`);

// 업무 목록 조회
const getTasks = (projectId, params) =>
  http.get(`${prefix}/${projectId}/tasks`, { params });

// 하위 업무 목록 조회
const getSubTasks = (projectId, parentId) =>
  http.get(`${prefix}/${projectId}/tasks/sub/${parentId}`);

// 업무 추가
const addTask = (projectId, params) =>
  http.post(`${prefix}/${projectId}/tasks`, params);

const addTasks = params => {
  const { titleList, status, groupId } = params;
  const taskList = titleList.map(title => ({ title, groupId, status }));

  return http.post(`${prefix}/${params.projectId}/tasks/bulk`, taskList);
};

const moveTask = params => {
  const { projectId, taskId, viewType, targetId, position } = params;
  const formData = new FormData();
  formData.append("targetId", targetId);
  formData.append("position", position);
  return http.put(
    `${prefix}/${projectId}/tasks/${taskId}/move/${viewType}`,
    formData
  );
};

// 업무 삭제
const deleteTasks = (projectId, taskIds) => {
  const data = new FormData();
  taskIds.forEach(id => data.append("taskIds", id));
  return http.delete(`${prefix}/${projectId}/tasks`, { data });
};

// 프로젝트 비우기
const deleteAllTask = projectId =>
  http.delete(`${prefix}/${projectId}/tasks/all`);

// 제목 변경
const updateTitle = ({ projectId, taskId, title }) => {
  const formData = new FormData();
  formData.append("title", title);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/title`, formData);
};

// 업무 상태 변경
const updateStatus = ({ projectId, taskId, status }) => {
  const formData = new FormData();
  formData.append("status", status);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/status`, formData);
};

// 중요도 변경
const updatePriority = ({ projectId, taskId, priority }) => {
  const formData = new FormData();
  formData.append("priority", priority);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/priority`, formData);
};

// 상세내용 변경
const updateContent = ({ projectId, taskId, content }) => {
  const formData = new FormData();
  formData.append("content", content);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/content`, formData);
};

// 그룹 변경
const updateGroupId = ({ projectId, taskId, groupId }) => {
  const formData = new FormData();
  formData.append("groupId", groupId);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/group`, formData);
};

// 담당자 수정
const updateAssignee = ({ projectId, taskId, userId }) => {
  const formData = new FormData();
  formData.append("userId", userId);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/assignees`, formData);
};

// 시작날짜 수정
const updateStartDate = ({ projectId, taskId, startDateTimeMillis }) => {
  const formData = new FormData();
  formData.append("startDateTimeMillis", startDateTimeMillis);
  return http.put(
    `${prefix}/${projectId}/tasks/${taskId}/start-date`,
    formData
  );
};

// 마감날짜 수정
const updateDueDate = ({ projectId, taskId, dueDateTimeMillis }) => {
  const formData = new FormData();
  formData.append("dueDateTimeMillis", dueDateTimeMillis);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/due-date`, formData);
};

// 시작, 마감날짜 수정
const updateRangeDate = params => {
  const { projectId, taskId, startDateTimeMillis, dueDateTimeMillis } = params;
  const formData = new FormData();
  formData.append("startDateTimeMillis", startDateTimeMillis);
  formData.append("dueDateTimeMillis", dueDateTimeMillis);

  return http.put(
    `${prefix}/${projectId}/tasks/${taskId}/range-date`,
    formData
  );
};

// 프로그레스 수정
const updateProgress = ({ projectId, taskId, progress }) => {
  const formData = new FormData();
  formData.append("progress", progress);
  return http.put(`${prefix}/${projectId}/tasks/${taskId}/progress`, formData);
};

// 체크리스트 추가
const addChecklist = ({ projectId, taskId, checklist }) => {
  return http.post(
    `${prefix}/${projectId}/tasks/${taskId}/checklist`,
    checklist
  );
};

// 체크리스트 수정
const updateChecklist = ({ projectId, taskId, checklist }) => {
  return http.put(
    `${prefix}/${projectId}/tasks/${taskId}/checklist`,
    checklist
  );
};

// 체크리스트 삭제
const deleteChecklist = ({ projectId, taskId, checklistId }) => {
  return http.delete(
    `${prefix}/${projectId}/tasks/${taskId}/checklist/${checklistId}`
  );
};

export {
  getInitialData,
  getTask,
  getTasks,
  getSubTasks,
  addTask,
  addTasks,
  moveTask,
  deleteTasks,
  deleteAllTask,
  updateTitle,
  updateStatus,
  updatePriority,
  updateContent,
  updateGroupId,
  updateAssignee,
  updateStartDate,
  updateDueDate,
  updateRangeDate,
  updateProgress,
  addChecklist,
  updateChecklist,
  deleteChecklist
};
