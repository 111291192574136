import axios from "axios";
import qs from "query-string";
import storage from "@/commons/api/storage";
import router from "@/commons/router";
import store from "@/commons/store";
import i18n from "@/_locales";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_SERVER_URL,
  // timeout: 1000 * 60, // 60 second
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
});

/**
 * 토큰 만료시 프로세스
 */
const oauth = {
  request_refreshToken: false,
  failed_queue: [],
  failed_clear: () => {
    oauth.failed_queue = [];
  },
  failed_queue_process: error => {
    oauth.failed_queue.forEach(promise => {
      if (error) promise.reject(error);
      else promise.resolve();
    });
    oauth.failed_queue = [];
  }
};

const requestRefreshToken = async () => {
  oauth.request_refreshToken = true;
  const _refreshToken = storage.getRefreshToken();
  storage.clearToken();
  if (!_refreshToken) return (window.location.href = "/");

  const formData = new FormData();
  formData.append("grant_type", "refresh_token");
  formData.append("refresh_token", _refreshToken);
  http.defaults.headers.common[
    "Authorization"
  ] = `Basic ${process.env.VUE_APP_API_CLIENT}`;

  const { data, status } = await http.post("/oauth/token", formData);
  oauth.request_refreshToken = false;
  if (status !== 200) return (window.location.href = "/");

  storage.setAccessToken(data.access_token);
  storage.setRefreshToken(data.refresh_token);

  // notify
  store.dispatch(
    "websocket/notifyAuthToken",
    { accessToken: data.access_token, refreshToken: data.refresh_token },
    { root: true }
  );
  return true;
};

/**
 * request interceptor
 */
http.interceptors.request.use(
  config => {
    // 인증 요청일 경우 PASS
    if (
      config.url === "/oauth/token" ||
      config.url === "/api/share/link/mails/viewer/available/url"
    )
      return config;

    // 토큰이 있는 경우 해더에 access_token 설정
    const token = storage.getAccessToken();
    if (token) config.headers["Authorization"] = "Bearer " + token;
    // [] 대괄호 인코딩
    config.paramsSerializer = p => qs.stringify(p, { arrayFormat: "brackets" });

    return config;
  },
  error => Promise.reject(error)
);

/**
 * response interceptor
 */
http.interceptors.response.use(
  response => response,
  async ({ config: originalRequest = {}, response = {}, message }) => {
    // 통신오류시 메일쓰기 저장
    const isPopup = router.currentRoute.name == "popup_mail_write";
    if (
      // 메일쓰기 팝업일때는 메일전송 실패에 대한것만
      (isPopup && originalRequest.url == "api/mails/send") ||
      // 메일쓰기 팝업이 아니고 탭을 사용하고 메일쓰기탭이 있을때
      (((!isPopup &&
        store.getters["mailConfig/getUseTab"] == 1 &&
        store.getters["mailLayout/existWriteTab"]) ||
        // 메일쓰기 팝업이 아니고 탭을 사용안하고 현재 화면이 메일쓰기일때
        (!isPopup &&
          store.getters["mailConfig/getUseTab"] == 0 &&
          router.currentRoute.name == "mail_write")) &&
        !(
          originalRequest.method == "get" &&
          [
            "api/mails/v2/compose",
            "api/mails/v2/draft",
            "api/mails/v2/reply",
            "api/share/mails/v2/reply",
            "api/mails/v2/forward",
            "api/share/mails/v2/forward",
            "api/mails/v2/resend"
          ].includes(originalRequest.url)
        ) &&
        !store.getters["mailCompose/showSendResult"])
    ) {
      storage.setMailWriteForm(
        JSON.stringify(store.getters["mailCompose/ASObserver"])
      );
    }

    const { status } = response;
    // 이미 리프레시 토큰 요청중일때
    if (oauth.request_refreshToken) {
      // 리프레시 토큰 요청이 안될때
      if (
        [400, 401].includes(status) &&
        originalRequest.url == "/oauth/token"
      ) {
        // 로그인 페이지로 이동 -> url로 처음들어왔을때 name 정보 존재하지않음 -> 알림창없이 바로 로그인페이지
        if (router.currentRoute.name) alert(i18n.t("login.6"));
        storage.clearToken();
        window.location.href = "/";
        return;
      }

      // 실패한 요청처리
      return new Promise(function(resolve, reject) {
        oauth.failed_queue.push({ resolve, reject });
      })
        .then(() => http(originalRequest))
        .catch(err => {
          oauth.failed_queue_process(err);
          return Promise.reject(err);
        });
    }

    // 인증 오류 이고 재시도중이 아닐 경우 토큰 갱신 요청
    const isFileUpload = [
      "api/mail/file/upload",
      "/api/drive/file/upload",
      "/detail/file/upload"
    ].includes(originalRequest.url);
    if ((status === 401 || isFileUpload) && !originalRequest._retry) {
      originalRequest._retry = true;

      if (await requestRefreshToken()) {
        oauth.failed_queue_process();
        return http(originalRequest);
      }
    }

    // Not Acceptable (허용되지않음)
    if (status == 406) return router.push({ name: "mail" });
    return { message, ...response };
  }
);

export default http;
