<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn v-on="on" v-bind="attrs" icon @click="showThemeDialog">
          <v-icon :color="hover ? 'accent' : ''">mdi-palette-outline</v-icon>
        </v-btn>
      </v-hover>
    </template>

    <span>{{ $t("common.82") }}</span>
  </v-tooltip>
</template>

<style lang="scss" scoped>
.v-btn {
  position: absolute;
  bottom: 65px;
  right: 23px;
  z-index: 6;
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("theme", ["showThemeDialog"])
  }
};
</script>
