<template>
  <div :class="wrapperCls">
    <Title :isMyTags="isMyTags" :search.sync="search" />
    <v-progress-linear
      v-if="showLinearProgress"
      indeterminate
      height="8"
      style="position: absolute;"
    />

    <v-card-text v-else class="py-0 mt-4" @scroll="scroll">
      <!-- 태그 검색 -->
      <SearchTags v-if="showSearchTags" :search="search" />

      <!-- 일반 태그 목록 -->
      <div v-else style="display: contents;">
        <!-- 새로 추가한 태그 목록 -->
        <NewTags v-if="showNewTags" :newTags="newTags" />
        <!-- 전체 태그 목록 -->
        <Tag
          v-for="(tag, idx) in tags"
          :key="tag.tag"
          :tag="tag"
          :lastChild="idx === tags.length - 1"
          :isMyTags="isMyTags"
        />
        <EmptyTags v-if="!tags.length" :isMyTags="isMyTags" />

        <div
          v-if="showCircularProgress"
          class="d-flex align-center justify-center py-3"
        >
          <v-progress-circular indeterminate color="primary" />
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<style lang="scss" scoped>
.cr-tag-list-wrapper::v-deep {
  position: relative;
  max-width: 61%;
  min-width: 61%;
  min-height: 450px;

  .v-card__text {
    height: 450px;
    overflow: hidden auto;
  }
}

.cr-tag-list-wrapper.cr-my-tag-list::v-deep {
  max-width: 38%;
  min-width: 38%;
  .v-card__text {
    border-right: 1px solid #d2d2d2;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Title from "./title/index.vue";
import NewTags from "./NewTags.vue";
import SearchTags from "./SearchTags.vue";
import Tag from "./Tag.vue";
import EmptyTags from "./EmptyTags.vue";

export default {
  components: { Title, NewTags, SearchTags, Tag, EmptyTags },
  data() {
    return {
      search: { tag: "", error: false, label: "" }
    };
  },
  computed: {
    ...mapGetters("tag", [
      "loading",
      "allTags",
      "newTags",
      "myTags",
      "page",
      "totalPages"
    ]),
    isMyTags() {
      return this.$vnode.key === "my";
    },
    tags() {
      if (this.isMyTags) return this.myTags;
      return this.allTags;
    },
    showNewTags() {
      return !this.isMyTags && this.newTags.length > 0;
    },
    showSearchTags() {
      return !this.isMyTags && this.search.tag.length > 0;
    },
    showLinearProgress() {
      if (this.showSearchTags && this.loading) return true;
      return !this.isMyTags && this.loading && this.allTags.length === 0;
    },
    showCircularProgress() {
      return !this.isMyTags && this.loading && this.allTags.length > 0;
    },
    wrapperCls() {
      let cls = "cr-tag-list-wrapper ";
      if (this.isMyTags) cls += "cr-my-tag-list";
      return cls;
    }
  },
  methods: {
    ...mapMutations("tag", ["SET_LOADING"]),
    ...mapActions("tag", ["getTags"]),
    scroll(e) {
      if (this.isMyTags || this.loading || this.page + 1 === this.totalPages) {
        return;
      }

      const { scrollTop, scrollHeight, offsetHeight } = e.target;
      if (scrollTop + offsetHeight < scrollHeight - 100) return;
      this.getTags(this.page + 1);
    }
  }
};
</script>
