import customerGroup from "./modules/group";
import customerRoute from "./modules/route";
import customerCard from "./modules/card";
import customerHistory from "./modules/history";
import customerCardDetail from "./modules/detail";
import customerDialog from "./modules/dialog";

export default {
  customerGroup,
  customerRoute,
  customerCard,
  customerHistory,
  customerCardDetail,
  customerDialog
};
