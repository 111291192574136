import http from "@/commons/api/http";

const prefix = "api/mails";
const sharePrefix = "api/share/mails";
const spamPrefix = "api/spam";

// ---------------------------------------------------------------------------//
// 메일 검색
const searchMail = params => http.get(`${prefix}/search`, { params });
// 주요 메일 목록 조회 (주고받은, 내부, 주소록)
const getMainMailList = params => http.get(`${prefix}/main`, { params });
// 공유받은 목록 조회
const getSharedFolders = params =>
  http.get(`${sharePrefix}/shared/folders`, { params });
// 공유한 목록 조회
const getSharingFolders = params =>
  http.get(`${sharePrefix}/sharing/folders`, { params });
// 메일 목록 조회
const getMailList = (isShare, params) =>
  http.get(isShare ? sharePrefix : prefix, { params });
// 스팸 메일 목록 조회
const getSpamMailList = params => http.get(`${spamPrefix}/list`, { params });

// references mail 목록 조회
const getReferencesMailList = params =>
  http.get(`${prefix}/references`, { params });
// ---------------------------------------------------------------------------//
// 중요표시 변경
const updateImportantStatus = (isShare, mails = [], shareInfo) => {
  const formData = new FormData();
  formData.append("importantStatus", mails[0].nextImportantStatus);
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));
  if (isShare) {
    formData.append("folderId", shareInfo.folderId);
    formData.append("ownerId", shareInfo.ownerId);
  }

  return http.put(
    `${isShare ? sharePrefix : prefix}/importantStatus`,
    formData
  );
};
// 읽음상태 변경
const updateReadStatus = (isShare, mails = [], shareInfo) => {
  const formData = new FormData();
  formData.append("readStatus", mails[0].nextReadStatus);
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));
  if (isShare) {
    formData.append("folderId", shareInfo.folderId);
    formData.append("ownerId", shareInfo.ownerId);
  }

  return http.put(`${isShare ? sharePrefix : prefix}/readStatus`, formData);
};
// 모두 읽음 변경
const updateAllRead = (isShare, folderId, shareInfo) => {
  if (isShare) {
    const formData = new FormData();
    formData.append("ownerId", shareInfo.ownerId);
    return http.put(`${sharePrefix}/${shareInfo.folderId}/read`, formData);
  }

  return http.put(`${prefix}/${folderId}/read`);
};
// ---------------------------------------------------------------------------//
// 메일보기
const getMailView = (isShare, mailId, params) =>
  http.get(`${isShare ? sharePrefix : prefix}/view/${mailId}`, { params });

//eml 파일 보기
const viewEmlFile = (isShare, mailId, position, params) => {
  if (!isShare) return http.get(`${prefix}/view/${mailId}/attach/${position}`);
  return http.get(`${sharePrefix}/view/${mailId}/attach/${position}`, {
    params
  });
};

// 캘린더메일 데이터 조회
const getCalendarDataInMailView = params =>
  http.get(`${prefix}/view/calendar`, { params });

// 메일 이동
const updateFolderId = (mails = [], targetFolderId) => {
  const formData = new FormData();
  formData.append("targetFolderId", targetFolderId);
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));

  return http.put(`${prefix}/folderId`, formData);
};

// 메일 전체 이동
const updateAllFolderId = (targetFolderId, prevFolderId) =>
  http.put(`${prefix}/folderId/${targetFolderId}/${prevFolderId}`);

// 메일 완전삭제
const updateDeleteStatus = (mails = [], folderId) => {
  const formData = new FormData();
  formData.append("folderId", folderId);
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));

  return http.put(`${prefix}/deleteStatus`, formData);
};

// 휴지통 비우기
const cleanTrash = trashId => http.put(`${prefix}/trash/${trashId}/clean`);

// 첨부파일 삭제
const removeAttach = params => {
  const { mailId, position, isShare } = params;
  if (!isShare) {
    return http.delete(`${prefix}/attach/${mailId}/${position}`);
  }

  return http.delete(`${sharePrefix}/attach/${mailId}/${position}`, { params });
};

// 태그 추가, 삭제
const updateTags = (mails, type, tag) => {
  const formData = new FormData();
  formData.append("type", type);
  formData.append("tag", tag);
  mails.forEach(({ mailId }) => formData.append("mailIds", mailId));

  return http.put(`${prefix}/tags`, formData);
};

// 발송예약 취소
const deleteMailReserve = mailId => http.delete(`${prefix}/reserve/${mailId}`);

// 메일 복구
const mailRestore = params => {
  const formData = new FormData();
  formData.append("part", params.part);
  formData.append("folderId", params.folderId);

  return http.post(`${prefix}/import`, formData);
};

// 스팸신고 메일 보내기
const reportMail = ({ mailId, reportContents }) => {
  const formData = new FormData();
  formData.append("mailId", mailId);
  formData.append("reportContents", reportContents);

  return http.post(`${prefix}/report`, formData);
};

// 메일 차단 해제
const unblockMail = mailId => http.patch(`${prefix}/${mailId}/unblock`);

// 해당 메일 주소 수신 거부
const unsubscribeSender = sender => {
  const formData = new FormData();
  formData.append("sender", sender);

  return http.post(`${spamPrefix}/unsubscribe`, formData);
};

// 차단 메일 수신허용
const allowSender = ids => {
  const formData = new FormData();
  formData.append("ids", ids);

  return http.post(`${spamPrefix}/allow`, formData);
};

// 메일 차단사유
const getBlockDetail = id => http.get(`${spamPrefix}/block/detail/${id}`);

// 차단 메일 복구
const recoveryMail = ({ id, fromEmail, subject }) => {
  const formData = new FormData();
  formData.append("fromEmail", fromEmail);
  formData.append("subject", subject);

  return http.post(`${spamPrefix}/recovery/${id}`, formData);
};

// 메일 원본 보기
const getViewOriginal = (isShare, mailId, attachPosition, params) => {
  if (!isShare) {
    return http.get(`${prefix}/view/original/${mailId}/${attachPosition}`);
  }

  return http.get(`${sharePrefix}/view/original/${mailId}/${attachPosition}`, {
    params
  });
};

export {
  getMailList,
  getSharedFolders,
  getSharingFolders,
  getMainMailList,
  getSpamMailList,
  getReferencesMailList,
  getMailView,
  getViewOriginal,
  updateImportantStatus,
  updateReadStatus,
  updateAllRead,
  updateFolderId,
  updateAllFolderId,
  updateDeleteStatus,
  cleanTrash,
  removeAttach,
  viewEmlFile,
  searchMail,
  updateTags,
  getCalendarDataInMailView,
  deleteMailReserve,
  mailRestore,
  reportMail,
  unblockMail,
  unsubscribeSender,
  allowSender,
  recoveryMail,
  getBlockDetail
};
