import {
  listDefaultAction,
  viewDefaultAction,
  writeDefaultAction
} from "@/mail/store/base/baseRoute";
import { virtualFolderConverter } from "@/mail/constant/folderType";
import i18n from "@/_locales";
import router from "@/commons/router";
import Vuetify from "@/commons/plugins/vuetify";

const root = { root: true };
const state = {
  // tab 클릭시 mail list 로딩 X
  isTab: false,
  mail_list: {
    folderId: -1,
    page: 1,
    actionObj: listDefaultAction
  },
  mail_view: {
    folderId: -1,
    page: 1,
    actionObj: viewDefaultAction
  },
  mail_write: {
    folderId: -1,
    page: 1,
    actionObj: writeDefaultAction
  },
  mail_config: {
    folderId: -1,
    page: 1,
    tabId: ""
  }
};

const getters = {
  getRouteListInfo: ({ mail_list }) => ({
    ...mail_list,
    actionObj: JSON.parse(mail_list.actionObj)
  }),
  getRouteShareInfo: ({ mail_list }) => ({
    folderId: parseInt(JSON.parse(mail_list.actionObj).sfi),
    ownerId: parseInt(JSON.parse(mail_list.actionObj).owi)
  }),
  getRouteViewInfo: ({ mail_view }) => ({
    ...mail_view,
    actionObj: JSON.parse(mail_view.actionObj)
  }),
  getRouteWriteInfo: ({ mail_write }) => ({
    ...mail_write,
    actionObj: JSON.parse(mail_write.actionObj)
  }),
  getRouteConfigInfo: ({ mail_config }) => mail_config,
  getUnreadChecked: ({ mail_list }) => {
    if (!mail_list.actionObj) return false;

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { readStatuses = [] } = parsedObj;
    return readStatuses.findIndex(v => v == 0) !== -1;
  },
  getReadChecked: ({ mail_list }) => {
    if (!mail_list.actionObj) return false;

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { readStatuses = [] } = parsedObj;
    return readStatuses.findIndex(v => v == 1) !== -1;
  },
  getIncludesAttachChecked: ({ mail_list }) => {
    if (!mail_list.actionObj) return false;

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { includesAttach = -1 } = parsedObj;
    return includesAttach == 1;
  },
  getImportantStatusesChecked: ({ mail_list }) => value => {
    if (!mail_list.actionObj) return false;

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { importantStatuses = [] } = parsedObj;
    if (importantStatuses.length == 5) return true;

    return importantStatuses.findIndex(v => v == value) !== -1;
  },
  getSort: ({ mail_list }) => {
    if (!mail_list.actionObj) return "receivedTimeMillis";

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { sort = "receivedTimeMillis" } = parsedObj;

    return sort;
  },
  getDir: ({ mail_list }) => {
    if (!mail_list.actionObj) return "desc";

    const parsedObj = JSON.parse(mail_list.actionObj);
    const { dir = "desc" } = parsedObj;

    return dir;
  }
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    Object.keys(params).forEach(key => (state[key] = params[key]));
  },
  SET_IS_TAB: (state, isTab) => {
    state.isTab = isTab;
  },
  RESET_ROUTE_VIEW: state => {
    const { list } = JSON.parse(state.mail_view.actionObj);
    state.mail_view.actionObj = JSON.stringify({ list, view: {} });
  }
};

const actions = {
  async setParams(
    { commit, dispatch, state, rootState, rootGetters },
    {
      routeName,
      folderId = "all",
      page = 1,
      tabId, // 환경설정 tabId
      actionObj = listDefaultAction
    }
  ) {
    let mailListSkip = !rootState.mail.loading;
    const routeParams = {
      mail_list: {
        folderId: state.mail_list.folderId,
        page: state.mail_list.page,
        actionObj: state.mail_list.actionObj
      },
      mail_view: {
        folderId: state.mail_view.folderId,
        page: state.mail_view.page,
        actionObj: state.mail_view.actionObj
      },
      mail_write: {
        folderId: state.mail_write.folderId,
        page: state.mail_write.page,
        actionObj: state.mail_write.actionObj
      },
      mail_config: {
        folderId: state.mail_config.folderId,
        page: state.mail_config.page,
        tabId: state.mail_config.tabId
      }
    };

    // router params에 folderId
    let convertedFolderId = virtualFolderConverter(folderId);
    // 최초 메일 설정값 호출
    if (!rootState.mailConfig.init) {
      await dispatch("mailConfig/getMailConfig", null, root);
      await dispatch("serviceConfig/loadConfig", "MAIL", root);
    }

    // 라우팅시 다이얼로그 닫기 여부
    let closedDialog = true;
    // 새로 들어온 값은 여기서 할당
    switch (routeName) {
      case "mail":
        // routeName이 mail이면 메일 첫 화면 설정값으로
        convertedFolderId = rootState.mailConfig.firstScreenFolderId;
      // eslint-disable-next-line no-fallthrough
      case "mail_list":
      case "mail_list_action": {
        mailListSkip = false;
        routeParams.mail_list["folderId"] = convertedFolderId;
        routeParams.mail_list["page"] = page;
        routeParams.mail_list["actionObj"] = actionObj;

        // 상세 검색일 경우 상세검색오픈
        const { showSearchDetail } = rootState.mailLayout;
        const { type } = JSON.parse(actionObj)?.search || {};
        let show = type === "detail";
        if (`${convertedFolderId}`?.startsWith("t_")) show = false;
        if (showSearchDetail) show = true;

        await commit("mailLayout/TOGGLE_SHOW_SEARCH_DETAIL", show, root);
        break;
      }
      case "mail_config": {
        routeParams.mail_list["folderId"] = convertedFolderId;
        routeParams.mail_list["page"] = page;
        /**
         * TODO:: 메일쓰기, 설정도 메일보기처럼 list 에 대한 파라미터 가지고있어야함
         * 선택된 폴더가 태그일때 임시처리
         */
        if (`${convertedFolderId}`?.startsWith("t_")) {
          const _tag = rootGetters["folder/getFolder"](convertedFolderId);
          routeParams.mail_list["folderId"] = !_tag ? -1 : convertedFolderId;
          routeParams.mail_list["actionObj"] = JSON.stringify({
            ...JSON.parse(routeParams.mail_list["actionObj"]),
            search: {
              type: !_tag ? "detail" : "all",
              tags: convertedFolderId.replace("t_", ""),
              useSearchHistory: false
            }
          });
        }

        if (!mailListSkip) {
          // url로 바로 들어온 경우
          routeParams.mail_list["folderId"] = convertedFolderId;
        }

        routeParams.mail_config["folderId"] = convertedFolderId;
        routeParams.mail_config["page"] = page;
        routeParams.mail_config["tabId"] = tabId;
        break;
      }
      case "popup_mail_view":
      case "mail_view": {
        routeParams.mail_view["folderId"] = convertedFolderId;
        routeParams.mail_view["page"] = page;
        routeParams.mail_view["actionObj"] = actionObj;

        if (!state.isTab) {
          const { list } = JSON.parse(actionObj);

          // 기존 state에 list 파라미터 값들과 현재 라우팅 파라미터의 값을 비교하여 다를 경우 메일목록 불러온다.
          if (state.mail_list.folderId != convertedFolderId) {
            routeParams.mail_list["folderId"] = convertedFolderId;
            mailListSkip = false;
          }

          if (state.mail_list.page != page) {
            routeParams.mail_list["page"] = page;
            mailListSkip = false;
          }

          if (JSON.stringify(list) !== state.mail_list.actionObj) {
            routeParams.mail_list["actionObj"] = JSON.stringify(list);
            mailListSkip = false;
          }
        }

        if (routeName === "popup_mail_view") mailListSkip = true;
        break;
      }
      case "popup_mail_write":
        mailListSkip = true;
      // eslint-disable-next-line no-fallthrough
      case "mail_write": {
        /**
         * TODO:: mail_view처럼 메일목록 처리 해줘야함
         */
        dispatch("auth/checkSystem", "domain", { root: true });
        dispatch("auth/checkSystem", "quota", { root: true });

        /**
         * TODO:: 메일쓰기, 설정도 메일보기처럼 list 에 대한 파라미터 가지고있어야함
         * 선택된 폴더가 태그일때 임시처리
         */
        if (`${convertedFolderId}`?.startsWith("t_")) {
          const _tag = rootGetters["folder/getFolder"](convertedFolderId);
          routeParams.mail_list["folderId"] = !_tag ? -1 : convertedFolderId;
          routeParams.mail_list["actionObj"] = JSON.stringify({
            ...JSON.parse(routeParams.mail_list["actionObj"]),
            search: {
              type: !_tag ? "detail" : "all",
              tags: convertedFolderId.replace("t_", ""),
              useSearchHistory: false
            }
          });
        }

        routeParams.mail_write["folderId"] = convertedFolderId;
        routeParams.mail_write["page"] = page;
        routeParams.mail_write["actionObj"] =
          actionObj == listDefaultAction ? writeDefaultAction : actionObj;

        if (!mailListSkip) {
          // url로 바로 들어온 경우
          routeParams.mail_list["folderId"] = convertedFolderId;
        }

        // 다이얼로그 닫기 예외처리
        const { type: dialogType } = rootState.mailDialog;
        if (["recipient", "driveAttach", "previewMail"].includes(dialogType)) {
          closedDialog = false;
        }

        break;
      }
      default: {
        break;
      }
    }

    // 다이얼로그 닫기
    if (closedDialog) commit("mailDialog/CLOSE_DIALOG", null, root);

    // 최초 폴더 호출
    if (
      (rootState.folder.folders.length == 0 || routeName == "mail") &&
      !(await dispatch("folder/getDefaultFolders", null, root))
    ) {
      return;
    }

    // 폴더의 존재 여부 검사, 없을 경우 최초 폴더로 라우팅
    const { folderId: mailListFolderId } = routeParams.mail_list;
    const folder = rootGetters["folder/getFolder"](mailListFolderId);
    if (!folder) return router.push({ name: "mail" });

    // 외부메일 정보 가져오기
    if (!rootState.mailConfigExternal.init) {
      await dispatch("mailConfigExternal/getExternalList", null, root);
    }

    // 내가 속한 그룹 계정 가져오기
    if (!rootState.mailAlias.init) {
      await dispatch("mailAlias/getMyAlias", null, root);
    }

    //  메일 리스트 호출
    if (!mailListSkip && !state.isTab) {
      await dispatch("mail/getList", routeParams.mail_list, root);

      // 좌우 분할 보기시
      if (
        !Vuetify.framework.breakpoint.xs &&
        rootState.mailConfig.splitView == 1
      ) {
        // 라우팅될때 첫번째 메일 보여주기 적용
        const parsedObj = JSON.parse(routeParams.mail_view["actionObj"]);
        let view = parsedObj.view;

        if (
          rootState.mailConfig.showFirstMail == 1 &&
          folder.folderType != "DRAFTS"
        ) {
          const [firstMail] = rootState.mail.mails;
          if (firstMail) {
            view = {
              basedId: firstMail.mailId,
              selectedDataId: firstMail.dataId
            };
          } else {
            view = {};
            commit("mailView/RESET_VIEW_STATE", null, { root: true });
          }
        } else if (
          routeName != "mail_view" &&
          rootState.mailConfig.showFirstMail == 0
        ) {
          view = {};
        }

        routeParams.mail_view["actionObj"] = JSON.stringify({
          ...parsedObj,
          view
        });
      }
    }

    // 메일 목록 라우트 이동시 메일 목록 스크롤 초기화
    if (["mail", "mail_list", "mail_list_action"].includes(routeName)) {
      const selector = ".v-data-table.cr-mail-list>.v-data-table__wrapper";
      const scrollEl = document.querySelector(selector);
      if (scrollEl && scrollEl.scrollTop) scrollEl.scrollTop = 0;
    }

    // isTab 초기화
    if (state.isTab) commit("SET_IS_TAB", false);
    await commit("SET_ROUTE_PARAMS", routeParams);
  },
  /**
   * 태그 클릭시 라우팅
   */
  routeTag({ rootGetters, dispatch }, tag) {
    if (!tag.startsWith("t_")) return;

    const folder = rootGetters["folder/getFolder"](tag);
    dispatch("routeMailList", {
      name: "mail_list_action",
      page: 1,
      folderId: !folder ? -1 : tag,
      actionObj: {
        search: {
          type: !folder ? "detail" : "all",
          tags: tag.replace("t_", ""),
          useSearchHistory: false
        }
      }
    });
  },
  /**
   * 메일목록 redirect
   */
  redirectMailList({ state, dispatch }) {
    const { folderId, page, actionObj } = state.mail_list;
    const params = { name: "mail_list", page, folderId };
    if (actionObj != listDefaultAction) {
      params.name = "mail_list_action";
      params.actionObj = JSON.parse(actionObj);
    }

    dispatch("routeMailList", params);
  },
  /**
   * 메일목록 라우팅
   *
   * @param {Object} params -- { name(default: mail_list), folderId, page, actionObj }
   * @param {Object} parmas.actionObj -- { sfi: sahreFolderId, owi: ownerId, sp: sharePermission }
   */
  routeMailList({ state }, params) {
    // 팝업창에서 라우트시 부모창 호출
    if (router.currentRoute.name.includes("popup_mail_")) {
      try {
        window.opener?.vxd("mailRoute/routeMailList", params, root);
      } catch (e) {
        console.error(e);
      }
      return;
    }

    const {
      folderId: stateFolderId,
      page: statePage,
      actionObj: stateActionObj
    } = state.mail_list;
    const listParams = {
      folderId: virtualFolderConverter(params?.folderId || stateFolderId),
      page: params?.page || statePage
    };
    let name = "mail_list";
    if (params?.name == "mail_list_action") {
      name = "mail_list_action";
      let actionObj = JSON.parse(stateActionObj);
      actionObj = { ...actionObj, ...(params?.actionObj || {}) };
      listParams["actionObj"] = JSON.stringify(actionObj);
    }

    router.push({ name, params: listParams });
  },
  /**
   * 메일보기 라우팅
   *
   */
  routeMailView({ state, rootState }, params) {
    const folderId = params?.folderId || state.mail_list.folderId;
    const page = params?.page || state.mail_list.page;
    const actionObj = {
      list: JSON.parse(state.mail_list.actionObj),
      view: {
        basedId: params.basedId,
        selectedDataId: params.selectedDataId,
        printable: params.printable,
        attachPosition: params.attachPosition
      }
    };

    if (
      (rootState.mailConfig.splitView == 0 &&
        rootState.mailConfig.popupRead == 1 &&
        !state.isTab) ||
      router.currentRoute.name.includes("popup_mail_") ||
      params.printable ||
      params.openPopup
    ) {
      const { basedId, printable, attachPosition = -1 } = params;
      window.open(
        `/#/mail/view/popup/${JSON.stringify(actionObj)}`,
        printable ? "" : `mailview-${basedId}-${attachPosition}`,
        "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
      );
    } else {
      router.push({
        name: "mail_view",
        params: {
          folderId: virtualFolderConverter(folderId),
          page,
          actionObj: JSON.stringify(actionObj)
        }
      });
    }
  },
  /**
   *
   * @param {*} params -- folderId, page, tabId
   */
  routeMailConfig({ state }, { folderId, page, tabId }) {
    router.push({
      name: "mail_config",
      params: {
        folderId: virtualFolderConverter(
          folderId || state.mail_config.folderId
        ),
        page: page || state.mail_config.page,
        tabId: tabId || "list"
      }
    });
  },
  /**
   *
   * 메일쓰기 라우팅
   * @param {Number} t              -- 메일쓰기 타입 1:답장 2:전달 3:전체답장 4:임시저장 5:다시보내기 6: 내게쓰기 7:주소록에서 메일쓰기 8: 드라이브에서 메일쓰기 9: 외부드라이브에서 메일쓰기
   * @param {Number} i              -- id값 (mailId 등)
   * @param {Array} is              -- id목록 (mailIds, contactIds 등)
   * @param {Array} to/cc/bcc       -- 수신자 목록
   * @param {Boolean} openPopup     -- 새창으로 띄울지의 여부
   * @param {Boolean} cp            -- 내용 부분에 삽입할 파라미터
   */
  routeMailWrite(actions, params) {
    const { state, rootState, rootGetters, dispatch } = actions;

    let answer = true;
    let name = "mail_write";
    const type = parseInt(params.t) ? parseInt(params.t) : 0;
    const { name: currentRouteName } = router.currentRoute;
    const { isTab, mail_list, mail_write } = state;
    const { mailConfig, mailCompose } = rootState;
    let { useTab, popupWrite } = mailConfig;
    // xs 에선 탭사용 X
    if (Vuetify.framework.breakpoint.xs) useTab = 0;
    const stringifyObj = JSON.stringify({
      t: type, // type
      cp: params.cp ? params.cp.join(",") : undefined, // content parameter
      i: parseInt(params.i) ? parseInt(params.i) : 0, // id
      is: Array.isArray(params.is) ? params.is.join(",") : undefined, // ids
      to: Array.isArray(params.to) ? params.to.join(",") : undefined,
      cc: Array.isArray(params.cc) ? params.cc.join(",") : undefined,
      bcc: Array.isArray(params.bcc) ? params.bcc.join(",") : undefined
    });

    // 메일쓰기 팝업 열기
    if (
      params.openPopup || // 새창에서 메일쓰기
      type >= 7 || // 타 모듈에서 메일쓰기
      (popupWrite == 1 && !isTab) || // 새창으로 메일쓰기 설정
      currentRouteName.includes("popup_mail_")
    ) {
      name = "popup_mail_write";

      if (currentRouteName !== "popup_mail_write") {
        return window.open(
          `/#/mail/write/popup/${stringifyObj}`,
          "",
          "width=1050,height=870,location=no,directories=no,resizable=no,status=no,toolbar=yes,menubar=no,scrollbars=yes"
        );
      }
    }

    if (
      !isTab && // 탭 이동이 아닐때
      !params.draftSave && // 임시저장해서 라우팅하는게 아닐때
      !mailCompose.showSendResult && // 결과창이 아니고
      ((useTab && rootGetters["mailTab/existWriteTab"]) || // 탭 사용 && 메일쓰기 탭 열린상태
      (!useTab && currentRouteName.includes("mail_write")) || // 탭 사용 X && 메일쓰기 화면
        currentRouteName == "popup_mail_write") // 메일쓰기 팝업일때
    ) {
      // 메일쓰기 새로열기 여부
      answer = window.confirm(i18n.t("mail.390"));
    }

    let { folderId, page } = mail_list;
    let { actionObj: aObj } = mail_write;
    folderId = virtualFolderConverter(folderId);

    // 새로 안열때
    if ((!answer || isTab) && !params.draftSave) {
      return router.push({ name, params: { folderId, page, actionObj: aObj } });
    }

    router.push({ name, params: { folderId, page, actionObj: stringifyObj } });
    // 임시저장이면 다시불러오기 X
    if (params.draftSave) return;
    dispatch("mailCompose/initCompose", null, { root: true });
  },
  /**
   * 새편지 알림
   *
   * @param state
   * @param dispatch
   * @param routeName
   * @param data
   */
  async newMailNotification({ state, dispatch }, { routeName, data }) {
    // 메일 페이지
    if (routeName.includes("mail")) {
      // 편지함 갱신
      await dispatch("folder/getDefaultFolders", null, { root: true });

      /*
       * 형식 검사를 엄격히 하면 안된다
       * 서버에서 내려준 값과 state 에 저장된 값을 다르게 판단함
       * - state.folder = "1", data.folderId = 1
       */
      if (
        state.mail_list.folderId == -1 ||
        state.mail_list.folderId == data.folderId
      ) {
        await dispatch(
          "mail/getList",
          {
            folderId: state.mail_list.folderId,
            page: state.mail_list.page,
            actionObj: state.mail_list.actionObj
          },
          { root: true }
        );
      }
    }

    // 알림
    const params = {
      page: 0,
      folderId: data.folderId,
      basedId: data.mailId,
      selectedDataId: data.mailId
    };

    dispatch(
      "notification/showNotification",
      {
        title: i18n.t("mail.568"),
        body: data.subject,
        click: async () => {
          if (!routeName.includes("mail")) {
            // 메일 외의 페이지에서 클릭한 경우
            await dispatch("routeMailList");
          }
          await dispatch("mailRoute/routeMailView", params, { root: true });
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
