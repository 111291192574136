const storage = {
  setUsername: username => localStorage.setItem("username", username),
  getUsername: () => localStorage.getItem("username"),

  setAccessToken: token => localStorage.setItem("access_token", token),
  setRefreshToken: token => localStorage.setItem("refresh_token", token),

  getAccessToken: () => localStorage.getItem("access_token"),
  getRefreshToken: () => localStorage.getItem("refresh_token"),

  clearToken: () => {
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
  },

  setRightAndLeftSplitWidth: width =>
    localStorage.setItem("rightAndLeft#list-width", width),
  setUpAndDowmSplitHeight: height =>
    localStorage.setItem("upAndDown#list-height", height),

  getRightAndLeftSplitWidth: () =>
    localStorage.getItem("rightAndLeft#list-width"),
  getUpAndDowmSplitHeight: () => localStorage.getItem("upAndDown#list-height"),

  setMailWriteForm: form => localStorage.setItem("mail_write_form", form),
  getMailWriteForm: () => localStorage.getItem("mail_write_form"),

  setAnnounceTime: timeMillis =>
    localStorage.setItem("announce_timeMillis", timeMillis),
  getAnnounceTime: () => localStorage.getItem("announce_timeMillis"),
  clearAnnounceTime: () => localStorage.removeItem("announce_timeMillis"),

  setTodoWorkspaceId: workspaceId =>
    localStorage.setItem("todo_workspace_id", workspaceId),
  getTodoWorkspaceId: () => localStorage.getItem("todo_workspace_id"),

  setMessengerStyle: style => localStorage.setItem("messenger_style", style),
  getMessengerStyle: () => localStorage.getItem("messenger_style"),

  setSidePanelOpen: open => localStorage.setItem("sidePanelOpen", open),
  getSidePanelOpen: () => localStorage.getItem("sidePanelOpen"),

  setEventDialogDetail: state =>
    localStorage.setItem("event_dialog_detail_state", state),
  getEventDialogDetail: () => localStorage.getItem("event_dialog_detail_state")
};

export default storage;
