<template>
  <div
    class="d-flex align-center justify-center"
    style="height:100%; width:100%;"
  >
    <v-progress-circular
      color="primary"
      indeterminate
      size="120"
      width="15"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  async created() {
    this.setShowSplash(false);
    const { params, query } = this.$route;
    const { type } = params;

    switch (type) {
      case "google":
        {
          const { code, error } = query;
          if (error || !code) return window.close();
          await this.saveToken({ code, type: "GOOGLE_DRIVE" });
        }
        break;
      default:
        break;
    }
    window.close();
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    ...mapActions("driveExternalToken", ["saveToken"])
  }
};
</script>
