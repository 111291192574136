<template>
  <v-btn
    text
    class="pa-0"
    height="40"
    style="display:block;"
    :ripple="false"
    v-on="$listeners"
  >
    <v-text-field
      outlined
      dense
      hide-details
      v-model="workspaceTitle"
      :append-icon="openMenu ? 'mdi-menu-up' : 'mdi-menu-down'"
    />
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn:before {
  opacity: 0 !important;
}
// 셀렉트처럼 보이는 부분
.v-input.v-text-field ::v-deep {
  pointer-events: none;

  .v-input__append-inner {
    .v-input__icon.v-input__icon--append {
      .v-icon {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  .v-input__control .v-input__slot {
    fieldset {
      top: 0px;
      legend {
        display: none;
      }
    }

    .v-text-field__slot {
      input {
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    openMenu: {
      default: false,
      type: Boolean
    },
    workspaceTitle: {
      default: "",
      type: String
    }
  }
};
</script>
