<template>
  <v-dialog
    scrollable
    no-click-animation
    max-width="400px"
    width="400px"
    :value="value"
    @input="val => $emit('input', val)"
  >
    <v-card max-height="400">
      <v-card-title>
        <span
          class="text-h6 font-weight-bold"
          v-text="$t('calendar.77')"
        ></span>
      </v-card-title>
      <v-card-text v-if="!loaded" class="d-flex pt-5 align-center">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-text>
      <v-card-text v-else class="pb-0">
        <PublicCalendarList
          v-if="companyCalendars.length"
          :title="$t('calendar.사내_캘린더')"
          :calendars="companyCalendars"
          @click-item="changeSubscription"
        />
        <PublicCalendarList
          v-if="holidayCalendars.length"
          :title="$t('calendar.공휴일_캘린더')"
          :calendars="holidayCalendars"
          @click-item="changeSubscription"
        />
        <v-list
          dense
          v-if="!companyCalendars.length && !holidayCalendars.length"
          v-text="$t('calendar.78')"
        ></v-list>
      </v-card-text>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text outlined @click="$emit('input', false)">
          {{ $t("common.닫기") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";
import {
  searchHolidayCalendars,
  searchPublicCalendarList,
  subscriptCalendar
} from "@/calendar/api/cal.api";
import PublicCalendarList from "@/calendar/components/calendar/PublicCalendarList.vue";

export default {
  components: { PublicCalendarList },
  props: ["value"],
  data() {
    return {
      loaded: false,
      companyCalendars: [],
      holidayCalendars: []
    };
  },
  computed: {
    ...mapGetters("cal", ["getSubscribedCalendars"])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("cal", ["syncSirTeamData", "cancelSubscription"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    async init() {
      await this.refreshCompanyCalendars();
      await this.refreshHolidayCalendars();
      this.loaded = true;
    },
    async refreshCompanyCalendars() {
      const params = {
        page: 0,
        pageSize: 20
      };
      const { data } = await searchPublicCalendarList(params);
      if (data) {
        this.checkSubscribedCalendar(data.content);
        this.companyCalendars = data.content;
      }
    },
    async refreshHolidayCalendars() {
      const { data } = await searchHolidayCalendars();
      if (data) {
        this.checkSubscribedCalendar(data);
        // 공휴일 캘린더 제목은 'ko.south_korea' 형식이며 '.' 뒤의 영문 국가명을 이용하여 적절하게 변환합니다.
        // 여기서 변환한 캘린더 제목은 구독 시 생성되는 캘린더의 제목으로 사용됩니다.
        data.forEach(cal => {
          const country = cal.title.substring(cal.title.indexOf(".") + 1);
          const translationCode = `calendar.공휴일.${country}`;
          if (this.$te(translationCode)) {
            cal.title = this.$t(translationCode);
          }
        });
        this.holidayCalendars = data;
      }
    },
    checkSubscribedCalendar(calendars) {
      calendars.forEach(cal => {
        cal.isSubscribed = this.getSubscribedCalendars.some(
          subscribedCal => subscribedCal.targetCalendarId === cal.id
        );
      });
    },
    changeSubscription(calendar) {
      // 하위 컴포넌트에서 이미 값이 변경됨에 유의합니다.
      if (calendar.isSubscribed) {
        this.subscribe(calendar);
      } else {
        this.unsubscribe(calendar);
      }
    },
    async subscribe(calendar) {
      const params = {
        targetCalendarId: calendar.id,
        title: calendar.title,
        calendarType: "PUBLIC",
        privilegeType: "read-only"
      };

      const result = !(await subscriptCalendar(params)).response;
      if (result) {
        await this.syncSirTeamData();

        this.openSnackbar({
          message: i18n.t("calendar.76", { value: calendar.title }),
          type: "SUCCESS"
        });
      } else {
        this.openSnackbar({
          message: i18n.t("calendar.75"),
          type: "ERROR"
        });
      }
    },
    async unsubscribe(calendar) {
      const subscriptionProxyCalendar = this.getSubscribedCalendars.find(
        cal => cal.targetCalendarId === calendar.id
      );
      const result = await this.cancelSubscription(subscriptionProxyCalendar);

      if (result) {
        this.openSnackbar({
          message: i18n.t("calendar.74", {
            value: subscriptionProxyCalendar.title
          }),
          type: "SUCCESS"
        });
      } else {
        this.openSnackbar({
          message: i18n.t("calendar.75"),
          type: "ERROR"
        });
      }
      return result;
    }
  }
};
</script>
