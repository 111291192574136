<template>
  <v-chip-group
    column
    :active-class="!fixedHeight ? 'primary--text' : ''"
    v-model="select"
  >
    <v-chip
      label
      small
      close
      class="mr-1 mb-1 cr-tag-chip"
      :class="{ 'cr-fixed': fixedHeight }"
      v-for="{ tag, color } in chips"
      :key="tag"
      :color="color"
      :text-color="invertColor(color)"
      @click.stop="click(tag)"
      @click:close="deleteClick(tag)"
    >
      <span>{{ tag }}</span>
      <span v-show="fixedHeight && viewTags.length > 1">
        외 {{ viewTags.length - 1 }}개
      </span>
    </v-chip>
  </v-chip-group>
</template>

<style lang="scss" scoped>
/* 태그 스타일 */
.v-chip.cr-tag-chip {
  margin: 0px;
  border-radius: 2px !important;
  padding: 3px 5px 2px 5px;
  font-size: 12px;
  line-height: 1;
  height: auto;
  border: 2px solid black;
  border-color: transparent;
  /* 선택됨 */
  &.v-chip--active.primary--text {
    color: #fff !important;
    caret-color: #fff !important;
    background-color: var(--v-primary-lighten2) !important;
    border-color: var(--v-primary-darken1) !important;
  }
  /* 삭제 아이콘 */
  ::v-deep .v-chip__close {
    font-size: 14px !important;
    max-height: 16px;
    height: 16px;
    max-width: 24px;
    width: 24px;
    margin-left: 0px !important;
    // X 폰트
    &::before {
      content: "\F0156";
    }
    &:hover {
      &::before {
        content: "\F1398";
        font-size: 16px;
      }
    }
  }

  &.cr-fixed::v-deep {
    max-width: 50%;
    .v-chip__content {
      display: flex;
      line-height: 2;

      span:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span:last-child {
        flex-shrink: 0;
        padding-left: 5px;
      }
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";

export default {
  props: {
    link: {
      type: Boolean,
      default: false
    },
    fixedHeight: {
      type: Boolean,
      default: false
    },
    chipSelect: {
      type: Number,
      default: undefined
    },
    myTags: {
      type: Array,
      default: () => []
    },
    viewTags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    select: {
      get() {
        return this.chipSelect;
      },
      set(select) {
        if (this.fixedHeight) return;
        this.$emit("update:chipSelect", select);
      }
    },
    chips() {
      if (this.fixedHeight) {
        const [tag] = this.viewTags;
        return tag ? [{ tag, color: "#E0E0E0" }] : [];
      }

      return this.viewTags.map(tag => {
        const [t] = this.myTags.filter(t => t.tag === tag);
        return t || { tag, color: "#E0E0E0" };
      });
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeTag"]),
    invertColor(color) {
      return invertColor(color);
    },
    click(tag) {
      if (!this.link) return;
      this.routeTag(`t_${tag}`);
    },
    deleteClick(tag) {
      if (!this.fixedHeight) return this.$emit("deleteViewTag", tag);
      this.viewTags.forEach(tag => this.$emit("deleteViewTag", tag));
    }
  }
};
</script>
