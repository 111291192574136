import http from "@/commons/api/http";

const uploadFile = (file, uploadType, config) => {
  const formData = new FormData();
  formData.append("part", file);
  formData.append("uploadType", uploadType);

  return http.post("api/mail/file/upload", formData, config);
};

/**
 * 메일 백업 목록 조회
 */
const getBackupList = (folderId, params) =>
  http.get(`api/mail/download/backup/folder/${folderId}/info`, { params });

/**
 * 드라이브 파일 조회
 * @param {*} params 파일 ids
 * @returns
 */
const uploadFromDrive = ({
  fileId,
  uploadType,
  tempFileSize,
  largeTempFileSize
}) =>
  http.get(`api/mail/drive/upload/${fileId}`, {
    params: { uploadType, tempFileSize, largeTempFileSize }
  });

export { uploadFile, uploadFromDrive, getBackupList };
