<!-- 이벤트 목록 -->
<!-- @onSearch     / params: inputText -->
<!-- @toggleDetail / params: showDetail -->
<template>
  <div class="cr-search-wrapper">
    <v-menu
      max-width="450"
      offset-y
      :close-on-content-click="false"
      v-model="showDetail"
    >
      <template v-slot:activator="{ on: { click }, attrs }">
        <v-text-field
          color="dark"
          flat
          solo
          hide-details
          v-model="inputText"
          maxlength="50"
          ref="searchInputRef"
          class="cr-search-input"
          v-bind="attrs"
          background-color="#f1f1f3"
          placeholder="게시판 통합 검색"
          prepend-inner-icon="mdi-magnify"
          :append-icon="showDetail ? 'mdi-menu-up' : 'mdi-menu-down'"
          @click:append="click"
          @keydown="inputKeydown"
        />
      </template>
      <SearchDetail
        :showDetail.sync="showDetail"
        @closeDetail="closeDetail"
        v-on="$listeners"
      />
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchDetail from "./SearchDetail";
export default {
  components: { SearchDetail },
  props: {},
  mounted() {
    this.inputText = this.getKeyword;
  },
  data() {
    return {
      inputText: "",
      showDetail: false
    };
  },
  watch: {
    getKeyword(n) {
      this.inputText = n;
    }
  },
  computed: {
    ...mapGetters("boardSearch", ["getSearchParam"]),
    getKeyword() {
      return this.getSearchParam?.query?.keyword ?? "";
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    inputKeydown(e) {
      if (e.key === "Enter") {
        if (this.validation()) {
          this.$emit("onSearch", this.inputText);
        }
      }
    },
    closeDetail() {
      this.showDetail = false;
    },
    validation() {
      // 앞뒤 공백 제거
      this.inputText = this.inputText.trim();
      // 글자 수 검증
      if (this.inputText.length < 1) {
        this.openSnackbar({
          message: "최소 한 글자 이상 입력해야 합니다.",
          type: "VALIDATION"
        });
        return false;
      }
      if (this.inputText.length > 50) {
        this.openSnackbar({
          message: "최대 50 글자까지 입력 가능합니다.",
          type: "VALIDATION"
        });
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-search-wrapper ::v-deep {
  display: flex;
  flex: 1 1 auto;
  width: 400px;
  max-width: 400px;

  .v-input.v-text-field.cr-search-input {
    .cr-detail-btn {
      padding: 0px;
    }
  }
}
</style>
