import http from "./http";
/**
 * 해당 서비스 환경설정 조회
 * @param {*} moduleName 모듈
 * @returns
 */
const getServiceConfig = moduleName =>
  http.get(`api/company/config/${moduleName}`);

export { getServiceConfig };
