export const EMPTY_EVENT = {
  eventUId: "",
  iCalUID: "",
  summary: null,
  description: null,
  location: null,
  sequence: 0,
  priority: 0,
  isPublic: true,
  allDay: false,
  transp: "OPAQUE",
  tzId: null,
  startDate: "",
  startTime: "00:00",
  endDate: "",
  endTime: "00:00"
};

export const DEFAULT_ALARM = {
  action: "EMAIL",
  time: 10,
  duration: "M"
};
