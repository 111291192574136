<template>
  <div class="d-flex align-center justify-space-between">
    <v-card-subtitle class="subtitle-1 pb-0 font-weight-bold">
      {{ $t(isMyTags ? "common.74" : "") }}
    </v-card-subtitle>

    <TagInput v-if="!isMyTags" :search="search" @setSearch="setSearch" />
  </div>
</template>

<script>
import TagInput from "./TagInput.vue";

export default {
  components: { TagInput },
  props: {
    isMyTags: {
      type: Boolean,
      default: false
    },
    search: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setSearch(search) {
      this.$emit("update:search", search);
    }
  }
};
</script>
