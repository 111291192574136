<template>
  <v-list dense class="pt-1 pb-0">
    <!-- 참석자 목록 -->
    <v-hover
      #default="{ hover }"
      :key="attendee.name ? attendee.name : attendee.email"
      v-for="attendee in value"
    >
      <v-list-item
        link
        class="px-1"
        :ripple="false"
        style="min-height: 32px"
        @keyup.delete.stop="removeAttendee(attendee)"
      >
        <v-list-item-content class="py-1">
          <v-list-item-title
            class="d-flex align-center text-body-2 text-truncate"
            style="line-height: 1.25rem"
          >
            <v-icon class="mr-2">mdi-account-circle</v-icon>
            <span v-if="attendee.name && attendee.name.length" class="mr-1">
              {{ attendee.name }}
            </span>
            <v-chip
              small
              label
              color="grey lighten-3"
              class="px-2"
              style="height: 20px;"
            >
              {{ attendee.email }}
            </v-chip>
            <v-icon
              v-if="attendee.isOrganizer"
              dense
              color="yellow darken-1"
              class="ml-1"
            >
              mdi-star-circle
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="deletable && hover" class="ma-0">
          <v-btn small icon @click="removeAttendee(attendee)">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
  </v-list>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeAttendee(item) {
      if (!this.deletable) return;

      this.$emit(
        "input",
        this.value.filter(attendee => attendee.key !== item.key)
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
