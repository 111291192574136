<template>
  <div class="cr-tree-wrap" :class="{ mobile: $vuetify.breakpoint.mdAndDown }">
    <v-treeview
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      item-text="groupName"
      :items="getContactGroupList"
      :active.sync="activeItems"
      :open.sync="openItems"
      @update:active="onActive"
      :load-children="getPrivateContactGroupChildren"
    >
      <!-- 아이콘 -->
      <template v-slot:prepend="{ item, open }">
        <FolderIcon :item="item" :isOpen="open" :activeItems="activeItems" />
      </template>
      <!-- 그룹 이름 / 버튼 -->
      <template v-slot:label="{ item }">
        <TreeItem :item="item" @addOpenItem="addOpenItem" />
      </template>
    </v-treeview>

    <!-- 불러 오기 -->
    <v-divider class="mt-1" />
    <ImportMenu />
    <ExportMenu />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  rootContactGroupType,
  privateContactGroupType
} from "@/contact/constant/contactGroupType";
import TreeItem from "./TreeItem";
import FolderIcon from "./FolderIcon";
import ImportMenu from "./ImportMenu";
import ExportMenu from "./ExportMenu";

export default {
  components: { FolderIcon, TreeItem, ImportMenu, ExportMenu },
  data: () => ({
    activeItems: [],
    prevActiveItem: {}
  }),
  watch: {
    getContactGroupList() {
      // 트리 Open
      const { contactGroupId: routeContactGroupId } = this.getCardListInfo;
      let routeOpen = [];
      if (routeContactGroupId && routeContactGroupId > 0) {
        routeOpen = this.getFullPath(routeContactGroupId);
      }
      routeOpen.push({ id: -1 });
      this.openItems = [...this.getPrivateRoot, ...routeOpen];

      // 선택된 그룹 없는 경우
      const routeContactGroup = this.getContactGroup(routeContactGroupId);
      if (!routeContactGroup) return;

      this.activeItems = [{ id: routeContactGroupId }];
    },
    /*
     앞/뒤로 가기(Route)에 의해 그룹 선택이 변경될때
     검색 히스토리로 라우팅될때 전체 연락처 선택
     */
    getCardListInfo({ contactGroupId: routeContactGroupId }) {
      this.activeItems =
        routeContactGroupId === -4
          ? [{ id: -1 }]
          : [{ id: routeContactGroupId }];
    },
    getContactConfig(contactConfig) {
      if (contactConfig) {
        this.activeItems = [];
      }
    }
  },
  computed: {
    ...mapGetters("group", [
      "getOpenedGroup",
      "getContactGroup",
      "getFullPath",
      "getContactGroupList",
      "getAllRoot",
      "getPrivateRoot"
    ]),
    ...mapGetters("contactRoute", ["getCardListInfo", "getContactConfig"]),
    openItems: {
      get() {
        return this.getOpenedGroup;
      },
      set(value) {
        this.SET_OPENED_GROUP(value);
      }
    }
  },
  methods: {
    ...mapMutations("group", ["SET_OPENED_GROUP"]),
    ...mapActions("group", [
      "getContactGroupListByParentId",
      "updateIsEdit",
      "updateContactGroupName",
      "deleteContactGroup"
    ]),
    // 개인 주소록 그룹 하위 주소록 그룹 불러오기
    async getPrivateContactGroupChildren(item) {
      if (privateContactGroupType[item.contactGroupType]) {
        await this.getContactGroupListByParentId({ parentId: item.id });
      }
    },
    // 트리노트 선택
    onActive([n = {}]) {
      const { id, contactGroupType } = n;
      const { contactGroupType: prevContactGroupType } = this.prevActiveItem;

      if (this.getContactConfig && !rootContactGroupType[contactGroupType])
        return;

      if (
        !id &&
        prevContactGroupType &&
        !rootContactGroupType[prevContactGroupType]
      ) {
        // 단, 검색일 경우는 active해제
        if (this.getCardListInfo.contactGroupId == -4) return;
        return (this.activeItems = [this.prevActiveItem]);
      }

      if (rootContactGroupType[contactGroupType]) {
        const { length } = this.openItems.filter(
          item => item.contactGroupType == contactGroupType
        );
        if (length == 0) {
          this.openItems.push(n);
        } else {
          this.openItems = this.openItems.filter(
            item => item.contactGroupType != contactGroupType
          );
        }

        this.activeItems = [];
      } else {
        this.prevActiveItem = n;
      }
    },
    addOpenItem(item) {
      this.openItems = [...this.openItems, item];
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-tree-wrap {
  overflow-y: auto;
  position: absolute;
  top: 52px;
  left: 0;
  right: 8px;
  bottom: 64px;

  &.mobile {
    top: 60px;
  }

  .v-treeview ::v-deep {
    /* 트리 노드 */
    .v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          margin-left: 0;
        }
      }
      /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
      .v-treeview-node__children {
        overflow: hidden;
      }
    }
    /* 텍스트 */
    .cr-tree-label-text {
      font-size: 14px;
    }
    .v-treeview-node--active .cr-tree-label-text {
      font-weight: bold;
    }

    > .v-treeview-node {
      .v-treeview-node__root {
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }

        .v-treeview-node__content {
          cursor: pointer;
          .v-treeview-node__label {
            .cr-tree_node_click_area {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .cr-tree-label-text {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              min-width: 0;
            }
          }
        }
      }
      > .v-treeview-node__children {
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>
