<template>
  <v-dialog max-width="1000" :value="showTagDialog" @input="CLOSE_DIALOG">
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ $t("mail.481") }}
        <v-spacer />
        <v-btn icon @click="CLOSE_DIALOG">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="d-flex">
        <List key="my" />
        <List key="all" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import List from "./list";

export default {
  components: { List },
  mounted() {
    this.SET_LOADING(true);
    setTimeout(() => {
      this.getTags(0);
    }, 500);
  },
  computed: {
    ...mapGetters("tag", ["showTagDialog"])
  },
  methods: {
    ...mapMutations("tag", ["CLOSE_DIALOG", "SET_LOADING"]),
    ...mapActions("tag", ["getTags"])
  }
};
</script>
