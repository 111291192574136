<template>
  <v-dialog
    :value="value"
    width="360"
    persistent
    @input="val => $emit('input', val)"
  >
    <v-card class="special-leave-dialog">
      <v-card-title>
        {{ $t("calendar.141") }}
      </v-card-title>
      <v-card-text>
        <v-row align="center" class="pa-4 justify-center">
          <v-col cols="auto">
            {{ $t("calendar.보정일수") }}
          </v-col>
          <v-col cols="6">
            <FlatTextField
              v-model="amount"
              style="min-width: 80px"
              type="number"
              step="0.5"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="closeDialog">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="accent" @click="correct">
          {{ $t("common.수정") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/_locales";
import { updateLeaveCorrection } from "@/calendar/api/leave.api";
import { mapActions, mapMutations } from "vuex";
import FlatTextField from "@/calendar/components/common/FlatTextField.vue";

export default {
  components: { FlatTextField },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    leaveCorrection: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    searchMilli: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      amount: this.leaveCorrection
    };
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("cal", ["SHOW_LOADING_OVERLAY", "HIDE_LOADING_OVERLAY"]),
    closeDialog() {
      this.$emit("input", false);
    },
    correct() {
      const param = {
        userId: this.userId,
        amount: this.amount,
        searchMilli: this.searchMilli
      };

      this.confirm({
        headline: "",
        message: i18n.t("calendar.142"),
        callback: async () => {
          this.SHOW_LOADING_OVERLAY();
          const response = await updateLeaveCorrection(param);
          this.HIDE_LOADING_OVERLAY();

          const result = response.status === 200;
          this.openSnackbar({
            message: this.$t(result ? "calendar.11" : "calendar.97"),
            type: result ? "SUCCESS" : "ERROR"
          });

          if (result) {
            this.$emit("correct");
            this.closeDialog();
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.flat-text-field ::v-deep input {
  text-align: right;
}
</style>
