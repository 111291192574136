import { CALENDAR_TYPE } from "@/calendar/constant/calConstants";

export const wrapSirTeamCalendar = calendar => {
  return {
    id: calendar.id,
    type: calendar.type,
    title: calendar.title,
    color: calendar.color,
    selected: calendar.isChecked,
    // SirTeam 캘린더 전용 필드
    privilege: calendar.privilege,
    isSubscribed: calendar.isSubscribed,
    targetCalendarId: calendar.targetCalendarId,
    organId: calendar.organId
  };
};

export const wrapExternalCalendar = calendar => {
  return {
    id: calendar.id,
    type: calendar.type,
    title: calendar.title,
    color: calendar.color,
    selected: calendar.selected,
    // 외부 캘린더 전용 필드
    calendarId: calendar.calendarId,
    connectionId: calendar.connectionId
  };
};

export const isOrganCalendar = calendar => {
  return calendar?.type === CALENDAR_TYPE.ORGANIZATION;
};
