import { uploadFile } from "@/board/api/board.api";
import Vue from "vue";
import axios from "axios";
import i18n from "@/_locales";

const state = {
  // file 삭제, 순서변경에 사용되는 키값
  key: 0,
  // file 객체
  files: [],
  // 첨부파일 리스트 아이템 목록
  items: [],
  // 첨부파일 삭제 목록
  deleteItems: [],

  // 파일업로더 show
  showUploader: false,
  // 첨부된 file 전체 크기
  totalSize: 0,
  // 전체 전송량
  totalLoaded: 0,
  // 전송된 전체 퍼센트
  totalProgress: 0,
  // 남은시간
  remainingTime: 0,
  // axios 취소 (전송취소)
  cancelTokenSources: []
};
const getters = {
  getFiles: ({ files }) => files,
  getItems: ({ items }) => items,
  showUploader: ({ showUploader }) => showUploader,
  getDeleteItems: ({ deleteItems }) => deleteItems,
  getTotalSize: ({ totalSize }) => totalSize,
  getTotalLoadedSize: ({ totalLoaded }) => totalLoaded,
  getTotalCount: ({ items }) => items.length,
  getRemainingTime: ({ remainingTime }) => remainingTime,
  getCompletedCount: ({ items }) =>
    items.filter(({ status }) => status == 2).length,
  getTotalProgress: ({ totalProgress }) => totalProgress,
  getFileIds: ({ items }) => items.map(({ id }) => id),
  getDeleteFileIds: ({ deleteItems }) =>
    deleteItems.filter(item => item.id > 0).map(({ id }) => id),
  isUploadComplete: ({ items }) => {
    if (items.length == 0) return false;
    const uploadedCount = items.filter(({ status }) => status > 1);
    return uploadedCount.length == items.length;
  }
};

const mutations = {
  SET_SHOW_UPLOADER: (state, showUploader) => {
    state.showUploader = showUploader;
  },
  SET_FILE: (state, file) => {
    const { id = 0, key, name, size } = file;
    state.totalSize += size;
    if (file.status == 2) state.totalLoaded += size;
    state.key += 1;
    state.files.push(file);
    state.items.push({
      id,
      key,
      name,
      size,
      loaded: file.status == 2 ? size : 0,
      progress: 0,
      status: file.status || 0
    });
  },
  SET_UPLOAD_STATE: (state, { totalProgress, totalLoaded }) => {
    state.totalProgress = totalProgress;
    state.totalLoaded = totalLoaded;
  },
  SET_UPLOAD_REMAINING_TIME: (state, remainingTime) => {
    const hour = parseInt(remainingTime / 3600);
    const min = parseInt((remainingTime % 3600) / 60);
    const sec = remainingTime % 60;

    if (hour) {
      state.remainingTime = i18n.t("board.14", { hour, min, sec });
    } else if (min) {
      state.remainingTime = i18n.t("board.15", { min, sec });
    } else {
      state.remainingTime = i18n.t("board.16", { sec });
    }
  },
  SET_UPLOAD_FILE_STATE: (state, { idx, id, progress, loaded, status }) => {
    const {
      progress: stateProgress,
      loaded: stateLoaded,
      status: stateStatus
    } = state.items[idx];
    const fileState = {
      id,
      progress: progress == undefined ? stateProgress : progress,
      loaded: loaded == undefined ? stateLoaded : loaded,
      status: status == undefined ? stateStatus : status
    };

    Vue.set(state.items, idx, { ...state.items[idx], ...fileState });
  },
  DELETE_FILE: (state, key) => {
    const index = state.files.findIndex(item => item.key == key);
    const file = state.files[index];
    Vue.delete(state.files, index);
    state.deleteItems = [...state.deleteItems, ...state.items.splice(index, 1)];
    state.totalSize =
      state.totalSize - file.size < 0 ? 0 : state.totalSize - file.size;
    if (file.status == 2) state.totalLoaded -= file.size;
  },
  SET_CANCEL_TOKEN_SOURCES: (state, cancelTokenSources) => {
    state.cancelTokenSources = cancelTokenSources;
  },
  UPLOAD_RESET: state => {
    state.key = 0;
    state.files = [];
    state.items = [];
    state.deleteItems = [];

    state.showUploader = false;
    state.totalSize = 0;
    state.totalLoaded = 0;
    state.totalProgress = 0;
    state.remainingTime = 0;
    state.cancelTokenSources = [];
  },
  ROLLBACK_UPLOAD_STATE: state => {
    state.items = state.items.map(i => ({
      ...i,
      id: 0,
      loaded: 0,
      progress: 0,
      status: 0
    }));
    state.totalLoaded = 0;
    state.totalProgress = 0;
    state.remainingTime = 0;
    state.cancelTokenSources = [];
  }
};

const actions = {
  setFiles({ dispatch, commit, state }, files) {
    try {
      // 파일 개수 확인
      if (state.files.length + files.length > 10) {
        throw new Error(i18n.t("board.17"));
      }
      Array.from(files).forEach(file => {
        file.key = state.key;
        commit("SET_FILE", file);
      });
    } catch ({ message }) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message,
          type: "ERROR"
        },
        { root: true }
      );
    }
  },
  // 파일 업로드 및 @callback 실행
  uploadFile(
    { commit, dispatch, state, getters },
    {
      uploadType,
      callbackCompleted = () => {},
      callbackCanceled = () => {},
      callbackFailed = () => {}
    }
  ) {
    let { files, items, totalSize, totalLoaded } = state;

    // file이 없으면 return;
    if (files.length == 0) return;
    commit("SET_SHOW_UPLOADER", true);

    const startTime = new Date().getTime();
    const cancelTokenSources = [];
    const beforeLoadedArray = [];
    let beforeProgressTime = 0;

    for (const [idx, file] of files.entries()) {
      if (items[idx].status == 0) {
        // axios 취소
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSources.push(cancelTokenSource);

        // progress event
        const config = {
          cancelToken: cancelTokenSource.token,
          onUploadProgress: function(e) {
            const beforeLoaded = beforeLoadedArray[idx]
              ? beforeLoadedArray[idx]
              : 0;
            totalLoaded += e.loaded - beforeLoaded;
            beforeLoadedArray[idx] = e.loaded;

            const totalProgress =
              totalLoaded / totalSize >= 1
                ? 100
                : (totalLoaded / totalSize) * 100;

            // 업로드 전체 progress 퍼센트, 전송량
            commit("SET_UPLOAD_STATE", {
              totalProgress,
              totalLoaded: totalLoaded > totalSize ? totalSize : totalLoaded
            });

            // 전송시간
            const progressTime = Math.floor(
              (new Date().getTime() - startTime + 1000) / 1000
            );
            if (progressTime != beforeProgressTime) {
              const remainingTime = Math.floor(
                (totalSize - totalLoaded) / ((e.loaded - beforeLoaded) * 10)
              );
              // 남은시간
              commit(
                "SET_UPLOAD_REMAINING_TIME",
                remainingTime < 0 ? 0 : remainingTime
              );
            }
            beforeProgressTime = progressTime;

            // 각 파일 progress 퍼센트
            const progress =
              e.loaded / e.total >= 1 ? 100 : (e.loaded / e.total) * 100;
            commit("SET_UPLOAD_FILE_STATE", {
              idx,
              progress,
              loaded: e.loaded > file.size ? file.size : e.loaded,
              status: 1
            });
          }
        };

        // 서버에 업로드
        uploadFile(file, uploadType, config).then(
          ({ status, message, data }) => {
            if (status == 200) {
              // 업로드 성공
              const { id } = data;
              commit("SET_UPLOAD_FILE_STATE", {
                idx,
                id,
                status: 2
              });
              // 모든 업로드 완료시
              if (getters.isUploadComplete) {
                commit("SET_SHOW_UPLOADER", false);
                callbackCompleted();
              }
            } else {
              // 취소 or 실패
              const { size: totalLoaded } = items.reduce(
                (accumulator, currentValue) => {
                  if (accumulator.status < 2) {
                    return { size: 0 };
                  } else if (currentValue.status == 2) {
                    return { size: accumulator.size + currentValue.size };
                  }
                  return { size: accumulator.size };
                }
              );
              // 이미 전송완료된 progress 퍼센트
              const totalProgress =
                totalLoaded / totalSize >= 1
                  ? 100
                  : (totalLoaded / totalSize) * 100;
              commit("SET_UPLOAD_STATE", { totalProgress, totalLoaded });
              commit("SET_UPLOAD_FILE_STATE", {
                idx,
                progress: 0,
                loaded: 0,
                status: 0
              });

              // 취소시
              if (message == "cancel") {
                callbackCanceled();
                return;
              }
              // 실패시
              if (status != 200) {
                let headline = i18n.t("board.122"); // 파일 업로드 실패
                let msg = i18n.t("board.123"); // 파일 업로드 실패
                if (status === 507) msg = i18n.t("board.124"); // 게시판 용량 초과
                dispatch(
                  "confirm/confirm",
                  { headline, message: msg, showCloseBtn: false },
                  { root: true }
                );
                callbackFailed();
                return;
              }
            }
          }
        );
      }
    }

    commit("SET_CANCEL_TOKEN_SOURCES", cancelTokenSources);
  },
  closeUploader({ commit }) {
    const { totalProgress, cancelTokenSources } = state;
    if (totalProgress < 100) {
      cancelTokenSources.forEach(cancelTokenSource =>
        cancelTokenSource.cancel("cancel")
      );
    }
    commit("SET_SHOW_UPLOADER", false);
  },
  deleteAllFiles({ getters, commit }) {
    const keys = getters.getItems.map(({ key }) => key);
    keys.forEach(k => {
      commit("DELETE_FILE", k);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
