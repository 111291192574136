<template>
  <div>
    <nav
      class="cr-custom-pagination"
      :class="type == 'list' ? 'list-pagination' : 'autocp-pagination'"
    >
      <ul>
        <!-- "<<" 처음페이지로 이동 -->
        <li>
          <v-btn
            icon
            tile
            plain
            class="cr-page-btn"
            :ripple="false"
            :disabled="page <= 1"
            @click="goPage(1)"
            ><v-icon>mdi-chevron-double-left</v-icon></v-btn
          >
        </li>
        <!-- "<" 이전블록으로 이동 -->
        <li class="mr-1">
          <v-btn
            icon
            tile
            plain
            class="cr-page-btn"
            :ripple="false"
            :disabled="blockPage <= 1"
            @click="goPrevBlock"
            ><v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </li>
        <!-- 해당페이지로 이동 -->
        <li v-for="index in pageList" :key="index">
          <v-btn
            class="cr-page-btn"
            :class="page == index ? 'cr-page-active' : 'cr-page'"
            style=""
            text
            :ripple="false"
            @click="goPage(index)"
            >{{ index }}</v-btn
          >
        </li>
        <!-- ">" 다음블록으로 이동 -->
        <li class="ml-1">
          <v-btn
            icon
            tile
            plain
            class="cr-page-btn"
            :ripple="false"
            @click="goNextBlock"
            :disabled="blockPage >= maxBlockPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </li>
        <!-- ">>" 마지막페이지로 이동 -->
        <li>
          <v-btn
            icon
            tile
            plain
            depressed
            class="cr-page-btn"
            :ripple="false"
            @click="goPage(totalPage)"
            :disabled="page >= totalPage"
            ><v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style lang="scss" scoped>
/* paging */
.cr-custom-pagination {
  padding: 4px;
  > ul {
    padding-left: 0;
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    justify-content: center;
    margin: 0;
    max-width: 100%;
    width: 100%;
    > li {
      align-items: center;
      display: flex;
      .cr-page-btn {
        color: black;
        min-width: 40px;
        padding: 0px 10px;
      }
      .cr-page-active {
        background: #757575;
        color: white;
      }
    }
  }
}
.autocp-pagination {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-top: 0px;
}
</style>
<script>
export default {
  data: () => ({
    totalPage: 0,
    maxBlockPage: 0,
    blockPage: 0,
    pageList: []
  }),
  mounted() {
    // 페이지 기본 세팅
    this.setPageConfig(this.totalElements);
    // 화면에 보여질 페이지 세팅
    this.setPageList();
  },
  watch: {
    totalElements(totalElements) {
      this.setPageConfig(totalElements);
      this.setPageList();
      // 아이템을 삭제해서 페이지가 줄어들때,마지막 페이지로 이동
      if (this.page > this.totalPage) this.$emit("pageClick", this.totalPage);
    },
    // 페이지 사이즈, 블록 사이즈 변경시 페이지 변경
    pageSize() {
      this.setPageConfig(this.totalElements);
      this.setPageList();
    },
    blockSize() {
      this.setPageConfig(this.totalElements);
      this.setPageList();
    },

    page(page) {
      if (this.pageList.includes(page)) return;
      this.setPageList();
    }
  },
  props: ["totalElements", "page", "pageSize", "blockSize", "type"],
  computed: {},
  methods: {
    // 페이지 설정정보 세팅
    setPageConfig(totalElements) {
      const { pageSize, blockSize } = this;
      // 총페이지 갯수, 최대 블록 수
      this.totalPage = Math.ceil(totalElements / pageSize);
      this.maxBlockPage = Math.ceil(this.totalPage / blockSize);
    },
    // 페이지 세팅
    setPageList() {
      const { page, totalPage, blockSize, maxBlockPage } = this;

      for (let i = 1; i <= maxBlockPage; i++) {
        if (blockSize * i >= page) {
          this.blockPage = i;
          break;
        }
      }
      let list = [];

      for (let i = 1; i <= blockSize; i += 1) {
        const pageNum = (this.blockPage - 1) * blockSize + i;
        list.push(pageNum);
        if (pageNum == totalPage) break;
      }
      this.pageList = list;
    },
    // 해당 페이지로 이동
    goPage(page) {
      this.$emit("update:page", page);
      this.$emit("pageClick", page);
    },
    // 블록이동 prev : 이전블록, next : 다음블록
    goPrevBlock() {
      if (this.blockPage <= 1) return;
      this.blockPage -= 1;
      const page = (this.blockPage - 1) * this.blockSize + 1;
      this.$emit("pageClick", page);
    },
    // 다음 블록으로 이동
    goNextBlock() {
      if (this.blockPage >= this.maxBlockPage) return;
      this.blockPage += 1;
      const page = (this.blockPage - 1) * this.blockSize + 1;
      this.$emit("pageClick", page);
    }
  }
};
</script>
