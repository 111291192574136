import { getDateWithTimeSecond } from "@/commons/utils/moment";
import {
  getReceiptUserList,
  cancelSend,
  cancelAllSend
} from "@/mail/api/receipt.api";
import i18n from "@/_locales";

const state = {
  loading: false,
  pageSize: 10,
  totalElements: 0,
  selectedTab: 0,
  items: [
    // {id, name, address, receiptStatus, updateTimeMillis, }
  ]
};

const getters = {
  getLoading: ({ loading }) => loading,
  getPageSize: ({ pageSize }) => pageSize,
  getTotalElements: ({ totalElements }) => totalElements,
  getSelectedTab: ({ selectedTab }) => selectedTab,
  getItems: ({ items }) => items
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_ITEMS: (state, { items, totalElements }) => {
    state.items = items;
    state.totalElements = totalElements;
    state.loading = false;
  },
  SET_SELECTED_TAB: (state, selectedTab) => {
    state.selectedTab = selectedTab;
  }
};

const actions = {
  async getReceiptUserList(
    { state, commit, dispatch },
    { receiptId, receiptStatus, page }
  ) {
    commit("SET_LOADING", true);
    const { status, data } = await getReceiptUserList({
      receiptId: receiptId,
      receiptStatus: receiptStatus,
      page: page - 1,
      pageSize: state.pageSize
    });
    if (status == 200) {
      const { receipt, receiptUserList } = data;
      const { content, totalElements } = receiptUserList;
      commit("mail/SET_RECEIPT", receipt, { root: true });
      commit("SET_ITEMS", {
        totalElements,
        items: content.map(
          (
            {
              id,
              name,
              address,
              receiptStatus,
              replyCode,
              updateTimeMillis,
              cancelable
            },
            idx
          ) => ({
            id: id || idx,
            name,
            address,
            receiptStatus,
            replyCode,
            updateTimeMillis: getDateWithTimeSecond(updateTimeMillis),
            cancelable
          })
        )
      });
    } else {
      commit("SET_ITEMS", {
        totalElements: 0,
        items: []
      });
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.391"), type: "ERROR" },
        { root: true }
      );
    }
  },
  async cancelSend({ commit, dispatch }, { receiptId, targetAddress }) {
    dispatch("loader/setLoaderShow", true, { root: true });
    const { status, data } = await cancelSend(receiptId, targetAddress);
    let message = i18n.t("mail.392");
    let type = "ERROR";

    if (status == 201) {
      commit("mail/SET_RECEIPT", data, { root: true });
      message = i18n.t("mail.393");
      type = "SUCCESS";
    }

    dispatch("loader/setLoaderShow", false, { root: true });
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async cancelAllSend({ dispatch, commit }, receiptId) {
    dispatch(
      "confirm/confirm",
      {
        headline: i18n.t("mail.190"),
        message: i18n.t("mail.394"),
        callback: async () => {
          dispatch("loader/setLoaderShow", true, { root: true });
          const { status, data } = await cancelAllSend(receiptId);
          let message = i18n.t("mail.392");
          let type = "ERROR";

          if (status == 201) {
            commit("mail/SET_RECEIPT", data, { root: true });

            message = i18n.t("mail.393");
            type = "SUCCESS";
          }

          dispatch("loader/setLoaderShow", false, { root: true });
          dispatch("snackbar/openSnackbar", { message, type }, { root: true });
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
