import moment from "moment";

const state = {
  type: "month",
  focus: ""
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    state.type = params.type;
    state.focus = params.focus;
  },
  SET_ROUTE_FOCUS: (state, { focus }) => {
    state.focus = focus;
  }
};

const getters = {
  getCalendarType: ({ type }) => type,
  getCalendarFocus: ({ focus }) => focus
};

const actions = {
  async setParams({ commit }, params) {
    let parameter = params;
    if (!parameter.focus) {
      parameter.focus = moment().format("YYYY-MM-DD");
    }
    await commit("SET_ROUTE_PARAMS", parameter);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
