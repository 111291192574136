<template>
  <!-- 테마 설정 다이얼로그 -->
  <v-dialog width="500px" hide-overlay v-model="show">
    <v-card>
      <v-card-title class="py-4">
        <span class="text-h6 font-weight-bold" v-text="$t('common.83')" />
      </v-card-title>
      <!-- 컨테이너 -->
      <v-container fluid class="mt-0 pt-0">
        <v-row dense style="background-color:#f0f0f0; height:270px;">
          <!-- 왼쪽 영역 -->
          <v-col cols="6">
            <!-- 버튼과 목록 영역 -->
            <div
              class="pa-1"
              style="position:relative; background-color:white;"
            >
              <!-- 버튼 -->
              <div>
                <v-btn style="width:49%;" color="primary" elevation="0">
                  COMPOSE
                </v-btn>
                <v-btn style="width:49%;" class="ml-1" elevation="0">
                  TO ME
                </v-btn>
              </div>
              <!-- 목록 -->
              <v-list dense>
                <v-list-item-group v-model="selectedMailbox">
                  <!-- 이메일함 목록 -->
                  <v-list-item
                    color="primary"
                    v-for="(item, i) in mailboxList"
                    :key="i"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon>{{ getMailboxIcon(item) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <!-- FAB 버튼 -->
              <div style="position:absolute; bottom:8px; right: 8px;">
                <v-btn color="accent" elevation="2" fab>
                  <v-icon>mdi-chat-processing-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- 오른쪽 영역 -->
          <v-col
            cols="6"
            style="overflow-x:hidden; overflow-y:auto; height:270px; padding-top:5px; padding-left:0px;"
          >
            <v-sheet
              v-ripple
              rounded
              elevation="0"
              class="mb-2 d-flex "
              style="cursor:pointer; overflow:hidden;"
              v-for="(item, i) in availableThemes"
              :key="i"
              @click="changeColor(i)"
            >
              <!-- 테마 미리보기 및 선택 -->
              <div
                class="flex-grow-1 px-3 py-2"
                :style="{ backgroundColor: getPrimaryColor(item) }"
              >
                <v-icon v-if="isSelected(i)" color="white" small>
                  mdi-check
                </v-icon>
                <span
                  class="black--text"
                  style="text-transform:uppercase; font-size:13px; text-shadow:0px 0px 10px white;"
                >
                  {{ i }}
                </span>
              </div>
              <div
                class="flex-grow-0"
                style="width:60px;"
                :style="{ backgroundColor: getAccentColor(item) }"
              ></div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <!-- 카드 액션 -->
      <v-card-actions class="py-4 px-6">
        <v-spacer />
        <v-btn text outlined @click="close">{{ $t("common.취소") }}</v-btn>
        <v-btn depressed color="primary" @click="save">
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mailboxIcons } from "@/commons/utils/icons";

export default {
  data: () => ({
    show: false,
    availableThemes: {},
    selectedTheme: "blue",
    // 화면 표시용
    selectedMailbox: 1,
    mailboxList: ["ALL", "INBOX", "DRAFTS", "DELETED", "SPAM"]
  }),
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters("theme", ["getCurrentTheme", "isThemeDialogVisible"])
  },
  watch: {
    show(value) {
      if (value) {
        this.initialize();
        return;
      }

      this.close();
    }
  },
  methods: {
    ...mapActions("theme", ["resetTheme", "hideThemeDialog", "applyTheme"]),
    initialize() {
      const themeMap = this.$vuetify.theme.defaults;
      let map = {};
      for (const theme in themeMap) {
        if (theme == "light") continue;
        if (theme == "dark") continue;
        map[theme] = themeMap[theme];
      }
      this.availableThemes = map;

      this.selectedTheme = this.getCurrentTheme;
      this.show = this.isThemeDialogVisible;
    },
    getMailboxIcon(text) {
      return mailboxIcons[text] ?? mailboxIcons.INBOX;
    },
    getPrimaryColor(item) {
      return item.primary;
    },
    getSecondaryColor(item) {
      return item.secondary;
    },
    getAccentColor(item) {
      return item.accent;
    },
    changeColor(key) {
      const theme = this.availableThemes[key];
      Object.keys(theme).forEach(i => {
        this.$vuetify.theme.themes.light[i] = theme[i];
      });
      this.selectedTheme = key;
    },
    isSelected(key) {
      return key == this.selectedTheme;
    },
    close() {
      this.selectedTheme = this.getCurrentTheme;
      this.resetTheme();
      this.hideThemeDialog();
    },
    save() {
      this.applyTheme(this.selectedTheme);
      this.hideThemeDialog();
    }
  }
};
</script>

<style lang="scss" scoped></style>
