const state = {
  title: null,
  show: false,
  x: 0,
  y: 0,
  itemList: []
};

const getters = {
  isShow: ({ show }) => show,
  getX: ({ x }) => x,
  getY: ({ y }) => y,
  getTitle: ({ title }) => title,
  getItemList: ({ itemList }) => itemList
};

// state값 수정
const mutations = {
  INIT: (state, { title = "", x, y, itemList = [] }) => {
    state.title = title;
    state.x = x;
    state.y = y;
    state.itemList = itemList;
    state.show = true;
  },
  SET_SHOW: (state, show) => (state.show = show)
};

// mutations 호출 및 비동기 처리
const actions = {
  async positioningMenu({ commit }, { title, x, y, itemList }) {
    setTimeout(() => {
      commit("INIT", { title, x, y, itemList });
    }, 0);
  },
  async closePositioningMenu({ commit }) {
    await commit("SET_SHOW", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
