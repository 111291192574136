import drive from "./modules/drive";
import driveShare from "./modules/driveShare";
import driveExternal from "./modules/driveExternal";
import driveRoute from "./modules/route";
import driveConfig from "./modules/config";
import driveLayout from "./modules/layout";
import driveDialog from "./modules/dialog";
import driveFile from "./modules/file";
import driveExternalToken from "./modules/externalToken";
export default {
  drive,
  driveShare,
  driveExternal,
  driveRoute,
  driveConfig,
  driveLayout,
  driveDialog,
  driveFile,
  driveExternalToken
};
