<template>
  <v-row class="pt-3" no-gutters>
    <v-col class="cr-announce-wrapper" cols="12">
      <div class="cr-view-wrapper">
        <div class="cr-view-title">
          <v-list-item class="px-0 py-2">
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold">
                <span v-text="getView.title"> </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="grey--text d-flex align-center">
                <span class="cr-regTime" v-text="getView.registTime"> </span>
                <!-- <v-btn icon v-bind:title="'목록보기'">
                  <v-icon v-text="'mdi-view-list'"></v-icon>
                </v-btn> -->
              </div>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div class="cr-view-contents" v-html="getView.contents"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data: () => ({}),
  async mounted() {
    // await this.getAnnounceView();
  },
  destroyed() {
    this.SET_VIEW_RESET();
  },
  computed: {
    ...mapGetters("announce", ["getView"])
  },
  methods: {
    ...mapMutations("announce", ["SET_VIEW_RESET"]),
    ...mapActions("announce", ["getAnnounceView"])
  }
};
</script>

<style lang="scss" scoped>
.row {
  height: 100%;
  .cr-announce-wrapper {
    position: relative;
    height: 100%;
    .cr-view-wrapper {
      height: 100%;
      .cr-view-contents ::v-deep p {
        margin-bottom: 0px !important;
      }
      .v-list-item {
        .v-list-item__action .cr-regTime {
          padding-top: 2px;
          margin-right: 8px;
        }
      }
      .cr-view-contents {
        color: #333;
        user-select: text;
        ::v-deep p {
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
