<script>
import GoogleLogo from "@/commons/views/icons/GoogleLogo.vue";
import { disconnectExternalOAuth2 } from "@/commons/api/external/external.api";
import { mapActions } from "vuex";

export default {
  components: { GoogleLogo },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    connection: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disconnecting: false
    };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    async disconnect() {
      this.disconnecting = true;
      await disconnectExternalOAuth2(this.connection.id);
      this.openSnackbar({
        message: this.$t("login.외부_서비스.연결_해제"),
        type: "SUCCESS"
      });
      this.$emit("disconnected");
      this.$emit("input", false);
    }
  }
};
</script>

<template>
  <v-dialog
    :value="value"
    @input="val => this.$emit('input', val)"
    max-width="384px"
  >
    <v-card class="py-2">
      <v-card-title>
        <GoogleLogo />
        <span class="ml-2 text-h5">
          {{ $t("calendar.구글_캘린더.연결_해제") }}
        </span>
      </v-card-title>
      <v-card-text>
        {{
          $t("calendar.구글_캘린더.연결_해제_내용", { email: connection.email })
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="() => this.$emit('input', false)">
          {{ $t("common.닫기") }}
        </v-btn>
        <v-btn
          :loading="disconnecting"
          :disabled="disconnecting"
          outlined
          color="accent"
          @click="disconnect"
        >
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
