<template>
  <v-text-field
    solo
    hide-details
    color="dark"
    autocomplete="off"
    ref="searchInputRef"
    background-color="grey lighten-3"
    prepend-inner-icon="mdi-magnify"
    class="cr-search-input"
    :flat.sync="flat"
    :placeholder="$t('common.21')"
    v-model="value"
    @focus="flat = false"
    @blur="flat = true"
  >
    <template slot="append">
      <v-btn
        large
        plain
        tile
        class="pa-0 cr-detail-btn"
        @click.capture.stop="detailBtnClick"
        :color="showSearchDetail ? 'accent' : ''"
      >
        <span :style="{ 'font-weight': showSearchDetail ? 'bold' : 'normal' }">
          {{ $t("common.상세") }}
        </span>
        <v-icon>
          {{ showSearchDetail ? "mdi-menu-up" : "mdi-menu-down" }}
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-input.v-text-field.cr-search-input::v-deep {
  .v-input__control {
    .v-input__slot {
      padding: 0px 0px 0px 12px;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    searchWord: {
      type: String,
      default: ""
    }
  },
  data() {
    return { flat: true };
  },
  computed: {
    ...mapGetters("mailLayout", ["showSearchDetail"]),
    value: {
      get() {
        return this.searchWord;
      },
      set(value) {
        this.$emit("update:searchWord", value);
      }
    }
  },
  methods: {
    ...mapMutations("mailLayout", ["TOGGLE_SHOW_SEARCH_DETAIL"]),
    detailBtnClick() {
      this.$parent.blur();
      this.TOGGLE_SHOW_SEARCH_DETAIL();
    }
  }
};
</script>
