import http from "@/commons/api/http";

const prefix = "api/mail/config/rule";

/**
 * 자동분류 목록 조회
 */
const getRuleList = params => http.get(`${prefix}`, { params });

/**
 * 자동분류 추가
 */
const addRule = params => http.post(`${prefix}`, params);

/**
 * 자동분류 수정
 */
const updateRule = params => http.put(`${prefix}/${params.id}`, params);

/**
 * 자동분류 순서변경
 */
const updateSortOrder = (id, targetSortOrder, isLast) => {
  const formData = new FormData();
  formData.append("isLast", isLast);
  return http.put(`${prefix}/${id}/sortOrder/${targetSortOrder}`, formData);
};

/**
 * 자동분류 삭제
 */
const deleteRules = ruleIds => {
  const formData = new FormData();
  ruleIds.forEach(id => formData.append("ruleIds", id));
  return http.delete(`${prefix}`, { data: formData });
};

export { getRuleList, addRule, updateRule, updateSortOrder, deleteRules };
