<template>
  <v-card :min-width="$vuetify.breakpoint.width < 412 ? '100%' : '412px'">
    <v-card-title
      class="d-flex align-center pa-2"
      :style="{ cursor: dragging ? 'grabbing' : 'grab' }"
      @mousedown="e => $emit('startDrag', e)"
    >
      <!-- 캘린더 선택 -->
      <FlatSelect
        v-model="selectedCalendar"
        :items="calendars"
        item-value="id"
        item-text="title"
        :btn-props="{ style: 'height: 32px', text: true }"
        return-object
        :menu-props="{ disabled: isExceptionEvent }"
        @mousedown.stop
        @input="val => $emit('changeCalendar', val)"
      >
        <template #item="{ item }">
          <v-icon class="mr-2" :color="item.color" small>
            mdi-circle
          </v-icon>
          {{ item.title }}
        </template>
        <template #selection="{ selection }">
          <v-icon class="mr-1" :color="selection.color" small>
            mdi-circle
          </v-icon>
          {{ selection.title }}
        </template>
      </FlatSelect>

      <v-spacer></v-spacer>

      <slot name="header"></slot>

      <v-btn icon @mousedown.stop @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-6 pb-0">
      <v-row>
        <v-col cols="12" :md="detailMode ? 5 : 12" :sm="detailMode ? 6 : 12">
          <!-- 제목 -->
          <v-row>
            <v-col cols="12">
              <IconField>
                <v-text-field
                  v-model="summary"
                  class="text-h6"
                  full-width
                  dense
                  autofocus
                  autocomplete="off"
                  hide-details="auto"
                  :placeholder="$t('calendar.eventTitle')"
                  @input="val => $emit('changeName', val)"
                  @keydown.enter="handleEdit(null)"
                >
                </v-text-field>
              </IconField>
            </v-col>
          </v-row>

          <!-- 이벤트 기간 -->
          <v-row dense>
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-clock-outline">
                <div class="d-flex align-center flex-wrap">
                  <FilledDateTimePicker
                    :date="startDate"
                    :time="allDay ? null : startTime"
                    @update:date="startDateChange"
                    @update:time="startTimeChange"
                  ></FilledDateTimePicker>
                  <v-icon x-small>mdi-tilde</v-icon>
                  <FilledDateTimePicker
                    :date="endDate"
                    :time="allDay ? null : endTime"
                    @update:date="endDateChange"
                    @update:time="endTimeChange"
                  ></FilledDateTimePicker>
                </div>
              </IconField>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <IconField>
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="allDay"
                    dense
                    color="primary"
                    hide-details
                    :ripple="false"
                    class="ma-0 pa-0 flex-shrink-0"
                    @change="val => $emit('changeAllDay', val)"
                  >
                    <template #label>
                      <span class="text--primary text-body-2">
                        {{ $t("calendar.종일") }}
                      </span>
                    </template>
                  </v-checkbox>
                  <v-icon class="ml-1 mr-n1" small>mdi-circle-small</v-icon>
                  <RecurrenceSelect
                    v-model="recurObj"
                    :start-date="startDate"
                    :all-day="allDay"
                    :disabled="isExceptionEvent"
                  />
                </div>
              </IconField>
            </v-col>
          </v-row>

          <!-- 참석자 -->
          <v-divider v-if="attendees.length" class="my-2"></v-divider>
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField
                dense
                spacing="dense"
                icon="mdi-account-multiple-outline"
              >
                <div class="d-flex align-center">
                  <div class="flex-fill">
                    <FlatMemberSearch
                      v-model="attendees"
                      :page-size="3"
                      :placeholder="$t('calendar.addAttendee')"
                      enableEmailDirectAddition
                      :disabled="isExceptionEvent"
                    />
                  </div>
                  <v-btn
                    small
                    icon
                    class="ml-1"
                    @click="showSearchDialog = true"
                  >
                    <v-icon size="20">
                      mdi-sitemap-outline
                    </v-icon>
                  </v-btn>
                </div>
              </IconField>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="attendees.length">
            <v-col cols="12">
              <IconField spacing="dense">
                <MemberList v-model="attendees" deletable />
              </IconField>
            </v-col>
          </v-row>
          <v-divider v-if="attendees.length" class="my-2"></v-divider>

          <!-- 장소 -->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-map-marker-outline">
                <div class="d-flex align-center">
                  <div class="flex-fill">
                    <FlatTextInput
                      v-model="location"
                      :placeholder="$t('calendar.addLocation')"
                    />
                  </div>

                  <v-btn
                    v-if="!isRecurEvent && !allDay"
                    small
                    icon
                    class="ml-1"
                    @click="openResourceDialog()"
                  >
                    <v-icon size="20">
                      mdi-book-clock-outline
                    </v-icon>
                  </v-btn>
                </div>
              </IconField>
            </v-col>
          </v-row>

          <!-- 설명 -->
          <v-divider v-if="descEditorActivated" class="my-2"></v-divider>
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-text">
                <FlatSimpleEditor
                  v-model="description"
                  :placeholder="$t('calendar.addDesc')"
                  @activate="descEditorActivated = true"
                />
              </IconField>
            </v-col>
          </v-row>

          <!-- 공개 여부 선택-->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-lock-open-outline">
                <div class="d-flex align-center">
                  <FlatSelect
                    v-model="isPublic"
                    :items="[
                      { text: $t('calendar.공개'), value: true },
                      { text: $t('calendar.비공개'), value: false }
                    ]"
                  />

                  <v-tooltip top color="grey darken-3">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="ml-1" dense>
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <ul>
                      <li>{{ $t("calendar.145") }}</li>
                      <li>{{ $t("calendar.148") }}</li>
                    </ul>
                  </v-tooltip>
                </div>
              </IconField>
            </v-col>
          </v-row>

          <!-- 알람 -->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-bell-outline">
                <v-btn
                  text
                  ref="addBtn"
                  :disabled="alarmList.length >= 5"
                  class="px-2 text-body-2 text-none"
                  @click="addAlarm"
                >
                  {{ $t("calendar.42") }}
                </v-btn>
              </IconField>
            </v-col>
          </v-row>

          <v-row v-if="alarmList.length" no-gutters class="small-row">
            <v-col cols="12">
              <IconField spacing="dense">
                <EventAlarmList v-model="alarmList" />
              </IconField>
            </v-col>
          </v-row>
        </v-col>

        <!-- 우측 패널 -->
        <v-col v-if="detailMode" cols="12" md="7" sm="6" class="pa-0">
          <FreeBusy
            v-if="loadedInitialData"
            :startDate="startDate"
            :startTime="startTime"
            :endDate="endDate"
            :endTime="endTime"
            :allDay="allDay"
            :attendees="attendees"
            :organizer="organizer"
            @updateTimeData="updateTimeByFreeBusy"
          ></FreeBusy>
        </v-col>
      </v-row>

      <GroupEventMailConfirm
        v-if="showGroupEventMailConfirm"
        v-model="showGroupEventMailConfirm"
        :isInvitation="isInvitation"
        @confirm="confirmedSendMail => handleEdit(confirmedSendMail)"
      />
      <AttendeeSelectionDialog
        v-if="showSearchDialog"
        :dialog.sync="showSearchDialog"
        :existAttendee="attendees"
        @setSearchResultFromDialog="result => (attendees = result)"
        @closeDialog="showSearchDialog = false"
      ></AttendeeSelectionDialog>
      <ResourceBookingDialog
        v-if="resource.showDialog"
        :dialog.sync="resource.showDialog"
        :resourceId.sync="resource.resourceId"
        :resourceName.sync="location"
        :bookingId="resource.resourceBookingId"
        :isUpdateMode="isUpdateMode"
        :startDate="startDate"
        :startTime="startTime"
        :endDate="endDate"
        :endTime="endTime"
        :allDay="allDay"
        @closeDialog="closeResourceDialog"
        @updateTimeData="updateTimeByFreeBusy"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        :class="{ 'grey lighten-2': detailMode }"
        class="text-none"
        @click="$emit('update:detailMode', !detailMode)"
      >
        {{ $t("common.상세") }}
      </v-btn>
      <v-btn
        v-if="isUpdateMode"
        depressed
        color="primary"
        class="text-none"
        @click="handleEdit(null)"
      >
        {{ $t("common.수정") }}
      </v-btn>
      <v-btn
        v-else
        depressed
        color="primary"
        class="text-none"
        @click="handleEdit(null)"
      >
        {{ $t("common.저장") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FreeBusy from "@/calendar/components/event/FreeBusy.vue";
import AttendeeSelectionDialog from "./attendee/dialog/index";
import EventViewMixin from "@/calendar/mixins/EventViewMixin";
import ResourceBookingDialog from "./resource/ResourceBookingDialog.vue";
import { CAL_CONSTANTS, CALENDAR_TYPE } from "@/calendar/constant/calConstants";
import { mapActions, mapGetters, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import IconField from "@/calendar/components/common/IconField.vue";
import RecurrenceSelect from "@/calendar/components/event/RecurrenceSelect.vue";
import FilledDateTimePicker from "@/calendar/components/common/FilledDateTimePicker.vue";
import EventAlarmList from "@/calendar/components/event/EventAlarmList.vue";
import { DEFAULT_ALARM } from "@/calendar/constant/skeleton";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";
import FlatTextInput from "@/calendar/components/common/FlatTextInput.vue";
import FlatMemberSearch from "@/calendar/components/common/FlatMemberSearch.vue";
import MemberList from "@/calendar/components/common/MemberList.vue";
import GroupEventMailConfirm from "@/calendar/components/event/confirm/GroupEventMailConfirm.vue";
import FlatSimpleEditor from "@/calendar/components/common/FlatSimpleEditor.vue";

export default {
  components: {
    FlatSimpleEditor,
    GroupEventMailConfirm,
    MemberList,
    FlatMemberSearch,
    FlatTextInput,
    FlatSelect,
    EventAlarmList,
    FilledDateTimePicker,
    RecurrenceSelect,
    IconField,
    AttendeeSelectionDialog,
    FreeBusy,
    ResourceBookingDialog
  },
  props: {
    dragging: {
      type: Boolean
    },
    detailMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [EventViewMixin],
  data() {
    return {
      calendars: [],
      selectedCalendar: null,
      attendees: [],
      organizer: {
        userId: 0,
        email: "TEMP_USER",
        name: "",
        cn: CAL_CONSTANTS.ATTENDEE_ROLE.chair,
        partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept
      },
      isExceptionRequest: false,
      exdates: [],
      recurObj: null,
      recurObjOrigin: null,
      exdateToApply: null,
      alarmList: [{ ...DEFAULT_ALARM }],
      loadedInitialData: false, // 시간 데이터 셋팅 완료 여부
      showGroupEventMailConfirm: false,
      isInvitation: false,
      showSearchDialog: false,
      resource: {
        showDialog: false,
        resourceId: 0,
        resourceBookingId: 0
      },
      descEditorActivated: false
    };
  },
  computed: {
    ...mapState("cal", ["eventEditor"]),
    ...mapGetters("cal", ["getMyCalendars", "getSubscribedCalendars"]),
    ...mapGetters("auth", ["getUserInfo"]),
    isExceptionEvent() {
      return !!(
        this.isExceptionRequest ||
        this.editEvent?.modifiesId ||
        this.editEvent?.detail?.recurrenceId
      );
    },
    isRecurEvent() {
      return this.recurObj != null;
    }
  },
  created() {
    this.initWritableCalendar();
    this.initOrganizer();
    if (this.isUpdateMode) {
      this.initViewByCurrentEvent();
    }
    // 시간 정보를 캘린더 뷰에 반영하기 위해 호출합니다.
    this.adjustDateTime("start");
    this.loadedInitialData = true;
  },
  watch: {
    attendees(val, old) {
      // 참석자 최초 추가 시 주최자 또한 참석자로 자동 추가합니다.
      if (old.length || val.length !== 1) return;
      if (this.attendees[0].email === this.organizer.email) {
        this.attendees[0].isOrganizer = true;
        return;
      }

      this.attendees[0].key = 1;
      this.attendees.unshift({
        key: 0,
        valid: true,
        ...this.organizer,
        partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept,
        value: `${this.organizer.name && `"${this.organizer.name}" `}<${
          this.organizer.email
        }>`,
        // 참석자 목록 표시 시 주최자 아이콘 표시를 위한 속성
        isOrganizer: true
      });
    },
    allDay(val) {
      this.transp = val ? "TRANSPARENT" : "OPAQUE";
    }
  },
  methods: {
    ...mapActions("cal", ["saveEventToServer", "updateEventToServer"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    initWritableCalendar() {
      // 내 캘린더, 쓰기 권한이 있는 구독 캘린더 표시
      this.calendars = [
        ...this.getMyCalendars,
        ...this.getSubscribedCalendars.filter(cal => cal.privilege === 3)
      ];

      this.selectedCalendar =
        this.getMyCalendars.find(
          calendar => calendar.type === CALENDAR_TYPE.DEFAULT
        ) ?? this.getMyCalendars[0];
    },
    initOrganizer() {
      this.organizer.userId = this.getUserInfo.id;
      this.organizer.email = this.getUserInfo.username;
      this.organizer.name = this.getUserInfo.accountName;
    },
    initViewByCurrentEvent() {
      const event = this.editEvent;
      this.selectedCalendar = event.calendar;
      this.isExceptionRequest = this.eventEditor.isExceptionRequest;

      if (this.isExceptionRequest) {
        const date = moment(event.start);
        this.exdateToApply = event.isAllDay
          ? date.format("YYYYMMDD")
          : date.format("YYYYMMDDTHHmmss");
      }

      this.alarmList = [...event.detail.alarms];

      if (event.isRecurring) {
        const recur = event.detail.recurrence;

        this.recurObj = {
          freq: recur.freq,
          interval: recur.interval,
          count: recur.count,
          until: recur.until,
          parts: {
            BYDAY: recur.byDay,
            BYMONTH: recur.byMonth,
            BYMONTHDAY: recur.byMonthDay
          }
        };
        this.recurObjOrigin = { ...this.recurObj };

        if (event.exDates) {
          this.exdates = event.exDates;
        }
      }

      const organizer = event.detail.organizer;
      if (organizer) {
        this.organizer.userId = organizer.userId;
        this.organizer.email = organizer.email;
        this.organizer.name = organizer.name;
        this.organizer.partStat = organizer.partStat;
      }

      event.detail.attendees.forEach((obj, index) => {
        const attendee = {
          key: index,
          valid: true,
          name: obj.name,
          partStat: obj.partStat,
          email: obj.email,
          userId: obj.userId,
          value: obj.name ? `"${obj.name}" <${obj.email}>` : `${obj.email}`,
          isOrganizer: this.organizer.email === obj.email
        };
        this.attendees.push(attendee);
      });
    },
    confirm(status) {
      this.$emit("confirm", status);
    },
    cancel() {
      this.$emit("cancel");
    },
    addAlarm() {
      this.alarmList.push({ ...DEFAULT_ALARM });
    },
    async handleEdit(confirmedSendMail) {
      if (!this.validateFields()) return;

      // 참석자 존재 시 메일 발송 여부 확인
      if (this.attendees.length > 0 && confirmedSendMail === null) {
        this.isInvitation = this.isUpdateMode ? this.editEvent.invited : false;
        this.showGroupEventMailConfirm = true;
        return;
      }

      const eventDetail = this.makeEventDetail();
      let baseParam = {
        calendarId: this.selectedCalendar.id,
        detail: eventDetail
      };
      if (confirmedSendMail !== null) {
        baseParam.confirmedSendMail = confirmedSendMail;
      }

      let result;
      if (this.isUpdateMode) {
        // 반복 설정 변경 시 예외일 초기화
        if (this.isUpdateMode && this.isRecurChanged()) {
          eventDetail.exDates = [];
        }

        result = await this.updateEventToServer({
          ...baseParam,
          originCalendarId: this.editEvent.calendar.id,
          eventUId: this.eventUId,
          exDate: this.exdateToApply,
          isExceptionRequest: this.isExceptionRequest,
          owner: this.editEvent.source.owner,
          isOrganizer: !this.editEvent.invited
        });
      } else {
        result = await this.saveEventToServer({
          ...baseParam,
          eventUId: uuidv4()
        });
      }

      this.confirm(result);
    },
    makeEventDetail() {
      let detail = this.makeBaseEventDetail();

      // 알람 리스트
      if (this.alarmList.length > 0) {
        detail.alarms = this.alarmList;
      }

      // 반복 이벤트
      if (this.recurObj) {
        detail.recurrence = {
          freq: this.recurObj.freq,
          count: this.recurObj.count,
          until: this.recurObj.until,
          interval: this.recurObj.interval,
          byDay: this.recurObj.parts.BYDAY,
          byMonth: this.recurObj.parts.BYMONTH,
          byMonthDay: this.recurObj.parts.BYMONTHDAY
        };
      }

      // 반복이벤트의 예외일
      if (this.exdates.length > 0) {
        detail.exDates = this.exdates;
      }

      // 참석자
      if (this.attendees.length > 0) {
        detail.organizer = this.organizer;
        detail.attendees = this.attendees;
      }

      // 시설 아이디
      if (this.resource.resourceId) {
        detail.resourceId = this.resource.resourceId;
        detail.resourceBookingId = this.resource.resourceBookingId;
      }
      return detail;
    },
    isRecurChanged() {
      if (!this.recurObjOrigin) return false;

      return this.recurObj?.toString() !== this.recurObjOrigin.toString();
    },
    updateTimeByFreeBusy({ currentDate, startTime, endTime }) {
      this.startDate = currentDate;
      this.endDate = currentDate;
      this.startTime = startTime;
      this.endTime = endTime;
      this.adjustDateTime("end");
    },
    validateFields() {
      let errorMessage;

      if (!this.summary) {
        errorMessage = this.$t("calendar.50");
      }

      // 캘린더 선택 체크
      const calendars = this.calendars.map(cal => cal.id);
      if (!calendars.includes(this.selectedCalendar.id)) {
        errorMessage = this.$t("calendar.53");
      }

      // 시간 체크
      if (!this.allDay) {
        const startStr = this.startDate + this.startTime;
        const endStr = this.endDate + this.endTime;
        const format = "YYYY-MM-DDHH:mm";
        let startDt = moment(startStr, format);
        let endDt = moment(endStr, format);

        if (startDt.valueOf() >= endDt.valueOf()) {
          errorMessage = this.$t("calendar.132");
        }
      }

      if (errorMessage) {
        this.openSnackbar({
          message: errorMessage,
          type: "ERROR"
        });
        return false;
      }

      return true;
    },
    startDateChange(date) {
      this.startDate = date;
      this.adjustDateTime("end");
    },
    endDateChange(date) {
      this.endDate = date;
      this.adjustDateTime("start");
    },
    startTimeChange(time) {
      this.startTime = time;
      this.adjustDateTime("end");
    },
    endTimeChange(time) {
      this.endTime = time;
      this.adjustDateTime("start");
    },
    adjustDateTime(adjustTarget) {
      const MINUTE_UNIT = 30;
      const startDateTime = moment(
        `${this.startDate} ${this.startTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const endDateTime = moment(
        `${this.endDate} ${this.endTime}`,
        "YYYY-MM-DD HH:mm"
      );

      if (startDateTime.isBefore(endDateTime)) {
        this.$emit("changeDateTime", {
          start: startDateTime.toDate(),
          end: endDateTime.toDate()
        });
        return;
      }

      if (adjustTarget === "start") {
        startDateTime.set({
          year: endDateTime.get("year"),
          month: endDateTime.get("month"),
          date: endDateTime.get("date")
        });

        if (startDateTime.isSameOrAfter(endDateTime)) {
          startDateTime.set({
            hour: endDateTime.get("hour"),
            minute: endDateTime.get("minute")
          });

          startDateTime.subtract(MINUTE_UNIT, "minutes");
        }

        this.startDate = startDateTime.format("YYYY-MM-DD");
        this.startTime = startDateTime.format("HH:mm");
      }

      if (adjustTarget === "end") {
        endDateTime.set({
          year: startDateTime.get("year"),
          month: startDateTime.get("month"),
          date: startDateTime.get("date")
        });

        if (startDateTime.isSameOrAfter(endDateTime)) {
          endDateTime.set({
            hour: startDateTime.get("hour"),
            minute: startDateTime.get("minute")
          });

          endDateTime.add(MINUTE_UNIT, "minutes");
        }

        this.endDate = endDateTime.format("YYYY-MM-DD");
        this.endTime = endDateTime.format("HH:mm");
      }

      this.$emit("changeDateTime", {
        start: startDateTime.toDate(),
        end: endDateTime.toDate()
      });
    },
    openResourceDialog() {
      this.resource.showDialog = true;
    },
    closeResourceDialog() {
      this.resource.showDialog = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/simple-scrollbar.scss";

.v-card__text {
  min-height: 380px;
  max-height: 448px;
  overflow-x: hidden;
  overflow-y: auto;

  // 종일 체크박스 레이블 여백
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }
}

.small-row {
  margin-top: 2px;
}
</style>
