<template>
  <v-menu
    v-model="menu"
    bottom
    max-width="206px"
    :close-on-content-click="false"
    transition="slide-y-transition"
  >
    <template #activator="{ attrs, on }">
      <!-- 최상위 요소에 v-if 사용 시 요소가 사라지는 버그가 있어 div로 감쌉니다. -->
      <div>
        <v-btn v-if="showButton" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list class="pt-0">
      <!-- 제목 수정중/수정 가능/읽기 전용 상태별 UI
      @keydown.stop 미적용 시 키 입력이 리스트까지 전파되어 Home, End 등의 키가
       올바르게 동작하지 않습니다.
       -->
      <v-text-field
        ref="title"
        v-if="editTitle"
        v-model="title"
        filled
        dense
        autofocus
        hide-details="auto"
        maxLength="80"
        append-icon="mdi-check"
        @click:append="updateCalendar"
        @keydown.stop
        @keydown.esc="
          editTitle = false;
          title = calendar.title;
        "
        @keydown.enter="updateCalendar"
      />
      <v-list-item
        v-else-if="titleEditable"
        link
        class="pt-1 grey lighten-4"
        @click="editTitle = true"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center justify-space-between">
            <span class="overflow-hidden" style="text-overflow: ellipsis">
              {{ calendar.title }}
            </span>
            <v-icon class="flex-shrink-0" :color="calendar.color" size="22">
              mdi-calendar-edit
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else class="pt-1">
        <v-list-item-content>
          <v-list-item-title
            class="d-flex align-center justify-space-between grey--text text--darken-1"
          >
            <span class="overflow-hidden" style="text-overflow: ellipsis">
              {{ calendar.title }}
            </span>
            <v-icon :color="calendar.color" size="22">
              mdi-calendar-blank
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="showDeleteBtn" link @click="openDeleteConfirm">
        <v-list-item-content>
          <v-list-item-title>{{ $t("common.삭제") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <DeleteEventsByUntilMenu
        v-if="showDeleteEventsByUntilBtn"
        :calendar="calendar"
      />

      <v-list-item v-if="showUnsubscribeBtn" link @click="unsubscribe">
        <v-list-item-content>
          <v-list-item-title>{{ $t("calendar.구독취소") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="showShareBtn" link @click="openShareDialog">
        <v-list-item-content>
          <v-list-item-title>{{ $t("calendar.공유설정") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="showSwatches"></v-divider>

      <!-- 캘린더 색상 -->
      <v-list-item v-if="showSwatches" class="pt-1 px-3">
        <v-list-item-content>
          <v-chip-group
            v-model="selectedColor"
            mandatory
            column
            @change="updateCalendar"
          >
            <v-chip
              v-for="color in swatches"
              :key="color"
              :color="color"
              :value="color"
              active-class="elevation-1"
              filter
              pill
            ></v-chip>
          </v-chip-group>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";
import {
  CALENDAR_SWATCHES,
  CALENDAR_TYPE
} from "@/calendar/constant/calConstants";
import DeleteEventsByUntilMenu from "@/calendar/components/calendar/DeleteEventsByUntilMenu.vue";

export default {
  components: { DeleteEventsByUntilMenu },
  props: {
    showButton: {
      type: Boolean,
      required: true
    },
    calendar: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      title: "",
      editTitle: false,
      titleEditable: false,
      titleRules: [
        value => !!value || i18n.t("calendar.150"),
        value => value.length <= 80 || i18n.t("calendar.24")
      ],
      showDeleteBtn: false,
      showDeleteEventsByUntilBtn: false,
      showUnsubscribeBtn: false,
      showShareBtn: false,
      showSwatches: false,
      selectedColor: "",
      swatches: []
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"])
  },
  watch: {
    menu(value) {
      if (value) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions("cal", [
      "updateCalendarToServer",
      "deleteCalendarFromServer",
      "cancelSubscription"
    ]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("share", ["shareDialog"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    init() {
      this.editTitle = false;
      this.title = this.calendar.title;
      this.selectedColor = this.calendar.color;

      this.showDeleteBtn = this.calendar.type === CALENDAR_TYPE.PRIVATE;
      // 기본 캘린더에는 연차 일정이 저장되는데
      // 일괄 삭제 시 연차 일정까지 삭제되므로 해당 기능을 지원하지 않습니다.
      this.showDeleteEventsByUntilBtn =
        this.calendar.type === CALENDAR_TYPE.PRIVATE;

      this.showUnsubscribeBtn =
        this.calendar.type === CALENDAR_TYPE.SUBSCRIPTION ||
        this.calendar.type === CALENDAR_TYPE.PUBLIC_SUBSCRIPTION;

      this.titleEditable = [
        CALENDAR_TYPE.DEFAULT,
        CALENDAR_TYPE.PRIVATE,
        CALENDAR_TYPE.SUBSCRIPTION,
        CALENDAR_TYPE.PUBLIC_SUBSCRIPTION
      ].includes(this.calendar.type);

      this.showShareBtn =
        !this.getUserInfo.isOrgChartRestricted &&
        (this.calendar.type === CALENDAR_TYPE.DEFAULT ||
          this.calendar.type === CALENDAR_TYPE.PRIVATE);

      this.showSwatches = this.calendar.type !== CALENDAR_TYPE.EXTERNAL;

      this.swatches = [...CALENDAR_SWATCHES];
      if (!this.swatches.includes(this.selectedColor)) {
        this.swatches.splice(this.swatches.length, 1, this.selectedColor);
      }
    },
    async updateCalendar() {
      for (const rule of this.titleRules) {
        const result = rule(this.title);
        if (result !== true) {
          this.$refs.title.errorMessages = result;
          this.$refs.title.counter = 80;
          return;
        }
      }

      this.editTitle = false;
      if (
        this.title === this.calendar.title &&
        this.selectedColor === this.calendar.color
      )
        return;

      const param = {
        calendarId: this.calendar.id,
        title: this.title,
        color: this.selectedColor,
        type: this.calendar.type,
        organId: this.calendar.organId
      };
      const result = await this.updateCalendarToServer(param);

      this.openSnackbar({
        message: result ? i18n.t("calendar.35") : i18n.t("calendar.36"),
        type: result ? "SUCCESS" : "ERROR"
      });
    },
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    openDeleteConfirm() {
      this.openConfirmDialog(
        "",
        i18n.t("calendar.108", { value: this.calendar.title }),
        async () => {
          const result = await this.deleteCalendarFromServer({
            id: this.calendar.id,
            name: this.calendar.title
          });

          if (result) {
            this.openSnackbar({
              message: i18n.t("calendar.109", { value: this.calendar.title }),
              type: "SUCCESS"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("calendar.100"),
              type: "ERROR"
            });
          }
        }
      );
    },
    unsubscribe() {
      this.openConfirmDialog(
        "",
        i18n.t("calendar.110", { value: this.calendar.title }),
        async () => {
          const result = await this.cancelSubscription(this.calendar);

          if (result) {
            this.openSnackbar({
              message: i18n.t("calendar.74", { value: this.calendar.title }),
              type: "SUCCESS"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("calendar.111"),
              type: "ERROR"
            });
          }
        }
      );
    },
    openShareDialog() {
      const shareItem = {
        id: this.calendar.id,
        name: this.calendar.title
      };

      this.shareDialog({
        isShared: false,
        moduleType: "CAL",
        shareItem,
        sendMessage: true,
        addCallback: null,
        deleteCallback: null
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "vuetify/src/styles/styles.sass";

::v-deep {
  .v-list-item {
    padding: 0 16px;
    min-height: 40px;

    .v-list-item__content {
      padding: 6px 0;
    }
  }
}

// 제목 수정 시 활성화되는 텍스트 필드
.v-text-field ::v-deep {
  .v-input__control .v-input__slot {
    padding: 8px 16px !important;

    &::before {
      border: none;
    }

    .v-input__append-inner {
      margin-top: 2px;
    }

    .v-input__icon {
      width: 20px;
      min-width: 20px;
      height: 20px;

      .v-icon {
        font-size: 20px;
      }
    }
  }
}

// 캘린더 색상 프리셋
.v-chip-group ::v-deep .v-slide-group__content {
  padding: 0;
}

.v-chip {
  width: 20px;
  height: 20px;
  margin: 3px;
  padding: 0;
  border-radius: 10px;

  &:hover {
    box-shadow: map-get($shadow-key-umbra, 2), map-get($shadow-key-penumbra, 2),
      map-get($shadow-key-ambient, 2);
  }

  // 선택 상태 아이콘
  ::v-deep .v-icon {
    color: white;
    margin-left: 2px;
    margin-right: -2px;
    font-size: 16px;
  }
}
</style>
