import i18n from "@/_locales";

const state = {
  show: false, // on/off
  title: "", // 타이틀
  type: "", // 다이얼로그 타입
  params: {}, // 파라미터
  // 다이얼로그 하단 좌측 버튼
  leftBtn: {
    show: false,
    text: ""
  },
  // 다이얼로그 하단 좌측 필수항목 문구 보여줄지 여부
  showRequired: false,
  // 다이얼로그 하단 우측 취소버튼(우측기준 두번째버튼)
  cancelBtn: "",
  // 다이얼로그 하단 우측 저장버튼(우측기준 첫번째버튼)
  confirmBtn: "",
  confirmCallbackFunc: () => {}
};

const getters = {
  showDialog: ({ show }) => show,
  dialogTitle: ({ title }) => title,
  dialogType: ({ type }) => type,
  getParams: ({ params }) => params,
  getLeftBtn: ({ leftBtn }) => leftBtn,
  isShowRequired: ({ showRequired }) => showRequired,
  getCancelBtn: ({ cancelBtn }) => cancelBtn,
  getConfirmBtn: ({ confirmBtn }) => confirmBtn
};

const mutations = {
  SET_DIALOG: (
    state,
    {
      title,
      type,
      params,
      leftBtn,
      showRequired = false,
      cancelBtn,
      confirmBtn,
      confirmCallbackFunc = () => {}
    }
  ) => {
    state.show = true;
    state.title = title;
    state.type = type || state.type;
    state.params = params || state.params;
    state.leftBtn = leftBtn || state.leftBtn;
    state.showRequired = showRequired;
    state.cancelBtn = cancelBtn || {
      show: true,
      text: i18n.t("common.취소")
    };
    state.confirmBtn = confirmBtn || {
      show: true,
      text: i18n.t("common.저장")
    };
    state.confirmCallbackFunc = confirmCallbackFunc;
  },
  CLOSE_DIALOG: state => {
    state.show = false;
    state.title = "";
    state.type = "";
    state.params = {};
    state.leftBtn = {
      show: false,
      text: ""
    };
    state.showRequired = false;
    state.cancelBtn = {
      show: true,
      text: i18n.t("common.취소")
    };
    state.confirmBtn = {
      show: true,
      text: i18n.t("common.저장")
    };
    state.confirmCallbackFunc = () => {};
  },
  // 좌측버튼 이름 변경
  UPDATE_LEFT_BTN_NAME: (state, name) => (state.leftBtn.text = name),
  // 타이틀 변경
  UPDATE_TITLE: (state, title) => (state.title = title)
};

const actions = {
  // 콜백함수
  confirmCallback({ state }, item) {
    state.confirmCallbackFunc(item);
  },
  async close({ commit }) {
    commit("CLOSE_DIALOG");
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
