import cal from "./modules/calendar";
import extCal from "@/calendar/store/modules/externalCalendar";
import calRoute from "./modules/route";
import calLayout from "./modules/layout";

export default {
  cal,
  extCal,
  calRoute,
  calLayout
};
