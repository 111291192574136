import Vue from "vue";
import i18n from "@/_locales";
import router from "@/commons/router";
import { mailboxIcons } from "@/commons/utils/icons";
import {
  getDefaultFolders,
  getFolderById,
  addFolder,
  updateFolderName,
  updateFolderColor,
  updateSearchFolder,
  updateTagFolder,
  deleteFolder,
  moveFolder,
  syncFolder
} from "../../api/folder.api";
import {
  systemFolderType,
  rootFolderType,
  privateFolderType,
  shareFolderType,
  quickMenuEnum
} from "@/mail/constant/folderType";

const root = { root: true };
const state = {
  tags: [],
  folders: [],
  openFolders: [],
  serializationFolders: []
};

const getters = {
  // 현재 폴더
  currentFolder: ({ folders }, getters, rootState) => {
    const { folderId } = rootState.mailRoute.mail_list;
    return getFolder(folders, folderId);
  },
  isTag: (state, getters) => {
    return getters.currentFolder?.folderType == "TAG";
  },
  isSent: (state, getters) => {
    return getters.currentFolder?.folderType == "SENT";
  },
  isDrafts: (state, getters) => {
    return getters.currentFolder?.folderType == "DRAFTS";
  },
  isSearch: (state, getters) => {
    return getters.currentFolder?.folderType == "SEARCH";
  },
  isDeleted: (state, getters) => {
    return getters.currentFolder?.folderType == "DELETED";
  },
  isTome: (state, getters) => {
    return getters.currentFolder?.folderType == "TOME";
  },
  isSpam: (state, getters) => {
    return getters.currentFolder?.folderType == "SPAM";
  },
  isShareRoot: (state, getters, rootState) => {
    const { actionObj } = rootState.mailRoute.mail_list;
    const { sfi, owi } = JSON.parse(actionObj);

    return (
      !!shareFolderType[getters.currentFolder?.folderType] &&
      !parseInt(sfi) &&
      !parseInt(owi)
    );
  },
  isSharedRoot: (state, getters) => {
    return (
      getters.isShareRoot && getters.currentFolder?.folderType == "SHARED_ROOT"
    );
  },
  isShare: (state, getters, rootState) => {
    const { actionObj } = rootState.mailRoute.mail_list;
    const { sfi, owi } = JSON.parse(actionObj);
    if (
      !!shareFolderType[getters.currentFolder?.folderType] &&
      parseInt(sfi) &&
      parseInt(owi)
    ) {
      const [folder] =
        getters.currentFolder.shareFolders?.filter(f => f.id == sfi) || [];
      return folder?.folderType == "SHARE";
    }

    return false;
  },
  currentShareFolder: (state, getters, rootState) => {
    if (!getters.isShare) return null;
    const { actionObj } = rootState.mailRoute.mail_list;
    const { sfi } = JSON.parse(actionObj);
    const [folder] =
      getters.currentFolder.shareFolders?.filter(f => f.id == sfi) || [];
    return folder;
  },
  // 태그 목록
  getTags: ({ tags }) => tags,
  // 메일함 목록 조회
  getFolders: ({ folders }, getters, rootState) => {
    return folders.filter(f => {
      if (quickMenuEnum[f.folderType] < 0) {
        const { quickMenu } = rootState.mailConfig ?? {};
        return quickMenu.indexOf(f.id) > -1;
      }

      // 스마트 메일함
      if (f.folderType == "SMART") {
        const { smartMailbox } = rootState.mailConfig ?? 0;
        return smartMailbox == 1;
      }

      // 태그
      if (f.folderType === "TAG_ROOT") {
        const { useTag } = rootState.mailConfig ?? 0;
        return useTag == 1;
      }

      // 검색 메일함
      if (f.folderType === "SEARCH_ROOT") {
        const { useSearchFolder } = rootState.mailConfig ?? 0;
        return useSearchFolder == 1;
      }

      // 공유받은 메일함
      if (f.folderType === "SHARED_ROOT") {
        const { useShareFolder } = rootState.mailConfig ?? 0;
        return useShareFolder == 1;
      }

      // 공유한 메일함
      // 조직도 사용 제한에 따른 기능 비활성화
      if (f.folderType === "SHARING_ROOT") {
        const { useShareFolder } = rootState.mailConfig ?? 0;
        if (useShareFolder == 0) return false;

        return rootState.auth?.userInfo?.isOrgChartRestricted == 0;
      }

      return true;
    });
  },
  // 메일함 조회
  getFolder: (state, { getFolders }) => fId => getFolder(getFolders, fId),
  // 개인메일함 root 조회
  getPrivateRootId: ({ folders }) =>
    folders.find(f => f.folderType == rootFolderType.PRIVATE_ROOT)?.id,
  // 검색메일함 root 조회
  getSearchRoot: ({ folders }) =>
    folders.find(({ folderType }) => folderType == rootFolderType.SEARCH_ROOT),
  // tag root 조회
  getTagRoot: ({ folders }) =>
    folders.filter(({ folderType }) => folderType == rootFolderType.TAG_ROOT),
  // 휴지통 folderId 조회
  getTrashId: ({ folders }) => folders.find(f => f.folderType == "DELETED")?.id,
  // 받은 메일함 folderId 조회
  getInboxId: ({ folders }) => folders.find(f => f.folderType == "INBOX")?.id,
  getSentId: ({ folders }) => folders.find(f => f.folderType == "SENT")?.id,
  // 해당 메일함 id의 상위 메일함들 모두 조회
  getFullPath: ({ folders }) => folderId => getFullPath(folders, folderId),
  getSerializationFolders: ({ serializationFolders }) => serializationFolders,
  getOpenFolders: ({ openFolders }) => openFolders
};

// 메일함 직렬화
const serialization = (folders, depth = 0, result = []) => {
  if (result.length == 0) {
    // 기본편지함 셋팅
    ["ALL", "INBOX", "TOME", "SENT", "DELETED"].forEach(type => {
      const [folder] = folders.filter(f => f.folderType == type);
      if (folder) result.push(folder);
    });
    const [privateRoot] = folders.filter(f => f.folderType == "PRIVATE_ROOT");
    if (!privateRoot) return result;
    serialization(privateRoot.children, 0, result);
  } else {
    folders.forEach(f => {
      result.push({ ...f, depth });

      if (f.childCount > 0) {
        serialization(f.children, depth + 1, result);
      }
    });
  }

  return result;
};

// 메일함id를 가지고 해당 메일함 가져오기
const getFolder = (folders, folderId) => {
  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i];
    if (folder.id == folderId) return folder;
    if (folder.children) {
      const childrenResult = getFolder(folder.children, folderId);
      if (childrenResult) return childrenResult;
    }
  }
};

const getFullPath = (folders, folderId, result = []) => {
  const { id, parentId, folderType } = getFolder(folders, folderId) || {};
  if (!id) return [];
  result.push({ id });

  if (parentId == 0) {
    let rootType = "";
    switch (folderType) {
      case "TAG": {
        rootType = rootFolderType.TAG_ROOT;
        break;
      }
      case "SEARCH": {
        rootType = rootFolderType.SEARCH_ROOT;
        break;
      }
      case privateFolderType.PRIVATE:
      case privateFolderType.PRIVATE_SECURE:
      case privateFolderType.PRIVATE_PERMANENT: {
        rootType = rootFolderType.PRIVATE_ROOT;
        break;
      }
      default: {
        return result;
      }
    }

    const [{ id }] = folders.filter(f => f.folderType == rootType);
    result.push({ id });
    return result;
  }

  return getFullPath(folders, parentId, result);
};

// 하위 폴더 까지 안읽은 메일 갯수 수정해주는 함수
const newCountUpdateFolder = folders => {
  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i];
    if (privateFolderType[folder.folderType]) folder.newCount = 0;
    if (folder.children) newCountUpdateFolder(folder.children);
  }
};

// 태그데이터 폴더형식으로 변환
const convertTag = (tags, parentId) =>
  tags.map(({ tag, color }) => ({
    id: `t_${tag}`,
    nameFolder: tag,
    color,
    parentId,
    folderType: "TAG",
    icon: "mdi-tag",
    isEdit: false
  }));

const mutations = {
  SET_TAGS: (state, tags) => (state.tags = tags),
  SET_TAG: (state, tag) => state.tags.push(tag),
  SET_DEFAULT_FOLDERS: (state, data) => {
    if (!data) return;

    let tags = [];
    const folders = data.map(f => {
      const icon = mailboxIcons[f.folderType];

      let nameFolder = i18n.t(`mail.folder_${f.folderType}`);
      if (!systemFolderType[f.folderType]) nameFolder = f.nameFolder;

      let children = f.children?.map(f => ({ ...f }));
      if (f.folderType == "TAG_ROOT" && Array.isArray(f.options?.tags)) {
        tags = f.options.tags;
        children = convertTag(tags, f.id);
      }

      return { ...f, nameFolder, icon, children };
    });
    state.folders = folders;
    state.tags = tags;
    state.serializationFolders = serialization(folders);
  },
  SET_CHILDREN: (state, { parentId, children, count = 0 }) => {
    const folder = getFolder(state.folders, parentId);
    if (!folder) return;

    let child = children;
    if (folder.children) {
      children.forEach(({ id }) => {
        const index = folder.children.findIndex(f => f.id == id);
        if (index > -1) Vue.delete(folder.children, index);
      });

      child = [...folder.children, ...children];
    }

    folder.childCount += count;
    child.sort((a, b) => a.sortOrder - b.sortOrder);
    Vue.set(folder, "children", child);
    Vue.set(state, "serializationFolders", serialization(state.folders));
  },
  // 개인 메일함 이름 수정 필드 toggle
  UPDATE_IS_EDIT: (state, folderId) => {
    const folder = getFolder(state.folders, folderId);
    if (!folder) return;

    Vue.set(folder, "isEdit", !folder?.isEdit);
  },
  // 해당 메일함 이름 변경
  UPDATE_FOLDER_NAME: (state, { id, nameFolder }) => {
    const folder = getFolder(state.folders, id);
    if (!folder) return;

    folder.nameFolder = nameFolder;

    // serializationFolders 도 업데이트
    const index = state.serializationFolders.findIndex(f => f.id == folder.id);
    if (index === -1) return;

    Vue.set(state.serializationFolders[index], "nameFolder", folder.nameFolder);
  },
  // 해당 메일함 색상 변경
  UPDATE_FOLDER_COLOR: (state, { id, color }) => {
    const folder = getFolder(state.folders, id);
    if (!folder) return;

    Vue.set(folder, "color", color);

    // serializationFolders 도 업데이트
    const index = state.serializationFolders.findIndex(f => f.id == folder.id);
    if (index === -1) return;

    Vue.set(state.serializationFolders[index], "color", folder.color);
  },
  // 해당 메일함 삭제
  DELETE_FOLDER: (state, { folderId, parentId }) => {
    const folder = getFolder(state.folders, parentId);
    if (!folder) return;

    const index = folder.children?.findIndex(({ id }) => id == folderId);
    if (folder.children?.length == 1) Vue.delete(folder, "children");
    else if (index > -1) Vue.delete(folder.children, index);

    Vue.set(folder, "childCount", folder.children?.length || 0);
    const sF = state.serializationFolders.filter(f => f.id != folderId);
    Vue.set(state, "serializationFolders", sF);
  },
  // 메일함 count 변경
  UPDATE_FOLDER_NEWCOUNT: (state, { mails, readStatus, rollback = false }) => {
    const folders = [...state.folders];

    mails?.forEach(({ folderId, prevReadStatus }) => {
      const folder = getFolder(folders, folderId);
      if (!folder) return;
      const { id, newCount } = folder;
      if (prevReadStatus !== readStatus) {
        if (readStatus == 0 && !rollback) folder.newCount += 1;
        else folder.newCount = newCount - 1 >= 0 ? newCount - 1 : 0;
      }

      // serializationFolders 도 업데이트
      const index = state.serializationFolders.findIndex(f => f.id == id);
      if (index === -1) return;

      Vue.set(state.serializationFolders[index], "newCount", folder.newCount);
    });

    Vue.set(state, "folders", [...folders]);
  },
  // 전체 읽음 처리후 folder newcount 처리
  INIT_FOLDER_NEWCOUNT: (state, folderId) => {
    const folders = [...state.folders];
    const folder = getFolder(folders, folderId);
    if (!folder) return;

    folder.newCount = 0;
    const index = state.serializationFolders.findIndex(f => f.id == folder.id);
    if (index > -1) Vue.set(state.serializationFolders[index], "newCount", 0);

    // 전체 폴더에서 모두읽음 처리시
    if (folder.folderType == "ALL") {
      const [inbox] = folders.filter(f => f.folderType == "INBOX");
      inbox.newCount = 0;

      // 전체폴더에서 전체읽음시 하위폴더도 안읽은 메일 개수 업데이트
      const [privateRoot] = folders.filter(f => f.folderType == "PRIVATE_ROOT");
      newCountUpdateFolder(privateRoot.children);
      state.serializationFolders.forEach(f => (f.newCount = 0));
    }

    Vue.set(state, "folders", [...folders]);
  },
  SET_FOLDER_META: (state, folder) => {
    const stateFolders = [...state.folders];
    const { id, newCount, totalCount, usedSize } = folder;
    const f = getFolder(stateFolders, id);
    if (f) {
      f.newCount = newCount;
      f.totalCount = totalCount;
      f.usedSize = usedSize;
    }

    Vue.set(state, "folders", [...stateFolders]);

    // serializationFolders 도 업데이트
    const index = state.serializationFolders.findIndex(f => f.id == folder.id);
    if (index === -1) return;

    Vue.set(state.serializationFolders, index, {
      ...state.serializationFolders[index],
      newCount,
      totalCount,
      usedSize
    });
  },
  UPDATE_FOLDER_META: (state, folders) => {
    const stateFolders = [...state.folders];
    const folderIds = Object.keys(folders);
    folderIds.forEach(id => {
      const { newCount, totalCount, usedSize } = folders[id];
      const folder = getFolder(stateFolders, id);
      if (!folder) return;

      folder.newCount = folder.newCount + newCount;
      folder.totalCount = folder.totalCount + totalCount;
      folder.usedSize = folder.usedSize + usedSize;

      // serializationFolders 도 업데이트
      const idx = state.serializationFolders.findIndex(f => f.id == folder.id);
      if (idx === -1) return;

      Vue.set(state.serializationFolders, idx, {
        ...state.serializationFolders[idx],
        newCount: folder.newCount,
        totalCount: folder.totalCount,
        usedSize: folder.usedSize
      });
    });

    Vue.set(state, "folders", [...stateFolders]);
  },
  SET_OPEN_FOLDERS: (state, openFolders) => {
    state.openFolders = openFolders;
  },
  DELETE_OPEN_FOLDERS: (state, folderId) => {
    const index = state.openFolders.findIndex(f => f.id == folderId);
    Vue.delete(state.openFolders, index);
  },
  // 내 메일함 교체
  CHANGE_PRIVATE_FOLDER: (state, privateList) => {
    const index = state.folders.findIndex(f => f.folderType === "PRIVATE_ROOT");
    if (index === -1) return;

    const stateFolders = [...state.folders];
    stateFolders[index].children = privateList;
    Vue.set(state, "folders", [...stateFolders]);

    // serializationFolders 도 업데이트
    state.serializationFolders = serialization(stateFolders);
  },
  SET_SHARE_FOLDERS: (state, shareFolders) => {
    const index = state.folders.findIndex(f => f.folderType == "SHARED_ROOT");
    if (index > -1) state.folders[index]["shareFolders"] = shareFolders;
  }
};

const actions = {
  /**
   * 기본 메일함 목록 조회
   */
  async getDefaultFolders({ commit }) {
    const { data = {}, status } = await getDefaultFolders();
    commit("SET_DEFAULT_FOLDERS", data);
    return status === 200;
  },
  /**
   * 메일함 조회
   */
  async getFolderById(action, id) {
    const { data, status } = await getFolderById(id);
    if (status !== 200) return {};
    return { ...data, ...(data?.options || {}) };
  },
  /**
   * 메일함 생성
   *
   * @param {*} nameFolder 메일함 이름
   * @param {*} folderType 메일함 타입
   * @param {*} parentId   부모 메일함 id
   * @param {*} options    메일함 옵션
   */
  async addFolder({ commit, dispatch }, params) {
    let parentId = params.parentId > 0 ? params.parentId : 0;
    const { data, status } = await addFolder({ ...params, parentId });

    let type = "ERROR";
    let message = i18n.t("mail.6");
    if (status === 201) {
      type = "SUCCESS";
      message = i18n.t("mail.5", { value: params.nameFolder });
      parentId = params.parentId;
      commit("SET_CHILDREN", { parentId, children: [data], count: 1 });
    }

    dispatch("snackbar/openSnackbar", { message, type }, root);
    return data || {};
  },
  /**
   * 개인 메일함명 수정
   *
   * @param {*} nameFolder 메일함 이름
   * @param {*} folderId   메일함 id
   */
  async updateFolderName({ commit, dispatch }, { nameFolder, folderId }) {
    const { data, status } = await updateFolderName({ nameFolder, folderId });
    if (status === 201) return commit("UPDATE_FOLDER_NAME", data);

    const type = "ERROR";
    const message = i18n.t("mail.7");
    dispatch("snackbar/openSnackbar", { message, type }, root);
  },
  /**
   * 개인 메일함 색상 수정
   *
   * @param {*} color 메일함 색상
   * @param {*} folderId   메일함 id
   */
  async updateFolderColor({ commit, dispatch }, { color, folderId }) {
    const { data, status } = await updateFolderColor({ color, folderId });
    if (status === 201) return commit("UPDATE_FOLDER_COLOR", data);

    const type = "ERROR";
    const message = i18n.t("mail.450");
    dispatch("snackbar/openSnackbar", { message, type }, root);
  },
  /**
   * 검색 메일함 수정
   *
   * @param {*} folderId   메일함 id
   * @param {*} nameFolder 메일함 이름
   * @param {*} options    검색 옵션
   */
  async updateSearchFolder({ commit, dispatch }, params) {
    const { data, status } = await updateSearchFolder(params);
    if (status === 201) return commit("UPDATE_FOLDER_NAME", data);

    const type = "ERROR";
    const message = i18n.t("mail.8");
    dispatch("snackbar/openSnackbar", { message, type }, root);
  },
  /**
   * 메일함 삭제
   *
   * @param {*} folderId 메일함 id
   * @param {*} parentId 상위 메일함 id
   */
  async deleteFolder({ state, rootState, getters, commit, dispatch }, params) {
    const { folderId, parentId } = params;
    const folder = getFolder(state.folders, folderId);
    if (!folder) return;

    let type = "ERROR";
    let message = "";
    const { folderType, children, totalCount } = folder;
    if (!["PRIVATE", "SEARCH"].includes(folderType)) message = i18n.t("mail.9"); // 폴더타입이 개인, 검색 아닐경우
    if (children) message = i18n.t("mail.10"); // 자식 폴더가 있을때
    if (totalCount) message = i18n.t("mail.11"); // 메일이 존재할때
    if (message) {
      dispatch("snackbar/openSnackbar", { message, type }, root);
      return;
    }

    const { status } = await deleteFolder(folderId);
    if (status !== 200) {
      message = i18n.t("mail.12");
      dispatch("snackbar/openSnackbar", { message, type }, root);
      return;
    }

    // 삭제하는 폴더가 열려있다면 닫아준다.
    let id = !parentId ? getters.getPrivateRootId : parentId;
    const index = state.openFolders?.findIndex(f => f.id == id);
    if (index >= 0 && state.openFolders[index]?.children?.length == 1) {
      commit("DELETE_OPEN_FOLDERS", id);
    }
    setTimeout(() => {
      commit("DELETE_FOLDER", { folderId, parentId: id });
    }, 0);

    let { mail_list } = rootState.mailRoute;
    const { name: routeName } = router.currentRoute;
    if (folderId !== mail_list.folderId) return;

    // 기본폴더가 삭제된 폴더일 경우 전체메일로 변경
    if (rootState.mailConfig.firstScreenFolderId == folderId) {
      let action = "mailConfig/SET_MAIL_CONFIG";
      await commit(action, { firstScreenFolderId: -1 }, root);
      action = "mailConfig/updateFirstScreen";
      await dispatch(action, null, root);
    }

    // 폴더 삭제후 현재 라우팅이 메일목록이고 해당 폴더를 보고있다면 기본 폴더로 변경
    const p = { folderId: rootState.mailConfig.firstScreenFolderId, page: 1 };
    if (
      routeName == "mail" ||
      routeName == "mail_list" ||
      routeName == "mail_list_action"
    ) {
      await dispatch("mailRoute/routeMailList", p, root);
      return;
    }

    // 목록 갱신만 해준다.
    mail_list = { ...mail_list, ...p };
    await commit("mailRoute/SET_ROUTE_PARAMS", { mail_list }, root);
    await dispatch("mail/getList", mail_list, root);
  },
  /**
   *
   * @param {*} id
   * @param {*} targetFolderId
   * @param {*} position
   */
  async moveFolder({ state, commit }, { id, targetFolderId, position }) {
    const { data, status } = await moveFolder({ id, targetFolderId, position });
    if (status !== 201) return;

    // 열려있는 폴더 닫기
    state.openFolders.forEach(of => {
      if (of.id < 0 || of.folderType == "TAG_ROOT") return;
      const f = getFolder(data, of.id);
      if (f.childCount !== 0) return;
      commit("DELETE_OPEN_FOLDERS", of.id);
    });

    // 폴더 열기 및 옮기기
    setTimeout(() => {
      commit("CHANGE_PRIVATE_FOLDER", data);

      if (position !== "inner") return;
      const targetFolder = getFolder(state.folders, targetFolderId);
      commit("SET_OPEN_FOLDERS", [...state.openFolders, targetFolder]);
    }, 0);
  },
  /**
   * 빠른메일함 복구
   */
  async syncFolder({ commit }) {
    const { data, status } = await syncFolder();
    if (status !== 201) return;

    commit("SET_DEFAULT_FOLDERS", data);
  },
  /**
   * 태그 목록에 추가
   */
  async addTagList({ getters, commit }, { tag, color }) {
    const [{ id: parentId }] = getters.getTagRoot ?? [{}];
    const params = { folderId: parentId, tag, color, type: "add" };
    const { status, data } = await updateTagFolder(params);
    if (status !== 201) return;

    commit("SET_TAGS", data);
    commit("SET_CHILDREN", { parentId, children: convertTag(data, parentId) });
  },
  /**
   * 태그 색상 변경
   */
  async updateTagColor({ getters, commit }, { tag, color }) {
    const [{ id: parentId }] = getters.getTagRoot ?? [{}];
    const params = { folderId: parentId, tag, color, type: "update" };
    const { status, data } = await updateTagFolder(params);
    if (status !== 201) return;

    commit("SET_TAGS", data);
    commit("SET_CHILDREN", { parentId, children: convertTag(data, parentId) });
  },
  /**
   * 태그 목록에서 제거
   */
  async deleteTagList({ getters, rootState, commit }, { tag }) {
    const [{ id: parentId }] = getters.getTagRoot ?? [{}];
    const params = { folderId: parentId, tag, type: "delete" };
    const { status, data } = await updateTagFolder(params);
    if (status !== 201) return;

    commit("SET_TAGS", data);
    commit("DELETE_FOLDER", { folderId: `t_${tag}`, parentId });

    const { mail_list } = rootState.mailRoute;
    const { folderId } = mail_list;
    if (!`${folderId}`.startsWith("t_")) return;

    router.push({ name: "mail" });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
