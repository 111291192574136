export default [
  {
    path: "/flow",
    name: "flow",
    component: () => import("../views/FlowMain.vue"),
    children: [
      {
        path: "projects",
        name: "flow_projects"
      },
      {
        path: ":projectId/tasks",
        name: "flow_tasks"
      },
      {
        path: ":projectId/tasks/:actionObj",
        name: "flow_tasks_action"
      }
    ]
  }
];
