import moment from "moment";
import {
  addCalendar,
  cancelPublicSubscription,
  deleteCalendar,
  deleteEvent,
  getCalendarConfig,
  getCalendars,
  getEvents,
  saveEvent,
  searchEventList,
  searchFreebusy,
  sendCalReplyMail,
  updateCalendar,
  updateEvent,
  updatePartStat,
  updateTreeStatus
} from "../../api/cal.api";

import { getDefaultOrganList } from "@/mail/api/organ.api";
import i18n from "@/_locales";
import {
  simpleWrapSirteamEvent,
  wrapSirTeamEvents
} from "@/calendar/utils/EventUtils";
import { wrapSirTeamCalendar } from "@/calendar/utils/CalendarUtils";
import { CALENDAR_TYPE } from "@/calendar/constant/calConstants";
import Vue from "vue";

const state = {
  calendarHeaderTitle: "",
  calendars: [],
  // 월 단위로 이벤트를 보관하는 객체. "2023-10"과 같은 값을 키로 사용
  monthlyEventCache: {},
  filterOption: {
    dept: {
      value: true
    },
    leave: {
      value: true,
      isAdvanced: true
    }
  },
  createEventOnFocusedDate: () => {},
  tempEvents: [],
  activeEventEditor: null,
  eventEditor: {
    x: 0,
    y: 0,
    // 반복 일정 중 예외 일정 수정 여부
    isExceptionRequest: false,
    // 일정 편집기 위치 고정 여부
    // 일정 편집기 위치를 이동할 수 있는 각 컴포넌트에서 판단하여 사용( CalendarFrame )
    fixed: false
  },
  editEvent: null,
  eventMenu: {
    event: null,
    element: null
  },
  eventSummaryMenu: {
    event: null,
    element: null
  },
  showLoadingOverlay: false,
  currentStartDt: 0,
  currentEndDt: 0,
  // 캘린더에서 전역적으로 사용하는 타임존 아이디,
  tzId: "Asia/Seoul"
};

const getters = {
  getEventsInDateRange: state => {
    if (state.currentStartDt === 0 || state.currentEndDt === 0) return [];

    const result = new Map();
    const collectEvents = month => {
      (state.monthlyEventCache[month]?.events || []).forEach(event => {
        if (!state.filterOption.leave.value && event.detail?.isLeave) {
          return;
        }

        if (
          !state.filterOption.dept.value &&
          event.calendar?.type === CALENDAR_TYPE.ORGANIZATION
        ) {
          return;
        }

        // 4일/주 단위 보기 시 VCalendar 최적화를 위해 기간 내에 포함되는 이벤트만 필터링
        if (
          event.start.getTime() <= state.currentEndDt &&
          event.end.getTime() >= state.currentStartDt
        ) {
          // 이벤트 기간이 여러 달에 걸치는 경우 각 월에 이벤트가 중복으로 존재하기 때문에
          // 이벤트 ID + 시작 시간을 기준으로 중복을 제거합니다.
          result.set(event.id + event.start.getTime(), event);
        }
      });
    };

    const startMonth = moment(state.currentStartDt).format("YYYY-MM");
    const endMonth = moment(state.currentEndDt).format("YYYY-MM");

    collectEvents(startMonth);
    if (startMonth !== endMonth) {
      collectEvents(endMonth);
    }

    return Array.from(result.values());
  },
  hasCachedEvents: state => month => {
    return (
      state.monthlyEventCache[month] && !state.monthlyEventCache[month].fetching
    );
  },
  hasValidCachedEvents: state => (month, validTime = 60000) => {
    return (
      state.monthlyEventCache[month] &&
      !state.monthlyEventCache[month].fetching &&
      // 이벤트가 캐싱된지 일정 시간( 기본 1분 ) 지난 경우 유효하지 않은 데이터로 판단
      Date.now() - state.monthlyEventCache[month].lastSyncTime < validTime
    );
  },
  getSelectedCalendars: ({ calendars }) =>
    calendars.filter(calendar => calendar.selected),
  getMyCalendars: ({ calendars }) => {
    return calendars.filter(
      calendar =>
        calendar.type === CALENDAR_TYPE.DEFAULT ||
        calendar.type === CALENDAR_TYPE.PRIVATE
    );
  },
  getSubscribedCalendars: ({ calendars }) => {
    const sharedCalendars = calendars.filter(
      calendar =>
        calendar.type === CALENDAR_TYPE.SUBSCRIPTION ||
        calendar.type === CALENDAR_TYPE.PUBLIC_SUBSCRIPTION
    );

    // 캘린더 이름 순 정렬
    sharedCalendars.sort((a, b) => a.title.localeCompare(b.title));

    return sharedCalendars;
  },
  getOrganCalendars: ({ calendars }, getters, rootState) => {
    const organCalendars = calendars.filter(
      calendar => calendar.type === CALENDAR_TYPE.ORGANIZATION
    );

    // 캘린더 이름 순 정렬
    organCalendars.sort((a, b) => a.title.localeCompare(b.title));

    // 본인 소속 조직 맨 위로
    if (rootState.auth?.userInfo) {
      const userOrganIdx = organCalendars.findIndex(
        calendar => calendar.organId === rootState.auth?.userInfo.organId
      );
      if (userOrganIdx !== -1) {
        organCalendars.unshift(organCalendars.splice(userOrganIdx, 1)[0]);
      }
    }

    return organCalendars;
  },
  getTzId: state => state.tzId,
  getShowLoadingOverlay: ({ showLoadingOverlay }) => showLoadingOverlay
};

const mutations = {
  SET_CALENDARS: (state, items) => (state.calendars = items),
  SET_FILTER_OPTION: (state, { option, value }) =>
    (state.filterOption[option].value = value),
  SET_MONTHLY_EVENTS: (state, { month, eventsInfo }) =>
    Vue.set(state.monthlyEventCache, month, eventsInfo),
  /**
   * 대상 월에 속하지 않는 이벤트를 캐시에서 삭제합니다.
   * @param months 삭제에서 제외할 월 목록. 없으면 전체 삭제
   */
  DELETE_EVENTS_EXCLUDING_MONTHS: (state, months) => {
    if (!months?.length) state.monthlyEventCache = {};

    Object.keys(state.monthlyEventCache).forEach(month => {
      if (!month.includes(month)) {
        delete state.monthlyEventCache[month];
      }
    });
  },
  /**
   * 캘린더 ID가 일치하는 이벤트를 캐시에서 삭제합니다.
   */
  DELETE_EVENTS_IN_CALENDAR: (state, calendarIds) => {
    const months = Object.keys(state.monthlyEventCache);
    for (const month of months) {
      const filteredEvents = state.monthlyEventCache[month].events.filter(
        event => !calendarIds.includes(event.calendar.id)
      );

      if (
        state.monthlyEventCache[month].events.length !== filteredEvents.length
      ) {
        state.monthlyEventCache[month].events = filteredEvents;
      }
    }
  },
  /**
   * 이벤트 캐시가 저장 한도를 넘은 경우 현재 조회 범위 밖의 이벤트부터 삭제합니다.
   */
  DELETE_OUTSIDE_EVENTS: state => {
    const MAX_MONTHS = 4;
    const start = moment(state.currentStartDt);
    const end = moment(state.currentEndDt);
    const monthOfViewRange = [
      start.format("YYYY-MM"),
      end.format("YYYY-MM"),
      start.subtract(1, "month").format("YYYY-MM"),
      end.add(1, "month").format("YYYY-MM")
    ];

    const months = Object.keys(state.monthlyEventCache);
    if (months.length <= MAX_MONTHS) return;

    let itemsToDeleteCount = months.length - MAX_MONTHS;
    for (let i = 0; i < months.length; i++) {
      // 현재 조회 범위 근처의 월은 삭제하지 않습니다.
      if (!monthOfViewRange.includes(months[i])) {
        delete state.monthlyEventCache[months[i]];
        if (--itemsToDeleteCount === 0) break;
      }
    }
  },
  SET_DATE_RANGE(state, payload) {
    state.currentStartDt = payload.startDt;
    state.currentEndDt = payload.endDt;
  },
  SET_CREATE_EVENT_FUNC(state, func) {
    state.createEventOnFocusedDate = func;
  },
  UPDATE_EVENT_TIME: (state, { event, start, end, isAllDay }) => {
    event.start = start;
    event.end = end;
    if (isAllDay !== undefined) {
      event.isAllDay = isAllDay;
    }

    // 월 보기 화면에서 사용자 일정 우선 표시 시 사용되는 시간을 갱신합니다.
    // 차후 복구 시 기존 정렬 상태를 유지하기 위해 정렬에 사용되는 시/분/초 값은 유지합니다.
    event.startForOrdering = new Date(event.startForOrdering);
    event.startForOrdering.setFullYear(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );
  },
  SET_ACTIVE_EVENT_EDITOR: (state, activeEventEditor) =>
    (state.activeEventEditor = activeEventEditor),
  SET_EVENT_EDITOR: (state, { x, y, isExceptionRequest, fixed }) => {
    state.eventEditor.x = x || state.eventEditor.x;
    state.eventEditor.y = y || state.eventEditor.y;
    state.eventEditor.isExceptionRequest = !!isExceptionRequest;
    state.eventEditor.fixed = !!fixed;
  },
  SET_EDIT_EVENT: (state, event) => {
    state.editEvent = event;
    if (event.isCreating && state.tempEvents.indexOf(state.editEvent) === -1) {
      state.tempEvents.push(state.editEvent);
    }
  },
  REPLACE_EDIT_EVENT_PROPS: (state, event) => {
    state.editEvent.name = event.name;
    state.editEvent.calendar = event.calendar;
    state.editEvent.start = event.start;
    state.editEvent.startForOrdering = event.startForOrdering;
    state.editEvent.end = event.end;
    state.editEvent.isAllDay = event.isAllDay;
  },
  UPDATE_EDIT_EVENT: (state, { propName, value }) => {
    // 컴포넌트에서 Vuex 상태 변경 시 오류 로그를 출력하기 때문에 이를 우회하기 위해 사용합니다.
    state.editEvent[propName] = value;
    if (propName === "start") {
      state.editEvent.startForOrdering = new Date(
        state.editEvent.startForOrdering
      );
      state.editEvent.startForOrdering.setFullYear(
        value.getFullYear(),
        value.getMonth(),
        value.getDate()
      );
    }
  },
  REMOVE_EDIT_EVENT: state => {
    if (state.editEvent) {
      const i = state.tempEvents.indexOf(state.editEvent);
      if (i !== -1) {
        state.tempEvents.splice(i, 1);
      }

      state.editEvent = null;
    }
  },
  SET_EVENT_MENU: (state, { event, element }) => {
    state.eventMenu.event = event;
    state.eventMenu.element = element;
  },
  SET_EVENT_SUMMARY_MENU: (state, { event, element }) => {
    state.eventSummaryMenu.event = event;
    state.eventSummaryMenu.element = element;
  },
  SHOW_LOADING_OVERLAY: state => (state.showLoadingOverlay = true),
  HIDE_LOADING_OVERLAY: state => (state.showLoadingOverlay = false),
  SET_CALENDAR_TITLE: (state, title) => (state.calendarHeaderTitle = title)
};

const actions = {
  /**
   * 최신 캘린더 데이터를 동기화합니다.
   */
  async syncSirTeamData({ getters, commit, dispatch }) {
    commit("SHOW_LOADING_OVERLAY");

    await dispatch("fetchSirTeamCalendars");
    if (getters.getSelectedCalendars.length === 0) {
      commit("HIDE_LOADING_OVERLAY");
      return;
    }

    const startMonth = moment(state.currentStartDt).format("YYYY-MM");
    const endMonth = moment(state.currentEndDt).format("YYYY-MM");

    await dispatch("fetchAndCacheMonthlyEvents", {
      month: startMonth,
      calendars: getters.getSelectedCalendars,
      isMerge: false
    });

    // 4일/주 보기는 시작, 종료 월이 다를 수 있습니다. 이 경우 종료 월의 이벤트까지 조회합니다.
    if (startMonth !== endMonth) {
      await dispatch("fetchAndCacheMonthlyEvents", {
        month: endMonth,
        calendars: getters.getSelectedCalendars,
        isMerge: false
      });
    }

    // 앞서 가져온 월을 제외한 캐싱된 데이터 삭제
    commit("DELETE_EVENTS_EXCLUDING_MONTHS", [startMonth, endMonth]);
    commit("HIDE_LOADING_OVERLAY");

    dispatch("prefetchCalendarsEvents", {
      calendars: getters.getSelectedCalendars,
      startMonth,
      endMonth
    });
  },
  /**
   * 캘린더에 표시할 이벤트를 불러옵니다.
   * @param bypassCache 캐시가 있더라도 새로 조회합니다.
   */
  async loadSirTeamEvents({ getters, commit, dispatch }, bypassCache = false) {
    const startMonth = moment(state.currentStartDt).format("YYYY-MM");
    const endMonth = moment(state.currentEndDt).format("YYYY-MM");

    // 캐시 유효성에 상관 없이 캐시가 있으면 UX를 위해 로딩 미표시
    if (
      !getters.hasCachedEvents(startMonth) ||
      !getters.hasCachedEvents(endMonth)
    ) {
      commit("SHOW_LOADING_OVERLAY");
    }

    if (
      !state.calendars.length ||
      bypassCache ||
      (await dispatch("requiredSync", startMonth)) ||
      (await dispatch("requiredSync", endMonth))
    ) {
      await dispatch("fetchSirTeamCalendars");
    }
    if (getters.getSelectedCalendars.length === 0) {
      commit("HIDE_LOADING_OVERLAY");
      return;
    }

    await dispatch("fetchAndUpdateEventsIfNeeded", {
      month: startMonth,
      bypassCache
    });

    // 4일/주 보기는 시작, 종료 월이 다를 수 있습니다. 이 경우 종료 월의 이벤트까지 조회합니다.
    if (startMonth !== endMonth) {
      await dispatch("fetchAndUpdateEventsIfNeeded", {
        month: endMonth,
        bypassCache
      });
    }

    commit("HIDE_LOADING_OVERLAY");

    dispatch("prefetchCalendarsEvents", {
      calendars: getters.getSelectedCalendars,
      startMonth,
      endMonth
    });
  },
  requiredSync({ getters }, month) {
    return (
      !getters.hasCachedEvents(month) || !getters.hasValidCachedEvents(month)
    );
  },
  /**
   * 이벤트의 캐싱 상태에 따라 이벤트를 조회 혹은 갱신합니다.
   */
  async fetchAndUpdateEventsIfNeeded(
    { getters, dispatch },
    { month, bypassCache }
  ) {
    /*
     * - 캐시가 있지만 유효하지 않으면 비동기 조회하여 데이터 최신화
     * - 캐시가 없지만 조회 중인 작업이 있으면 대기, 없으면 동기 조회
     * - 유효한 캐시가 있는 경우 조회하지 않음
     */
    if (getters.hasCachedEvents(month)) {
      if (!getters.hasValidCachedEvents(month) || !!bypassCache) {
        dispatch("fetchAndCacheMonthlyEvents", {
          month: month,
          calendars: getters.getSelectedCalendars,
          isMerge: false
        });
      }
    } else {
      if (state.monthlyEventCache[month]) {
        await state.monthlyEventCache[month].fetching;
      } else {
        await dispatch("fetchAndCacheMonthlyEvents", {
          month: month,
          calendars: getters.getSelectedCalendars,
          isMerge: false
        });
      }
    }

    if (
      getters.hasCachedEvents(month) &&
      !getters.hasValidCachedEvents(month)
    ) {
      dispatch("fetchAndCacheMonthlyEvents", {
        month: month,
        calendars: getters.getSelectedCalendars,
        isMerge: false
      });
    } else if (!getters.hasCachedEvents(month)) {
      if (state.monthlyEventCache[month]) {
        await state.monthlyEventCache[month].fetching;
      } else {
        await dispatch("fetchAndCacheMonthlyEvents", {
          month: month,
          calendars: getters.getSelectedCalendars,
          isMerge: false
        });
      }
    }
  },
  async addCalendarToServer({ dispatch }, param) {
    const response = await addCalendar(param);
    if (!response.message) {
      await dispatch("syncSirTeamData");
      return true;
    }
  },
  /**
   * 캘린더 목록을 조회합니다. 백엔드는 캘린더 목록 조회 시 이벤트를 동기화함에 유의합니다.
   */
  async fetchSirTeamCalendars({ commit, dispatch, rootGetters }) {
    const response = await getCalendars();
    if (response.message || !response.data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.1"), type: "ERROR" },
        { root: true }
      );

      return;
    }

    const userInfo = rootGetters["auth/getUserInfo"];
    const wrappedCalendars = response.data.map(calendar =>
      wrapSirTeamCalendar(calendar)
    );

    if (userInfo.isOrgChartRestricted) {
      commit(
        "SET_CALENDARS",
        wrappedCalendars.filter(
          calendar => calendar.type !== CALENDAR_TYPE.ORGANIZATION
        )
      );
    } else {
      commit("SET_CALENDARS", wrappedCalendars);
    }
  },
  async updateTreeCalendarStatus({ commit, dispatch }, { calendarId, status }) {
    commit("SHOW_LOADING_OVERLAY");

    const response = await updateTreeStatus({
      calendarId,
      isChecked: status
    });
    if (response.message) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.2"), type: "ERROR" },
        { root: true }
      );
      commit("HIDE_LOADING_OVERLAY");
      return;
    }

    // 캘린더, 이벤트 동기화
    await dispatch("fetchSirTeamCalendars");

    if (status) {
      await dispatch("fetchAndMergeCalendarsEvents", {
        calendars: [
          state.calendars.find(calendar => calendar.id === calendarId)
        ]
      });
    } else {
      commit("DELETE_EVENTS_IN_CALENDAR", [calendarId]);
    }

    commit("HIDE_LOADING_OVERLAY");
  },
  async deleteCalendarFromServer({ dispatch }, { id, name }) {
    const response = await deleteCalendar({ id, title: name });
    if (!response.message) {
      await dispatch("syncSirTeamData");
      return true;
    }
  },
  async updateCalendarToServer({ dispatch }, param) {
    const response = await updateCalendar(param);
    if (!response.message) {
      await dispatch("syncSirTeamData");
      return true;
    }
  },
  /**
   * 현재 조회 범위의 이전 월과 다음 월의 이벤트를 미리 조회합니다.
   * 조회 범위( 일/주/월 ) 이동 시 사용자 UX를 위한 미리보기 용도이기 때문에
   * 데이터 정확성은 중요하지 않습니다. 따라서 캐싱된 데이터가 있는 경우 조회하지 않습니다.
   * @param startMonth 현재 조회 시작 월
   * @param endMonth 현재 조회 종료 월
   */
  async prefetchCalendarsEvents(
    { getters, dispatch },
    { calendars, startMonth, endMonth }
  ) {
    const preMonth = moment(startMonth)
      .subtract(1, "month")
      .format("YYYY-MM");
    const nextMonth = moment(endMonth)
      .add(1, "month")
      .format("YYYY-MM");

    for (const month of [preMonth, nextMonth]) {
      if (getters.hasCachedEvents(month)) continue;

      await dispatch("fetchAndCacheMonthlyEvents", {
        month,
        calendars,
        isMerge: false
      });
    }
  },
  /**
   * 이벤트를 조회한 후 캐싱되어 있는 이벤트 목록과 병합합니다.
   */
  async fetchAndMergeCalendarsEvents({ dispatch }, { calendars }) {
    if (!calendars?.length) return;

    const startMonth = moment(state.currentStartDt).format("YYYY-MM");
    const endMonth = moment(state.currentEndDt).format("YYYY-MM");

    await dispatch("fetchAndCacheMonthlyEvents", {
      month: startMonth,
      calendars,
      isMerge: true
    });

    if (startMonth !== endMonth) {
      await dispatch("fetchAndCacheMonthlyEvents", {
        month: endMonth,
        calendars,
        isMerge: true
      });
    }

    // 비동기로 캐싱된 월까지 조회 후 병합
    const cachedMonths = Object.keys(state.monthlyEventCache).filter(
      month => month !== startMonth && month !== endMonth
    );
    for (const month of cachedMonths) {
      dispatch("fetchAndCacheMonthlyEvents", {
        month,
        calendars,
        isMerge: true
      });
    }
  },
  /**
   * 월 단위로 이벤트를 조회하여 캐싱합니다.
   * @param isMerge 조회한 이벤트를 기존 이벤트 목록과 병합합니다.
   */
  async fetchAndCacheMonthlyEvents({ commit }, { month, calendars, isMerge }) {
    const date = moment(month);
    commit("SET_MONTHLY_EVENTS", {
      month,
      eventsInfo: {
        fetching: getEvents({
          calendars,
          dtStart: date.startOf("month").valueOf(),
          dtEnd: date.endOf("month").valueOf()
        }),
        // 이전 데이터가 있으면 조회되기 전까지 유지
        lastSyncTime: state.monthlyEventCache[month]
          ? state.monthlyEventCache[month].lastSyncTime
          : 0,
        events: state.monthlyEventCache[month]
          ? state.monthlyEventCache[month].events
          : []
      }
    });
    const response = await state.monthlyEventCache[month].fetching;
    if (response.message) {
      commit("SET_MONTHLY_EVENTS", {
        month,
        eventsInfo: {
          ...state.monthlyEventCache[month],
          fetching: null
        }
      });
      return;
    }

    commit("SET_MONTHLY_EVENTS", {
      month,
      eventsInfo: {
        fetching: null,
        // 병합은 동기화 시간을 갱신하지 않습니다.
        lastSyncTime: isMerge
          ? state.monthlyEventCache[month].lastSyncTime
          : Date.now(),
        events: wrapSirTeamEvents(
          calendars,
          response.data,
          isMerge ? state.monthlyEventCache[month].events : []
        )
      }
    });

    if (!isMerge) {
      commit("DELETE_OUTSIDE_EVENTS");
    }
  },
  async reloadCalendarEvents({ commit, dispatch }, calendarIds) {
    commit("DELETE_EVENTS_IN_CALENDAR", calendarIds);
    await dispatch("fetchSirTeamCalendars");

    const calendars = state.calendars.filter(calendar =>
      calendarIds.includes(calendar.id)
    );
    if (calendars.length) {
      await dispatch("fetchAndMergeCalendarsEvents", {
        calendars
      });
    }
  },
  async reloadCalendarEventsAfterEdit({ getters, dispatch }, param) {
    // 캘린더 이동 시 두 캘린더 모두 새로고침
    if (
      param.originCalendarId &&
      param.calendarId &&
      param.originCalendarId !== param.calendarId
    ) {
      await dispatch("reloadCalendarEvents", [
        param.originCalendarId,
        param.calendarId
      ]);
      return;
    }

    // 일반적인 일정 생성/수정 시 해당 캘린더만 새로고침
    if (!param.detail.isLeave) {
      await dispatch("reloadCalendarEvents", [param.calendarId]);
      return;
    }

    // 연차 일정은 캘린더 ID가 0으로 전달되며, 자동으로 기본 캘린더에 저장되므로
    //  기본 캘린더 새로고침
    const defaultCalendar = getters.getMyCalendars.find(
      calendar => calendar.type === CALENDAR_TYPE.DEFAULT
    );
    if (defaultCalendar) {
      await dispatch("reloadCalendarEvents", [defaultCalendar.id]);
    } else {
      await dispatch("syncSirTeamData");
    }
  },
  async saveEventToServer({ commit, dispatch }, param) {
    commit("SHOW_LOADING_OVERLAY");
    const response = await saveEvent(param);
    if (response.message) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.4"), type: "ERROR" },
        { root: true }
      );
    } else {
      await dispatch("reloadCalendarEventsAfterEdit", param);

      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.3"), type: "SUCCESS" },
        { root: true }
      );
    }
    commit("HIDE_LOADING_OVERLAY");

    return !response.message;
  },
  async updateEventToServer({ commit, dispatch }, param) {
    commit("SHOW_LOADING_OVERLAY");
    const response = await updateEvent(param);
    if (response.message) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.6"), type: "ERROR" },
        { root: true }
      );
    } else {
      await dispatch("reloadCalendarEventsAfterEdit", param);

      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.5"), type: "SUCCESS" },
        { root: true }
      );
    }
    commit("HIDE_LOADING_OVERLAY");

    return !response.message;
  },
  async deleteEventFromServer({ commit, dispatch }, param) {
    commit("SHOW_LOADING_OVERLAY");
    const queryStr = new URLSearchParams(param).toString();
    const response = await deleteEvent(queryStr);
    if (response.message) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.8"), type: "ERROR" },
        { root: true }
      );
    } else {
      await dispatch("reloadCalendarEvents", [param.calendarId]);

      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.7"), type: "SUCCESS" },
        { root: true }
      );
    }
    commit("HIDE_LOADING_OVERLAY");
  },
  // 검색엔진으로 이벤트 검색
  async searchEventList({ getters }, params) {
    const { data } = await searchEventList(params);
    if (data.content.empty) {
      return [];
    }

    return {
      eventList: simpleWrapSirteamEvent(
        getters.getSelectedCalendars,
        data.content
      ),
      totalPages: data.totalPages,
      totalElements: data.totalElements
    };
  },
  async findFreebusyList(actions, params) {
    const { data = [] } = await searchFreebusy(params);
    if (data.empty) {
      return [];
    }

    const eventList = data.map(
      ({ id, userId, eventUId, detail, isRecurring }) => {
        const tempEnd = new Date(detail.dtEnd);

        return {
          id: id,
          userId: userId,
          uId: eventUId,
          start: new Date(detail.dtStart),
          end: detail.isAllDay
            ? new Date(tempEnd.setDate(tempEnd.getDate() - 1))
            : tempEnd,
          isAllDay: detail.isAllDay,
          isRecurring: isRecurring
        };
      }
    );
    return eventList;
  },
  async cancelSubscription({ dispatch }, calendar) {
    let result = false;
    if (calendar.type === CALENDAR_TYPE.PUBLIC_SUBSCRIPTION) {
      const response = await cancelPublicSubscription(calendar.id);
      if (!response.message) {
        result = true;
      }
    } else {
      result = await dispatch(
        "share/toCancelShare",
        {
          cancelList: calendar.targetCalendarId,
          moduleType: "CAL"
        },
        { root: true }
      );
    }
    if (result) {
      await dispatch("syncSirTeamData");
      return true;
    }
  },
  async updatePartStat({ commit, dispatch }, { param, calendarId }) {
    commit("SHOW_LOADING_OVERLAY");

    const response = await updatePartStat(param);
    if (response.message) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.6"), type: "ERROR" },
        { root: true }
      );
    } else {
      await dispatch("reloadCalendarEvents", [calendarId]);
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("calendar.5"), type: "SUCCESS" },
        { root: true }
      );
    }

    commit("HIDE_LOADING_OVERLAY");

    return !response.message;
  },
  async sendCalReplyMail({ commit, dispatch }, param) {
    commit("SHOW_LOADING_OVERLAY");

    let message = i18n.t("calendar.9");
    let type = "SUCCESS";
    const response = await sendCalReplyMail(param);
    if (response.message) {
      message = i18n.t("calendar.10");
      type = "ERROR";
    } else {
      await dispatch("syncSirTeamData");
    }

    commit("HIDE_LOADING_OVERLAY");
    dispatch(
      "snackbar/openSnackbar",
      { message: message, type: type },
      { root: true }
    );

    return response.data;
  },
  async findOrganCalendars({ dispatch }) {
    const { data } = await getDefaultOrganList();

    if (!data) {
      return [];
    }

    const subscribedOrgan = await dispatch("findCalendarConfig");

    return data.map(
      ({ id, parentId, name, email, childCount, children = [] }) => {
        const checkedChildren = children.map(
          ({ id, parentId, name, email, childCount }) => {
            return {
              id,
              parentId,
              name,
              email,
              checked: subscribedOrgan.some(e => e.organId === id),
              childCount,
              children: childCount > 0 ? [] : undefined
            };
          }
        );

        return {
          id,
          parentId,
          name,
          email,
          checked: subscribedOrgan.some(e => e.organId === id),
          childCount,
          children: checkedChildren.length > 0 ? checkedChildren : undefined
        };
      }
    );
  },
  async findCalendarConfig() {
    const calendarConfig = await getCalendarConfig();
    const subscribedOrgan = calendarConfig.data?.subscribedOrgan;
    return subscribedOrgan || [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
