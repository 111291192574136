const virtualFolderConverter = folderId => {
  const types = [
    { value: -1, name: "recent" },
    { value: -2, name: "important" },
    { value: -3, name: "share" },
    { value: -4, name: "search" }
  ];

  if (folderId == undefined) return folderId;

  const result = types.filter(({ value, name }) =>
    isNaN(parseInt(folderId))
      ? name == folderId.toLowerCase()
      : value == folderId
  );

  if (result.length == 0) return folderId > 0 ? folderId : NaN;
  else return isNaN(parseInt(folderId)) ? result[0].value : result[0].name;
};

export { virtualFolderConverter };
