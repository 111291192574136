<template>
  <div
    class="cr-tree-wrap"
    :class="$vuetify.breakpoint.lgAndUp ? '' : 'mobile'"
  >
    <v-treeview
      key="customerTree"
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      item-key="id"
      item-text="groupName"
      :items="getCustomerGroupList"
      :active.sync="activeItems"
      :open.sync="openItems"
      :search="groupSearchWord"
    >
      <!-- 아이콘 -->
      <template v-slot:prepend="{ item, open }">
        <v-icon
          v-if="item.customerGroupType === 'CUSTOMER'"
          :class="item.childCount === 0 ? 'ml-3' : ''"
          :color="getIconColor(item)"
          v-text="getIconsText(open, item)"
        ></v-icon>
        <v-icon
          v-else
          :class="item.customerGroupType === 'TRASH' ? 'ml-3' : ''"
          :color="getIconColor(item)"
        >
          {{ ICON_TEXT[item.customerGroupType] }}
        </v-icon>
      </template>
      <!-- 그룹 이름 / 버튼 -->
      <template v-slot:label="{ item }">
        <v-hover v-slot:default="{ hover }">
          <div class="d-flex align-center justify-space-between">
            <div
              class="cr-tree_node_click_area"
              @click.stop.prevent="nodeClick(item)"
            />
            <span class="cr-tree-label-text">
              {{ getGroupNameText(item) }}
            </span>
            <v-btn
              icon
              small
              v-if="hover && showContextMenu(item)"
              @click.stop="e => openPositioningMenu(e, item)"
            >
              <v-icon v-text="'mdi-dots-vertical'" />
            </v-btn>
          </div>
        </v-hover>
      </template>
    </v-treeview>

    <v-divider class="mt-1" />

    <v-list dense v-if="hasWritePermissionAtLeastOne">
      <v-list-item link @click="() => routeConfigTab({ tabId: 'import' })">
        <v-list-item-icon class="ml-2 mr-0">
          <v-icon small color="blue">mdi-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("contact.10") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GROUP_TYPES,
  hasPermissionInChildren,
  ICON_TEXT
} from "@/customer/constant/constants";
import router from "@/commons/router";

export default {
  data: () => ({
    prevActiveItem: {},
    addContactGroupDialog: false,
    parentContactGroup: null,
    dialog: {
      showDialog: false,
      parentFolder: null
    },
    groupSearchWord: null
  }),
  mounted() {
    const { groupId } = this.getRouteInfo;
    this.handleRoute(groupId);
  },
  watch: {
    "$route.params": {
      handler: function(params) {
        this.handleRoute(params.groupId);
      }
      //immediate: true
    }
  },
  computed: {
    ...mapGetters("customerGroup", [
      "getCustomerGroupList",
      "getActiveItems",
      "getOpenItems",
      "getRootGroup",
      "getFullParentGroups",
      "getCustomerGroup"
    ]),
    ...mapGetters("customerRoute", ["getRouteInfo"]),
    ICON_TEXT() {
      return ICON_TEXT;
    },
    activeItems: {
      get() {
        return this.getActiveItems;
      },
      set(value) {
        if (value.length > 0) {
          if (this.activeItems[0]?.id === value[0].id) return;
        }
        this.SET_ACTIVE_ITEMS(value);
      }
    },
    openItems: {
      get() {
        return this.getOpenItems;
      },
      set(items) {
        this.SET_OPEN_ITEMS(items);
      }
    },
    hasWritePermissionAtLeastOne() {
      return hasPermissionInChildren(this.getRootGroup.children);
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("customerGroup", ["deleteCustomerGroup"]),
    ...mapActions("customerRoute", ["routeConfigTab"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    ...mapMutations("customerGroup", ["SET_ACTIVE_ITEMS", "SET_OPEN_ITEMS"]),
    isStaticGroup(item) {
      return GROUP_TYPES[item.customerGroupType] !== "CUSTOMER";
    },
    getIconsText(open, item) {
      if (item.isDeleted) return ICON_TEXT.CUSTOMER_REMOVED;
      return open && item.childCount > 0
        ? ICON_TEXT.CUSTOMER_OPEN
        : ICON_TEXT.CUSTOMER;
    },
    getGroupNameText(item) {
      return this.isStaticGroup(item)
        ? i18n.t(`customer.${item.groupName}`)
        : item.groupName;
    },
    nodeClick(group) {
      const activeItems = this.activeItems;
      // treeview 기본 동작은 다시 클릭하면 해제 하므로
      // 이미 선택되어 있으면 리턴
      if (activeItems[0]?.id === group.id) {
        return false;
      }
      // 라우트 변경
      if (group.customerGroupType === "ROOT") {
        return this.$router.push({ name: "customer" });
      }
      this.$router.push({
        name: "customer_list",
        params: { type: group.customerGroupType, groupId: group.id, page: 1 }
      });
    },
    onActive([n = {}]) {
      const { id } = n;
      if (!id) {
        return (this.activeItems = [this.prevActiveItem]);
      }
      this.activeItems = n;
    },
    // 트리노드 선택시 아이콘 색상 변경
    getIconColor(item) {
      const [activeItem = {}] = this.activeItems;
      const { id } = activeItem;
      return id === item.id ? "primary" : "";
    },
    handleRoute(groupId) {
      if (!groupId) {
        groupId = -2;
      }
      const currentGroup = this.getCustomerGroup(groupId);
      // 존재하지 않는 그룹으로 route 이동 시, root 로 보냄
      if (!currentGroup) {
        return router.push({ name: "customer" });
      }

      const parents = this.getFullParentGroups(groupId);
      this.openItems = [this.getRootGroup, ...this.openItems, ...parents];
      this.activeItems = [currentGroup];
    },
    // 공유 주소록 그룹 컨텍스트메뉴
    openPositioningMenu(e, row) {
      e.preventDefault();
      this.closePositioningMenu();

      const defaultMenuList = [
        {
          // 하위그룹 추가
          label: i18n.t("customer.add.child"),
          func: e => {
            this.openAddDialog(e, row);
          }
        },
        {
          // 삭제
          label: i18n.t("common.삭제"),
          func: () => {
            this.removeCustomerGroup(row);
          }
        }
      ];

      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList: defaultMenuList
      });
    },
    // Dialog 열기
    openAddDialog(e, parentCustomerGroup) {
      e.stopPropagation();
      this.closePositioningMenu();

      this.SET_DIALOG({
        title: i18n.t("customer.add.group"),
        type: "customerGroup",
        params: {
          mode: "ADD",
          parentCustomerGroup
        }
      });
    },
    // 공유 주소록 그룹 삭제
    removeCustomerGroup(group) {
      this.confirm({
        message: i18n.t("customer.confirm.group.delete", {
          value: group.groupName
        }),
        callback: async () => {
          // 1. 권한 체크
          // 2. 휴지통으로 이동
          await this.deleteCustomerGroup(group.id);
        },
        showCloseBtn: true
      });
    },
    showContextMenu(group) {
      return !!(
        group.customerGroupType === "CUSTOMER" &&
        group.hasPermission &&
        !group.isDeleted
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer {
  font-size: 12px;
}
.cr-tree-wrap {
  overflow-y: auto;
  position: absolute;
  top: 52px;
  left: 0;
  right: 8px;
  bottom: 64px;

  &.mobile {
    top: 60px;
  }

  // 트리 노드 스타일
  .v-treeview ::v-deep {
    .v-treeview-node--shaped {
      .v-treeview-node__root {
        min-height: 42px;
        margin: 5px 0;
        padding-left: 1px;

        .v-treeview-node__content {
          margin-left: 0;
        }
      }

      .v-treeview-node__children {
        overflow: hidden;
      }
    }
    /* 텍스트 */
    .cr-tree-label-text {
      font-size: 14px;
    }
    > .v-treeview-node {
      .v-treeview-node__root {
        /*&.v-treeview-node--active {
          pointer-events: none;
        }*/
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }

        .v-treeview-node__content {
          cursor: pointer;

          .v-treeview-node__label {
            .cr-tree_node_click_area {
              position: absolute;
              inset: 0;
              left: 50px;
            }

            .cr-tree-label-text {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              min-width: 0;
            }
          }
        }
      }

      > .v-treeview-node__children {
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>
