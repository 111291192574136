<template>
  <v-container class="overflow-x-auto">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-icon left>
          mdi-calendar-outline
        </v-icon>
        <span class="text-subtitle-1 font-weight-bold">
          {{ $t("calendar.72") }}
        </span>
      </v-col>
    </v-row>

    <!-- 날짜 이동 -->
    <v-row dense>
      <v-col cols="12" class="d-flex align-center">
        <span class="text-subtitle-1">
          {{ this.currentDate }}
        </span>
        <v-btn small icon @click="setCurrentDate('prev')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn small icon @click="setCurrentDate('next')">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- 시간 표시 -->
    <div style="width: 585px;">
      <table class="table-time-header">
        <tr>
          <td colspan="16">
            <div class="freebusy-subheader">
              <div class="info-wrap">
                <div class="box selected"></div>
                <span class="subheader-text">
                  {{ $t("calendar.68") }}
                </span>
                <div class="box hasEvent"></div>
                <span class="subheader-text">
                  {{ $t("calendar.69") }}
                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="header-title"></td>
          <td class="header-time">8</td>
          <td class="header-time">9</td>
          <td class="header-time">10</td>
          <td class="header-time">11</td>
          <td class="header-time">12</td>
          <td class="header-time">13</td>
          <td class="header-time">14</td>
          <td class="header-time">15</td>
          <td class="header-time">16</td>
          <td class="header-time">17</td>
          <td class="header-time">18</td>
          <td class="header-time">19</td>
          <td class="header-time">20</td>
          <td class="header-time">21</td>
          <td class="header-time">22</td>
        </tr>
      </table>

      <div class="freebusy-wrap overflow-hidden"></div>
    </div>

    <div class="mt-2 text-caption">
      {{ "* " + $t("calendar.70") }}
    </div>

    <!-- 참석자별 시간 -->
    <div class="hidden-div d-none">
      <table class="table-time-freebusy toCopy">
        <tr>
          <td class="freebusy-title"></td>
          <td class="freebusy-time 0 08:00"></td>
          <td class="freebusy-time 1 08:30"></td>
          <td class="freebusy-time 2 09:00"></td>
          <td class="freebusy-time 3 09:30"></td>
          <td class="freebusy-time 4 10:00"></td>
          <td class="freebusy-time 5 10:30"></td>
          <td class="freebusy-time 6 11:00"></td>
          <td class="freebusy-time 7 11:30"></td>
          <td class="freebusy-time 8 12:00"></td>
          <td class="freebusy-time 9 12:30"></td>
          <td class="freebusy-time 10 13:00"></td>
          <td class="freebusy-time 11 13:30"></td>
          <td class="freebusy-time 12 14:00"></td>
          <td class="freebusy-time 13 14:30"></td>
          <td class="freebusy-time 14 15:00"></td>
          <td class="freebusy-time 15 15:30"></td>
          <td class="freebusy-time 16 16:00"></td>
          <td class="freebusy-time 17 16:30"></td>
          <td class="freebusy-time 18 17:00"></td>
          <td class="freebusy-time 19 17:30"></td>
          <td class="freebusy-time 20 18:00"></td>
          <td class="freebusy-time 21 18:30"></td>
          <td class="freebusy-time 22 19:00"></td>
          <td class="freebusy-time 23 19:30"></td>
          <td class="freebusy-time 24 20:00"></td>
          <td class="freebusy-time 25 20:30"></td>
          <td class="freebusy-time 26 21:00"></td>
          <td class="freebusy-time 27 21:30"></td>
          <td class="freebusy-time 28 22:00"></td>
          <td class="freebusy-time 29 22:30"></td>
        </tr>
      </table>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/styles/simple-scrollbar.scss";

.table-time-header,
.table-time-freebusy {
  border: 1px solid #ddd;
  border-collapse: collapse;

  .header-title,
  .freebusy-title {
    border-top: 1px solid #ddd;
    width: 100px;
    padding-left: 10px;
    font-size: 13px;
    color: #222;
    font-weight: bold;
  }

  .header-time {
    width: 32px;
    height: 30px;
    text-align: center;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }

  .freebusy-time {
    width: 16px;
    height: 30px;
    text-align: center;
    border-left: 1px solid #ddd;
  }
}

.freebusy-wrap {
  position: relative;
}

.selectable-time {
  position: absolute;
  pointer-events: none;
  width: 16px;
  height: 31px;
  border: 1px dashed red;
  top: 0px;
  left: 100px;
}

.selected-time {
  position: absolute;
  pointer-events: none;
  border: 1px Dotted blue;
  top: 0px;
}

.hasEvent {
  background-color: #c9c9c9;
}

.freebusy-subheader {
  height: 33px;

  .info-wrap {
    display: flex;
    align-items: center;
    height: inherit;

    .box {
      width: 15px;
      height: 15px;
      margin-left: 10px;

      &.selected {
        border: 2px solid rgba(0, 0, 255, 0.6);
      }
    }

    .subheader-text {
      font-weight: bold;
      font-size: 12px;
      color: #424242;
      padding-left: 10px;
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";

export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    startTime: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
    allDay: {
      type: Boolean,
      required: true
    },
    attendees: {
      type: Array,
      required: true,
      default: () => []
    },
    organizer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentDate: null,
      toSearchUsers: []
    };
  },
  watch: {
    async attendees() {
      await this.appendUserFreebusy();
      await this.adjustTimeDivs();
    },
    async watchTimeChanged() {
      await this.adjustTimeDivs();
    },
    async currentDate() {
      await this.appendUserFreebusy();
      await this.adjustTimeDivs();
    }
  },
  created() {
    this.currentDate = this.startDate;
  },
  computed: {
    watchTimeChanged() {
      return (
        this.startDate,
        this.endDate,
        this.startTime,
        this.endTime,
        this.allDay,
        Date.now()
      );
    }
  },
  methods: {
    ...mapActions("cal", ["findFreebusyList"]),
    setCurrentDate(direction) {
      let date = moment(this.currentDate, "YYYY-MM-DD");
      if (direction === "next") {
        this.currentDate = date.add(1, "days").format("YYYY-MM-DD");
      } else {
        this.currentDate = date.add(-1, "days").format("YYYY-MM-DD");
      }
    },
    expandTimeRange(event) {
      const tbOrderNum = event.target.classList[1];
      const timeRangeDivEl = document.querySelector(".selectable-time");
      timeRangeDivEl.classList.remove("d-none");
      timeRangeDivEl.style.left = tbOrderNum * 16 + 100 + "px";
    },
    setSelectedTimeToDialog(event) {
      const selectedTimeEl = document.querySelector(".selected-time");
      selectedTimeEl.classList.remove("d-none");
      const selectableTimeEl = document.querySelector(".selectable-time");
      selectedTimeEl.style.height = selectableTimeEl.style.height;
      selectedTimeEl.style.left = selectableTimeEl.style.left;
      selectedTimeEl.style.width = selectableTimeEl.style.width;

      // 이벤트 dialog (부모)의 시간값 변경
      const tempStart = moment(this.startTime, "HH:mm"); // 기존 시작시간
      const tempEnd = moment(this.endTime, "HH:mm"); // 기존 시작시간
      const tempDiff = tempEnd.diff(tempStart, "minutes"); // 기존 시작시간과 종료시간의 차이

      const toStartTime = moment(event.target.classList[2], "HH:mm"); // 유저가 클릭한 시작 시간
      let toEndTime = toStartTime.clone().add(tempDiff, "minutes"); // 종료시간 계산

      this.$emit("updateTimeData", {
        currentDate: this.currentDate,
        startTime: toStartTime.format("HH:mm"),
        endTime: toEndTime.format("HH:mm")
      });
      event.preventDefault();
      event.stopPropagation();
    },
    async adjustTimeDivs() {
      const selectedTimeEl = document.querySelector(".selected-time");
      const selectableTimeEl = document.querySelector(".selectable-time");
      if (selectableTimeEl) {
        selectableTimeEl.remove();
      }
      if (selectedTimeEl) {
        selectedTimeEl.remove();
      }

      const freebusyWrap = document.querySelector(".freebusy-wrap");

      if (this.allDay || this.startDate !== this.endDate) {
        // freebusy에 선택시간 div overlay하기 위해 이벤트 리스너 셋팅
        freebusyWrap.querySelectorAll(".freebusy-time").forEach(el => {
          el.removeEventListener("mouseover", this.expandTimeRange);
          el.removeEventListener("mouseup", this.setSelectedTimeToDialog);
        });
        return;
      }

      const startTimeNum = this.startTime.replace(":", "");
      let toOrder = (startTimeNum - 800) / 100;
      let orderNum = Math.floor(toOrder) / 0.5;
      let left = toOrder % 1;
      if (left > 0) {
        orderNum = orderNum + 1;
      }

      const tempStart = moment(this.startTime, "HH:mm"); // 기존 시작시간
      let tempEnd = moment(this.endTime, "HH:mm"); // 기존 시작시간
      let tempDiff = tempEnd.diff(tempStart, "minutes"); // 기존 시작시간과 종료시간의 차이
      if (tempDiff < 0) {
        tempEnd.add(1, "days");
        tempDiff = tempEnd.diff(tempStart, "minutes");
      }
      let diffNum = tempDiff / 30; // 30으로 나눠서 td 갯수 산출

      const freeBusyTables = document.querySelectorAll(
        ".freebusy-wrap > .table-time-freebusy"
      );

      const selectedTime = document.createElement("div");

      selectedTime.classList.add("selected-time");
      selectedTime.style.position = "absolute";
      selectedTime.style.pointerEvents = "none";
      selectedTime.style.border = "2px solid rgba(0,0,255,.6)";
      selectedTime.style.top = "0px";
      selectedTime.style.zIndex = 2;
      selectedTime.style.height = 31 * freeBusyTables.length + "px";
      selectedTime.style.left = 100 + orderNum * 16 + "px";
      selectedTime.style.width = 16 * diffNum + "px";

      freebusyWrap.appendChild(selectedTime);

      const selectableTime = document.createElement("div");
      selectableTime.classList.add("selectable-time");
      selectableTime.style.position = "absolute";
      selectableTime.style.pointerEvents = "none";
      selectableTime.style.border = "2px dashed rgba(255,0,0,.6)";
      selectableTime.style.top = "0px";
      selectableTime.style.height = 31 * freeBusyTables.length + "px";
      selectableTime.style.left = 100 + orderNum * 16 + "px";
      selectableTime.style.width = 16 * diffNum + "px";

      freebusyWrap.appendChild(selectableTime);

      // 8:00 이전이 시작 시간이면 타임 div 표시 안 함.
      if (moment(this.startTime, "HH:mm").isBefore(moment("08:00", "HH:mm"))) {
        selectedTime.classList.add("d-none");
        selectableTime.classList.add("d-none");
      }

      // freebusy에 선택시간 div overlay하기 위해 이벤트 리스너 셋팅
      freebusyWrap.querySelectorAll(".freebusy-time").forEach(el => {
        el.addEventListener("mouseover", this.expandTimeRange);
        el.addEventListener("mouseup", this.setSelectedTimeToDialog);
      });
    },
    async searchAttendeesFreeBusy() {
      const attendeeList = this.attendees.filter(user => user.userId);
      attendeeList.unshift(this.organizer);
      this.toSearchUsers = attendeeList;
      const startDt = moment(this.currentDate + " 08:00");
      const endDt = moment(this.currentDate + " 22:00");
      const result = await this.findFreebusyList({
        attendeeUserIds: attendeeList.map(user => user.userId).join(","),
        dtStart: startDt.valueOf(),
        dtEnd: endDt.valueOf()
      });
      return result;
    },
    async appendUserFreebusy() {
      const userTimes = await this.searchAttendeesFreeBusy();
      const existFreebusys = document.querySelectorAll(
        ".freebusy-wrap > .table-time-freebusy"
      );
      existFreebusys.forEach(el => {
        el.parentNode.removeChild(el);
      });

      this.toSearchUsers.forEach(user => {
        const freebusyWrap = document.querySelector(".freebusy-wrap");
        const freebusyTable = document.querySelector(
          ".hidden-div > .table-time-freebusy"
        );
        const existUserFreeBusy = document.querySelector(
          ".table-time-freebusy.user-" + user.userId
        );

        if (existUserFreeBusy) {
          existUserFreeBusy.remove();
        }
        const userFreeBusy = freebusyTable.cloneNode(true);
        userFreeBusy.classList.remove("d-none");
        userFreeBusy.classList.remove("toCopy");
        userFreeBusy.classList.add("user-" + user.userId);
        userFreeBusy.querySelector(".freebusy-title").textContent = user.name;

        if (userTimes) {
          let timeStrSet = new Set();
          let allDay = false;
          const startRange = moment(this.currentDate + " 01:00").toDate();
          userTimes
            .filter(event => event.userId == user.userId)
            .forEach(event => {
              if (event.isAllDay) {
                allDay = true;
                return false;
              }

              if (event.start.getTime() < startRange.getTime()) {
                return false;
              }

              const start = moment(event.start);
              const end = moment(event.end);

              if (allDay == false) {
                for (let time = start; time < end; time.add("30", "minutes")) {
                  timeStrSet.add(CAL_CONSTANTS.findNearestTime(time, "HH:mm"));
                }
              }
            });

          userFreeBusy
            .querySelectorAll(".freebusy-time")
            .forEach(freebusyTime => {
              if (allDay) {
                freebusyTime.classList.add("hasEvent");
              } else {
                timeStrSet.forEach(time => {
                  if (freebusyTime.classList.contains(time)) {
                    freebusyTime.classList.add("hasEvent");
                  }
                });
              }
            });
        }
        freebusyWrap.appendChild(userFreeBusy);
      });
    }
  }
};
</script>
