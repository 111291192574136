<template>
  <v-dialog
    :value="dialog"
    persistent
    max-width="320"
    content-class="otp_dialog"
    @click:outside="clickOutside"
  >
    <div @click="clickOutside">
      <v-card>
        <v-progress-linear
          v-model="progress"
          height="4"
          color="primary"
        ></v-progress-linear>
        <v-card-title class="text-h6 font-weight-bold">
          {{ $t("login.인증") }}
        </v-card-title>
        <v-card-text>
          <div v-text="$t('login.5')"></div>
          <div class="otp_wrap">
            <v-text-field
              v-for="(item, i) in numbers"
              :key="i"
              :id="'_id' + item.index"
              ref="_number"
              v-model="item.model"
              maxlength="1"
              autocomplete="off"
              hide-details
              class="otp_ipt"
              @paste="paste"
              @keydown.up.left="keydownLeft"
              @keydown.down.right="keydownRight"
              @keydown.delete="keydownDelete"
              @keydown.enter="confirmClick"
              @input="inputEvent"
              @click.stop
              @focus="focusIn"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions @click.stop>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="$emit('update:dialog', false)">
            {{ $t("common.취소") }}
          </v-btn>
          <v-btn depressed color="primary" @click="confirmClick">
            {{ $t("common.확인") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<style scoped>
.v-dialog__content >>> .otp_dialog .v-input__slot {
  padding: 0px !important;
}
.v-dialog__content >>> .otp_dialog .v-input input {
  border: 1px solid blueviole;
  text-align: center;
  font-weight: bold;
}
.v-dialog__content >>> .otp_dialog .otp_wrap {
  display: flex;
  justify-content: space-between;
  margin: 4px -5px 12px -5px;
}
.v-dialog__content >>> .otp_dialog .otp_ipt {
  display: inline-flex;
  margin: 0px 5px;
}
</style>

<script>
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    dialog: {
      type: Boolean
    },
    confirm: {
      default: () => {},
      type: Function
    }
  },
  watch: {
    dialog: function(next) {
      if (!next) {
        clearInterval(this.progressId);
        return;
      }
      this.progress = 0;
      this.numbers.forEach(item => (item.model = ""));
      setTimeout(() => this.$refs._number[0].focus(), 200);

      // progress
      this.progressId = setInterval(() => {
        const wave = 30;
        let seconds = new Date().getSeconds();
        seconds = seconds > wave ? seconds - wave : seconds;
        this.progress = (100 / wave) * seconds;
      }, 1000);
    },
    numbers: {
      deep: true,
      handler(numbers) {
        const { length } = numbers.filter(n => n.model.length == 1);
        if (length == 6) this.confirmClick();
      }
    }
  },
  data() {
    return {
      foucsIndex: 0,
      progress: 0,
      progressId: null,
      numbers: [
        { index: 0, model: "" },
        { index: 1, model: "" },
        { index: 2, model: "" },
        { index: 3, model: "" },
        { index: 4, model: "" },
        { index: 5, model: "" }
      ]
    };
  },
  methods: {
    stopEvent(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    focusIn(e) {
      this.selectText(e.target);
      this.foucsIndex = this.numbers.filter(
        item => `_id${item.index}` === e.target.id
      )[0].index;
    },
    selectText(el) {
      el.select();
    },
    getValue() {
      return this.numbers.map(num => num.model).join("");
    },
    cursorIndex() {
      return this.foucsIndex;
    },
    keydownLeft(e) {
      this.stopEvent(e);
      const index = this.cursorIndex();
      if (index == 0) return;
      this.$refs._number[index - 1].focus();
    },
    keydownRight(e) {
      this.stopEvent(e);
      const index = this.cursorIndex();
      if (index == this.numbers.length - 1) return;
      this.$refs._number[index + 1].focus();
    },
    keydownDelete(e) {
      this.stopEvent(e);
      const cursor = this.cursorIndex(e);
      this.numbers[cursor].model = "";

      if (e.key === "Backspace") {
        this.keydownLeft(e);
      }

      this.numbers.forEach((item, index) => {
        if (e.key === "Backspace" && index <= cursor) return;
        else if (e.key === "Delete" && cursor >= index) return;

        this.numbers[index - 1].model = item.model;
        this.numbers[index].model = "";
      });
    },
    inputEvent(v) {
      let str = String(v);
      if (str.length == 1) {
        this.numbers[this.cursorIndex()].model = v;
      } else if (str.length <= 6) {
        for (const i in str) {
          this.numbers[i].model = str[i];
        }
      }

      this.keydownRight({
        preventDefault: () => {},
        stopPropagation: () => {}
      });
    },
    // 붙여 넣기
    paste(e) {
      const clipboardData = `${e.clipboardData.getData("text")}`.split("");
      const currentValue = this.getValue();
      let cursor = this.cursorIndex(e);

      if (isBlank(clipboardData)) return;
      if (isBlank(currentValue)) cursor = 0;

      clipboardData.some((val, index) => {
        if (index + cursor >= this.numbers.length) return;
        this.numbers[index + cursor].model = val;
      });
    },
    // 팝업 외부 클릭
    clickOutside() {
      const number = this.numbers.filter(num => isBlank(num.model));
      this.$refs._number[number.length > 0 ? number[0].index : 0].focus();
    },
    // 확인
    confirmClick() {
      const currentValue = this.getValue();
      if (currentValue.length < this.numbers.length) {
        this.clickOutside();
        return;
      }
      this.$emit("update:dialog", false);
      this.confirm(currentValue);
    }
  }
};
</script>
