<template>
  <div>
    <DefaultNavigation v-if="showDefault" />
    <TasksNavigation v-else />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DefaultNavigation from "./default";
import TasksNavigation from "./tasks";

export default {
  components: { DefaultNavigation, TasksNavigation },
  computed: {
    ...mapState("flowLayout", ["isNavigationSwapped"]),
    showDefault() {
      const { name } = this.$route;

      if (name?.split("_")[1] !== "tasks") return true;
      return this.isNavigationSwapped;
    }
  },
  watch: {
    $route({ name }) {
      if (name?.split("_")[1] === "tasks") {
        this.SET_IS_NAVIGATION_SWAPPED(false);
      }
    }
  },
  methods: {
    ...mapMutations("flowLayout", ["SET_IS_NAVIGATION_SWAPPED"])
  }
};
</script>
