import http from "@/commons/api/http";

const prefix = "api/mail/config/external";

/**
 * 외부메일 목록 조회
 */
const getExternalList = () => http.get(`${prefix}`);

/**
 * 외부메일 정보 추가
 */
const addExternalMail = params => {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    formData.append(key, params[key]);
  });

  return http.post(`${prefix}`, formData);
};

/**
 * 외부메일 정보 수정
 */
const updateExternalMail = params => {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    formData.append(key, params[key]);
  });

  return http.put(`${prefix}`, formData);
};

/**
 * 외부메일 정보 삭제
 */
const deleteExternalMail = externalIds => {
  const formData = new FormData();
  externalIds.forEach(id => formData.append("externalIds", id));
  return http.delete(`${prefix}`, { data: formData });
};

/**
 * 외부메일 가져오기
 */
const fetchExternalMail = externalIds =>
  http.get(`${prefix}/fetch`, {
    params: { externalIds: externalIds.join(",") }
  });

export {
  getExternalList,
  addExternalMail,
  updateExternalMail,
  deleteExternalMail,
  fetchExternalMail
};
