import {
  CAL_CONSTANTS,
  EVENT_PLATFORM,
  LEAVE_STATUS
} from "@/calendar/constant/calConstants";
import auth from "@/commons/store/modules/auth.js";
import moment_tz from "moment-timezone";
import { datetime, rrulestr } from "rrule-2.7.2";

/**
 * 구글 캘린더 이벤트 목록을 프론트엔드에서 사용할 공통 이벤트 모델로 래핑합니다.
 */
export const wrapGoogleEvents = (
  calendars,
  externalEventResponse,
  searchStartTime,
  searchEndTime
) => {
  const calendarMap = new Map();
  calendars.forEach(item => calendarMap.set(item.id, item));

  const result = [];
  for (const externalEvents of externalEventResponse) {
    const calendar = calendarMap.get(externalEvents.calendarId);
    const wrappedEvents = externalEvents.items
      .filter(
        item =>
          !!item.summary &&
          !item.recurrence &&
          // 예외 이벤트 중 반복 일정 취소(삭제)를 의미하는 이벤트 제외
          (!item.recurringEventId || item.status !== "cancelled")
      )
      .map(item => wrapGoogleEvent(calendar, item));
    initStartForOrdering(wrappedEvents, 3);

    const recurrenceEvents = expandRecurringEvents(
      calendar,
      externalEvents,
      searchStartTime,
      searchEndTime
    );
    initStartForOrdering(recurrenceEvents, 3);

    result.push(...wrappedEvents, ...recurrenceEvents);
  }

  return result;
};

const expandRecurringEvents = (
  calendar,
  externalEvents,
  searchStartTime,
  searchEndTime
) => {
  const recurrenceEvents = externalEvents.items.filter(
    item => !!item.recurrence
  );
  const exceptionEvents = externalEvents.items.filter(
    item => !!item.recurringEventId
  );
  const searchStart = moment_tz.utc(searchStartTime);
  const searchEnd = moment_tz.utc(searchEndTime);

  const searchStartDt = datetime(
    searchStart.year(),
    searchStart.month() + 1,
    searchStart.date()
  );
  const searchEndDt = datetime(
    searchEnd.year(),
    searchEnd.month() + 1,
    searchEnd.date()
  );

  return recurrenceEvents.flatMap(item =>
    expandRecurringEvent(
      calendar,
      item,
      exceptionEvents,
      searchStartDt,
      searchEndDt
    )
  );
};

const expandRecurringEvent = (
  calendar,
  item,
  exceptionEvents,
  searchStartDt,
  searchEndDt
) => {
  const wrappedEvent = wrapGoogleEvent(calendar, item);
  const dtstart = datetime(
    wrappedEvent.start.getUTCFullYear(),
    wrappedEvent.start.getUTCMonth() + 1,
    wrappedEvent.start.getUTCDate(),
    wrappedEvent.start.getUTCHours(),
    wrappedEvent.start.getUTCMinutes()
  );
  const rrule = rrulestr(item.recurrence[0], {
    dtstart,
    tzid: item.start.timeZone
  });

  const recurrenceDates = rrule
    .between(
      searchStartDt.getTime() > dtstart.getTime() ? searchStartDt : dtstart,
      searchEndDt,
      true
    )
    .filter(recurrenceDate => {
      return !exceptionEvents.some(
        exceptionEvent =>
          exceptionEvent.recurringEventId === item.id &&
          recurrenceDate.getTime() ===
            googleDateToJSDate(
              exceptionEvent.originalStartTime,
              false
            ).getTime()
      );
    });

  const diff = wrappedEvent.end.getTime() - wrappedEvent.start.getTime();
  return recurrenceDates.map(date => {
    return {
      ...wrappedEvent,
      start: date,
      end: new Date(date.getTime() + diff)
    };
  });
};

const wrapGoogleEvent = (calendar, item) => {
  return {
    platform: EVENT_PLATFORM.GOOGLE,
    source: item,
    id: item.id,
    calendar: calendar,
    name: item.summary,
    isAllDay: !!item.start?.date,
    isRecurring: !!item.recurrence,
    modifiesId: item.recurringEventId,
    start: googleDateToJSDate(item.start, false),
    end: googleDateToJSDate(item.end, true),
    isPrivate: item.visibility === "private",
    description: item.description,
    location: item.location,
    ...extractGroupEventInfo(calendar, item),
    me: item.creator.self,
    hasWritePrivilege: false,
    waitForAction: false
  };
};

const extractGroupEventInfo = (calendar, item) => {
  return {
    organizer: item.organizer,
    attendees: item.attendees
      ? item.attendees
          .filter(
            attendee =>
              (attendee.displayName || attendee.email) &&
              attendee.responseStatus
          )
          .map(attendee => {
            return {
              name: attendee.displayName,
              email: attendee.email,
              /*
               * SirTeam 이벤트는 참석 상태를 대문자로 관리하지만, 구글 이벤트의 참석 상태는 소문자이므로 변환합니다.
               * 이때 응답 대기를 나타내는 값이 SirTeam 이벤트는 NEEDS-ACTION, 구글 이벤트는 needsAction이므로
               * 구글 이벤트 수정 지원 시 두 값을 적절히 변환해야 합니다.
               */
              partStat: attendee.responseStatus.toUpperCase()
            };
          })
      : [],
    invited: item?.organizer?.email !== calendar.connection.email
  };
};

export const googleDateToJSDate = (googleDate, isEnd) => {
  if (googleDate?.date) {
    return (
      moment_tz
        .utc(googleDate.date.value)
        // 구글 캘린더 이벤트의 종일 이벤트는 당일을 포함하지 않는 비포괄적 종료일이므로 하루를 빼줍니다.
        .add(isEnd ? -1 : 0, "day")
        .toDate()
    );
  } else {
    const result = moment_tz.utc(googleDate.dateTime.value);
    if (googleDate.timeZone) {
      result.tz(googleDate.timeZone);
    }
    return result.toDate();
  }
};

/**
 * SirTeam 캘린더 이벤트 목록을 프론트엔드에서 사용할 공통 이벤트 모델로 래핑합니다.
 * @param events SirTeam API로 응답 받은 일정 목록
 * @param existingEvents 이미 보유 중인 래핑된 일정 목록
 * @return 기존 일정 목록을 포함하는 래핑된 일정 목록
 */
export const wrapSirTeamEvents = (calendars, events, existingEvents) => {
  const userInfo = auth.state.userInfo;
  const calendarMap = new Map();
  calendars.forEach(item => calendarMap.set(item.id, item));

  const eventMap = new Map();
  existingEvents?.forEach(item => eventMap.set(getEventKey(item), { ...item }));

  for (const item of events) {
    const calendar = calendarMap.get(item.calendarId);
    if (!calendar) continue;

    /*
     * 중복 일정을 그룹화하고, 표시되는 일정을 결정합니다. 일정은 다음과 같은 경우 중복될 수 있습니다.
     * - 공유 캘린더 소유자가 그룹 일정 생성 시 공유 받은 구성원을 초대하는 경우
     * - 동일한 그룹 일정에 초대된 구성원들의 (부서)캘린더를 구독 중인 경우
     */
    const eventKey = getEventKey(item);
    const existsEvent = eventMap.get(eventKey);
    if (existsEvent) {
      // 표시되는 일정을 결정합니다.
      if (
        // 내 일정 최우선 표시
        item.me ||
        // 공유 일정
        (!existsEvent.me && calendar.privilege === 3) ||
        // 주최자의 그룹 일정
        (!existsEvent.hasWritePrivilege && !item.isInvitation)
      ) {
        eventMap.delete(eventKey);
      } else {
        // 부서 캘린더 일정인 경우
        if (item.owner) {
          const integratedEvent = wrapSirTeamEvent(calendar, item, userInfo);

          // 그룹 일정이 이미 존재하는 경우 중복되는 그룹 일정을 그룹화 목록에 추가합니다.
          // 내 일정인 경우 grouped 속성이 없으므로 새로 생성합니다.
          if (!existsEvent.grouped) {
            existsEvent.grouped = new Map();
          }
          existsEvent.grouped.set(
            integratedEvent.source.owner.id,
            integratedEvent
          );
        }
        continue;
      }
    }

    // 내 일정, 혹은 그룹화 대상이 아닌 일정
    const wrappedEvent = wrapSirTeamEvent(
      calendarMap.get(item.calendarId),
      item,
      userInfo
    );
    // 일정을 교체하는 경우 그룹화 상태를 복사합니다.
    if (existsEvent) {
      wrappedEvent.grouped = existsEvent.grouped;
    }

    eventMap.set(eventKey, wrappedEvent);
  }

  // 시작 시간 빠른 순, 기간 짧은 순, 캘린더 이름 순
  const result = Array.from(eventMap.values()).sort((a, b) => {
    // 종일 일정을 시간 일정 보다 큰 값으로 간주하기 위해 23:59:59초로 계산
    const aStart = a.isAllDay
      ? a.start.getTime() + 86399000
      : a.start.getTime();
    const bStart = b.isAllDay
      ? b.start.getTime() + 86399000
      : b.start.getTime();
    const startDiff = aStart - bStart;
    if (startDiff !== 0) return startDiff;

    const aDiff = a.end.getTime() - a.start.getTime();
    const bDiff = b.end.getTime() - b.start.getTime();
    const diff = aDiff - bDiff;
    if (diff !== 0) return diff;

    return a.calendar.title.localeCompare(b.calendar.title);
  });

  initStartForOrdering(result, 0);

  return result;
};

export const simpleWrapSirteamEvent = (calendars, events) => {
  const userInfo = auth.state.userInfo;
  const calendarMap = new Map();
  calendars.forEach(item => calendarMap.set(item.id, item));

  const result = [];
  for (const item of events) {
    const calendar = calendarMap.get(item.calendarId);
    if (!calendar) continue;

    result.push(wrapSirTeamEvent(calendar, item, userInfo));
  }

  return result;
};

export const wrapSirTeamEvent = (calendar, item, userInfo) => {
  const result = {
    platform: EVENT_PLATFORM.SIRTEAM,
    source: item,
    id: item.eventUId,
    calendar: calendar,
    name: item.detail.summary,
    isAllDay: item.detail.isAllDay,
    isRecurring: item.isRecurring,
    modifiesId: item.modifiesId,
    start: new Date(item.detail.dtStart),
    end: new Date(item.detail.dtEnd),
    description: item.detail.description,
    location: item.detail.location,
    isPrivate: !item.isPublic,
    organizer: item.detail.organizer,
    attendees: item.detail.attendees,
    // 다른 사용자가 초대한 일정인 경우에만 true.
    // 그룹 일정이 아니거나, 주최자가 자신을 참석자로 초대한 경우 false.
    invited: item.isInvitation,
    me: item.me,
    hasWritePrivilege: calendar.privilege === 3 || item.me,
    waitForAction: false,
    // SirTeam 이벤트 전용 필드
    detail: item.detail
  };

  if (result.isAllDay) {
    result.start.setHours(0, 0, 0, 0);
    result.end.setHours(0, 0, 0, 0);
  }

  // 부서 일정인 경우 owner 속성이 설정되어 있으며, 이 경우 일정에 참석하는 구성원으로 추가합니다.
  if (item.owner) {
    // SirTeam 이벤트 전용 필드
    // 그룹화된 일정에 참석하는 구성원 ID 목록
    result.grouped = new Map();
    result.grouped.set(result.source.owner.id, result);
  }

  if (result.attendees) {
    result.attendees.forEach(attendee => {
      if (result.organizer.email === attendee.email) {
        attendee.isOrganizer = true;
      }
    });
    result.attendees.sort((a, b) => {
      // 일정의 주최자가 참석 중인 경우 참석자 목록의 가장 앞에 배치합니다.
      if (a.isOrganizer) return -1;
      if (b.isOrganizer) return 1;

      const attendeeA = a.name ? a.name : a.email;
      const attendeeB = b.name ? b.name : b.email;
      return attendeeA.localeCompare(attendeeB);
    });
  }

  result.waitForAction = waitForActionEvent(result, userInfo.username);

  return result;
};

/**
 * 상호 작용을 대기 중인 일정인지 확인합니다.
 * @returns {boolean} true인 경우 상호 작용을 대기 중인 일정.
 *         ( 참석 상태를 결정하지 않은 일정, 휴가 신청 상태가 결정되지 않은 일정, ... )
 */
const waitForActionEvent = (event, userEmail) => {
  if (!event.hasWritePrivilege) return false;

  if (event.attendees?.length && event.invited) {
    for (const attendee of event.attendees) {
      if (attendee.email === userEmail) {
        return (
          attendee.partStat === "" ||
          attendee.partStat === CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction
        );
      }
    }
  }

  if (event.detail.isLeave && event.detail.leaveStatus === LEAVE_STATUS.READY) {
    return true;
  }

  return false;
};

/**
 * 캘린더 월 보기 화면에서 사용자 소유 일정을 우선 표시하기 위해 사용하는 시간 값을 설정합니다.
 * @param base  기준 시간. 정렬 우선 순위를 결정하기 위해 사용하며, 차후 고도화 시 로직 개선이 필요합니다.
 *              예를 들어 써팀 일정은 0, 구글 일정은 3으로 지정하여 써팀 일정을 우선 표시하고 있습니다.
 */
const initStartForOrdering = (events, base) => {
  let idx = 0;
  let lastDate = new Date(0);
  for (const event of events) {
    if (
      lastDate.getFullYear() !== event.start.getFullYear() ||
      lastDate.getMonth() !== event.start.getMonth() ||
      lastDate.getDate() !== event.start.getDate()
    ) {
      idx = 0;
      lastDate = event.start;
    }

    event.startForOrdering = new Date(event.start);
    event.startForOrdering.setHours(event.me ? base : base + 6, idx++);
  }
};

/**
 * 이벤트 중복 확인용 키를 생성합니다.
 */
const getEventKey = event => {
  return `${event.detail.uId}-${event.detail.dtStart}-${event.detail.organizer?.userId}`;
};

export const makeEmptyEvent = (calendar, dateTimeInfo) => {
  return {
    platform: EVENT_PLATFORM.SIRTEAM,
    source: null,
    id: null,
    calendar: calendar,
    name: null,
    isAllDay: dateTimeInfo.isAllDay,
    isRecurring: false,
    modifiesId: 0,
    start: dateTimeInfo.start,
    end: dateTimeInfo.end,
    startForOrdering: dateTimeInfo.start,
    description: null,
    location: null,
    isPrivate: false,
    organizer: null,
    attendees: null,
    invited: false,
    me: false,
    hasWritePrivilege: calendar.privilege === 3,
    waitForAction: false
  };
};

export const isGroupedEvent = (event, validCount) => {
  return event.grouped?.size > validCount;
};
