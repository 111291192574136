import { getRootFolder } from "@/drive/api/drive.api";

import router from "@/commons/router";

import { getDriveFolderById } from "../../api/drive.api";
import { virtualFolderConverter } from "@/drive/constant/driveFolderType";
import { isJson } from "@/commons/utils/validation";

const state = {
  drive_list: {
    folderId: 0,
    page: 1,
    actionObj: JSON.stringify({ sort: "updatedTimeMillis", dir: "desc" })
  },
  drive_share_list: {
    folderId: 0,
    page: 1,
    actionObj: JSON.stringify({ sort: "updatedTimeMillis", dir: "desc" })
  },
  drive_external_list: {
    externalType: "",
    tabId: "",
    folderId: "",
    actionObj: ""
  }
};
const getters = {
  getDriveListInfo: ({ drive_list }) => ({
    ...drive_list,
    actionObj: JSON.parse(drive_list.actionObj)
  }),
  getShareListInfo: ({ drive_share_list }) => ({
    ...drive_share_list,
    actionObj: JSON.parse(drive_share_list.actionObj)
  }),
  getExternalListInfo: ({ drive_external_list }) => drive_external_list,
  getExternalDriveType: ({ drive_external_list }) =>
    drive_external_list.externalType,
  getPage: ({ drive_list }) => parseInt(drive_list.page),
  getSharePage: ({ drive_share_list }) => parseInt(drive_share_list.page),
  getTabId: ({ drive_external_list }) => drive_external_list.tabId
};
const mutations = {
  INIT_DRIVE_ROUTE: state => {
    state.drive_list = {
      folderId: 0,
      page: 1,
      actionObj: JSON.stringify({ sort: "updatedTimeMillis", dir: "desc" })
    };
    state.drive_share_list = {
      folderId: 0,
      page: 1,
      actionObj: JSON.stringify({ sort: "updatedTimeMillis", dir: "desc" })
    };
    state.drive_external_list = {
      externalType: "",
      tabId: "",
      folderId: "",
      actionObj: ""
    };
  },
  SET_ROUTE_PARAMS: (state, params) => {
    if (params.routeName == "drive_external_list") {
      const {
        externalType = "",
        tabId = "",
        folderId = "",
        actionObj = ""
      } = params;
      state[params.routeName] = { externalType, tabId, folderId, actionObj };
    } else {
      const {
        folderId = 0,
        page = 1,
        actionObj = JSON.stringify({ sort: "usedTimeMillis", dir: "desc" })
      } = params;

      state[params.routeName] = { folderId, page, actionObj };
    }
  }
};
const actions = {
  async setParams({ state, getters, commit, dispatch, rootGetters }, params) {
    // 다이얼로그 on 일 경우 닫기
    if (rootGetters["driveDialog/showDialog"]) {
      dispatch("driveDialog/close", {}, { root: true });
    }

    if (params.routeName == "drive_external_list_by_folderId") {
      if (params.tabId == "search" && isJson(params.folderId)) {
        return router.push({
          name: "drive_external_list_by_search",
          params: {
            externalType: params.externalType,
            actionObj: params.folderId
          }
        });
      }
    }

    // 라우팅에 따른 파라미터 설정
    let parameter;
    switch (params.routeName) {
      case "drive":
        {
          const { data } = await getRootFolder();
          parameter = {
            routeName: "drive_list",
            folderId: data.id,
            page: 1,
            actionObj: JSON.stringify({ sort: "usedTimeMillis", dir: "desc" })
          };
        }
        break;
      case "drive_list":
        parameter = {
          routeName: "drive_list",
          folderId: virtualFolderConverter(params.folderId),
          page: params.page,
          actionObj: JSON.stringify({ sort: "usedTimeMillis", dir: "desc" })
        };
        break;
      case "drive_list_action":
        parameter = {
          routeName: "drive_list",
          folderId: virtualFolderConverter(params.folderId),
          page: params.page,
          actionObj: params.actionObj
        };
        break;
      // 공유문서함
      case "drive_share_list":
        parameter = {
          routeName: "drive_share_list",
          folderId: -3,
          page: params.page,
          actionObj: JSON.stringify({ sort: "usedTimeMillis", dir: "desc" })
        };
        break;
      case "drive_share_list_action":
        parameter = {
          routeName: "drive_share_list",
          folderId: virtualFolderConverter(params.folderId),
          page: params.page,
          actionObj: params.actionObj
        };
        break;
      case "drive_shared_list":
        parameter = {
          routeName: "drive_share_list",
          folderId: virtualFolderConverter(params.folderId),
          page: params.page,
          actionObj: JSON.stringify({ sort: "usedTimeMillis", dir: "desc" })
        };
        break;
      case "drive_shared_list_action":
        parameter = {
          routeName: "drive_share_list",
          folderId: virtualFolderConverter(params.folderId),
          page: params.page,
          actionObj: params.actionObj
        };
        break;
      // 외부 드라이브 연동
      case "drive_external":
        parameter = {
          routeName: "drive_external_list",
          externalType: params.externalType,
          tabId: "my",
          folderId: ""
        };
        break;
      case "drive_external_list_by_tab":
        parameter = {
          routeName: "drive_external_list",
          externalType: params.externalType,
          tabId: params.tabId,
          folderId: ""
        };
        break;
      case "drive_external_list_by_folderId":
        parameter = {
          routeName: "drive_external_list",
          externalType: params.externalType,
          tabId: params.tabId,
          folderId: params.folderId
        };
        break;

      case "drive_external_list_by_search":
        parameter = {
          routeName: "drive_external_list",
          externalType: params.externalType,
          tabId: "search",
          folderId: "",
          actionObj: params.actionObj
        };
        break;
      default:
        break;
    }

    // 설정 값 가져오기
    if (!rootGetters["driveConfig/getConfigInit"]) {
      await commit("driveConfig/SET_INIT", true, { root: true });
    }

    // 외부 드라이브 인증 토큰 불러오기
    if (!rootGetters["driveExternalToken/init"]) {
      // 토큰 초기화
      await dispatch("driveExternalToken/getToken", null, { root: true });
      await dispatch("serviceConfig/loadConfig", "DRIVE", { root: true });
    }

    // 외부 드라이브
    if (params.routeName.includes("drive_external")) {
      let validate = false;
      switch (parameter.externalType) {
        case "google":
          validate =
            rootGetters["serviceConfig/getDriveServiceConfig"].useGoogleDrive;
          break;

        default:
          break;
      }

      if (!validate) {
        return router.push({ name: "drive" });
      }
      await commit("SET_ROUTE_PARAMS", parameter);
      /**
       * 인증 화면인 경우, 토큰이 있다면 리스트 화면으로 이동
       * 리스트 화면인 경우, 토큰이 없다면 인증 화면으로 이동
       */
      if (params.routeName == "drive_external") {
        if (
          rootGetters["driveExternalToken/haveTokenByExternalType"](
            parameter.externalType
          )
        ) {
          return router.push({
            name: "drive_external_list_by_tab",
            params: { externalType: parameter.externalType, tabId: "my" }
          });
        }
      } else {
        if (
          !rootGetters["driveExternalToken/haveTokenByExternalType"](
            parameter.externalType
          )
        ) {
          return router.push({
            name: "drive_external",
            params: { externalType: parameter.externalType }
          });
        }

        if (params.routeName == "drive_external_list_by_tab") {
          await dispatch(
            "driveExternal/getDriveDataByTab",
            { externalType: params.externalType, tabId: params.tabId },
            { root: true }
          );
        } else if (params.routeName == "drive_external_list_by_search") {
          await dispatch(
            "driveExternal/getDriveDataBySearch",
            { externalType: params.externalType, actionObj: params.actionObj },
            { root: true }
          );
        } else {
          await dispatch(
            "driveExternal/getDriveDataById",
            { externalType: params.externalType, id: params.folderId },
            { root: true }
          );
        }
      }
      return;
    }

    // 공유 문서함
    if (params.routeName.includes("share")) {
      await commit("SET_ROUTE_PARAMS", parameter);
      await dispatch(
        "driveShare/getShareDriveList",
        {
          folderId: parameter.folderId,
          page: parameter.page,
          pageSize: rootGetters["driveConfig/getPageSize"],
          actionObj: getters.getShareListInfo.actionObj
        },
        { root: true }
      );
      return;
    }

    // 기본 문서함
    // 폴더의 존재 여부 검사, 없을 경우 최초 폴더로 라우팅
    // 문자 or 음수 일 경우
    if (isNaN(parameter.folderId)) return router.push({ name: "drive" });
    // 사용자의 폴더인지의 경우
    if (parameter.folderId > 0) {
      const drive = rootGetters["drive/getFolder"](parameter.folderId);
      if (!drive) {
        const { data = [], status } = await getDriveFolderById(
          parameter.folderId
        );
        if (status != 200) return router.push({ name: "drive" });
        if (!(data.fileType == "ROOT" || data.fileType == "DIRECTORY"))
          return router.push({ name: "drive" });
      }
    }

    await commit("SET_ROUTE_PARAMS", parameter);
    await dispatch(
      "drive/getDriveList",
      {
        folderId: parameter.folderId,
        page: parameter.page,
        pageSize: rootGetters["driveConfig/getPageSize"],
        actionObj: state.drive_list.actionObj
      },
      { root: true }
    );
  },

  async initDriveData({ dispatch, getters }) {
    // 초기 드라이브 폴더 세팅
    let { folderId } = getters.getDriveListInfo;
    // folderId가 없는경우는 공유 폴더일 경우
    if (folderId == 0) folderId = -3;
    await dispatch("drive/getDefaultFolders", folderId, { root: true });

    // Splash
    await dispatch("splash/setShowSplash", false, { root: true });
  },
  routeExternalTab({ getters }, { tabId }) {
    router.push({
      name: "drive_external_list_by_tab",
      params: {
        externalType: getters.getExternalDriveType,
        tabId: tabId || "my"
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
