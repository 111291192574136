<template>
  <v-list dense elevation="0" class="py-0">
    <TreeView
      v-if="folders.length > 0"
      :key="inputValue"
      v-bind="$props"
      v-on="$listeners"
      :folders="folders"
      :isSearch="inputValue.length > 0"
    />

    <v-list-item-group v-else>
      <v-list-item v-if="useAddFolder" @click="addFolderFunc">
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between">
            <div class="d-flex" style="min-width: 0px;">
              <span class="d-inline-block text-truncate">{{ inputValue }}</span>
            </div>
            <div class="d-flex">(<span v-text="$t('mail.282')"></span>)</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <div class="cr-no-data" @click.stop v-text="$t('common.4')"></div>
    </v-list-item-group>
  </v-list>
</template>

<style lang="scss" scoped>
.v-list {
  position: absolute;
  top: 55px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;

  .v-list-item__title {
    font-size: 0.875rem !important;
  }

  .cr-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 150px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { quickMenuEnum } from "@/mail/constant/folderType";
import TreeView from "./TreeView.vue";

export default {
  components: { TreeView },
  props: {
    selectedFolderId: {
      default: 0,
      type: Number
    },
    inputValue: {
      default: "",
      type: String
    },
    width: {
      default: "100%",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    },
    useAddFolder: {
      default: false,
      type: Boolean
    },
    showAllFolder: {
      default: true,
      type: Boolean
    },
    showTOMEFolder: {
      default: true,
      type: Boolean
    },
    showSENTFolder: {
      default: true,
      type: Boolean
    },
    showDELETEDFolder: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("folder", ["getFolders", "getSerializationFolders"]),
    ...mapGetters("mailConfig", ["getQuickMenu"]),
    isHome() {
      return this.$route.name == "home";
    },
    folders() {
      if (this.inputValue) {
        let quickMenus = [];
        if (this.isHome) {
          quickMenus = this.getFolders.filter(
            f => quickMenuEnum[f.folderType] < 0
          );
        }
        return [...this.getSerializationFolders, ...quickMenus].filter(
          ({ id, nameFolder, folderType }) => {
            if (this.$vnode.key === "move" && folderType === "TOME") {
              return false;
            }

            return (
              nameFolder
                .toLocaleLowerCase()
                .indexOf(this.inputValue.toLocaleLowerCase()) > -1 &&
              (this.showAllFolder || id !== -1) &&
              (this.showTOMEFolder || folderType !== "TOME") &&
              (this.showSENTFolder || folderType !== "SENT") &&
              (this.showDELETEDFolder || folderType !== "DELETED")
            );
          }
        );
      }

      return this.getFolders.filter(f => {
        switch (f.folderType) {
          case "PRIVATE_ROOT":
            return !f.children ? false : f.children.length > 0;
          case "TAG_ROOT":
          case "DRAFTS":
            return false;
          case "ALL":
            return this.showAllFolder;
          case "TOME":
            return this.showTOMEFolder && this.$vnode.key !== "move";
          case "SENT":
            return this.showSENTFolder;
          case "DELETED":
            return this.showDELETEDFolder;
        }

        if (this.isHome && quickMenuEnum[f.folderType] < 0) return true;
        return f.id > 0;
      });
    }
  },
  methods: {
    ...mapActions("folder", ["addFolder"]),
    async addFolderFunc() {
      const { inputValue } = this;
      this.inputValue = inputValue.trim();

      if (!this.inputValue) {
        this.$refs.searchInput?.$refs?.textField?.focus();
      }

      const newFolder = await this.addFolder({
        nameFolder: this.inputValue,
        folderType: "PRIVATE",
        parentId: -8 // private_root이 -8
      });

      if (newFolder) {
        this.$emit("select", newFolder);
      }
    }
  }
};
</script>
