<template>
  <div class="cr-splash-wrapper">
    <div class="cr-splash">
      <component :is="components[this.splashImage]" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    components: {
      type: Object,
      default() {
        return {
          home: () => import("@/commons/views/icons/Home.vue"),
          mail: () => import("@/commons/views/icons/Mail.vue"),
          cal: () => import("@/commons/views/icons/Calendar.vue"),
          contact: () => import("@/commons/views/icons/Contact.vue"),
          drive: () => import("@/commons/views/icons/Drive.vue"),
          board: () => import("@/commons/views/icons/Board.vue"),
          todo: () => import("@/commons/views/icons/Todo.vue"),
          resource: () => import("@/commons/views/icons/Resource.vue"),
          approval: () => import("@/commons/views/icons/Approval.vue")
        };
      }
    }
  },
  computed: {
    ...mapGetters("splash", ["splashImage"])
  }
};
</script>

<style lang="scss" scoped>
.cr-splash-wrapper::v-deep {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 1);

  .cr-splash {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 2.5s;
    svg {
      width: 140px;
      path,
      circle {
        fill: var(--v-primary-base);
      }
    }
  }
}
</style>
