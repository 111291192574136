const state = {
  headline: "",
  show: false,
  type: "",
  params: {},

  preventClose: false,
  fullscreen: false,
  showConfirmBtn: true,
  cancelBtnTitle: "취소",
  confirmBtnTitle: "확인"
};

const getters = {
  headline: ({ headline }) => headline,
  showDialog: ({ show }) => show,
  dialogType: ({ type }) => type,
  params: ({ params }) => params,
  fullscreen: ({ fullscreen }) => fullscreen,
  preventClose: ({ preventClose }) => preventClose,
  showConfirmBtn: ({ showConfirmBtn }) => showConfirmBtn,
  btnTitles: ({ cancelBtnTitle, confirmBtnTitle }) => ({
    cancel: cancelBtnTitle,
    confirm: confirmBtnTitle
  })
};

const mutations = {
  SET_DIALOG: (
    state,
    {
      headline,
      show,
      type,
      params,
      btnTitles,
      preventClose = false,
      showConfirmBtn = true,
      fullscreen = false
    }
  ) => {
    state.headline = headline;
    state.show = show;
    state.type = type || state.type;
    state.params = params || state.params;
    state.showConfirmBtn = showConfirmBtn;
    state.fullscreen = fullscreen;
    state.preventClose = preventClose;

    if (btnTitles) {
      state.cancelBtnTitle = btnTitles.cancel || state.cancelBtnTitle;
      state.confirmBtnTitle = btnTitles.confirm || state.confirmBtnTitle;
    }
  },
  CLOSE_DIALOG: state => {
    state.headline = "";
    state.show = false;
    state.type = "";
    state.params = {};
    state.cancelBtnTitle = "취소";
    state.confirmBtnTitle = "확인";
  },
  SET_PREVENT_CLOSE: (state, preventClose) => {
    state.preventClose = preventClose;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
