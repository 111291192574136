<template>
  <div style="display: contents;">
    <HelpMenu />
    <SearchMenu v-bind="$props" />
    <!-- 2024.03.07 업데이트 아이콘 제거 (변경 예정)
    <AnnounceMenu />
    -->
    <SettingMenu v-bind="$props" />
    <AppMenu />
    <UserMenu />
  </div>
</template>

<script>
import HelpMenu from "./HelpMenu";
import SearchMenu from "./SearchMenu";
// import AnnounceMenu from "./AnnounceMenu";
import SettingMenu from "./SettingMenu";
import AppMenu from "./AppMenu";
import UserMenu from "./UserMenu";

export default {
  components: {
    HelpMenu,
    SearchMenu,
    /* AnnounceMenu, */
    SettingMenu,
    AppMenu,
    UserMenu
  },
  props: {
    route: {
      type: String,
      default: ""
    }
  }
};
</script>
