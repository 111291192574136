import http from "@/commons/api/http";

const prefix = "api/contact/card";

/**
 * 연락처 리스트 조회
 *
 * @param {*} params
 * @returns
 */
const getCardList = params => http.get(prefix, { params });

/**
 * 해당 id 연락처 리스트 조회
 * @param {*} params
 * @returns
 */

const getCardListById = params => http.get(`${prefix}/list/ids`, { params });

/**
 * 인쇄 연락처 리스트 조회
 *
 * @param {*} params
 * @returns
 */
const getPrintCardList = params => http.get(`${prefix}/print`, { params });

/**
 * 연락처 삭제
 *
 * @param {*} cardIds
 * @returns
 */
const deleteCardList = cardIdList => {
  const formData = new FormData();
  formData.append("cardIdList", cardIdList);

  return http.delete(prefix, { data: formData });
};

/**
 * 연락처 중요표시 변경
 *
 * @param {*} params
 * @returns
 */
const updateImportantStatus = ({ cardIdList, importantStatus }) => {
  const formData = new FormData();
  formData.append("importantStatus", importantStatus);
  cardIdList.forEach(cardId => {
    formData.append("cardIdList", cardId);
  });

  return http.put(`${prefix}/importantStatus`, formData);
};

/**
 * 연락처 추가
 *
 * @param {*} cardData
 * @returns
 */
const addCard = cardData => http.post(prefix, cardData);

/**
 * 연락처 일괄 추가
 *
 * @param {*} cardData
 * @returns
 */
const addAllCard = cardData => http.post(`${prefix}/all`, cardData);

/**
 * 연락처 수정
 *
 * @param {*} param0
 * @returns
 */
const updateCard = ({ id, cardData }) => http.put(`${prefix}/${id}`, cardData);

/**
 * 연락처 합치기
 *
 * @param {*} param0
 * @returns
 */
const mergeCard = ({ id, cardData }) =>
  http.put(`${prefix}/${id}/merge`, cardData);

// 주소록 파일 불러오기
const cardRestore = params => {
  const formData = new FormData();
  formData.append("part", params.part);
  formData.append("type", params.type);
  formData.append("charset", params.charset);

  return http.post(`${prefix}/import`, formData);
};

// 불러온 연락처 저장
const cardRestoreSave = params => http.post(`${prefix}/import/save`, params);

// 연락처 이동
const moveCard = params => http.put(`${prefix}/move`, params);

export {
  getCardList,
  getCardListById,
  getPrintCardList,
  deleteCardList,
  updateImportantStatus,
  addCard,
  addAllCard,
  updateCard,
  mergeCard,
  cardRestore,
  cardRestoreSave,
  moveCard
};
