export default [
  {
    path: "/board",
    name: "board",
    component: () =>
      import(/* webpackChunkName: "board" */ "../views/BoardMain.vue"),
    children: [
      {
        path: ":boardId",
        name: "board_list"
      },
      {
        path: ":boardId/:actionObj",
        name: "board_list_action"
      }
    ]
  },
  {
    path: "/board/view/:postId/:actionObj",
    name: "board_post_view",
    component: () =>
      import(/* webpackChunkName: "board" */ "../views/BoardMain.vue")
  },
  {
    path: "/board/config/:tabType",
    name: "board_config",
    component: () =>
      import(/* webpackChunkName: "board" */ "../views/BoardMain.vue")
  },
  {
    // type  : all, post, reply, file
    // query : 검색 쿼리 ObJect
    path: "/board/search/:type/:query",
    name: "board_search",
    component: () =>
      import(/* webpackChunkName: "board" */ "../views/BoardMain.vue")
  }
];
