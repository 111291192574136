<template>
  <v-text-field
    ref="textField"
    outlined
    dense
    hide-details
    :placeholder="
      $t('mail.281', {
        value: useAddFolder ? $t('mail.424') : ''
      })
    "
    prepend-inner-icon="mdi-magnify"
    class="cr-search-wrapper py-2 px-3"
    v-model="value"
    @click.stop
    @keydown.stop
    @keydown.esc="$emit('update:openMenu', false)"
  />
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  &.cr-search-wrapper {
    position: absolute;
    top: 0px;
    bottom: 245px;
    left: 0px;
    right: 0px;
    background: #fafafb;
  }

  > .v-input__control {
    background-color: #fff;
  }
}
</style>

<script>
export default {
  props: {
    inputValue: {
      default: "",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    },
    useAddFolder: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    value: {
      get() {
        return this.inputValue;
      },
      set(value) {
        this.$emit("update:inputValue", value);
      }
    }
  }
};
</script>
