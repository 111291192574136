<template>
  <!-- 주메뉴 drawer -->
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <!-- 버튼 -->
    <div
      class="py-2 px-1 d-flex"
      style="justify-content: space-between"
      :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''"
    >
      <div style="flex: 1; width: 100%;" class="px-1">
        <v-btn
          large
          depressed
          style="width: 100%; align-items: center;"
          :disabled="!hasWritePermission || isTrash"
          class="mr-1 primary drawer-menu-button"
          @click="e => openCardDialog(e)"
        >
          <h4 v-text="$t('contact.12')"></h4>
        </v-btn>
      </div>
      <div style="flex: 1; width: 100%" class="px-1" v-if="isCustomerAdmin">
        <v-btn
          large
          depressed
          style="width: 100%"
          class="v-btn-light drawer-menu-button"
          @click="e => openGroupDialog(e)"
        >
          <h4 v-text="$t('contact.82')"></h4>
        </v-btn>
      </div>
    </div>
    <!-- 주소록 그룹 트리 -->
    <TreeView />
  </v-navigation-drawer>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations } from "vuex";
import TreeView from "../components/treeview/TreeView";
import { hasPermissionInChildren } from "@/customer/constant/constants";
export default {
  components: {
    TreeView
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("customerGroup", [
      "getRootGroup",
      "getCustomerAdminList",
      "getCurrentGroup"
    ]),
    isCustomerAdmin() {
      const { id } = this.getUserInfo;
      return this.getCustomerAdminList.some(
        item => item.userDetail.userId === id
      );
    },
    isTrash() {
      const currentGroup = this.getCurrentGroup;
      if (currentGroup) {
        return currentGroup.customerGroupType === "TRASH";
      }
      return false;
    },
    hasWritePermission() {
      const currentGroup = this.getCurrentGroup;
      if (currentGroup) {
        if (currentGroup.customerGroupType === "ROOT") {
          return hasPermissionInChildren(this.getRootGroup.children);
        }
        const { hasPermission } = this.getCurrentGroup;
        return hasPermission;
      }
      return false;
    }
  },
  methods: {
    ...mapMutations("customerDialog", ["SET_DIALOG"]),
    // 개인 주소록 그룹 추가 Dialog 열기
    openCardDialog(e) {
      e.stopPropagation();
      this.SET_DIALOG({
        title: i18n.t("contact.12"),
        type: "customerCard",
        showExpand: true
      });
    },
    openGroupDialog(e) {
      e.stopPropagation();

      const parentCustomerGroup = this.getRootGroup;
      this.SET_DIALOG({
        title: i18n.t("customer.add.group"),
        type: "customerGroup",
        params: {
          mode: "ADD",
          type: "ALL",
          parentCustomerGroup
        }
      });
    },
    // Dialog 닫기
    closeDialog() {
      this.dialog.showDialog = false;
    }
  },
  data: () => ({
    drawer: true
  })
};
</script>
