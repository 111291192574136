import http from "./http";

const prefix = "/api/share";

// 공유 리스트
const getShareMembers = ({ moduleType, objectId }) => {
  return http.get(`${prefix}`, {
    params: { moduleType, objectId }
  });
};

// 공유 추가
const addShare = params => {
  return http.post(prefix, params);
};

// 공유 수정
const updateShare = params => {
  return http.put(prefix, params);
};

// 링크공유 추가 및 조회
const getLinkShareInfo = ({ moduleType, objectId }) => {
  return http.get(`${prefix}/link`, {
    params: { moduleType, objectId }
  });
};

// 링크 공유 수정
const updateLinkShare = params => {
  return http.put(`${prefix}/link`, params);
};

// 링크 공유 삭제
const deleteLinkShare = ({ id }) => {
  return http.delete(`${prefix}/link/${id}`);
};

// 공유받은 쪽에서의 공유 취소
const shareCancel = ({ cancelList, moduleType }) => {
  const formData = new FormData();
  formData.append("cancelList", cancelList);
  formData.append("moduleType", moduleType);
  return http.delete(`${prefix}/cancel`, { data: formData });
};

// 공유한 공유 및 메타정보 삭제
const deleteShare = ({ objectIds, moduleType }) => {
  const formData = new FormData();
  formData.append("objectIds", objectIds);
  formData.append("moduleType", moduleType);

  return http.delete(`${prefix}`, { data: formData });
};

export {
  getShareMembers,
  addShare,
  updateShare,
  getLinkShareInfo,
  updateLinkShare,
  deleteLinkShare,
  shareCancel,
  deleteShare
};
