const byteCalculation = bytes => {
  const s = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  const parseIntBytes = parseInt(bytes);
  const e = Math.floor(Math.log(parseIntBytes) / Math.log(1024));

  if (e == "-Infinity") return "0 " + s[2];

  return `${(parseIntBytes / Math.pow(1024, Math.floor(e))).toFixed(2)} ${
    s[e]
  }`;
};

const separateByteCalculation = bytes => {
  const s = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  const parseIntBytes = parseInt(bytes);
  const e = Math.floor(Math.log(parseIntBytes) / Math.log(1024));

  if (e == "-Infinity")
    return {
      size: 0,
      unit: "MB"
    };

  return {
    size: (parseIntBytes / Math.pow(1024, Math.floor(e))).toFixed(2),
    unit: s[e]
  };
};

export { byteCalculation, separateByteCalculation };
