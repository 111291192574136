<template>
  <v-menu
    min-width="80px"
    v-model="show"
    :position-x="getX"
    :position-y="getY"
    absolute
    offset-y
  >
    <v-list dense>
      <div class="title px-4" v-if="getTitle">
        {{ getTitle }}
      </div>
      <div v-for="(item, index) in getItemList" :key="index">
        <v-menu right offset-x open-on-hover v-if="item.type == 'menu'">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>

          <v-list dense>
            <v-list-item
              link
              v-for="(title, index) in item.menuList"
              :key="index"
              @click.stop="() => menuClick(item, index)"
            >
              <v-list-item-title> {{ title }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item
          link
          v-else
          v-show="!item.hideItem"
          :disabled="item.disabled"
          @click="item.func"
        >
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<style scoped>
.v-list >>> .title {
  font-weight: 400;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("positioningMenu", [
      "isShow",
      "getTitle",
      "getX",
      "getY",
      "getItemList"
    ]),
    show: {
      get() {
        return this.isShow;
      },
      set() {
        return this.closePositioningMenu();
      }
    }
  },
  methods: {
    ...mapActions("positioningMenu", ["closePositioningMenu"]),
    menuClick(item, index) {
      this.show = false;

      if (item.func) item.func(item.menuList, index);
    }
  }
};
</script>
