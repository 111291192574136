import {
  getPostDetail,
  getPostList,
  getRead,
  readPost,
  addPost,
  getReplyCount,
  updatePost,
  deletePost,
  updateIsNotice,
  getFeeling,
  saveFeeling,
  deleteFeeling,
  getFileList
} from "@/board/api/board.api";
import router from "@/commons/router";
import { virtualBoardConverter } from "@/board/constant/boardType";
import Vue from "vue";
import i18n from "@/_locales";

const state = {
  // 일반 게시글 목록
  postList: {
    loading: false,
    page: 0,
    totalPage: 0,
    totalElements: 0,
    list: []
  },
  // 공지사항 목록
  noticeList: {
    initialized: false,
    loading: false,
    page: 0,
    totalPage: 0,
    totalElements: 0,
    list: []
  },
  // 게시글 데이타
  posts: {}
};

const getters = {
  // 일반 게시글
  getPostList: ({ postList, posts }) => {
    const list = postList.list.map(postId => posts[postId]);
    const result = { ...postList, list };
    return result;
  },

  // 공지사항
  getNoticeList: ({ noticeList, posts }) => {
    const list = noticeList.list.map(postId => posts[postId]);
    const result = { ...noticeList, list };
    return result;
  },

  // 게시글 컴포넌트 공통
  getPostById: ({ posts }) => postId => posts[postId]
};
const mutations = {
  // ==================== 게시글 ====================
  INIT_POST_STATE: state => {
    state.postList = {
      loading: false,
      page: 0,
      totalPage: 0,
      totalElements: 0,
      list: []
    };
    state.noticeList = {
      initialized: false,
      loading: false,
      page: 0,
      totalPage: 0,
      totalElements: 0,
      list: []
    };
    state.posts = {};
  },
  SET_POST_LIST: (
    { postList, posts },
    { list, page, totalPage, totalElements }
  ) => {
    // 제로인덱스 보정
    page += 1;

    // 첫 페이지일 경우 리스트 초기화
    if (page === 1) postList.list = [];
    // 게시글 데이터 저장
    list.forEach(post => {
      // 이미 존재한다면 읽음 여부 빼고 데이터 최신화
      const postData = posts[post.id]
        ? { ...post, seen: posts[post.id].seen }
        : post;
      Vue.set(posts, post.id, {
        ...postData,
        boardId: post?.boardId ?? 0,
        userId: post?.userId ?? 0,
        userName: post?.userName ?? ""
      });
      postList.list = [...postList.list, post.id];
    });
    postList.page = page;
    postList.totalPage = totalPage;
    postList.totalElements = totalElements;
  },
  INIT_POST_LIST: state => {
    Vue.set(state, "postList", {
      loading: false,
      page: 1,
      totalPage: 1,
      totalElements: 0,
      list: []
    });
  },
  RESET_POST_LIST: ({ postList }) => {
    Vue.set(postList, "list", []);
  },
  SET_POST_LIST_LOADING: ({ postList }, loading) => {
    Vue.set(postList, "loading", loading);
  },
  SET_POST: ({ posts }, post) => {
    // 기존 읽음 정보는 유지
    if (posts[post.id]?.seen) {
      post = { ...post, seen: true };
    }
    Vue.set(posts, post.id, post);
  },
  DELETE_POST: (state, postId) => {
    state.postList.list = state.postList.list.filter(id => id != postId);
    state.noticeList.list = state.noticeList.list.filter(id => id != postId);
    Vue.delete(state.posts, postId);
  },
  // 첨부파일 목록 세팅
  SET_FILE_LIST: ({ posts }, { postId, fileList }) => {
    const post = posts[postId];
    Vue.set(posts, postId, {
      ...post,
      files: fileList,
      fileCount: fileList.length
    });
  },
  RESET_FILE_LIST: ({ posts }, postId) => {
    const post = posts[postId];
    Vue.set(posts, postId, { ...post, files: [] });
  },

  // ==================== 리플 ====================
  SET_REPLY_COUNT: ({ posts }, { postId, count }) => {
    Vue.set(posts, postId, { ...posts[postId], replyCount: count });
  },

  // ==================== 읽음 ====================
  SET_READ_DATA: ({ posts }, { postId, payload }) => {
    Vue.set(posts, postId, { ...posts[postId], readList: payload });
    Vue.set(posts, postId, { ...posts[postId], readCount: payload.length });
  },
  SET_READ_COUNT: ({ posts }, { postId, readCount, seen }) => {
    Vue.set(posts, postId, { ...posts[postId], readCount });
    Vue.set(posts, postId, { ...posts[postId], seen });
  },
  RESET_READ_LIST: ({ posts }, postId) => {
    Vue.set(posts, postId, { ...posts[postId], readList: [] });
  },

  // ==================== 감정표현 ====================
  SET_FEEL_DATA: ({ posts }, { postId, feelList, userId }) => {
    Vue.set(posts, postId, {
      ...posts[postId],
      feelList,
      feelCount: feelList.length,
      ifelt: feelList.filter(f => f.userId == userId).length > 0
    });
  },
  RESET_FEEL_LIST: ({ posts }, postId) => {
    const post = posts[postId];
    Vue.set(posts, postId, { ...post, feelList: [] });
  },
  // 감정표현 갯수 갱신
  SET_FEEL_COUNT: ({ posts }, { postId, ifelt, feelCount, feelType }) => {
    const post = posts[postId];
    Vue.set(posts, postId, { ...post, feelCount, ifelt, feelType });
  },

  // ============== 공지사항 ===============
  SET_NOTICE_PANEL: ({ posts, noticeList }, list) => {
    // 공지사항 패널 목록 초기화
    noticeList.initialized = true;
    noticeList.list = [];
    // 게시글 데이터 저장
    list.forEach(n => {
      let post;
      if (posts[n.id]) {
        // 이미 store에 존재하는 게시글일 경우 일부 정보들만 갱신
        const {
          title,
          seen,
          updatedTimeMillis,
          userId,
          userEmail,
          userName
        } = n;
        post = {
          ...posts[n.id],
          title,
          seen,
          updatedTimeMillis,
          userId,
          userEmail,
          userName
        };
      } else {
        post = n;
      }
      noticeList.list.push(n.id);
      Vue.set(posts, n.id, post);
    });
  },
  SET_NOTICE_LIST: ({ posts, noticeList }, { list, page, totalPage }) => {
    // 제로인덱스 보정
    page += 1;
    // 첫 페이지일 경우 리스트 초기화
    if (page === 1) noticeList.list = [];
    // 게시글 데이터 저장
    list.forEach(post => {
      // 읽음 정보가 있다면 유지
      const seen = posts[post.id]?.seen ? true : posts.seen;
      Vue.set(posts, post.id, {
        ...post,
        seen,
        boardId: post?.boardId ?? 0,
        userId: post?.userId ?? 0,
        userName: post?.userName ?? ""
      });
      noticeList.list = [...noticeList.list, post.id];
    });
    noticeList.initialized = true;
    noticeList.page = page;
    noticeList.totalPage = totalPage;
  },
  INIT_NOTICE_LIST: state => {
    Vue.set(state, "noticeList", {
      initialized: false,
      loading: false,
      page: 0,
      totalPage: 0,
      totalElements: 0,
      list: []
    });
  },
  RESET_NOTICE_LIST: ({ noticeList }) => {
    Vue.set(noticeList, "list", []);
  },
  SET_NOTICE_LIST_LOADING: ({ noticeList }, loading) => {
    Vue.set(noticeList, "loading", loading);
  }
};

const actions = {
  // 게시글 단건 조회
  async loadPost({ commit, dispatch }, postId) {
    const { status, data } = await getPostDetail(postId);

    // 에러 발생시
    if (status != 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.33"), type: "ERROR" },
        { root: true }
      );
      commit("boardDialog/CLOSE_DIALOG", "", { root: true });
    }

    // 정상일 경우 Post 데이터 최신화
    commit("SET_POST", data);
  },
  // 게시글 상세 불러오기 (TODO 로직 변경 필요)
  async loadPostView({ rootGetters, commit, dispatch }, postId) {
    const { status, data } = await getPostDetail(postId);

    if (status != 200) {
      let message = i18n.t("board.34");
      let type = "ERROR";
      if (status == 403) {
        message = i18n.t("board.8");
        type = "VALIDATION";
      }
      dispatch("snackbar/openSnackbar", { message, type }, { root: true });

      // 에러 발생시 리스트로 다시 라우팅
      const { id: boardId } = rootGetters["boardRoute/getRouteListInfo"];
      return router.push({
        name: "board_list",
        params: {
          boardId: virtualBoardConverter(boardId)
        }
      });
    }

    commit("SET_POST", data);

    // 라우팅 게시판 아이디 검사 (트리의 액티브 효과와 싱크를 맞추기 위해)
    const postData = data;
    const routeBoardId = rootGetters["boardRoute/getRouteListInfo"].id;
    const groupId = rootGetters["board/getBoardById"](postData.boardId)
      ?.groupId;

    // 라우팅의 게시판 아이디와 게시글의 게시판아이디 일치하면 OK
    if (postData.boardId == routeBoardId) return;
    // 다를경우
    // 1. 모든 게시물 일 경우 OK
    if (routeBoardId == -1) return;
    // 2. 내 게시물일 경우 작성자 아이디와 유저 아이디가 일치하면 ok
    if (routeBoardId == -2) {
      if (postData.userId == rootGetters["auth/getUserInfo"].id) return;
    }
    // 3. 상위 게시판일 경우 OK
    if (groupId == routeBoardId) return;

    // 일치하지 않으면 라우팅 게시판 아이디를 게시물의 게시판아이디로 변경해준다.
    dispatch("boardRoute/setParamBoardId", postData.boardId, { root: true });
  },
  // 게시글 리스트 불러오기
  async loadPostList(
    { rootGetters, commit, dispatch },
    { boardId, page = 1, sort = "updatedTimeMillis", dir = "desc" }
  ) {
    commit("SET_POST_LIST_LOADING", true);

    const viewType = rootGetters["boardConfig/getViewType"];
    if (viewType === "CARD" && page === 1) commit("RESET_POST_LIST");

    const { status, data = [] } = await getPostList({
      id: boardId,
      page: page - 1,
      pageSize: rootGetters["boardConfig/getPostPageSize"],
      sort: sort,
      dir: dir,
      contentSize: viewType === "LIST" ? 100 : 0
    });

    switch (status) {
      case 200:
        {
          let postList = data.content;
          const viewType = rootGetters["boardConfig/getViewType"];
          if (viewType === "LIST") commit("RESET_POST_LIST");
          // 리스트 반영
          await commit("SET_POST_LIST", {
            list: postList,
            page: data.number,
            totalPage: data.totalPages,
            totalElements: data.totalElements
          });
        }
        break;
      case 403:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.18"), type: "ERROR" },
          { root: true }
        );
        await commit("INIT_POST_LIST");
        break;
      default:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.19"), type: "ERROR" },
          { root: true }
        );
        if (page === 1) await commit("INIT_POST_LIST");
        break;
    }

    commit("SET_POST_LIST_LOADING", false);
  },
  // 현재 게시판의 첫 페이지를 가져오고, 최상단으로 이동
  async getLoadFirstPage({ rootGetters, dispatch }) {
    const routeId = rootGetters["boardRoute/getRouteListInfo"].id;

    // 리스트 첫페이지 불러옴
    await dispatch("loadPostList", {
      boardId: routeId
    });
  },
  // 게시물 읽기
  async readPost({ commit, dispatch }, postId) {
    const { data, status } = await readPost(postId);

    if (!status == 200) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.20"), type: "ERROR" },
        { root: true }
      );
    }

    commit("SET_READ_COUNT", {
      postId,
      readCount: data.readCount,
      seen: data.seen
    });
  },
  // 게시물 읽음 목록 조회
  async getRead({ commit, dispatch }, postId) {
    const response = await getRead(postId);

    if (response.status !== 200) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.20"), type: "ERROR" },
        { root: true }
      );
    }

    commit("SET_READ_DATA", { postId, payload: response.data });
  },
  // 게시물 삭제 후 다음페이지 게시물 한개 불러오기
  async deletePostSync({ rootGetters, getters, commit, dispatch }, id) {
    // 해당 post 삭제
    commit("DELETE_POST", id);

    // 마지막 페이지를 최신화
    const listType = rootGetters["boardRoute/getListType"];
    if (listType == "post") {
      // 일반 리스트일때
      const boardId = rootGetters["boardRoute/getRouteListInfo"].id;
      dispatch("loadPostList", {
        boardId,
        page: getters.getPostList.page,
        pageSize: rootGetters["boardConfig/getPostPageSize"]
      });
    } else {
      // 공지사항 리스트 일때
      dispatch("loadNoticeList", {
        page: getters.getNoticeList.page,
        pageSize: rootGetters["boardConfig/getPostPageSize"]
      });
    }
  },

  // 첨부파일 목록 가져오기
  async getFileList({ dispatch, commit }, { postId, uploadType }) {
    const { status, data } = await getFileList({
      targetId: postId,
      uploadType
    });

    if (status !== 200) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.20"), type: "ERROR" },
        { root: true }
      );
    }

    commit("SET_FILE_LIST", { postId, fileList: data });
  },
  // 게시글 추가
  async addPost({ rootGetters, dispatch, commit }, param) {
    param.fileIds = rootGetters["boardFile/getFileIds"];
    const { status, data } = await addPost(param);
    const messages = {
      ERROR: i18n.t("board.21"),
      SUCCESS: i18n.t("board.22")
    };
    const type = status === 201 ? "SUCCESS" : "ERROR";

    const routeId = rootGetters["boardRoute/getRouteListInfo"].id;

    /**
     현재 라우팅된 게시판 아이디가 모든게시물, 내 게시물 그리고 등록한 게시판 아이디와 일치할 경우
     게시물 리스트를 업데이트 해준다.
    */
    if (status == 201) {
      if (routeId == -1 || routeId == -2 || routeId == param.boardId) {
        await dispatch("getLoadFirstPage");
        // 공지사항으로 등록한 경우 공지사항 패널 최신화
        if (param.isNotice) await dispatch("loadNoticePanel");
      }
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
    commit("boardDialog/CLOSE_DIALOG", "", { root: true });
    return data;
  },
  // 게시글 수정
  async updatePost({ getters, rootGetters, dispatch, commit }, param) {
    param.fileIds = rootGetters["boardFile/getFileIds"];
    param.deleteFileIds = rootGetters["boardFile/getDeleteFileIds"];

    const { status } = await updatePost(param);
    const messages = {
      ERROR: i18n.t("board.23"),
      SUCCESS: i18n.t("board.24")
    };
    let type = status === 200 ? "SUCCESS" : "ERROR";
    const routeId = rootGetters["boardRoute/getRouteListInfo"].id;
    const listType = rootGetters["boardRoute/getListType"];

    if (status == 200) {
      const originPost = getters.getPostById(param.id);
      // 수정된 post 최신화
      await dispatch("loadPost", param.id);

      // 공지사항 리스트에서 수정했을 경우
      if (listType == "notice") {
        // 공지사항 해제했을 경우
        if (originPost.isNotice && !param.isNotice) {
          await dispatch("deletePostSync", param.id);
        }
      } else if (listType == "search") {
        // 검색 목록에서 수정시
        commit("boardDialog/CLOSE_DIALOG", "", { root: true });
        dispatch("boardSearch/refreshPostList", "", { root: true });
      } else {
        // 공지사항으로 등록된 글을 수정했거나 공지사항 여부가 변동 되었다면
        // 공지사항 패널 리로드
        if (originPost.isNotice || originPost.isNotice != param.isNotice) {
          dispatch("loadNoticePanel");
        }

        // 현재 보고있는 게시판과 다른 게시판으로 이동했을 경우
        const routeBoard = rootGetters["board/getBoardById"](routeId);
        const changeBoard = rootGetters["board/getBoardById"](param.boardId);
        if (
          routeId != -1 &&
          routeId != -2 &&
          !(routeBoard.groupId == changeBoard.groupId)
        ) {
          await dispatch("deletePostSync", param.id);
        }
      }
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
    commit("boardDialog/CLOSE_DIALOG", "", { root: true });
  },
  // 게시글 삭제
  async deletePost({ getters, rootGetters, dispatch }, postId) {
    const targetPost = getters.getPostById(postId);
    const listType = rootGetters["boardRoute/getListType"];
    const { status } = await deletePost(postId);

    const messages = {
      ERROR: i18n.t("board.25"),
      SUCCESS: i18n.t("board.26")
    };
    const type = status === 200 ? "SUCCESS" : "ERROR";

    if (status == 200) {
      if (listType == "post") {
        // 일반 리스트에서 공지사항을 삭제했다면 공지사항 패널 리스트 갱신
        if (targetPost.isNotice) dispatch("loadNoticePanel");
      }
      await dispatch("deletePostSync", postId);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
  },

  // 게시글 수정 (게시글 보기 일때)
  async updatePostView({ rootGetters, commit, dispatch }, param) {
    param.fileIds = rootGetters["boardFile/getFileIds"];
    param.deleteFileIds = rootGetters["boardFile/getDeleteFileIds"];

    const { status } = await updatePost(param);
    const messages = {
      ERROR: i18n.t("board.23"),
      SUCCESS: i18n.t("board.24")
    };
    const type = status === 200 ? "SUCCESS" : "ERROR";

    if (status == 200) {
      dispatch("loadPostView", param.id);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
    commit("boardDialog/CLOSE_DIALOG", "", { root: true });
  },
  // 게시글 삭제 (게시글 보기 일때)
  async deletePostView({ rootGetters, dispatch, commit }, postId) {
    const response = await deletePost(postId);

    const messages = {
      ERROR: i18n.t("board.25"),
      SUCCESS: i18n.t("board.26")
    };
    const type = response.status === 200 ? "SUCCESS" : "ERROR";
    const listType = rootGetters["boardRoute/getListType"];

    if (response.status == 200) {
      if (listType === "search") {
        // 검색 페이지일 경우
        commit("boardDialog/CLOSE_DIALOG", "", { root: true });
        dispatch("boardSearch/refreshPostList", "", { root: true });
      } else {
        router.push({
          name: "board_list_action",
          params: {
            boardId: virtualBoardConverter(
              rootGetters["boardRoute/getRouteListInfo"].id
            )
          }
        });
      }
    }
    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
  },
  // 리플 갯수 갱신
  async getReplyCount({ commit }, postId) {
    const { status, data } = await getReplyCount(postId);

    if (status === 200) {
      commit("SET_REPLY_COUNT", { postId, count: data });
    }
  },
  // 감정 표현 목록 조회
  async getFeeling({ commit, dispatch, rootGetters }, postId) {
    const { data: feelList, status } = await getFeeling(postId);

    if (status !== 200) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.20"), type: "ERROR" },
        { root: true }
      );
    }

    const { id: userId } = rootGetters["auth/getUserInfo"];
    commit("SET_FEEL_DATA", { postId, feelList, userId });
  },
  // 감정 표현 저장
  async saveFeeling({ commit, dispatch }, { postId, feelType }) {
    const { data, status } = await saveFeeling({ postId, feelType });

    let type = "ERROR";
    let message = i18n.t("board.32");
    if (status === 200) {
      type = "SUCCESS";
      message = i18n.t("board.5");
      commit("SET_FEEL_COUNT", { ...data, feelType });
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  // 감정 표현 삭제
  async deleteFeeling({ commit, dispatch }, postId) {
    const { data, status } = await deleteFeeling(postId);

    let message = i18n.t("board.32");
    let type = "ERROR";
    if (status == 200) {
      message = i18n.t("board.5");
      type = "SUCCESS";
      // 감정표현 목록 또는 갯수 갱신
      commit("SET_FEEL_COUNT", data);
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  // ============== 공지사항 ===============
  // 공지사항 패널 목록 조회
  async loadNoticePanel({ commit, dispatch }) {
    commit("SET_NOTICE_LIST_LOADING", true);

    const { status, data } = await getPostList({
      id: virtualBoardConverter("notice"),
      page: 0,
      pageSize: 5,
      contentSize: -1
    });

    if (status == 200) {
      commit("SET_NOTICE_PANEL", data.content);
    } else {
      let message = i18n.t("board.19");
      let type = "ERROR";
      dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    }

    commit("SET_NOTICE_LIST_LOADING", false);
  },
  // 공지사항 목록 조회
  async loadNoticeList(
    { rootGetters, commit, dispatch },
    { page = 1, sort = "updatedTimeMillis", dir = "desc" }
  ) {
    commit("SET_NOTICE_LIST_LOADING", true);
    if (page === 1) commit("RESET_NOTICE_LIST");

    const { status, data } = await getPostList({
      id: virtualBoardConverter("notice"),
      page: page - 1,
      pageSize: rootGetters["boardConfig/getPostPageSize"],
      sort,
      dir,
      contentSize: 0
    });

    switch (status) {
      case 200:
        {
          // 리스트 반영
          let postList = data.content;
          commit("SET_NOTICE_LIST", {
            list: postList,
            page: data.number,
            totalPage: data.totalPages,
            totalElements: data.totalElements
          });
        }
        break;
      case 403:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.18"), type: "ERROR" },
          { root: true }
        );
        commit("INIT_NOTICE_LIST");
        break;
      default:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.19"), type: "ERROR" },
          { root: true }
        );
        if (page === 1) commit("INIT_NOTICE_LIST");
        break;
    }

    commit("SET_NOTICE_LIST_LOADING", false);
  },
  // 공지사항 여부 수정
  async updateIsNotice(
    { getters, rootGetters, commit, dispatch },
    { postId, isNotice }
  ) {
    const listType = rootGetters["boardRoute/getListType"];

    const { status } = await updateIsNotice({ postId, isNotice });
    let type = status === 200 ? "SUCCESS" : "ERROR";
    const messages = {
      SUCCESS: isNotice ? i18n.t("board.129") : i18n.t("board.130"),
      ERROR: isNotice ? i18n.t("board.131") : i18n.t("board.132")
    };

    // 공지사항 여부 최신화
    if (status == 200) {
      // 공지사항 리스트 에서 공지사항 제거시
      if (listType == "notice" && !isNotice) {
        dispatch("deletePostSync", postId);
      } else if (listType == "search") {
        // 검색 목록에서 수정시
        commit("boardDialog/CLOSE_DIALOG", "", { root: true });
        dispatch("boardSearch/refreshPostList", "", { root: true });
      } else {
        const originPost = getters.getPostById(postId);
        commit("SET_POST", { ...originPost, isNotice });
        dispatch("loadNoticePanel");
      }
    }
    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type: type },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
