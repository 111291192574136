import Vue from "vue";
import { updateHeader } from "@/todo/api/board.api";
import headerUtils from "../utils/header";

const state = {
  header: []
};

const getters = {
  header: ({ header }) => header,
  isEmpty: ({ header }) => header.length === 0,
  titleHeader: ({ header }) => {
    const [title] = header.filter(h => h.type === headerUtils.TITLE().type);
    return title ?? {};
  },
  timelineHeaders: ({ header }) =>
    header.filter(h => h.type === headerUtils.TIMELINE().type)
};

const mutations = {
  RESET_STATE: state => {
    state.header = [];
  },
  SET_HEADER: (state, header) => {
    Vue.set(
      state,
      "header",
      header.map(h => {
        if (h.type == headerUtils.ADDITIONAL().type) {
          delete h.width;
        }
        return h;
      })
    );
  },
  CHANGE_HEADER: (state, { value, header }) => {
    const index = state.header.findIndex(h => h.value == value);
    if (index > -1) {
      Vue.set(state.header, index, { ...state.header[index], ...header });
    }
  },
  ADD_HEADER: (state, { index, header }) => {
    state.header.splice(index, 0, header);
  },
  DELETE_HEADER: (state, { index }) => {
    const { type, value, connectedColumn: cv } = state.header[index];
    // 숫자 타입
    if (type == headerUtils.NUMBER().type && cv) {
      const idx = state.header.findIndex(h => h.value == cv);
      if (idx > -1) {
        state.header[idx].connectedColumn =
          state.header[idx].connectedColumn
            ?.split(",")
            ?.filter(v => v !== value)
            ?.join(",") ?? "";
      }
    }

    // 타임라인 타입
    if (type == headerUtils.TIMELINE().type && cv) {
      cv?.split(",")?.forEach(v => {
        const idx = state.header.findIndex(h => h.value == v);
        if (idx > -1) state.header[idx].connectedColumn = "";
      });
    }

    Vue.delete(state.header, index);
  },
  RESIZE_HEADER: (state, { value, width }) => {
    const index = state.header.findIndex(h => h.value == value);
    if (index > -1) {
      Vue.set(state.header, index, { ...state.header[index], width });
    }
  },
  MOVE_HEADER: (state, { value1, value2 }) => {
    const index1 = state.header.findIndex(({ value }) => value == value1);
    const index2 = state.header.findIndex(({ value }) => value == value2);
    const tmp = state.header[index1];
    Vue.delete(state.header, index1);
    state.header.splice(index2, 0, tmp);
  },
  CONNECT_HEADER: (state, { timelineValue: v1, numberValues: v2 }) => {
    // timeline에 숫자컬럼 value 입력
    const index1 = state.header.findIndex(({ value }) => value == v1);
    Vue.set(state.header[index1], "connectedColumn", v2);

    // 숫자 컬럼에 timeline value 입력
    v2.split(",").forEach((v, idx) => {
      const index2 = state.header.findIndex(h => h.value == v);
      if (index2 > -1) {
        Vue.set(state.header[index2], "connectedColumn", v1);
        const tmp = state.header[index2];
        Vue.delete(state.header, index2);
        const timelineIdx = state.header.findIndex(({ value }) => value == v1);
        state.header.splice(timelineIdx + 1 + idx, 0, tmp);
      }
    });
  },
  DISCONNECT_HEADER: (state, { timelineValue: v1 }) => {
    const index1 = state.header.findIndex(({ value }) => value == v1);
    const { connectedColumn } = state.header[index1] ?? {};
    if (connectedColumn) {
      connectedColumn.split(",").forEach(v => {
        const index = state.header.findIndex(({ value }) => value == v);
        if (index > -1) {
          state.header[index].connectedColumn = "";
        }
      });
      state.header[index1].connectedColumn = "";
    }
  }
};

const actions = {
  /**
   *
   * @param {*} mutation - ADD_HEADER / DELETE_HEADER / RESIZE_HEADER / CHANGE_HEADER / MOVE_HEADER
   */
  async updateHeader(
    { state, commit, dispatch },
    { mutation, boardId, params }
  ) {
    const prevHeader = [...state.header];
    commit(mutation, params);
    const { status } = await updateHeader(
      boardId,
      state.header.filter(h => h.value != "data-table-select")
    );

    let message = `컬럼을 수정하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 409:
        message = "컬럼 정보를 다시 확인해주세요.";
        break;
      case 404:
        message = "보드가 존재하지 않습니다.";
        break;
      case 201:
        return;
    }

    commit("SET_HEADER", prevHeader);
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
