<template>
  <v-tooltip bottom v-if="showHelpMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="help">
        <v-icon color="primary">mdi-help-circle-outline </v-icon>
      </v-btn>
    </template>
    <div v-text="$t('common.도움말')"></div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["getCompanyInfo"]),
    showHelpMenu() {
      return !this.$vuetify.breakpoint.xs && this.getCompanyInfo?.ui?.help == 1;
    }
  },
  methods: {
    // 도움말
    help() {
      let url = this.getCompanyInfo?.ui?.helpUrl;
      if (!url) {
        url = "https://www.sirteam.net";
      }

      window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped></style>
