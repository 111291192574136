<template>
  <v-dialog
    :value="useForceChangePasswordDialog"
    persistent
    max-width="340"
    content-class="otp_dialog"
  >
    <v-card>
      <v-card-title class="text-h6 font-weight-bold">
        {{ this.title }}
      </v-card-title>
      <v-card-text>
        <div v-html="subTitle"></div>
        <div class="caption blue--text">{{ $t("account.26") }}</div>

        <div class="grey--text text--darken-2 mt-5" v-text="$t('account.24')" />
        <v-text-field
          dense
          filled
          solo
          flat
          autocomplete="off"
          ref="new"
          background-color="grey lighten-3"
          :type="newShowPw ? 'text' : 'password'"
          :append-icon="newShowPw ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="newShowPw = !newShowPw"
          :error-messages="errorMsg.newPwError"
          tabindex="2"
          v-model="newPw"
          @blur="newPwCheck"
        ></v-text-field>

        <div class="grey--text text--darken-2" v-text="$t('account.25')" />
        <v-text-field
          dense
          filled
          solo
          flat
          autocomplete="off"
          ref="newChk"
          background-color="grey lighten-3"
          :type="checkNewShowPw ? 'text' : 'password'"
          :append-icon="checkNewShowPw ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="checkNewShowPw = !checkNewShowPw"
          :error-messages="errorMsg.checkNewPwError"
          tabindex="3"
          v-model="checkNewPw"
          @blur="chkNewPwCheck"
        ></v-text-field>
      </v-card-text>
      <v-card-actions @click.stop>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="accent"
          :disabled="!enableOkButton"
          @click="save"
        >
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
::v-deep .v-messages__message {
  line-height: 1.3;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "@/_locales";
import { isBlank, passwordValidation } from "@/commons/utils/validation";

export default {
  data() {
    return {
      // show: false,
      /* 오류 메시지 */
      errorMsg: {
        newPwError: "",
        checkNewPwError: ""
      },
      /* 비밀번호 보기 */
      newShowPw: false,
      checkNewShowPw: false,
      /* 새 비밀번호 */
      newPw: "",
      checkNewPw: ""
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters("auth", ["useForceChangePasswordDialog", "getUserInfo"]),
    enableOkButton() {
      this.newPwCheck();
      this.chkNewPwCheck();
      return (
        this.newPw &&
        this.checkNewPw &&
        this.newPw == this.checkNewPw &&
        this.errorMsg.newPwError == "" &&
        this.errorMsg.checkNewPwError == ""
      );
    },
    title() {
      if (
        this.getUserInfo.ROLE == "ADMIN" &&
        this.getUserInfo.JOIN_TYPE == "SNS"
      ) {
        return i18n.t("account.37");
      }
      return i18n.t("account.22");
    },
    subTitle() {
      if (
        this.getUserInfo.ROLE == "ADMIN" &&
        this.getUserInfo.JOIN_TYPE == "SNS"
      ) {
        return i18n.t("account.39");
      }
      return i18n.t("account.38");
    }
  },
  methods: {
    ...mapActions("auth", ["updateUserPassword"]),
    // 새 비밀번호 검사
    newPwCheck() {
      const { newPw } = this;
      // 비밀번호
      this.newPw = newPw.trim();
      if (isBlank(this.newPw)) {
        this.errorMsg.newPwError = "";
        return;
      } else if (!passwordValidation(this.newPw)) {
        this.errorMsg.newPwError = i18n.t("account.26");
        return;
      } else {
        this.errorMsg.newPwError = "";
      }

      if (this.checkNewPw) this.chkNewPwCheck();
    },
    // 새 비밀번호 확인 검사
    chkNewPwCheck() {
      // 새 비밀번호 입력을 안했거나, 유효성검사에 실패했을 경우
      if (isBlank(this.newPw) || this.errorMsg.newPwError) {
        return;
      }

      const { checkNewPw } = this;
      this.checkNewPw = checkNewPw.trim();

      if (this.newPw !== this.checkNewPw) {
        this.errorMsg.checkNewPwError = i18n.t("account.31");
        return;
      } else {
        this.errorMsg.checkNewPwError = "";
      }
    },
    // 비밀번호 변경
    async save() {
      const { newPw } = this;
      if (isBlank(newPw)) return;

      await this.updateUserPassword(newPw);
    }
  }
};
</script>
