export const BOX_LIST = [
  {
    key: "home",
    name: "대시보드",
    icon: "home",
    routeName: "approval_home"
  },
  {
    key: "ledger",
    name: "문서대장",
    path: "ledger",
    icon: "ledger",
    routeName: "approval_document_list",
    description:
      "결재 완료된 문서들을 확인할 수 있습니다.<br>접속한 사용자의 부서 또는 권한에 따라 노출되는 문서는 상이합니다."
  },
  {
    key: "draft",
    name: "기안함",
    icon: "draft",
    children: [
      {
        key: "dd",
        name: "상신한",
        icon: "dot",
        path: "draft/draft",
        routeName: "approval_document_list",
        description:
          "내가 상신한 문서중<br>결재가 진행중인 상태의 문서를 확인합니다."
      },
      {
        key: "dc",
        name: "완료된",
        icon: "dot",
        path: "draft/complete",
        routeName: "approval_document_list",
        description:
          "내가 상신한 문서중<br>결재가 완료된 상태의 문서를 확인합니다."
      },
      {
        key: "dt",
        name: "저장된",
        icon: "dot",
        path: "draft/temp",
        routeName: "approval_document_list",
        description: "임시저장한 문서를 확인합니다."
      },
      {
        key: "dr",
        name: "반려된",
        icon: "dot",
        path: "draft/reject",
        routeName: "approval_document_list",
        description:
          "내가 상신한 문서중<br>결재가 반려된 상태의 문서를 확인합니다."
      },
      {
        key: "dn",
        name: "반송된",
        icon: "dot",
        path: "draft/return",
        routeName: "approval_document_list",
        description: "내가 상신한 문서중<br>반송된 상태의 문서를 확인합니다."
      },
      {
        key: "de",
        name: "회수된",
        icon: "dot",
        path: "draft/retrieve",
        routeName: "approval_document_list",
        description: "내가 상신 후 회수한 문서를 확인합니다."
      }
    ]
  },
  {
    key: "approve",
    name: "결재함",
    icon: "approve",
    children: [
      {
        key: "ab",
        name: "결재전",
        icon: "dot",
        path: "approve/before",
        routeName: "approval_document_list",
        description: "내가 결재할 차례의 문서를 확인합니다."
      },
      {
        key: "ap",
        name: "진행중",
        icon: "dot",
        path: "approve/progress",
        routeName: "approval_document_list",
        description: "내가 결재한 문서의 진행상태를 확인합니다."
      },
      {
        key: "ac",
        name: "완료된",
        icon: "dot",
        path: "approve/complete",
        routeName: "approval_document_list",
        description: "내가 결재한 문서중 최종 결재 완료된 문서를 확인합니다."
      },
      {
        key: "ar",
        name: "반려된",
        icon: "dot",
        path: "approve/reject",
        routeName: "approval_document_list",
        description: "내가 결재한 문서중 반려 처리된 문서를 확인합니다."
      }
    ]
  },
  {
    key: "receive",
    name: "수신함",
    icon: "receive",
    children: [
      {
        key: "rb",
        name: "수신전",
        icon: "dot",
        path: "receive/before",
        routeName: "approval_document_list",
        description: "내가 수신할 문서를 확인합니다."
      },
      {
        key: "rc",
        name: "완료된",
        icon: "dot",
        path: "receive/complete",
        routeName: "approval_document_list",
        description: "내가 수신확인한 문서를 확인합니다."
      }
    ]
  },
  {
    key: "sh",
    name: "공람함",
    icon: "share",
    path: "share",
    routeName: "approval_document_list",
    description: "나 또는 소속 부서가 공람자로 지정된 문서를 확인합니다."
  },
  {
    key: "cc",
    name: "참조함",
    icon: "cc",
    path: "cc",
    routeName: "approval_document_list",
    description: "내가 참조자로 지정된 문서를 확인합니다."
  },
  {
    key: "organ",
    name: "부서문서함",
    icon: "organ",
    children: [
      {
        key: "oh",
        name: "부서협조",
        icon: "dot",
        path: "organ/help",
        routeName: "approval_document_list",
        description: "내가 문서담당자인 부서가 협조할 문서를 확인합니다."
      },
      {
        key: "or",
        name: "부서수신",
        icon: "dot",
        path: "organ/receive",
        routeName: "approval_document_list",
        description: "내가 문서담당자인 부서가 수신할 문서를 확인합니다."
      }
    ]
  }
];

// [key]에 해당하는 [box] 반환
export const getBoxByKey = key => {
  const findByKey = (key, list) => {
    for (const item of list) {
      if (item.key === key) return item;
      if (item.children) {
        const subItem = findByKey(key, item.children);
        if (subItem) return subItem;
      }
    }
  };
  return findByKey(key, BOX_LIST);
};
// [key]에 해당하는 [box]의 [parentBox] 반환
export const getParentBoxByKey = key => {
  const findByKey = (key, list) => {
    for (const item of list) {
      if (item.key === key) return item;
      if (item.children) {
        const subItem = findByKey(key, item.children);
        if (subItem) return item;
      }
    }
    return null;
  };
  return findByKey(key, BOX_LIST);
};

export default { BOX_LIST, getBoxByKey, getParentBoxByKey };
