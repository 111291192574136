<template>
  <div class="cr-app-menu-wrapper mb-5">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="cr-btn"
          v-bind="attrs"
          v-on="on"
          :class="[item.selected]"
          @click="routeModule(item.name)"
        >
          <component :is="components[item.name]" />
        </div>
      </template>

      <span>{{ item.title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import storage from "@/commons/api/storage";

export default {
  components: {},
  props: {
    components: {
      type: Object,
      default() {
        return {
          home: () => import("@/commons/views/icons/Home.vue"),
          mail: () => import("@/commons/views/icons/Mail.vue"),
          cal: () => import("@/commons/views/icons/Calendar.vue"),
          contact: () => import("@/commons/views/icons/Contact.vue"),
          drive: () => import("@/commons/views/icons/Drive.vue"),
          board: () => import("@/commons/views/icons/Board.vue"),
          todo: () => import("@/commons/views/icons/Todo.vue"),
          resource: () => import("@/commons/views/icons/Resource.vue"),
          approval: () => import("@/commons/views/icons/Approval.vue"),
          customer: () => import("@/commons/views/icons/Customer.vue")
        };
      }
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  methods: {
    routeModule(name) {
      if (name === "todo") {
        const workspaceId = parseInt(storage.getTodoWorkspaceId());
        if (workspaceId > 0) {
          name = "todo_workspace";
          this.$router.push({ name, params: { workspaceId } });
          return;
        }
      }

      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
/* 앱 아이콘 */
.cr-app-menu-wrapper::v-deep {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  padding: 0;
  margin: 0;
  width: 81px;

  // 버튼
  .cr-btn {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    overflow: hidden;
  }

  // 버튼 아이콘
  .cr-btn {
    &.selected {
      background-color: var(--v-primary-base);
      svg path,
      svg circle {
        fill: #fff;
      }
    }
    svg path,
    svg circle {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  // 마우스 오버
  .cr-btn {
    &:hover {
      background-color: var(--v-accent-base);

      cursor: pointer;
      box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.09),
        0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      svg path,
      svg circle {
        fill: #fff;
      }
    }
  }
}
</style>
