var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-tree-wrapper",class:_vm.$vuetify.breakpoint.lgAndUp ? '' : 'mobile'},[_c('v-treeview',{attrs:{"dense":"","hoverable":"","activatable":"","transition":"","shaped":"","return-object":"","item-text":"fileName","items":_vm.getFolders,"load-children":_vm.getDriveFolderChildren,"active":_vm.activeItems,"open":_vm.openItems},on:{"update:active":[function($event){_vm.activeItems=$event},_vm.onActive],"update:open":function($event){_vm.openItems=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.icon)?_c('v-icon',{attrs:{"color":_vm.getIconColor(item)},domProps:{"textContent":_vm._s(item.icon)}}):_c('v-icon',{class:item.childCount === 0 ? 'ml-3' : '',attrs:{"color":item.folderColor},domProps:{"textContent":_vm._s(
          item.shareFlag
            ? 'mdi-folder-account'
            : open
            ? 'mdi-folder-open'
            : 'mdi-folder'
        )}})]}},{key:"label",fn:function(ref){
        var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('div',{class:_vm.draggableClass(item),staticStyle:{"margin-top":"1px"},attrs:{"data-id":item.id}},[_c('div',{staticClass:"cr-tree_node_click_area",on:{"click":function () { return _vm.nodeClick(item); }}}),(!item.isEdit)?_c('span',{staticClass:"cr-tree-label-text"},[_vm._v(" "+_vm._s(item.fileName)+" ")]):_vm._e(),(item.isEdit)?_c('v-text-field',{attrs:{"autofocus":"","loading":_vm.editTextLoading,"label":item.fileName},on:{"blur":function (e) { return _vm.editFolder(e, item); },"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.editFolder(e, item); }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function (e) { return _vm.editBtnClick(e, item); }).apply(null, arguments)}]}}):_vm._e(),_c('div',[(
                hover &&
                  !item.isEdit &&
                  !_vm.editTextLoading &&
                  (item.fileType == 'DIRECTORY' || item.fileType == 'ROOT')
              )?_c('v-btn',{staticClass:"tree-append-right",class:item.fileType == 'ROOT' ? 'root-context' : '',attrs:{"icon":"","small":"","plain":""},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.openPositioningMenu(e, item); }).apply(null, arguments)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-dots-vertical')}})],1):_vm._e(),(item.fileType === 'ROOT')?_c('v-btn',{staticClass:"tree-append-right",attrs:{"icon":"","small":""},on:{"click":function (e) { return _vm.openDialog(e, item); }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(item.fileType === 'DELETED')?_c('v-btn',{staticClass:"tree-append-right cr-tutorial-drive-trash",attrs:{"icon":"","small":""},on:{"click":function (e) { return _vm.cleanBtnClick(e, item); }}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e(),(item.fileType === 'GOOGLE')?_c('v-btn',{staticClass:"tree-append-right cr-tutorial-drive-connect",attrs:{"icon":"","small":"","disabled":!_vm.haveTokenByExternalType('google'),"title":_vm.$t('drive.47')},on:{"click":function (e) { return _vm.disconnectBtnClick(e, item); }}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-connection")])],1):_vm._e()],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }