<template>
  <div
    class="cr-tree-wrapper"
    :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
  >
    <v-treeview
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      item-text="nameFolder"
      draggable="true"
      :items="getFolders"
      :active.sync="activeItems"
      :open.sync="openFolders"
      @update:active="onActive"
      v-drag-and-drop="{
        DRAG_COMP: 'tree', // directive를 사용하는 컴포넌트 타이틀
        genHelper, // helper 생성 함수 / Argument(event, setDragInfo)) / return element
        genDropzone, // 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
        markDropzone, // 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
        mouseup // drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
      }"
    >
      <!-- 아이콘 -->
      <template v-slot:prepend="{ item, open, active }">
        <FolderIcon :item="item" :open="open" :active="active" />
      </template>

      <!-- 폴더 이름 / 버튼 / 뱃지 -->
      <template v-slot:label="{ item }">
        <TreeItem :item="item" />
      </template>
    </v-treeview>
  </div>
</template>

<style lang="scss" scoped>
.cr-tree-wrapper {
  overflow-y: auto;
  position: absolute;
  top: 52px;
  bottom: 124px;
  left: 0px;
  right: 8px;

  &.mobile {
    top: 60px;
    bottom: 60px;
  }

  .v-treeview ::v-deep {
    /* 트리 노드 */
    > .v-treeview-node.v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          cursor: pointer;
          margin-left: 0px;
        }
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }
      }

      /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
      .v-treeview-node__children {
        overflow: hidden;
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }

    /* 텍스트 */
    .cr-tree-label-text {
      font-size: 14px;
    }
    .v-treeview-node--active .cr-tree-label-text {
      font-weight: bold;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { rootFolderType } from "@/mail/constant/folderType";
import FolderIcon from "./FolderIcon.vue";
import TreeItem from "./TreeItem.vue";

export default {
  components: { FolderIcon, TreeItem },
  mounted() {
    if (!this.currentFolder) return;
    const { id, folderType } = this.currentFolder;

    // 현재 선택된 폴더가 하위 폴더라면 상위폴더 오픈
    let routeOpen = [];
    if (id > 0 || folderType == "TAG") routeOpen = this.getFullPath(id);
    // 펼쳐보기 - 0: 내메일함, 1: 검색폴더, 2: 태그
    this.getFolderExpand.forEach(num => {
      switch (num) {
        case 0:
          routeOpen = [{ id: this.getPrivateRootId }, ...routeOpen];
          break;
        case 1:
          routeOpen = [this.getSearchRoot, ...routeOpen];
          break;
        case 2:
          routeOpen = [...this.getTagRoot, ...routeOpen];
          break;
        default:
          break;
      }
    });
    this.openFolders = routeOpen;

    // root폴더일 경우
    if (rootFolderType[folderType]) return;
    // url 접속시 메일함 active
    this.activeItems = [{ id }];
  },
  data() {
    return {
      activeItems: [],
      prevActiveItem: {}
    };
  },
  computed: {
    ...mapGetters("mail", ["getSelectedMails"]),
    ...mapGetters("dragDrop", ["dragComp"]),
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailConfig", ["getFolderExpand"]),
    ...mapGetters("folder", [
      "currentFolder",
      "getFolders",
      "getFullPath",
      "getPrivateRootId",
      "getSearchRoot",
      "getTagRoot",
      "getOpenFolders",
      "getSentId"
    ]),
    openFolders: {
      get() {
        return this.getOpenFolders;
      },
      set(openFolders) {
        this.SET_OPEN_FOLDERS(openFolders);
      }
    }
  },
  watch: {
    // route에 의해 폴더선택이 변경될때 // url hash만 변경시
    getRouteListInfo({ folderId: routeFolderId }) {
      // 폴더 id 정보가 없을때
      if (routeFolderId == null || routeFolderId == undefined) return;
      // 해당 메일함이 없을때
      if (!this.currentFolder && typeof routeFolderId == "number") return;

      const { id, folderType } = this.currentFolder;
      // 해당하는 메일함이 root폴더일 경우
      if (rootFolderType[folderType]) return;

      // 현재 선택된 폴더가 하위 폴더라면 상위폴더 오픈
      let routeOpen = [];
      if (id > 0 || folderType == "TAG") {
        routeOpen = this.getFullPath(id)?.filter(f => f.id != id);
      }

      this.openFolders = [...this.openFolders, ...routeOpen];
      this.activeItems = [{ id }];
    },
    // 설정 부분 펼쳐보기
    getFolderExpand(folderExpand) {
      let routeOpen = [...this.openFolders];
      // 펼쳐보기 - 0: 내메일함, 1: 검색폴더, 2: 태그
      [0, 1, 2].forEach(num => {
        const index = folderExpand.indexOf(num);
        let id = this.getPrivateRootId;
        if (num === 1) id = this.getSearchRoot?.id;
        if (num === 2) id = (this.getTagRoot ?? [])[0]?.id;

        if (index === -1) {
          // 펼쳐보기 설정 해제했을때 열려있다면 닫는다.
          const idx = routeOpen.findIndex(r => r.id === id);
          if (idx !== -1) routeOpen.splice(idx, 1);
        } else {
          routeOpen = [{ id }, ...routeOpen];
        }
      });

      this.openFolders = routeOpen;
    }
  },
  methods: {
    ...mapMutations("folder", ["SET_OPEN_FOLDERS"]),
    ...mapActions("folder", ["moveFolder"]),
    // 트리노트 선택
    onActive([n = {}]) {
      const { id, folderType } = n;

      // 이미 선택된 메일함을 또 선택했을때 active해제방지
      if (!id) return (this.activeItems = [this.prevActiveItem]);
      // 선택된 메일함이 root폴더일경우 active해제 및 open/close
      if (rootFolderType[folderType]) {
        const { length } = this.openFolders.filter(i => i.id == id);
        if (length == 0) {
          this.openFolders = [...this.openFolders, n];
        } else {
          this.openFolders = this.openFolders.filter(i => i.id !== id);
        }

        // 메일함이 선택된 상태에서 root폴더함을 눌렀을때 기존 선택된 메일함 active해제방지
        return (this.activeItems = [this.prevActiveItem]);
      }

      // 메일쓰기화면에서 트리 선택시 mailRoute vuex와 정보다르면 route정보에 맞춘다.
      if (this.getRouteListInfo.folderId != id) {
        return (this.activeItems = [this.getRouteListInfo.folderId]);
      }

      this.prevActiveItem = n;
    },
    // ** drag&drop **
    // 헬퍼 생성 함수
    genHelper(e, setDragInfo) {
      const target = e.target
        .closest(".v-treeview-node__root")
        ?.cloneNode(true);
      const draggableEl = target?.querySelector(".cr-draggable-tree");
      if (!draggableEl) return;
      setDragInfo({ id: draggableEl.getAttribute("data-id") });

      const labelDiv = target.querySelector(".v-treeview-node__label > div");
      const rightDiv = labelDiv.querySelector("button");
      const dropzone = labelDiv.querySelector(".cr-drop-tree");
      const toggleBtn = target.querySelector(".v-treeview-node__toggle");

      if (rightDiv) labelDiv.removeChild(rightDiv);
      if (toggleBtn) target.removeChild(toggleBtn);
      if (dropzone) labelDiv.removeChild(dropzone);

      Object.assign(target.style, {
        paddingRight: "20px",
        paddingTop: "3px"
      });
      target.classList.add("dnd-ghost");
      return target;
    },
    // 드랍가능영역 생성함수
    genDropzone() {
      // document.getElementsByClassName은 배열이 아니라 HtmlCollection을 리턴함
      // 아래 라인은 이를 배열로 인식하게 함.
      HTMLCollection.prototype.forEach = Array.prototype.forEach;
      if (this.dragComp == "tree") {
        document.getElementsByClassName("cr-dropzone-tree").forEach(el => {
          const { length } = el.getElementsByClassName("cr-drop-tree");
          if (length > 0) return;

          const dropEl = document.createElement("div");
          Object.assign(dropEl.style, {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
          });
          dropEl.id = el.getAttribute("data-id");
          dropEl.classList.add("cr-drop-tree");
          el.appendChild(dropEl);

          dropEl.addEventListener("mouseleave", async function(e) {
            e.target.style.border = null;
            e.target.style.background = null;
          });
        });
      }

      // 폴더가 새로열렸을때 생성위해서
      if (this.dragComp == "list") {
        const { length: sentMailCnt } = this.getSelectedMails.filter(
          m =>
            m.mailType == "SENT" ||
            m.mailType == "RESERVE" ||
            m.mailType == "RESERVE_CANCEL"
        );

        document.getElementsByClassName("cr-dropzone-list").forEach(el => {
          const { length } = el.getElementsByClassName("cr-drop-list");
          if (length > 0) return;

          const folderId = el.getAttribute("data-id");
          if (sentMailCnt == 0 && folderId == this.getSentId) return;

          const dropEl = document.createElement("div");
          Object.assign(dropEl.style, {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
          });
          dropEl.id = el.getAttribute("data-id");
          dropEl.classList.add("cr-drop-list");
          el.appendChild(dropEl);

          dropEl.addEventListener("mouseleave", async function(e) {
            e.target.style.border = null;
            e.target.style.background = null;
          });
        });
      }
    },
    markDropzone(e, dragInfo) {
      if (
        e.target.classList.contains("cr-drop-tree") &&
        e.target.id !== dragInfo.id
      ) {
        const { height } = e.target.getBoundingClientRect();
        const borderStyle = "3px solid #9E9E9E";
        const backgroundStyle = "rgba(1, 1, 1, 0.1)";

        if (e.offsetY <= 12) {
          e.target.style.border = null;
          e.target.style.borderTop = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "before");
        } else if (e.offsetY > height - 12) {
          e.target.style.border = null;
          e.target.style.borderBottom = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "next");
        } else {
          e.target.style.borderTop = borderStyle;
          e.target.style.borderBottom = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "inner");
        }
      }

      if (
        e.target.classList.contains("v-treeview-node__toggle") &&
        !e.target.classList.contains("v-treeview-node__toggle--open")
      ) {
        e.target.click();
      }
    },
    mouseup(e, dragInfo) {
      const id = parseInt(dragInfo?.id);
      const targetFolderId = parseInt(e.target.id);
      const position = e.target.getAttribute("data-position");
      if (id && targetFolderId && position && id !== targetFolderId) {
        this.moveFolder({ id, targetFolderId, position });
      }

      // 드랍가능영역 제거
      const dropList = document.getElementsByClassName("cr-drop-tree");
      while (dropList.length > 0) {
        dropList[0].parentNode.removeChild(dropList[0]);
      }
    }
  }
};
</script>
