export default [
  {
    path: "/account",
    redirect: "/account/info"
  },
  {
    path: "/account/:tabType",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountMain.vue")
  }
];
