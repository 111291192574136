var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"text-center cr-tutorial-appmenu"},[_c('v-menu',{attrs:{"absolute":"","content-class":"apps-menu","position-y":_vm.$vuetify.breakpoint.xs ? 56 : 64},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.xs ? "" : _vm.$t("common.앱"))+" ")])]}}])},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"cr-app-menu-wrapper"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"cr-btn-wrapper"},[_c('div',_vm._g(_vm._b({staticClass:"cr-btn",class:[item.name, item.selected],on:{"click":function($event){return _vm.routeModule(item.name)}}},'div',attrs,false),on),[_c(_vm.components[item.name],{tag:"component",attrs:{"size":_vm.components[item.size]}})],1),_c('div',{staticClass:"cr-btn-title mt-2"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1)}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }