import { getServiceConfig } from "@/commons/api/serviceConfig.api";

const state = {
  mailConfig: {
    largeFileMaxDuration: 0,
    largeFileMaxSize: 0,
    largeFileSizeUnit: "GB",
    isLargeAttPw: 0,
    pop3: 0,
    imap: 0
  },
  contactConfig: {},
  calendarConfig: {},
  driveConfig: {
    useGoogleDrive: 0
  },
  boardConfig: {
    deletedPostMaxDuration: 7
  },
  todoConfig: {},
  approvalConfig: {
    prevRejectUse: false,
    upperLineChangeUse: false
  }
};

const getters = {
  getMailServiceConfig: ({ mailConfig }) => mailConfig,
  getContactServiceConfig: ({ contactConfig }) => contactConfig,
  getCalendarServiceConfig: ({ calendarConfig }) => calendarConfig,
  getDriveServiceConfig: ({ driveConfig }) => {
    return {
      useGoogleDrive: !!driveConfig.useGoogleDrive
    };
  },
  getBoardServiceConfig: ({ boardConfig }) => boardConfig,
  getTodoServiceConfig: ({ todoConfig }) => todoConfig,
  getApprovalServiceConfig: ({ approvalConfig }) => approvalConfig
};

const mutations = {
  SET_CONFIG: (state, { moduleName, config }) => {
    switch (moduleName) {
      case "MAIL":
        state.mailConfig = config;
        break;
      case "CONTACT":
        state.contactConfig = config;
        break;
      case "CALENDAR":
        state.calendarConfig = config;
        break;
      case "DRIVE":
        state.driveConfig = config;
        break;
      case "BOARD":
        state.boardConfig = config;
        break;
      case "TODO":
        state.todoConfig = config;
        break;
      case "APPROVAL":
        state.approvalConfig = config;
        break;
      default:
        break;
    }
  }
};

const actions = {
  async loadConfig({ commit }, moduleName) {
    const { data, status = 401 } = await getServiceConfig(moduleName);
    let isError = status != 200 ? true : !data.result;
    if (isError) return;

    commit("SET_CONFIG", { moduleName, config: data.config });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
