import http from "@/commons/api/http";
const prefix = "api/todo/kanban";

const getKanbanColumns = boardId => http.get(`${prefix}/columns/${boardId}`);

const updateColumns = (boardId, columns) => {
  const formData = new FormData();
  formData.append("boardId", boardId);
  formData.append("columns", columns);
  return http.put(`${prefix}/columns`, formData);
};

export { getKanbanColumns, updateColumns };
