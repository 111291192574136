const convertGoogleFileIconByMimeType = mimeType => {
  if (!mimeType) return "file";

  let fileType;
  switch (mimeType) {
    case "application/vnd.google-apps.folder":
      fileType = "folder";
      break;
    case "application/vnd.google-apps.spreadsheet":
      fileType = "sheet";
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      fileType = "xlsx";
      break;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      fileType = "pptx";
      break;
    case "application/vnd.google-apps.presentation":
      fileType = "presentation";
      break;
    case "application/vnd.google-apps.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      fileType = "docx";
      break;
    case "application/vnd.google-apps.form":
      fileType = "form";
      break;
    case "image/png":
      fileType = "png";
      break;
    case "application/pdf":
      fileType = "pdf";
      break;
    case "application/zip":
      fileType = "zip";
      break;
    case "video/mp4":
      fileType = "video";
      break;
    case "text/plain":
      fileType = "txt";
      break;
    default:
      fileType = "file";
      break;
  }
  return fileType;
};

const convertGoogleFileExportKeyByMimeType = mimeType => {
  let key;
  switch (mimeType) {
    case "application/vnd.google-apps.spreadsheet":
      key = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    case "application/vnd.google-apps.presentation":
      key =
        "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      break;
    case "application/vnd.google-apps.document":
      key =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    default:
      key = undefined;
      break;
  }

  return key;
};

export {
  convertGoogleFileIconByMimeType,
  convertGoogleFileExportKeyByMimeType
};
