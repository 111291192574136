<template>
  <v-tooltip bottom v-if="!$vuetify.breakpoint.xs && showSettingMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="cr-tutorial-settings"
        icon
        v-bind="attrs"
        v-on="on"
        @click="click"
      >
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>
    <div v-text="tooltip" />
  </v-tooltip>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    route: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getPlanType", "getCompanyInfo"]),
    ...mapGetters("mailLayout", { showMailSetting: "showConfigDrawer" }),
    ...mapGetters("calLayout", { showCalSetting: "showLeaveDialog" }),
    ...mapGetters("contactLayout", { showContactSetting: "getShowSetting" }),
    tooltip() {
      switch (this.route) {
        case "mail":
        case "contact":
        case "board":
        case "approval":
          return this.$t("common.설정");
        case "cal":
          return this.$t("calendar.연차관리");
        default:
          return "";
      }
    },
    showSettingMenu() {
      switch (this.route) {
        case "cal":
          if (this.getUserInfo?.isHr && this.getPlanType === "ADVANCED") {
            return true;
          }
          if (
            this.getUserInfo?.isHr &&
            this.getPlanType === "CUSTOM" &&
            this.getCompanyInfo?.customizeUI?.flagHR === 1
          ) {
            return true;
          }
          return false;
        case "mail":
        case "contact":
        case "board":
        case "approval":
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    ...mapMutations("mailLayout", {
      setShowMailSetting: "SET_SHOW_CONFIG_DRAWER"
    }),
    ...mapMutations("calLayout", {
      setShowCalSetting: "SET_SHOW_LEAVE_DIALOG"
    }),
    ...mapMutations("contactLayout", {
      setShowContactSetting: "SET_SHOW_SETTING"
    }),
    click() {
      switch (this.route) {
        case "mail":
          this.setShowMailSetting(!this.showMailSetting);
          break;
        case "cal":
          this.setShowCalSetting(!this.showCalSetting);
          break;
        case "contact":
          this.setShowContactSetting(!this.showContactSetting);
          break;
        case "board":
          this.$router.push({
            name: "board_config",
            params: { tabType: "manage" }
          });
          break;
        case "approval":
          this.$router.push({
            name: "approval_config"
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>
