import http from "@/commons/api/http";

const prefix = "api/calendar";

// 캘린더리스트 조회
const getCalendars = () => http.get(prefix + "/find");

// 캘린더 추가
const addCalendar = params => http.post(prefix + "/add", params);

// 캘린더 수정
const updateCalendar = params => {
  return http.put(prefix + "/update", params);
};

const updateTreeStatus = params =>
  http.patch(`${prefix}/updateTreeStatus`, params);

// 캘린더 삭제
const deleteCalendar = ({ id, title }) => {
  const formData = new FormData();
  formData.append("title", title);

  return http.delete(`${prefix}/delete/${id}`, { data: formData });
};

// 이벤트 리스트 조회
// TODO 임시 api. 향후 변경 필요 (prefix + "/event/find")
const getEvents = params => http.post(prefix + "/event/find", params);

// 이벤트 생성
const saveEvent = params => http.post(prefix + "/event", params);

// 이벤트 수정
const updateEvent = params => http.patch(prefix + "/event", params);

// 이벤트 삭제
const deleteEvent = queryString =>
  http.delete(`${prefix}/event?${queryString}`);

// 날짜 기준으로 이벤트 삭제
const deleteEventByDate = params =>
  http.delete(`${prefix}/event/deleteByDate`, { data: params });

// 그룹 일정 주최자인 경우 참석 상태 설정
const updatePartStat = params => http.patch(prefix + "/event/partStat", params);

// 이벤트 상세 조회
const getEventDetail = id => http.get(`${prefix}/event/view/${id}`);

// 검색엔진으로 키워드 이벤트 조회
const searchEventList = params => {
  return http.get(prefix + "/event/search", { params });
};

// 유휴시간 조회
const searchFreebusy = params =>
  http.get(prefix + "/event/freeBusy", { params: params });

// 공휴일 캘린더 목록 조회
const searchHolidayCalendars = () => http.get(prefix + "/holiday");

// 퍼블릭 캘린더 리스트 조회
const searchPublicCalendarList = params =>
  http.get(prefix + "/find/public", { params: params });

// 퍼블릭 캘린더 구독
const subscriptCalendar = params => http.post(prefix + "/subscribe", params);

// 퍼블릭 캘린더 구독 취소
const cancelPublicSubscription = calendarId =>
  http.delete(prefix + `/cancelSubscription/${calendarId}`);

// 캘린더 응답 메일 전송 (reply)
const sendCalReplyMail = params => http.put(prefix + "/reply", params);

// 캘린더 응답 메일 전송 (counter)
const sendCalCounterReplyMail = params =>
  http.put(prefix + "/counterReply", params);

// 캘린더 설정 조회
const getCalendarConfig = () => http.get(prefix + "/find/config");

const subscribeOrganCalendar = params =>
  http.put(`${prefix}/organCalendar/`, params);
export {
  addCalendar,
  getCalendars,
  getEvents,
  getEventDetail,
  deleteCalendar,
  updateCalendar,
  deleteEvent,
  saveEvent,
  updateEvent,
  searchEventList,
  searchFreebusy,
  searchHolidayCalendars,
  searchPublicCalendarList,
  subscriptCalendar,
  cancelPublicSubscription,
  deleteEventByDate,
  updatePartStat,
  updateTreeStatus,
  sendCalReplyMail,
  sendCalCounterReplyMail,
  subscribeOrganCalendar,
  getCalendarConfig
};
