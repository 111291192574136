<template>
  <div>
    <FabBtn />
    <v-hover v-slot="{ hover }">
      <div
        :style="{ opacity: opacity * 0.1 }"
        v-show="!minimize"
        ref="div"
        :class="
          `cr-window-wrapper ${maximize ? 'cr-window-maxmize' : ''} ${
            hover && isMailDrag ? 'cr-messenger-dropzone' : ''
          }`
        "
      >
        <v-card ref="card" elevation="5" :height="height" :width="width">
          <Systembar
            :dragStart.sync="dragStart"
            @closeWindow="closeWindow"
            @mousedown="mousedown"
          />
          <div class="cr-window-content-wrapper cr-messenger">
            <Iframe ref="iframe" />
            <div
              v-if="dragStart || dragMode"
              class="cr-resize-guard d-flex align-center justify-center"
            >
              <!--
              <v-icon v-show="hover && isMailDrag" size="200" color="#fff">
                mdi-share-variant
              </v-icon>
              -->
            </div>
          </div>
        </v-card>
        <div
          v-for="re in resizeEls"
          :key="re"
          :class="`cr-${re}-resize`"
          @mousedown.stop="e => mousedown(e, re)"
        />
      </div>
    </v-hover>
  </div>
</template>

<style lang="scss" scoped>
/* 윈도우 */
.cr-window-wrapper {
  position: absolute;
  z-index: 100;
  top: 0;
  border: 4px solid transparent;
  &.cr-window-maxmize div[class$="resize"] {
    cursor: default !important;
  }

  .cr-window-content-wrapper {
    position: relative;
    width: 100%;
    height: calc(100% - 32px);
    padding: 0px;
    overflow: auto;
    .cr-resize-guard {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }

  &.cr-messenger-dropzone {
    border-color: var(--v-primary-base);
    .cr-window-content-wrapper .cr-resize-guard {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .cr-n-resize {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 4px;
    cursor: n-resize;
  }
  .cr-s-resize {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 4px;
    cursor: s-resize;
  }
  .cr-w-resize {
    position: absolute;
    top: 0px;
    width: 4px;
    height: 100%;
    cursor: w-resize;
  }
  .cr-e-resize {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 4px;
    cursor: e-resize;
  }
  .cr-nw-resize {
    position: absolute;
    top: -4px;
    left: -4px;
    height: 14px;
    width: 14px;
    cursor: nw-resize;
  }
  .cr-sw-resize {
    position: absolute;
    bottom: -4px;
    left: -4px;
    height: 14px;
    width: 14px;
    cursor: sw-resize;
  }
  .cr-ne-resize {
    position: absolute;
    top: -4px;
    right: -4px;
    height: 14px;
    width: 14px;
    cursor: ne-resize;
  }
  .cr-se-resize {
    position: absolute;
    bottom: -4px;
    right: -4px;
    height: 14px;
    width: 14px;
    cursor: se-resize;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import storage from "@/commons/api/storage";
import FabBtn from "./FabBtn.vue";
import Systembar from "./Systembar.vue";
import Iframe from "./Iframe.vue";

export default {
  components: { FabBtn, Systembar, Iframe },
  mounted() {
    document.addEventListener("mousemove", this.mousemove);
    document.addEventListener("mouseup", this.mouseup);

    this.positionInit();
  },
  destroyed() {
    document.removeEventListener("mousemove", this.mousemove);
    document.removeEventListener("mouseup", this.mouseup);
  },
  data() {
    let style = {};
    try {
      style = JSON.parse(storage.getMessengerStyle());
    } catch (error) {
      style = {};
    }

    return {
      dragStart: false,
      direction: "",
      width: style?.width || 504,
      height: style?.height || 700,
      startWidth: style?.width || 504,
      startHeight: style?.height || 700,
      top: 0,
      left: 0,
      pageX: 0,
      pageY: 0,

      prevWidth: 0,
      prevHeight: 0,
      prevTop: 0,
      prevLeft: 0,

      bodyHeight: 0,
      bodyWidth: 0,
      resizeEls: ["n", "s", "w", "e", "nw", "sw", "ne", "se"]
    };
  },
  computed: {
    ...mapGetters("dragDrop", ["dragComp", "dragMode"]),
    ...mapGetters("messenger", ["minimize", "maximize", "opacity"]),
    ...mapGetters("mail", ["getSelectedMails"]),
    isMailDrag() {
      return (
        this.dragMode &&
        this.dragComp == "list" &&
        this.getSelectedMails.length === 1
      );
    }
  },
  watch: {
    dragStart(dragStart) {
      // tinymce 에디터 존재시 가림막
      const editorIfr = document.getElementById("tiny_ifr");
      if (!editorIfr) return;

      if (dragStart) {
        const div = document.createElement("div");
        div.id = "editorGuard";
        div.style.position = "absolute";
        div.style.top = 0;
        div.style.left = 0;
        div.style.right = 0;
        div.style.bottom = 0;
        editorIfr.parentNode.appendChild(div);
        return;
      }

      const editorGuard = document.getElementById("editorGuard");
      if (editorGuard && !dragStart) {
        editorIfr.parentNode.removeChild(editorGuard);
      }
    },
    minimize(v) {
      if (v) return;
      const {
        clientWidth: bodyWidth,
        clientHeight: bodyHeight
      } = document.body;
      // 브라우저 크기 변경시 포지션 초기화
      if (this.bodyHeight != bodyHeight || this.bodyWidth != bodyWidth) {
        this.positionInit();
      }

      this.$refs.div.style.top = `${this.top}px`;
      this.$refs.div.style.left = `${this.left}px`;
    },
    maximize(v) {
      if (!v) {
        this.width = this.prevWidth;
        this.height = this.prevHeight;
        this.$refs.div.style.top = this.prevTop;
        this.$refs.div.style.left = this.prevLeft;
        return;
      }

      const {
        clientWidth: bodyWidth,
        clientHeight: bodyHeight
      } = document.body;
      const {
        clientWidth: parentWidth,
        clientHeight: parentHeight
      } = this.$parent.$el;
      const naviWidth = bodyWidth - parentWidth;
      const appbarHeight = bodyHeight - parentHeight;

      // 이전 값 기록
      this.prevWidth = this.width;
      this.prevHeight = this.height;
      this.prevTop = this.$refs.div.style.top;
      this.prevLeft = this.$refs.div.style.left;

      this.width = bodyWidth;
      this.height = bodyHeight;
      this.$refs.div.style.top = `${-appbarHeight}px`;
      this.$refs.div.style.left = `${-naviWidth}px`;
    }
  },
  methods: {
    ...mapMutations("messenger", ["SET_MINIMIZE"]),
    closeWindow() {
      const { contentWindow } = this.$refs.iframe.$refs.iframe;
      if (!contentWindow) return;

      contentWindow.postMessage({ path: "home", externalCommand: "go" }, "*");
      this.SET_MINIMIZE(true);
    },
    // 초기 윈도우 크기,위치 설정
    positionInit() {
      const {
        clientWidth: bodyWidth,
        clientHeight: bodyHeight
      } = document.body;
      this.bodyHeight = bodyHeight;
      this.bodyWidth = bodyWidth;

      // 오른쪽 여유공간
      const paddingRight = 180;
      // 밑에 여유공간
      const paddingBottom = 40;

      let top = 0;
      if (bodyHeight > this.startHeight + paddingBottom) {
        top = bodyHeight - this.startHeight - paddingBottom;
      }

      let left = 0;
      if (bodyWidth > this.startWidth + paddingRight) {
        left = bodyWidth - this.startWidth - paddingRight;
      }

      this.top = top;
      this.left = left;
    },
    mousedown(e, direction) {
      if (this.maximize) {
        return;
      }

      this.dragStart = true;
      this.direction = direction;
      this.pageX = e.pageX;
      this.pageY = e.pageY;
      this.startWidth = this.width;
      this.startHeight = this.height;
      this.top = parseInt(this.$refs.div.style.top.replace("px", "")) || 0;
      this.left = parseInt(this.$refs.div.style.left.replace("px", "")) || 0;
    },
    mousemove(e) {
      if (!this.dragStart || !this.direction || this.maximize) return;

      const {
        clientWidth: bodyWidth,
        clientHeight: bodyHeight
      } = document.body;
      const {
        clientWidth: parentWidth,
        clientHeight: parentHeight
      } = this.$parent.$el;
      const minWidth = 504;
      const minHeight = 600;
      const naviWidth = bodyWidth - parentWidth;
      const appbarHeight = bodyHeight - parentHeight;
      const pageX = e.pageX - this.pageX;
      const pageY = e.pageY - this.pageY;
      switch (this.direction) {
        case "move": {
          const limitLeft = -(this.width - 150);
          let top = this.top + pageY;
          let left = this.left + pageX;

          // 왼쪽 제한
          if (left + naviWidth < limitLeft) left = -(naviWidth - limitLeft);
          // 오른쪽 제한
          if (left - parentWidth > -50) left = parentWidth - 50;
          // 위 제한
          if (top + appbarHeight < 0) top = -appbarHeight;
          // 아래 제한
          if (top - parentHeight > -50) top = parentHeight - 50;

          this.$refs.div.style.top = `${top}px`;
          this.$refs.div.style.left = `${left}px`;
          break;
        }
        case "n": {
          let top = this.top + pageY;
          let height = this.startHeight - pageY;
          // 위 제한 / 아래로 제한 (일부분이 브라우저 아래로 내려가 있을때)
          if (top + appbarHeight < 0 || top - parentHeight > -50) {
            return;
          }

          // 높이 제한
          if (height <= minHeight) {
            this.height = minHeight;
            return;
          }
          if (height >= bodyHeight) {
            this.height = bodyHeight;
            return;
          }

          this.$refs.div.style.top = `${top}px`;
          this.height = height;
          break;
        }
        case "s": {
          let height = this.startHeight + pageY;

          // 높이 제한
          if (height <= minHeight) height = minHeight;
          if (height >= bodyHeight) height = bodyHeight;
          this.height = height;
          break;
        }
        case "w": {
          let left = this.left + pageX;
          const width = this.startWidth - pageX;
          // 왼쪽 제한 (브라우저 밖으로 안나가게)
          // if (left + naviWidth < 0) { return; }
          // 오른쪽 제한(일부분이 오른쪽 브라우저 밖으로 나가있을때)
          if (left - parentWidth > -50) {
            return;
          }
          // 너비 제한
          if (width <= minWidth) {
            this.width = minWidth;
            return;
          }
          if (width >= bodyWidth) {
            this.width = bodyWidth;
            return;
          }

          this.$refs.div.style.left = `${left}px`;
          this.width = width;
          break;
        }
        case "e": {
          let width = this.startWidth + pageX;
          // 왼쪽 제한
          if (this.left + width <= -150) return;
          // 너비 제한
          if (width <= minWidth) width = minWidth;
          if (width >= bodyWidth) width = bodyWidth;
          this.width = width;
          break;
        }
        case "nw": {
          let top = this.top + pageY;
          let height = this.startHeight - pageY;
          // 위 / 길이 제한
          if (
            top + appbarHeight > 0 &&
            top - parentHeight < -50 &&
            height >= minHeight &&
            height <= bodyHeight
          ) {
            this.$refs.div.style.top = `${top}px`;
            this.height = height;
          }

          let left = this.left + pageX;
          const width = this.startWidth - pageX;
          // 오른쪽 제한(일부분이 오른쪽 브라우저 밖으로 나가있을때)
          if (left - parentWidth > -50) {
            return;
          }
          // 너비 제한
          if (width <= minWidth) {
            this.width = minWidth;
            return;
          }
          if (width >= bodyWidth) {
            this.width = bodyWidth;
            return;
          }

          this.$refs.div.style.left = `${left}px`;
          this.width = width;
          break;
        }
        case "sw": {
          let height = this.startHeight + pageY;

          // 높이 제한
          if (height <= minHeight) height = minHeight;
          if (height >= bodyHeight) height = bodyHeight;
          this.height = height;

          let left = this.left + pageX;
          const width = this.startWidth - pageX;
          // 왼쪽 제한 (브라우저 밖으로 안나가게)
          // if (left + naviWidth < 0) { return; }
          // 오른쪽 제한(일부분이 오른쪽 브라우저 밖으로 나가있을때)
          if (left - parentWidth > -50) {
            return;
          }
          // 너비 제한
          if (width <= minWidth) {
            this.width = minWidth;
            return;
          }
          if (width >= bodyWidth) {
            this.width = bodyWidth;
            return;
          }

          this.$refs.div.style.left = `${left}px`;
          this.width = width;
          break;
        }
        case "ne": {
          let top = this.top + pageY;
          let height = this.startHeight - pageY;
          // 위 / 길이 제한
          if (
            top + appbarHeight > 0 &&
            top - parentHeight < -50 &&
            height >= minHeight &&
            height <= bodyHeight
          ) {
            this.$refs.div.style.top = `${top}px`;
            this.height = height;
          }

          let width = this.startWidth + pageX;
          // 왼쪽 제한
          if (this.left + width <= -150) return;
          // 너비 제한
          if (width <= minWidth) width = minWidth;
          if (width >= bodyWidth) width = bodyWidth;
          this.width = width;
          break;
        }
        case "se": {
          let height = this.startHeight + pageY;

          // 높이 제한
          if (height <= minHeight) height = minHeight;
          if (height >= bodyHeight) height = bodyHeight;
          this.height = height;

          let width = this.startWidth + pageX;
          // 왼쪽 제한
          if (this.left + width <= -150) return;
          // 너비 제한
          if (width <= minWidth) width = minWidth;
          if (width >= bodyWidth) width = bodyWidth;
          this.width = width;
          break;
        }

        default:
          break;
      }
    },
    mouseup() {
      if (!this.dragStart) return;

      this.dragStart = false;
      this.direction = "";
      this.top = parseInt(this.$refs.div.style.top.replace("px", "")) || 0;
      this.left = parseInt(this.$refs.div.style.left.replace("px", "")) || 0;
      storage.setMessengerStyle(
        JSON.stringify({
          width: this.width,
          height: this.height,
          top: this.top,
          left: this.left
        })
      );
    }
  }
};
</script>
