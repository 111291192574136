<template>
  <div style="display: flex; min-width: 0px;">
    <span class="text-truncate">{{ item.name }}</span>
    <span class="mr-2 cr-extension">.{{ item.ext }}</span>
  </div>
</template>

<style lang="scss" scoped>
.cr-extension {
  flex-shrink: 0;
}
</style>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
