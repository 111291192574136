<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <!-- 버튼 -->
    <div class="pa-2 d-flex" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
      <v-btn
        large
        depressed
        width="118"
        class="mr-1 primary drawer-menu-button"
        @click="routeMailWrite({ t: 0 })"
      >
        <h4 v-html="$t('mail.메일쓰기')"></h4>
      </v-btn>
      <v-btn
        large
        depressed
        width="118"
        class="v-btn-light drawer-menu-button"
        @click="routeMailWrite({ t: 6 })"
      >
        <h4 v-html="$t('mail.내게쓰기')"></h4>
      </v-btn>
    </div>
    <!-- 메일함 트리 -->
    <TreeView :class="$vuetify.breakpoint.lgAndUp ? 'cr-lg-and-up' : ''" />

    <!-- 사용자 용량 정보-->
    <UserQuotaBar />
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TreeView from "@/mail/views/components/treeview";
import UserQuotaBar from "@/commons/views/UserQuotaBar";

export default {
  components: { TreeView, UserQuotaBar },
  mounted() {
    // 메일 split 화면 가로길이 계산을 위함
    this.SET_NAVI_INFO({ show: true, width: this.$el.clientWidth });
  },
  data() {
    return {
      drawer: true
    };
  },
  computed: {
    ...mapGetters("mailLayout", ["naviInfo"])
  },
  watch: {
    drawer(show) {
      this.SET_NAVI_INFO({ show, width: this.naviInfo.naviWidth });
    }
  },
  methods: {
    ...mapMutations("mailLayout", ["SET_NAVI_INFO"]),
    ...mapActions("mailRoute", ["routeMailWrite"])
  }
};
</script>

<style scoped lang="scss">
.drawer-menu-button ::v-deep .v-btn__content {
  max-width: 99%;
  white-space: normal;
}
</style>
