const state = {
  show: false,
  // 중복 클릭 방지를 위해 isConfirmed 사용
  isConfirmed: false,
  type: "",
  headline: "",
  params: {}
};

const getters = {};

const mutations = {
  SHOW_DIALOG: (state, params) => {
    state.show = true;
    state.isConfirmed = false;
    state.headline = params.headline;
    state.type = params.type || state.type;
    state.params = params.params || state.params;
  },
  CLOSE_DIALOG: state => {
    state.show = false;
    state.isConfirmed = false;
    state.type = "";
    state.headline = "";
    state.params = {};
  },
  SET_CONFIRMED: (state, isConfirmed) => {
    state.isConfirmed = isConfirmed;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
