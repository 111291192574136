import http from "@/commons/api/http";

const prefix = "api/todo/item";

const getSubItems = (groupId, parentId) =>
  http.get(`${prefix}/sub/${groupId}/${parentId}`);

/**
 * 아이템 추가
 * @param {*} boardId 보드 id
 * @param {*} groupId 그룹 id
 * @param {*} title 아이템 타이틀
 */
const addItem = ({ boardId, groupId, parentId, sortOrder, title }) => {
  const formData = new FormData();
  formData.append("boardId", boardId);
  formData.append("groupId", groupId);
  formData.append("parentId", parentId || 0);
  formData.append("sortOrder", sortOrder || 0);
  formData.append("title", title);

  return http.post(`${prefix}`, formData);
};

/**
 * 아이템 순서 변경
 * @param {*} boardId 보드 id
 * @param {*} id 아이템 id
 * @param {*} id2 옮기려는 위치의 아이템
 * @param {*} gi2 옮기려는 그룹 id
 * @param {*} position 옮기는 위치
 */
const moveItem = params => {
  let targetId = params.id2;
  let targetPosition = params.position;
  if (params.id2 == params.pi2) targetPosition = "first";
  if (params.id2 == "thead" || params.id2 == "additional") {
    targetId = 0;
    targetPosition = params.id2 == "thead" ? "first" : "last";
  }

  const formData = new FormData();
  formData.append("boardId", params.boardId);
  formData.append("targetItemId", targetId);
  formData.append("targetParentId", params.pi2);
  formData.append("targetGroupId", params.gi2);
  formData.append("position", targetPosition);

  return http.put(`${prefix}/${params.id}/move`, formData);
};

/**
 * 아이템 값 변경
 * @param {*} boardId 보드 id
 * @param {*} itemId 아이템 id
 * @param {*} headerValue 헤더 고유 값
 * @param {*} itemValue 아이템 변경 값
 */
const updateItem = ({ boardId, itemId, headerValue, itemValue }) => {
  const formData = new FormData();
  formData.append("boardId", boardId);
  formData.append("itemValue", itemValue);
  formData.append("headerValue", headerValue);

  return http.put(`${prefix}/${itemId}`, formData);
};

/**
 * 아이템 삭제
 * @param {*} boardId 보드 id
 * @param {*} itemIds 아이템 id
 */
const deleteItems = (boardId, items) => {
  const formData = new FormData();
  formData.append("boardId", boardId);
  items.forEach(({ id }) => {
    if (typeof id !== "number") return;
    formData.append("itemIds", id);
  });

  return http.delete(`${prefix}`, { data: formData });
};

export { getSubItems, addItem, moveItem, updateItem, deleteItems };
