<template>
  <div
    class="d-flex align-center"
    v-click-outside="clickOutside"
    @keydown.esc="clickOutside"
  >
    <!-- 메일함 선택 -->
    <MailboxSelect
      v-show="focusedInput"
      ref="mailboxSelect"
      class="mr-2"
      width="240px"
      height="400px"
      contentClass="cr-appbar"
      :folderId="routeFolderId"
      :closeOnContentClick="false"
      @change="mailboxChange"
    />
    <div class="cr-search-wrapper" @click="focus" @keydown="keydown">
      <!-- 검색어 입력창 -->
      <SearchInput
        ref="input"
        :searchWord.sync="searchWord"
        :keyEvent="keyEvent"
      />
      <!-- 검색어 자동완성 -->
      <SearchField
        v-if="showSearchField"
        :folderId="folderId"
        :searchWord="searchWord"
        :keyEvent="keyEvent"
      />
      <!-- 검색어 저장 -->
      <SearchHistory
        v-if="showSearchHistory"
        :folderId="folderId"
        :keyEvent="keyEvent"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-search-wrapper {
  display: flex;
  flex: 1 1 auto;
  width: 400px;
  max-width: 400px;

  /* 검색 드롭 메뉴 (검색필드, 히스토리) */
  .cr-search-drop-wrapper {
    position: absolute;
    max-width: 600px;
    width: 600px;
    top: 58px;
    left: 255px;
    &.cr-md {
      left: 206px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import MailboxSelect from "@/mail/views/components/main/commons/mailboxSelect";
import SearchInput from "./SearchInput.vue";
import SearchField from "./SearchField";
import SearchHistory from "./history";

export default {
  components: { MailboxSelect, SearchInput, SearchField, SearchHistory },
  mounted() {
    if (isBlank(this.query)) return;
    this.searchWord = this.query;
  },
  data() {
    return {
      keyEvent: null,
      folderId: -1,
      searchWord: "",
      focusedInput: false
    };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailLayout", ["useSearchHistory"]),
    ...mapGetters("folder", ["currentFolder", "getFolder"]),
    routeFolderId() {
      return this.currentFolder?.id || -1;
    },
    query() {
      return this.getRouteListInfo.actionObj?.search?.query || "";
    },
    showSearchField() {
      const { focusedInput, searchWord } = this;
      return focusedInput && !isBlank(searchWord);
    },
    showSearchHistory() {
      const { focusedInput, useSearchHistory, showSearchField } = this;
      return focusedInput && useSearchHistory && !showSearchField;
    }
  },
  watch: {
    query(query) {
      this.searchWord = query;
    },
    routeFolderId(folderId) {
      this.folderId = folderId;
    }
  },
  methods: {
    focus() {
      this.focusedInput = true;
      this.$emit("updateCls", "cr-focus-search");
    },
    blur() {
      this.focusedInput = false;
      this.$emit("updateCls", "");

      if (!this.$refs.input) return;
      this.$refs.input.$refs.searchInputRef.blur();
    },
    clickOutside() {
      if (!this.$refs.mailboxSelect) return;

      const { $refs } = this.$refs.mailboxSelect;
      if (!$refs.menu.isActive) return this.blur();
      this.$refs.mailboxSelect.activateMenu();
    },
    keydown(e) {
      this.keyEvent = e;
    },
    mailboxChange({ id }) {
      setTimeout(() => {
        // mailboxChange가 먼저 실행되기 때문에 이벤트 미뤄준다
        this.folderId = id;
        this.$refs.mailboxSelect.closeMenu();
        this.$refs.input.$refs.searchInputRef.focus();
      }, 0);
    }
  }
};
</script>
