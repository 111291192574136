import i18n from "@/_locales";

const state = {
  headline: "",
  show: false,
  type: "",
  params: {},

  showActions: true,
  showConfirmBtn: true,
  cancelBtnTitle: "",
  confirmBtnTitle: "",

  leftBtn: {
    use: false,
    color: "",
    title: "",
    func: () => {}
  }
};

const getters = {
  headline: ({ headline }) => headline,
  showDialog: ({ show }) => show,
  dialogType: ({ type }) => type,
  params: ({ params }) => params,
  showActions: ({ showActions }) => showActions,
  showConfirmBtn: ({ showConfirmBtn }) => showConfirmBtn,
  btnTitles: ({ cancelBtnTitle, confirmBtnTitle }) => ({
    cancel: cancelBtnTitle || i18n.t("common.취소"),
    confirm: confirmBtnTitle || i18n.t("common.확인")
  }),
  leftBtn: ({ leftBtn }) => leftBtn
};

const mutations = {
  SET_DIALOG: (state, params) => {
    state.headline = params.headline;
    state.show = params.show;
    state.type = params.type || state.type;
    state.params = params.params || state.params;
    state.showActions = params.showActions ?? true;
    state.showConfirmBtn = params.showConfirmBtn ?? true;

    if (!params.btnTitles) return;
    const { btnTitles } = params;
    state.cancelBtnTitle = btnTitles.cancel || state.cancelBtnTitle;
    state.confirmBtnTitle = btnTitles.confirm || state.confirmBtnTitle;
  },
  SET_HEADLINE: (state, headline) => {
    state.headline = headline;
  },
  SET_LEFT_BTN: (state, leftBtn) => {
    state.leftBtn = leftBtn;
  },
  SET_SHOW_CONFIRM_BTN: (state, show) => {
    state.showConfirmBtn = show;
  },
  SET_CONFIRM_BTN_TITLE: (state, title) => {
    state.confirmBtnTitle = title ?? i18n.t("common.확인");
  },
  SET_CANCEL_BTN_TITLE: (state, title) => {
    state.cancelBtnTitle = title ?? i18n.t("common.취소");
  },
  CLOSE_DIALOG: state => {
    state.headline = "";
    state.show = false;
    state.type = "";
    state.params = {};
    state.cancelBtnTitle = i18n.t("common.취소");
    state.confirmBtnTitle = i18n.t("common.확인");
    state.leftBtn = {
      use: false,
      color: "",
      title: "",
      func: () => {}
    };
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
