<template>
  <div
    class="cr-tree-wrapper"
    :class="$vuetify.breakpoint.lgAndUp ? '' : 'mobile'"
  >
    <v-treeview
      dense
      hoverable
      transition
      shaped
      return-object
      activatable
      open-on-click
      item-key="key"
      :items="treeItems"
      :open.sync="openItems"
      :active.sync="active"
    >
      <!-- 노드 아이콘 -->
      <template v-slot:prepend="{ item, active }">
        <v-icon v-if="item.icon" :color="active ? 'primary' : ''">
          {{ icons[item.icon] }}
        </v-icon>
      </template>
      <!-- 노드 라벨 -->
      <template v-slot:label="{ item }">
        <div class="cr-tree_node_click_area" @click="e => nodeClick(e, item)" />
        {{ item.name }}
      </template>
    </v-treeview>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import {
  BOX_LIST,
  getBoxByKey,
  getParentBoxByKey
} from "@/approval/constant/box";

export default {
  name: "TreeView",
  data() {
    return {
      // 트리 아이콘
      icons: {
        folder: "mdi-folder",
        home: "mdi-view-dashboard",
        draft: "mdi-folder-arrow-right",
        approve: "mdi-folder-edit",
        receive: "mdi-folder-arrow-left",
        share: "mdi-folder",
        cc: "mdi-folder-pound",
        organ: "mdi-folder-account",
        ledger: "mdi-archive-search",
        dot: "mdi-square-small"
      },
      // 트리 목록 (* key 필드가 식별키로 사용됨)
      treeItems: [...BOX_LIST],
      activeItem: {},
      openItems: []
    };
  },
  computed: {
    // tree active node
    active: {
      get() {
        return [this.activeItem];
      },
      set([item]) {
        this.activeItem = item;
      }
    },
    // 현재 라우팅에 해당하는 트리 노드
    nodeByCurrentRoute() {
      let key = undefined;
      const { name, params } = this.$route;
      switch (name) {
        case "approval":
        case "approval_home":
          key = "home";
          break;
        case "approval_document_list":
        case "approval_document_view":
          key = params?.boxKey;
      }
      return getBoxByKey(key);
    }
  },
  methods: {
    // 트리 클릭 시 라우팅 하는 함수
    nodeClick(e, item) {
      // 폴더일 경우 리턴
      if (item.children) return;
      // 아닐 경우 라우팅 이동
      e.stopPropagation();
      let params;
      if (item?.routeName === "approval_document_list") {
        params = { boxKey: item.key };
      }
      this.$router.push({
        name: item.routeName,
        params
      });
    },
    // {key}에 해당하는 폴더 열기
    openFolderByKey(key) {
      this.openItems = [...this.openItems, getBoxByKey(key)];
    }
  },
  watch: {
    // 노드 선택 변경시
    activeItem(n) {
      // STEP.1 모든 폴더 닫기
      this.openItems = [];
      // STEP.2 선택된 노드의 상위 문서함이 존재시 열기
      if (!n) return;
      const parentBox = getParentBoxByKey(n.key);
      if (parentBox) this.openFolderByKey(parentBox.key);
    },
    // 라우팅에 의해 노드 선택이 변경될때
    nodeByCurrentRoute(changedNode) {
      this.activeItem = changedNode;
    }
  },
  mounted() {
    // 현재 라우팅에 해당하는 트리노드 active
    this.activeItem = this.nodeByCurrentRoute;
  }
};
</script>

<style lang="scss" scoped>
.cr-tree-wrapper {
  position: absolute;
  top: 52px;
  bottom: 0;
  left: 0;
  right: 1px;
  overflow-y: auto;
  padding-right: 7px;
  max-height: calc(100% - 116px);

  &.mobile {
    top: 60px;
    max-height: calc(100% - 52px);
  }
  .v-treeview ::v-deep {
    /* 트리 노드 */
    .v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          margin-left: 0;
        }
      }
      /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
      .v-treeview-node__children {
        overflow: hidden;
      }
    }

    > .v-treeview-node {
      .v-treeview-node__root {
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }

        .v-treeview-node__content {
          cursor: pointer;
          .v-treeview-node__label {
            .cr-tree_node_click_area {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            .cr-tree-label-text {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              min-width: 0;
            }
          }
        }
      }
      > .v-treeview-node__children {
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>
