import http from "@/commons/api/http";

/**
 * 기본 정보 수정
 * @returns
 */
const updateGeneralInfo = ({ accountName, personalEmail, languageType }) => {
  const formData = new FormData();
  formData.append("accountName", accountName);
  formData.append("personalEmail", personalEmail);
  formData.append("languageType", languageType);
  return http.put("api/users/general", formData);
};

/**
 * 언어 변경
 * @returns {*}
 */
const updateUserLanguage = language => {
  const formData = new FormData();
  formData.append("languageType", language);
  return http.put("api/users/language", formData);
};

export { updateGeneralInfo, updateUserLanguage };
