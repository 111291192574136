var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-tree-wrapper",class:{ mobile: _vm.$vuetify.breakpoint.mdAndDown }},[_c('v-treeview',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
      DRAG_COMP: 'tree', // directive를 사용하는 컴포넌트 타이틀
      genHelper: _vm.genHelper, // helper 생성 함수 / Argument(event, setDragInfo)) / return element
      genDropzone: _vm.genDropzone, // 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)
      markDropzone: _vm.markDropzone, // 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)
      mouseup: _vm.mouseup // drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))
    }),expression:"{\n      DRAG_COMP: 'tree', // directive를 사용하는 컴포넌트 타이틀\n      genHelper, // helper 생성 함수 / Argument(event, setDragInfo)) / return element\n      genDropzone, // 드랍가능영역 생성함수 (호출시기: 드래그시작, 컴포넌트 업데이트)\n      markDropzone, // 마우스 이동시 드랍가능한영역 표시해주는 함수 / Argument(event, dragInfo)\n      mouseup // drag & drop 후 발생하는 mouseup event / Argument(event, dragInfo))\n    }"}],attrs:{"dense":"","hoverable":"","activatable":"","transition":"","shaped":"","return-object":"","item-text":"nameFolder","draggable":"true","items":_vm.getFolders,"active":_vm.activeItems,"open":_vm.openFolders},on:{"update:active":[function($event){_vm.activeItems=$event},_vm.onActive],"update:open":function($event){_vm.openFolders=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
    var item = ref.item;
    var open = ref.open;
    var active = ref.active;
return [_c('FolderIcon',{attrs:{"item":item,"open":open,"active":active}})]}},{key:"label",fn:function(ref){
    var item = ref.item;
return [_c('TreeItem',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }