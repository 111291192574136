<template>
  <v-icon :class="{ 'ml-3': leaf, 'primary--text': active }">
    {{ `mdi-${icons[item.folderType] || "file-document"}` }}
  </v-icon>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    },
    leaf: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Object,
      default: () => ({
        FILTER_ROOT: "filter"
      })
    }
  },
  computed: {}
};
</script>
