<template>
  <div v-html="$t(isMyTags ? 'common.84' : 'common.85')" />
</template>

<style lang="scss" scoped>
div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
export default {
  props: {
    isMyTags: {
      type: Boolean,
      default: false
    }
  }
};
</script>
