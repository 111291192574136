<template>
  <v-snackbar
    v-model="showSnackbar"
    :timeout="getTimeOut"
    :color="getColor"
    :top="getPosition.top"
    :right="getPosition.right"
    class="text-pre-wrap"
  >
    <v-icon class="mr-1">{{ getIcon }}</v-icon>
    {{ getMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="CLOSE_SNACKBAR">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("snackbar", [
      "isShow",
      "getMessage",
      "getTimeOut",
      "getColor",
      "getIcon",
      "getPosition"
    ]),
    showSnackbar: {
      get() {
        return this.isShow;
      },
      set() {
        return this.CLOSE_SNACKBAR();
      }
    }
  },
  methods: {
    ...mapMutations("snackbar", ["CLOSE_SNACKBAR"])
  }
};
</script>
