<template>
  <span v-if="!item.edit" class="text-truncate">
    {{ item.title }}
  </span>
  <!-- 폴더이름 수정필드 -->
  <v-text-field
    v-else
    autofocus
    autocomplete="off"
    :loading="loading"
    :label="item.title"
    @blur="editBoard"
    @keyup.enter="editBoard"
    @keyup.esc="UPDATE_BOARD({ boardId: item.id, edit: !item.edit })"
  />
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { loading: false };
  },
  methods: {
    ...mapMutations("todoBoard", ["UPDATE_BOARD"]),
    ...mapActions("todoBoard", ["updateBoardTitle"]),
    async editBoard(e) {
      const { id: boardId, title: prevTitle, boardType } = this.item;
      const title = e.target.value.trim();
      if (isBlank(title) || title == prevTitle) {
        return this.UPDATE_BOARD({ boardId, edit: false });
      }

      this.loading = true;
      await this.updateBoardTitle({ boardId, boardType, title });
      this.loading = false;
    }
  }
};
</script>
