import { getAccountHistory } from "@/account/api/history.api";
import i18n from "@/_locales";

const state = {
  loading: false,
  page: 0,
  pageSize: 10,
  totalElements: 0,
  historyList: []
};

const getters = {
  getLoading: ({ loading }) => loading,
  getPage: ({ page }) => page,
  getPageSize: ({ pageSize }) => pageSize,
  getTotalElements: ({ totalElements }) => totalElements,

  getList: ({ historyList }) =>
    historyList.map((item, index) => {
      return {
        index,
        ...item
      };
    })
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_LOG_LIST: (state, { page, list, totalElements }) => {
    state.page = page;
    state.totalElements = totalElements;
    state.historyList = list;
  }
};

const actions = {
  async getLogList({ commit, dispatch }, param) {
    commit("SET_LOADING", true);

    const page = param.page;
    const { data, status } = await getAccountHistory({
      page: page - 1
    });

    commit("SET_LOADING", false);
    if (status != 200) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("common.80"), type: "ERROR" },
        { root: true }
      );
    }
    const { list, result } = data;
    if (!result) {
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("common.80"), type: "ERROR" },
        { root: true }
      );
    }
    const { content, totalElements } = list;
    commit("SET_LOG_LIST", { page: page, list: content, totalElements });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
