import i18n from "@/_locales";
import { searchTags } from "../../../api/tag.api";
const searchState = {
  searchTags: []
};

const searchGetters = {
  searchTags: ({ searchTags }) => searchTags,
  validTag: () => tag => {
    // 공백 체크
    if (tag.match(/\s/g)?.length > 0) {
      return { label: i18n.t("common.67"), error: true };
    }

    // 특수문자 체크
    if (tag.match(/[\\[\];'",./=`~!@#$%^&*()\-+|<>?:{}]/g)?.length > 0) {
      return { label: i18n.t("common.68"), error: true };
    }

    // 글자수 체크
    if (tag.length > 20) {
      return { label: i18n.t("common.69"), error: true };
    }

    return { error: false, label: "" };
  }
};

const searchMutations = {
  SET_SEARCH_TAGS: (state, tags) => {
    state.searchTags = tags;
  }
};

const searchActions = {
  async searchTags({ state, commit, dispatch }, { tag, error, page }) {
    const { pageSize } = state;
    if (!tag || error) {
      commit("SET_LOADING", false);
      commit("SET_SEARCH_TAGS", []);
      return;
    }

    const { data, status } = await searchTags(tag, { page, pageSize });
    if (status !== 200) {
      let type = "ERROR";
      let message = i18n.t("common.7");
      dispatch("snackbar/openSnackbar", { message, type }, { root: true });
      return;
    }

    commit("SET_LOADING", false);
    commit("SET_SEARCH_TAGS", data.content);
  }
};

export { searchState, searchGetters, searchMutations, searchActions };
