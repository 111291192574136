<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" icon @click="$emit('closeDrawer')">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <span>{{ $t("mail.490") }}</span>
  </v-tooltip>
</template>

<style lang="scss" scoped>
.v-btn {
  position: absolute;
  bottom: 11px;
  right: 23px;
  z-index: 6;
}
</style>

<script>
export default {};
</script>
