<template>
  <v-menu v-model="menu" offset-y disable-keys offset-overflow>
    <template v-slot:activator="{ on, attrs }">
      <div class="cr-tag-ac" v-bind="attrs" v-on="on">
        <v-text-field
          ref="input"
          dense
          filled
          hide-details
          autocomplete="off"
          :outlined="outlined"
          :loading="loading"
          :placeholder="_placeholder"
          v-model="value"
          @keydown="inputKeydown"
        >
          <template slot="prepend-inner">
            <Chips
              v-bind="$props"
              :chipSelect.sync="chipSelect"
              @deleteViewTag="deleteViewTag"
            />
          </template>
        </v-text-field>
      </div>
    </template>

    <List
      v-if="showList"
      v-bind="$props"
      :loading="loading"
      :list="list"
      :listSelect.sync="listSelect"
      @rowClick="rowClick"
    />
  </v-menu>
</template>

<style lang="scss" scoped>
.cr-tag-ac {
  position: relative;
}
.v-input.v-text-field ::v-deep {
  clear: both;
  > .v-input__control {
    > .v-input__slot {
      display: flex;
      flex-wrap: wrap;
      /* hover 때문에 important */
      background-color: transparent !important;
      min-height: 29px;
      padding: 0px;

      &::before {
        border: none;
      }

      > .v-text-field__slot {
        > input {
          padding-top: 0px;
          font-size: 14px;
        }
      }

      .v-input__prepend-inner,
      .v-item-group,
      .v-slide-group__wrapper,
      .v-slide-group__content {
        display: contents;
      }
    }
  }
}

// oulined
.v-input.v-text-field.v-text-field--outlined::v-deep {
  > .v-input__control {
    > .v-input__slot {
      padding: 4px 6px 0px;
      > .v-text-field__slot {
        > input {
          padding: 0px 7px 2px;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import List from "./List.vue";
import Chips from "./Chips.vue";

export default {
  components: { List, Chips },
  emits: ["add:tag", "delete:tag"],
  props: {
    link: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    creatable: {
      type: Boolean,
      default: true
    },
    fixedHeight: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    listHeight: {
      type: Number,
      default: 400
    },
    myTags: {
      type: Array,
      default: () => []
    },
    viewTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      menu: false,
      timeout: null,
      loading: false,
      keydown: false,
      listSelect: undefined,
      chipSelect: undefined,
      search: { tag: "", error: false, label: "" }
    };
  },
  computed: {
    ...mapGetters("tag", {
      showTagDialog: "showTagDialog",
      searchTagList: "searchTags",
      validTag: "validTag"
    }),
    value: {
      get() {
        return this.search.tag;
      },
      set(tag) {
        // 2byte 문자버그로 인해 텍스트 입력 후 페이지 클릭시 두번탐 -> keydown이벤트로 감지
        if (!this.keydown) return;
        const { error, label } = this.validTag(tag);
        this.search = { tag, error, label };
      }
    },
    list() {
      const { value, searchTagList, myTags, viewTags } = this;
      if (value.length > 0) {
        return searchTagList.filter(t => viewTags.indexOf(t.tag) === -1);
      }

      const _viewTags = viewTags.map(tag => ({ tag, isViewTag: true }));
      const filteredTags = myTags.filter(t => viewTags.indexOf(t.tag) === -1);
      if (this.fixedHeight) return [..._viewTags, ...filteredTags];
      return filteredTags;
    },
    showList() {
      return !this.loading && this.menu && this.list.length > 0;
    },
    _placeholder() {
      const { length } = this.viewTags;
      return length > 0 ? "" : this.placeholder || this.$t("mail.133");
    }
  },
  watch: {
    search({ tag: n }, { tag: p }) {
      if (n === p || this.showTagDialog) return;
      if (n) this.loading = true;
      this.SET_SEARCH_TAGS([]);
      this.getSearchTags();
    }
  },
  methods: {
    ...mapMutations("tag", ["SET_SEARCH_TAGS"]),
    ...mapActions("tag", ["addTag", "searchTags"]),
    createTag(tag) {
      if (!this.creatable) return;
      this.addTag({ ...this.search, callback: () => this.addViewTag(tag) });
    },
    addViewTag(tag) {
      this.$emit("add:tag", tag);
      this.search = { tag: "", error: false, label: "" };
      this.menu = false;
    },
    deleteViewTag(tag) {
      this.$emit("delete:tag", tag);
      this.menu = false;
    },
    rowClick(tag) {
      if (tag.isViewTag) return this.deleteViewTag(tag.tag);
      this.addViewTag(tag.tag);
    },
    getSearchTags() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(async () => {
        await this.searchTags(this.search);
        this.loading = false;
        this.keydown = false;
      }, 400);
    },
    inputKeydown(e) {
      e.stopPropagation();
      const maxIndex = this.list.length - 1;
      const maxChipIndex = this.viewTags.length - 1;
      const {
        chipSelect: cS,
        value: v,
        listSelect: lS,
        menu,
        fixedHeight: fixed
      } = this;

      switch (e.key) {
        case "ArrowLeft": {
          if (fixed || maxChipIndex == -1 || cS == 0 || v.length != 0) {
            return;
          }

          this.listSelect = undefined;
          this.chipSelect = cS == undefined ? maxChipIndex : cS - 1;
          return;
        }
        case "ArrowRight": {
          if (fixed || maxChipIndex == -1 || cS == undefined || v.length != 0) {
            return;
          }

          this.listSelect = undefined;
          this.chipSelect = cS == maxChipIndex ? undefined : cS + 1;
          return;
        }
        case "ArrowUp": {
          e.preventDefault();
          if (!menu) return (this.menu = true);

          this.chipSelect = undefined;
          this.listSelect = lS == undefined ? maxIndex : lS - 1;
          if (this.listSelect < 0) this.listSelect = maxIndex;
          return;
        }
        case "ArrowDown": {
          e.preventDefault();
          if (!menu) return (this.menu = true);

          this.chipSelect = undefined;
          this.listSelect = lS == undefined || lS + 1 > maxIndex ? 0 : lS + 1;
          return;
        }
        case "Escape": {
          if (lS >= 0) this.listSelect = undefined;
          else this.menu = false;
          return;
        }
        case "Enter": {
          e.preventDefault();
          e.stopPropagation();
          if (this.loading) return;

          // 선택한 리스트가 있는지 확인
          if (lS >= 0) {
            const tag = this.list[lS];
            if (!tag) return;
            return this.rowClick(tag);
          }

          this.value = v.trim();
          if (!this.value) return;
          if (
            !this.viewTags.find(
              v => v.toLowerCase() === this.value.toLowerCase()
            )
          ) {
            this.createTag(this.value);
          }

          return;
        }
        case "Backspace": {
          if (fixed && !this.value) return;
          if (v.length == 0 && cS != undefined) {
            const tag = this.viewTags[cS];
            if (!tag) return;

            this.deleteViewTag(tag);
          } else if (v.length == 0) {
            this.chipSelect = maxChipIndex;
          }
          break;
        }
        default:
          this.chipSelect = undefined;
          this.listSelect = undefined;
          this.menu = true;
          break;
      }

      this.keydown = true;
    }
  }
};
</script>
