<template>
  <div>
    <v-card-subtitle class="px-0">
      {{ $t("common.71") }}
    </v-card-subtitle>

    <!-- 검색된 태그 목록 -->
    <Tag
      v-for="(tag, idx) in searchTags"
      :key="tag.tag"
      :tag="tag"
      :lastChild="idx === searchTags.length - 1"
    />
    <!-- 데이터 없을 때 -->
    <div v-if="!searchTags.length && !search.error" class="cr-empty">
      <p>{{ $t("common.4") }}</p>
      <p>
        <span class="primary--text">"{{ search.tag }}"</span>
        {{ $t("common.72") }}
        <a link @click="addTag(search)">{{ $t("common.73") }}</a>
      </p>
    </div>
    <div v-else class="cr-empty red--text font-weight-bold">
      {{ search.label }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-empty {
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;

  p {
    font-size: 0.95rem;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Tag from "./Tag.vue";

export default {
  components: { Tag },
  props: {
    search: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("tag", ["searchTags"])
  },
  methods: {
    ...mapActions("tag", ["addTag"])
  }
};
</script>
