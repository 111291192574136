var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activated && !_vm.editorInitialized)?_c('v-skeleton-loader',{staticClass:"mx-2",attrs:{"type":"sentences"}}):(!_vm.activated)?_c('v-btn',{staticClass:"activator text-body-2 grey--text",attrs:{"block":"","text":""},on:{"click":_vm.activate}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activated && _vm.editorInitialized),expression:"activated && editorInitialized"}]},[_c('Editor',{key:_vm.editorKey,attrs:{"value":_vm.value,"plugins":"lists autolink autoresize paste link quickbars","toolbar":"bold italic underline | bullist numlist | link","init":{
          width: '100%',
          height: '100%',
          // 불필요한 UI 제거
          resize: false,
          menubar: false,
          branding: false,
          elementpath: false,
          // HTML 태그 정리( \n\r, 불필요한 루트 태그 제거 )
          indent: false,
          forced_root_block: false,
          // 붙여넣기 시 서식 제거( 배경색 등 허용하지 않은 서식 방지 )
          paste_as_text: true,
          // 이미지 붙여넣기 제한
          paste_data_images: false,
          placeholder: _vm.placeholder,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar:
            'bold italic underline | bullist numlist | link',
          link_quicklink: true,
          base_url: '/js',
          content_style:
            'body { margin: 4px 12px; line-height: 1.25rem; font-size: 14px; font-family: Noto Sans KR, -apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', sans-serif }' +
            ' p, ul, ol { margin: 0; } ul, ol { padding-left: 24px }',
          // 불필요한 하단 공백 제거
          autoresize_bottom_margin: 0,
          setup: function(ed) {
            _vm.editor = ed;
          }
        }},on:{"onFocus":_vm.onFocus,"onBlur":_vm.onBlur,"input":function (val) { return _vm.$emit('input', val); }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }