<template>
  <div style="display: contents;">
    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <Navigation />
    </v-navigation-drawer>
    <NavigationFloatingBtn v-if="showNavigationFloatingBtn" />
  </div>
</template>

<script>
import Navigation from "../components/navigation";
import NavigationFloatingBtn from "@/flow/views/components/navigation/tasks/FloatingBtn.vue";

export default {
  components: { Navigation, NavigationFloatingBtn },
  data() {
    return { drawer: true };
  },
  computed: {
    showNavigationFloatingBtn() {
      return this.drawer && this.$route.name.split("_")[1] === "tasks";
    }
  }
};
</script>
