// 사용자 정의 디렉티브 longpress 추가 Important.vue에서 사용
const longPress = {
  bind: function(el, binding, vNode) {
    if (typeof binding.value !== "function") {
      const compName = vNode.context.name;
      let warn = `[long-press:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) warn += `Found in component '${compName}' `;

      console.warn(warn);
    }

    let pressTimer = null;
    let start = e => {
      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          binding.value(e);
        }, 1000);
      }
    };
    let cancel = e => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
        // 기본 btn에 click예외처리 위해 한번더 호출
        binding.value(e);
      }
    };

    // Add Event listeners
    el.addEventListener("mousedown", start);
    el.addEventListener("touchstart", start);
    // Cancel timeouts if this events happen
    el.addEventListener("click", cancel);
    el.addEventListener("mouseout", cancel);
    el.addEventListener("touchend", cancel);
    el.addEventListener("touchcancel", cancel);
  }
};

export default longPress;
