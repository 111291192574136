<template>
  <div class="d-flex align-center" style="height: 100%;">
    <div class="ma-auto d-flex flex-column" style="width: 400px">
      <div
        class="d-flex pa-0 pt-1 pb-2 pl-2 grey--text text-caption justify-end"
      >
        *공유 받은 메일 주소로 인증 코드를 받아주세요.
      </div>
      <div class="d-flex align-center mb-3">
        <v-text-field
          ref="email"
          outlined
          dense
          hide-details
          autofocus
          placeholder="이메일 주소 입력"
          :loading="emailLoading"
          :disabled="emailDisabled"
          v-model="email"
          @keydown.enter="send"
        >
          <template v-slot:progress>
            <v-progress-linear indeterminate absolute height="4" />
          </template>
        </v-text-field>
        <v-btn
          tile
          depressed
          height="40"
          color="primary"
          :disabled="emailDisabled"
          :class="!emailLoading && emailDisabled ? 'cr-send-success' : ''"
          @click="send"
        >
          {{ !emailLoading && emailDisabled ? "전송완료" : "메일전송" }}
        </v-btn>
      </div>

      <div class="d-flex justify-center">
        <v-otp-input
          ref="otp"
          class="ml-8"
          v-model="otp"
          :disabled="otpLoading"
          @finish="onFinish"
        />
        <div class="cr-timer">
          <v-progress-circular
            v-show="showTimer"
            :color="otpLoading ? 'primary' : 'white'"
            :value="(timer * 100) / 60"
            :indeterminate="otpLoading"
          >
            <span>{{ timer }}</span>
          </v-progress-circular>
        </div>
      </div>
      <div
        class="d-flex pa-0 pt-1 pb-2 red--text text-caption justify-center"
        style="height: 32px;"
      >
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-text-field {
  background: #fff;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f5f5 !important;

  &.cr-send-success {
    color: var(--v-primary-base) !important;
  }
}

.v-otp-input {
  max-width: 300px;
}
.cr-timer {
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  width: 50px;
  font-size: 16px;
  padding: 12px 8px;
}
</style>

<script>
import { mapActions } from "vuex";
import { isEmail, isBlank } from "@/commons/utils/validation";

export default {
  data() {
    return {
      email: "",
      emailLoading: false,
      emailDisabled: false,

      otp: "",
      otpLoading: false,
      showTimer: false,
      timer: null,
      interval: null,
      msg: ""
    };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("mailLinkShare", ["sendVerificationCode", "getMailView"]),
    async send() {
      const email = this.email.trim();
      if (isBlank(email)) {
        this.$refs.email.focus();
        this.openSnackbar({
          type: "ERROR",
          message: "메일주소를 입력해주세요."
        });
        return;
      }

      if (!isEmail(email)) {
        this.$refs.email.focus();
        this.openSnackbar({
          type: "ERROR",
          message: "메일주소를 확인해주세요."
        });
        return;
      }

      this.emailLoading = true;
      this.emailDisabled = true;
      this.otp = "";

      // 메일전송 404 403 500
      const { data, status } = await this.sendVerificationCode(email);

      let message = "메일 발송에 실패했습니다. 관리자에게 문의하세요.";
      let type = "ERROR";
      switch (status) {
        case 200:
          message = "메일을 확인해주세요.";
          type = "SUCCESS";
          this.$refs.otp.focus();
          this.startTimer((new Date().getTime() - data) / 1000);
          break;
        case 404:
          message = "공유 정보를 찾을 수 없습니다.";
          this.emailDisabled = false;
          break;
        case 403:
          message = "접근 권한이 없습니다.";
          this.emailDisabled = false;
          break;
      }

      this.emailLoading = false;
      this.openSnackbar({ message, type });
    },
    startTimer(timer) {
      this.showTimer = true;
      this.timer = Math.floor(60 - timer);
      this.interval = setInterval(() => {
        this.timer -= 1;
        if (this.timer === 0) {
          clearInterval(this.interval);
          this.showTimer = false;
          this.otpLoading = true;
          this.interval = null;
          this.timer = null;
          this.msg = "*인증 시간이 만료되었습니다.";
        }
      }, 1000);
    },
    async onFinish(verificationCode) {
      if (
        !this.emailLoading &&
        !this.emailDisabled &&
        !this.showTimer &&
        this.interval === null
      ) {
        return;
      }

      clearInterval(this.interval);
      this.interval = null;
      this.otpLoading = true;
      this.timer = null;

      // 서버로 메일보기 요청
      if (!(await this.getMailView({ email: this.email, verificationCode }))) {
        this.showTimer = false;
        this.msg = "인증에 실패했습니다.";
      }
    }
  }
};
</script>
