<template>
  <v-toolbar class="cr-cal-toolbar">
    <v-menu
      v-model="advancedMenu"
      :close-on-content-click="false"
      offset-y
      rounded
      transition="slide-y-transition"
      content-class="elevation-3"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="searchParam.keyword"
          color="dark"
          solo
          hide-details
          clearable
          :flat="flat"
          background-color="grey lighten-3"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('calendar.112')"
          :disabled="advancedMenu"
          class="cr-search-input hidden-sm-and-down shrink"
          @click="flat = false"
          @blur="flat = true"
          @keydown.enter="execSearch"
        >
          <template #append>
            <v-btn
              v-on="on"
              rounded
              depressed
              :class="{ 'grey--text': !advancedMenu }"
              :color="`grey ${advancedMenu ? 'lighten-2' : 'lighten-3'}`"
            >
              {{ $t("common.상세") }}
              <v-icon dense :class="{ 'is-menu-active': advancedMenu }">
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>
      <!-- 상세 검색 -->
      <v-card>
        <v-card-text class="text--primary px-5">
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.검색_대상") }}
            </v-col>
            <v-col cols="9">
              <FlatSelect
                v-model="searchParam.calendarType"
                :items="calendarTypes"
                :menu-props="{
                  offsetY: true
                }"
                :btn-props="{ text: false, depressed: true }"
              />
            </v-col>
          </v-row>
          <!-- 제목 -->
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.제목") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="searchParam.summary"
                required
                :placeholder="$t('calendar.151')"
                @keydown.enter="execAdvancedSearch"
              ></FlatTextField>
            </v-col>
          </v-row>
          <!-- 설명 -->
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.설명") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="searchParam.description"
                required
                :placeholder="$t('calendar.152')"
                @keydown.enter="execAdvancedSearch"
              ></FlatTextField>
            </v-col>
          </v-row>
          <!-- 장소 -->
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.장소") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="searchParam.location"
                required
                :placeholder="$t('calendar.153')"
                @keydown.enter="execAdvancedSearch"
              ></FlatTextField>
            </v-col>
          </v-row>
          <!-- 참석자 -->
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.참석자") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="searchParam.attendee"
                required
                :placeholder="$t('calendar.154')"
                @keydown.enter="execAdvancedSearch"
              ></FlatTextField>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-list-item dense class="ml-2 pa-0">
            <v-list-item-icon class="mr-1">
              <v-icon dense color="grey lighten-1">
                mdi-information
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ $t("calendar.156") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="execAdvancedSearch">
            {{ $t("calendar.검색") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <SearchDialog
      v-if="searchDialog"
      v-model="searchDialog"
      :search-param="searchParam"
      max-width="300px"
    />
  </v-toolbar>
</template>

<script>
import FlatTextField from "@/calendar/components/common/FlatTextField.vue";
import SearchDialog from "@/calendar/components/event/EventSearchDialog.vue";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";

const SEARCH_CALENDAR_TYPE = {
  MY: "MY",
  DEPT: "DEPT"
};

const EMPTY_PARAM = {
  calendarType: SEARCH_CALENDAR_TYPE.MY,
  keyword: "",
  summary: "",
  description: "",
  attendee: "",
  location: ""
};

export default {
  components: { FlatSelect, SearchDialog, FlatTextField },
  data() {
    return {
      flat: true,
      advancedMenu: false,
      searchDialog: false,
      searchParam: {
        ...EMPTY_PARAM
      },
      calendarTypes: [
        { text: this.$t("calendar.내_캘린더"), value: SEARCH_CALENDAR_TYPE.MY },
        {
          text: this.$t("calendar.155"),
          value: SEARCH_CALENDAR_TYPE.DEPT
        }
      ]
    };
  },
  watch: {
    advancedMenu(val) {
      if (val) {
        this.searchParam = {
          ...EMPTY_PARAM
        };
      }
    }
  },
  methods: {
    execSearch() {
      this.openSearchDialog();
    },
    execAdvancedSearch() {
      this.searchParam.keyword = "";
      this.openSearchDialog();
    },
    isInputValid() {
      return (
        this.searchParam.keyword.length > 0 ||
        this.searchParam.summary.length > 1 ||
        this.searchParam.description.length > 1 ||
        this.searchParam.attendee.length > 1 ||
        this.searchParam.location.length > 1
      );
    },
    openSearchDialog() {
      if (!this.isInputValid()) return;
      this.searchDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar ::v-deep {
  background-color: transparent !important;
  .v-toolbar__content {
    padding: 0 !important;

    .cr-search-input {
      width: 600px;
      max-width: 600px;

      .v-input__slot {
        padding: 0 4px 0 12px !important;

        .v-btn__content .is-menu-active.v-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
