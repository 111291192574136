<template>
  <v-row>
    <!-- 게시판 선택 -->
    <v-col cols="12" class="py-2">
      <div
        class="cr-title text-body-2 mb-2 d-flex align-start"
        v-text="'게시판'"
      ></div>
      <InputSelector
        label="전체"
        :height="height"
        :initItems="boardItems"
        :selectedItem.sync="selectedBoard"
      />
    </v-col>
    <!-- 제목/내용 검색 -->
    <v-col cols="12" class="d-flex align-center py-2">
      <!-- 검색대상 선택 -->
      <div class="mr-2" style="width:200px">
        <div
          class="cr-title text-body-2 mb-2 d-flex align-start"
          v-text="'대상'"
        ></div>
        <v-select
          v-model="searchType"
          :items="searchTypeList"
          :height="height"
          dense
          outlined
          hide-details
        />
      </div>
      <!-- 검색어 -->
      <div style="width:100%">
        <div
          class="cr-title text-body-2 mb-2 d-flex align-start"
          v-text="'검색어'"
        ></div>
        <v-text-field
          ref="keywordInput"
          dense
          outlined
          :height="height"
          hide-details
          autocomplete="off"
          tabindex="1"
          placeholder="입력해 주세요"
          v-model="keyword"
          maxlength="50"
          @keydown="inputKeydown"
        ></v-text-field>
      </div>
    </v-col>
    <!-- 태그 -->
    <v-col cols="12" class="py-2">
      <div
        class="cr-title text-body-2 mb-2 d-flex align-start"
        v-text="'태그'"
      />
      <TagAutocomplete
        outlined
        fixedHeight
        :creatable="false"
        :placeholder="'태그입력'"
        :listHeight="240"
        :myTags="getTags"
        :viewTags="tags"
        @add:tag="addTag"
        @delete:tag="deleteTag"
      />
    </v-col>
    <!-- 부가 옵션 -->
    <v-col cols="12" class="py-2">
      <div
        class="cr-title text-body-2 mb-2 d-flex align-start"
        v-text="'포함'"
      />
      <div class="d-flex align-center">
        <v-checkbox
          v-model="isImportant"
          dense
          label="중요"
          color="#6A8FFF"
          hide-details
          class="ma-0 pa-0 mr-4"
        ></v-checkbox>
        <v-checkbox
          v-model="isNotice"
          dense
          label="공지사항"
          color="#6A8FFF"
          hide-details
          class="ma-0 pa-0 mr-4"
        ></v-checkbox>
        <v-checkbox
          v-model="hasAttach"
          dense
          label="첨부파일"
          color="#6A8FFF"
          class="ma-0 pa-0"
          hide-details
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn text class="mr-1" @click="closeDetail">닫기</v-btn>
      <v-btn color="primary" elevation="0" @click="onSearch">검색</v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-title {
  color: grey;
}
</style>

<script>
import InputSelector from "@/board/views/components/common/InputSelector";
import TagAutocomplete from "@/commons/views/tag/autocomplete";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { InputSelector, TagAutocomplete },
  props: {},
  mounted() {},
  data() {
    return {
      selectedBoard: {},
      searchTypeList: [
        {
          text: "전체",
          value: "ALL"
        },
        {
          text: "제목",
          value: "TITLE"
        },
        {
          text: "내용",
          value: "CONTENT"
        },
        {
          text: "제목+내용",
          value: "TITLE_CONTENT"
        },
        {
          text: "작성자",
          value: "WRITER"
        }
      ],
      searchType: "ALL",
      keyword: "",
      isImportant: false,
      isNotice: false,
      hasAttach: false,
      tags: [],
      height: 45
    };
  },
  watch: {},
  computed: {
    ...mapGetters("board", ["getBoards"]),
    ...mapGetters("boardConfig", ["getTags"]),
    boardItems() {
      let boards = [];

      // 1 depth 구조로 변경
      this.getBoards.forEach(b => {
        const { children, ...board } = b;
        boards = children
          ? [...boards, board, ...children]
          : [...boards, board];
      });

      // 검색 가능한 게시판 필터링
      return boards.filter(b => !b.isDeleted && b.boardType == "BOARD");
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    init() {
      this.selectedBoard = {};
      this.searchType = "ALL";
      this.keyword = "";
      this.isImportant = false;
      this.isNotice = false;
      this.hasAttach = false;
      this.tags = [];
    },
    addTag(tag) {
      this.tags.push(tag);
    },
    deleteTag(tag) {
      this.tags = this.tags.filter(t => t !== tag);
    },
    closeDetail() {
      this.$emit("closeDetail", false);
      this.init();
    },
    inputKeydown(e) {
      if (e.key === "Enter") this.onSearch();
    },
    onSearch() {
      let queryObj = {};

      // 게시판
      if (this.selectedBoard) {
        queryObj.boardId = this.selectedBoard.id;
      }

      // 유효성 검증 및 검색라우팅
      if (this.validation()) {
        // 키워드, 대상
        queryObj.keyword = this.keyword;
        queryObj.searchType = this.searchType;
        queryObj.tags = this.tags;
        // 부가 옵션
        if (this.isImportant) queryObj.isImportant = true;
        if (this.isNotice) queryObj.isNotice = true;
        if (this.hasAttach) queryObj.hasAttach = true;

        this.$router.push({
          name: "board_search",
          params: {
            type: "post",
            query: JSON.stringify(queryObj)
          }
        });
        this.closeDetail();
      }
    },
    validation() {
      // 앞뒤 공백 제거
      this.keyword = this.keyword.trim();

      // 검색 입력창 Element
      const keywordInput = this.$refs?.keywordInput;

      // 글자 수 검증
      if (this.tags.length == 0 && this.keyword.length < 1) {
        this.openSnackbar({
          message: "최소 한 글자 이상 입력해야 합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      if (this.keyword.length > 50) {
        this.openSnackbar({
          message: "최대 50 글자까지 입력 가능합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      return true;
    }
  },
  destroyed() {
    this.init();
  }
};
</script>

<style lang="scss" scoped></style>
