import storage from "@/commons/api/storage";

const state = {
  show: (storage.getSidePanelOpen() ?? "true") === "true",
  width: 80
};

const getters = {
  showSidePanel: ({ show }) => show,
  sidePanelWidth: ({ width }) => width,
  // 삭제예정
  renderedWidth: ({ show, width }) => (show ? width : 0),
  sidePanelInfo: ({ show, width }) => ({
    showSidePanel: show,
    sidePanelWidth: width
  })
};

// state값 수정
const mutations = {
  SET_SHOW_SIDE_PANEL: (state, show) => {
    state.show = show;
  }
};

// mutations 호출 및 비동기 처리
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
