<template>
  <div>
    <FlatTextInput
      v-model="search"
      ref="searchInput"
      :placeholder="placeholder"
      @keydown.stop="searchKeydown"
    />
    <span v-show="errorMessage" class="ml-2 text-caption error--text">
      {{ errorMessage }}
    </span>
    <v-menu
      v-if="searchedItems.length"
      :value="searchedItems.length"
      :activator="$refs.searchInput.$el"
      :close-on-content-click="false"
      offset-y
      offset-overflow
    >
      <v-list ref="searchList" dense two-line>
        <v-list-item-group v-model="searchSelection">
          <v-list-item
            :key="idx"
            v-for="(item, idx) in searchedItems"
            @click="addSelectedAttendee(item)"
          >
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ item.name }}
                <span class="text-caption grey--text text--darken-2">
                  {{ makeJobSummary(item) }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-space-between">
                {{ item.email }}

                <v-tooltip top>
                  <template #activator="{ attrs, on }">
                    <v-icon v-bind="attrs" v-on="on" small>
                      {{ types[item.type].icon }}
                    </v-icon>
                  </template>
                  <span>
                    {{ types[item.type].text }}
                  </span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <Pagination
          v-if="totalPages > 1"
          type="autocomplete"
          :page.sync="page"
          :pageSize="pageSize"
          :blockSize="blockSize"
          :totalElements="totalElements"
          @pageClick="clickPage"
          class="pagingArea"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { getEmails } from "@/commons/api/autocomplete.api";
import FlatTextInput from "@/calendar/components/common/FlatTextInput.vue";
import i18n from "@/_locales";
import { isEmail } from "@/commons/utils/validation";
import Pagination from "@/commons/views/Pagination.vue";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";

let timeout;

export default {
  components: { Pagination, FlatTextInput },
  props: {
    value: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: ""
    },
    enableEmailDirectAddition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorMessage: null,
      search: "",
      searchedItems: [],
      searchSelection: null,
      loading: false,
      page: 1,
      blockSize: 3,
      totalPages: 1,
      totalElements: 0,
      types: {
        MEMBER: {
          text: i18n.t("common.조직도"),
          icon: "mdi-sitemap-outline"
        },
        RECENT: {
          text: i18n.t("common.최근"),
          icon: "mdi-history"
        },
        CONTACT: {
          text: i18n.t("common.주소록"),
          icon: "mdi-card-account-phone-outline"
        },
        CUSTOMER: {
          text: i18n.t("common.공용주소록"),
          icon: "mdi-account-box-multiple"
        }
      }
    };
  },
  watch: {
    search() {
      this.errorMessage = null;
      this.searchSelection = null;
      this.page = 1;
      this.searchAutoComplete();
    }
  },
  methods: {
    searchAutoComplete() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      if (!this.search) {
        this.searchedItems = [];
        this.loading = false;
        return;
      }

      this.loading = true;
      timeout = setTimeout(() => {
        this.fetchAutoComplete();
      }, 200);
    },
    async fetchAutoComplete() {
      const { data } = await getEmails({
        page: this.page - 1,
        pageSize: this.pageSize,
        query: this.search,
        typeList: Object.keys(this.types)
      });
      const { content, totalPages, totalElements } = data;

      this.searchedItems = content;
      if (this.searchedItems.length) {
        this.searchSelection = 0;
      }

      this.totalPages = totalPages;
      this.totalElements = totalElements;
      this.loading = false;
      this.$refs.searchInput.$refs.input.focus();
    },
    searchKeydown(e) {
      switch (e.key) {
        case "Enter": {
          e.preventDefault();
          if (this.searchSelection !== null) {
            this.addSelectedAttendee(this.searchedItems[this.searchSelection]);
          } else if (this.enableEmailDirectAddition) {
            this.addAttendeeByEmail(this.search);
          }
          break;
        }
        case "ArrowUp": {
          e.preventDefault();
          if (this.searchSelection > 0) {
            this.searchSelection--;
          }
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          if (this.searchSelection === null) {
            this.searchSelection = 0;
          } else if (this.searchSelection < this.searchedItems.length - 1) {
            this.searchSelection++;
          }
          break;
        }
        case "Escape": {
          e.preventDefault();
          this.resetSearch();
          break;
        }
      }
    },
    clickPage() {
      this.loading = true;

      this.resetSearchSelection();
      this.fetchAutoComplete();
    },
    addSelectedAttendee(item) {
      if (this.exists(item.email)) {
        this.resetSearch();
        return;
      }

      const attendeeValue = `${item.name && `"${item.name}" `}<${item.email}>`;
      const attendee = {
        key: this.value[this.value.length - 1]?.key + 1 || 0,
        value: attendeeValue,
        ...item,
        partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
        valid: isEmail(attendeeValue)
      };

      this.$emit("input", [...this.value, attendee]);
      this.resetSearch();
    },
    addAttendeeByEmail(email) {
      if (!isEmail(email)) {
        this.errorMessage = this.$t("calendar.49");
        return;
      }

      if (this.exists(email)) {
        this.resetSearch();
        return;
      }

      this.$emit("input", [
        ...this.value,
        {
          key: this.value[this.value.length - 1]?.key + 1 || 0,
          value: email,
          email,
          userId: 0,
          partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
          valid: true
        }
      ]);
      this.resetSearch();
    },
    exists(email) {
      return this.value.some(item => item.email === email);
    },
    resetSearch() {
      this.search = "";
      this.searchedItems = [];
      this.resetSearchSelection();
      this.page = 1;
      this.totalPages = 1;
      this.totalElements = 0;
      this.loading = false;
    },
    resetSearchSelection() {
      this.searchSelection = null;
      if (this.$refs.searchList) {
        this.$refs.searchList.$el.scrollTop = 0;
      }
    },
    makeJobSummary(item) {
      const { jobTitle, jobGrade, organization } = item;
      let result = "";

      if (organization) result += ` ${organization}`;
      if (jobGrade) result += ` / ${jobGrade}`;
      if (jobTitle) result += ` / ${jobTitle}`;

      return result.length ? " · " + result : "";
    }
  }
};
</script>

<style scoped lang="scss">
.v-list-item {
  min-height: 40px !important;
  padding: 0 12px;
}

::v-deep .autocp-pagination {
  padding: 0;
  margin-top: 4px;
  border: none;
}
</style>
