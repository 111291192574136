<template>
  <v-container
    fill-height
    fluid
    class="pa-0 login_container"
    :class="{ 'mobile-frame': $vuetify.breakpoint.xs }"
  >
    <div class="cr_progress">
      <v-progress-linear
        color="primary"
        height="8"
        striped
        indeterminate
        v-if="isLoading"
      ></v-progress-linear>
    </div>

    <!-- 배경 이미지 -->
    <div class="login_background">
      <div class="wall left"></div>
      <div class="wall right"></div>
      <div class="wall bottom"></div>
      <div class="human"></div>
    </div>

    <!-- 입력 상자 -->
    <div class="login_wrapper">
      <v-select
        class="text-caption cr-lang-selector"
        background-color="grey lighten-3"
        dense
        color="dark"
        filled
        solo
        flat
        hide-details
        item-text="name"
        item-value="value"
        :items="getLanguageList"
        @change="changeLanguage"
        v-model="selectedLanguage"
      ></v-select>
      <div class="cr_form">
        <div align="center">
          <div class="text-h4 mb-16 login_logo">CRINITY</div>
          <v-card class="mx-auto" max-width="360" elevation="0">
            <div class="pa-0">
              <v-card-text class="pa-0">
                <div class="cr_label mb-2" v-text="$t('login.Email')"></div>
                <v-text-field
                  autofocus
                  required
                  solo
                  flat
                  background-color="#F3F6F9"
                  ref="_id"
                  v-model="email"
                  :error="error.email"
                  :disabled="isLoading"
                  @keydown.enter="login"
                ></v-text-field>

                <div
                  class="cr_label mt-2 mb-2"
                  v-text="$t('login.Password')"
                ></div>
                <v-text-field
                  solo
                  flat
                  background-color="#F3F6F9"
                  ref="_pw"
                  v-model="password"
                  type="password"
                  :error="error.password"
                  :disabled="isLoading"
                  @keydown.enter="login"
                ></v-text-field>

                <v-checkbox
                  color="primary"
                  class="mt-0 mb-8"
                  v-model="remember"
                  :disabled="isLoading"
                  :label="$t('login.Remember')"
                ></v-checkbox>
              </v-card-text>

              <v-card-actions class="mb-10 pa-0">
                <v-btn
                  block
                  depressed
                  height="48"
                  color="primary text-h6 login-btn"
                  :loading="isLoading"
                  @click="login"
                  v-text="$t('login.로그인')"
                >
                </v-btn>
              </v-card-actions>
            </div>
            <div class="d-inline-flex px-2">
              <div class="mx-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.crinity.apps.cts_app"
                  target="_blank"
                >
                  <img src="../../assets/badge_play_store.png" />
                </a>
              </div>
              <div class="mx-2">
                <a
                  href="https://apps.apple.com/kr/app/sirteam/id1624866932"
                  target="_blank"
                >
                  <img src="../../assets/badge_app_store.png" />
                </a>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <OTPDialog :dialog.sync="showOTPDialog" :confirm="login" />
  </v-container>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import OTPDialog from "@/commons/views/login/OTPDialog";
import RSAKey from "@/commons/utils/rsa/rsa.js";
import storage from "@/commons/api/storage";
export default {
  components: {
    OTPDialog
  },
  data() {
    i18n.locale = navigator?.language?.toLowerCase()?.substring(0, 2) || "ko";

    return {
      email: "",
      password: "",
      remember: false,
      error: {
        email: false,
        password: false
      },
      showOTPDialog: false,
      rsa: new RSAKey(),
      selectedLanguage: i18n.locale
    };
  },
  created() {
    this.SET_SHOW_SPLASH(false);
    this.SET_COMAPNY_INFO({});
  },
  computed: {
    ...mapGetters("auth", [
      "isLoading",
      "getLanguageList",
      "getFirstScreenModule",
      "getUserInfoInit"
    ])
  },
  async mounted() {
    const { data = {}, status = 500 } = await this.authKey();
    if (status == 200) {
      this.rsa.setPublic(data.modulus, data.exponent);
    }

    this.email = storage.getUsername();
    this.remember = !isBlank(this.email);
    if (this.remember) {
      this.$refs._pw.focus();
    }
  },
  methods: {
    ...mapActions("auth", ["loginAction", "authKey", "initUserInfo"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("auth", ["SET_COMAPNY_INFO"]),
    ...mapMutations("splash", ["SET_SPLASH_IMAGE", "SET_SHOW_SPLASH"]),
    async login(authentication_code) {
      const { email, password } = this;
      this.error.email = false;
      this.error.password = false;
      this.showOTPDialog = false;

      if (isBlank(email) || email.indexOf("@") == -1) {
        this.error.email = true;
        this.$refs._id.focus();
        this.openSnackbar({
          message: isBlank(email) ? i18n.t("login.1") : i18n.t("login.2")
        });
        return;
      }

      if (isBlank(password)) {
        this.error.password = true;
        this.$refs._pw.focus();
        this.openSnackbar({
          message: i18n.t("login.3")
        });
        return;
      }

      if (typeof authentication_code != "string") {
        authentication_code = "";
      }

      storage.setUsername(this.remember ? email : "");
      let response = await this.loginAction({
        email: this.rsa.encrypt(email),
        password: this.rsa.encrypt(password),
        authentication_code: this.rsa.encrypt(authentication_code),
        grant_type: this.rsa.encrypt("password")
      });

      if (response.status != 200) {
        this.errorAction(response);
      } else {
        if (!this.getUserInfoInit)
          await this.initUserInfo({ routeName: this.$route.name });
        this.SET_SHOW_SPLASH(true);
        this.SET_SPLASH_IMAGE(this.getFirstScreenModule.toLowerCase());
        this.$router.push({ name: this.getFirstScreenModule.toLowerCase() });
      }
    },
    errorAction(response) {
      // OTP 팝업
      if (response.data.error === "require_credentials") {
        this.showOTPDialog = true;
        return;
      }

      // 인증 오류
      this.$refs._pw.focus();

      this.error.email = true;
      this.error.password = true;
      setTimeout(() => {
        this.error.email = false;
        this.error.password = false;
      }, 1500);

      const message = i18n.t("login.4");
      this.openSnackbar({
        message: message
      });
    },
    changeLanguage(lang) {
      i18n.locale = lang;
    }
  }
};
</script>

<style lang="scss" scoped>
.login_container {
  overflow: auto;
  min-width: 540px;
  background-color: #b1dced;
}

/* 프로그래스 */
.cr_progress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

/* 배경 이미지 */
.login_background {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;

  .wall {
    position: absolute;
    height: 100%;
    &.right {
      right: 0px;
      width: 485px;
      background-color: #f59640;
    }
    &.left {
      left: 0px;
      right: 487px;
      background-color: #ffd7b3;
    }
    &.bottom {
      position: absolute;
      top: 320px;
      right: 485px;
      width: 0px;
      height: 0px;
      border-right: 2000px solid white;
      border-top: 1000px solid transparent;
      border-bottom: 1000px solid transparent;
      &:after {
        position: absolute;
        top: -1000px;
        left: 2000px;
        content: "";
        border-left: 2000px solid white;
        border-top: 1000px solid transparent;
        border-bottom: 1000px solid transparent;
      }
    }
  }
  .human {
    position: absolute;
    top: 130px;
    right: 0;
    width: 1000px;
    height: 750px;
    background-repeat: no-repeat;
    background-image: url("../../assets/login-bg.png");
  }
}

/* 입력 상자 */
.login_wrapper {
  position: relative;
  height: 100%;
  background-color: #fff;
  align-items: center;
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  /* 언어 select */
  .cr-lang-selector ::v-deep {
    right: 12px;
    top: 12px;
    width: 170px;
    position: absolute;
    .v-input__control .v-input__slot {
      padding-right: 12px;
      min-height: 35px;
    }
  }

  /* 로고 */
  .login_logo {
    width: 121px;
    height: 68px;
    font-size: 38px !important;
    font-family: Trade Gothic, sans-serif !important;
    letter-spacing: 2px !important;
    font-weight: bold !important;
    background-image: url("../../assets/logo-login.png");
    text-indent: -1000000px;
  }

  /* 버튼 */
  .login-btn {
    font-size: 18px !important;
  }

  .cr_form {
    width: 540px;
  }
  .cr_label {
    color: #181c32;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
    font-family: sans-serif;
  }
}

/* 모바일 */
.mobile-frame {
  min-width: auto;
  .login_img {
    display: none;
  }
  .login_wrapper {
    width: 100%;
    .cr_form {
      width: auto;
      min-width: 300px;
      margin: 0px auto;
    }
  }
}
</style>
