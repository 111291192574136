import flowTasks from "./modules/tasks";
import flowRoute from "./modules/route";
import flowDialog from "./modules/dialog";
import flowFolder from "./modules/folder";
import flowProjects from "./modules/projects";
import flowLayout from "./modules/layout";

export default {
  flowTasks,
  flowRoute,
  flowDialog,
  flowFolder,
  flowProjects,
  flowLayout
};
