import {
  getPostList,
  movePost,
  deletePostPermanently
} from "@/board/api/board.api";
import { virtualBoardConverter } from "@/board/constant/boardType";

import i18n from "@/_locales";

const state = {
  loading: false,
  page: 1,
  totalPages: 0,
  totalElements: 0,
  postList: [],
  selected: [],
  sort: "deletedTimeMillis",
  desc: true
};
const getters = {
  getLoading: ({ loading }) => loading,
  getTrashList: ({ postList }) => postList,
  getSelected: ({ selected }) => selected,
  getPageable: ({ page, totalPages, totalElements, sort, desc }) => ({
    page,
    totalPages,
    totalElements,
    sort,
    desc
  })
};
const mutations = {
  INIT: state => {
    state.loading = false;
    state.page = 1;
    state.totalPages = 0;
    state.totalElements = 0;
    state.postList = [];
    state.selected = [];
    state.sort = "deletedTimeMillis";
    state.desc = true;
  },
  SET_TRASH_LIST: (state, data) => {
    const { content: list, number: page, totalElements, totalPages } = data;
    state.postList = list;
    state.page = page;
    state.totalElements = totalElements;
    state.totalPages = totalPages;
  },
  SET_SELECTED: (state, selected) => {
    state.selected = selected;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_DESC: (state, desc) => {
    state.desc = desc;
  }
};
const actions = {
  // 게시글 검색 :page startNum = 1
  async loadTrashList({ commit, dispatch, getters }, page) {
    commit("SET_LOADING", true);
    const { sort, desc } = getters.getPageable;
    const { status, data } = await getPostList({
      id: virtualBoardConverter("trash"),
      page: page - 1,
      pageSize: this.getPostPageSize,
      sort: sort === "deleteUserName" ? "deleteUser.id" : sort,
      dir: desc ? "desc" : "asc",
      contentSize: -1
    });

    switch (status) {
      case 200:
        {
          // 리스트 반영
          commit("SET_TRASH_LIST", data);
        }
        break;
      case 403:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.18"), type: "ERROR" },
          { root: true }
        );
        break;
      default:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.19"), type: "ERROR" },
          { root: true }
        );
        break;
    }

    commit("SET_SELECTED", []);
    commit("SET_LOADING", false);
  },

  // 게시글 복구(이동)
  async moveSelectedPost({ commit, getters, dispatch }, boardId) {
    const params = {
      boardId,
      postIds: getters.getSelected.map(p => p.id)
    };

    // zero index라서 +1 보정
    const page = getters.getPageable.page + 1;
    const { status } = await movePost(params);
    switch (status) {
      case 200:
        {
          dispatch(
            "snackbar/openSnackbar",
            { message: i18n.t("board.144"), type: "SUCCESS" },
            { root: true }
          );
          // 리스트 갱신
          dispatch("loadTrashList", page);
        }
        break;
      case 403:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.8"), type: "ERROR" },
          { root: true }
        );
        break;
      default:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.145"), type: "ERROR" },
          { root: true }
        );
        break;
    }

    commit("boardDialog/CLOSE_DIALOG", "", { root: true });
  },

  // 게시글 영구삭제
  async deleteSelectedPost({ commit, getters, dispatch }) {
    commit("SET_LOADING", true);

    const postIds = getters.getSelected.map(p => p.id);
    const { status } = await deletePostPermanently(postIds);

    // zero index라서 +1 보정
    const page = getters.getPageable.page + 1;
    switch (status) {
      case 200:
        {
          dispatch(
            "snackbar/openSnackbar",
            { message: i18n.t("board.147"), type: "SUCCESS" },
            { root: true }
          );
          // 리스트 갱신
          dispatch("loadTrashList", page);
        }
        break;
      case 403:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.8"), type: "ERROR" },
          { root: true }
        );
        break;
      default:
        dispatch(
          "snackbar/openSnackbar",
          { message: i18n.t("board.32"), type: "ERROR" },
          { root: true }
        );
        break;
    }

    commit("boardDialog/CLOSE_DIALOG", "", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
