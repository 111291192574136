<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <!-- 버튼 -->
    <div class="pa-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
      <v-btn class="primary" block large depressed @click="openFileInput">
        <h4 v-text="$t('drive.55')"></h4>
      </v-btn>
      <input
        multiple
        type="file"
        ref="fileInput"
        class="cr-file-input"
        @change="changeFileInput"
      />
    </div>
    <!-- 편지함 트리 -->
    <TreeView></TreeView>
    <!-- 사용자 용량 정보-->
    <UserQuotaBar />
  </v-navigation-drawer>
</template>

<style scoped>
/* 파일 업로드 input tag */
.cr-file-input {
  display: none;
}
</style>
<script>
import i18n from "@/_locales";
import { mapGetters, mapActions } from "vuex";
import TreeView from "@/drive/views/components/treeview/TreeView";
import UserQuotaBar from "@/commons/views/UserQuotaBar";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  components: {
    TreeView,
    UserQuotaBar
  },
  data: () => ({
    drawer: true
  }),
  computed: {
    ...mapGetters("drive", ["getActiveItems"])
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("driveFile", ["setFiles"]),
    byteCalculation(size) {
      return byteCalculation(size);
    },
    // 파일 선택창 띄우기
    openFileInput() {
      if (
        this.getActiveItems[0].fileType === "DELETED" ||
        this.getActiveItems[0].fileType === "RECENT" ||
        this.getActiveItems[0].fileType === "IMPORTANT" ||
        this.getActiveItems[0].fileType === "SHARE"
      ) {
        this.openSnackbar({
          message: i18n.t("drive.56"),
          type: "VALIDATION"
        });
        return;
      }
      this.$refs.fileInput.click();
    },

    async changeFileInput(e) {
      const files = e.target.files;
      /* 유효성 검사 */
      // 1. 파일이 있는지 확인
      if (!files) return;

      // 유효성 검사 후 파일 세팅
      await this.setFiles({ files });
      this.$refs.fileInput.value = "";
    }
  }
};
</script>
