import http from "@/commons/api/http";

export const fetchExternalCalendars = (connectionId, sync) => {
  return http.get("/api/external/calendars", {
    params: {
      connectionId,
      sync
    }
  });
};

export const updateExternalCalendar = (calendarId, value) => {
  return http.patch(`/api/external/calendars/${calendarId}`, {
    selected: value
  });
};

export const fetchExternalEvents = (connectionId, startDt, endDt, ids) => {
  return http.get("/api/external/calendars/events", {
    params: {
      connectionId,
      startDt,
      endDt,
      ids
    }
  });
};
