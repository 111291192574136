<template>
  <div class="cr-mail-view-attach-wrapper">
    <div class="d-flex align-center flex-wrap cr-attach-label pb-2">
      <div class="d-flex align-center">
        <v-btn
          text
          x-small
          plain
          :ripple="false"
          class="cr-attach-expand-btn"
          @click="showAttachList = !showAttachList"
        >
          <v-icon class="cr-view-print-hide">
            {{ showAttachList ? "mdi-chevron-down" : "mdi-chevron-right" }}
          </v-icon>
        </v-btn>
        <span> {{ $t("mail.159", { value: items.length }) }} </span>
        <span class="cr-attach-size">{{ `(${totalSize})` }}</span>
      </div>
      <a
        :download="'all.zip'"
        class="cr-attach-save cr-view-print-hide"
        v-text="$t('mail.160')"
        @click.prevent="attachDownload"
      />
    </div>
    <v-list v-show="showAttachList" outlined>
      <v-list-item dense v-for="(item, idx) in attachs" :key="idx">
        <v-list-item-content>
          <div
            class="d-flex align-center justify-space-between"
            style="min-width: 0px;"
          >
            <div
              :style="`min-width: 0px; ${item.size > 0 ? '' : 'opacity: 0.6'}`"
              class="d-flex align-center"
            >
              <v-icon
                class="cr-view-print-hide"
                :color="getIcon(item.name).color"
              >
                {{ getIcon(item.name).icon }}
              </v-icon>
              <div style="min-width: 0px;">
                <a
                  v-if="item.size > 0"
                  class="d-flex align-center ml-1"
                  :download="item.name"
                  @click.prevent="attachDownload"
                >
                  <FileName :item="item" />
                </a>
                <div v-if="item.size > 0" class="size grey--text mt-1 ml-1">
                  {{ byteCalculation(item.size) }}
                </div>
              </div>

              <!-- 삭제된 파일 -->
              <div
                v-if="item.size == 0"
                class="d-flex align-center"
                style="min-width: 0px;"
              >
                <span
                  class="text-truncate"
                  style="cursor: default; text-decoration: line-through; min-width: 0px;"
                >
                  {{ item.name }}
                </span>
                <span style="flex-shrink: 0;" class="mr-2">
                  .{{ item.ext }}
                </span>
              </div>
            </div>

            <!-- 다운로드 버튼 -->
            <div style="min-width: 24px;" class="cr-view-print-hide">
              <v-tooltip
                v-if="item.size > 0"
                top
                :disabled="$vuetify.breakpoint.xs"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    x-small
                    plain
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    class="cr-attach-list-btn download"
                    @click="attachDownload"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <div
                  class="d-flex justify-center caption"
                  v-text="$t('common.저장')"
                ></div>
              </v-tooltip>
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.cr-mail-view-attach-wrapper {
  max-width: 600px;
  margin: 20px 0px 30px 0px;

  .cr-attach-label {
    font-size: 14px;

    * {
      padding: 0;
      margin: 0;
    }

    .cr-attach-save {
      padding-left: 7px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .cr-attach-expand-btn {
      padding: 0px;
      min-width: 24px;
    }
  }

  .cr-attach-list-btn {
    padding: 0px;
    min-width: 24px;

    &.download {
      .v-icon.v-icon {
        font-size: 20px;
        margin-top: 2px;
      }
    }
  }

  .v-list ::v-deep {
    .v-list-item__content {
      * {
        padding: 0;
        margin: 0;
      }
    }

    a {
      text-decoration: none;
      color: #333;
    }

    .size {
      font-size: 13px;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import FileName from "@/mail/views/components/main/commons/FileName";

export default {
  components: { FileName },
  props: {
    items: {
      default: () => [],
      type: Array
    }
  },
  data: () => ({
    showAttachList: true
  }),
  computed: {
    ...mapGetters("folder", ["isShare"]),
    ...mapGetters("mailRoute", ["getRouteShareInfo", "getRouteViewInfo"]),
    attachs() {
      return this.items.map(i => {
        const ext = i.name
          .split("?")[0]
          .split("#")[0]
          .split(".")
          .pop();
        const name = i.name.slice(0, i.name.length - 1 - ext.length);
        return { ...i, name, ext };
      });
    },
    totalSize() {
      if (this.items.length == 0) return byteCalculation(0);
      return byteCalculation(
        this.items.reduce((accumulator, currentValue) => ({
          size: accumulator.size + currentValue.size
        })).size
      );
    }
  },
  methods: {
    byteCalculation(size) {
      return byteCalculation(size);
    },
    async attachDownload() {
      // 구현 필요
    },
    getIcon(name) {
      const index = name.lastIndexOf(".");
      const ext = name.substring(index + 1, name.length).toLowerCase();

      return {
        icon: fileIcons[ext] || fileIcons["file"],
        color: fileIconColors[ext] || fileIconColors["file"]
      };
    }
  }
};
</script>
