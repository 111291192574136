var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"absolute":"","position-y":_vm.$vuetify.breakpoint.xs ? 56 : 76},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var settingMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$vuetify.breakpoint.xs},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, settingMenu)),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('common.사용자_정보'))}})])]}}])},[_c('v-card',[_c('v-list',[_c('v-list-item',{staticClass:"cr-user-contents-wrapper"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold text-subtitle-1 mb-1",domProps:{"textContent":_vm._s(_vm.getUserInfo.displayName)}}),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2 font-weight-regular",domProps:{"textContent":_vm._s(_vm.getUserInfo.username)}})],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"cr-language-menu-wrapper align-center"},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"px-2"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('common.언어'))}})],1),_c('v-list-item-action',{staticClass:"ma-0 text-caption rounded px-3 py-1",domProps:{"textContent":_vm._s(_vm.selectedLanguageName)}})],1)]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}},_vm._l((_vm.getLanguageList),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":item.value},on:{"click":function($event){return _vm.changeLang(item.value)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)}),1)],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ name: 'account', params: { tabType: 'info' } })}}},[_c('v-list-item-title',{staticClass:"px-2",domProps:{"textContent":_vm._s(_vm.$t('common.계정_설정'))}})],1),(_vm.isAdmin)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.gotoAdmin}},[_c('v-list-item-title',{staticClass:"px-2",domProps:{"textContent":_vm._s(_vm.$t('common.관리자_바로가기'))}})],1):_vm._e()],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-title',{staticClass:"px-2",domProps:{"textContent":_vm._s(_vm.$t('common.로그아웃'))}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }