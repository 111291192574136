import http from "@/commons/api/http";

/**
 * 기본 주소록 그룹 리스트 조회
 * @returns
 */
const getDefaultContactGroupList = () => http.get("api/contact/group");

/**
 * 연락처 리스트 조회
 *
 * @param {*} params
 * @returns
 */
const getCardList = params => http.get("api/contact/card", { params });

/**
 * 기본 조직도 그룹 리스트 조회
 * @returns
 */
const getDefaultOrganList = (includeCount = false) =>
  http.get("api/organizations", { params: { includeCount } });

/**
 * 유저의 대표조직 조회
 * @returns
 */
const getUserOrgan = () => http.get("api/organizations/user/representative");

/**
 * 조직도 하위 조직 리스트 조회
 * @param {*} parentId  -- 상위 조직 id
 * @param {*} includeCount  -- 구성원 수 포함 여부
 * @returns
 */
const getOrganListByParentId = ({ parentId, includeCount = false }) =>
  http.get(`api/organizations/${parentId}/children`, {
    params: { includeCount }
  });

/**
 * 조직도 유저 리스트 조회
 * @param {*} params
 * @returns
 */
const getOrganUserList = ({ organId, pageSize, page, searchWord }) => {
  return http.get(`api/users/organization/${organId}`, {
    params: { pageSize, page, searchWord }
  });
};

/**
 * 특정 입사일자 유저 조회
 * @param {*} params
 * @returns
 */
const getUserListWithJoinTime = ({ pageSize, page, searchTime }) => {
  return http.get(`api/users/findByJoinTime`, {
    params: { pageSize, page, searchTime }
  });
};

export {
  getDefaultContactGroupList,
  getCardList,
  getDefaultOrganList,
  getOrganListByParentId,
  getOrganUserList,
  getUserOrgan,
  getUserListWithJoinTime
};
