<template>
  <v-dialog
    max-width="400px"
    :value="value"
    @keydown.enter="confirm"
    @keydown.esc="cancel"
    @click:outside="cancel"
    @input="val => $emit('input', val)"
  >
    <v-card>
      <v-card-title>
        <span v-if="recurConfirm.isRecurring">
          {{ recurConfirm.title }}
        </span>
        <span v-else class="text-subtitle-1">
          {{ recurConfirm.title }}
        </span>
      </v-card-title>
      <v-card-text v-if="recurConfirm.isRecurring">
        <v-radio-group
          v-model="isExceptionRequest"
          column
          hide-details
          mandatory
        >
          <v-radio :value="false">
            <template #label>
              <span>
                {{ $t("calendar.recur.confirm.all") }}
              </span>
              <v-tooltip v-if="isUpdateMode" top color="info">
                <template #activator="{ attrs, on }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    dense
                    class="ml-1"
                    color="info"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("calendar.recur.confirm.updateAllWarning") }}</span>
              </v-tooltip>
            </template>
          </v-radio>
          <v-radio
            :label="$t('calendar.recur.confirm.only')"
            :value="true"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="cancel">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="accent" @click="confirm">
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    recurConfirm: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isExceptionRequest: false
  }),
  computed: {
    isUpdateMode() {
      return this.recurConfirm.type === "update";
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.$emit("input", false);
    },
    confirm() {
      this.$emit("confirm", this.isExceptionRequest);
      this.$emit("input", false);
    }
  }
};
</script>
