import http from "@/commons/api/http";

const prefix = "/api/announce";

/**
 * 업데이트 내역 조회
 * @param {*} param0
 * @returns
 */
const getList = ({ page = 0, pageSize = 20 }) =>
  http.get(`${prefix}/list`, {
    params: {
      page,
      pageSize
    }
  });

/**
 * 해당 업데이트 내역 상세보기
 * @param {*} id
 * @returns
 */
const getView = id => http.get(`${prefix}/${id}`);

/**
 * 마지막 로그인 시간 기준 최신 업데이트 내역 조회
 * @param {*} id
 * @returns
 */
const getRecentView = timeMillis => {
  return http.get(`${prefix}/recent`, {
    params: { timeMillis }
  });
};

/**
 * 확인하지 않은 업데이트 내역 있는지 여부
 * @returns
 */
const getRecentUpdated = () => http.get(`${prefix}/recent/isUpdated`);

export { getList, getView, getRecentView, getRecentUpdated };
