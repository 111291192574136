import Vue from "vue";
import {
  findResourceTypes,
  findCompanyResources,
  findBookingList
} from "@/resource/api/resource.api";
import i18n from "@/_locales";

function customSort(a, b) {
  const order = ["MEETING_ROOM", "CAR", "EQUIPMENT"];
  const typeA = a.type;
  const typeB = b.type;
  const indexA = order.indexOf(typeA);
  const indexB = order.indexOf(typeB);

  if (indexA < indexB) {
    return -1;
  } else if (indexA > indexB) {
    return 1;
  } else {
    return 0;
  }
}

const state = {
  types: [],
  typeIcons: {
    ALL: "mdi-check-all",
    MEETING_ROOM: "mdi-door-open",
    CAR: "mdi-car",
    EQUIPMENT: "mdi-package"
  },
  resources: [],
  activeItems: [],
  openItems: [],
  currentDate: "",
  currentType: "",
  showLoadingOverlay: false
};

const getters = {
  getTypes: ({ types }) => types,
  getActualTypes: ({ types }) => {
    if (state.currentType != "ALL") {
      types = types.filter(type => type.value == state.currentType);
    }
    return types.filter(type => type.value != "ALL");
  },
  getTypeIcons: ({ typeIcons }) => typeIcons,
  getResources: ({ resources }) => resources,
  getResourcesByType: ({ resources }) => type =>
    resources.filter(resource => resource.type === type),
  getCurrentType: ({ currentType }) => currentType,
  getCurrentDate: ({ currentDate }) => currentDate,
  getActiveItems: ({ activeItems }) => activeItems,
  getShowLoadingOverlay: ({ showLoadingOverlay }) => showLoadingOverlay
};

const mutations = {
  SET_TYPES: (state, types) => (state.types = types),
  SET_RESOURCES: (state, resources) => (state.resources = resources),
  SET_DATA: (state, data) => {
    state.currentDate = data.date;
    state.currentType = data.type;
    state.activeItems.length = 0;
    state.activeItems.push(data.type); // tree 업데이트를 위해
  },
  SET_CURRENT_DATE: (state, currentDate) => (state.currentDate = currentDate),
  SET_ACTIVE_ITEM: (state, activeItems) => (state.activeItems = activeItems),
  SET_SHOW_LOADING_OVERLAY: (state, showLoadingOverlay) =>
    (state.showLoadingOverlay = showLoadingOverlay),
  SET_FOLD_RESOURCE: (state, { value, isFolded }) => {
    const idx = state.types.findIndex(type => type.value == value);
    Vue.set(state.types[idx], "isFolded", isFolded);
  }
};

const actions = {
  // 시설/설비 유형 데이터 로드
  async loadTypes({ commit, dispatch }) {
    const { status, data = [] } = await findResourceTypes();

    if (status != 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.1"), type: "ERROR" },
        { root: true }
      );
      return;
    }
    let types = data.map(value => {
      return {
        title: i18n.t(`resource.${value}`),
        value: value,
        isFolded: false
      };
    });
    //const defaultType = { title: i18n.t(`resource.ALL`), value: "ALL" };
    //types.unshift(defaultType);
    await commit("SET_TYPES", types);
  },
  async loadResourceData({ commit, dispatch, state }) {
    const { status, data = [] } = await findCompanyResources({
      searchDate: state.currentDate,
      type: state.currentType
    });

    if (status != 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("resource.1"), type: "ERROR" },
        { root: true }
      );
      return;
    }

    data.sort(customSort);
    /*
    let checkType = "";
    let resources = [];
    for (const item of data) {
      if (checkType == "" || checkType != item.type) {
        const resourceHeader = {
          name: i18n.t(`resource.${item.type}`),
          type: "ITEM_HEADER",
          category: item.type
        };
        resources.push(resourceHeader);
      }
      checkType = item.type;
      item.category = item.type;
      item.visibility = true;
      resources.push(item);
    }
    */
    await commit("SET_RESOURCES", data);
  },
  async searchDate({ commit, dispatch }, { searchDate }) {
    await commit("SET_CURRENT_DATE", searchDate);
    await dispatch("loadResourceData");
  },
  async findResourceById({ state }, { resourceId }) {
    const resource = state.resources.find(
      resource => resource.id == resourceId
    );
    return resource;
  },
  async fetchBookingList({ dispatch }, { resourceId, searchDate }) {
    const { status, data = [] } = await findBookingList({
      searchDate: searchDate,
      resourceId: resourceId
    });

    if (status != 200 || !data) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("resource.1"), type: "ERROR" },
        { root: true }
      );
      return;
    }
    return data;
  },
  async startLoading({ commit }) {
    await commit("SET_SHOW_LOADING_OVERLAY", true);
  },
  async endLoading({ commit }) {
    await commit("SET_SHOW_LOADING_OVERLAY", false);
  },
  async foldResource({ commit }, { value, isFolded }) {
    await commit("SET_FOLD_RESOURCE", { value, isFolded });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
