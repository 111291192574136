const state = {
  native: false,
  show: false,
  timeout: 5000,
  title: "",
  body: "",
  img: "",
  click: () => {},
  pid: ""
};

const getters = {
  isShow: ({ show }) => show,
  getTimeOut: ({ timeout }) => timeout,
  getTitle: ({ title }) => title,
  getBody: ({ body }) => body,
  getImage: ({ img }) => img,
  getClick: ({ click }) => click
};

// state값 수정
const mutations = {
  INIT_TYPE: (state, { isNative }) => {
    state.native = isNative;
  },

  INIT: (state, { title, body, img, click, show }) => {
    state.title = title;
    state.body = body;
    state.img = img;
    state.click = click;
    state.show = show;
  },

  SET_PID: (state, { pid }) => {
    state.pid = pid;
  },
  SET_SHOW: (state, show) => (state.show = show),
  HIDE: state => (state.show = false)
};

// mutations 호출 및 비동기 처리
const actions = {
  async hideNotification({ commit, state }) {
    commit("HIDE");
    if (state.pid) {
      state.pid.close();
    }
  },
  async showNotification(
    { commit, dispatch, state },
    { title = "", body = "", img = "", click = () => {} }
  ) {
    if (window.Notification) {
      // 브라우저 알림 지원 여부 확인
      if (window.Notification.permission === "granted") {
        await commit("INIT_TYPE", {
          isNative: true
        });
      } else if (window.Notification.permission === "denied") {
        await commit("INIT_TYPE", {
          isNative: false
        });
      } else {
        window.Notification.requestPermission().then(permission => {
          commit("INIT_TYPE", {
            isNative: permission == "granted"
          });
        });
      }
    }

    // 알림
    dispatch(
      state.native === true ? "_chromeNotifition" : "_scriptNotifition",
      {
        title: title,
        body: body,
        img: img,
        click: click
      }
    );
  },

  // Javascript 알림
  async _scriptNotifition(
    { commit },
    { title = "", body = "", img = "", click = () => {} }
  ) {
    commit("INIT", {
      title: title,
      body: body,
      img: img,
      click: () => {
        click();
        commit("HIDE");
      },
      show: true
    });
  },

  // 브라우저 알림
  async _chromeNotifition(
    { commit, dispatch },
    { title = "", body = "", img = "", click = () => {} }
  ) {
    let notification = new window.Notification(title, {
      icon: img,
      body: body
    });
    notification.onclick = () => {
      click();
      dispatch("hideNotification");
    };
    commit("SET_PID", { pid: notification });
    dispatch("hideNotification");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
