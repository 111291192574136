<template>
  <div v-show="loading" :class="cls">
    <List
      v-if="!noContent"
      :listSelect="listSelect"
      :searchHistory="searchHistory"
      @deleteHistory="deleteHistory"
    />
    <NoContent v-else />
    <Footer @deleteHistory="deleteHistory" />
  </div>
</template>

<style lang="scss" scoped>
.cr-search-drop-wrapper {
  background: #fff;
  font-size: 17px;
  padding: 0px 0px 25px;

  &.no-content {
    padding: 13px 13px 36px;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";
import {
  getMailSearchHistory,
  deleteMailSearchHistory
} from "../../../../api/history.api";
import List from "./List.vue";
import NoContent from "./NoContent.vue";
import Footer from "./Footer.vue";

export default {
  components: { List, NoContent, Footer },
  props: {
    keyEvent: {
      type: Event,
      default: null
    }
  },
  mounted() {
    this.getMailSearchHistory();
  },
  data() {
    return {
      loading: false,
      listSelect: 0,
      searchHistory: []
    };
  },
  computed: {
    ...mapGetters("folder", ["getFolder"]),
    ...mapGetters("mailLayout", ["useSearchHistory"]),
    cls() {
      return {
        "no-content": this.noContent,
        "elevation-3 cr-search-drop-wrapper": true
      };
    },
    noContent() {
      return this.useSearchHistory && this.searchHistory.length === 0;
    }
  },
  watch: {
    keyEvent(e) {
      let maxIdx = this.searchHistory.length - 1;
      switch (e.key) {
        case "ArrowUp": {
          e.preventDefault();
          this.listSelect -= 1;
          if (this.listSelect < 0) this.listSelect = maxIdx;
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          this.listSelect += 1;
          if (this.listSelect > maxIdx) this.listSelect = 0;
          break;
        }
        case "Enter": {
          e.preventDefault();
          const { historyObj } = this.searchHistory[this.listSelect];
          this.search(historyObj);
          break;
        }
        case "Delete": {
          e.preventDefault();
          const { id } = this.searchHistory[this.listSelect];
          this.deleteHistory(id);
          break;
        }
      }
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailList"]),
    search(params) {
      const { useSearchHistory } = this;

      this.$parent.blur();
      this.routeMailList({
        name: "mail_list_action",
        page: 1,
        folderId: params.folderId,
        actionObj: {
          search: { type: "detail", ...params, useSearchHistory }
        }
      });
    },
    async getMailSearchHistory() {
      const { status, data } = await getMailSearchHistory();
      if (status !== 200 || !Array.isArray(data)) return;

      data.forEach(({ id, options: historyObj }) => {
        let items = [];
        const { type, folderId, timeRange } = historyObj;
        Object.keys(historyObj).forEach(option => {
          let searchField = "";
          let searchWord = historyObj[option];
          if (!searchWord) return;

          switch (option) {
            case "query":
              switch (type) {
                case "all.from.subject":
                  searchField = this.$t("mail.427");
                  break;
                case "all.from":
                  searchField = this.$t("mail.보낸사람");
                  break;
                case "all.to":
                  searchField = this.$t("mail.30");
                  break;
                case "all.content":
                  searchField = this.$t("mail.내용");
                  break;
                default:
                  searchField = this.$t("mail.전체");
                  break;
              }
              break;
            case "queryAttach":
              searchField = this.$t("mail.첨부파일");
              break;
            case "queryCc":
              searchField = this.$t("mail.참조");
              break;
            case "queryContent":
              searchField = this.$t("mail.내용");
              break;
            case "queryFrom":
              searchField = this.$t("mail.보낸사람");
              break;
            case "querySubjectContentAttach":
              searchField = this.$t("mail.31");
              break;
            case "querySubject":
              searchField = this.$t("mail.제목");
              break;
            case "querySubjectContent":
              searchField = this.$t("mail.33");
              break;
            case "queryTo":
              searchField = this.$t("mail.받는사람");
              break;
            case "queryToCc":
              searchField = this.$t("mail.30");
              break;
            case "timeRange": {
              let [start, end] = timeRange;
              if (!start && !end) break;

              start = new Date(start).getTime();
              start = start ? getDateWithoutTime(start, "YYYY-MM-DD") : "";
              end = new Date(end).getTime();
              end = end ? getDateWithoutTime(end, "YYYY-MM-DD") : "";
              historyObj.timeRange = [start, end];
              searchField = this.$t("mail.기간");
              searchWord = ` ${start} ~ ${end}`;
              break;
            }
            case "tags": {
              historyObj.tags = historyObj.tags?.join(",");
              if (historyObj.tags.length === 0) break;

              searchField = this.$t("mail.folder_TAG_ROOT");
              searchWord = historyObj.tags;
              break;
            }
            default:
              break;
          }

          if (!searchField) return;
          items.push({ searchWord, searchField });
        });

        if (items.length === 0) return;
        if (!Array.isArray(this.searchHistory)) this.searchHistory = [];

        const folder = this.getFolder(folderId);
        const nameFolder = folder ? folder.nameFolder : this.$t("mail.34");
        this.searchHistory.push({ id, items, historyObj, nameFolder });
      });
      this.loading = true;
    },
    async deleteHistory(id, isAll) {
      const { status } = await deleteMailSearchHistory(id, !!isAll);
      if (status !== 200) return;
      if (isAll) return (this.searchHistory = []);

      this.searchHistory = this.searchHistory.filter(h => h.id !== id);
    }
  }
};
</script>
