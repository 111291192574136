<template>
  <v-tab-item>
    <v-color-picker
      width="330"
      swatches-max-height="240"
      hide-canvas
      hide-mode-switch
      hide-sliders
      hide-inputs
      show-swatches
      v-model="_color"
    />
  </v-tab-item>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#b71c1c"
    }
  },
  computed: {
    _color: {
      get() {
        return this.color;
      },
      set(color) {
        this.$emit("update:color", color);
      }
    }
  }
};
</script>
