import http from "@/commons/api/http";

const prefix = "api/history";

/**
 * 최근 비밀번호 변경 기록
 * @returns 변경 기록
 */
const getRecentPwHistory = () => http.get(`${prefix}/pw/recent`);

/**
 * 계정 이력
 * @param {*} params
 * @returns
 */
const getAccountHistory = params => http.get(`${prefix}/account`, { params });

export { getRecentPwHistory, getAccountHistory };
