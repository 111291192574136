import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import store from "@/commons/store";
import { mapGetters, mapState } from "vuex";
import moment from "moment-timezone";
import {
  toLocalDateString,
  toLocalTimeString
} from "@/calendar/utils/DateUtils";

export const EMPTY_EVENT = {
  eventUId: "",
  iCalUID: "",
  summary: null,
  description: null,
  location: null,
  sequence: 0,
  priority: 0,
  isPublic: true,
  allDay: false,
  transp: "OPAQUE",
  tzId: null,
  startDate: "",
  startTime: "00:00",
  endDate: "",
  endTime: "00:00",
  resource: {
    resourceId: 0
  }
};

const EventViewMixin = {
  data() {
    return {
      ...EMPTY_EVENT
    };
  },
  computed: {
    ...mapState("cal", ["editEvent"]),
    ...mapGetters("cal", ["getTzId"]),
    isUpdateMode() {
      return this.editEvent && !this.editEvent.isCreating;
    }
  },
  watch: {
    // 일정 편집기가 열린 상태에서 다른 일시를 선택하는 경우 값을 반영합니다.
    "editEvent.start": function() {
      this.initDateTimeByEvent(this.editEvent);
    },
    "editEvent.isAllDay": function() {
      this.initDateTimeByEvent(this.editEvent);
    }
  },
  created() {
    if (this.getTzId) {
      this.tzId = this.getTzId;
    }

    if (this.editEvent) {
      this.initByCurrentEvent(this.editEvent);
    } else {
      this.initEventDateTime();
    }
  },
  methods: {
    initEventDateTime() {
      // 현재 시간 기준으로 가장 가까운 시간 선택
      const start = moment();
      const end = moment().add(1, "hours");

      this.allDay = true;
      this.startDate = start.format("YYYY-MM-DD");
      this.endDate = end.format("YYYY-MM-DD");
      this.startTime = CAL_CONSTANTS.findNearestTime(start, "HH:mm");
      this.endTime = CAL_CONSTANTS.findNearestTime(end, "HH:mm");
    },
    initByCurrentEvent(event) {
      this.eventUId = event.id;
      this.iCalUID = event.source?.detail.uId;
      this.summary = event.name;
      this.description = event.description;
      this.location = event.location;
      this.sequence = event.source?.detail.sequence;
      this.priority = event.source?.detail.priority;
      this.transp = event.isAllDay ? "TRANSPARENT" : "OPAQUE";
      this.isPublic = !event.isPrivate;
      this.initDateTimeByEvent(event);

      this.resource.resourceId = event.source?.detail.resourceId;
      this.resource.resourceBookingId = event.source?.detail.resourceBookingId;
    },
    initDateTimeByEvent(event) {
      this.allDay = event.isAllDay;

      this.startDate = toLocalDateString(event.start);
      this.endDate = toLocalDateString(event.end);

      if (this.allDay) {
        // 저장된 종일 일정은 시간 정보가 없으므로 시간 일정으로 변경 시 가까운 시간이 선택되도록 미리 계산
        this.startTime = CAL_CONSTANTS.findNearestTime(moment(), "HH:mm");
        this.endTime = CAL_CONSTANTS.findNearestTime(
          moment().add(1, "hours"),
          "HH:mm"
        );
      } else {
        this.startTime = toLocalTimeString(event.start);
        this.endTime = toLocalTimeString(event.end);
      }
    },
    makeBaseEventDetail() {
      // 이벤트 기본 데이터
      let detail = {
        uId: this.iCalUID,
        summary: this.summary,
        description: this.description,
        location: this.location,
        sequence: this.sequence,
        priority: this.priority,
        transp: this.transp,
        isPublic: this.isPublic,
        isAllDay: this.allDay
      };

      let startDate = CAL_CONSTANTS.convertStringToMomentDate(
        this.startDate,
        this.startTime,
        this.allDay
      );

      let endDate = CAL_CONSTANTS.convertStringToMomentDate(
        this.endDate,
        this.endTime,
        this.allDay
      );

      detail.dtStart = startDate.valueOf();
      detail.dtEnd = endDate.valueOf();
      detail.timezone = store.getters["cal/getTzId"];
      return detail;
    }
  }
};

export default EventViewMixin;
