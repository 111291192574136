<template>
  <v-card class="overflow-hidden" style="height:100%;">
    <v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling"
      class="px-2 align-center"
    >
      <img src="https://www.sirteam.net/img/h1_bi.png" />
      <v-spacer></v-spacer>

      <div style="font-size:13px;" class="text-right">
        <div class="red--text">{{ expiryDate }} 만료됨.</div>
        <div class="text--disabled">{{ permission }}</div>
      </div>
    </v-app-bar>

    <v-sheet
      id="scrolling"
      class="overflow-y-auto"
      style="padding-top:64px; height:100%; user-select: text;"
    >
      <v-container>
        <!-- 제목 -->
        <v-card-title class="px-6">{{ mail.subject }}</v-card-title>
        <v-divider />

        <v-card-text class="px-6">
          <div class="d-flex align-baseline mt-2">
            <div class="cr-adr-label">보낸사람</div>
            <div class="d-flex flex-wrap text--primary cr-adr-field">
              {{ mail.from.address }}
            </div>
          </div>
          <div class="d-flex align-baseline mt-2">
            <div class="cr-adr-label">받는사람</div>
            <div class="d-flex flex-wrap text--primary cr-adr-field">
              {{ to }}
            </div>
          </div>
          <div v-if="cc" class="d-flex align-baseline mt-2">
            <div class="cr-adr-label">참조</div>
            <div class="d-flex flex-wrap text--primary cr-adr-field">
              {{ cc }}
            </div>
          </div>
          <div v-if="bcc" class="d-flex align-baseline mt-2">
            <div class="cr-adr-label">숨은참조</div>
            <div class="d-flex flex-wrap text--primary cr-adr-field">
              {{ bcc }}
            </div>
          </div>
          <div class="d-flex align-baseline mt-2">
            <div class="cr-adr-label">받은날짜</div>
            <div class="d-flex flex-wrap text--primary">
              {{ mail.receivedTimeMillis }}
            </div>
          </div>
        </v-card-text>
        <v-divider />

        <div class="cr-contents my-5 px-6">
          <AttachList
            v-if="false && attachList.lengnth > 0"
            :items="attachList"
          />
          <div ref="content" v-if="mail.content" v-html="mail.content" />
        </div>
      </v-container>
    </v-sheet>
  </v-card>
</template>

<style lang="scss" scoped>
// 앱바
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%) !important;
}
.v-sheet.v-app-bar.v-app-bar.v-app-bar--hide-shadow {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12) !important;
}

// 보낸사람, 받는사람
.cr-adr-label {
  color: #333;
  float: left;
  width: 80px;
  min-width: 80px;
  margin-right: 8px;
}
.cr-adr-field {
  word-break: keep-all;
}
// 내용
.cr-contents {
  line-height: 1.7;
  word-break: break-all;
}
.cr-contents ::v-deep p {
  padding: 0px;
  margin: 0px;
}
</style>

<script>
import { mapGetters } from "vuex";
import AttachList from "./AttachList.vue";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  components: {
    AttachList
  },
  computed: {
    ...mapGetters("mailLinkShare", ["mail", "linkShare"]),
    expiryDate() {
      const exDate = new Date();
      exDate.setTime(this.linkShare.startDateTimeMillis);
      exDate.setDate(exDate.getDate() + this.linkShare.expiryDate);
      return getDateWithoutTime(exDate);
    },
    permission() {
      return this.linkShare.permission == 0
        ? "링크가 있는 누구나 접근 가능"
        : "승인된 사용자만 접근 가능";
    },
    to() {
      const to =
        this.mail?.to?.map(
          t =>
            `${t.personal ? `"${t.personal}" ` : ""}${
              t.personal ? `<${t.address}>` : t.address
            }`
        ) ?? [];
      return to.join(", ");
    },
    cc() {
      const cc =
        this.mail?.cc?.map(
          t =>
            `${t.personal ? `"${t.personal}" ` : ""}${
              t.personal ? `<${t.address}>` : t.address
            }`
        ) ?? [];
      return cc.join(", ");
    },
    bcc() {
      const bcc =
        this.mail?.bcc?.map(
          t =>
            `${t.personal ? `"${t.personal}" ` : ""}${
              t.personal ? `<${t.address}>` : t.address
            }`
        ) ?? [];
      return bcc.join(", ");
    },
    attachList() {
      return this.mail?.attachList || [];
    }
  }
};
</script>
