<template>
  <v-list dense class="py-0 mt-2">
    <v-list-item link @click="() => routeConfigTab({ tabId: 'import' })">
      <v-list-item-icon class="ml-2 mr-0">
        <v-icon small color="blue">mdi-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <span class="cr-tutorial-contact-import">
            {{ $t("contact.10") }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("contactRoute", ["routeConfigTab"])
  }
};
</script>
