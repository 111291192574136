import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/commons/store";
import Login from "@/commons/views/login/Login.vue";
import NotFoundPage from "@/commons/views/NotFoundPage.vue";
import AuthLoading from "@/commons/views/AuthLoading";
import LinkViewer from "@/mail/views/LinkViewer";

import account from "@/account/router";
import mail from "@/mail/router";
import calendar from "@/calendar/router";
import contact from "@/contact/router";
import drive from "@/drive/router";
import board from "@/board/router";
import todo from "@/todo/router";
import flow from "@/flow/router";
import resource from "@/resource/router";
import approval from "@/approval/router";
import home from "@/home/router";
import customer from "@/customer/router"; // 고객 관리 모듈 추가
import ExternalOAuth2Authorization from "@/commons/views/ExternalOAuth2Authorization.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(err => {
    // route 중복에러
    if (VueRouter.isNavigationFailure(err)) {
      // 동일 라우팅에 대한 reload func 정의된 경우 실행
      const { reload } = this.currentRoute?.meta ?? {};
      if (reload && typeof reload === "function") {
        reload(this.currentRoute);
        return;
      }
      // route중복에러로 인해 beforeEach에서 처리할 수 없어 여기서 처리
      if (
        location.name &&
        location.name != "login" &&
        err.name === "NavigationDuplicated"
      ) {
        let name = location.name.split("_")[0];
        if (name === "popup") {
          name = location.name.split("_")[1];
        }

        // 메일쓰기에서 트리노드 클릭시 이부분을 타서 분기처리
        if (store.state[`${name}Route`]) {
          store.dispatch(`${name}Route/setParams`, {
            routeName: location.name,
            ...location.params,
            duplicated: true
          });
        }
      }
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/oauth2/connect/code/:registrationId",
    name: "oauth2Connection",
    component: ExternalOAuth2Authorization
  },
  {
    path: "/auth/:type",
    name: "authLoading",
    component: AuthLoading
  },
  {
    path: "/404",
    name: "notFound",
    component: NotFoundPage
  },
  {
    path: "/link/viewer/:params",
    name: "linkViewer",
    component: LinkViewer
  },
  ...account,

  ...mail,
  ...calendar,
  ...contact,
  ...drive,
  ...board,
  ...todo,
  ...flow,
  ...resource,
  ...approval,
  ...home,
  ...customer,
  /**
   * 없는 페이지 처리
   */
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  }
});

export default router;
