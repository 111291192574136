<template>
  <div class="cr-search-wrapper">
    <v-text-field
      color="dark"
      solo
      hide-details
      autocomplete="off"
      :flat.sync="flat"
      @click="flat = false"
      @blur="flat = true"
      background-color="grey lighten-3"
      class="cr-search-input hidden-sm-and-down"
      prepend-inner-icon="mdi-magnify"
      :label="$t('contact.4')"
      :placeholder="$t('contact.4')"
      @keydown="searchCards"
      v-model="searchWord"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-search-wrapper ::v-deep {
  display: flex;
  flex: 1 1 auto;
  width: 600px;
  max-width: 600px;
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      searchWord: "",
      flat: true
    };
  },
  watch: {
    // 현재 페이지의 contactGroupId가 검색페이지가 아닌경우 검색창 초기화
    getCardListInfo: function({ contactGroupId: routeContactGroupId }) {
      if (routeContactGroupId != -4) {
        this.searchWord = "";
      }
    }
  },
  computed: {
    ...mapGetters("contactRoute", ["getCardListInfo"])
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),

    searchCards(e) {
      if (e.key === "Enter") {
        let { searchWord } = this;
        if (searchWord == null) searchWord = "";
        this.searchWord = searchWord.trim();

        // 빈값일 경우
        if (this.searchWord.length == 0) {
          // 현재 라우팅경로가 검색 일 경우에 처음화면으로 돌아간다.
          if (this.getCardListInfo.contactGroupId == -4)
            this.$router.push({
              name: "contact"
            });
          return;
        }

        // 두글자 이상 입력해야 함.
        if (this.searchWord.length < 2) {
          this.openSnackbar({
            message: i18n.t("contact.3"),
            type: "VALIDATION"
          });
          return;
        }

        // contactGroupId, page, 입력한 검색어를 파라미터로 라우팅해준다.
        this.$router.push({
          name: "contact_list_action",
          params: {
            contactGroupId: "search",
            page: 1,
            actionObj: JSON.stringify({ searchWord: this.searchWord })
          }
        });
      }
    }
  }
};
</script>
