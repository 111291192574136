import {
  getGroupByType,
  LINE_GROUP,
  LINE_TYPE
} from "@/approval/constant/approvalLine";
import { ApprovalLines } from "@/approval/utils/ApprovalLines";
import { getKey } from "@/approval/utils/ApprovalLineUtils";
import API from "@/approval/api/approval.api";

export const LINE_RULE_ERRORS = {
  DUPLICATE_APPROVER: "중복된 결재자 입니다.",
  DUPLICATE_SORT_ORDER: "결재선 순서가 중복되었습니다.",
  INVALID_LAST_LINE: "최종 결재선 유형은 반드시 [결재] 유형이어야 합니다.",
  INCLUDE_DRAFT_USER: "기안자는 결재선에 포함될 수 없습니다.",
  INCLUDE_DRAFT_ORGAN: "기안 부서는 공람 유형만 지정 가능합니다."
};
export const LINE_SERVER_ERRORS = {
  USER_NOT_EXIST: "사용자가 존재하지 않거나, 부서에 속해있지 않습니다.",
  ORGAN_NOT_EXIST: "더이상 존재하지 않는 조직입니다."
};
export const LINE_ERRORS = {
  ...LINE_RULE_ERRORS,
  ...LINE_SERVER_ERRORS
};

/**
 * 중복 결재자 검사
 * @returns {array} lines 요소별 통과 여부 및 에러메시지 배열
 */
export const validateDuplicateApprover = lines => {
  const keys = lines.map(getKey);
  const duplicateKeys = keys.filter(
    (key, index) => keys.indexOf(key) !== index
  );
  return lines.map(line => {
    const valid = !duplicateKeys.includes(getKey(line));
    const error = valid ? undefined : LINE_ERRORS.DUPLICATE_APPROVER;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidDuplicateApprover = lines => {
  return validateDuplicateApprover(lines).every(({ valid }) => valid);
};

/**
 * 결재선 순서 검사
 * @returns {array} lines 요소별 통과 여부 및 에러메시지 배열
 */
export const validateSortOrder = lines => {
  // 동일 그룹내 sortOrder 중복 여부 찾는 함수
  const isDuplicatedSortOrder = line => {
    const sameSortOrderLinesGroup = lines
      .filter(({ sortOrder }) => sortOrder === line.sortOrder)
      .map(({ type }) => getGroupByType(type));
    return hasDuplicatesInArray(sameSortOrderLinesGroup);
  };
  // @param {lines} 순회하며 중복여부 검사 후 결과 리턴
  return lines.map(line => {
    const valid = !isDuplicatedSortOrder(line);
    const error = valid ? undefined : LINE_ERRORS.DUPLICATE_SORT_ORDER;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidSortOrder = lines => {
  return validateSortOrder(lines).every(({ valid }) => valid);
};

/**
 * 결재 그룹의 [최종결재선] 유형 검증 (최종결재선은 반드시 [결재])
 */
export const validateLastLineType = lines => {
  const lastApprovalLine = new ApprovalLines(lines)
    .filterByGroup(LINE_GROUP.APPROVAL)
    .sortAsc()
    .getLast();
  const validate = line => {
    if (getKey(line) !== getKey(lastApprovalLine)) return true;
    return line?.type === LINE_TYPE.APPROVAL;
  };
  return lines.map(line => {
    const valid = validate(line);
    const error = valid ? undefined : LINE_ERRORS.INVALID_LAST_LINE;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidLastLineType = lines => {
  return validateLastLineType(lines).every(({ valid }) => valid);
};

/**
 * 기안자가 결재선에 포함된 경우 검증
 */
export const validateDraftUser = (lines, draftUserId) => {
  return lines.map(line => {
    const valid = line.approver?.userId !== draftUserId;
    const error = valid ? undefined : LINE_ERRORS.INCLUDE_DRAFT_USER;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidDraftUser = (lines, draftUserId) => {
  return validateDraftUser(lines, draftUserId).every(({ valid }) => valid);
};

/**
 * 기안 부서가 결재선에 포함된 경우 검증 (기안 부서는 공람 외 추가 불가능)
 */
export const validateDraftOrgan = (lines, draftOrganId) => {
  const validate = line => {
    if (line?.approver?.isUser) return true;
    if (line?.approver?.organId !== draftOrganId) return true;
    return line?.type === LINE_TYPE.SHARE_ORGAN;
  };
  return lines.map(line => {
    const valid = validate(line);
    const error = valid ? undefined : LINE_ERRORS.INCLUDE_DRAFT_ORGAN;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidDraftOrgan = (lines, draftOrganId) => {
  return validateDraftOrgan(lines, draftOrganId).every(({ valid }) => valid);
};

/**
 * 서버로부터 결재자 유효성 검증
 */
export const validateApproverByServer = async lines => {
  const payload = lines.map(({ approver }) => ({
    userId: approver.userId,
    organId: approver.organId
  }));
  const { data = [] } = await API.validateApproverList(payload);
  return lines.map((line, idx) => {
    const valid = data[idx]?.valid ?? false;
    const error = valid
      ? undefined
      : line?.approver?.isUser
      ? LINE_ERRORS.USER_NOT_EXIST
      : LINE_ERRORS.ORGAN_NOT_EXIST;
    return { valid, error };
  });
};
// @returns {boolean}
export const isValidApproverByServer = async lines => {
  const result = await validateApproverByServer(lines);
  return result.every(({ valid }) => valid);
};

/**
 * 검사 결과를 결재선 목록에 병합하는 함수
 * @param approvalLines     - 결재선 목록
 * @param validationResults - 검사 결과 목록
 * @returns {Array} merged approvalLines
 */
export const mergeValidationErrors = (approvalLines, validationResults) => {
  if (approvalLines?.length !== validationResults?.length) return approvalLines;
  return approvalLines.map((line, idx) => {
    const errors = validationResults[idx].valid
      ? line?.errors
      : [...(line?.errors ?? []), validationResults[idx].error];
    return { ...line, errors };
  });
};

/**
 * 검사 결과와 병합된 결재선 목록에서 [모든] errors 초기화
 * @param mergedLines 검사 결과가 병합된 결재선 목록
 * @returns {Array}   검사 결과가 초기화된 결재선 목록
 */
export const resetAllErrors = mergedLines => {
  return [...mergedLines].map(line => {
    const { errors = [], ...attrs } = line;
    const ruleErrors = Object.values(LINE_ERRORS);
    return {
      ...attrs,
      errors: errors.filter(err => !ruleErrors.includes(err))
    };
  });
};
/**
 * 검사 결과와 병합된 결재선 목록에서 [Rule] errors 초기화
 * @param mergedLines 검사 결과가 병합된 결재선 목록
 * @returns {Array}   검사 결과가 초기화된 결재선 목록
 */
export const resetRuleErrors = mergedLines => {
  return [...mergedLines].map(line => {
    const { errors = [], ...attrs } = line;
    const ruleErrors = Object.values(LINE_RULE_ERRORS);
    return {
      ...attrs,
      errors: errors.filter(err => !ruleErrors.includes(err))
    };
  });
};
/**
 * 검사 결과와 병합된 결재선 목록에서 [Server] errors 초기화
 * @param mergedLines 검사 결과가 병합된 결재선 목록
 * @returns {Array}   검사 결과가 초기화된 결재선 목록
 */
export const resetServerErrors = mergedLines => {
  return [...mergedLines].map(line => {
    const { errors = [], ...attrs } = line;
    const ruleErrors = Object.values(LINE_SERVER_ERRORS);
    return {
      ...attrs,
      errors: errors.filter(err => !ruleErrors.includes(err))
    };
  });
};

/**
 * 배열 내 중복된 값이 존재하는지 여부 체크
 * @param array 기본 자료형으로 요소로만 구성된 배열
 * @returns {boolean} 중복 포함 여부
 */
const hasDuplicatesInArray = array => {
  const duplicates = array.filter(
    (item, index) => array.indexOf(item) !== index
  );
  return duplicates > 0;
};
