export default [
  {
    path: "/resource",
    name: "resource",
    component: () =>
      import(
        /* webpackChunkName: "resource-main" */ "../views/ResourceMain.vue"
      ),
    children: [
      {
        path: ":date/:type",
        name: "resource_list"
      }
    ]
  }
];
