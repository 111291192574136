import board from "@/board/store/modules/board";
import boardRoute from "@/board/store/modules/route";
import boardPost from "@/board/store/modules/post";
import boardReply from "@/board/store/modules/reply";
import boardDialog from "@/board/store/modules/dialog";
import boardConfig from "@/board/store/modules/config";
import boardFile from "@/board/store/modules/file";
import boardSearch from "@/board/store/modules/search";
import boardTrash from "@/board/store/modules/trash";

export default {
  board,
  boardRoute,
  boardPost,
  boardReply,
  boardDialog,
  boardConfig,
  boardFile,
  boardSearch,
  boardTrash
};
