const state = {
  show: false,
  type: "",
  subType: "",
  itemId: 0,
  parentId: 0,
  groupId: 0,
  boardId: 0,
  itemValue: "",
  headerValue: "",
  top: 0,
  left: 0,
  targetWidth: 0,
  targetHeight: 0,
  isDetail: false,
  autoClose: false
};

const getters = {
  menuState: state => state,
  show: ({ show }) => show,
  type: ({ type }) => type,
  subType: ({ subType }) => subType,
  itemId: ({ itemId }) => itemId,
  parentId: ({ parentId }) => parentId,
  groupId: ({ groupId }) => groupId,
  boardId: ({ boardId }) => boardId,
  itemValue: ({ itemValue }) => itemValue,
  headerValue: ({ headerValue }) => headerValue,
  top: ({ top }) => top,
  left: ({ left }) => left,
  targetWidth: ({ targetWidth }) => targetWidth,
  targetHeight: ({ targetHeight }) => targetHeight,
  isDetail: ({ isDetail }) => isDetail,
  autoClose: ({ autoClose }) => autoClose,
  position: ({ top, left, targetWidth, targetHeight }) => ({
    top,
    left,
    targetWidth,
    targetHeight
  })
};

const mutations = {
  SET_MENU: (state, params) => {
    state.show = true;
    Object.keys(params).forEach(key => {
      state[key] = params[key];
    });
  },
  CLOSE_MENU: state => {
    state.show = false;
    state.type = "";
    state.subType = "";
    state.top = 0;
    state.left = 0;
    state.targetWidth = 0;
    state.targetHeight = 0;
    state.itemId = 0;
    state.parentId = 0;
    state.headerValue = "";
    state.isDetail = false;
    state.autoClose = false;
  }
};

const actions = {
  setMenu({ commit, state }, params) {
    if (
      state.itemId === params.itemId &&
      state.headerValue === params.headerValue
    ) {
      commit("CLOSE_MENU");
      return;
    }

    commit("SET_MENU", params);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
