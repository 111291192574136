import http from "@/commons/api/http";

const prefix = "api/todo/board";

/**
 * board 목록 조회
 * @param {*} workspaceId 워크스페이스 id
 */
const getBoards = workspaceId =>
  http.get(`api/todo/workspace/${workspaceId}/boards`);

/**
 * board 입장
 * @param {*} id 보드 id
 */
const enterBoard = (id, organId) =>
  http.get(`${prefix}/${id}`, { params: { organId } });

/**
 * board 생성
 * @param {*} workspaceId 워크스페이스 id
 * @param {*} title 보드 이름
 * @param {*} boardType 보드 타입
 * @param {*} parentId 상위 보드 id
 */
const addBoard = (workspaceId, params) => {
  return http.post(`${prefix}/${workspaceId}`, params);
};

/**
 * board 이름변경
 * @param {*} id 보드 id
 * @param {*} title 보드 이름
 */
const updateBoardTitle = (id, title) => {
  const formData = new FormData();
  formData.append("title", title);

  return http.put(`${prefix}/${id}/title`, formData);
};

/**
 * board 순서변경
 * @param {*} boardId 보드 id
 * @param {*} targetBoardId 타겟 보드 id
 * @param {*} position 보드 위치
 */
const moveBoard = ({ boardId, targetBoardId, position }) =>
  http.put(`${prefix}/${boardId}/move/${targetBoardId}/${position}`);

/**
 * board 삭제
 * @param {*} id 보드 id
 */
const deleteBoard = id => http.delete(`${prefix}/${id}`);

/**
 * board 헤더 수정
 * @param {*} id 보드 id
 * @param {*} header 헤더
 */
const updateHeader = (id, header) =>
  http.put(`${prefix}/${id}/header`, { header });

/**
 * board 필터 저장
 */
const saveFilter = (boardId, filterName, isDefault, filters) =>
  http.post(`${prefix}/${boardId}/filter`, { filterName, isDefault, filters });

/**
 * board 필터 수정
 */
const updateFilter = (boardId, id, filterName, isDefault, filters) =>
  http.put(`${prefix}/${boardId}/filter`, {
    id,
    filterName,
    isDefault,
    filters
  });

/**
 * board 기본 필터 수정
 */
const updateDefaultFilterId = (boardId, id, isDefault) =>
  http.put(`${prefix}/${boardId}/filter/${id}/default/${isDefault}`);

/**
 * board 필터 삭제
 */
const deleteFilter = (boardId, id) =>
  http.delete(`${prefix}/${boardId}/filter/${id}`);

export {
  getBoards,
  enterBoard,
  addBoard,
  updateBoardTitle,
  moveBoard,
  deleteBoard,
  updateHeader,
  saveFilter,
  updateFilter,
  updateDefaultFilterId,
  deleteFilter
};
