<template>
  <v-row>
    <!-- 게시판 선택 -->
    <v-col cols="12" class="py-2">
      <div
        class="cr-title text-body-2 mb-2 d-flex align-start"
        v-text="'게시판'"
      ></div>
      <InputSelector
        label="전체"
        :height="height"
        :initItems="boardItems"
        :selectedItem.sync="selectedBoard"
      />
    </v-col>
    <!-- 파일명 검색 -->
    <v-col cols="12" class="d-flex align-center py-2">
      <!-- 업로드 유형 선택 -->
      <div class="mr-2" style="width:150px">
        <div
          class="cr-title text-body-2 mb-2 d-flex align-start"
          v-text="'대상'"
        ></div>
        <v-select
          v-model="searchType"
          :items="searchTypeList"
          :height="height"
          dense
          outlined
          hide-details
        />
      </div>
      <!-- 파일명 -->
      <div style="width:100%">
        <div
          class="cr-title text-body-2 mb-2 d-flex align-start"
          v-text="'검색어'"
        ></div>
        <v-text-field
          ref="keywordInput"
          dense
          outlined
          :height="height"
          hide-details
          autocomplete="off"
          tabindex="1"
          placeholder="입력해 주세요"
          v-model="keyword"
          maxlength="50"
          @keydown="inputKeydown"
        ></v-text-field>
      </div>
    </v-col>
    <v-col cols="12" class="d-flex justify-end py-2">
      <v-btn text class="mr-1" @click="closeDetail">닫기</v-btn>
      <v-btn color="primary" elevation="0" @click="onSearch">검색</v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-title {
  color: grey;
}
</style>

<script>
import InputSelector from "@/board/views/components/common/InputSelector";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { InputSelector },
  props: {},
  mounted() {},
  data() {
    return {
      selectedBoard: {},
      searchType: "ALL",
      searchTypeList: [
        { text: "전체", value: "ALL" },
        { text: "파일명", value: "FILENAME" },
        { text: "업로더", value: "UPLOADER" }
      ],
      keyword: "",
      height: 45
    };
  },
  watch: {},
  computed: {
    ...mapGetters("board", ["getBoards"]),
    boardItems() {
      let boards = [];

      // 1 depth 구조로 변경
      this.getBoards.forEach(b => {
        const { children, ...board } = b;
        boards = children
          ? [...boards, board, ...children]
          : [...boards, board];
      });

      // 검색 가능한 게시판 필터링
      return boards.filter(b => !b.isDeleted && b.boardType == "BOARD");
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    init() {
      this.selectedBoard = {};
      this.searchType = "ALL";
      this.keyword = "";
    },
    closeDetail() {
      this.$emit("closeDetail", false);
      this.init();
    },
    inputKeydown(e) {
      if (e.key === "Enter") this.onSearch();
    },
    onSearch() {
      let queryObj = {};

      // 게시판
      if (this.selectedBoard) {
        queryObj.boardId = this.selectedBoard.id;
      }

      // 유효성 검증 및 검색라우팅
      if (this.validation()) {
        // 키워드, 대상
        queryObj.keyword = this.keyword;
        queryObj.searchType = this.searchType;

        this.$router.push({
          name: "board_search",
          params: {
            type: "file",
            query: JSON.stringify(queryObj)
          }
        });
        this.closeDetail();
      }
    },
    validation() {
      // 앞뒤 공백 제거
      this.keyword = this.keyword.trim();

      // 검색 입력창 Element
      const keywordInput = this.$refs?.keywordInput;

      // 글자 수 검증
      if (this.keyword.length < 1) {
        this.openSnackbar({
          message: "최소 한 글자 이상 입력해야 합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      if (this.keyword.length > 50) {
        this.openSnackbar({
          message: "최대 50 글자까지 입력 가능합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      return true;
    }
  },
  destroyed() {
    this.init();
  }
};
</script>

<style lang="scss" scoped></style>
