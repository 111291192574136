<template>
  <div class="text-caption text--secondary">
    {{ useSearchHistory ? $t("common.16") : $t("common.17") }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("mailLayout", ["useSearchHistory"])
  }
};
</script>
