import i18n from "@/_locales";

const state = {
  headline: "",
  show: false,
  type: "",
  params: {},

  showTitleCloseBtn: false,
  showSecondBtn: false,
  showConfirmBtn: true,
  cancelBtnTitle: "",
  secondBtnTitle: "",
  confirmBtnTitle: "",

  confirmCallbackFunc: () => {}
};

const getters = {
  headline: ({ headline }) => headline,
  showDialog: ({ show }) => show,
  dialogType: ({ type }) => type,
  params: ({ params }) => params,
  showTitleCloseBtn: ({ showTitleCloseBtn }) => showTitleCloseBtn,
  showBtn: ({ showSecondBtn, showConfirmBtn }) => ({
    second: showSecondBtn,
    confirm: showConfirmBtn
  }),
  btnTitles: ({ cancelBtnTitle, secondBtnTitle, confirmBtnTitle }) => ({
    cancel: cancelBtnTitle || i18n.t("common.취소"),
    second: secondBtnTitle,
    confirm: confirmBtnTitle || i18n.t("common.확인")
  })
};

const mutations = {
  SET_DIALOG: (
    state,
    {
      headline,
      type,
      params,
      showTitleCloseBtn = false,
      btnTitles,
      showSecondBtn = false,
      showConfirmBtn = true,
      confirmCallbackFunc = () => {}
    }
  ) => {
    state.headline = headline;
    state.show = true;
    state.type = type || state.type;
    state.params = params || state.params;
    state.showTitleCloseBtn = showTitleCloseBtn;
    state.showSecondBtn = showSecondBtn;
    state.showConfirmBtn = showConfirmBtn;
    state.confirmCallbackFunc = confirmCallbackFunc;

    if (btnTitles) {
      state.cancelBtnTitle = btnTitles.cancel || state.cancelBtnTitle;
      state.secondBtnTitle = btnTitles.second || state.secondBtnTitle;
      state.confirmBtnTitle = btnTitles.confirm || state.confirmBtnTitle;
    }
  },
  CLOSE_DIALOG: state => {
    state.headline = "";
    state.show = false;
    state.type = "";
    state.params = {};
    state.showTitleCloseBtn = false;
    state.showSecondBtn = false;
    state.showConfirmBtn = true;
    state.cancelBtnTitle = i18n.t("common.취소");
    state.secondBtnTitle = "";
    state.confirmBtnTitle = i18n.t("common.확인");
    state.confirmCallbackFunc = () => {};
  }
};

const actions = {
  confirmCallback({ state }) {
    state.confirmCallbackFunc();
  },
  async close({ commit }) {
    commit("CLOSE_DIALOG");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
