<template>
  <v-list dense class="elevation-3 cr-search-drop-wrapper">
    <v-list-item-group color="primary" v-model="model">
      <v-list-item
        v-for="item in searchFields"
        :key="item.type"
        @click.stop="search(item.type)"
      >
        <v-list-item-title>
          <span v-if="item.title" class="body-2 black--text">
            {{ `${item.title}:  ` }}
          </span>
          <span class="body-2 font-weight-bold black--text">
            {{ searchWord }}
          </span>
          <span class="body-2 ml-1 blue-grey--text text--darken-1">
            - {{ item.description }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    folderId: {
      type: Number,
      default: -1
    },
    searchWord: {
      type: String,
      default: ""
    },
    keyEvent: {
      type: Event,
      default: null
    }
  },
  mounted() {
    if (!this.getRouteListInfo.actionObj?.search) return;
    const { type } = this.getRouteListInfo.actionObj?.search;
    const idx = this.searchFields.findIndex(s => s.type === type);
    if (idx === -1) return;

    this.model = idx;
  },
  data() {
    return { model: 0 };
  },
  computed: {
    ...mapGetters("mailRoute", ["getRouteListInfo"]),
    ...mapGetters("mailLayout", ["useSearchHistory"]),
    searchFields() {
      return [
        {
          title: this.$t("mail.572"),
          description: this.$t("mail.573"),
          type: "all.from.subject"
        },
        {
          title: this.$t("mail.제목"),
          description: this.$t("mail.426"),
          type: "all.subject"
        },
        {
          title: this.$t("mail.보낸사람"),
          description: this.$t("mail.27"),
          type: "all.from"
        },
        {
          title: this.$t("mail.받는사람"),
          description: this.$t("mail.28"),
          type: "all.to"
        },
        {
          title: this.$t("mail.내용"),
          description: this.$t("mail.29"),
          type: "all.content"
        }
      ];
    }
  },
  watch: {
    keyEvent(e) {
      let maxIdx = this.searchFields.length - 1;
      switch (e.key) {
        case "ArrowUp": {
          e.preventDefault();
          this.model -= 1;
          if (this.model < 0) this.model = maxIdx;
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          this.model += 1;
          if (this.model > maxIdx) this.model = 0;
          break;
        }
        case "Enter": {
          e.preventDefault();
          const { type } = this.searchFields[this.model];
          this.search(type);
          break;
        }
      }
    }
  },
  methods: {
    ...mapActions("mailRoute", ["routeMailList"]),
    search(type) {
      const { folderId, searchWord: query, useSearchHistory } = this;

      this.$parent.blur();
      this.routeMailList({
        name: "mail_list_action",
        page: 1,
        folderId,
        actionObj: {
          search: { type, query, useSearchHistory }
        }
      });
    }
  }
};
</script>
