import http from "@/commons/api/http";

const prefix = "api/mail";

const getReceipt = ({ mailId }) =>
  http.get(`${prefix}/${mailId}/receipt`, {
    params: {
      mailId
    }
  });

// 수신확인 상세보기(사용자정보가져오기)
const getReceiptUserList = ({
  receiptId,
  receiptStatus,
  page = 0,
  pageSize = 5
}) =>
  http.get(`${prefix}/receipt/${receiptId}`, {
    params: {
      receiptStatus,
      page,
      pageSize
    }
  });

// 발송취소
const cancelSend = (receiptId, targetAddress) => {
  const formData = new FormData();
  formData.append("targetAddress", targetAddress);
  return http.put(`${prefix}/receipt/${receiptId}`, formData);
};

// 모든 메일 발송취소
const cancelAllSend = receiptId =>
  http.put(`${prefix}/receipt/${receiptId}/all`);

export { getReceipt, getReceiptUserList, cancelSend, cancelAllSend };
