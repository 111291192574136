import http from "./http";

const prefix = "/api/gpt";

const promptWithSirTEAM = ({
  promptType,
  question,
  before,
  after,
  callback
}) => {
  const formData = new FormData();
  formData.append("promptType", promptType);
  formData.append("question", question);
  formData.append("before", before);
  formData.append("after", after);

  return http.post(`${prefix}/st/prompt`, formData, {
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "text/event-stream; charset=utf-8"
    },
    responseType: "stream",
    onDownloadProgress: e => {
      callback(e.target.responseText);
    }
  });
};

export { promptWithSirTEAM };
