<template>
  <div
    class="d-inline-flex"
    style="position: relative;"
    :class="{ 'cr-draggable-autocomplete': crDraggable }"
    :data-id="itemKey"
    :data-idx="index"
  >
    <v-chip
      label
      close
      outlined
      :class="chipClass"
      close-icon="mdi-close"
      :ripple="false"
      @click="chipClick(index)"
      @click:close="() => chipClose(index)"
      @mousedown.capture.stop=""
    >
      {{ value }}
      <v-btn
        v-if="directInput"
        text
        x-small
        plain
        :color="chipEditColor"
        :ripple="false"
        @click="chipEditClick"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-chip>
  </div>
</template>

<style lang="scss" scoped>
.cr-draggable-autocomplete {
  &.cr-drop-ahead::before {
    content: "";
    background-color: #b71c1c;
    border-radius: inherit;
    pointer-events: none;
    position: absolute;
    top: 3px;
    bottom: 4px;
    left: 1px;
    width: 3px;
  }

  &.cr-drop-behind::before {
    content: "";
    background-color: #b71c1c;
    border-radius: inherit;
    pointer-events: none;
    position: absolute;
    top: 3px;
    bottom: 4px;
    right: -4px;
    width: 3px;
  }
}
.v-chip {
  /* chip 간격 */
  margin-top: 3px !important;
  margin-right: -1px !important;
  border-radius: 0px !important;
  /* chip 크기 */
  &.v-size--default {
    height: 30px;
  }

  /* mdi-pencil 버튼 */
  > .v-chip__content > .v-btn.v-btn--text.theme--light.v-size--x-small {
    width: 15px;
    padding: 0px;
    min-width: unset;
    margin-left: 10px;

    > .v-btn__content > .v-icon {
      font-size: 18px;
    }
  }

  /* email형식 오류 */
  &.cr-chip-valid-border {
    border: 2px solid #b56363;
    color: #b56363 !important;
    margin-right: -1px !important;
    margin-left: 4px !important;
    padding: 0 10px;

    .primary--text {
      color: #b56363 !important;
    }
  }

  /* chip 선택시 border */
  &.v-chip--outlined.v-chip.v-chip.cr-chip-selected {
    background-color: rgba(182, 220, 255, 0.2) !important;

    &.cr-chip-border {
      margin-left: 4px !important;
    }

    &.cr-chip-valid-border {
      border: 2px solid #dbadad;
      background-color: rgba(255, 182, 182, 0.2) !important;
    }
  }
}

.v-chip.v-chip--outlined.v-chip.v-chip {
  &::before {
    opacity: 0 !important;
  }
  &.theme--light.v-chip:hover::before {
    opacity: 0.04 !important;
  }
  box-shadow: none !important;
}
.v-chip.v-chip--outlined.v-chip.v-chip.cr-chip-selected::before {
  background-color: transparent;
}
</style>

<script>
export default {
  props: [
    "value",
    "index",
    "valid",
    "itemKey",
    "chipSelect",
    "pressedControl",
    "pressedShift",
    "directInput",
    "crDraggable"
  ],
  computed: {
    chipEditColor() {
      return !this.valid ? "primary" : "";
    },
    chipClass() {
      let cls = "cr-autocomplete-chip";

      if (!this.valid) {
        cls += " mx-1 cr-chip-valid-border";
      }

      cls += " my-1";
      const [chipSelect = undefined] = this.chipSelect;
      if (this.chipSelect.indexOf(this.index) > -1) cls += " cr-chip-selected";
      if (chipSelect == this.index) cls += " cr-chip-border";
      else cls += " mx-1";

      return cls;
    }
  },
  methods: {
    chipClose(index) {
      if (this.pressedControl || this.pressedShift) return;
      this.$emit("chipClose", index);
      this.$emit("inputFocus");
    },
    chipEditClick(e = { stopPropagation: () => {} }) {
      if (this.pressedControl || this.pressedShift) return;
      this.$emit("chipModify", e, this.$el, this.index);
    },
    chipClick(index) {
      let chipSelect = [];
      if (this.pressedControl) {
        if (this.chipSelect.findIndex(i => i == index) == -1) {
          chipSelect = [index, ...this.chipSelect];
        } else {
          chipSelect = this.chipSelect.filter(i => i != index);
        }
      } else if (this.pressedShift) {
        const [first] = this.chipSelect;
        const length = first > index ? first : index;
        let i = first > index ? index : first;

        for (i; i <= length; i += 1) {
          chipSelect.push(i);
        }
      } else {
        chipSelect = [index];
      }

      setTimeout(() => {
        this.$emit("update:chipSelect", chipSelect);
      }, 0);
    }
  }
};
</script>
