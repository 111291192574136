import {
  getExternalTokensByModule,
  saveToken,
  revokeToken
} from "@/commons/api/external/token.api";

import router from "@/commons/router";
import i18n from "@/_locales";

const state = {
  // 초기화 여부
  init: false,
  // 외부 애플리케이션 별 토큰
  tokens: {
    google: ""
  }
};

const getters = {
  init: ({ init }) => init,
  haveTokenByExternalType: ({ tokens }) => externalType => {
    return !!tokens[externalType];
  },
  getTokenByExternalType: ({ tokens }) => externalType => {
    return tokens[externalType];
  }
};

const mutations = {
  SET_INIT: (state, init) => (state.init = init),
  SET_INIT_TOKENS: state => {
    state.init = false;
    state.tokens = {
      google: ""
    };
  },
  // 외부 애플리케이션 별 토큰 SET
  SET_EXTERNAL_TOKEN: (state, info) => {
    info.forEach(token => {
      switch (token.externalType) {
        case "GOOGLE_DRIVE":
          state.tokens.google = token.accessToken;
          break;
        default:
          break;
      }
    });
  },
  DELETE_TOKEN: (state, externalType) => {
    switch (externalType) {
      case "GOOGLE_DRIVE":
        state.tokens.google = "";
        break;
      default:
        break;
    }
  }
};

const actions = {
  // 토큰 가져오기
  async getToken({ commit }) {
    commit("SET_INIT", true);
    const { data = [], status } = await getExternalTokensByModule("DRIVE");
    if (status != 200) return;
    if (data.length > 0) {
      commit("SET_EXTERNAL_TOKEN", data);
    }
  },
  // 토큰 저장하기
  async saveToken(getters, { code, type }) {
    const { data = {}, status = 400 } = await saveToken({
      code,
      externalType: type
    });
    if (status != 200 || !data) {
      return window.opener?.vxd(
        "snackbar/openSnackbar",
        { type: "ERROR", message: i18n.t("drive.67") },
        { root: true }
      );
    }

    window.opener?.vxc("driveExternalToken/SET_EXTERNAL_TOKEN", [data], {
      root: true
    });

    window.opener?.vr.push({
      name: "drive_external_list_by_tab",
      params: {
        externalType: window.opener.vxg("driveRoute/getExternalDriveType"),
        tabId: "my"
      }
    });
  },
  // 연결 해제
  async disconnectDrive({ rootGetters, commit, dispatch }, externalType) {
    const { status = 400 } = await revokeToken(externalType);
    const type = status == 200 ? "SUCCESS" : "ERROR";

    const msg = {
      ERROR: i18n.t("drive.68"),
      SUCCESS: i18n.t("drive.69")
    };
    dispatch(
      "snackbar/openSnackbar",
      {
        type,
        message: msg[type]
      },
      { root: true }
    );

    if (type == "SUCCESS") {
      commit("DELETE_TOKEN", externalType);
      commit("driveExternal/SET_INIT_EXTERNAL_DATA", null, { root: true });

      const activeItem = rootGetters["drive/getActiveItems"];
      if (activeItem.length < 1) return;
      if (activeItem[0].fileType == "GOOGLE")
        return router.push({
          name: "drive_external",
          params: {
            externalType: rootGetters["driveRoute/getExternalDriveType"]
          }
        });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
