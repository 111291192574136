import {
  getRuleList,
  addRule,
  updateRule,
  deleteRules,
  updateSortOrder
} from "../../api/rule.api";
import i18n from "@/_locales";

const state = {
  loading: false,
  list: [],
  totalElements: 0,
  page: 0,
  pageSize: 100
};

const getters = {
  getRuleList: ({ list }) => list,
  getRuleTotalElements: ({ totalElements }) => totalElements,
  getRulePageSize: ({ pageSize }) => pageSize,
  getRuleLoading: ({ loading }) => loading
};

const mutations = {
  SET_RULE_LIST: (state, { list, totalElements }) => {
    state.list = list;
    state.totalElements = totalElements;
    state.loading = false;
  },
  SET_LOADING: (state, loading) => (state.loading = loading)
};

const actions = {
  async getRuleList({ state, commit, rootGetters }) {
    commit("SET_LOADING", true);
    const { data, status } = await getRuleList({
      page: state.page,
      pageSize: state.pageSize
    });

    if (status == 200) {
      const list = [];
      data.content.forEach(r => {
        const adrs = { adrFrom: [], adrTo: [], adrCc: [], adrToCc: [] };
        if (r.conditionOptions.address.length > 0) {
          r.conditionOptions.address.forEach(o => {
            const [key] = Object.keys(o);
            adrs[`adr${key}`].push(o[key]);
          });
        }

        const { nameFolder } =
          rootGetters["folder/getFolder"](r.processOptions.folderId) || {};
        list.push({
          ...r,
          ...r.conditionOptions,
          ...r.processOptions,
          ...adrs,
          nameFolder
        });
      });

      commit("SET_RULE_LIST", { list, totalElements: data.totalElements });
    }
  },
  async addRule({ state, dispatch }, params) {
    if (state.totalElements >= 100) return;

    const { status } = await addRule(params);
    if (status == 201) {
      dispatch("getRuleList");
    } else if (status == 409) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.386"), type: "ERROR" },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.387"), type: "ERROR" },
        { root: true }
      );
    }

    return status == 201;
  },
  async updateRule({ dispatch }, params) {
    const { status } = await updateRule(params);
    if (status == 201) {
      dispatch("getRuleList");
    } else if (status == 409) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.386"), type: "ERROR" },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.388"), type: "ERROR" },
        { root: true }
      );
    }

    return status == 201;
  },
  async updateSortOrder(
    { commit, rootGetters },
    { id, targetSortOrder, isLast = false }
  ) {
    const { data, status } = await updateSortOrder(id, targetSortOrder, isLast);

    if (status == 200) {
      const list = [];
      data.content.forEach(r => {
        const adrs = { adrFrom: [], adrTo: [], adrCc: [], adrToCc: [] };
        if (r.conditionOptions.address.length > 0) {
          r.conditionOptions.address.forEach(o => {
            const [key] = Object.keys(o);
            adrs[`adr${key}`].push(o[key]);
          });
        }

        const { nameFolder } =
          rootGetters["folder/getFolder"](r.processOptions.folderId) || {};
        list.push({
          ...r,
          ...r.conditionOptions,
          ...r.processOptions,
          ...adrs,
          nameFolder
        });
      });

      commit("SET_RULE_LIST", { list, totalElements: data.totalElements });
    }
  },
  async deleteRules({ dispatch }, ruleIds) {
    const { status } = await deleteRules(ruleIds);
    if (status == 204) {
      dispatch("getRuleList");
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.389"), type: "ERROR" },
        { root: true }
      );
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
