import http from "@/commons/api/http";

const prefix = "api/customer/history";

// 메모 리스트 조회
const fetchMemoList = params => http.get(prefix, { params });

// 메모 조회
const fetchMemo = id => http.get(`${prefix}/${id}`);

// 메모 저장
const createContactMemo = formData => http.post(`${prefix}`, formData);

// 메모 수정
const modifyContactMemo = payload => http.patch(`${prefix}`, payload);

// 메모 삭제
const removeContactMemo = id => http.delete(`${prefix}/${id}`);

// 메모 첨부 다운로드
const downloadAttach = id =>
  http.get(`${prefix}/download/${id}`, {
    responseType: "blob"
  });

export {
  fetchMemoList,
  fetchMemo,
  createContactMemo,
  modifyContactMemo,
  removeContactMemo,
  downloadAttach
};
