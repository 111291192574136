import Vue from "vue";
import axios from "axios";
import API from "@/approval/api/approval.api";

// progress 계산하는 함수
function getProgressFunc(totalLoaded, totalSize) {
  return totalLoaded / totalSize >= 1 ? 100 : (totalLoaded / totalSize) * 100;
}
// 전송상태
const UPLOAD_STATUS = {
  WAITING: "WAITING",
  UPLOADING: "UPLOADING",
  COMPLETE: "COMPLETE",
  FAIL: "FAIL"
};

// state 초기값
const initialState = {
  show: false,
  items: [],
  cancelTokenSources: []
};

const state = { ...initialState };

const getters = {
  getShow: ({ show }) => show,
  getItems: ({ items }) => items,
  getTotalSize: ({ items }) => {
    return items.reduce((totalSize, item) => totalSize + item.size, 0);
  },
  getTotalLoaded: ({ items }) => {
    return items.reduce((totalLoaded, item) => totalLoaded + item.loaded, 0);
  },
  getTotalProgress: (_, getters) => {
    return getProgressFunc(getters.getTotalLoaded, getters.getTotalSize);
  },
  isUploadCompleted: ({ items }) => {
    return items.every(item => item.status === UPLOAD_STATUS.COMPLETE);
  },
  getLoadedFileIds: ({ items }) => {
    return items
      .filter(item => item.status === UPLOAD_STATUS.COMPLETE)
      .map(item => item.id);
  }
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_SHOW: (state, show) => (state.show = show),
  SET_UPLOADED_FILES: (state, uploadedFiles = []) => {
    const uploadedItems = uploadedFiles.map(file => ({
      ...file,
      status: UPLOAD_STATUS.COMPLETE,
      loaded: file.size,
      progress: 100
    }));
    state.items = [...state.items, ...uploadedItems];
  },
  ADD_FILE: (state, part) => {
    const item = {
      id: null,
      name: part.name,
      size: part.size,
      status: UPLOAD_STATUS.WAITING,
      loaded: 0,
      progress: 0,
      part
    };
    state.items = [...state.items, item];
  },
  REMOVE_FILE: (state, idx) => {
    Vue.delete(state.items, idx);
  },
  UPDATE_ITEM_LOADED: (state, { idx, loaded }) => {
    const progress = getProgressFunc(loaded, state.items[idx].size);
    Vue.set(state.items, idx, { ...state.items[idx], loaded, progress });
  },
  UPDATE_ITEM_STATUS: (state, { idx, status }) => {
    Vue.set(state.items, idx, { ...state.items[idx], status });
  },
  UPDATE_ITEM_ID: (state, { idx, id }) => {
    Vue.set(state.items, idx, { ...state.items[idx], id });
  },
  ADD_CANCEL_TOKEN_SOURCE: (state, token) =>
    (state.cancelTokenSources = [...state.cancelTokenSources, token])
};

const actions = {
  upload({ commit, dispatch, state, getters }, { callback }) {
    const { items } = state;
    if (items.length < 1 || getters.isUploadCompleted) {
      callback();
      return;
    }

    commit("SET_SHOW", true);

    // 모든 파일 병렬 업로드
    items.forEach((item, idx) => {
      if (![UPLOAD_STATUS.WAITING, UPLOAD_STATUS.FAIL].includes(item.status)) {
        return;
      }

      // axios 취소 토큰
      const cancelTokenSource = axios.CancelToken.source();
      commit("ADD_CANCEL_TOKEN_SOURCE", cancelTokenSource);

      // 업로드 진행률 및 업로드 취소를 위한 axios config
      const config = {
        cancelToken: cancelTokenSource.token,
        onUploadProgress: e => {
          commit("UPDATE_ITEM_LOADED", { idx, loaded: e.loaded });
          commit("UPDATE_ITEM_STATUS", {
            idx,
            status: UPLOAD_STATUS.UPLOADING
          });
        }
      };

      // 서버에 업로드
      API.uploadFile(item.part, config)
        .then(({ status, data, message }) => {
          // 업로드 취소시
          if (message === "cancel") {
            commit("UPDATE_ITEM_LOADED", { idx, loaded: 0 });
            commit("UPDATE_ITEM_STATUS", {
              idx,
              status: UPLOAD_STATUS.WAITING
            });
          }
          // 업로드 완료시
          if (status === 200) {
            commit("UPDATE_ITEM_ID", { idx, id: data.id });
            commit("UPDATE_ITEM_STATUS", {
              idx,
              status: UPLOAD_STATUS.COMPLETE
            });
          }
          // 업로드 실패시
          if (status !== 200) {
            commit("UPDATE_ITEM_LOADED", { idx, loaded: 0 });
            commit("UPDATE_ITEM_STATUS", {
              idx,
              status: UPLOAD_STATUS.FAIL
            });
            const message =
              status === 413
                ? "전송 가능한 최대 크기를 초과했습니다."
                : status === 507
                ? "전체 용량을 초과했습니다. 관리자에게 문의하세요."
                : "업로드에 실패했습니다.";
            dispatch(
              "snackbar/openSnackbar",
              { message, type: "ERROR" },
              { root: true }
            );
          }
          // 모든 업로드가 완료된 경우 :: 업로드 창 닫고 콜백 실행
          if (getters.isUploadCompleted) {
            commit("SET_SHOW", false);
            callback();
          }
        })
        .catch(() => {
          // TODO 업로드 에러 처리
        });
    });
  },
  // 업로드 취소
  cancelUpload({ state, commit }) {
    state.cancelTokenSources.forEach(c => c.cancel("cancel"));
    commit("SET_SHOW", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
