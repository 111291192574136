import http from "@/commons/api/http";

const prefix = "api/mail/config";

// 메일 설정 조회
const getMailConfig = () => http.get(prefix);

/**
 * 메일 첫 화면 수정
 *
 * @param {*} id - folderId
 * @returns
 */
const updateFirstScreen = id => http.put(`${prefix}/${id}/firstScreen`);

/**
 * 메일 목록 개수 수정
 *
 * @param {*} pageSize
 * @returns
 */
const updatePageSize = pageSize => http.put(`${prefix}/${pageSize}/pageSize`);

/**
 * 임시보관 자동 저장 수정
 *
 * @param {*} autoSave
 * @returns
 */
const updateAutoSave = autoSave => http.put(`${prefix}/${autoSave}/autoSave`);

/**
 * 목록/보기 좌우분할 사용
 *
 * @param {*} splitView
 * @returns
 */
const updateSplitView = (splitView, showFirstMail) => {
  const formData = new FormData();
  formData.append("showFirstMail", showFirstMail);

  return http.put(`${prefix}/${splitView}/splitView`, formData);
};

/**
 * 그룹보기 사용
 *
 * @param {*} groupView
 * @returns
 */
const updateGroupView = groupView =>
  http.put(`${prefix}/${groupView}/groupView`);

/**
 * 탭 사용
 *
 * @param {*} useTab
 * @returns
 */
const updateUseTab = useTab => http.put(`${prefix}/${useTab}/useTab`);

/**
 * 새창으로 메일 읽기
 *
 * @param {*} popupRead
 * @returns
 */
const updatePopupRead = popupRead =>
  http.put(`${prefix}/${popupRead}/popupRead`);

/**
 * 새창으로 메일 쓰기
 *
 * @param {*} popupRead
 * @returns
 */
const updatePopupWrite = popupWrite =>
  http.put(`${prefix}/${popupWrite}/popupWrite`);

/**
 * 읽기/목록 설정 수정
 *
 * @returns
 */
const updateListConfig = params => {
  const formData = new FormData();
  formData.append("firstScreenFolderId", params.firstScreenFolderId);
  formData.append("pageSize", params.pageSize);
  formData.append("splitView", params.splitView);
  formData.append("showFirstMail", params.showFirstMail);
  formData.append("groupView", params.groupView);
  formData.append("useTab", params.useTab);
  formData.append("popupRead", params.popupRead);
  formData.append("autoTagging", params.autoTagging);
  formData.append("useTag", params.useTag);
  formData.append("useSearchFolder", params.useSearchFolder);
  formData.append("useShareFolder", params.useShareFolder);
  formData.append("subjectSearchOperator", params.subjectSearchOperator);

  params.folderExpand.forEach(i => formData.append("folderExpand", i));
  params.quickMenu.forEach(i => formData.append("quickMenu", i));

  return http.put(`${prefix}/list`, formData);
};

/**
 * 쓰기/답장 설정 수정
 *
 * @returns
 */
const updateWriteConfig = params => http.put(`${prefix}/write`, params);

/**
 * pop3 설정 수정
 *
 * @returns
 */
const updatePop3Config = params => http.put(`${prefix}/pop3`, params);

/**
 * 외부 메일 프로그램 환경설정 정보
 * @returns
 */
const getExternalSettingInfo = () =>
  http.get(`${prefix}/pop3/external/setting`);

/**
 * 외부메일 비밀번호 생성
 * @returns
 */
const getPop3Password = () => http.get(`${prefix}/pop3/password`);

/**
 * 스마트메일함 설정 수정
 *
 * @returns
 */
const updateSmartMailboxConfig = params => {
  const formData = new FormData();
  formData.append("smartMailbox", params.smartMailbox);
  formData.append("classifyPromotion", params.classifyPromotion);
  formData.append("classifySocial", params.classifySocial);
  return http.put(`${prefix}/smartMailbox`, formData);
};

export {
  getMailConfig,
  updateFirstScreen,
  updatePageSize,
  updateAutoSave,
  updateSplitView,
  updateGroupView,
  updateUseTab,
  updatePopupRead,
  updatePopupWrite,
  updateListConfig,
  updateWriteConfig,
  updatePop3Config,
  getExternalSettingInfo,
  getPop3Password,
  updateSmartMailboxConfig
};
