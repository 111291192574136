<template>
  <v-menu
    ref="menu"
    :activator="selectedElement"
    :close-on-content-click="false"
    offset-y
    offset-x
    offset-overflow
    rounded="lg"
    :min-width="$vuetify.breakpoint.width < 424 ? '100%' : '424px'"
    max-width="480px"
    content-class="elevation-10"
    @input="val => $emit('input', val)"
  >
    <v-card v-if="selectedEvent">
      <v-card-title class="py-1 pr-1">
        <!-- 구글 일정 표시 -->
        <div v-if="isGoogleEvent" class="d-flex align-center">
          <GoogleLogo size="16" />
          <span class="ml-1 text-caption">
            {{ selectedEvent.calendar.connection.email }}
          </span>
        </div>
        <!-- 부서 일정 표시 -->
        <div v-if="isOrganEvent">
          <v-chip
            small
            label
            :color="selectedEvent.calendar.color"
            text-color="white"
            class="px-2 rounded-lg"
          >
            {{ selectedEvent.source.owner.accountName }}
          </v-chip>
        </div>
        <v-spacer></v-spacer>

        <!-- 수정 권한 보유 시 버튼 -->
        <template v-if="selectedEvent.hasWritePrivilege && isUsedLeaveEvent">
          <v-tooltip top>
            <template v-slot:activator="{ attrs, on }">
              <v-icon v-bind="attrs" v-on="on" color="grey">
                mdi-pencil-off-outline
              </v-icon>
            </template>
            <span>{{ $t("calendar.연차_수정_불가") }}</span>
          </v-tooltip>
        </template>
        <template v-else-if="selectedEvent.hasWritePrivilege">
          <v-btn fab icon small :color="iconColor" @click="openUpdateConfirm">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn fab icon small :color="iconColor" @click="openDeleteConfirm">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <v-btn
          class="ml-4"
          fab
          icon
          small
          :color="iconColor"
          @click.stop="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-7 pb-6 text-body-2 text--primary">
        <!-- 제목 -->
        <IconField
          dense
          icon="mdi-checkbox-blank"
          :color="selectedEvent.calendar.color"
          class="mb-3"
        >
          <IconFieldContent>
            <span class="text-h6 grey--text text--darken-4 font-weight-bold">
              {{ selectedEvent.name }}
            </span>
            <!-- 연차 정보 -->
            <v-tooltip
              top
              v-if="isSirTeamEvent && selectedEvent.detail.isLeave"
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="pl-2"
                  :color="getLeaveStatColor(selectedEvent.detail.leaveStatus)"
                >
                  {{ getLeaveStatIcon(selectedEvent.detail.leaveStatus) }}
                </v-icon>
              </template>
              <span>
                {{
                  $t(`calendar.연차_상태.${selectedEvent.detail.leaveStatus}`)
                }}
              </span>
            </v-tooltip>

            <!-- 시간 -->
            <span class="d-block text-subtitle-2">
              {{ getFormattedDateTimeText(selectedEvent) }}
            </span>

            <!-- 반복 설정 -->
            <div
              v-if="selectedEvent.isRecurring"
              class="d-flex align-center grey--text"
            >
              <v-icon small>
                mdi-cached
              </v-icon>
              <span class="ml-1">
                {{ getRecurrenceDescriptionText(getRecurObj()) }}
              </span>
            </div>
          </IconFieldContent>
        </IconField>

        <!-- 위치 -->
        <IconField
          v-if="selectedEvent.location"
          icon="mdi-map-marker-outline"
          dense
          :color="iconColor"
        >
          <IconFieldContent>{{ selectedEvent.location }}</IconFieldContent>
        </IconField>

        <!-- 그룹 일정 정보 -->
        <v-expansion-panels
          v-if="selectedEvent.attendees.length"
          :value="0"
          hover
          flat
        >
          <v-expansion-panel>
            <!-- 요약 패널 -->
            <v-expansion-panel-header
              class="px-1 py-0 ml-n1 text-body-2"
              style="min-height: 32px;"
            >
              <IconField
                icon="mdi-account-multiple-outline"
                class="text-truncate"
                dense
                :color="iconColor"
              >
                <div>
                  {{ $t("calendar.주최자") }}
                  {{
                    selectedEvent.organizer.name
                      ? selectedEvent.organizer.name
                      : selectedEvent.organizer.email
                  }}
                  ·
                  {{
                    $t("calendar.64", {
                      value: selectedEvent.attendees.length
                    })
                  }}
                </div>
                <!-- 주최자인 경우 참석상태 요약 표시 -->
                <div
                  class="text-caption"
                  v-if="!selectedEvent.invited"
                  style="letter-spacing: 0.025em !important;"
                >
                  {{ partStatsSummary }}
                </div>
              </IconField>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <IconField dense>
                <v-list class="pt-1 pb-1">
                  <!-- 참석자 목록 -->
                  <v-list-item
                    :key="attendee.name ? attendee.name : attendee.email"
                    v-for="attendee in selectedEvent.attendees"
                    class="pa-0"
                    style="min-height: 20px"
                  >
                    <v-list-item-content class="pt-0 pb-1">
                      <v-list-item-title
                        class="text-body-2 text-truncate"
                        style="line-height: 17px"
                      >
                        <!-- 주최한 일정인 경우 참석자들의 참석 상태 표시 -->
                        <v-tooltip top v-if="!selectedEvent.invited">
                          <template #activator="{ attrs, on }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              :color="getPartStatColor(attendee.partStat)"
                              class="mr-1"
                            >
                              {{ getPartStatIcon(attendee.partStat) }}
                            </v-icon>
                          </template>
                          <span>
                            {{
                              CAL_CONSTANTS.translatePartstat(attendee.partStat)
                            }}
                          </span>
                        </v-tooltip>

                        <span>
                          {{ attendee.name }}
                        </span>
                        <v-chip
                          small
                          label
                          color="grey lighten-3"
                          class="px-2"
                          style="height: 20px;"
                        >
                          {{ attendee.email }}
                        </v-chip>
                        <v-icon
                          v-if="attendee.isOrganizer"
                          dense
                          color="yellow darken-1"
                          class="ml-1"
                        >
                          mdi-star-circle
                        </v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </IconField>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- 내용 -->
        <IconField
          v-if="selectedEvent.description"
          icon="mdi-text"
          dense
          :color="iconColor"
        >
          <IconFieldContent v-html="selectedEvent.description">
          </IconFieldContent>
        </IconField>

        <!-- 캘린더 -->
        <IconField icon="mdi-calendar" dense :color="iconColor">
          <IconFieldContent>
            {{ selectedEvent.calendar.title }}
          </IconFieldContent>
        </IconField>

        <!-- 비공개 여부 -->
        <IconField
          v-if="selectedEvent.isPrivate"
          icon="mdi-lock-outline"
          dense
          :color="iconColor"
        >
          <IconFieldContent>
            {{ $t("calendar.비공개") }}
          </IconFieldContent>
        </IconField>
      </v-card-text>
      <!-- 참석 상태 설정 -->
      <v-card-actions
        v-if="
          (selectedEvent.invited || isUserOrganizerAndAttendee) &&
            selectedEvent.hasWritePrivilege
        "
        class="d-flex align-center grey lighten-4 pl-7 pr-5 py-1"
      >
        <span class="text-subtitle-2">
          {{ $t("calendar.참석여부") }}
        </span>
        <v-spacer></v-spacer>
        <v-chip-group v-model="partStat" mandatory color="accent">
          <v-chip
            :key="val"
            v-for="val in Object.values(CAL_CONSTANTS.ATTENDEE_PARTSTAT)"
            v-show="val !== CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction"
            small
            :value="val"
            @click="handleReply(val)"
          >
            <v-icon v-show="val === partStat" small left>
              {{ getPartStatIcon(val) }}
            </v-icon>
            {{
              $t(
                isUserOrganizerAndAttendee
                  ? `calendar.partStat.organizer.${val}`
                  : `calendar.partStat.attendee.${val}`
              )
            }}
          </v-chip>
        </v-chip-group>
      </v-card-actions>

      <GroupEventMailConfirm
        v-if="showGroupEventMailConfirm"
        v-model="showGroupEventMailConfirm"
        @confirm="deleteEvent"
      />

      <RecurEventTargetConfirm
        v-if="confirmDialog.show"
        v-model="confirmDialog.show"
        :recurConfirm="confirmDialog"
        @confirm="
          val =>
            confirmDialog.onlyPartStat ? editPartStat(val) : editConfirm(val)
        "
        @cancel="() => confirmDialog.onlyPartStat && revertPartStat()"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  CAL_CONSTANTS,
  EVENT_PLATFORM,
  LEAVE_STATUS
} from "@/calendar/constant/calConstants";
import RecurEventTargetConfirm from "./confirm/RecurEventTargetConfirm.vue";
import moment from "moment";
import i18n from "@/_locales";
import {
  getFormattedDateTimeText,
  getRecurrenceDescriptionText
} from "@/calendar/utils/DateUtils";
import { isOrganCalendar } from "@/calendar/utils/CalendarUtils";
import { Frequency, RRule } from "rrule-2.7.2";
import GoogleLogo from "@/commons/views/icons/GoogleLogo.vue";
import IconField from "@/calendar/components/common/IconField.vue";
import IconFieldContent from "@/calendar/components/common/IconFieldContent.vue";
import GroupEventMailConfirm from "@/calendar/components/event/confirm/GroupEventMailConfirm.vue";

export default {
  components: {
    GroupEventMailConfirm,
    IconFieldContent,
    IconField,
    GoogleLogo,
    RecurEventTargetConfirm
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      iconColor: "blue-grey darken-3",
      confirmDialog: {
        show: false,
        title: "",
        type: "delete",
        isRecurring: false,
        onlyPartStat: false,
        partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
        prevPartStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction
      },
      partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
      isUserOrganizerAndAttendee: false,
      isSirTeamEvent: false,
      isOrganEvent: false,
      isGoogleEvent: false,
      isUsedLeaveEvent: false,
      isExceptionRequest: false,
      showGroupEventMailConfirm: false
    };
  },
  computed: {
    ...mapState("cal", ["eventMenu"]),
    ...mapGetters("auth", ["getUserInfo"]),
    CAL_CONSTANTS() {
      return CAL_CONSTANTS;
    },
    selectedEvent() {
      return this.eventMenu.event;
    },
    selectedElement() {
      return this.eventMenu.element;
    },
    partStatsSummary() {
      if (!this.selectedEvent?.attendees) return null;

      const partStatCounts = [
        {
          partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept,
          count: 0
        },
        {
          partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline,
          count: 0
        },
        {
          partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.tentative,
          count: 0
        },
        {
          partStat: CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction,
          count: 0
        }
      ];
      for (const attendee of this.selectedEvent.attendees) {
        switch (attendee.partStat) {
          case CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept:
            partStatCounts[0].count++;
            break;
          case CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline:
            partStatCounts[1].count++;
            break;
          case CAL_CONSTANTS.ATTENDEE_PARTSTAT.tentative:
            partStatCounts[2].count++;
            break;
          case CAL_CONSTANTS.ATTENDEE_PARTSTAT.needsAction:
            partStatCounts[3].count++;
            break;
        }
      }

      let result = "";
      for (const item of partStatCounts) {
        if (item.count > 0) {
          result += `${result.length ? ", " : ""}
              ${this.$t(`calendar.partStat.summary.${item.partStat}`, {
                value: item.count
              })}`;
        }
      }

      return result;
    }
  },
  created() {
    this.initBySelectedEvent();
  },
  watch: {
    value() {
      this.$refs.menu.runDelay(this.value ? "open" : "close");
    },
    selectedEvent() {
      this.initBySelectedEvent();
    }
  },
  methods: {
    getRecurrenceDescriptionText,
    getFormattedDateTimeText,
    ...mapActions("confirm", {
      calReplyConfirm: "confirm",
      calReplyConfirmAgree: "agree",
      calReplyConfirmDisagree: "disagree"
    }),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("cal", [
      "deleteEventFromServer",
      "updateEventToServer",
      "sendCalReplyMail",
      "updatePartStat"
    ]),
    getPartStatIcon(partstat) {
      switch (partstat) {
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept:
          return "mdi-check-circle";
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.tentative:
          return "mdi-help-circle";
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline:
          return "mdi-close-circle";
        default:
          return "mdi-dots-horizontal-circle";
      }
    },
    getPartStatColor(partstat) {
      switch (partstat) {
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.accept:
          return "green";
        case CAL_CONSTANTS.ATTENDEE_PARTSTAT.decline:
          return "red";
        default:
          return "grey";
      }
    },
    getLeaveStatIcon(leaveStatus) {
      switch (leaveStatus) {
        case LEAVE_STATUS.CONFIRMED:
          return "mdi-check-underline";
        case LEAVE_STATUS.DENIED:
          return "mdi-close";
        default:
          return "mdi-dots-horizontal-circle";
      }
    },
    getLeaveStatColor(leaveStatus) {
      switch (leaveStatus) {
        case LEAVE_STATUS.CONFIRMED:
          return "green";
        case LEAVE_STATUS.DENIED:
          return "red";
        default:
          return "grey";
      }
    },
    // 일반/반복 일정 편집 확인창
    openDeleteConfirm() {
      this.confirmDialog.type = "delete";
      this.confirmDialog.isRecurring =
        this.selectedEvent.isRecurring || this.selectedEvent.modifiesId;
      if (this.confirmDialog.isRecurring) {
        this.confirmDialog.title = this.$t("calendar.recur.confirm.delete");
      } else {
        this.confirmDialog.title = this.$t("calendar.65", {
          value: this.selectedEvent.name
        });
      }
      this.confirmDialog.onlyPartStat = false;
      this.confirmDialog.show = true;
    },
    openUpdateConfirm() {
      if (!this.selectedEvent.isRecurring) {
        this.isExceptionRequest = false;
        this.openEventEditor();
        return;
      }

      this.confirmDialog.type = "update";
      this.confirmDialog.title = this.$t("calendar.recur.confirm.update");
      this.confirmDialog.isRecurring =
        this.selectedEvent.isRecurring || this.selectedEvent.modifiesId;
      this.confirmDialog.onlyPartStat = false;
      this.confirmDialog.show = true;
    },
    async editConfirm(isExceptionRequest) {
      this.isExceptionRequest = isExceptionRequest;
      if (this.confirmDialog.type !== "delete") {
        this.openEventEditor();
        return;
      }

      if (
        this.selectedEvent.attendees.length > 0 &&
        !this.selectedEvent.invited
      ) {
        this.showGroupEventMailConfirm = true;
      } else {
        await this.deleteEvent(false);
      }
    },
    async deleteEvent(confirmedSendMail) {
      const param = {
        calendarId: this.selectedEvent.calendar.id,
        eventUId: this.selectedEvent.id,
        exDate: this.generateExDateToApply(),
        isExceptionRequest: this.isExceptionRequest,
        confirmedSendMail: confirmedSendMail,
        isOrganizer: !this.selectedEvent.invited
      };

      await this.deleteEventFromServer(param);
      this.$emit("input", false);
    },
    openEventEditor() {
      this.$emit("open:eventEditor", {
        event: this.selectedEvent,
        element: this.selectedElement,
        isExceptionRequest: this.isExceptionRequest
      });
      this.$emit("input", false);
    },
    // confirm 다이얼로그창 생성 함수
    openReplyConfirmDialog(
      headline,
      message,
      callback = () => {},
      closeCallback = () => {}
    ) {
      this.calReplyConfirm({ headline, message, callback, closeCallback });
    },
    handleReply(partStat) {
      if (this.partStat === partStat) return;
      this.confirmDialog.partStat = partStat;
      this.confirmDialog.prevPartStat = this.partStat;

      this.confirmDialog.isRecurring =
        this.selectedEvent.isRecurring || this.selectedEvent.modifiesId;
      if (this.confirmDialog.isRecurring) {
        this.confirmDialog.title = this.$t("calendar.recur.confirm.update");
        this.confirmDialog.onlyPartStat = true;
        this.confirmDialog.show = true;
      } else {
        /*
         * 반복되지 않고 modifiesId가 0이지만, 반복 ID가 존재하는 경우 예외 일정에만 초대된 참석자의 일정입니다.
         * 해당 일정은 반복 ID를 항상 전송해야 합니다.
         */
        this.editPartStat(!!this.selectedEvent.detail.recurrenceId);
      }
    },
    editPartStat(isExceptionRequest) {
      this.isExceptionRequest = isExceptionRequest;
      if (this.isUserOrganizerAndAttendee) {
        this.updateOrganizerPartStat(this.confirmDialog.partStat);
      } else {
        this.replyPartStat(
          this.confirmDialog.partStat,
          this.confirmDialog.prevPartStat
        );
      }
    },
    async updateOrganizerPartStat(partStat) {
      const param = {
        id: this.selectedEvent.source.id,
        partStat
      };
      if (this.isExceptionRequest) {
        param.exDate = this.generateExDateToApply();
      }

      await this.updatePartStat({
        param,
        calendarId: this.selectedEvent.calendar.id
      });

      // 현재 일정 메뉴가 참조하는 일정 객체는 변경 전 데이터이므로
      // 사용자가 다시 클릭하여 최신 일정 객체를 불러오도록 유도합니다.
      this.$emit("input", false);
    },
    async replyPartStat(partStat, prevPartStat) {
      const param = {
        organizerAdr: this.selectedEvent.detail.organizer.email,
        partStat: partStat,
        iCal: this.selectedEvent.iCal,
        eventId: this.selectedEvent.source.id
      };
      if (this.isExceptionRequest) {
        param.exDate = this.generateExDateToApply();
      }

      if (partStat === "DECLINED") {
        this.openReplyConfirmDialog(
          "",
          i18n.t("calendar.67"),
          async () => {
            this.calReplyConfirmDisagree();
            const result = await this.sendCalReplyMail(param);

            if (result) {
              this.openSnackbar({
                message: i18n.t("calendar.7"),
                type: "SUCCESS"
              });
            } else {
              this.partStat = prevPartStat;
              this.openSnackbar({
                message: i18n.t("calendar.8"),
                type: "ERROR"
              });
            }
            this.$emit("input", false);
          },
          () => (this.partStat = prevPartStat)
        );
      } else {
        await this.sendCalReplyMail(param);
        this.$emit("input", false);
      }
    },
    revertPartStat() {
      this.partStat = this.confirmDialog.prevPartStat;
    },
    initBySelectedEvent() {
      if (!this.selectedEvent) return;

      this.isSirTeamEvent =
        this.selectedEvent.platform === EVENT_PLATFORM.SIRTEAM;
      this.isOrganEvent = isOrganCalendar(this.selectedEvent.calendar);
      this.isGoogleEvent =
        this.selectedEvent.platform === EVENT_PLATFORM.GOOGLE;
      this.isUsedLeaveEvent =
        this.isSirTeamEvent &&
        this.selectedEvent.detail.isLeave &&
        this.selectedEvent.detail.leaveStatus === LEAVE_STATUS.CONFIRMED &&
        this.selectedEvent.start.getTime() < new Date().getTime();

      this.isUserOrganizerAndAttendee = false;
      this.selectedEvent.attendees.forEach(attendee => {
        if (attendee.email === this.getUserInfo.username) {
          this.partStat = attendee.partStat || "NEEDS-ACTION";
          if (attendee.email === this.selectedEvent.organizer.email) {
            this.isUserOrganizerAndAttendee = true;
          }
        }
      });
    },
    getRecurObj() {
      if (this.selectedEvent.platform === EVENT_PLATFORM.SIRTEAM) {
        const recur = this.selectedEvent.detail.recurrence;
        return {
          freq: recur.freq,
          interval: recur.interval,
          count: recur.count,
          until: recur.until,
          parts: {
            BYDAY: recur.byDay,
            BYMONTH: recur.byMonth,
            BYMONTHDAY: recur.byMonthDay
          }
        };
      }

      if (this.selectedEvent.platform === EVENT_PLATFORM.GOOGLE) {
        const options = RRule.parseString(
          this.selectedEvent.source.recurrence[0]
        );
        const freq = options.freq;
        let byMonth = options.bymonth;
        let byMonthDay = options.bymonthday;
        if (freq === Frequency.MONTHLY || freq === Frequency.YEARLY) {
          if (!byMonth) {
            byMonth = this.selectedEvent.start.getMonth() + 1;
          }
          if (!byMonthDay) {
            byMonthDay = this.selectedEvent.start.getDate();
          }
        }

        return {
          freq: Frequency[freq],
          interval: options.interval,
          count: options.count,
          until: options.until,
          parts: {
            BYDAY: Array.isArray(options.byweekday)
              ? options.byweekday.map(item => item.toString())
              : options.byweekday?.toString(),
            BYMONTH: byMonth,
            BYMONTHDAY: byMonthDay
          }
        };
      }

      return null;
    },
    generateExDateToApply() {
      const date = moment(this.selectedEvent.start);
      return this.selectedEvent.isAllDay
        ? date.format("YYYYMMDD")
        : date.format("YYYYMMDDTHHmmss");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/simple-scrollbar.scss";

.v-card__text {
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: auto;
}

span {
  vertical-align: middle;
}

.v-sheet {
  user-select: text;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
