import { getKanbanColumns, updateColumns } from "@/todo/api/kanban.api";

const state = {
  columns: [],
  divideGroup: false
};

const getters = {
  columns: ({ columns }) => columns,
  divideGroup: ({ divideGroup }) => divideGroup,
  headers: (s, g, { todoHeader }) =>
    todoHeader.header.filter(h => h.type != "ADDITIONAL" && h.type != "TITLE"),
  displayHeaders: ({ columns }, g, { todoHeader }) =>
    todoHeader.header.filter(h => columns.includes(h.value)),
  headerIcons: () => ({
    PERSON: "mdi-account-multiple-outline",
    STATUS: "mdi-playlist-check",
    TIMELINE: "mdi-chart-timeline",
    NUMBER: "mdi-counter",
    CREATED_LOG: "mdi-clock-outline",
    TEXT: "mdi-format-text",
    LINK: "mdi-link-variant",
    CHECK: "mdi-check-bold"
  })
};

const mutations = {
  SET_COLUMNS: (state, { columns }) => {
    if (!columns) return (state.columns = []);
    if (Array.isArray(columns)) state.columns = columns;
    else state.columns = columns.split(",");
  }
};

const actions = {
  async getKanbanColumns({ commit }, boardId) {
    const { data, status } = await getKanbanColumns(boardId);

    if (status !== 200) return;
    commit("SET_COLUMNS", data);
  },
  async updateColumns({ commit }, { boardId, columns }) {
    const { data, status } = await updateColumns(boardId, columns.join(","));

    if (status !== 201) return;
    commit("SET_COLUMNS", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
