<template>
  <v-tabs
    v-bind="props"
    :value="selectedIndex"
    class="rounded-xl"
    color="primary--text"
    background-color="blue-grey lighten-5"
    slider-color="grey lighten-5"
    centered
    @change="val => $emit('input', items[val].value)"
  >
    <v-tab v-for="item in items" :key="item.value" :ripple="false">
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    props: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    selectedIndex() {
      return this.items.findIndex(item => item.value === this.value);
    }
  }
};
</script>

<style scoped lang="scss">
@import "vuetify/src/styles/styles.sass";

.v-tabs ::v-deep {
  .v-tabs-bar {
    padding: 0 4px;
  }

  .v-tabs-slider-wrapper {
    height: 100% !important;
    padding: 4px 0;
    z-index: 0;

    .v-tabs-slider {
      border-radius: map-get($rounded, "xl");
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1),
        0 2px 2px 0 rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
    }
  }

  .v-tab {
    min-width: 40px !important;
    padding: 0 12px;

    &::before {
      background-color: transparent !important;
    }
  }
}
</style>
