import router from "@/commons/router";
import i18n from "@/_locales";

const state = {
  tabType: "",
  tabs: [
    { tabId: "info", name: "계정_정보" },
    { tabId: "security", name: "계정_보안" },
    { tabId: "account_history", name: "계정_접근_이력" },
    { tabId: "service", name: "서비스_설정" }
  ],
  goQuota: false
};

const getters = {
  getTabType: ({ tabType }) => tabType,
  getTabs: ({ tabs }) =>
    tabs.map(t => ({ ...t, name: i18n.t(`account.${t.name}`) })),
  getGoQuota: ({ goQuota }) => goQuota
};
const mutations = {
  SET_TABS: (state, tabs) => (state.tabs = tabs),
  SET_TAB_TYPE: (state, type) => (state.tabType = type),
  SET_GO_QUOTA: (state, goQuota) => (state.goQuota = goQuota),
  RESET_DATA: state => {
    state.tabType = "";
    state.goQuota = false;
  }
};
const actions = {
  async setParams({ getters, commit }, params) {
    // 메일, 드라이브에 하단 용량바 클릭시,
    // 계정정보 페이지에 스크롤을 하단으로 내려 용량정보를 보이기 위한 변수
    if (params?.goQuota || false) {
      commit("SET_GO_QUOTA", params?.goQuota);
    }

    const isExistTab = getters.getTabs.filter(
      tab => tab.tabId == params.tabType
    );

    // 잘못 된 tabType일 경우
    if (isExistTab.length == 0) {
      return router.push({
        name: "account",
        params: { tabType: "info" }
      });
    }

    commit("SET_TAB_TYPE", params.tabType);
  },
  routeAccountTab(getters, { tabId }) {
    router.push({
      name: "account",
      params: {
        tabType: tabId || "info"
      }
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
