<template>
  <div class="cr-app-menu-wrapper mb-5">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="cr-btn"
          v-bind="attrs"
          v-on="on"
          icon
          @click="gotoLink(item.link)"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </template>

      <span>{{ item.title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    gotoLink(link) {
      window.open(link);
    }
  }
};
</script>

<style lang="scss" scoped>
/* 앱 아이콘 */
.cr-app-menu-wrapper::v-deep {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  padding: 0;
  margin: 0;
  width: 81px;

  // 버튼
  .cr-btn {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-color: #fff;
    overflow: hidden;
  }

  // 마우스 오버
  .cr-btn {
    &:hover {
      background-color: var(--v-accent-base);

      cursor: pointer;
      box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.09),
        0 1px 1px 0 rgba(0, 0, 0, 0.09), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      .v-icon {
        color: white;
      }
    }
  }
}
</style>
