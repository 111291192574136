<template>
  <div class="text-center cr-tutorial-appmenu" v-show="visible">
    <v-menu
      absolute
      content-class="apps-menu"
      :position-y="$vuetify.breakpoint.xs ? 56 : 64"
    >
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom :disabled="$vuetify.breakpoint.xs">
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
              <v-icon>mdi-apps</v-icon>
            </v-btn>
          </template>
          {{ $vuetify.breakpoint.xs ? "" : $t("common.앱") }}
        </v-tooltip>
      </template>

      <v-card>
        <v-container fluid>
          <v-row>
            <div
              class="cr-app-menu-wrapper"
              v-for="(item, index) in items"
              :key="index"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="cr-btn-wrapper">
                    <div
                      class="cr-btn"
                      v-bind="attrs"
                      v-on="on"
                      :class="[item.name, item.selected]"
                      @click="routeModule(item.name)"
                    >
                      <component
                        :is="components[item.name]"
                        :size="components[item.size]"
                      />
                    </div>
                    <div class="cr-btn-title mt-2">
                      {{ item.title }}
                    </div>
                  </div>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters } from "vuex";
import storage from "@/commons/api/storage";

export default {
  components: {},
  props: {
    components: {
      type: Object,
      default() {
        return {
          home: () => import("@/commons/views/icons/Home.vue"),
          mail: () => import("@/commons/views/icons/Mail.vue"),
          cal: () => import("@/commons/views/icons/Calendar.vue"),
          contact: () => import("@/commons/views/icons/Contact.vue"),
          drive: () => import("@/commons/views/icons/Drive.vue"),
          approval: () => import("@/commons/views/icons/Approval.vue"),
          customer: () => import("@/commons/views/icons/Customer.vue"),
          board: () => import("@/commons/views/icons/Board.vue"),
          todo: () => import("@/commons/views/icons/Todo.vue"),
          resource: () => import("@/commons/views/icons/Resource.vue")
        };
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getUsedModule", "getCompanyInfo"]),
    items() {
      let title = {
        home: i18n.t("common.대시보드"),
        mail: i18n.t("common.메일"),
        cal: i18n.t("common.캘린더"),
        contact: i18n.t("common.주소록"),
        drive: i18n.t("common.드라이브"),
        approval: i18n.t("common.전자결재"),
        customer: i18n.t("common.공용주소록"),
        board: i18n.t("common.게시판"),
        todo: i18n.t("common.워크플로"),
        flow: i18n.t("common.워크플로"),
        resource: i18n.t("common.시설예약")
      };

      // FIXME: 알파테스트 서비스는 크리니티만 임시적으로 오픈.
      const companyInfo = this.getCompanyInfo;
      if (companyInfo.id !== 1) {
        delete title.approval;
      }

      return Object.keys(this.getUsedModule)
        .filter(key => this.getUsedModule[key])
        .map(key => ({
          title: title[key],
          name: key,
          selected: (this.$route.name || "").startsWith(key) ? "selected" : ""
        }));
    },
    visible() {
      if (this.getCompanyInfo.planType === "BASIC") {
        return false;
      }
      if (this.getCompanyInfo.planType === "CUSTOM") {
        return this.getCompanyInfo.customizeUI.flagAppFolder === 1;
      }
      return true;
    }
  },
  methods: {
    routeModule(name) {
      if (name == "todo") {
        const workspaceId = parseInt(storage.getTodoWorkspaceId());
        if (workspaceId > 0) {
          this.$router.push({
            name: "todo_workspace",
            params: { workspaceId }
          });
          return;
        }
      }

      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.apps-menu {
  left: auto !important;
  right: 0;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

  // 테두리
  .v-card {
    padding: 15px;
    max-width: 245px;
    border-radius: 20px 0 0 20px;
  }

  // 메뉴
  .cr-app-menu-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    padding: 12px;
  }
  .cr-btn-wrapper::v-deep {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 93px;

    // 메뉴 명
    .cr-btn-title {
      text-align: center;
      font-weight: normal;
      font-size: 13px;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      letter-spacing: -1px;
    }

    // 메뉴 버튼
    .cr-btn {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 70px;
      height: 70px;
      border-radius: 16px;
      background-color: #fff;
      overflow: hidden;
      svg {
        width: 50%;
      }
    }

    .cr-btn {
      &.selected {
        background-color: var(--v-primary-base);
        svg path,
        svg circle {
          fill: #fff;
        }
      }
      svg path,
      svg circle {
        fill: var(--v-primary-base);
        filter: brightness(120%);
      }
    }

    // 마우스 오버
    .cr-btn {
      &:hover {
        background-color: var(--v-accent-base);
        filter: brightness(1.2);

        cursor: pointer;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.19),
          0 1px 1px 0 rgba(0, 0, 0, 0.19), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        svg path,
        svg circle {
          fill: #fff;
        }
      }
    }
  }
}
</style>
