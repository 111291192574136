import store from "@/commons/store";

const pages = {
  MAIN: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/ApprovalMain.vue"
    ),
  HOME: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/DashboardPage"
    ),
  NOT_ALLOWED: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/NotAllowedPage"
    ),
  NOT_FOUND: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/NotFoundPage"
    ),
  CONFIG: () =>
    import(/* webpackChunkName: "approval_main" */ "../views/pages/ConfigPage"),
  PRESET_CONFIG: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/ConfigPage/PresetConfigPage"
    ),
  DOCUMENT_LIST: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/DocumentListPage.vue"
    ),
  DOCUMENT_VIEW: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/DocumentViewPage.vue"
    ),
  PRINT_DOCUMENT_VIEW: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/DocumentViewPrintPage.vue"
    ),
  FORM_SELECT: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/FormSelectPage.vue"
    ),
  DRAFT: () =>
    import(
      /* webpackChunkName: "approval_main" */ "../views/pages/DraftPage.vue"
    )
};

export default [
  {
    // 메인 (대시보드)
    path: "/approval",
    name: "approval",
    redirect: { name: "approval_home" },
    component: pages.MAIN,
    children: [
      // 홈
      {
        path: "home",
        name: "approval_home",
        meta: {
          reload: async () => await store.dispatch("approvalDashboard/init")
        },
        component: pages.HOME
      },
      // 설정
      {
        path: "config",
        name: "approval_config",
        redirect: { name: "approval_config_preset" },
        component: pages.CONFIG,
        children: [
          {
            path: "preset",
            name: "approval_config_preset",
            component: pages.PRESET_CONFIG
          }
        ]
      },
      // 열람 권한 없음
      {
        path: "not-allowed",
        name: "approval_not_allowed",
        component: pages.NOT_ALLOWED
      },
      // 문서가 존재하지 않음
      {
        path: "not-found",
        name: "approval_not_found",
        component: pages.NOT_FOUND
      },
      // 문서 목록
      {
        path: "doc/list/:boxKey",
        name: "approval_document_list",
        props: route => extractDocumentListProps(route),
        meta: {
          reload: async route => {
            const props = extractDocumentListProps(route);
            await store.dispatch("approvalDocumentList/init", props);
          }
        },
        component: pages.DOCUMENT_LIST
      },
      // 문서 보기
      {
        path: "doc/list/:boxKey/:docId",
        name: "approval_document_view",
        props: route => extractDocumentViewProps(route),
        meta: {
          reload: async route => {
            const docId = extractDocumentViewProps(route)?.docId;
            await store.dispatch("approvalDocumentView/init", docId);
          }
        },
        component: pages.DOCUMENT_VIEW
      },
      // 문서 보기 (preview)
      {
        path: "doc/preview/:docId",
        name: "approval_document_preview",
        props: route => extractDocumentViewProps(route),
        meta: {
          reload: async route => {
            const docId = extractDocumentViewProps(route)?.docId;
            await store.dispatch("approvalDocumentView/init", docId);
          }
        },
        component: pages.DOCUMENT_VIEW
      },
      // 문서 보기 (인쇄)
      {
        path: "doc/print/:docId",
        name: "approval_document_view_print",
        props: true,
        meta: {
          reload: null
        },
        component: pages.PRINT_DOCUMENT_VIEW
      },
      // 기안 수정
      {
        path: "doc/list/:boxKey/:docId/edit",
        name: "approval_draft_edit",
        props: route => ({
          docId: route.params.docId,
          draftType: "EDIT"
        }),
        component: pages.DRAFT
      },
      // 내부 기안 작성
      {
        path: "draft/inner",
        name: "approval_draft_inner",
        props: route => ({
          ...extractDraftProps(route),
          draftType: "INNER_DRAFT"
        }),
        component: pages.DRAFT
      },
      // 재기안
      {
        path: "draft/reuse",
        name: "approval_draft_reuse",
        props: route => ({
          ...extractDraftProps(route),
          draftType: "REUSE"
        }),
        component: pages.DRAFT
      },
      // 기안 작성: {formId} 양식으로
      {
        path: "draft/:formId",
        name: "approval_draft",
        props: route => ({
          ...extractDraftProps(route),
          draftType: "DRAFT"
        }),
        component: pages.DRAFT
      },
      // 기안 양식 선택
      {
        path: "form/select",
        name: "approval_form_select",
        component: pages.FORM_SELECT
      }
    ]
  }
];

// 문서목록 페이지 props 추출
const extractDocumentListProps = ({ params, query }) => {
  const userConfig = store.state.approvalConfig.basicConfig || {};
  const { boxKey } = params || {};
  const {
    page,
    size = userConfig.pageSize || 10,
    sort,
    dir,
    draftFromDate,
    draftToDate,
    formId,
    keywordFlag,
    userId,
    organId,
    name,
    email,
    docNum,
    title
  } = query || {};
  // 상세검색 [키워드] 추출
  let keyword;
  switch (keywordFlag) {
    case "DRAFT_USER_ID":
      keyword = userId
        ? {
            userId: parseToInt(userId),
            name,
            email
          }
        : undefined;
      break;
    case "DRAFT_ORGAN_ID":
      keyword = organId
        ? {
            organId,
            name,
            email
          }
        : undefined;
      break;
    case "DOC_NUM":
      keyword = docNum ? { docNum } : undefined;
      break;
    case "TITLE":
      keyword = title ? { title } : undefined;
      break;
    default:
      keyword = undefined;
  }
  // props 반환
  return {
    boxKey,
    page: parseToInt(page),
    size: parseToInt(size),
    sort,
    dir,
    draftFromDate,
    draftToDate,
    formId: parseToInt(formId),
    keywordFlag,
    keyword
  };
};

// 문서보기 페이지 props 추출
const extractDocumentViewProps = ({ params }) => {
  const { boxKey, docId } = params || {};
  return { boxKey, docId: parseToInt(docId) };
};

// 기안상신 페이지 props 추출
const extractDraftProps = ({ params, query }) => {
  const { formId } = params || {};
  const { originDocId, originLineId, docId } = query;
  return {
    formId: parseToInt(formId),
    originDocId: parseToInt(originDocId),
    originLineId: parseToInt(originLineId),
    docId: parseToInt(docId)
  };
};

// route.params 는 문자열만 반환하기 떄문에 정수타입일 경우 변환
const parseToInt = str => {
  const num = Number(str);
  if (isNaN(num)) {
    return undefined;
  } else {
    return num;
  }
};
