<template>
  <v-tab-item>
    <div class="cr-emoji-picker">
      <div v-for="(category, idx) in categories" :key="category">
        <h5 :class="{ 'mt-3': idx != 0 }">{{ category }}</h5>
        <div class="cr-emojis">
          <span
            v-for="{ emoji, emojiName } in emojis[category]"
            :key="emojiName"
            :title="emojiName"
            @click="$emit('update:emoji', emoji)"
          >
            {{ emoji }}
          </span>
        </div>
      </div>
    </div>
  </v-tab-item>
</template>

<style lang="scss" scoped>
.cr-emoji-picker {
  width: 330px;
  height: 240px;
  overflow: hidden auto;
  padding: 8px;

  h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }

  .cr-emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
      cursor: pointer;
      padding: 0.2rem;
      border-radius: 5px;
      font-size: 25px;
      &:hover {
        cursor: pointer;
        transform: scale(1.5);
      }
    }

    &:after {
      content: "";
      flex: auto;
    }
  }
}
</style>

<script>
import emojiData from "./emoji";

export default {
  mounted() {
    Object.keys(emojiData).forEach(category => {
      this.emojis[category] = Object.keys(emojiData[category]).map(
        emojiName => ({
          emojiName,
          emoji: emojiData[category][emojiName]
        })
      );
    });

    this.categories = Object.keys(emojiData);
  },
  data() {
    return {
      categories: [],
      emojis: {}
    };
  }
};
</script>
