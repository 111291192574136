import i18n from "@/_locales";

const state = {
  show: false, // on/off
  title: "", // 타이틀
  type: "", // 다이얼로그 타입
  params: {}, // 파라미터

  // 다이얼로그 하단 더보기 버튼
  showExpand: false,
  // 다이얼로그 하단 우측 취소버튼(우측기준 두번째버튼)
  cancelBtn: { show: true, text: "" },
  // 다이얼로그 하단 우측 저장버튼(우측기준 첫번째버튼)
  confirmBtn: { show: true, text: "" },
  confirmCallbackFunc: () => {}
};

const getters = {
  showDialog: ({ show }) => show,
  dialogTitle: ({ title }) => title,
  dialogType: ({ type }) => type,
  getParams: ({ params }) => params,
  isShowExpand: ({ showExpand }) => showExpand,
  getCancelBtn: ({ cancelBtn: { show, text } }) => ({
    show,
    text: text || i18n.t("common.취소")
  }),
  getConfirmBtn: ({ confirmBtn: { show, text } }) => ({
    show,
    text: text || i18n.t("common.저장")
  })
};

const mutations = {
  SET_DIALOG: (
    state,
    {
      title,
      type,
      params,
      showExpand = false,
      cancelBtn,
      confirmBtn,
      confirmCallbackFunc = () => {}
    }
  ) => {
    state.show = true;
    state.title = title;
    state.type = type || state.type;
    state.params = params || state.params;
    state.showExpand = showExpand;
    state.cancelBtn = cancelBtn || state.cancelBtn;
    state.confirmBtn = confirmBtn || state.confirmBtn;
    state.confirmCallbackFunc = confirmCallbackFunc;
  },
  SET_SHOW_EXPAND: (state, value) => {
    state.showExpand = value;
  },
  CLOSE_DIALOG: state => {
    state.show = false;
    state.title = "";
    state.type = "";
    state.params = {};
    state.showExpand = false;
    state.cancelBtn = {
      show: true,
      text: i18n.t("common.취소")
    };
    state.confirmBtn = {
      show: true,
      text: i18n.t("contact.저장")
    };
    state.confirmCallbackFunc = () => {};
  }
};

const actions = {
  // 콜백함수
  confirmCallback({ state }, item) {
    state.confirmCallbackFunc(item);
  },
  async close({ commit }) {
    commit("CLOSE_DIALOG");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
