<template>
  <v-dialog
    v-if="show"
    :value="show"
    max-width="396px"
    width="380px"
    @input="() => closeDialog(null)"
    @keydown.esc="() => closeDialog(null)"
  >
    <v-card>
      <v-card-title>
        {{ $t("calendar.79") }}
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row align="center">
          <v-col cols="4" class="text--primary">
            {{ $t("calendar.recur.period") }}
          </v-col>
          <v-col cols="auto" class="d-flex align-center">
            <FlatTextField
              v-model="interval"
              type="number"
              min="1"
              step="1"
              style="max-width: 64px"
            />
            <FlatSelect
              v-model="freq"
              :items="freqList"
              item-text="name"
              :menu-props="{
                offsetY: true
              }"
              :btn-props="{
                text: true,
                'min-width': '72px',
                class: 'grey lighten-4 ml-2'
              }"
              @input="onChangeFreq"
            />
            <span v-if="i18n().locale === 'ko'" class="ml-1">
              {{ $t("calendar.recur.every") }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="freq === 'WEEKLY'">
          <v-col cols="12">
            <span class="d-block text--primary">
              {{ $t("calendar.recur.dayOfWeek") }}
            </span>
            <v-chip-group v-model="weekdays" mandatory multiple column>
              <v-chip
                v-for="item in dayList"
                :key="item.value"
                :value="item"
                active-class="primary--text"
                color="grey lighten-4"
                text-color="grey"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row v-if="freq === 'MONTHLY'" class="mt-0">
          <v-col cols="4" />
          <v-col cols="auto">
            <FlatSelect
              v-model="freqSubType"
              :items="getMonthlyOptions"
              :menu-props="{
                offsetY: true
              }"
              :btn-props="{
                text: true,
                class: 'grey lighten-4'
              }"
            />
          </v-col>
        </v-row>
        <v-row v-if="freq === 'YEARLY'" class="mt-0">
          <v-col cols="4" />
          <v-col cols="auto">
            <FlatSelect
              v-model="freqSubType"
              :items="getYearlyOptions"
              :menu-props="{
                offsetY: true
              }"
              :btn-props="{
                text: true,
                class: 'grey lighten-4'
              }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text--primary">
            {{ $t("calendar.recur.end") }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-radio-group
              v-model="endType"
              hide-details
              mandatory
              dense
              class="ma-0"
            >
              <v-radio value="infinity">
                <template #label>
                  <span class="end-type-title">
                    {{ $t("calendar.recur.endType.never") }}
                  </span>
                </template>
              </v-radio>
              <v-radio value="count">
                <template #label>
                  <span class="end-type-title">
                    {{ $t("calendar.recur.endType.count") }}
                  </span>
                  <div class="d-flex align-center">
                    <FlatTextField
                      v-model="count"
                      type="number"
                      min="1"
                      step="1"
                      style="max-width: 72px"
                      :disabled="endType !== 'count'"
                    />
                    <span class="ml-1">
                      {{ $t("calendar.80") }}
                    </span>
                  </div>
                </template>
              </v-radio>
              <v-radio value="until">
                <template #label>
                  <span class="end-type-title">{{
                    $t("calendar.recur.endType.until")
                  }}</span>
                  <div class="d-flex align-center">
                    <FilledDateTimePicker
                      :date.sync="until"
                      :date-btn-props="{
                        class: 'grey lighten-4',
                        disabled: endType !== 'until'
                      }"
                    />
                    <span class="ml-1">
                      {{ $t("calendar.recur.repeatUntil") }}
                    </span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="() => closeDialog(null)">
          {{ $t("common.닫기") }}
        </v-btn>
        <v-btn outlined color="accent" @click="save">
          {{ $t("common.저장") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";
import moment from "moment";
import i18n from "@/_locales";
import { dayOfMonthToText } from "@/calendar/utils/DateUtils";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";
import FlatTextField from "@/calendar/components/common/FlatTextField.vue";
import FilledDateTimePicker from "@/calendar/components/common/FilledDateTimePicker.vue";

const SUB_TYPE = Object.freeze({
  WEEKDAY_OF_WEEK_IN_MONTH: 0,
  DAY_OF_MONTH: 1,
  LAST_WEEKDAY_OF_MONTH: 2,
  LAST_DAY_OF_MONTH: 3
});
export default {
  components: { FilledDateTimePicker, FlatTextField, FlatSelect },
  props: ["show", "recurObj", "startDate", "isAllDay"],
  data: () => ({
    freq: "DAILY",
    // WEEKLY 유형에 사용 [MO, TU, ...]
    weekdays: [],
    // MONTHLY, YEARLY 유형에 사용 [1MO], [1TU], ...
    weekdayNums: [],
    freqSubType: SUB_TYPE.WEEKDAY_OF_WEEK_IN_MONTH,
    monthOfYear: 1,
    weekOfMonth: 1,
    dayOfMonth: 1,
    dayOfWeek: 0,
    interval: 1,
    endType: "infinity",
    count: 10,
    until: "",
    freqList: CAL_CONSTANTS.FREQ_LIST.map(freq => ({
      value: freq,
      name: i18n.t(`calendar.recur.type.${freq}`)
    })),
    dayList: CAL_CONSTANTS.DAY_LIST.map(day => ({
      value: day,
      name: i18n.t(`calendar.recur.days.${day}`)
    }))
  }),
  computed: {
    getMonthlyOptions() {
      return [
        {
          text: this.$t("calendar.82", {
            value: dayOfMonthToText(this.dayOfMonth)
          }),
          value: SUB_TYPE.DAY_OF_MONTH
        },
        {
          text: this.$t("calendar.83", {
            number: dayOfMonthToText(this.weekOfMonth),
            week: this.dayList[this.dayOfWeek].name
          }),
          value: SUB_TYPE.WEEKDAY_OF_WEEK_IN_MONTH
        },
        {
          text: this.$t("calendar.115", {
            weekday: this.dayList[this.dayOfWeek].name
          }),
          value: SUB_TYPE.LAST_WEEKDAY_OF_MONTH
        },
        {
          text: this.$t("calendar.81"),
          value: SUB_TYPE.LAST_DAY_OF_MONTH
        }
      ];
    },
    getYearlyOptions() {
      return [
        {
          text: i18n.t("calendar.84", {
            month: i18n.d(new Date(0, this.monthOfYear - 1), {
              month: "short"
            }),
            day: dayOfMonthToText(this.dayOfMonth)
          }),
          value: SUB_TYPE.DAY_OF_MONTH
        },
        {
          text: this.$t("calendar.85", {
            month: i18n.d(new Date(0, this.monthOfYear - 1), {
              month: "short"
            }),
            number: dayOfMonthToText(this.weekOfMonth),
            week: this.dayList[this.dayOfWeek].name
          }),
          value: SUB_TYPE.WEEKDAY_OF_WEEK_IN_MONTH
        }
      ];
    }
  },
  created() {
    const date = moment(this.startDate);
    this.monthOfYear = date.month() + 1;
    this.dayOfMonth = date.date();
    this.weekOfMonth = Math.ceil(date.date() / 7);
    this.dayOfWeek = date.day();
    this.until = this.startDate;
    this.weekdays = [this.dayList[this.dayOfWeek]];

    if (this.recurObj) {
      this.initByRecurObj(this.recurObj);
    }
  },
  methods: {
    i18n() {
      return i18n;
    },
    initByRecurObj(recur) {
      this.freq = recur.freq;
      this.interval = recur.interval > 0 ? recur.interval : 1;

      const byDays = recur.parts["BYDAY"];
      const byMonth = recur.parts["BYMONTH"];
      const byMonthDay = +recur.parts["BYMONTHDAY"];

      if (byDays?.length) {
        if (recur.freq === "WEEKLY") {
          this.weekdays = this.dayList.filter(dayInfo =>
            byDays.some(byDay => dayInfo.value === byDay)
          );
        } else {
          this.weekdayNums = byDays;
        }
      }

      if (byMonth) {
        this.monthOfYear = byMonth;
      }

      if (byMonthDay > 0) {
        this.freqSubType = SUB_TYPE.DAY_OF_MONTH;
        this.dayOfMonth = byMonthDay;
      } else if (byMonthDay === -1) {
        this.freqSubType = SUB_TYPE.LAST_DAY_OF_MONTH;
        this.dayOfMonth = byMonthDay;
      }

      if (recur.count > 0) {
        this.endType = "count";
        this.count = recur.count;
      } else if (recur.until) {
        this.endType = "until";
        this.until = moment(recur.until, "YYYYMMDD").format("YYYY-MM-DD");
      }
    },
    onChangeFreq() {
      this.freqSubType = SUB_TYPE.WEEKDAY_OF_WEEK_IN_MONTH;
    },
    closeDialog(recur) {
      this.$emit("close", recur);
    },
    save() {
      const recurParts = {};

      switch (this.freq) {
        case "WEEKLY":
          if (this.weekdays.length > 0) {
            recurParts.BYDAY = this.weekdays.map(day => day.value);
          }
          break;
        case "MONTHLY":
          if (this.freqSubType === SUB_TYPE.DAY_OF_MONTH) {
            recurParts.BYMONTHDAY = this.dayOfMonth;
          } else if (this.freqSubType === SUB_TYPE.LAST_DAY_OF_MONTH) {
            recurParts.BYMONTHDAY = -1;
          } else if (this.freqSubType === SUB_TYPE.LAST_WEEKDAY_OF_MONTH) {
            recurParts.BYDAY = [-1 + this.dayList[this.dayOfWeek].value];
          } else {
            recurParts.BYDAY = [
              this.weekOfMonth + this.dayList[this.dayOfWeek].value
            ];
          }
          break;
        case "YEARLY":
          recurParts.BYMONTH = this.monthOfYear;
          if (this.freqSubType === SUB_TYPE.DAY_OF_MONTH) {
            recurParts.BYMONTHDAY = this.dayOfMonth;
          } else {
            recurParts.BYDAY = [
              this.weekOfMonth + this.dayList[this.dayOfWeek].value
            ];
          }
          break;
        default:
          break;
      }

      const until =
        this.endType === "until"
          ? moment(this.until).format("YYYYMMDD") +
            (this.isAllDay ? "" : " 23:59:59")
          : "";
      const count = this.endType === "count" ? this.count : 0;

      const data = {
        freq: this.freq,
        interval: this.interval,
        until: until,
        count: count,
        parts: recurParts
      };

      this.closeDialog(data);
    }
  }
};
</script>

<style scoped lang="scss">
@import "vuetify/src/styles/styles.sass";

.v-input--radio-group ::v-deep {
  .v-radio {
    min-height: 36px;
    margin-bottom: 4px !important;

    .v-label {
      font-size: map-get($headings, body-2, size);
      line-height: map-get($headings, body-2, line-height);
      letter-spacing: map-get($headings, body-2, letter-spacing);
    }
  }
}

.v-chip-group ::v-deep {
  .v-chip {
    min-width: 36px;
    height: 36px;
    margin: 2px 4px 0 0;
    border-radius: map-get($rounded, circle);
    // 영어 요일 대문자 표시
    text-transform: uppercase;
  }
}

.end-type-title {
  width: 92px;
}
</style>
