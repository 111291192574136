import { getDateWithoutTime } from "@/commons/utils/moment";
import headerUtils from "./header";

const getTimelineTimes = timeline => {
  const current = new Date(
    `${getDateWithoutTime(new Date().getTime(), "YYYY-MM-DD")} 24:00`
  ).getTime();
  let [sd, ed] = timeline?.split(",") ?? [];
  sd = new Date(`${sd} 24:00`).getTime();
  ed = new Date(`${ed} 24:00`).getTime();

  return { sd, ed, current };
};

const itemFilter = (items, filters) => {
  const result = { isFilter: false, groupIds: [], itemIds: [] };
  // 필터 확인
  const enabledFilters = filters.filter(
    ({ column, condition, values }) =>
      column.value &&
      condition.value &&
      Array.isArray(values) &&
      values.length > 0
  );
  if (enabledFilters.length == 0) return result;
  result.isFilter = true;

  // 아이템 필터링
  Object.keys(items).forEach(groupId => {
    const itemArr = items[groupId];
    if (!Array.isArray(itemArr)) return;

    for (let i = 0; i < itemArr.length; i += 1) {
      let str = "";
      const item = itemArr[i];
      for (let i = 0; i < enabledFilters.length; i += 1) {
        const { column, condition, values, operator } = enabledFilters[i];
        const itemValue = item[column.value];
        if (str.length > 0 && operator == "and") str += " &&";
        if (str.length > 0 && operator == "or") str += " ||";

        switch (condition.value) {
          case "is": {
            if (!itemValue) {
              str += " false";
              break;
            }
            const [result] = values.filter(v => {
              if (column.type == headerUtils.TIMELINE().type) {
                const { sd, ed, current } = getTimelineTimes(itemValue);
                if (!sd || !ed) return false;

                switch (v.value) {
                  case "present":
                    return sd <= current && current <= ed;
                  case "expiry":
                    return current === ed;
                  case "comming":
                    return sd > current;
                  case "past":
                    return current > ed;
                }

                return false;
              }

              return v.value === itemValue;
            });

            str += ` ${!!result}`;
            break;
          }
          case "is not": {
            if (!itemValue) {
              str += " true";
              break;
            }

            let result = true;
            for (let i = 0; i < values.length; i += 1) {
              if (column.type == headerUtils.TIMELINE().type) {
                const { sd, ed, current } = getTimelineTimes(itemValue);
                if (!sd || !ed) return false;

                switch (values[i].value) {
                  case "present": {
                    result = sd > current || current > ed;
                    break;
                  }
                  case "expiry": {
                    result = current !== ed;
                    break;
                  }
                  case "comming": {
                    result = sd <= current;
                    break;
                  }
                  case "past": {
                    result = current <= ed;
                    break;
                  }
                }

                if (!result) break;
              } else if (values[i].value === itemValue) {
                result = false;
                break;
              }
            }

            str += ` ${result}`;
            break;
          }
          case "includes": {
            const [result] = values.filter(v => {
              if (column.type == headerUtils.PERSON().type) {
                try {
                  const parsedValue = JSON.parse(v.value);
                  return itemValue?.includes(parsedValue.email ?? parsedValue);
                } catch (e) {
                  //
                }
              }

              return itemValue?.includes(v.value);
            });
            str += ` ${!!result}`;
            break;
          }
          case "not includes": {
            const [result] = values.filter(v => !itemValue?.includes(v.value));
            str += ` ${!!result}`;
            break;
          }
          case "empty": {
            str += itemValue ? " false" : " true";
            break;
          }
          case "not empty": {
            str += !itemValue ? " false" : " true";
            break;
          }
          case "starts between": {
            const { sd } = getTimelineTimes(itemValue);
            const [{ value: date }] = values;
            let [d1, d2] = date.split(",");
            d1 = new Date(`${d1} 24:00`).getTime();
            d2 = new Date(`${d2} 24:00`).getTime();

            // 날짜 하나만 선택
            if (!d2) {
              str += d1 === sd ? " true" : " false";
            } else {
              str += d1 <= sd && d2 >= sd ? " true" : " false";
            }
            break;
          }
          case "ends between": {
            const { ed } = getTimelineTimes(itemValue);
            const [{ value: date }] = values;
            let [d1, d2] = date.split(",");
            d1 = new Date(`${d1} 24:00`).getTime();
            d2 = new Date(`${d2} 24:00`).getTime();

            // 날짜 하나만 선택
            if (!d2) {
              str += d1 === ed ? " true" : " false";
            } else {
              str += d1 <= ed && d2 >= ed ? " true" : " false";
            }
          }
        }
      }

      if (eval(str)) {
        if (result.itemIds.indexOf(item.id) == -1) {
          result.itemIds.push(item.id);
        }
        if (result.groupIds.indexOf(item.groupId) == -1) {
          result.groupIds.push(item.groupId);
        }
      }
    }
  });

  return result;
};

export default itemFilter;
