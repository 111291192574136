<template>
  <div v-show="isShow" class="cr-common-loader">
    <v-progress-linear
      color="primary"
      indeterminate
      rounded
      height="8"
    ></v-progress-linear>
  </div>
</template>

<style lang="scss" scoped>
.cr-common-loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.37);
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("loader", ["isShow"])
  }
};
</script>
