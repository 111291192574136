<template>
  <div>
    <v-icon v-if="item.icon" v-text="item.icon" :color="getColor" />
    <v-icon v-else v-text="getIcon" :class="getClass" :color="getColor" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeItems: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColor() {
      const [activeItem = {}] = this.activeItems;
      const { id } = activeItem;
      return id === this.item.id ? "primary" : "";
    },
    getClass() {
      return this.item.childCount === 0 ? "ml-3" : "";
    },
    getIcon() {
      return this.isOpen ? "mdi-folder-open" : "mdi-folder";
    }
  },
  methods: {}
};
</script>
