//import i18n from "@/_locales";
import http from "@/commons/api/http";

const prefix = "api/customer/group";

// 고객사 그룹 리스트 조회
const fetchCustomerGroupList = () => http.get(prefix);

// 자식 주소록 그룹 리스트 조회
const fetchCustomerGroupListByParentId = ({ parentId = "0" }) =>
  http.get(`${prefix}/${parentId}/children`);

// 고객사 그룹 리스트 조회(단일 구조)
const fetchFlatCustomerGroupList = () => http.get(`${prefix}/list`);

// 고객사 그룹 생성
const createCustomerGroup = payload => http.post(prefix, payload);

// 고객사 그룹 수정
const updateCustomerGroup = payload => http.patch(prefix, payload);

// 고객사 그룹 삭제
const deleteCustomerGroup = customerId =>
  http.delete(`${prefix}/${customerId}`);

// 고객사 그룹 조회
const fetchCustomerGroupById = id => http.get(`${prefix}/${id}`);

// 고객사 멤버 조회
const fetchCustomerGroupMembers = groupId =>
  http.get(`${prefix}/member/${groupId}`);

const fetchCustomerAdminList = () => http.get(`${prefix}/admin`);
/*
// 고객그룹명 중복 체크
const fetchExistsCustomerGroupName = groupName =>
  http.get(`${prefix}/exists`, { params: { groupName } });
*/
// 고객사 그룹 리스트 조회 - 공통 컴포넌트용(조직도, 주소록, 고객주소록)
const fetchCustomerGroupListByParent = parentId =>
  http.get(`${prefix}/byParent/${parentId}`);

const fetchDuplicationCheck = params =>
  http.get(`${prefix}/exists`, { params });

const restoreCanceledGroup = groupId =>
  http.patch(`${prefix}/restore/${groupId}`);

export {
  fetchCustomerGroupList,
  fetchCustomerGroupListByParentId,
  fetchFlatCustomerGroupList,
  createCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup,
  fetchCustomerGroupById,
  fetchCustomerGroupMembers,
  fetchCustomerAdminList,
  fetchCustomerGroupListByParent,
  fetchDuplicationCheck,
  restoreCanceledGroup
};
