import {
  fetchCardList,
  searchCardList,
  fetchCardDetail,
  createCard,
  updateCard,
  deleteCardList,
  moveCards,
  fetchPrintCardList
} from "@/customer/api/card.api";
import i18n from "@/_locales";
import { handleError } from "@/customer/constant/constants";

const state = {
  headers: [
    {
      text: "",
      value: "importantStatus",
      use: true,
      default: true,
      sortable: false,
      width: "44px",
      class: "px-1",
      cellClass: "px-1 importantStatus"
    },
    {
      text: "이름",
      value: "userName",
      use: true,
      default: true,
      sortable: true,
      width: "130px",
      class: "min-width"
    },
    {
      text: "이메일",
      value: "email",
      use: true,
      default: true,
      sortable: true,
      class: "min-width"
    },
    {
      text: "전화번호",
      value: "tel",
      use: true,
      sortable: true,
      width: "130px"
    },
    {
      text: "회사",
      value: "company",
      use: true,
      sortable: true,
      width: "100px"
    },
    {
      text: "부서",
      value: "organ",
      use: true,
      sortable: true,
      width: "80px"
    },
    {
      text: "직급",
      value: "position",
      use: true,
      sortable: true,
      width: "80px"
    },
    {
      text: "홈페이지",
      value: "url",
      use: true,
      width: "160px",
      sortable: false
    },
    {
      text: "메모",
      value: "memo",
      use: true,
      width: "80px",
      sortable: false
    },
    {
      text: "그룹",
      value: "groupName",
      use: true,
      width: "200px",
      sortable: false
    }
  ],
  cardList: [],
  selectedCards: [],
  totalCount: 0,
  loading: false
};

const getters = {
  getCardList: ({ cardList }) => cardList,
  isLoading: ({ loading }) => loading,
  getTotalCount: ({ totalCount }) => totalCount,
  getDefaultHeaders: ({ headers }) =>
    headers.map(h => ({ ...h, text: i18n.t(`contact.${h.text}`) })),
  getHeaders: ({ headers }) => {
    return headers
      .filter(({ use }) => use)
      .map(h => ({ ...h, text: h.text ? i18n.t(`contact.${h.text}`) : "" }));
  },
  getConfig: (state, getters, rootState) => {
    return {
      pageSize: rootState.customerRoute.customer_config.pageSize,
      sort: rootState.customerRoute.customer_config.sort,
      dir: rootState.customerRoute.customer_config.dir
    };
  }
};

const mutations = {
  SET_CARD_LIST: (state, cardList) => (state.cardList = cardList),
  TOGGLE_LOADING: (state, loading) => (state.loading = loading),
  SET_TOTAL_COUNT: (state, totalCount) => (state.totalCount = totalCount),
  ADD_HEADER: (state, { index, header }) =>
    state.headers.splice(index, 0, header)
};

const actions = {
  /**
   *
   * @param commit
   * @param dispatch
   * @param groupId
   * @param type
   * @param _pageSize
   * @param _sort
   * @param _dir
   * @param page
   * @param importantStatus
   * @param consonant
   * @param isNew
   * @param searchWord
   * @returns {Promise<void>}
   */
  async getCardList(
    { commit, dispatch, getters },
    {
      groupId,
      type,
      page = 1,
      pageSize,
      importantStatus = 0,
      consonant,
      isNew = false
    }
  ) {
    // 고객사 주소록 root는 패스
    if (!type || type === "ROOT") {
      commit("SET_TOTAL_COUNT", 10);
      commit("SET_CARD_LIST", []);
      return;
    }

    const params = {
      groupId,
      type,
      page: page - 1,
      pageSize,
      importantStatus,
      consonant,
      ...getters.getConfig
    };

    commit("TOGGLE_LOADING", true);
    const { status, data = {} } = await fetchCardList(params);

    if (status === 404) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("customer.error.group"), type: "ERROR" },
        { root: true }
      );
      commit("TOGGLE_LOADING", false);
      return;
    }

    if (status !== 200) {
      commit("TOGGLE_LOADING", false);
      return;
    }

    commit("SET_TOTAL_COUNT", isNew ? 10 : data.totalElements);
    commit("SET_CARD_LIST", data.content);
    commit("TOGGLE_LOADING", false);
  },

  /**
   * 연락처 검색
   * @param _
   * @param searchType
   * @param keyword
   * @param searchUserName
   * @param searchEmil
   * @param searchTel
   * @param page
   * @param pageSize
   * @returns {Promise<{}|{cardList, totalPages, page: *, totalElements}>}
   */
  async searchCardList(
    _,
    {
      searchType,
      keyword,
      searchUserName,
      searchEmil,
      searchTel,
      page = 0,
      pageSize = 10
    }
  ) {
    if (!searchType) {
      return {};
    }

    const { status, data = {} } = await searchCardList({
      searchType,
      keyword,
      searchUserName,
      searchEmil,
      searchTel,
      page,
      pageSize
    });

    if (status === 200) {
      return {
        cardList: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        page: data.number + 1
      };
    }
  },
  /**
   * 연락처 상세 정보 조회
   *
   * @param {*} actions
   * @param {*} id
   * @returns
   */
  async getContactDetail(actions, id) {
    const { status, data } = await fetchCardDetail({ id });
    if (status !== 200) {
      return;
    }

    const {
      firstName,
      lastName,
      emailList,
      telList,
      telTypeList,
      contactGroupList,
      company,
      organ,
      position,
      addrTypeList,
      postList,
      localityList,
      streetAddressList,
      urlList,
      memo
    } = data;
    return {
      id,
      firstName: firstName || "",
      lastName: lastName || "",
      emailList: emailList || [],
      telList: telList || [],
      telTypeList: telTypeList || [],
      contactGroupList: contactGroupList || [],
      company: company || "",
      organ: organ || "",
      position: position || "",
      addrTypeList: addrTypeList || [],
      postList: postList || [],
      localityList: localityList || [],
      streetAddressList: streetAddressList || [],
      urlList: urlList || [],
      memo: memo || ""
    };
  },

  /**
   * 연락처 추가
   *
   * @param {*} param0
   * @param {*} cardData
   * @param cardData
   */
  async addCard({ dispatch, rootGetters }, cardData) {
    const { status } = await createCard(cardData);
    const messages = {
      ERROR: i18n.t("contact.53"),
      SUCCESS: i18n.t("contact.54")
    };
    let type = "ERROR";

    if (status === 201) {
      type = "SUCCESS";
      const routeParams = rootGetters["customerRoute/getRouteInfo"];
      const params = {
        ...routeParams,
        ...routeParams.actions
      };
      await dispatch("getCardList", params);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type },
      { root: true }
    );
  },

  /**
   * 연락처 수정
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async updateCard({ dispatch, rootGetters }, cardData) {
    const response = await updateCard({ cardData });
    const { status } = response;

    const messages = {
      ERROR: i18n.t("contact.55"),
      SUCCESS: i18n.t("contact.56")
    };

    if (status !== 200) {
      return handleError(dispatch, messages.ERROR);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );
    const routeParams = rootGetters["customerRoute/getRouteInfo"];
    const params = {
      ...routeParams,
      ...routeParams.actions
    };
    await dispatch("getCardList", params);
    return true;
  },

  /**
   * 연락처 삭제
   *
   * @param {*} param0
   * @param {*} groupId
   * @param {*} cardIdList
   */
  async delete({ dispatch, rootGetters }, { groupId, cardIdList }) {
    const response = await deleteCardList({ groupId, cardIdList });
    const { status } = response;

    const messages = {
      ERROR: i18n.t("contact.51"),
      SUCCESS: i18n.t("contact.52")
    };

    if (status !== 200) {
      return handleError(dispatch, messages.ERROR);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );

    const routeParams = rootGetters["customerRoute/getRouteInfo"];
    const params = {
      ...routeParams,
      ...routeParams.actions
    };
    await dispatch("getCardList", params);
  },

  /**
   * 연락처 복원
   *
   * @param {*} param0
   * @param {*} cardData
   */
  async restoreCustomerCard(
    { dispatch, rootGetters },
    { targetGroupId, cardIdList }
  ) {
    const { status } = await moveCards({ targetGroupId, cardIdList });

    const messages = {
      ERROR: i18n.t("customer.message.fail", {
        action: i18n.t("customer.modify")
      }),
      SUCCESS: i18n.t("customer.message.success", {
        action: i18n.t("customer.modify")
      })
    };

    if (status !== 200) {
      return handleError(dispatch, messages.ERROR);
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: messages.SUCCESS, type: "SUCCESS" },
      { root: true }
    );

    const routeParams = rootGetters["customerRoute/getRouteInfo"];
    const params = {
      ...routeParams,
      ...routeParams.actions,
      trash: true
    };
    await dispatch("getCardList", params);
    return true;
  },

  /**
   * 인쇄 연락처 리스트 조회
   *
   * @param {*} actions
   * @param {*} param1
   * @returns
   */
  async getPrintCardList(
    actions,
    { groupId = 0, sort = "id", dir = "desc", consonant }
  ) {
    const { data = [] } = await fetchPrintCardList({
      groupId,
      sort,
      dir,
      consonant
    });
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
