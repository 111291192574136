import {
  authKey,
  login,
  logout,
  getUserDetail,
  getCompanyInfo,
  getUserServiceConfig,
  getUserQuota,
  checkToken
} from "@/commons/api/auth.api";

import storage from "@/commons/api/storage";
import vuetify from "@/commons/plugins/vuetify";
import moment from "moment";
import i18n from "@/_locales";
import { updateGeneralInfo, updateUserLanguage } from "@/account/api/info.api";
import { updateServiceConfig } from "@/account/api/service.api";
import { forceUpdatePw } from "@/account/api/security.api";

const state = {
  loading: false,
  init: false,
  companyInfo: {},
  userInfo: {},
  userServiceConfig: {},
  languageList: [
    { value: "ko", name: i18n.t("common.한국어") },
    { value: "en", name: i18n.t("common.영어") },
    { value: "id", name: i18n.t("common.인도네시아어") }
  ],
  dialog: {
    password: false
  }
};

const getters = {
  isLoading: ({ loading }) => loading,
  getUserInfoInit: ({ init }) => init,
  // 회사 정보
  getCompanyInfo: ({ companyInfo }) => companyInfo,
  // 유저 정보
  getUserInfo: ({ userInfo }) => userInfo,
  // 유저 서비스 설정 정보
  getUserServiceConfig: ({ userServiceConfig }) => userServiceConfig,
  // 유저 용량 정보
  getUserQuotaInfo: ({ userInfo }) => ({
    totalQuota: userInfo.totalQuota,
    usedSize: userInfo.usedSize
  }),
  // 용량 초과 여부
  isOverQuota: ({ userInfo }) => userInfo.totalQuota < userInfo.usedSize,
  // 도메인 상태
  getDomainStatus: ({ companyInfo }) =>
    companyInfo.domainStatus === "AVAILABLE",
  // 사용중인 도메인 목록
  getDomains: ({ companyInfo }) => companyInfo?.domains || [],
  // 언어 설정 리스트
  getLanguageList: ({ languageList }) => languageList,
  // 모듈 사용 여부
  getUsedModule: ({ userInfo, companyInfo }) => ({
    home: !!companyInfo.useHome,
    mail: true,
    cal: !!companyInfo.useCalendar,
    contact: !!companyInfo.useContact,
    drive: !!companyInfo.useDrive,
    approval: !!companyInfo.useApproval,
    customer: !!companyInfo.useCustomer,
    board: !!companyInfo.useBoard,
    todo: !!companyInfo.useToDo,
    flow:
      userInfo.username === "ch197cm@crinity.com" ||
      userInfo.username === "vovo118@crinity.com",
    resource: !!companyInfo.useResource
  }),
  useMessenger: ({ companyInfo }) => !!companyInfo.useMessenger,
  getFirstScreenModule: ({ userServiceConfig }) =>
    userServiceConfig.firstScreenModuleType,
  getPlanType: ({ companyInfo }) => companyInfo.planType || "FREE",
  // 비밀번호 변경 팝얻
  useForceChangePasswordDialog: ({ dialog }) => dialog.password,
  // 사용자 정의 UI
  getCustomizeUIInfo: ({ companyInfo }) =>
    companyInfo.customizeUI ? companyInfo.customizeUI : {},
  // 사용자 제한된 기능 목록
  restrictedFunctions: ({ userInfo }) => ({
    isOrgChartRestricted: userInfo.isOrgChartRestricted
  }),
  // 사용자의 대표 조직
  getRepresentativeOrgan: ({ userInfo }) => {
    const { organRelationList, organId } = userInfo;
    return organRelationList?.find(o => o.organId === organId) ?? {};
  }
};

// state값 수정
const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_INIT: (state, init) => {
    state.init = init;
  },
  SET_COMAPNY_INFO: (state, info) => {
    state.companyInfo = info;
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info;
  },
  SET_USER_SERVICE_CONFIG: (state, config) => {
    state.userServiceConfig = config;
  },
  SET_USER_QUOTA_INFO: (state, info) => {
    state.userInfo.totalQuota = info.totalQuota;
    state.userInfo.usedSize = info.usedSize;
  },
  UPDATE_USER_TWO_FACTOR_AUTH: (state, twoFactorAuth) => {
    state.userInfo.twoFactorAuth = twoFactorAuth;
  },
  UPDATE_USER_LANGUAGE: (state, language) => {
    state.userInfo.language = language;
  },
  UPDATE_GENERAL_INFO: (state, { accountName, personalEmail }) => {
    state.userInfo.accountName = accountName;
    state.userInfo.displayName = accountName;
    state.userInfo.personalEmail = personalEmail;
  },
  SET_REQUEST_PASSWORD_CHANGE: (state, value) => {
    state.userInfo.requestPasswordChange = value;
    state.dialog.password = value == 1;
  },
  SET_THEME: (state, theme) => {
    state.userInfo.theme = theme;
  }
};

// mutations 호출 및 비동기 처리
const actions = {
  // 인증키
  async authKey() {
    let { data = {}, status = 500 } = await authKey();
    return { data, status };
  },
  // 로그인
  async loginAction({ commit }, { email, password, authentication_code }) {
    commit("SET_LOADING", true);
    let { data = {}, status = 401 } = await login(
      email,
      password,
      authentication_code
    );
    if (status == 200) {
      const { access_token, refresh_token } = data;
      await storage.setAccessToken(access_token);
      await storage.setRefreshToken(refresh_token);
    }

    commit("SET_LOADING", false);
    return { data, status };
  },
  async checkToken() {
    await checkToken();
  },
  clearToken() {
    storage.clearToken();
  },
  async storeUserDetailToLocalStorage() {
    const { data = {}, status = 401 } = await getUserDetail();
    if (status == 200) {
      await storage.setUserDetail(JSON.stringify(data));
    }
  },
  // 유저 정보 조회
  async getUserDetailToStore({ commit }) {
    const { data = {}, status = 401 } = await getUserDetail();
    if (status == 200) {
      commit("SET_USER_INFO", data);
    }
  },
  // 유저 용량 정보 조회
  async setUserQuotaInfo({ commit }) {
    const { data = {}, status = 401 } = await getUserQuota();
    if (status == 200) {
      commit("SET_USER_QUOTA_INFO", data);
    }
  },
  // 회사정보 조회
  async getCompanyInformation({ commit }) {
    const { data = [], status = 401 } = await getCompanyInfo();
    if (status == 200) {
      commit("SET_COMAPNY_INFO", data);
    }
  },
  // 유저 서비스 설정 정보 조회
  async getUserServiceConfig({ commit }) {
    const { data = {}, status = 401 } = await getUserServiceConfig();
    if (status == 200) {
      commit("SET_USER_SERVICE_CONFIG", data);
    }
  },
  getStoredUserServiceConfig({ getters }) {
    return getters.getUserServiceConfig;
  },

  // 유저/ 회사 정보 조회
  async initUserInfo({ state, commit, dispatch }, { routeName }) {
    await dispatch("getUserServiceConfig");
    await dispatch("getUserDetailToStore");
    await dispatch("getCompanyInformation");

    const { userInfo, companyInfo } = state;
    i18n.locale = userInfo?.languageType?.toLowerCase();
    vuetify.framework.lang.current = i18n.locale;
    moment.locale(i18n.locale);
    commit("SET_INIT", true);

    // 알림 서버 연결
    dispatch(
      "websocket/initWebsocket",
      { host: userInfo.deliveryUrl },
      { root: true }
    );

    // 메일 팝업창이나 카드 인쇄시 메신저와 알림글 방지
    if (
      routeName.includes("popup_mail") ||
      routeName === "contact_print" ||
      routeName === "customer_print"
    ) {
      return;
    }

    /**
     * JOIN_TYPE 이 SNS인 경우는 홈페이지를 통해
     * 간편 가입을 한 사용자 이기 때문에
     * 비밀번호 변경 팝업 이전 튜토리얼을 진행한다
     * 튜토리얼이 완료된 후 비밀번호 변경 팝업이 나타난다
     */
    if (userInfo.JOIN_TYPE == "SNS") {
      return;
    }

    /**
     * 비밀번호 변경 팝업
     */
    if (userInfo.requestPasswordChange == 1) {
      commit("SET_REQUEST_PASSWORD_CHANGE", 1);
    }

    /**
     * 시스템 공지
     * - 비밀번호 변경이 활성화 된경우 시스템 팝업을 생략한다
     */
    if (userInfo.requestPasswordChange == 0) {
      commit("messenger/SET_USE", !!companyInfo.useMessenger, { root: true });
    }

    /**
     * 사용자 컬러
     */
    commit("theme/SET_CURRENT_THEME", userInfo.theme, { root: true });
    if (!["light"].includes(userInfo.theme)) {
      const theme = vuetify.framework.theme.defaults[userInfo.theme];
      Object.keys(theme).forEach(i => {
        vuetify.framework.theme.themes.light[i] = theme[i];
      });
    }

    /**
     * AI 사용 설정
     */
    if (userInfo.companyId === 1) {
      dispatch("ai/initAI", true, { root: true });
    }
  },

  // 기본 정보 수정 { commit, dispatch }
  async updateGeneralInfo(
    { getters, commit, dispatch },
    { accountName, personalEmail, languageType }
  ) {
    const isChangeLanguage = languageType != getters.getUserInfo.languageType;

    const { data = {}, status = 401 } = await updateGeneralInfo({
      accountName,
      personalEmail,
      languageType: languageType.toUpperCase()
    });

    // 결과
    let type =
      status != 200 ? "ERROR" : data.result == "SUCCESS" ? "SUCCESS" : "ERROR";
    let msg = "정보를 수정 하는데 실패하였습니다.";

    if (type == "ERROR") {
      if (status == 200) {
        switch (data.message) {
          case "personalEmail":
            msg = "이름은 필수 입력 사항입니다.";
            break;
          default:
            break;
        }
      }
      dispatch(
        "snackbar/openSnackbar",
        {
          message: msg,
          type
        },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "저장 하였습니다.",
          type
        },
        { root: true }
      );

      // 수정 성공 시
      if (isChangeLanguage) {
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        commit("UPDATE_GENERAL_INFO", { accountName, personalEmail });
      }
    }
  },

  // 사용자 언어 정보 수정
  async updateUserLanguage({ commit, dispatch }, language) {
    const { status = 401 } = await updateUserLanguage(language);

    let type = "ERROR";
    let message = i18n.t("common.58");
    if (status == 200) {
      type = "SUCCESS";
      message = i18n.t("common.57");
      commit("UPDATE_USER_LANGUAGE", language);
      setTimeout(() => {
        location.reload();
      }, 100);
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  // 사용자 설정 정보 변경
  async updateUserServiceCofig({ commit, dispatch }, params) {
    const { data, status } = await updateServiceConfig(params);
    let type = "ERROR";
    let message = i18n.t("common.62");
    if (status == 200) {
      type = "SUCCESS";
      message = i18n.t("common.61");
      commit("SET_USER_SERVICE_CONFIG", data);
    }
    dispatch("snackbar/openSnackbar", { type, message }, { root: true });
  },

  // 비밀번호 변경
  async updateUserPassword({ commit, dispatch }, newPassword) {
    const { status = 401 } = await forceUpdatePw(newPassword);
    let type = "ERROR";
    let message = i18n.t("account.32");
    if (status == 200) {
      type = "SUCCESS";
      message = i18n.t("account.33");
      commit("SET_REQUEST_PASSWORD_CHANGE", 0);
    }

    dispatch("snackbar/openSnackbar", { type, message }, { root: true });
  },

  // 로그아웃
  async getLogoutUrl({ dispatch }) {
    dispatch("websocket/notifyLogout", {}, { root: true });
    return logout();
  },

  // 튜토리얼 정보 변경
  async updateTutorialStatus({ getters, commit }, { module, value }) {
    const clone = JSON.parse(JSON.stringify(getters.getUserServiceConfig));
    switch (module) {
      case "home":
        clone.homeTutorial = value;
        break;
      case "mail":
        clone.mailTutorial = value;
        break;
      case "calendar":
        clone.calendarTutorial = value;
        break;
      case "contact":
        clone.contactTutorial = value;
        break;
      case "drive":
        clone.driveTutorial = value;
        break;
      case "board":
        clone.boardTutorial = value;
        break;
      case "workflow":
        clone.workflowTutorial = value;
        break;
    }
    commit("SET_USER_SERVICE_CONFIG", clone);
  },
  // 사용자 테마
  getTheme({ state }) {
    const { userInfo } = state;
    return userInfo.theme ?? "light";
  },
  // 사용자 테마 변경
  async updateTheme({ commit, dispatch }, theme) {
    const { status = 401 } = await forceUpdatePw(theme);
    let type = "ERROR";
    let message = i18n.t("account.32");
    if (status == 200) {
      type = "SUCCESS";
      message = i18n.t("account.33");
      commit("SET_REQUEST_PASSWORD_CHANGE", 0);
    }

    dispatch("snackbar/openSnackbar", { type, message }, { root: true });
  },

  /**
   *
   * 시스템 확인 (도메인상태, 용량정보)
   *
   */
  checkSystem({ getters: g, dispatch }, type) {
    const params = { type: "ERROR", setTop: true };

    switch (type) {
      case "domain":
        if (g.getDomainStatus) return false;
        params["message"] = i18n.t("mail.2");
        break;
      case "quota":
        if (!g.isOverQuota) return false;
        params["message"] = i18n.t("mail.3");
        break;
      default:
        return false;
    }

    dispatch("snackbar/openSnackbar", params, { root: true });
    return true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
