<template>
  <v-hover v-slot:default="{ hover }">
    <div :data-id="item.id" class="d-flex align-center justify-space-between">
      <!-- 노드 클릭 영역 -->
      <div class="cr-click-area" :title="item.title" @click="nodeClick" />
      <!-- 폴더이름 -->
      <span class="text-truncate">{{ item.title }}</span>
      <!-- 폴더 메뉴, 설정, 휴지통비우기, 생성 버튼 -->
      <Actions :item="item" :hover="hover" />
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-click-area {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>

<script>
import { mapActions } from "vuex";
import Actions from "./actions";

export default {
  components: { Actions },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("flowRoute", [
      "routeDashboard",
      "routeTasks",
      "routeProjects"
    ]),
    nodeClick() {
      const { id: projectId, folderType } = this.item || {};
      if (folderType?.includes("ROOT")) return;

      let action = "Tasks";
      switch (projectId) {
        case -1:
          action = "Dashboard";
          break;
        case -2:
          action = "Projects";
          break;
        default:
          break;
      }

      this[`route${action}`]({ projectId });
    }
  }
};
</script>
