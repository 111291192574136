import moment from "moment";
import i18n from "@/_locales";
import { CAL_CONSTANTS } from "@/calendar/constant/calConstants";

const mCurrent = moment();

export const toLocalDateString = date => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const toLocalTimeString = date => {
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  return `${hour}:${minute}`;
};

export const getFormattedDateTimeText = (
  event,
  options = {
    alwaysLongDate: false,
    useLongDateIfNotCurrentYear: false
  }
) => {
  const start = moment(event.start);
  const end = moment(event.end);
  const useLongDate =
    options.alwaysLongDate ||
    (options.useLongDateIfNotCurrentYear &&
      (!start.isSame(mCurrent, "year") || !end.isSame(mCurrent, "year"))) ||
    !start.isSame(end, "year");

  return generateDateTimeText(start, end, {
    useLongDate,
    excludeTime: event.isAllDay
  });
};

/**
 * 시작일과 종료일을 기준으로 날짜와 시간을 표시합니다.
 * 년도가 다른 경우 년도까지 표시합니다.
 */
export const generateDateTimeText = (
  start,
  end,
  options = {
    useLongDate: false,
    excludeTime: false
  }
) => {
  const dateFormat = options.useLongDate ? "ll" : "Mo Do (ddd)";

  if (options.excludeTime) {
    return start.isSame(end, "day")
      ? start.format(dateFormat)
      : `${start.format(dateFormat)}~${end.format(dateFormat)}`;
  }

  const startDateTimeFormat = `${dateFormat} A h:mm`;
  const endDateTimeFormat =
    // 날짜가 동일하면 시간만 표시
    (start.isSame(end, "day") ? "" : `${dateFormat} `) +
    // 시작 시간과 오전/오후가 다른 경우 표시
    (start.format("A") === end.format("A") ? "" : "A ") +
    "h:mm";

  return `${start.format(startDateTimeFormat)}~${end.format(
    endDateTimeFormat
  )}`;
};

/**
 * 시작일과 종료일을 기준으로 날짜와 시간을 표시합니다.
 * 월이 다른 경우 월까지 표시합니다.
 */
export const getDateTimeSummary = event => {
  const start = moment(event.start);
  const end = moment(event.end);

  if (event.isAllDay) {
    const format = start.isSame(end, "month") ? "Do (ddd)" : "Mo Do (ddd)";
    return start.isSame(end, "day")
      ? start.format(format)
      : `${start.format(format)} ~ ${end.format(format)}`;
  }

  const startDateTimeFormat = `A h:mm`;
  const endDateTimeFormat =
    // 시작 시간과 오전/오후가 다른 경우 표시
    (start.format("A") === end.format("A") ? "" : "A ") + "h:mm";

  return start.isSame(end, "day")
    ? `${start.format(startDateTimeFormat)} ~ ${end.format(endDateTimeFormat)}`
    : `${start.format("Do (ddd) " + startDateTimeFormat)} ~ ${end.format(
        "Do (ddd) " + endDateTimeFormat
      )}`;
};

export const dayOfMonthToText = number => {
  // 영문 숫자 표기법 적용 1st, 2nd, 3rd ...
  if (i18n.locale === "en") {
    switch (number + "".slice(-1)) {
      case "1":
        return number + "st";
      case "2":
        return number + "nd";
      case "3":
        return number + "rd";
      default:
        return number + "th";
    }
  }

  return number + "";
};

export const getRecurrenceDescriptionText = recur => {
  const byDay = recur.parts["BYDAY"];
  const byMonth = recur.parts["BYMONTH"];
  const byMonthDay = +recur.parts["BYMONTHDAY"];

  let freqDesc = "";
  let byDesc = "";
  let finishDesc = "";

  if (recur.count > 0) {
    finishDesc = i18n.t("calendar.113", { value: recur.count });
  } else if (recur.until) {
    finishDesc = i18n.t("calendar.114", {
      value: moment(recur.until, "YYYYMMDD").format("LL")
    });
  }

  switch (recur.freq) {
    case "WEEKLY":
      if (recur.interval > 1) {
        freqDesc = i18n.t("calendar.116", { value: recur.interval });
      } else {
        freqDesc = i18n.t("calendar.recur.freq.WEEKLY");
      }
      if (byDay) {
        const dayValues = CAL_CONSTANTS.DAY_LIST.filter(dayValue =>
          byDay.some(day => day === dayValue)
        );
        byDesc = i18n.t("calendar.118", {
          value: dayValues
            .map(dayValue => i18n.t(`calendar.recur.days.${dayValue}`))
            .join(", ")
        });
      }
      break;
    case "MONTHLY":
      if (recur.interval > 1) {
        freqDesc = i18n.t("calendar.119", { value: recur.interval });
      } else {
        freqDesc = i18n.t("calendar.recur.freq.MONTHLY");
      }
      if (byMonthDay > 0) {
        byDesc = i18n.t("calendar.121", {
          value: dayOfMonthToText(byMonthDay)
        });
      } else if (byMonthDay === -1) {
        byDesc = i18n.t("calendar.81");
      } else {
        const firstChar = byDay[0].substr(0, 1);
        if (firstChar === "-") {
          // 마지막 요일
          const dayOfWeek = CAL_CONSTANTS.DAY_LIST.filter(
            dayValue => dayValue === byDay[0].substring(2)
          ).map(dayValue => i18n.t(`calendar.recur.days.${dayValue}`));
          byDesc = i18n.t("calendar.115", { weekday: dayOfWeek });
        } else {
          // N번째 요일
          const numberIdx = firstChar === "+" ? 1 : 0;
          const weekNumber = Number(byDay[0].substr(numberIdx, 1));
          const dayOfWeek = CAL_CONSTANTS.DAY_LIST.filter(
            dayValue => dayValue === byDay[0].substring(numberIdx + 1)
          ).map(dayValue => i18n.t(`calendar.recur.days.${dayValue}`));
          byDesc = i18n.t("calendar.122", { weekNumber, dayOfWeek });
        }
      }
      break;
    case "YEARLY":
      if (recur.interval > 1) {
        freqDesc = i18n.t("calendar.123", { value: recur.interval });
      } else {
        freqDesc = i18n.t("calendar.recur.freq.YEARLY");
      }
      if (byMonthDay) {
        byDesc = i18n.t("calendar.84", {
          month: i18n.d(new Date(0, byMonth - 1), { month: "short" }),
          day: dayOfMonthToText(byMonthDay)
        });
      } else {
        const weekNumber = Number(byDay[0].substr(0, 1));
        const dayOfWeek = CAL_CONSTANTS.DAY_LIST.filter(
          dayValue => dayValue === byDay[0].substring(1)
        ).map(dayValue => i18n.t(`calendar.recur.days.${dayValue}`));
        byDesc = i18n.t("calendar.85", {
          month: i18n.d(new Date(0, byMonth - 1), { month: "short" }),
          number: dayOfMonthToText(weekNumber),
          week: dayOfWeek
        });
      }
      break;
    default:
      if (recur.interval > 1) {
        freqDesc = i18n.t("calendar.127", { value: recur.interval });
      } else {
        freqDesc = i18n.t("calendar.recur.freq.DAILY");
      }
      break;
  }

  return `${freqDesc} ${byDesc}${finishDesc.length ? ", " + finishDesc : ""}`;
};
