import store from "@/commons/store/index";
import moment from "moment-timezone";
import ICAL from "ical.js";
import i18n from "@/_locales";

const CAL_CONSTANTS = {
  BASE_CALENDAR:
    "BEGIN:VCALENDAR\nPRODID:CRINITY-CALENDAR\nVERSION:2.0\nEND:VCALENDAR",
  FREQ_LIST: ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"],
  DAY_LIST: ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
  ATTENDEE_PARTSTAT: {
    needsAction: "NEEDS-ACTION",
    accept: "ACCEPTED",
    decline: "DECLINED",
    tentative: "TENTATIVE"
  },
  ATTENDEE_ROLE: {
    chair: "CHAIR",
    req: "REQ-PARTICIPANT",
    opt: "OPT-PARTICIPANT",
    non: "NON-PARTICIPANT"
  },
  translatePartstat(partstat) {
    const key = `calendar.partStat.attendee.${partstat}`;
    return i18n.te(key)
      ? i18n.t(key)
      : i18n.t("calendar.partStat.attendee.NEEDS-ACTION");
  },
  getIcalTimeComponent(yyyymmdd, hhmm, allDay) {
    let zone = null;
    const tzIdFromStore = store.getters["cal/getTzId"];
    if (!allDay) {
      zone = new ICAL.Timezone({
        tzid: tzIdFromStore,
        component: null
      });
    }

    const time = new ICAL.Time(
      {
        year: ICAL.helpers.strictParseInt(yyyymmdd.substr(0, 4)),
        month: ICAL.helpers.strictParseInt(yyyymmdd.substr(5, 2)),
        day: ICAL.helpers.strictParseInt(yyyymmdd.substr(8, 2)),
        hour: hhmm ? ICAL.helpers.strictParseInt(hhmm.substr(0, 2)) : 0,
        minute: hhmm ? ICAL.helpers.strictParseInt(hhmm.substr(3, 2)) : 0,
        second: ICAL.helpers.strictParseInt(0),
        isDate: allDay
      },
      zone
    );
    return time;
  },
  convertStringToMomentDate(yyyymmdd, hhmm, allDay) {
    const tzIdFromStore = store.getters["cal/getTzId"];
    if (allDay) {
      return moment.utc(yyyymmdd, "YYYY-MM-DD");
    } else {
      return moment(`${yyyymmdd} ${hhmm}`, "YYYY-MM-DD HH:mm").tz(
        tzIdFromStore
      );
    }
  },
  convertDateToMomentDate(date, allDay) {
    const tzIdFromStore = store.getters["cal/getTzId"];
    if (allDay) {
      return moment.utc(
        `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        "YYYY-MM-DD"
      );
    } else {
      return moment(date).tz(tzIdFromStore);
    }
  },
  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  },
  convertAttachPropToFile(attach) {
    const fileName = attach.getFirstParameter("x-filename"),
      fileType = attach.getFirstParameter("fmttype"),
      base64String = atob(attach.getFirstValue());

    let length = base64String.length,
      buffer = new ArrayBuffer(length),
      u8arr = new Uint8Array(buffer);

    while (length--) {
      u8arr[length] = base64String.charCodeAt(length);
    }
    return new File([u8arr], fileName, { type: fileType });
  },
  getNextHalfHour() {
    const now = moment();
    return now.add(30 - (now.minute() % 30), "minutes");
  },
  findNearestTime(date, format) {
    const minute = date.format("mm");
    const rest = minute % 30;
    if (rest < 15) {
      return date
        .clone()
        .subtract(rest, "minutes")
        .format(format);
    } else {
      return date
        .clone()
        .add(30 - rest, "minutes")
        .format(format);
    }
  }
};

export const CALENDAR_VIEW_TYPE = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  FOUR_DAYS: "4day"
};

export const CALENDAR_TYPE = {
  DEFAULT: "DEFAULT",
  PRIVATE: "PRIVATE",
  ORGANIZATION: "ORGANIZATION",
  SUBSCRIPTION: "SUBSCRIPTION",
  PUBLIC_SUBSCRIPTION: "PUBLIC_SUBSCRIPTION",
  PUBLIC: "PUBLIC",
  HOLIDAY: "HOLIDAY",
  EXTERNAL: "EXTERNAL"
};

export const EVENT_PLATFORM = {
  SIRTEAM: "sirteam",
  GOOGLE: "google"
};

export const LEAVE_STATUS = {
  READY: "READY",
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED"
};

export const DEFAULT_CALENDAR_COLOR = "#F44336";
export const CALENDAR_SWATCHES = [
  "#F06292",
  "#F44336",
  "#E57373",
  "#FB8C00",
  "#FFA726",
  "#C0CA33",
  "#8BC34A",
  "#43A047",
  "#009688",
  "#03A9F4",
  "#2196F3",
  "#3F51B5",
  "#5C6BC0",
  "#7E57C2",
  "#673AB7",
  "#9C27B0",
  "#795548",
  "#A1887F",
  "#607D8B",
  "#757575"
];

export { CAL_CONSTANTS };
