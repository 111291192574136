import { getToken } from "@/commons/api/messenger.api";

const state = {
  use: false,
  maximize: false,
  minimize: true,
  opacity: null,

  unread: 0,
  openRoomId: "home",

  iframeEl: null,

  tenantId: null,
  accessToken: null
};

const getters = {
  useMessenger: ({ use }) => use,
  maximize: ({ maximize }) => maximize,
  minimize: ({ minimize }) => minimize,
  unread: ({ unread }) => unread,
  openRoomId: ({ openRoomId }) => openRoomId,
  isRoomOpend: ({ openRoomId }) => openRoomId !== "home",
  iframeEl: ({ iframeEl }) => iframeEl,
  tenantId: ({ tenantId }) => tenantId,
  opacity: ({ opacity }) =>
    (opacity ??= localStorage.getItem("tc#opacity") || 10)
};

// state값 수정
const mutations = {
  SET_USE: (state, use) => (state.use = use),
  SET_MAXIMIZE: (state, maximize) => (state.maximize = maximize),
  SET_MINIMIZE: (state, minimize) => (state.minimize = minimize),
  SET_OPACITY: (state, opacity) => {
    localStorage.setItem("tc#opacity", opacity);
    state.opacity = opacity;
  },
  SET_UNREAD: (state, unread) => (state.unread = unread),
  SET_OPEN_ROOM_ID: (state, openRoomId) => (state.openRoomId = openRoomId),
  SET_IFRAME_ELEMENT: (state, iframeEl) => (state.iframeEl = iframeEl),
  SET_TENANT_ID: (state, tenantId) => (state.tenantId = tenantId),
  SET_ACCESS_TOKEN: (state, accessToken) => (state.accessToken = accessToken)
};

// mutations 호출 및 비동기 처리
const actions = {
  /**
   * 초기화
   * @param {*} param0
   * @param {*} el
   * @returns
   */
  async init({ commit, state }, el) {
    const { data = {} } = await getToken();
    const { messenger, tenantId } = data ?? {};
    if (!messenger?.success || !messenger?.data?.authToken) return;

    /**
     * 메신저 서버 URL 설정
     * - URL 설정후 페이지가 로드된후 onLoadIFrame 함수가 호출된다
     */
    commit("SET_IFRAME_ELEMENT", el);
    commit("SET_TENANT_ID", tenantId);
    commit("SET_ACCESS_TOKEN", messenger.data.authToken);

    // 서버 src 설정
    state.iframeEl.src = `https://chat-${tenantId}.sirteam.net`;
  },

  /**
   * 로그인
   * @param {*} param0
   * @returns
   */
  async login({ state }) {
    if (!state.iframeEl) return;
    if (!state.tenantId) return;

    const { contentWindow } = state.iframeEl;
    if (!contentWindow) return;

    contentWindow.postMessage(
      { externalCommand: "login-with-token", token: state.accessToken },
      "*"
    );
  },
  async pleaseRoomOpenMessage({ dispatch }) {
    dispatch(
      "snackbar/openSnackbar",
      { message: "대화방을 선택해주세요.", type: "ERROR" },
      { root: true }
    );
  },
  async postMessage({ state }, msg) {
    const { contentWindow } = state.iframeEl;
    if (!contentWindow) return;

    contentWindow.postMessage(
      { externalCommand: "cts-sendMessage", rid: state.openRoomId, msg },
      "*"
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
