<template>
  <v-select
    item-color="light-grey"
    :items="getItems"
    v-model="selected"
    item-value="type"
    dense
    outlined
    hide-details
  >
    <!-- 아이템 목록 -->
    <template v-slot:item="{ item }">
      <div class="d-block my-1">
        <div class="d-flex">
          <v-icon color="primary" class="mr-3">{{ item.icon }}</v-icon>
          <div
            class="flex-grow-1 button align-self-center"
            v-text="item.text"
          />
        </div>
        <div
          class="d-block caption text--secondary ml-9"
          v-text="item.description"
        />
      </div>
    </template>

    <!-- 선택된 아이템 -->
    <template v-slot:selection="{ item }">
      <div class="d-block my-1">
        <div class="d-flex">
          <v-icon color="primary" class="mr-3">{{ item.icon }}</v-icon>
          <div
            class="flex-grow-1 button align-self-center"
            v-text="item.text"
          />
        </div>
        <div
          class="d-block caption text--secondary ml-9"
          v-text="item.description"
        />
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("ai", ["getItems", "getSelected"]),
    selected: {
      get() {
        return this.getSelected.type;
      },
      set(value) {
        this.SET_TYPE(value);
      }
    }
  },
  methods: {
    ...mapMutations("ai", ["SET_TYPE"])
  }
};
</script>
