<template>
  <span v-if="unaddible">
    {{ activeNode.name }}
  </span>

  <v-btn
    v-else
    small
    depressed
    :color="activeGroup ? 'primary' : 'white'"
    @click="addGroup"
  >
    <v-tooltip top :disabled="unaddible">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-icon
            size="16"
            class="mr-1"
            :color="activeGroup ? 'white' : 'primary'"
          >
            mdi-at
          </v-icon>
          <span :class="{ 'primary--text': !activeGroup }">
            {{ activeNode.name }}
          </span>
        </div>
      </template>

      {{ isOrgan ? $t("mail.550") : $t("mail.549") }}
    </v-tooltip>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn {
  margin-left: -6px;
  padding: 2px !important;
  min-width: 0px !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/_locales";

export default {
  props: {
    to: {
      type: Array,
      default: () => []
    },
    cc: {
      type: Array,
      default: () => []
    },
    bcc: {
      type: Array,
      default: () => []
    },
    selectedPanel: {
      type: String,
      default: "to"
    },
    selectorType: {
      type: String,
      default: "contact"
    }
  },
  computed: {
    ...mapGetters("organ", ["activeNode"]),
    unaddible() {
      if (this.selectorType === "customer") {
        return true;
      }
      const { id } = this.activeNode || {};
      return id === "search" || id < 0;
    },
    activeGroup() {
      const rcpts = this[this.selectedPanel];
      const { name, email } = this.activeNode;
      if (!email) return false;

      // 현재 그룹만
      let value = `"${name}" <${email}>`;
      if (-1 !== rcpts.findIndex(r => this.equalsValue(r.value, value))) {
        return true;
      }

      // 하위 그룹 포함
      if (email.startsWith("O#")) {
        value = `"${name}+" <${email.replace("O#", "O+")}>`;
        if (-1 !== rcpts.findIndex(r => this.equalsValue(r.value, value))) {
          return true;
        }
      }

      return false;
    },
    isOrgan() {
      return this.selectorType === "organ";
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    equalsValue(value1, value2) {
      const regex = /[\s"<>]/g;
      return value1.replace(regex, "") === value2.replace(regex, "");
    },
    addGroup() {
      const { name, email } = this.activeNode;
      if (!email) return;

      if (email.startsWith("O#")) {
        return this.addOrganGroup();
      }

      const value = `"${name}" <${email}>`;
      if (this.activeGroup) {
        return this.$emit("removeRcpt", { value });
      }
      this.$emit("addRcpt", { value });
    },
    addOrganGroup() {
      const { name, email } = this.activeNode;
      if (!email) return;

      const groupEmail = `"${name}" <${email}>`;
      const subGroupEmail = `"${name}+" <${email.replace("O#", "O+")}>`;
      const hasGroup = true;

      if (this.activeGroup) {
        return this.$emit("removeRcpt", {
          groupEmail,
          subGroupEmail,
          hasGroup
        });
      }

      this.confirm({
        message: i18n.t("mail.588"),
        cancelButtonTitle: i18n.t("mail.589"),
        okButtonTitle: i18n.t("mail.590"),
        showCloseBtn: true,
        closeCallback: () => {
          this.$emit("addRcpt", {
            groupEmail,
            subGroupEmail,
            hasGroup,
            hasSubGroup: false
          });
        },
        callback: () => {
          this.$emit("addRcpt", {
            groupEmail,
            subGroupEmail,
            hasGroup,
            hasSubGroup: true
          });
        }
      });
    }
  }
};
</script>
