import Vue from "vue";
import {
  getSubItems,
  addItem,
  moveItem,
  updateItem,
  deleteItems
} from "@/todo/api/item.api";

const state = {
  moveItemId: 0,
  items: {},
  subItems: {},
  showSubItems: {},
  selectedItems: {},
  organUserList: []
};

// state, getters, rootState, rootGetters
const getters = {
  moveItemId: ({ moveItemId }) => moveItemId,
  items: ({ items }, g, rS, rG) => {
    const { isFilter, groupIds, itemIds } = rG["todoFilter/filtersIds"];
    if (!isFilter) return items;

    const obj = {};
    groupIds?.forEach(gid => {
      obj[gid] = items[gid].filter(item => itemIds?.indexOf(item.id) > -1);
    });
    return obj;
  },
  orgItems: ({ items }) => items,
  subItems: ({ subItems }) => subItems,
  showSubItems: ({ showSubItems }) => showSubItems,
  selectedItems: ({ selectedItems }) => selectedItems,
  recommender: ({ items, organUserList }, getters, rootState) => {
    const { header } = rootState.todoHeader;
    const obj = {};

    header.forEach(({ type, value }) => {
      if (type !== "PERSON") return;

      const arr = [];
      // 해당 사람컬럼에 등록된 사람들
      Object.keys(items).forEach(groupId => {
        items[groupId].forEach(item => {
          if (item[value]) {
            item[value].split(";").forEach(p => {
              try {
                const { email, name, userId } = JSON.parse(p);
                const index = arr.findIndex(a => a.userId == userId);
                if (index == -1) {
                  arr.push({ email, name, userId });
                }
              } catch (e) {
                //
              }
            });
          }
        });
      });

      // 조직도 사람들
      organUserList.forEach(u => {
        const index = arr.findIndex(a => a.userId == u.id);
        if (index == -1) {
          arr.push({ email: u.username, name: u.accountName, userId: u.id });
        }
      });

      // 자기자신은 맨 앞으로
      const myInfo = {
        name: rootState.auth.userInfo.accountName,
        email: rootState.auth.userInfo.username,
        userId: rootState.auth.userInfo.id
      };
      const index = arr.findIndex(a => a.userId == myInfo.userId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      arr.unshift(myInfo);
      obj[value] = arr;
    });

    return obj;
  }
};

const mutations = {
  SET_MOVE_ITEM_ID: (state, moveItemId) => {
    state.moveItemId = moveItemId;
  },
  RESET_STATE: state => {
    state.items = {};
    state.subItems = {};
    state.showSubItems = {};
    state.selectedItems = {};
    state.organUserList = [];
  },
  SET_ITEMS: (state, items) => {
    Object.keys(items).forEach(gid => {
      const itemList = items[gid].map(i => ({ ...i.itemRow.items, ...i }));
      Vue.set(state.items, gid, itemList);
    });
  },
  ADD_ITEM: (state, { groupId, item }) => {
    item = { ...item, ...item.itemRow.items };
    Vue.set(state.items, groupId, [...(state.items[groupId] ?? []), item]);
  },
  SET_SUB_ITEMS: (state, { groupId, parentId, items }) => {
    items = items.map(i => ({ ...i.itemRow.items, ...i }));

    const selectedItems = state.selectedItems[groupId];
    const parentItem = selectedItems?.find(i => i.id == parentId);
    if (parentItem) {
      const filteredItems = selectedItems.filter(i => i.parentId !== parentId);
      Vue.set(state, "selectedItems", {
        ...state.selectedItems,
        [groupId]: [...filteredItems, ...items]
      });
    }

    Vue.set(state.showSubItems, parentId, true);
    Vue.set(state.subItems, parentId, items);
  },
  ADD_SUB_ITEM: (state, { groupId, parentId, item }) => {
    const idx = state.items[groupId].findIndex(i => i.id === parentId);
    if (idx !== -1) state.items[groupId][idx]["subCnt"] += 1;
    if (!Array.isArray(state.subItems[parentId])) {
      Vue.set(state.subItems, parentId, []);
    }

    Vue.set(state.showSubItems, parentId, true);
    state.subItems[parentId].forEach(i => {
      if (item.sortOrder > i.sortOrder) return;
      i.sortOrder += 1;
    });
    state.subItems[parentId].push({ ...item.itemRow.items, ...item });
    state.subItems[parentId].sort((a, b) => a.sortOrder - b.sortOrder);

    const selectedItems = state.selectedItems[groupId];
    const parentItem = selectedItems?.find(i => i.id == parentId);
    if (!parentItem) return;

    const filteredItems = selectedItems.filter(i => i.parentId !== parentId);
    Vue.set(state, "selectedItems", {
      ...state.selectedItems,
      [groupId]: [...filteredItems, ...state.subItems[parentId]]
    });
  },
  CLOSE_SUB_ITEMS: (state, { parentId }) => {
    Vue.set(state.showSubItems, parentId, false);
  },
  MOVE_ITEM: (state, { gi, id, pi, gi2, id2, pi2, position }) => {
    // 드래그한 아이템
    let items1 = state.items[gi];
    if (pi > 0) items1 = state.subItems[pi];

    const index1 = items1.findIndex(i => i.id == id);
    const item1 = items1[index1];
    if (!item1) return;
    // 드래그한 아이템 삭제
    Vue.delete(items1, index1);

    if (!state.items[gi2]) state.items[gi2] = [];
    let items2 = state.items[gi2];
    if (pi2 > 0) {
      if (!state.subItems[pi2]) state.subItems[pi2] = [];
      items2 = state.subItems[pi2];
    }
    let index2 = items2.findIndex(i => i.id == id2);
    if (position == "after") index2 += 1;
    if (id2 == "thead" || id2 == pi2) index2 = 0;
    if (id2 == "additional") index2 = items2.length;

    // 드래그한 아이템 추가
    const { sortOrder = 0 } = items2[index2] ?? {};
    items2.splice(index2, 0, {
      ...item1,
      groupId: gi2,
      parentId: pi2,
      sortOrder
    });
    items2.forEach((i, idx) => {
      if (idx > index2) i.sortOrder += 1;
    });

    if (pi) {
      const idx = state.items[gi].findIndex(i => i.id == pi);
      if (idx !== -1) state.items[gi][idx]["subCnt"] -= 1;
      if (state.items[gi][idx]["subCnt"] <= 0) {
        state.items[gi][idx]["subCnt"] = 0;
        state.showSubItems[pi] = false;
      }
    }

    if (pi2) {
      const idx = state.items[gi].findIndex(i => i.id == pi2);
      if (idx !== -1) state.items[gi][idx]["subCnt"] += 1;
    }

    Vue.set(state, "items", { ...state.items });
    Vue.set(state, "subItems", { ...state.subItems });
  },
  MOVE_ITEM_ROLLBACK: (
    state,
    { gi, items1, gi2, items2, pi, subItems1, pi2, subItems2 }
  ) => {
    state.items[gi] = items1;
    state.items[gi2] = items2;
    if (pi) state.subItems[pi] = subItems1;
    if (pi2) state.subItems[pi2] = subItems2;
  },
  UPDATE_ITEM: (state, params) => {
    const { groupId, itemId, parentId, headerValue, itemValue } = params;
    let items = state.items[groupId];
    if (parentId > 0) items = state.subItems[parentId];

    const [item] = items.filter(({ id }) => id == itemId);
    if (!item) return;

    const index = items.findIndex(({ id }) => id == itemId);
    Vue.set(items, index, { ...item, [headerValue]: itemValue });
  },
  DELETE_ITEMS: (state, items) => {
    items.forEach(({ groupId, id, parentId }) => {
      if (parentId > 0) {
        const index = state.subItems[parentId]?.findIndex(i => i.id == id);
        if (index > -1) {
          Vue.delete(state.subItems[parentId], index);
          // 카운트 줄이기
          const idx = state.items[groupId]?.findIndex(i => i.id == parentId);
          if (idx > -1) {
            const { subCnt } = state.items[groupId][idx];
            Vue.set(state.items[groupId][idx], "subCnt", subCnt - 1);
            if (subCnt - 1 <= 0) Vue.set(state.showSubItems, parentId, false);
          }
        }
      }

      const index = state.items[groupId]?.findIndex(i => i.id == id);
      if (index > -1) Vue.delete(state.items[groupId], index);
      if (state.subItems[id]) Vue.delete(state.subItems, id);
    });
  },
  SET_SELECTED_ITEMS: (state, { groupId, selectedItems }) => {
    const items = { ...state.selectedItems, [groupId]: selectedItems };
    Vue.set(state, "selectedItems", items);
  },
  RESET_SELECTED_ITEMS: state => {
    state.selectedItems = {};
  },
  SET_ORGAN_USERS: (state, organUserList) => {
    state.organUserList = organUserList;
  },
  SET_ITEM_ATTRS: (state, { groupId, itemId, parentId, keyValues }) => {
    if (!state.items[groupId]) return;
    if (parentId > 0 && !state.subItems[parentId]) return;

    const items =
      parentId > 0 ? state.subItems[parentId] : state.items[groupId];
    const index = items.findIndex(({ id }) => id == itemId);
    if (!items[index]) return;

    keyValues.forEach(({ key, value }) => {
      Vue.set(items, index, { ...items[index], [key]: value });
    });
  }
};

const actions = {
  async getSubItems({ commit }, { groupId, parentId }) {
    const { status, data: items } = await getSubItems(groupId, parentId);
    if (status !== 200) {
      // 하위 아이템 불러오기 실패
    }

    commit("SET_SUB_ITEMS", { groupId, parentId, items });
  },
  async addItem({ commit, dispatch }, params) {
    const { data: item, status } = await addItem(params);
    let message = "아이템을 추가하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 409: {
        message = "컬럼정보가 잘못되어 아이템을 생성할 수 없습니다.";
        break;
      }
      case 404: {
        message = "그룹이 존재하지 않습니다";
        break;
      }
      case 201: {
        const { groupId, parentId } = params;
        if (parentId > 0) commit("ADD_SUB_ITEM", { groupId, parentId, item });
        else commit("ADD_ITEM", { groupId, item });

        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  // { boardId, gi, id, pi, gi2, id2, pi2, position }
  async moveItem({ state, commit, dispatch }, params) {
    commit("SET_MOVE_ITEM_ID", params.id);
    params.pi = parseInt(params.pi) || 0;
    params.pi2 = parseInt(params.pi2) || 0;

    // 롤백 데이터
    const { items, subItems } = state;
    const { gi, gi2, pi, pi2 } = params;
    const rollback = {
      items1: items[gi].map(i => ({ ...i })),
      items2: items[gi2]?.map(i => ({ ...i })) || [],
      subItems1: subItems[pi]?.map(i => ({ ...i })) || [],
      subItems2: subItems[pi2]?.map(i => ({ ...i })) || []
    };
    commit("MOVE_ITEM", params);

    const { data, status } = await moveItem(params);
    let message = "아이템을 이동하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404:
        message = "아이템이 존재하지 않습니다.";
        break;
      case 201:
        if (pi2 > 0) {
          commit("SET_SUB_ITEMS", { groupId: gi2, parentId: pi2, items: data });
          commit("SET_MOVE_ITEM_ID", 0);
          return;
        }

        commit("SET_ITEMS", { [gi2]: data });
        commit("SET_MOVE_ITEM_ID", 0);
        return;
    }

    commit("MOVE_ITEM_ROLLBACK", { gi, gi2, pi, pi2, ...rollback });
    commit("SET_MOVE_ITEM_ID", 0);
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateItem({ commit, dispatch }, params) {
    commit("UPDATE_ITEM", params);
    const { status } = await updateItem(params);

    let message = "아이템을 수정하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404:
        message = "아이템이 존재하지 않습니다.";
        break;
      case 201:
        return;
    }

    commit("UPDATE_ITEM", { ...params, itemValue: params.prevValue });
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteItems({ commit, dispatch }, { boardId, items }) {
    const { status } = await await deleteItems(boardId, items);

    let message = "아이템을 삭제하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404:
        message = "보드가 존재하지 않습니다.";
        break;
      case 204:
        commit("DELETE_ITEMS", items);
        return true;
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
