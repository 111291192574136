<template>
  <!-- 주메뉴 drawer -->
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <!-- 버튼 -->
    <div class="pa-2" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
      <v-btn
        class="primary"
        block
        large
        depressed
        @click="e => openBookingDialog(e)"
      >
        <h4>시설 예약</h4>
      </v-btn>
    </div>
    <!-- 시설/설비 트리 -->
    <TreeView />
  </v-navigation-drawer>
</template>

<style scoped>
.cr-tree-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>

<script>
//import i18n from "@/_locales";
import { mapActions, mapMutations } from "vuex";
import TreeView from "../components/treeview/TreeView";

export default {
  components: {
    TreeView
  },
  methods: {
    ...mapActions("resourceDialog", ["openResourceBookingDialog"]),
    ...mapMutations("resourceDialog", ["SET_SHOW"]),
    // 자원 예약 dialog 열기
    openBookingDialog(e) {
      e.stopPropagation();
      this.openResourceBookingDialog({ mode: "SAVE" });
    }
  },
  data: () => ({
    drawer: true
  })
};
</script>
