import Vue from "vue";
import {
  getSignList,
  addSign,
  updateUseNewMail,
  updateUseReplyForward,
  editSign,
  deleteSign
} from "@/mail/api/sign.api";
import i18n from "@/_locales";

const state = {
  signList: []
};

const getters = {
  getSignList: ({ signList }) => signList,
  getSelectSignList: ({ signList }) => {
    let selectList = signList.map(({ id, title }) => {
      return { id, title };
    });
    selectList.unshift({ id: 0, title: i18n.t("mail.없음") });
    return selectList;
  },
  getNewDeafault: ({ signList }) => {
    return signList.filter(item => item.useNewMail == 1)[0];
  },
  getRepForDeafault: ({ signList }) => {
    return signList.filter(item => item.useReplyForward == 1)[0];
  }
};

/**
 * sign 조회
 */
const getSign = (list, id) => {
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    if (item.id == id) return item;
  }
};

const mutations = {
  SET_SIGN_LIST: (state, list) => {
    state.signList = list;
  },
  // 서명 추가
  SET_SIGN_ITEM: (state, sign) => {
    state.signList.push(sign);
  },
  // 서명 수정
  UPDATE_SIGN_ITEM: (state, sign) => {
    const index = state.signList.findIndex(({ id }) => id == sign.id);
    Vue.set(state.signList, index, sign);
  },
  // 새 메일 서명 기본값 변경
  SET_NEW_SIGN_DEFAULT: (state, id) => {
    // 현재 기본값 초기화
    const defaultList = state.signList.filter(item => item.useNewMail == 1);
    defaultList.forEach(item => {
      item.useNewMail = 0;
    });

    // 없음일 경우는 return
    if (id == 0) return;
    // 선택한 sign 기본값 업데이트
    const sign = getSign(state.signList, id);
    sign.useNewMail = 1;
  },
  // 답장, 전달 메일 서명 기본값 변경
  SET_REPFOR_SIGN_DEFAULT: (state, id) => {
    // 현재 기본값 초기화
    const defaultList = state.signList.filter(
      item => item.useReplyForward == 1
    );
    defaultList.forEach(item => {
      item.useReplyForward = 0;
    });

    // 없음일 경우는 return
    if (id == 0) return;
    // 선택한 sign 기본값 업데이트
    const sign = getSign(state.signList, id);
    sign.useReplyForward = 1;
  },

  // 서명 삭제
  DELETE_SIGN: (state, deleteId) => {
    const index = state.signList.findIndex(({ id }) => id == deleteId);
    Vue.delete(state.signList, index);
  }
};

const actions = {
  /**
   * 초기 데이터
   */
  async getInitList({ commit }) {
    const { data = [] } = await getSignList();
    commit("SET_SIGN_LIST", data);
  },

  /**
   * 메일 기본값 변경
   */
  async updateDefaultSign({ commit, dispatch }, { id, mailType }) {
    const { status } =
      mailType == "new"
        ? await updateUseNewMail(id)
        : await updateUseReplyForward(id);
    let message = i18n.t("mail.378");
    let type = "ERROR";
    if (status == 201) {
      message = i18n.t("mail.379");
      type = "SUCCESS";
      // 기본값 업데이트
      mailType == "new"
        ? commit("SET_NEW_SIGN_DEFAULT", id)
        : commit("SET_REPFOR_SIGN_DEFAULT", id);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  /**
   * 추가
   */
  async addSign({ commit, dispatch }, { title, signStyle, contents }) {
    const { data = {}, status } = await addSign({ title, signStyle, contents });
    let message = i18n.t("mail.380");
    let type = "ERROR";
    let result = false;
    if (status == 201) {
      message = i18n.t("mail.381");
      type = "SUCCESS";
      result = true;
      commit("SET_SIGN_ITEM", data);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return result;
  },

  /**
   * 수정
   */
  async editSign({ commit, dispatch }, { id, title, signStyle, contents }) {
    const { data = {}, status } = await editSign({
      id,
      title,
      signStyle,
      contents
    });

    let message = i18n.t("mail.382");
    let type = "ERROR";
    let result = false;
    if (status == 201) {
      message = i18n.t("mail.383");
      type = "SUCCESS";
      result = true;
      commit("UPDATE_SIGN_ITEM", data);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return result;
  },

  /**
   * 삭제
   */
  async deleteSign({ commit, dispatch }, id) {
    const { status } = await deleteSign(id);

    let message = i18n.t("mail.384");
    let type = "ERROR";
    if (status == 204) {
      message = i18n.t("mail.385");
      type = "SUCCESS";
      commit("DELETE_SIGN", id);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
