const state = {
  isNavigationSwapped: false
};

const getters = {};

const mutations = {
  SET_IS_NAVIGATION_SWAPPED: (state, navigationSwapped) => {
    state.isNavigationSwapped = navigationSwapped;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
