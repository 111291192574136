<template>
  <div v-if="showBadge" class="overline cr-badge">
    {{ item.newCount }}
  </div>
</template>

<style lang="scss" scoped>
.cr-badge {
  min-width: 24px;
  text-align: center;
}
</style>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showBadge() {
      const { newCount, isEdit, folderType } = this.item;
      return (
        !this.hover &&
        newCount > 0 &&
        !isEdit &&
        folderType !== "DELETED" &&
        folderType !== "SENT" &&
        folderType !== "DRAFTS"
      );
    }
  }
};
</script>
