import { getMessage } from "@/flow/store/utils/messageUtils";
import { addTask, addTasks } from "@/flow/api/task.api";

const initialTask = {
  parentId: 0,
  subTasks: [],
  assignees: [],
  checklist: [],
  content: "",
  status: "REQUESTED",
  priority: "NORMAL",
  progress: 0,
  startDateTimeMillis: 0,
  dueDateTimeMillis: 0
};

const state = {
  newTask: { ...initialTask }
};

const getters = {};

const mutations = {
  SET_NEW_TASK: (state, newTask) => {
    state.newTask = { ...state.newTask, ...newTask };
    state.newTask.checklist.forEach(item => (item.key = item.key || item.id));
  },
  RESET_NEW_TASK: state => {
    state.newTask = { ...initialTask };
  },
  ADD_TASK: (state, { task, viewType }) => {
    const { taskDataMap } = state;
    const { id: taskId, groupId, status } = task;
    const key = viewType === "KANBAN" ? status : groupId;
    const taskViewMap = state[viewType][key];
    if (!taskViewMap) state[viewType][key] = { ids: [], total: 0 };
    else if (!taskViewMap.ids.length && taskViewMap.total > 0) return;

    state.taskDataMap = { ...taskDataMap, [taskId]: task };
    state[viewType][key].ids.push(taskId);
    state[viewType][key].total += 1;
    state[viewType] = { ...state[viewType] };
  },
  ADD_SUB_TASK: (state, task) => {
    const { id: taskId, parentId } = task;
    const parentTask = state.taskDataMap[parentId];
    if (!parentTask) return;

    const { subCnt = 0, subTasks = [] } = parentTask;
    parentTask["subCnt"] = subCnt + 1;
    parentTask["subTasks"] = [...subTasks, taskId];
    state.taskDataMap[taskId] = task;
  }
};

const actions = {
  async addTask({ state, rootState, rootGetters, commit, dispatch }) {
    const { projectId } = rootState.flowRoute;
    const { status, data } = await addTask(projectId, state.newTask);
    const message = getMessage(status);
    // 업무등록 dialog에서 호출했을때
    if (rootState.flowDialog.type === "addTask") {
      if (message) commit("flowDialog/SET_CONFIRMED", false, { root: true });
      else commit("flowDialog/CLOSE_DIALOG", null, { root: true });
    }
    if (message) return dispatch("snackbar", { message });

    const viewType = rootGetters["flowRoute/viewType"];
    await dispatch(`scrollToTaskIn${viewType}`, [data]);
    commit("RESET_NEW_TASK");
  },
  async addTasks({ rootState, rootGetters, commit, dispatch }, params) {
    commit("loader/SET_SHOW", true, { root: true });

    params.projectId = rootState.flowRoute.projectId;
    const { status, data } = await addTasks(params);
    const message = getMessage(status);
    if (message) {
      commit("loader/SET_SHOW", false, { root: true });
      return dispatch("snackbar", { message });
    }

    const viewType = rootGetters["flowRoute/viewType"];
    await dispatch(`scrollToTaskIn${viewType}`, data);
    commit("loader/SET_SHOW", false, { root: true });
  },
  async addSubTask({ rootState, commit, dispatch }, task) {
    const { projectId } = rootState.flowRoute;
    task = { ...initialTask, ...task };
    const { status, data } = await addTask(projectId, task);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("ADD_SUB_TASK", data);
  },
  async scrollToTaskInLIST({ getters, dispatch }, addedTasks) {
    if (!addedTasks || !Array.isArray(addedTasks)) return;

    // 추가 페이지 호출
    const [{ groupId }] = addedTasks;
    await dispatch("loadMorePages", { viewType: "LIST", groupId });

    // 추가된 업무가 목록에 있는지 확인
    const existingTasks = getters["LIST_tasks"][groupId] || [];
    const checkParams = { viewType: "LIST", existingTasks, addedTasks };
    const lastTaskId = await dispatch("checkTaskInList", checkParams);

    // 해당 엘리먼트가 안보이면 스크롤이동
    setTimeout(() => {
      const lastTaskEl = document.getElementById(`task_${lastTaskId}`);
      if (!lastTaskEl) return;

      const { innerHeight } = window;
      const { clientHeight } = document.documentElement;
      const { bottom } = lastTaskEl.getBoundingClientRect();
      if (bottom <= (innerHeight || clientHeight)) return;

      document.getElementById(`task_${lastTaskId}`).scrollIntoView();

      const scrollEl = lastTaskEl.closest("#scrollEl");
      const { top } = scrollEl.getBoundingClientRect();
      const { bottom: bottom2 } = lastTaskEl.getBoundingClientRect();
      if (top + scrollEl.clientHeight - bottom2 <= 120) return;

      scrollEl.scrollBy(0, -150);
    }, 100);
  },
  async scrollToTaskInKANBAN({ getters, dispatch }, addedTasks) {
    if (!addedTasks || !Array.isArray(addedTasks)) return;

    // 추가 페이지 호출
    const [{ status }] = addedTasks;
    await dispatch("loadMorePages", { viewType: "KANBAN", status });

    // 추가된 업무가 목록에 있는지 확인
    const viewType = "KANBAN";
    const existingTasks = getters["KANBAN_tasks"][status];
    dispatch("checkTaskInList", { viewType, existingTasks, addedTasks });

    setTimeout(() => {
      const scrollEl = document.querySelector(`#scrollEl_${status}`);
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }, 100);
  },
  // 추가 페이지 호출
  async loadMorePages({ state, getters, commit, dispatch }, params) {
    commit("loader/SET_SHOW", true, { root: true });
    const { viewType, groupId, status } = params;
    const key = viewType == "LIST" ? groupId : status;
    if (state[viewType][key]) {
      const { total } = state[viewType][key] || {};
      const { length } = getters[`${viewType}_tasks`][key] || [];
      const requiredPage = Math.ceil((total - length) / state.pageSize);

      for (let i = 0; i < requiredPage; i += 1) {
        const lastTask = getters[`${viewType}_tasks`][key]?.at(-1);
        const { listSortOrder, kanbanSortOrder } = lastTask;
        const sortOrder = viewType == "LIST" ? listSortOrder : kanbanSortOrder;
        await dispatch("getTasks", { sortOrder, groupId, status });
      }
    }

    commit("loader/SET_SHOW", false, { root: true });
  },
  checkTaskInList({ commit }, { viewType, existingTasks, addedTasks }) {
    let { id: lastTaskId } = existingTasks.at(-1) || {};
    addedTasks.forEach(task => {
      if (existingTasks.findIndex(t => t.id === task.id) > -1) return;

      commit("ADD_TASK", { task, viewType });
      lastTaskId = task.id;
    });

    return lastTaskId;
  }
};

export default { state, getters, mutations, actions };
