import i18n from "@/_locales";
import {
  getDashboardConfig,
  updateMailboxId,
  addBookmark,
  modifyBookmark,
  deleteBookmark,
  addWorkflow,
  deleteWorkflow
} from "@/home/api/home.api";

const state = {
  mailboxId: null,
  bookmarks: [],
  workflowIds: [],
  calReload: false
};

const getters = {
  getMailboxId: ({ mailboxId }) => mailboxId,
  getBookmarks: ({ bookmarks }) => bookmarks,
  getWorkflowIds: ({ workflowIds }) => workflowIds,
  calReload: ({ calReload }) => calReload
};

// state값 수정
const mutations = {
  SET_INIT: state => {
    state.mailboxId = -1;
    state.bookmarks = [];
    state.workflowIds = [];
  },
  SET_DASHBOARD_CONFIG: (state, { mailboxId, bookmarks, workflow }) => {
    state.mailboxId = mailboxId ?? -1;
    state.workflowIds = workflow ?? [];
    state.bookmarks = bookmarks ?? [];
  },
  SET_MAILBOX_ID: (state, mailboxId) => (state.mailboxId = mailboxId),
  SET_BOOKMARK: (state, bookmark) => (state.bookmarks = bookmark),
  SET_WORKFLOW_IDS: (state, workflowIds) => (state.workflowIds = workflowIds),
  SET_CAL_RELOAD: (state, calReload) => (state.calReload = calReload)
};

// mutations 호출 및 비동기 처리
const actions = {
  async initDashboardData({ commit, dispatch }) {
    const { status, data } = await getDashboardConfig();
    if (status == 200) {
      commit("SET_DASHBOARD_CONFIG", data);
    }

    setTimeout(() => {
      dispatch("splash/setShowSplash", false, { root: true });
    }, 300);
  },

  async updateMailboxId({ commit, dispatch }, mailboxId) {
    const { status } = await updateMailboxId(mailboxId);
    const message = status == 200 ? i18n.t("common.61") : i18n.t("common.62");
    const type = status == 200 ? "SUCCESS" : "ERROR";

    if (status == 200) commit("SET_MAILBOX_ID", mailboxId);
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },

  async addBookmark({ commit, dispatch }, params) {
    commit("loader/SET_SHOW", true, { root: true });
    const { data, status } = await addBookmark(params);

    let message = { message: i18n.t("common.64"), type: "ERROR" };
    if (status === 201) {
      commit("SET_BOOKMARK", data);
      message = { message: i18n.t("common.63"), type: "SUCCESS" };
    }

    dispatch("snackbar/openSnackbar", message, { root: true });
    commit("loader/SET_SHOW", false, { root: true });
  },
  async modifyBookmark({ commit, dispatch }, params) {
    commit("loader/SET_SHOW", true, { root: true });
    const { data, status } = await modifyBookmark(params);

    let message = { message: i18n.t("common.43"), type: "ERROR" };
    if (status === 201) {
      commit("SET_BOOKMARK", data);
      message = { message: i18n.t("common.44"), type: "SUCCESS" };
    }

    dispatch("snackbar/openSnackbar", message, { root: true });
    commit("loader/SET_SHOW", false, { root: true });
  },
  async deleteBookmark({ commit, dispatch }, id) {
    const { data, status } = await deleteBookmark(id);

    let message = { message: i18n.t("common.66"), type: "ERROR" };
    if (status === 200) {
      commit("SET_BOOKMARK", data);
      message = { message: i18n.t("common.65"), type: "SUCCESS" };
    }

    dispatch("snackbar/openSnackbar", message, { root: true });
  },

  /* 워크 플로우 */
  async addWorkflow({ commit, dispatch }, id) {
    const { data, status } = await addWorkflow(id);

    const message = status == 200 ? i18n.t("common.63") : i18n.t("common.64");
    const type = status == 200 ? "SUCCESS" : "ERROR";

    if (status == 200) {
      commit("SET_WORKFLOW_IDS", data);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteWorkflow({ commit, dispatch }, id) {
    const { data, status } = await deleteWorkflow(id);
    const message = status == 200 ? i18n.t("common.65") : i18n.t("common.66");
    const type = status == 200 ? "SUCCESS" : "ERROR";

    if (status == 200) {
      commit("SET_WORKFLOW_IDS", data);
    }
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
