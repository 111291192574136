<template>
  <div class="text-center">
    <v-menu
      :close-on-content-click="false"
      absolute
      :position-y="$vuetify.breakpoint.xs ? 56 : 76"
    >
      <template v-slot:activator="{ on: settingMenu, attrs }">
        <v-tooltip bottom :disabled="$vuetify.breakpoint.xs">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...settingMenu }">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span v-text="$t('common.사용자_정보')" />
        </v-tooltip>
      </template>
      <v-card>
        <v-list>
          <v-list-item class="cr-user-contents-wrapper">
            <v-list-item-content>
              <v-list-item-title
                class="font-weight-bold text-subtitle-1 mb-1"
                v-text="getUserInfo.displayName"
              />
              <v-list-item-subtitle
                class="text-subtitle-2 font-weight-regular"
                v-text="getUserInfo.username"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-menu left offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                class="cr-language-menu-wrapper align-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-content class="px-2">
                  <v-list-item-title v-text="$t('common.언어')">
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                  class="ma-0 text-caption rounded px-3 py-1"
                  v-text="selectedLanguageName"
                ></v-list-item-action>
              </v-list-item>
            </template>
            <v-list dense nav>
              <v-list-item-group v-model="selectedLanguage">
                <v-list-item
                  v-for="(item, index) in getLanguageList"
                  :key="index"
                  :value="item.value"
                  @click="changeLang(item.value)"
                >
                  <v-list-item-title v-text="item.name" />
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-list-item
            link
            @click="
              $router.push({ name: 'account', params: { tabType: 'info' } })
            "
          >
            <v-list-item-title
              class="px-2"
              v-text="$t('common.계정_설정')"
            ></v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isAdmin" link @click="gotoAdmin">
            <v-list-item-title
              class="px-2"
              v-text="$t('common.관리자_바로가기')"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item link @click="logout">
            <v-list-item-title
              class="px-2"
              v-text="$t('common.로그아웃')"
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import storage from "@/commons/api/storage";
import i18n from "@/_locales";

export default {
  data() {
    return {
      name: "",
      email: "",
      selectedLanguage: ""
    };
  },
  mounted() {
    this.selectedLanguage =
      this.getUserInfo?.languageType?.toLowerCase() || i18n.fallbackLocale;
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo", "getLanguageList"]),
    selectedLanguageName() {
      const languageObj = this.getLanguageList.filter(
        lang => lang.value == this.selectedLanguage
      );
      return languageObj.length == 0 ? "" : languageObj[0].name;
    },
    isAdmin() {
      if (this.getUserInfo?.roles) {
        return this.getUserInfo.roles.includes("ROLE_ADMIN");
      }
      return false;
    }
  },
  methods: {
    ...mapActions("auth", ["clearToken", "getLogoutUrl"]),
    ...mapActions("auth", ["updateUserLanguage"]),
    async logout() {
      const result = await this.getLogoutUrl();
      this.clearToken();
      storage.clearAnnounceTime();
      if (result.data.url && result.data.url.length > 0) {
        window.location.href = result.data.url;
      } else {
        window.location.href = "/";
      }
    },
    gettingReady() {
      alert(i18n.t("common.1"));
    },
    async changeLang(lang) {
      await this.updateUserLanguage(lang.toUpperCase());
    },
    gotoAdmin() {
      // 인증 토큰 설정
      document.getElementById("accessToken").value = storage.getAccessToken();
      document.getElementById("refreshToken").value = storage.getRefreshToken();

      // 페이지 이동
      document.getElementById(
        "directForm"
      ).action = `${this.getUserInfo.adminUrl}/oauth/sso/login`;
      document.getElementById("directForm").submit();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  min-width: 235px;
  .v-list ::v-deep {
    .cr-user-contents-wrapper {
      .v-list-item__subtitle {
        color: #a1a5b7 !important;
      }
    }
    .cr-language-menu-wrapper {
      cursor: pointer;
      .v-list-item__action {
        background-color: #f6f6f6;
        color: #7e8299 !important;
      }
    }
  }
}
</style>
