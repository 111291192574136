import resource from "@/resource/store/modules/resource";
import resourceRoute from "@/resource/store/modules/route";
import resourceLayout from "@/resource/store/modules/layout";
import resourceView from "@/resource/store/modules/resourceView";
import resourceDialog from "@/resource/store/modules/resourceBookingDialog";

export default {
  resource,
  resourceRoute,
  resourceLayout,
  resourceView,
  resourceDialog
};
