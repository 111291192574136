import _create from "./create";
import _update from "./update";
import _delete from "./delete";
import _checklist from "./checklist";

const state = {
  ..._create.state,
  ..._update.state,
  ..._delete.state
};

const getters = {
  ..._create.getters,
  ..._update.getters,
  ..._delete.getters
};

const mutations = {
  ..._create.mutations,
  ..._update.mutations,
  ..._delete.mutations,
  ..._checklist.mutations
};

const actions = {
  ..._create.actions,
  ..._update.actions,
  ..._delete.actions,
  ..._checklist.actions
};

export default { state, getters, mutations, actions };
