<template>
  <v-tooltip bottom v-if="showSearchMenu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="click">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    {{ $t("calendar.검색") }}
  </v-tooltip>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    route: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters("calLayout", { calShowSearch: "showSearch" }),
    showSearchMenu() {
      switch (this.route) {
        case "cal":
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    ...mapMutations("calLayout", { setCalShowSearch: "SET_SHOW_SEARCH" }),
    click() {
      switch (this.route) {
        case "cal":
          this.setCalShowSearch(!this.calShowSearch);
          break;

        default:
          break;
      }
    }
  }
};
</script>
