import http from "@/commons/api/http";

const BASE_URL = process.env.VUE_APP_API_SERVER_URL;
export const EXTERNAL_SERVICE = {
  googleCalendar: {
    name: "GOOGLE_CALENDAR",
    registrationId: "google-calendar"
  }
};

export const openExternalOAuth2Window = registrationId => {
  const authorizationUrl = `${BASE_URL}/oauth2/authorization/${registrationId}`;

  popupCenter({
    url: authorizationUrl,
    title: "OAuth2 Connection",
    w: 512,
    h: 630
  });
};

const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if (window.focus) newWindow.focus();
};

export const connectExternalOAuth2 = (serviceType, params) => {
  return http.get(`${BASE_URL}/login/oauth2/code/${serviceType}`, {
    params: params,
    withCredentials: true
  });
};

export const fetchConnections = serviceType => {
  return http.get("/api/oauth2/connections", {
    params: {
      serviceType: serviceType
    }
  });
};

export const disconnectExternalOAuth2 = connectionId => {
  return http.delete(`/api/oauth2/connections/${connectionId}`);
};
