import { updateTutorialStatus } from "@/commons/api/auth.api";
import i18n from "@/_locales";

const state = {
  speed: 300,
  status: {
    home: false,
    mail: false,
    calendar: false,
    contact: false,
    drive: false,
    board: false,
    workflow: false,
  },
};

const getters = {
  getSpeed: ({ speed }) => speed,
  useHome: ({ status }) => status.home,
  useMail: ({ status }) => status.mail,
  useCalendar: ({ status }) => status.calendar,
  useContact: ({ status }) => status.contact,
  useDrive: ({ status }) => status.drive,
  useBoard: ({ status }) => status.board,
  useWorkflow: ({ status }) => status.workflow,
};

const mutations = {
  SET_HOME: (state, value) => (state.status.home = value),
  SET_MAIL: (state, value) => (state.status.mail = value),
  SET_CALENDAR: (state, value) => (state.status.calendar = value),
  SET_CONTACT: (state, value) => (state.status.contact = value),
  SET_DRIVE: (state, value) => (state.status.drive = value),
  SET_BOARD: (state, value) => (state.status.board = value),
  SET_WORKFLOW: (state, value) => (state.status.workflow = value),
};

const actions = {
  async initTutorial({ commit, dispatch }, { module }) {
    const {
      homeTutorial,
      mailTutorial,
      calendarTutorial,
      contactTutorial,
      driveTutorial,
      boardTutorial,
      workflowTutorial,
    } = await dispatch("auth/getStoredUserServiceConfig", {}, { root: true });

    setTimeout(() => {
      switch (module) {
        case "home":
          commit("SET_HOME", homeTutorial);
          break;
        case "mail":
          commit("SET_MAIL", mailTutorial);
          break;
        case "calendar":
          commit("SET_CALENDAR", calendarTutorial);
          break;
        case "contact":
          commit("SET_CONTACT", contactTutorial);
          break;
        case "drive":
          commit("SET_DRIVE", driveTutorial);
          break;
        case "board":
          commit("SET_BOARD", boardTutorial);
          break;
        case "workflow":
          commit("SET_WORKFLOW", workflowTutorial);
          break;
      }
    }, 700);
  },

  async updateTutorialStatus({ commit, dispatch }, { module, value }) {
    switch (module) {
      case "home":
        commit("SET_HOME", value);
        break;
      case "mail":
        commit("SET_MAIL", value);
        break;
      case "calendar":
        commit("SET_CALENDAR", value);
        break;
      case "contact":
        commit("SET_CONTACT", value);
        break;
      case "drive":
        commit("SET_DRIVE", value);
        break;
      case "board":
        commit("SET_BOARD", value);
        break;
      case "workflow":
        commit("SET_WORKFLOW", value);
        break;
    }

    const { status } = await updateTutorialStatus({
      module: module,
      status: value,
    });

    let type = "ERROR";
    let message = i18n.t("common.62");
    if (status == 200) {
      type = "SUCCESS";
      message = i18n.t("common.61");
      dispatch("auth/updateTutorialStatus", { module, value }, { root: true });
    }
    dispatch("snackbar/openSnackbar", { type, message }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
