import i18n from "@/_locales";
import Vue from "vue";
import {
  getDefaultContactGroupList,
  getCardList,
  getDefaultOrganList,
  getOrganListByParentId,
  getOrganUserList,
  getUserOrgan
} from "../../api/organ.api";
import { getContactGroupListByParentId } from "@/contact/api/group.api";
import { fetchCustomerGroupListByParent } from "@/customer/api/group.api";
import { fetchCardList, searchCardList } from "@/customer/api/card.api";

const state = {
  contact: [],
  organ: [],
  customer: [],
  list: [],
  loading: false,
  totalElements: 0,
  pageSize: 20,
  page: 1,
  fullCode: "",

  // 활성화된 노드정보
  activeNode: {}
};

/**
 * 노드 조회
 * @param {*} tree    tree
 * @param {*} nodeId  찾을 node Id
 * @returns 트리 노드
 */
const getNode = (tree, nodeId) => {
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i];

    // 해당노드가 찾고자 하는 노드인 경우 리턴
    if (node.id == nodeId) return node;

    // 만약 children(하위노드)가 있는경우 하위노드에서 서치
    if (node.children) {
      const childrenResult = getNode(node.children, nodeId);
      if (childrenResult) return childrenResult;
    }
  }
};

const getters = {
  getContact: ({ contact }) => contact,
  getOrgan: ({ organ }) => organ,
  getCustomer: ({ customer }) => customer,
  getList: ({ list }) => list,
  getLoading: ({ loading }) => loading,
  getTotalElements: ({ totalElements }) => totalElements,
  getPageSize: ({ pageSize }) => pageSize,
  getPage: ({ page }) => page,
  getFullCode: ({ fullCode }) => fullCode,

  activeNode: ({ activeNode }) => activeNode
};
const mutations = {
  // 기본 주소록 트리 set
  SET_DEFAULT_CONTACT: (state, contact) => (state.contact = contact),
  // 자식노드 SET
  SET_CHILDREN: (state, { parentId, children, type }) => {
    const stateDataByType = {
      customer: state.customer,
      organ: state.organ,
      contact: state.contact
    };
    const node = getNode(stateDataByType[type], parentId);

    if (!node) return;
    Vue.set(node, "children", children);
  },
  // 기본 조직도 트리 set
  SET_DEFAULT_ORGAN: (state, organ) => (state.organ = organ),
  // loading 표시
  SET_LOADING: (state, loading) => (state.loading = loading),
  // 리스트 set
  SET_LIST: (state, { list, totalElements, page }) => {
    state.list = list;
    state.totalElements = totalElements;
    state.page = page;
    state.loading = false;
  },
  SET_FULLCODE: (state, fullCode) => {
    state.fullCode = fullCode;
  },
  SET_DEFAULT_CUSTOMER: (state, customer) => (state.customer = customer),
  RESET_DATA: state => {
    state.contact = [];
    state.organ = [];
    state.list = [];
    state.totalElements = 0;
    state.fullCode = "";
  },

  SET_ACTIVE_NODE: (state, activeNode) => {
    state.activeNode = activeNode;
  }
};
const actions = {
  // 주소록 초기 트리 데이터 가져오기
  async getContactGroupData({ commit }) {
    const { data = [] } = await getDefaultContactGroupList();
    const defaultTree = data.map(
      ({
        id,
        groupName,
        contactGroupType,
        parentId,
        childCount,
        children = []
      }) => {
        // 다국어 처리를 위한 기본 폴더 맵핑
        if (
          [
            "ALL", // 전체
            "IMPORTANT", // 중요한 연락처
            "RECENT", // 최근 등록한 연락처
            "PRIVATE_ROOT" // 내 주소록
          ].indexOf(contactGroupType) > -1
        ) {
          groupName = i18n.t(`contact.group_${contactGroupType}`);
        }

        let childrenObj = {};
        if (childCount > 0) {
          const mappedChildren = children.map(
            ({
              id,
              groupName,
              contactGroupType,
              email,
              parentId,
              childCount
            }) => {
              const children = childCount > 0 ? { children: [] } : {};
              return {
                id,
                name: groupName,
                contactGroupType,
                email,
                parentId,
                childCount,
                ...children
              };
            }
          );
          childrenObj = { children: mappedChildren };
        }
        return {
          id,
          name: groupName,
          contactGroupType,
          parentId,
          childCount,
          ...childrenObj
        };
      }
    );
    commit("SET_DEFAULT_CONTACT", defaultTree);

    // 초기 트리 데이터를 가져온 후 처음 나오는 리스트는 전체연락처
    // await dispatch("getCardList", { contactGroupId: -1, page: 1 });
  },

  // 주소록 트리 자식데이터 가져오기
  async getGroupsByParentId({ commit }, { parentId }) {
    const { data = [] } = await getContactGroupListByParentId({ parentId });
    const children = data.map(
      ({ id, groupName, contactGroupType, parentId, childCount }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          name: groupName,
          contactGroupType,
          parentId,
          ...childrenObj
        };
      }
    );
    commit("SET_CHILDREN", { parentId, children, type: "contact" });
  },

  // 주소록 리스트 가져오기
  async getCardList(
    { commit, getters },
    {
      contactGroupId = 0,
      pageSize = 20,
      sort = "userName",
      dir = "asc",
      page = 1,
      importantStatus = 0,
      searchWord = ""
    }
  ) {
    commit("SET_LOADING", true);
    // 폴더마다 parameter 조정
    switch (contactGroupId) {
      case -2:
        importantStatus = 1;
        break;
      case -3:
        sort = "id";
        dir = "desc";
        break;
      default:
        importantStatus = 0;
    }

    pageSize = getters.getPageSize;

    const { data = [] } = await getCardList({
      groupId: contactGroupId < 0 ? 0 : contactGroupId,
      pageSize,
      sort,
      dir,
      page: page - 1,
      importantStatus,
      searchWord
    });

    const { content, totalElements } = data;

    const list = content.map(
      ({ id, userName, email, company, position, importantStatus }) => {
        return {
          contactId: id,
          valid: true,
          value: `"${userName}" <${email}>`,
          type: "CONTACT",
          name: userName,
          email,
          importantStatus,
          organization: company,
          jobTitle: position
        };
      }
    );

    commit("SET_LIST", { list, totalElements, page });
  },

  // 조직도 트리 데이터 가져오기
  async getOrganData({ commit }) {
    const { data = [] } = await getDefaultOrganList();

    const defaultTree = data.map(
      ({ id, parentId, name, email, childCount, children = [] }) => {
        let childrenObj = {};
        if (childCount > 0) {
          const mappedChildren = children.map(
            ({ id, parentId, name, email, childCount }) => {
              const children = childCount > 0 ? { children: [] } : {};
              return {
                id,
                parentId,
                name,
                email,
                childCount,
                ...children
              };
            }
          );
          childrenObj = { children: mappedChildren };
        }
        return {
          id,
          parentId,
          name,
          email,
          childCount,
          ...childrenObj
        };
      }
    );

    await commit("SET_DEFAULT_ORGAN", defaultTree);

    // const fullCode = data.fullCode

    // await dispatch("getOrganUserList", {
    //   organId: "0001"
    // });
  },

  // 조직도 트리 자식데이터 가져오기
  async getOrganByParentId({ commit }, { parentId }) {
    const { data = [] } = await getOrganListByParentId({ parentId });

    const children = data.map(({ id, parentId, name, email, childCount }) => {
      const childrenObj = childCount > 0 ? { children: [] } : {};
      return {
        id,
        parentId,
        name,
        email,
        childCount,
        ...childrenObj
      };
    });
    commit("SET_CHILDREN", { parentId, children, type: "organ" });
  },

  // 조직도 유저 리스트 가져오기
  async getOrganUserList(
    { getters, commit },
    { organId, pageSize = 20, page = 1, searchWord = "" }
  ) {
    commit("SET_LOADING", true);

    pageSize = getters.getPageSize;

    const { data = [] } = await getOrganUserList({
      organId,
      pageSize,
      page: page - 1,
      searchWord
    });

    const { content, totalElements } = data;

    const list = content.map(
      ({ id, jobGrade, accountName, organizationName, username }) => {
        return {
          userId: id,
          valid: true,
          value: `"${accountName}" <${username}>`,
          type: "MEMBER",
          name: accountName,
          email: username,
          organization: organizationName,
          jobGrade
        };
      }
    );

    commit("SET_LIST", { list, totalElements, page });
  },

  // 유저의 대표조직 전체 코드 가져오기
  async getUserOrganCode({ commit }) {
    const { data = [] } = await getUserOrgan();
    commit("SET_FULLCODE", data ? data.fullCode : "");
  },
  // 고객 주소록 초기 트리 데이터 가져오기
  async getCustomerDefaultList({ commit }) {
    const { data = [] } = await fetchCustomerGroupListByParent(0);

    const customers = data.map(
      ({ id, parentId, groupName, customerGroupType, childCount }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          name: groupName,
          customerGroupType,
          childCount,
          ...childrenObj
        };
      }
    );
    commit("SET_DEFAULT_CUSTOMER", customers);
  },
  // 고객 주소록 초기 트리 데이터 가져오기
  async getCustomerChildrenByParentId({ commit }, { parentId }) {
    const { data = [] } = await fetchCustomerGroupListByParent(
      parentId ? parentId : 0
    );

    const children = data.map(
      ({ id, parentId, groupName, customerGroupType, childCount }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          name: groupName,
          customerGroupType,
          childCount,
          ...childrenObj
        };
      }
    );
    commit("SET_CHILDREN", { parentId, children, type: "customer" });
  },

  // 고객주소록 리스트 가져오기
  async getCustomerCardList(
    { commit, getters },
    {
      customerGroupId = 0,
      pageSize = 20,
      sort = "userName",
      dir = "asc",
      page = 1,
      searchType = "ALL",
      keyword
    }
  ) {
    commit("SET_LOADING", true);
    pageSize = getters.getPageSize;
    const { data = [] } = await fetchCardList({
      groupId: customerGroupId,
      pageSize,
      sort,
      dir,
      page: page - 1,
      searchType,
      keyword
    });

    const { content, totalElements } = data;

    const list = content.map(({ id, userName, email, company, position }) => {
      return {
        customerCardId: id,
        valid: true,
        value: `"${userName}" <${email}>`,
        type: "CUSTOMER",
        name: userName,
        email,
        organization: company,
        jobTitle: position
      };
    });

    commit("SET_LIST", { list, totalElements, page });
  },

  // 고객주소록 리스트 가져오기 - 검색
  async searchCustomerCardList(
    { commit, getters },
    {
      pageSize = 20,
      sort = "userName",
      dir = "asc",
      page = 1,
      searchType = "ALL",
      keyword
    }
  ) {
    commit("SET_LOADING", true);
    pageSize = getters.getPageSize;
    const { data = [] } = await searchCardList({
      pageSize,
      sort,
      dir,
      page: page - 1,
      searchType,
      keyword
    });

    const { content, totalElements } = data;

    const list = content.map(({ id, userName, email, company, position }) => {
      return {
        customerCardId: id,
        valid: true,
        value: `"${userName}" <${email}>`,
        type: "CUSTOMER",
        name: userName,
        email,
        organization: company,
        jobTitle: position
      };
    });

    commit("SET_LIST", { list, totalElements, page });
  },

  // 초기화
  async resetData({ commit }) {
    commit("RESET_DATA");
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
