const composeTypeConverter = type => {
  const types = [
    { value: 1, name: "reply" },
    { value: 2, name: "forward" },
    { value: 3, name: "allReply" },
    { value: 4, name: "draft" },
    { value: 5, name: "resend" },
    { value: 6, name: "tome" },
    { value: 7, name: "contact" },
    { value: 8, name: "drive" },
    { value: 9, name: "googleDrive" },
    { value: 10, name: "customer" }
  ];

  if (!parseInt(type)) return "init";
  return types.find(t => t.value == type)?.name || "init";
};

export { composeTypeConverter };
