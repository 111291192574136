<template>
  <v-menu
    v-model="menuOpened"
    offset-x
    content-class="elevation-3"
    transition="slide-x-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ attrs, on }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center justify-space-between">
            {{ $t("calendar.107") }}
            <v-icon>
              mdi-menu-right
            </v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-text class="d-flex align-center">
        <v-menu
          v-model="fromDatePickerOpened"
          :close-on-content-click="false"
          transition="slide-y-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              v-bind="attrs"
              v-on="on"
              readonly
              hide-details
              dense
              filled
              rounded
              :class="{ 'order-1': isReverse }"
              :style="{ maxWidth: '128px' }"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="fromDate"
            no-title
            :day-format="datePickerDayFormat"
          ></v-date-picker>
        </v-menu>
        <span :class="{ 'ml-1': !isReverse, 'mr-1': isReverse }">
          {{ $t("calendar.58") }}
        </span>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined color="error" @click="openDeleteByUntilConfirm">
          {{ $t("common.삭제") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import i18n from "@/_locales";
import { mapActions, mapMutations } from "vuex";
import moment from "moment/moment";
import { deleteEventByDate } from "@/calendar/api/cal.api";

export default {
  props: {
    calendar: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      menuOpened: false,
      fromDatePickerOpened: false,
      fromDate: moment().format("YYYY-MM-DD")
    };
  },
  computed: {
    isReverse() {
      // 문법 어순에 따라 컴포넌트 배치 순서 변경 하기 위함
      return i18n.locale !== "ko";
    }
  },
  watch: {
    menuOpened(value) {
      if (!value) return;

      this.fromDate = moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapMutations("cal", ["SHOW_LOADING_OVERLAY", "HIDE_LOADING_OVERLAY"]),
    ...mapActions("cal", ["reloadCalendarEvents"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    datePickerDayFormat(val) {
      return Number(val.substr(val.lastIndexOf("-") + 1));
    },
    openDeleteByUntilConfirm() {
      this.confirm({
        headline: "",
        message: this.$t("calendar.149", {
          fromDate: moment(this.fromDate).format("YYYY-MM-DD")
        }),
        callback: async () => {
          this.SHOW_LOADING_OVERLAY();
          const param = {
            calendarId: this.calendar.id,
            calendarTitle: this.calendar.title,
            fromDate: moment(this.fromDate).valueOf()
          };

          const response = await deleteEventByDate(param);
          if (response.message) {
            this.openSnackbar({
              message: i18n.t("calendar.100"),
              type: "ERROR"
            });
          } else {
            await this.reloadCalendarEvents([this.calendar.id]);
            this.openSnackbar({
              message: i18n.t("calendar.60", {
                value: moment(this.fromDate).format("YYYY-MM-DD")
              }),
              type: "SUCCESS"
            });
          }
          this.HIDE_LOADING_OVERLAY();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-text-field .v-input__slot {
    padding: 0 16px;

    input {
      text-align: center;
    }
  }
}
</style>
