<template>
  <v-icon
    :color="color"
    :class="{ 'ml-3': !!item.parentId && !item.childCount }"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    icon() {
      const { boardType } = this.item;
      if (boardType === "FOLDER") return "mdi-folder";
      return "mdi-application-outline";
    },
    color() {
      const { id: itemId } = this.item;
      const [activeItem] = this.activeItems.filter(i => i.id == itemId);
      if (!activeItem) return "";
      return "primary";
    }
  }
};
</script>
