const rootContactGroupType = {
  PRIVATE_ROOT: "PRIVATE_ROOT"
};

const allContactGroupType = {
  ALL: "ALL"
};

const privateContactGroupType = {
  PRIVATE: "PRIVATE"
};

const virtualContactGroupConverter = contactGroupId => {
  const types = [
    { value: -1, name: "all" },
    { value: -2, name: "important" },
    { value: -3, name: "new" },
    { value: -4, name: "search" }
  ];

  if (contactGroupId === undefined) return contactGroupId;

  const result = types.filter(({ value, name }) =>
    isNaN(parseInt(contactGroupId))
      ? name === contactGroupId.toLowerCase()
      : value === contactGroupId
  );

  if (result.length === 0) return contactGroupId;
  else
    return isNaN(parseInt(contactGroupId)) ? result[0].value : result[0].name;
};

export {
  rootContactGroupType,
  allContactGroupType,
  privateContactGroupType,
  virtualContactGroupConverter
};
