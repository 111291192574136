import { getMessage } from "@/flow/store/utils/messageUtils";
import { getTask } from "@/flow/api/task.api";

const state = {
  detailTaskId: null,
  isDetailCollapsed: true
};

const getters = {
  detailTask: ({ taskDataMap, detailTaskId }) => taskDataMap[detailTaskId]
};

const mutations = {
  SET_DETAIL_TASK_ID: (state, task) => {
    const ids = [];
    task.subTasks?.forEach(_task => {
      state.taskDataMap[_task.id] = _task;
      ids.push(_task.id);
    });

    state.taskDataMap[task.id] = { ...task, subTasks: ids };
    state.detailTaskId = task.id;
  },
  RESET_DETAIL_TASK_ID: state => {
    state.detailTaskId = null;
    state.isDetailCollapsed = true;
  },
  SET_IS_DETAIL_COLLAPSED: (state, isDetailCollapsed) => {
    state.isDetailCollapsed = isDetailCollapsed;
  }
};

const actions = {
  async showTaskDetail({ commit, dispatch }, task) {
    if (!task) return commit("RESET_DETAIL_TASK_ID");

    const { projectId, id: taskId } = task;
    const { status, data } = await getTask(projectId, taskId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_DETAIL_TASK_ID", data);
  }
};

export default { state, getters, mutations, actions };
