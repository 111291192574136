import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  addChecklist,
  updateChecklist,
  deleteChecklist
} from "@/flow/api/task.api";

const mutations = {
  UPDATE_CHECKLIST: (state, { taskId, checklist }) => {
    const task = state.taskDataMap[taskId];
    if (!task) return;

    state.taskDataMap[taskId] = { ...task, checklist };
  }
};

const actions = {
  async addChecklist({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data: checklist } = await addChecklist(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { taskId } = params;
    commit("UPDATE_CHECKLIST", { taskId, checklist });
  },
  async updateChecklist({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data: checklist } = await updateChecklist(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { taskId } = params;
    commit("UPDATE_CHECKLIST", { taskId, checklist });
  },
  async deleteChecklist({ rootState, commit, dispatch }, params) {
    params.projectId = rootState.flowRoute.projectId;
    const { status, data: checklist } = await deleteChecklist(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    const { taskId } = params;
    commit("UPDATE_CHECKLIST", { taskId, checklist });
  }
};

export default { mutations, actions };
