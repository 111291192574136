import http from "@/commons/api/http";

const prefix = "api/resource";

// 모든 리소스 조회
const findCompanyResources = params => http.get(prefix, { params });

// 리소스 조회 (타입)
const findCompanyResourcesByType = params =>
  http.get(`${prefix}/type`, { params });

// 자원 유형 조회
const findResourceTypes = () => http.get(prefix + "/types");

// 단일 리소스 조회
const findResource = id => http.get(`${prefix}/${id}`);

// 단일 예약 조회
const findBooking = id => http.get(`${prefix}/book/${id}`);

// 예약 리스트 조회(리소스 아이디, 날짜)
const findBookingList = params => http.get(`${prefix}/book/list`, { params });

// 자원 예약
const saveBooking = body => http.post(prefix + "/book", body);

// 자원 예약 수정
const updateBooking = params => http.patch(prefix + "/book", params);

// 예약 취소
const cancelBooking = id => http.delete(`${prefix}/book/${id}`);

// 이미지 보기
const viewImage = id =>
  http.get(`${prefix}/img/${id}`, {
    responseType: "blob"
  });

export {
  findResourceTypes,
  findCompanyResources,
  findCompanyResourcesByType,
  findResource,
  findBooking,
  saveBooking,
  updateBooking,
  cancelBooking,
  viewImage,
  findBookingList
};
