<template>
  <div class="d-flex mr-1">
    <v-btn
      v-if="isAdmin"
      text
      outlined
      class="mr-1"
      color="primary"
      :disabled="loading"
      @click="manageMember"
    >
      <v-icon size="16" class="mr-1">mdi-account-cog</v-icon>
      멤버관리
    </v-btn>

    <v-btn
      v-if="isMaster"
      text
      outlined
      :disabled="loading"
      color="grey darken-1"
      @click="modifyProject"
    >
      <v-icon size="16" class="mr-1">mdi-cog</v-icon>
      <span>설정</span>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.v-btn {
  height: 30px !important;
}
</style>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("flowTasks", ["loading"]),
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("flowProjects", ["selectedProject"]),
    isMaster() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject || {};

      return members?.find(m => m.authority === "MASTER")?.userId == id;
    },
    isAdmin() {
      const { id } = this.getUserInfo;
      const { members } = this.selectedProject;
      const member = members?.find(m => m.userId === id);

      return (
        member &&
        member.joinStatus === "ACCEPTED" &&
        (member.authority === "ADMIN" || member.authority === "MASTER")
      );
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    ...mapMutations("flowProjects", ["SET_MANAGED_PROJECT_ID"]),
    modifyProject() {
      const headline = "프로젝트 수정";
      const type = "addProject";
      const params = { project: this.selectedProject };

      this.SHOW_DIALOG({ headline, type, params });
    },
    manageMember() {
      this.SET_MANAGED_PROJECT_ID(this.selectedProject.id);
      this.SHOW_DIALOG({
        headline: "프로젝트 멤버 관리",
        type: "manageMember"
      });
    }
  }
};
</script>
