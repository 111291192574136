import router from "@/commons/router";
import i18n from "@/_locales";
import { virtualBoardConverter } from "@/board/constant/boardType";

const state = {
  // 리스트 타입 post, notice
  list_type: "post",
  board_list: {
    id: 0,
    actionObj: JSON.stringify({ page: 1 })
  },
  post_view_id: 0,
  treeActive: true,
  board_config: {
    tabType: ""
  }
};
const getters = {
  getListType: ({ list_type }) => list_type,
  getRouteListInfo: ({ board_list }) => ({
    ...board_list,
    actionObj: JSON.parse(board_list.actionObj)
  }),
  getRoutePostViewId: ({ post_view_id }) => post_view_id,
  getTreeActive: ({ treeActive }) => treeActive,
  getConfigTabType: ({ board_config }) => board_config.tabType
};
const mutations = {
  SET_ROUTE_PARAMS: (state, routeParams) => (state.board_list = routeParams),
  SET_ROUTE_VIEW_ID: (state, postId) => (state.post_view_id = postId),
  INIT_ROUTE_STATE: state => {
    state.list_type = "post";
    state.board_list = {
      id: 0,
      actionObj: JSON.stringify({ page: 1 })
    };
    state.post_view_id = 0;
    state.treeActive = true;
    state.board_config = {
      show: false,
      tabType: ""
    };
  },
  SET_LIST_TYPE: (state, type) => {
    state.list_type = type;
  },
  SET_TREE_ACTIVE: (state, isActive) => {
    state.treeActive = isActive;
  },
  SET_BOARD_CONFIG_TAB_TYPE: (state, tabType) => {
    state.board_config.tabType = tabType;
  }
};
const actions = {
  async setParams({ rootGetters, commit, dispatch }, params) {
    // 다이얼로그 on 일 경우 닫기
    if (rootGetters["boardDialog/showDialog"]) {
      dispatch("boardDialog/close", {}, { root: true });
    }

    // 최초 게시판 설정 로드
    if (!rootGetters["boardConfig/getConfigInit"])
      await dispatch("boardConfig/loadBoardConfig", null, { root: true });

    // 게시판 정보
    if (
      rootGetters["board/getBoards"].length == 0 ||
      params.routeName == "board"
    ) {
      await dispatch("initData");
    }

    // 환경설정 페이지일 경우
    if (params.routeName === "board_config") {
      commit("SET_TREE_ACTIVE", false);
      commit("SET_BOARD_CONFIG_TAB_TYPE", params.tabType);

      return;
    }

    // 검색 페이지일 경우
    if (params.routeName === "board_search") {
      commit("SET_LIST_TYPE", "search");
      commit("SET_TREE_ACTIVE", false);

      // 검색 쿼리 상태저장
      const queryObj = JSON.parse(params.query);

      // 검색어 유효성 검증
      const keyword = queryObj?.keyword?.trim() ?? "";
      const tags = queryObj?.tags ?? [];
      if (tags.length < 1 && (keyword.length < 1 || keyword.length > 50)) {
        commit("boardSearch/INIT_SEARCH", "", { root: true });
        router.push({
          name: "board"
        });
      }

      const searchType =
        params.type === "all" ? "ALL" : queryObj.searchType ?? 0;
      const page = params.type === "all" ? 0 : queryObj.page ?? 0;
      const pageSize = params.type === "all" ? 5 : queryObj.pageSize ?? 10;

      const searchParam = {
        type: params.type,
        query: { ...queryObj, page, pageSize, searchType }
      };

      commit("boardSearch/SET_SEARCH_PARAM", searchParam, { root: true });

      // 게시글 검색
      if (params.type === "post" || params.type === "all") {
        dispatch("boardSearch/searchPostList", searchParam.query, {
          root: true
        });
      }
      // 댓글 검색
      if (params.type === "reply" || params.type === "all") {
        dispatch("boardSearch/searchReplyList", searchParam.query, {
          root: true
        });
      }
      // 파일 검색
      if (params.type === "file" || params.type === "all") {
        dispatch("boardSearch/searchFileList", searchParam.query, {
          root: true
        });
      }

      return;
    }

    // 리스트 or 보기 페이지일때 파라미터 세팅
    let parameter;
    let parsedAction;
    let viewType, page;
    await commit("SET_TREE_ACTIVE", true);
    switch (params.routeName) {
      case "board":
        page = 1;
        parameter = {
          id: virtualBoardConverter("all"),
          actionObj: JSON.stringify({ page })
        };
        break;
      case "board_list":
        viewType = rootGetters["boardConfig/getViewType"];
        page = 1;

        parameter = {
          id: virtualBoardConverter(params.boardId),
          actionObj: JSON.stringify({
            viewType,
            page
          })
        };
        break;
      case "board_list_action":
        parsedAction = JSON.parse(params?.actionObj);
        viewType =
          parsedAction?.viewType ?? rootGetters["boardConfig/getViewType"];
        page = parsedAction?.page ?? 1;

        // viewType 적용
        commit("boardConfig/SET_VIEW_TYPE", viewType, { root: true });

        parameter = {
          id: virtualBoardConverter(params.boardId),
          actionObj: JSON.stringify({
            viewType,
            page
          })
        };
        break;
      case "board_post_view":
        parsedAction = JSON.parse(params.actionObj);
        viewType =
          parsedAction?.viewType ?? rootGetters["boardConfig/getViewType"];
        page = parsedAction?.page ?? 1;

        parameter = {
          id: virtualBoardConverter(parsedAction.boardId),
          actionObj: JSON.stringify({
            viewType,
            page
          })
        };
        break;
      default:
        break;
    }

    // 게시판의 존재 여부 검사, 없을 경우 모든 게시물로 라우팅
    // 문자 or 음수 일 경우
    if (
      isNaN(parameter.id) ||
      !rootGetters["board/getBoardById"](parameter.id)
    ) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("board.8"), type: "VALIDATION" },
        { root: true }
      );
      return router.push({ name: "board" });
    }

    // 파라미터 저장
    commit("SET_ROUTE_PARAMS", parameter);
    commit(
      "SET_ROUTE_VIEW_ID",
      params.routeName == "board_post_view" ? params.postId : 0
    );

    if (params.routeName == "board_post_view") {
      // 게시글 상세 보기
      await dispatch("boardPost/loadPostView", params.postId, {
        root: true
      });
    } else {
      // 게시글 리스트 조회
      commit("SET_LIST_TYPE", "post");
      const board = rootGetters["board/getBoardById"](params.boardId);
      if (board?.boardType === "TRASH") {
        // 휴지통 조회일 경우
        dispatch("serviceConfig/loadConfig", "BOARD", { root: true });
      } else if (params.boardId === "notice") {
        // 공지사항 목록 조회일 경우
        commit("SET_LIST_TYPE", "notice");
        // 공지사항 목록 조회
        dispatch(
          "boardPost/loadNoticeList",
          {
            page: 1
          },
          { root: true }
        );
      } else {
        // 일반 게시글 목록 조회일 경우
        dispatch(
          "boardPost/loadPostList",
          {
            boardId: parameter.id,
            page
          },
          { root: true }
        );

        // 첫 페이지 or 공지사항 목록이 초기화 되지 않은 경우 공지사항 패널 조회
        const { initialized } = rootGetters["boardPost/getNoticeList"];
        if (page === 1 || !initialized) {
          dispatch("boardPost/loadNoticePanel", null, { root: true });
        }
      }
      return;
    }
  },
  setParamBoardId({ state, commit }, boardId) {
    const parameter = {
      id: boardId,
      actionObj: state.board_list.actionObj
    };
    commit("SET_ROUTE_PARAMS", parameter);
  },
  async initData({ dispatch }) {
    await dispatch("board/loadBoards", null, { root: true });
  },
  routeConfigTab(getters, { tabId }) {
    router.push({
      name: "board_config",
      params: {
        tabType: tabId || "manage"
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
