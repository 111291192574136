<template>
  <v-card class="cr-detail-main">
    <v-col cols="12">
      <v-btn-toggle
        v-model="selectedIdx"
        mandatory
        active-class="cr-btn-active"
        class="d-flex flex-row"
        style="border-radius:0px"
      >
        <v-btn
          v-for="({ name }, idx) in btnList"
          :key="idx"
          class="flex-grow-1"
        >
          {{ name }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12">
      <PostSearchDetail v-if="selectedType === 'post'" v-on="$listeners" />
      <ReplySearchDetail v-if="selectedType === 'reply'" v-on="$listeners" />
      <FileSearchDetail v-if="selectedType === 'file'" v-on="$listeners" />
    </v-col>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-detail-main {
  .cr-btn-active {
    color: #fff !important;
    background-color: var(--v-primary-base);
    border-style: none !important;
  }
}
</style>

<script>
import FileSearchDetail from "./detail/FileSearchDetail";
import PostSearchDetail from "./detail/PostSearchDetail";
import ReplySearchDetail from "./detail/ReplySearchDetail";
export default {
  components: { FileSearchDetail, PostSearchDetail, ReplySearchDetail },
  mounted() {},
  data() {
    return {
      selectedIdx: 0,
      btnList: [
        { type: "post", name: "게시글" },
        { type: "reply", name: "댓글" },
        { type: "file", name: "첨부파일" }
      ]
    };
  },
  watch: {},
  computed: {
    selectedType() {
      return this.btnList[this.selectedIdx].type;
    }
  },
  methods: {},
  destroyed() {}
};
</script>
