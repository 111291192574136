<template>
  <v-btn v-if="show" icon small @click.stop="click">
    <v-icon :size="size">{{ icon }}</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn {
  min-width: 28px !important;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { rootFolderType, privateFolderType } from "@/mail/constant/folderType";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 받은메일함, 내게쓴메일함, 내메일함, 검색메일함, 태그
      showDotsFolderType: {
        ...privateFolderType,
        SEARCH: "SEARCH",
        INBOX: "INBOX",
        TOME: "TOME"
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["restrictedFunctions"]),
    ...mapGetters("folder", [
      "getTagRoot",
      "getSearchRoot",
      "isSharedRoot",
      "isShareRoot"
    ]),
    show() {
      const { isEdit, folderType } = this.item;

      // 전체 폴더함 설정 버튼
      if (folderType === "ALL") return true;
      // 휴지통 비우기 버튼
      if (folderType === "DELETED") return true;
      // 내메일함 / 검색메일함 / 태그 추가 버튼
      if (rootFolderType[folderType]) return true;
      // 기본 메뉴 버튼
      return !isEdit && this.hover && this.showDotsFolderType[folderType];
    },
    icon() {
      const { folderType } = this.item;

      // 전체 폴더함 / 태그 설정 버튼
      if (["ALL", "TAG_ROOT"].includes(folderType)) return "mdi-cog";
      // 휴지통 비우기 버튼
      if (folderType === "DELETED") return "mdi-delete-outline";
      // 내메일함 / 검색메일함 / 태그 추가 버튼
      if (rootFolderType[folderType]) return "mdi-plus";
      // 기본 메뉴 버튼
      return "mdi-dots-vertical";
    },
    size() {
      const { folderType } = this.item;

      // 전체 폴더함 설정 버튼
      if (["ALL", "TAG_ROOT"].includes(folderType)) return "18";
      return "";
    }
  },
  methods: {
    ...mapMutations("folder", ["UPDATE_IS_EDIT"]),
    ...mapMutations("mailDialog", ["SET_DIALOG"]),
    ...mapMutations("tag", ["SHOW_TAG_DIALOG"]),
    ...mapActions("share", ["shareDialog"]),
    ...mapActions("mailRoute", ["routeMailConfig"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("mail", ["cleanTrash", "updateAllRead"]),
    ...mapActions("folder", ["getFolderById", "deleteFolder"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("project", ["showProjectListDialog"]),
    click(e) {
      this.closePositioningMenu();
      const { folderType } = this.item;

      // 전체 폴더함 설정 버튼
      if (folderType === "ALL") {
        this.routeMailConfig({ ...this.getRouteListInfo, tabId: "folder" });
        return;
      }

      // 휴지통 비우기 버튼
      if (folderType === "DELETED") {
        this.confirm({
          message: this.$t("mail.24"),
          callback: this.cleanTrash
        });
        return;
      }

      if (folderType === "PROJECT_ROOT") {
        this.showProjectListDialog();
        return;
      }

      // 내메일함 / 검색메일함 / 태그 추가 버튼
      if (rootFolderType[folderType]) {
        this.openAddDialog({ parentFolder: this.item });
        return;
      }

      // 기본 메뉴 버튼
      this.openMenu(e);
    },
    // (내메일함 / 검색메일함 / 태그) 추가, 수정 다이얼로그 열기
    // parentFolder => 추가할때 상위폴더, targetFolder => 수정시 해당폴더
    openAddDialog({ parentFolder, targetFolder }) {
      let type = "";
      let headline = this.$t("mail.20");
      const params = {};

      switch (parentFolder?.folderType) {
        case rootFolderType.PRIVATE_ROOT:
        case privateFolderType.PRIVATE:
        case privateFolderType.PRIVATE_SECURE:
        case privateFolderType.PRIVATE_PERMANENT: {
          type = "folder";
          params["parentFolder"] = parentFolder;
          break;
        }
        case rootFolderType.SEARCH_ROOT: {
          type = "searchFolder";
          headline = this.$t(targetFolder ? "mail.21" : "mail.22");
          params["parentFolder"] = parentFolder;
          params["targetFolder"] = targetFolder;
          break;
        }
        case rootFolderType.TAG_ROOT: {
          this.SHOW_TAG_DIALOG({
            myTags: "folder/getTags",
            addAction: "folder/addTagList",
            deleteAction: "folder/deleteTagList",
            editAction: "folder/updateTagColor"
          });
          return;
        }
      }

      this.SET_DIALOG({ headline, show: true, type, params });
    },
    openMenu(e) {
      const { folderType } = this.item;
      const isPrivate = !!privateFolderType[folderType];
      let label = this.$t("mail.13"); // 검색메일함 수정
      if (isPrivate) label = this.$t("mail.14"); // 이름 변경

      const hide1 = ["INBOX", "TOME", "DRAFTS"].includes(folderType);
      const hide2 = ["SEARCH", "DRAFTS"].includes(folderType);
      // 조직도 사용 제한에 따른 공유 기능 비활성화
      const hide3 =
        ["SEARCH", "DRAFTS", "TOME"].includes(folderType) ||
        this.restrictedFunctions.isOrgChartRestricted == 1;

      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList: [
          // 수정
          { label, hideItem: hide1, func: this.editBtnClick },
          // 색상 변경
          {
            label: this.$t("mail.448"),
            hideItem: !isPrivate,
            func: this.openColorPicker
          },
          // 하위 메일함 추가
          {
            label: this.$t("mail.16"),
            hideItem: !isPrivate,
            func: () => this.openAddDialog({ parentFolder: this.item })
          },
          // 삭제
          {
            label: this.$t("mail.18"),
            hideItem: hide1,
            func: this.removeFolder
          },
          // 모두 읽음 표시
          {
            label: this.$t("mail.19"),
            hideItem: hide2,
            func: () => this.updateAllRead(this.item.id)
          },
          // 공유
          {
            label: this.$t("mail.공유"),
            hideItem: hide3,
            func: this.openShareDialog
          }
        ]
      });
    },
    // 내메일함 이름변경 input 활성화 / 검색메일함 수정 다이얼로그
    async editBtnClick() {
      this.closePositioningMenu();
      const { id, folderType } = this.item;
      if (folderType == "SEARCH") {
        const targetFolder = await this.getFolderById(id);
        this.openAddDialog({ parentFolder: this.getSearchRoot, targetFolder });
        return;
      }

      this.UPDATE_IS_EDIT(id);
    },
    // 내메일함 색상변경 다이얼로그
    openColorPicker() {
      this.SET_DIALOG({
        show: true,
        headline: this.$t("mail.449"),
        type: "colorPicker",
        params: { targetFolder: this.item }
      });
    },
    // 메일함 삭제
    removeFolder() {
      const { nameFolder, id: folderId, parentId, folderType } = this.item;
      this.confirm({
        message: this.$t("mail.25", { value: nameFolder }),
        callback: async () => {
          let id = parentId;
          if (!id && folderType == "SEARCH") {
            id = this.getSearchRoot?.id;
          }
          if (!id && privateFolderType[folderType]) {
            id = this.getPrivateRootId;
          }

          await this.deleteFolder({ folderId, parentId: id });
        }
      });
    },
    // 공유 다이얼로그 열기
    openShareDialog() {
      const { id, nameFolder: name } = this.item;
      this.shareDialog({
        moduleType: "MAIL",
        shareItem: { id, name },
        sendMessage: false,
        addCallback: () => {
          if (this.isShareRoot && !this.isSharedRoot) {
            this.$router.push(this.$router.currentRoute);
          }
        }
      });
    }
  }
};
</script>
