<template>
  <v-dialog
    :value="value"
    @input="handleDialog"
    width="400px"
    max-width="400px"
  >
    <v-card rounded="lg">
      <v-card-title class="font-weight-bold">
        {{ $t("calendar.32") }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="3">
              <v-menu right offset-y rounded="lg">
                <template #activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    block
                    depressed
                    color="blue-grey lighten-5"
                    style="height: 40px"
                  >
                    <v-icon :color="selectedColor">
                      mdi-circle
                    </v-icon>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list width="198px" class="pa-2">
                  <v-chip-group
                    v-model="selectedColor"
                    class="calendar-swatches"
                    mandatory
                    column
                  >
                    <v-chip
                      v-for="color in swatches"
                      :key="color"
                      :color="color"
                      :value="color"
                      active-class="elevation-1"
                      pill
                    ></v-chip>
                  </v-chip-group>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="9">
              <v-text-field
                ref="title"
                v-model="title"
                autofocus
                dense
                filled
                :rules="[titleRules.required, titleRules.counter]"
                :placeholder="$t('calendar.캘린더_이름')"
                maxLength="80"
                counter="80"
                background-color="blue-grey lighten-5"
                append-icon="mdi-calendar-edit"
                @keydown.enter="addCalendar"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input
                ref="icsFileInput"
                v-model="icsFile"
                accept="text/calendar"
                prepend-icon=""
                append-icon="mdi-calendar-import"
                dense
                filled
                clearable
                small-chips
                full-width
                :show-size="1000"
                :rules="[
                  file => !file || file.size < 10000000 || $t('calendar.27')
                ]"
                :label="$t('calendar.25')"
                :hint="$t('calendar.26')"
                persistent-hint
              >
                <template #selection="{ text }">
                  <v-chip color="accent" label>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex">
              <v-spacer></v-spacer>
              <v-btn class="mr-1" text outlined @click="handleDialog(false)">
                {{ $t("common.취소") }}
              </v-btn>
              <v-btn outlined color="accent" @click="addCalendar">
                {{ $t("common.저장") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/_locales";
import {
  CALENDAR_SWATCHES,
  CALENDAR_TYPE,
  DEFAULT_CALENDAR_COLOR
} from "@/calendar/constant/calConstants";
import { mapActions, mapMutations } from "vuex";
import ICAL from "ical.js";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: "",
      titleRules: {
        required: value => !!value || i18n.t("calendar.150"),
        counter: value => value.length <= 80 || i18n.t("calendar.24")
      },
      swatches: [...CALENDAR_SWATCHES],
      selectedColor: DEFAULT_CALENDAR_COLOR,
      icsFile: null
    };
  },
  watch: {
    async icsFile(file) {
      if (!file) return;

      if (file.type !== "text/calendar") {
        alert(i18n.t("calendar.31"));
        this.$refs.icsFileInput.clearableCallback();
        return;
      }

      const icsText = await this.convertIcsFileToString(file);
      const vCalendar = ICAL.Component.fromString(icsText);
      const calendarName = vCalendar.getFirstPropertyValue("x-wr-calname");
      if (calendarName) {
        this.title = calendarName.substring(0, 80);
      }
    }
  },
  methods: {
    ...mapMutations("cal", ["SHOW_LOADING_OVERLAY", "HIDE_LOADING_OVERLAY"]),
    ...mapActions("cal", ["addCalendarToServer", "updateCalendarToServer"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    handleDialog(value) {
      if (!value) {
        this.$emit("input", false);
      }
    },
    async addCalendar() {
      if (!this.$refs.title.validate()) return;

      this.SHOW_LOADING_OVERLAY();

      const param = {
        title: this.title,
        color: this.selectedColor,
        calendarType: CALENDAR_TYPE.PRIVATE,
        iCal: await this.convertIcsFileToString(this.icsFile)
      };
      const result = await this.addCalendarToServer(param);
      this.openSnackbar({
        message: result ? i18n.t("calendar.33") : i18n.t("calendar.34"),
        type: result ? "SUCCESS" : "ERROR"
      });

      this.handleDialog(false);
      this.HIDE_LOADING_OVERLAY();
    },
    convertIcsFileToString(icsFile) {
      if (!icsFile) return null;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(icsFile, "utf-8");
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "vuetify/src/styles/styles.sass";

.v-text-field ::v-deep {
  .v-input__control .v-input__slot {
    &::before {
      border: none;
    }
  }
}

.v-text-field--filled {
  border-radius: 4px;
}

.v-file-input ::v-deep .v-input__slot {
  cursor: pointer;
}

.calendar-swatches {
  .v-chip-group ::v-deep .v-slide-group__content {
    padding: 0;
  }

  .v-chip {
    width: 20px;
    height: 20px;
    margin: 3px;
    padding: 0;
    border-radius: 10px;

    &:hover {
      box-shadow: map-get($shadow-key-umbra, 2),
        map-get($shadow-key-penumbra, 2), map-get($shadow-key-ambient, 2);
    }
  }
}
</style>
