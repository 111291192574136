import i18n from "@/_locales";
import Vue from "vue";
import {
  addContactGroup,
  deleteContactGroup,
  getContactGroupListByParentId,
  getDefaultContactGroupList,
  getPrivateContactGroupList,
  getPrivateContactGroupTree,
  getSuperParentContactGroup,
  updateContactGroupName
} from "../../api/group.api";
import {
  allContactGroupType,
  rootContactGroupType
} from "@/contact/constant/contactGroupType";

const state = {
  openedGroup: [],
  contactGroupList: [],
  privateContactGroupTree: []
};

const getters = {
  getOpenedGroup: ({ openedGroup }) => openedGroup,
  getContactGroupList: ({ contactGroupList }) => contactGroupList,
  getAllRoot: ({ contactGroupList }) =>
    contactGroupList.filter(
      ({ contactGroupType }) => contactGroupType === allContactGroupType.ALL
    ),
  getPrivateRoot: ({ contactGroupList }) =>
    contactGroupList.filter(
      ({ contactGroupType }) =>
        contactGroupType == rootContactGroupType.PRIVATE_ROOT
    ),
  getContactGroup: ({ contactGroupList }) => contactGroupId =>
    getContactGroup(contactGroupList, contactGroupId),
  getFullPath: ({ contactGroupList }) => contactGroupId =>
    getFullPath(contactGroupList, contactGroupId),
  getPrivateContactGroupTree: ({ privateContactGroupTree }) =>
    privateContactGroupTree,
  getTreeContactGroup: ({ privateContactGroupTree }) => contactGroupId =>
    getTreeContactGroup(privateContactGroupTree, contactGroupId)
};

const getTreeContactGroup = (contactGroupList, contactGroupId) => {
  for (let i = 0; i < contactGroupList.length; i += 1) {
    const contactGroup = contactGroupList[i];
    if (contactGroup.id == contactGroupId) return contactGroup;

    if (contactGroup.children) {
      const childrenResult = getTreeContactGroup(
        contactGroup.children,
        contactGroupId
      );
      if (childrenResult) return childrenResult;
    }
  }
};

const getContactGroup = (contactGroupList, contactGroupId) => {
  for (let i = 0; i < contactGroupList.length; i += 1) {
    const contactGroup = contactGroupList[i];
    if (contactGroup.id == contactGroupId) return contactGroup;

    if (contactGroup.childCount > 0 && contactGroup.children) {
      const childrenResult = getContactGroup(
        contactGroup.children,
        contactGroupId
      );
      if (childrenResult) return childrenResult;
    }
  }
};

const getFullPath = (contactGroupList, contactGroupId, result = []) => {
  const { id, parentId } = getContactGroup(contactGroupList, contactGroupId);
  result.push({ id });
  if (parentId == 0) return result;

  return getFullPath(contactGroupList, parentId, result);
};

const deleteChildren = contactGroupList => {
  for (let i = 0; i < contactGroupList.length; i += 1) {
    const contactGroup = contactGroupList[i];
    const { childCount, children } = contactGroup;

    if (childCount == 0) delete contactGroup.children;
    if (childCount > 0 && !children) contactGroup.children = [];
    if (children) deleteChildren(children);
  }
};

const deleteNullChildren = contactGroupList => {
  for (let i = 0; i < contactGroupList.length; i += 1) {
    const contactGroup = contactGroupList[i];
    const { childCount, children } = contactGroup;

    if (childCount == 0) delete contactGroup.children;
    if (children) deleteNullChildren(children);
  }
};

const mutations = {
  SET_OPENED_GROUP: (state, openedGroup) => (state.openedGroup = openedGroup),
  SET_DEFAULT_CARD_GROUP_LIST: (state, contactGroupList) =>
    (state.contactGroupList = contactGroupList),
  SET_CHILDREN: (state, { parentId, children, count = 0 }) => {
    const contactGroup = getContactGroup(state.contactGroupList, parentId);
    contactGroup.childCount += count;

    let child = children;
    // 기존 하위 주소록 그룹 있을때
    if (contactGroup.children) {
      children.forEach(({ id: prevId }) => {
        const index = contactGroup.children.findIndex(({ id }) => id == prevId);
        if (index !== -1) {
          Vue.delete(contactGroup.children, index);
        }
      });

      child = [...contactGroup.children, ...children];
    }
    Vue.set(contactGroup, "children", child);
  },
  UPDATE_IS_EDIT: (state, contactGroupId) => {
    const contactGroup = getContactGroup(
      state.contactGroupList,
      contactGroupId
    );
    contactGroup.isEdit = !contactGroup.isEdit;
  },
  UPDATE_CARD_GROUP_NAME: (state, { contactGroupId, groupName }) => {
    const contactGroup = getContactGroup(
      state.contactGroupList,
      contactGroupId
    );
    contactGroup.groupName = groupName;
  },
  // 그룹 삭제
  DELETE_CARD_GROUP: (state, { groupId, parentId }) => {
    const group = getContactGroup(state.contactGroupList, parentId);
    if (!group) return;

    const index = group.children?.findIndex(({ id }) => id === groupId);
    if (group.children?.length === 1) Vue.delete(group, "children");
    else if (index > -1) Vue.delete(group.children, index);

    Vue.set(group, "childCount", group.children?.length || 0);
  },
  SET_PRIVATE_CONTACT_GROUP_TREE: (state, privateContactGroupTree) =>
    (state.privateContactGroupTree = privateContactGroupTree)
};

const actions = {
  /**
   * 기본 주소록 그룹 리스트 조회
   *
   * @param {*} param0
   * @param {*} contactGroupId
   */
  async getDefaultContactGroupList({ commit }, contactGroupId) {
    const { data = [] } = await getDefaultContactGroupList();
    const icons = {
      ALL: "mdi-account-multiple",
      IMPORTANT: "mdi-star",
      RECENT: "mdi-clock-time-four-outline",
      PRIVATE_ROOT: "mdi-card-account-details"
    };
    const contactGroupList = data.map(
      ({
        id,
        groupName,
        contactGroupType,
        parentId,
        totalCount,
        newCount,
        childCount,
        children = []
      }) => {
        // 다국어 처리를 위한 기본 폴더 맵핑
        if (
          [
            "ALL", // 전체
            "IMPORTANT", // 중요한 연락처
            "RECENT", // 최근 등록한 연락처
            "PRIVATE_ROOT" // 내 주소록
          ].indexOf(contactGroupType) > -1
        ) {
          groupName = i18n.t(`contact.group_${contactGroupType}`);
        }

        let childrenObj = {};
        if (childCount > 0) {
          const mappedChildren = children.map(
            ({
              id,
              groupName,
              contactGroupType,
              parentId,
              totalCount,
              newCount,
              childCount
            }) => {
              const children = childCount > 0 ? { children: [] } : {};
              return {
                id,
                groupName,
                contactGroupType,
                parentId,
                totalCount,
                newCount,
                isEdit: false,
                childCount,
                icon: icons[contactGroupType],
                ...children
              };
            }
          );
          childrenObj = { children: mappedChildren };
        }

        return {
          id,
          groupName,
          contactGroupType,
          parentId,
          totalCount,
          newCount,
          childCount,
          isEdit: false,
          icon: icons[contactGroupType],
          ...childrenObj
        };
      }
    );

    let routeContactGroup = null;
    if (contactGroupId > 0) {
      routeContactGroup = getContactGroup(contactGroupList, contactGroupId);
      if (!routeContactGroup) {
        const { data } = await getSuperParentContactGroup(contactGroupId);

        // children 제거
        deleteChildren(data.children);

        // 기본폴더 리스트에 data추가
        for (let i = 0; i < contactGroupList.length; i += 1) {
          const contactGroup = contactGroupList[i];
          if (contactGroup.contactGroupType === allContactGroupType.ALL) {
            for (let j = 0; j < contactGroup.children.length; j += 1) {
              if (contactGroup.children[j].id === data.id) {
                contactGroup.children[j] = data;
                break;
              }
            }
            break;
          }
        }
      }
    }

    commit("SET_DEFAULT_CARD_GROUP_LIST", contactGroupList);
  },
  /**
   * 자식 주소록 그룹 리스트 조회
   *
   * @param {*} param0
   * @param {*} param1
   */
  async getContactGroupListByParentId({ commit }, { parentId }) {
    try {
      const { data = [] } = await getContactGroupListByParentId({ parentId });
      const children = data.map(
        ({
          id,
          groupName,
          contactGroupType,
          parentId,
          totalCount,
          newCount,
          childCount
        }) => {
          const childrenObj = childCount > 0 ? { children: [] } : {};
          return {
            id,
            groupName,
            contactGroupType,
            parentId,
            totalCount,
            newCount,
            childCount,
            isEdit: false,
            ...childrenObj
          };
        }
      );

      commit("SET_CHILDREN", { parentId, children });
    } catch (e) {
      // console.log("getContactGroupListByParentId e = ", e);
    }
  },
  /**
   * 개인 주소록 그룹 리스트 조회
   *
   * @returns
   */
  async getPrivateContactGroupList() {
    try {
      const { data = [] } = await getPrivateContactGroupList();
      return data;
    } catch (e) {
      // console.log("getPrivateContactGroupList e = ", e);
    }
  },
  /**
   * 주소록 그룹 추가
   *
   * @param {*} param0
   * @param {*} param1
   */
  async addContactGroup(
    { commit, getters, dispatch },
    { groupName, contactGroupType, parentId }
  ) {
    try {
      const { data, status } = await addContactGroup({
        groupName,
        contactGroupType,
        parentId: parentId > 0 ? parentId : 0
      });

      let id = parentId;
      if (parentId == 0) {
        const [{ id: rootId }] = getters.getPrivateRoot;
        id = rootId;
      }

      const messages = {
        ERROR: i18n.t("contact.65"),
        SUCCESS: i18n.t("contact.66")
      };
      const type = status === 201 ? "SUCCESS" : "ERROR";
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );

      commit("SET_CHILDREN", { parentId: id, children: [data], count: 1 });
      await dispatch("getPrivateContactGroupTree");
      return data;
    } catch (e) {
      // console.log("addContactGroup e = ", e);
    }
  },
  /**
   * 주소록 그룹 에디트 모드 변경
   *
   * @param {*} param0
   * @param {*} contactGroupId
   */
  updateIsEdit({ commit }, contactGroupId) {
    commit("UPDATE_IS_EDIT", contactGroupId);
  },
  /**
   * 주소록 그룹명 수정
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async updateContactGroupName(
    { commit, dispatch },
    { groupName, contactGroupId }
  ) {
    try {
      const { data, status } = await updateContactGroupName({
        groupName,
        contactGroupId
      });

      await commit("UPDATE_CARD_GROUP_NAME", {
        contactGroupId: data.id,
        groupName: data.groupName
      });

      const messages = {
        ERROR: i18n.t("contact.67"),
        SUCCESS: i18n.t("contact.68")
      };
      const type = status === 200 ? "SUCCESS" : "ERROR";
      dispatch(
        "snackbar/openSnackbar",
        { message: messages[type], type },
        { root: true }
      );

      return true;
    } catch (e) {
      // console.log("updateContactGroupName e = ", e);
    }
  },
  /**
   * 주소록 그룹 삭제
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async deleteContactGroup(
    { commit, getters, dispatch },
    { groupId, parentId /*openItems*/ }
  ) {
    const { status } = await deleteContactGroup(groupId);

    if (status !== 200) return false;

    let id = parentId;
    if (parentId === 0) {
      const [{ id: rootId }] = getters.getAllRoot;
      id = rootId;
    }

    setTimeout(() => {
      commit("DELETE_CARD_GROUP", {
        groupId,
        parentId: id
      });
    }, 0);

    let openItems = [...getters.getOpenedGroup];
    const index = openItems.findIndex(({ id: openId }) => openId === id);
    if (openItems[index].children.length === 1) openItems.splice(index, 1);
    commit("SET_OPENED_GROUP", openItems);

    const messages = {
      ERROR: i18n.t("contact.69"),
      SUCCESS: i18n.t("contact.70")
    };
    const type = status === 200 ? "SUCCESS" : "ERROR";
    dispatch(
      "snackbar/openSnackbar",
      { message: messages[type], type },
      { root: true }
    );

    return true;
  },
  /**
   * 개인 주소록 그룹 트리 조회
   *
   * @returns
   */
  async getPrivateContactGroupTree({ commit }) {
    try {
      const { status, data = [] } = await getPrivateContactGroupTree();
      if (status === 200) {
        deleteNullChildren(data);
        commit("SET_PRIVATE_CONTACT_GROUP_TREE", data);
        return true;
      }
    } catch (e) {
      // console.log("getPrivateContactGroupTree e = ", e);
    }
  },

  /**
   * 메일 검색
   * @param dispatch
   * @param query
   * @returns
   */
  async searchMail({ dispatch }, { query }) {
    const name = "mail_list_action";
    const search = { query: query, type: "all.from", useSearchHistory: false };
    const actionObj = { search: { ...search } };

    // 메일 검색 페이지로 이동
    dispatch(
      "mailRoute/routeMailList",
      { name, page: 1, folderId: -1, actionObj },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
