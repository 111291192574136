import http from "@/commons/api/http";

const prefix = "api/calendar/leave";

// 연차리스트 조회
const getLeave = params => http.get(prefix, { params });

// 특별휴가 등록
const saveSpecialLeave = params =>
  http.post(prefix + "/saveSpecialLeave", params);

// 특별휴가 수정
const updateSpecialLeave = params =>
  http.patch(`${prefix}/updateSpecialLeave`, params);

// 특별휴가 삭제
const deleteSpecialLeave = specialLeaveId =>
  http.delete(`${prefix}/deleteSpecialLeave/${specialLeaveId}`);

// 캘린더리스트 조회
const exportUserLeaveExcel = () =>
  http.get(prefix + "/exportExcel", {
    responseType: "arraybuffer"
  });

const getApprover = () => http.get(`${prefix}/findApprover`);

// 보정 연차 수정
const updateLeaveCorrection = params =>
  http.post(prefix + "/updateLeaveCorrection", params);

export {
  getLeave,
  saveSpecialLeave,
  deleteSpecialLeave,
  updateSpecialLeave,
  exportUserLeaveExcel,
  getApprover,
  updateLeaveCorrection
};
