const state = {
  // config 초기화 여부
  init: false,
  pageSize: 10,
  blockSize: 5
};
const getters = {
  getConfigInit: ({ init }) => init,
  getPageSize: ({ pageSize }) => pageSize,
  getBlockSize: ({ blockSize }) => blockSize
};
const mutations = {
  SET_INIT: (state, init) => (state.init = init)
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
