export default [
  {
    path: "/contact/config/:tabType",
    name: "contact_config",
    component: () =>
      import(
        /* webpackChunkName: "contact_config" */ "../views/ContactMain.vue"
      )
  },
  {
    path: "/contact/print/:contactGroupId/:page/:actionObj",
    name: "contact_print",
    component: () =>
      import(
        /* webpackChunkName: "contact_print" */ "../views/components/list/PrintCardList.vue"
      )
  },
  {
    path: "/contact/list",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact_main" */ "../views/ContactMain.vue"),
    children: [
      {
        path: ":contactGroupId/:page",
        name: "contact_list"
      },
      {
        path: ":contactGroupId/:page/:actionObj",
        name: "contact_list_action"
      }
    ]
  }
];
