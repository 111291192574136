import http from "@/commons/api/http";

const prefix = "api/config/contact";

/**
 * 주소록 설정 조회
 *
 * @returns
 */
const getConfig = () => http.get(prefix);

/**
 * 주소록 설정 수정
 *
 * @param {*} param0
 * @returns
 */
const updateConfig = ({ firstPage, pageSize }) => {
  const formData = new FormData();
  formData.append("firstPage", firstPage);
  formData.append("pageSize", pageSize);

  return http.put(prefix, formData);
};

/**
 * 주소록 첫 화면 수정
 *
 * @param {*} param0
 * @returns
 */
const updateFirstScreen = firstPage => {
  const formData = new FormData();
  formData.append("firstPage", firstPage);

  return http.put(`${prefix}/firstScreen`, formData);
};

/**
 * 주소록 목록 개수 수정
 *
 * @param {*} pageSize
 * @returns
 */
const updatePageSize = pageSize => http.put(`${prefix}/${pageSize}/pageSize`);

/**
 * 주소록 숨김 컬럼 수정
 *
 * @param {*} param0
 * @returns
 */
const updateConfigHideColumns = ({ columnName, hide }) => {
  const formData = new FormData();
  formData.append("columnName", columnName);
  formData.append("hide", hide);

  return http.put(`${prefix}/hideColumns`, formData);
};

export {
  getConfig,
  updateConfig,
  updateFirstScreen,
  updatePageSize,
  updateConfigHideColumns
};
