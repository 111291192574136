/**
 * data table drag&drop directive
 */
const dataTableDragDrop = {
  componentUpdated: async function(el, binding, vnode) {
    const {
      dropElementClass,
      backgroundColor
      // beforeDragstart: bindBeforeDragstart,
      // mouseup: bindMouseup
    } = binding.value;
    const { getters } = vnode.context.$store;

    // const mouseup = async function(e) {
    //   console.log("mouseup");
    //   if (await getters["dragDrop/dragMode"]) {
    //     const dropZone = e.target.closest(`.${dropElementClass}`);
    //     if (dropZone && dropZone.classList.contains(dropElementClass)) {
    //       dropZone.style.background = null;
    //     }
    //     setTimeout(() => {
    //       bindBeforeDragstart && bindBeforeDragstart(e);
    //       bindMouseup && bindMouseup(e);
    //     }, 0);
    //   }
    // };

    const mouseover = async function(e) {
      const dropZone = e.target.closest(`.${dropElementClass}`);
      if (
        dropZone &&
        dropZone.classList.contains(dropElementClass) &&
        (await getters["dragDrop/dragMode"])
      ) {
        dropZone.style.background = backgroundColor;
      }
    };

    const mouseleave = async function(e) {
      const dropZone = e.target.closest(`.${dropElementClass}`);
      if (
        dropZone &&
        dropZone.classList.contains(dropElementClass) &&
        (await getters["dragDrop/dragMode"])
      ) {
        dropZone.style.background = null;
      }
    };

    // node updqte마다 event 직접 binding
    setTimeout(() => {
      const nodeList = el.getElementsByClassName(dropElementClass);
      nodeList.forEach(function(node) {
        if (!node.bindEventMouseover) {
          node.removeEventListener("mouseover", mouseover);
          node.addEventListener("mouseover", mouseover);
          node.bindEventMouseover = true;
        }
        if (!node.bindEventMouseleave) {
          node.removeEventListener("mouseleave", mouseleave);
          node.addEventListener("mouseleave", mouseleave);
          node.bindEventMouseleave = true;
        }
        // if (!node.bindEventMouseup) {
        //   node.removeEventListener("mouseup", mouseup);
        //   node.addEventListener("mouseup", mouseup);
        //   node.bindEventMouseup = true;
        // }
      });
    });
  },
  inserted: async function(el, binding, vnode) {
    const {
      dragElementClass,
      dropElementClass,
      backgroundColor,
      helperLabel,
      beforeDragstart: bindBeforeDragstart,
      mouseup: bindMouseup,
      drop: bindDrop
    } = binding.value;
    const { dispatch, getters } = vnode.context.$store;
    await dispatch("dragDrop/setDragMode", false);

    el.draggable = true;
    el.ondragstart = async function(e) {
      await dispatch("dragDrop/setDragMode", true);
      bindBeforeDragstart && bindBeforeDragstart(e);

      // vnode.context -> vue 컴포넌트 자체
      const { selectedItems } = binding.value;
      const idList = selectedItems.map(({ id }) => id);
      await dispatch("dragDrop/setDragElementIdList", idList);

      const helper = document.getElementById("drag-helper");
      helper.innerText = helperLabel.replace("$n", selectedItems.length);

      if (helper.style.display != "block") {
        helper.style.display = "block";
        helper.style.zIndex = "999";
      }

      e.stopPropagation();
      e.preventDefault();
    };

    const genHelper = function() {
      const helper = document.createElement("span");

      helper.id = "drag-helper";
      helper.style.background = "#e4effa";
      helper.style.position = "absolute";
      helper.style.display = "none";
      helper.style.borderRadius = "5px";
      helper.style.paddingTop = "5px";
      helper.style.paddingRight = "10px";
      helper.style.paddingBottom = "5px";
      helper.style.paddingLeft = "10px";

      document.body.appendChild(helper);
    };

    const mousemoveHelper = async function(e) {
      if (await getters["dragDrop/dragMode"]) {
        const helper = document.getElementById("drag-helper");
        helper.style.left = e.clientX + "px";
        helper.style.top = e.clientY + "px";
      }
    };

    const mouseupHelper = async function() {
      document.body.removeEventListener("mousemove", mousemoveHelper);
      document.body.removeEventListener("mouseup", mouseupHelper);

      await dispatch("dragDrop/setDragMode", false);

      const helper = document.getElementById("drag-helper");
      helper && helper.parentNode.removeChild(helper);
    };

    const mousedown = async function(e) {
      const node = e.target.closest(`.${dragElementClass}`);
      await dispatch("dragDrop/setDragElement", node);

      if (!node) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      const index = Array.prototype.indexOf.call(
        node.parentNode.childNodes,
        node
      );
      await dispatch("dragDrop/setDragElementIndex", index);

      genHelper();
      document.body.addEventListener("mousemove", mousemoveHelper);
      document.body.addEventListener("mouseup", mouseupHelper);
    };

    const mouseup = async function(e) {
      if (await getters["dragDrop/dragMode"]) {
        const dropZone = e.target.closest(`.${dropElementClass}`);
        if (dropZone && dropZone.classList.contains(dropElementClass)) {
          dropZone.style.background = null;
        }
        setTimeout(() => {
          bindBeforeDragstart && bindBeforeDragstart(e);
          bindMouseup && bindMouseup(e);
        }, 0);
      }
    };

    const dragover = async function(e) {
      const dropZone = e.target.closest(`.${dropElementClass}`);
      if (dropZone && dropZone.classList.contains(dropElementClass)) {
        dropZone.style.background = backgroundColor;
      }
    };

    const dragleave = async function(e) {
      const dropZone = e.target.closest(`.${dropElementClass}`);
      if (dropZone && dropZone.classList.contains(dropElementClass)) {
        dropZone.style.background = null;
      }
    };

    const drop = async function(e) {
      e.stopPropagation();
      e.preventDefault();

      const dropZone = e.target.closest(`.${dropElementClass}`);
      if (dropZone && dropZone.classList.contains(dropElementClass)) {
        dropZone.style.background = null;
        bindDrop && bindDrop(e, dropZone);
      }
    };

    el.addEventListener("mousedown", mousedown);
    el.addEventListener("mouseup", mouseup);
    el.addEventListener("dragover", dragover);
    el.addEventListener("dragleave", dragleave);
    el.addEventListener("drop", drop);
  }
};

export default dataTableDragDrop;
