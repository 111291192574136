import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const requireLang = require.context("@/_locales", true, /\.json$/);
  const messages = {};

  for (const file of requireLang.keys()) {
    if (file === "./index.js") continue;

    const path = file.replace(/(\.\/|\.json$)/g, "").split("/");
    path.reduce((o, s, i) => {
      if (o[s]) return o[s];
      o[s] = i + 1 === path.length ? requireLang(file) : {};
      return o[s];
    }, messages);
  }
  return messages;
}

const messages = loadLocaleMessages();

const i18n = new VueI18n({
  locale: "ko", // 기본 locale
  fallbackLocale: "ko", // locale 설정 실패시 사용할 locale
  messages: messages,
  silentTranslationWarn: true // 메시지 코드가 없을때 나오는 console 경고 off
});

export default i18n;
