<template>
  <div class="cr-project-info">
    <AvatarMenu :avatar="avatar" disabled />
    <div class="cr-project-title">
      <h3 class="text-truncate" :title="title">
        {{ title }}
      </h3>
      <div class="caption text-truncate" :title="description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-info {
  position: relative;
  display: flex;
  flex-grow: 1;
  .cr-project-title {
    position: absolute;
    top: 0px;
    left: 60px;
    right: 12px;
    bottom: 0px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import AvatarMenu from "@/flow/views/components/commons/avatarMenu";

export default {
  components: { AvatarMenu },
  computed: {
    ...mapGetters("flowProjects", ["selectedProject"]),
    avatar() {
      return { ...(this.selectedProject || {}), size: 45 };
    },
    title() {
      return this.selectedProject?.title || "";
    },
    description() {
      return this.selectedProject?.description || "";
    }
  }
};
</script>
