import router from "@/commons/router";
import Vuetify from "@/commons/plugins/vuetify";
import { quickMenuEnum } from "@/mail/constant/folderType";
import {
  getMailConfig,
  updateFirstScreen,
  updatePageSize,
  updateAutoSave,
  updateSplitView,
  updateGroupView,
  updateUseTab,
  updateListConfig,
  updatePopupRead,
  updatePopupWrite,
  updateWriteConfig,
  updatePop3Config,
  updateSmartMailboxConfig
} from "../../api/config.api";
import i18n from "@/_locales";

const state = {
  // config 초기화 여부
  init: false,
  // 말머리 사용 여부
  useSubjectFirst: 0,
  // 말머리 목록
  subjectFirsts: [],
  // 제목 이모지 사용 여부
  useSubjectEmoji: 0,
  // 보내는 사람 목록
  senderNames: [],
  // 보내는 사람 기본 값
  defaultSenderName: "",
  // 서명 목록
  signs: [],
  // 나를 항상 참조에 포함
  includeMe: 0,
  // 나를 항상 참조에 포함 이메일주소
  includeMeEmail: "",
  // 새창으로 메일 쓰기
  popupWrite: 0,
  // 임시보관메일 재사용 여부
  draftReuse: 0,
  // 자동 임시저장 시간
  autoSave: 0,
  // 발송 전 미리보기
  previewBeforeSend: 0,
  // 초기 기본 폰트 스타일
  initFontStyle: "나눔고딕",
  //초기 기본 폰트 크기
  initFontSize: "14pt",
  // 메일 제목 포맷
  subjectFormat: "",
  // 메일 첫 화면
  firstScreenFolderId: -1,
  // 목록 개수
  pageSize: 20,
  // 좌우분할 사용
  splitView: 0,
  // 좌우분할시 첫번째 메일보기
  showFirstMail: 0,
  // 그룹보기 사용
  groupView: 1,
  // 탭사용
  useTab: 1,
  //새창으로 메일 읽기
  popupRead: 0,
  // 펼쳐보기 - 0: 내메일함, 1: 검색폴더, 2: 태그
  folderExpand: [],
  // 퀵메뉴
  quickMenu: [],
  // POP3 사용 여부 0:사용 안함, 1:사용함
  pop3Flag: 0,
  // POP3 적용 범위 0: 기존에 받은 메일을 포함하여 받기, 1: 지금부터 새로 받은 메일만 받기
  pop3FetchRange: 0,
  // POP3 읽음 표시 0: 상태 변경하지 않음, 1: 읽어간 메일 읽음 표시
  pop3ReadMark: 0,
  // POP3 원본 저장 0: 원본 저장, 1: 클라이언트 설정에 따름
  pop3Del: 0,
  // 클라이언트 방식 0:POP3, 1:IMAP
  clientProtocolType: 0,
  // IMAP 메시지 제한
  imapMessageMaxLimit: 1000,
  // 스마트 메일함
  smartMailbox: 0,
  // 프로모션 분류
  classifyPromotion: 0,
  // 소셜 분류
  classifySocial: 0,
  // 자동 태그
  autoTagging: 0,
  // 태그
  useTag: 1,
  // 검색편지함
  useSearchFolder: 1,
  // 공유
  useShareFolder: 1,
  // 제목 검색 조건 0:단어 포함, 1:정확히 일치
  subjectSearchOperator: 0
};

const getters = {
  getConfigInit: ({ init }) => init,
  getUseSubjectFirst: ({ useSubjectFirst }) => useSubjectFirst,
  getSubjectFirsts: ({ subjectFirsts }) => [
    i18n.t("mail.107"),
    ...subjectFirsts
  ],
  getUseSubjectEmoji: ({ useSubjectEmoji }) => useSubjectEmoji,
  getSenderNames: ({ senderNames }) => senderNames,
  getDefaultSenderName: ({ defaultSenderName }) => defaultSenderName,
  getIncludeMe: ({ includeMe }) => includeMe,
  getIncludeMeEmail: ({ includeMeEmail }) => includeMeEmail,
  getFirstScreenFolderId: ({ firstScreenFolderId }) => firstScreenFolderId,
  getPageSize: ({ pageSize }) => pageSize,
  getAutoSave: ({ autoSave }) => autoSave,
  // getAutoSave: () => 5,
  getSplitView: ({ splitView }) =>
    Vuetify.framework.breakpoint.xs ? 0 : splitView,
  getShowFirstMail: ({ showFirstMail }) => showFirstMail,
  getGroupView: ({ groupView }) => groupView,
  getUseTab: ({ useTab }) => (Vuetify.framework.breakpoint.xs ? 0 : useTab),
  getFolderExpand: ({ folderExpand }) => folderExpand,
  getPopupRead: ({ popupRead }) =>
    Vuetify.framework.breakpoint.xs ? 0 : popupRead,
  getPopupWrite: ({ popupWrite }) =>
    Vuetify.framework.breakpoint.xs ? 0 : popupWrite,
  getQuickMenu: ({ quickMenu }) => quickMenu,
  getPreviewBeforeSend: ({ previewBeforeSend }) => previewBeforeSend,

  getListConfig: config => ({
    firstScreenFolderId: config.firstScreenFolderId,
    pageSize: config.pageSize,
    splitView: config.splitView,
    showFirstMail: config.showFirstMail,
    groupView: config.groupView,
    useTab: config.useTab,
    popupRead: config.popupRead,
    folderExpand: config.folderExpand,
    quickMenu: config.quickMenu,
    autoTagging: config.autoTagging,
    useTag: config.useTag,
    useSearchFolder: config.useSearchFolder,
    useShareFolder: config.useShareFolder,
    subjectSearchOperator: config.subjectSearchOperator
  }),
  getWriteConfig: config => ({
    useSubjectFirst: config.useSubjectFirst,
    subjectFirsts: config.subjectFirsts,
    useSubjectEmoji: config.useSubjectEmoji,
    senderNames: config.senderNames,
    defaultSenderName: config.defaultSenderName,
    includeMe: config.includeMe,
    includeMeEmail: config.includeMeEmail,
    popupWrite: config.popupWrite,
    draftReuse: config.draftReuse,
    autoSave: config.autoSave,
    previewBeforeSend: config.previewBeforeSend,
    initFontStyle: config.initFontStyle,
    initFontSize: config.initFontSize,
    subjectFormat: config.subjectFormat
  }),
  getPop3Config: config => ({
    pop3Flag: config.pop3Flag,
    pop3FetchRange: config.pop3FetchRange,
    pop3ReadMark: config.pop3ReadMark,
    pop3Del: config.pop3Del,
    clientProtocolType: config.clientProtocolType,
    imapMessageMaxLimit: config.imapMessageMaxLimit
  }),
  getSmartMailboxConfig: config => ({
    smartMailbox: config.smartMailbox,
    classifyPromotion: config.classifyPromotion,
    classifySocial: config.classifySocial
  })
};

const mutations = {
  SET_MAIL_CONFIG: (state, config) => {
    if (!state.init) state.init = true;
    Object.keys(config).forEach(c => (state[c] = config[c]));
  }
};

const actions = {
  async getMailConfig({ commit, rootState }) {
    const { data } = await getMailConfig();
    const { includeMeEmail, defaultSenderName: senderName } = data;
    const { accountName, username: senderEmail } = rootState.auth.userInfo;

    commit("SET_MAIL_CONFIG", {
      ...data,
      includeMeEmail: includeMeEmail || `"${accountName}" <${senderEmail}>`
    });
    commit(
      "mailCompose/SET_SENDER",
      { senderName, senderEmail },
      { root: true }
    );
  },
  async updateFirstScreen({ state }) {
    await updateFirstScreen(state.firstScreenFolderId);
  },
  async updatePageSize({ dispatch, state, rootState }) {
    await updatePageSize(state.pageSize);
    dispatch(
      "mail/getList",
      { ...rootState.mailRoute.mail_list, page: 1 },
      { root: true }
    );
  },
  async updateAutoSave({ state }) {
    await updateAutoSave(state.autoSave);
  },
  async updateSplitView({ state, commit, dispatch }) {
    const splitView = state.splitView ? 0 : 1;
    // split 여부 상관없이 목록으로이동
    const params = { moveToTabId: "list", splitView: state.splitView };
    dispatch("mailTab/deleteViewTabAndMoveList", params, { root: true });

    commit("SET_MAIL_CONFIG", { splitView, showFirstMail: 0 });
    await updateSplitView(state.splitView, state.showFirstMail);
  },
  async updatePopupRead({ commit, state }) {
    commit("SET_MAIL_CONFIG", { popupRead: state.popupRead ? 0 : 1 });
    await updatePopupRead(state.popupRead);
  },
  async updateShowFirstMail({ commit, state }) {
    commit("SET_MAIL_CONFIG", { showFirstMail: state.showFirstMail ? 0 : 1 });
    await updateSplitView(state.splitView, state.showFirstMail);
  },
  async updatePopupWrite({ commit, state }) {
    commit("SET_MAIL_CONFIG", { popupWrite: state.popupWrite ? 0 : 1 });
    await updatePopupWrite(state.popupWrite);
  },
  async updateGroupView({ commit, state }) {
    commit("SET_MAIL_CONFIG", { groupView: state.groupView ? 0 : 1 });
    await updateGroupView(state.groupView);
  },
  async updateUseTab({ state, rootGetters, commit, dispatch }) {
    const { useTab, splitView } = state;
    // 기존에 사용중이 였다면 메일쓰기탭 확인 및 탭 제거
    if (useTab) {
      // 메일쓰기 탭 있으면 물어봐야함
      if (
        router.currentRoute.name !== "mail_write" &&
        rootGetters["mailTab/existWriteTab"] &&
        !window.confirm(i18n.t("mail.45"))
      ) {
        // 스위치 꺼지는거 방지
        return commit("SET_MAIL_CONFIG", { useTab: useTab + 1 });
      }

      dispatch("mailTab/useTabChange", splitView, { root: true });
    }

    commit("SET_MAIL_CONFIG", { useTab: useTab ? 0 : 1 });
    await updateUseTab(state.useTab);
  },
  async updateListConfig(actions, params) {
    const { state, rootState, rootGetters, commit, dispatch } = actions;
    const { useTab, splitView } = state;
    // 기존에 사용중이 였다면 메일쓰기탭 확인 및 탭 제거
    if (useTab && !params.useTab) {
      // 메일쓰기 탭 있으면 물어봐야함
      if (
        router.currentRoute.name !== "mail_write" &&
        rootGetters["mailTab/existWriteTab"] &&
        !window.confirm(i18n.t("mail.45"))
      ) {
        // 스위치 꺼지는거 방지
        return commit("SET_MAIL_CONFIG", { useTab: useTab + 1 });
      }

      dispatch("mailTab/useTabChange", splitView, { root: true });
    }

    if (splitView !== params.splitView) {
      let moveToTabId = "list";
      if (params.useTab) moveToTabId = "";

      const dParams = { moveToTabId, splitView };
      dispatch("mailTab/deleteViewTabAndMoveList", dParams, { root: true });
    }

    const { status } = await updateListConfig(params);
    if (status == 201) {
      // 퀵메뉴 설정과 현재 선택된 폴더 확인
      const folderId = rootState.mailRoute.mail_list.folderId;
      if (
        Object.values(quickMenuEnum).find(q => q === folderId) &&
        !params.quickMenu.find(q => q === folderId)
      ) {
        dispatch(
          "mailRoute/routeMailList",
          { folderId: -1, page: 1, name: "mail_list", actionObj: {} },
          { root: true }
        );
      }

      commit("SET_MAIL_CONFIG", params);
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.422"), type: "SUCCESS" },
        { root: true }
      );

      if (params.pageSize != state.pageSize) {
        dispatch(
          "mail/getList",
          { ...rootState.mailRoute.mail_list, page: 1 },
          { root: true }
        );
      }
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.423"), type: "ERROR" },
        { root: true }
      );
    }
  },
  async updateWriteConfig({ commit, dispatch }, params) {
    const { status } = await updateWriteConfig(params);
    if (status == 201) {
      commit("SET_MAIL_CONFIG", params);
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.422"), type: "SUCCESS" },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.423"), type: "ERROR" },
        { root: true }
      );
    }
  },
  async updatePop3Config({ commit, dispatch }, params) {
    const { status } = await updatePop3Config(params);

    if (status == 201) {
      commit("SET_MAIL_CONFIG", params);
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.422"), type: "SUCCESS" },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.423"), type: "ERROR" },
        { root: true }
      );
    }

    return status;
  },
  async updateSmartMailboxConfig({ commit, dispatch }, params) {
    const { status } = await updateSmartMailboxConfig(params);
    if (status == 201) {
      commit("SET_MAIL_CONFIG", params);
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.422"), type: "SUCCESS" },
        { root: true }
      );
    } else {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("mail.423"), type: "ERROR" },
        { root: true }
      );
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
