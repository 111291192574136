import i18n from "@/_locales";
import * as googleApi from "@/drive/api/external/google.api";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { getDateWithTime } from "@/commons/utils/moment";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { convertGoogleFileIconByMimeType } from "@/commons/utils/google/driveUtil";

import router from "@/commons/router";

const state = {
  headers: [
    { text: "이름", value: "name", sortable: false },
    {
      text: "소유자",
      value: "owner",
      width: "15%",
      sortable: false
    },
    {
      text: "64",
      value: "modifiedTime",
      width: "15%",
      sortable: false
    },
    {
      text: "크기",
      value: "size",
      width: "10%",
      sortable: false
    }
  ],
  list: [],
  loading: false
};
const getters = {
  getHeaders: ({ headers }) =>
    headers.map(h => ({ ...h, text: i18n.t(`drive.${h.text}`) })),
  getDriveList: ({ list }) => {
    const resultList = list.map(
      ({
        id,
        name,
        mimeType,
        parents,
        webViewLink,
        createdTime,
        modifiedTime,
        owners,
        shared,
        ownedByMe,
        size,
        exportLinks,
        shortcutDetails
      }) => {
        const fileType = convertGoogleFileIconByMimeType(mimeType);
        const isShortcut = mimeType == "application/vnd.google-apps.shortcut";
        return {
          id,
          name,
          mimeType,
          parents,
          webViewLink,
          createdTime,
          modifiedTime: getDateWithTime(new Date(modifiedTime).getTime()),
          owner: ownedByMe ? i18n.t("drive.나") : owners[0].displayName,
          fileType,
          isShortcut,
          shared,
          icon: isShortcut
            ? fileIcons[
                convertGoogleFileIconByMimeType(shortcutDetails.targetMimeType)
              ]
            : fileType == "folder" && shared
            ? fileIcons["share"]
            : fileIcons[fileType],
          iconColor: isShortcut
            ? fileIconColors[
                convertGoogleFileIconByMimeType(shortcutDetails.targetMimeType)
              ]
            : fileIconColors[fileType],
          size: size > 0 ? byteCalculation(size) : "—",
          exportLinks,
          shortcutDetails
        };
      }
    );
    return resultList;
  },
  getLoading: ({ loading }) => loading
};
const mutations = {
  SET_INIT_EXTERNAL_DATA: state => {
    state.list = [];
    state.loading = false;
  },
  SET_LIST: (state, list) => (state.list = list),
  SET_LOADING: (state, loading) => (state.loading = loading)
};
const actions = {
  /** 드라이브 데이터 관련 함수 */
  async getDriveDataByTab({ commit, dispatch }, { externalType, tabId }) {
    commit("SET_LOADING", true);

    // TODO 추후 externalType에 대응하도록 수정필요
    const convertApi = {
      google: googleApi
    };
    const api = convertApi[externalType];

    const { data = [], status } =
      tabId == "my" ? await api.getRootDrive() : await api.getShareDrive();
    commit("SET_LOADING", false);
    if (status != 200) {
      return dispatch(
        "snackbar/openSnackbar",
        {
          message: i18n.t("drive.63"),
          type: "ERROR"
        },
        { root: true }
      );
    }
    const { files } = data;
    commit("SET_LIST", files);
  },
  async getDriveDataById({ commit, dispatch }, { externalType, id }) {
    commit("SET_LOADING", true);

    // TODO 추후 externalType에 대응하도록 수정필요
    const convertApi = {
      google: googleApi
    };
    const api = convertApi[externalType];

    const { data = [], status } = await api.getFilesByParentId(id);
    commit("SET_LOADING", false);
    if (status != 200) {
      return dispatch(
        "snackbar/openSnackbar",
        {
          message: i18n.t("drive.63"),
          type: "ERROR"
        },
        { root: true }
      );
    }
    const { files } = data;
    commit("SET_LIST", files);
  },
  async getDriveDataBySearch(
    { rootGetters, commit, dispatch },
    { externalType, actionObj }
  ) {
    commit("SET_LOADING", true);

    const { fileType, title, includeWord, startDate, endDate } = JSON.parse(
      actionObj
    );
    if (fileType == "all" && !title && !includeWord && !startDate) {
      return router.push({
        name: "drive_external_list_by_tab",
        params: {
          externalType: rootGetters["driveRoute/getExternalDriveType"],
          tabId: "my"
        }
      });
    }

    // TODO 추후 externalType에 대응하도록 수정필요
    const convertApi = {
      google: googleApi
    };
    const api = convertApi[externalType];

    const { data = [], status } = await api.getSearchDrive({
      fileType,
      title,
      includeWord,
      startDate,
      endDate
    });
    commit("SET_LOADING", false);
    if (status != 200) {
      return dispatch(
        "snackbar/openSnackbar",
        {
          message: i18n.t("drive.63"),
          type: "ERROR"
        },
        { root: true }
      );
    }
    const { files } = data;
    commit("SET_LIST", files);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
