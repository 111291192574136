import Vue from "vue";
import manage from "./manage";
import fetch from "./fetch";
import group from "./group";
import detail from "./detail";

const state = {
  loading: true,
  // 다음 페이지의 항목 선택 여부
  nextPageSelect: false,
  // 선택된 하위 업무
  checkedTasks: [],
  // 열려있는 하위 업무
  openSubTasks: [],

  ...manage.state,
  ...fetch.state,
  ...group.state,
  ...detail.state
};

const getters = {
  // 로그인한 사용자 편집권한 여부
  // state getters rootState rootGetters
  hasEditPermission: (a, b, c, d) => {
    const { members } = d["flowProjects/selectedProject"];
    const { id } = d["auth/getUserInfo"];

    const member = members?.find(m => m.userId == id);
    return member?.authority !== "READ";
  },

  ...manage.getters,
  ...fetch.getters,
  ...group.getters,
  ...detail.getters
};

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  ADD_OPEN_SUB_TASKS: (state, id) => {
    state.openSubTasks.push(id);
  },
  DELETE_OPEN_SUB_TASKS: (state, index) => {
    Vue.delete(state.openSubTasks, index);
  },
  SET_NEXT_PAGE_SELECT: (state, nextPageSelect) => {
    state.nextPageSelect = nextPageSelect;
  },
  SET_CHECKED_TASKS: (state, checkedTasks) => {
    state.checkedTasks = checkedTasks;
    if (!checkedTasks.length) state.nextPageSelect = false;
  },
  CHECK_TASK: (state, task) => {
    const index = state.checkedTasks.findIndex(t => t.id === task.id);
    if (index === -1) state.checkedTasks.push(task);
    else {
      Vue.delete(state.checkedTasks, index);
      if (!state.checkedTasks.length) state.nextPageSelect = false;
    }
  },
  ...manage.mutations,
  ...fetch.mutations,
  ...group.mutations,
  ...detail.mutations
};

const actions = {
  ...manage.actions,
  ...fetch.actions,
  ...group.actions,
  ...detail.actions,

  snackbar({ dispatch }, { message, type = "ERROR" }) {
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  confirm({ dispatch }, { message, callback }) {
    dispatch("confirm/confirm", { message, callback }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
