<template>
  <v-treeview
    dense
    shaped
    hoverable
    activatable
    transition
    return-object
    draggable="true"
    item-text="title"
    class="cr-todo-tree"
    :class="{ mobile: mdAndDown }"
    :items="boards"
    :active.sync="activeItems"
    :open.sync="_openFolders"
    @update:active="onActive"
    v-drag-and-drop="{
      DRAG_COMP: 'tree',
      genHelper,
      genDropzone,
      markDropzone,
      mouseup
    }"
  >
    <!-- 아이콘 -->
    <template v-slot:prepend="{ item }">
      <Icon :item="item" :activeItems="activeItems" />
    </template>

    <!-- 보드 이름 / 버튼  -->
    <template v-slot:label="{ item }">
      <TreeItem :item="item" />
    </template>
  </v-treeview>
</template>

<style lang="scss" scoped>
.v-treeview.cr-todo-tree::v-deep {
  overflow-y: auto;
  position: absolute;
  left: 0px;
  right: 8px;
  top: 105px;
  bottom: 64px;
  &.mobile {
    top: 120px;
    bottom: 0px;
  }

  /* 트리 노드 */
  .v-treeview-node--shaped {
    /* 노드 높이 */
    .v-treeview-node__root {
      min-height: 42px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 1px;
      /* 텍스트 왼쪽 여백 */
      .v-treeview-node__content {
        margin-left: 0px;
      }
    }
    /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
    .v-treeview-node__children {
      overflow: hidden;
    }
  }

  > .v-treeview-node {
    .v-treeview-node__root {
      .notranslate.v-treeview-node__toggle {
        z-index: 9999;
      }
      .v-treeview-node__content {
        cursor: pointer;
      }
    }
    > .v-treeview-node__children {
      .v-treeview-node__level {
        width: 12px;
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Icon from "./Icon.vue";
import TreeItem from "./treeItem";

export default {
  components: { Icon, TreeItem },
  mounted() {
    this.initData();
  },
  data() {
    return { activeItems: [], prevActiveItem: null };
  },
  computed: {
    ...mapGetters("dragDrop", ["dragComp"]),
    ...mapGetters("todoRoute", ["routeWorkspaceId", "routeBoardId"]),
    ...mapGetters("auth", { userInfo: "getUserInfo" }),
    ...mapGetters("todoBoard", ["boards", "openFolders", "findBoard"]),
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    _openFolders: {
      get() {
        return this.openFolders;
      },
      set(openFolders) {
        this.SET_OPEN_FOLDERS(openFolders);
      }
    }
  },
  watch: {
    routeWorkspaceId(next, prev) {
      if (next !== prev) {
        this.prevActiveItem = null;
        this.activeItems = [];
        this._openFolders = [];
      }
    },
    routeBoardId() {
      this.initData();
    }
  },
  methods: {
    ...mapMutations("todoBoard", ["SET_OPEN_FOLDERS"]),
    ...mapActions("todoBoard", ["moveBoard"]),
    getParentFolder(parentId) {
      const parents = [];
      const _getParentFolder = parentId => {
        const folder = this.findBoard(parentId);
        if (!folder) return;

        parents.push(folder);
        if (!folder.parentId) return;

        _getParentFolder(folder.parentId);
      };

      _getParentFolder(parentId);
      return parents;
    },
    initData() {
      if (this.routeBoardId === 0) {
        this.prevActiveItem = null;
        this.activeItems = [];
        this._openFolders = [];
        return;
      }

      const board = this.findBoard(this.routeBoardId);
      if (!board) return;

      this.activeItems = [board];
      if (!board.parentId) return;

      this._openFolders = [
        ...this._openFolders,
        ...this.getParentFolder(board.parentId)
      ];
    },
    onActive([n = {}]) {
      const { id, boardType, childCount } = n;

      // Board active 해제 방지
      if (!id && this.prevActiveItem) {
        this.activeItems = [this.prevActiveItem];
      }
      // Board 클릭
      if (boardType == "BOARD") {
        this.prevActiveItem = n;
      }
      // Folder 클릭
      if (boardType == "FOLDER") {
        this.activeItems = this.prevActiveItem ? [this.prevActiveItem] : [];
        if (!childCount || childCount === 0) return;

        const index = this._openFolders.findIndex(f => f.id == id);
        let openFolders = [...this._openFolders, n];
        if (index > -1) openFolders = this._openFolders.filter(f => f.id != id);

        this._openFolders = openFolders;
      }
    },

    // drag&drop
    // 헬퍼 생성 함수
    genHelper(e, setDragInfo) {
      const rootEl = e.target.closest(".v-treeview-node__root");
      const draggableEl = rootEl?.querySelector(".cr-draggable-tree");
      if (!draggableEl) return;

      const { id, type: boardType } = draggableEl.dataset;
      setDragInfo({ id, boardType });

      const target = rootEl.cloneNode(true);
      const labelDiv = target.querySelector(".v-treeview-node__label > div");
      const rightDiv = labelDiv.querySelector("button");
      const dropzone = labelDiv.querySelector(".cr-drop-tree");
      const toggleBtn = target.querySelector(".v-treeview-node__toggle");

      if (rightDiv) labelDiv.removeChild(rightDiv);
      if (toggleBtn) target.removeChild(toggleBtn);
      if (dropzone) labelDiv.removeChild(dropzone);

      Object.assign(target.style, { paddingRight: "20px", paddingTop: "3px" });
      target.classList.add("dnd-ghost");
      return target;
    },
    // 드랍가능영역 생성함수
    genDropzone() {
      if (this.dragComp == "tree") {
        document.getElementsByClassName("cr-dropzone-tree").forEach(el => {
          const { length } = el.getElementsByClassName("cr-drop-tree");
          if (length > 0) return;

          const { id, type } = el.dataset;
          const dropEl = document.createElement("div");
          dropEl.id = id;
          dropEl.setAttribute("data-type", type);
          dropEl.classList.add("cr-drop-tree");
          Object.assign(dropEl.style, {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px"
          });
          el.appendChild(dropEl);

          dropEl.addEventListener("mouseleave", async function(e) {
            e.target.style.border = null;
            e.target.style.background = null;
          });
        });
      }
    },
    markDropzone(e, dragInfo) {
      const boardType = e.target.getAttribute("data-type");
      if (
        boardType &&
        e.target.id !== dragInfo.id &&
        e.target.classList.contains("cr-drop-tree")
      ) {
        const { height } = e.target.getBoundingClientRect();
        const borderStyle = "3px solid #9E9E9E";
        const backgroundStyle = "rgba(1, 1, 1, 0.1)";

        if (e.offsetY <= 12) {
          e.target.style.border = null;
          e.target.style.borderTop = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "before");
        } else if (e.offsetY > height - 12) {
          e.target.style.border = null;
          e.target.style.borderBottom = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "next");
        } else if (boardType == "FOLDER") {
          e.target.style.borderTop = borderStyle;
          e.target.style.borderBottom = borderStyle;
          e.target.style.background = backgroundStyle;
          e.target.setAttribute("data-position", "inner");
        }
      }

      if (
        e.target.classList.contains("v-treeview-node__toggle") &&
        !e.target.classList.contains("v-treeview-node__toggle--open")
      ) {
        e.target.click();
      }
    },
    mouseup(e, dragInfo) {
      const boardId = parseInt(dragInfo?.id);
      const targetBoardId = parseInt(e.target.id);
      const position = e.target.getAttribute("data-position");
      const boardType = dragInfo.boardType;

      if (boardId && targetBoardId && position && boardId !== targetBoardId) {
        this.moveBoard({ boardId, targetBoardId, position, boardType });
      }

      // 드랍가능영역 제거
      const dropList = document.getElementsByClassName("cr-drop-tree");
      while (dropList.length > 0) {
        dropList[0].parentNode.removeChild(dropList[0]);
      }
    }
  }
};
</script>
