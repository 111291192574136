const isEmpty = value => {
  if (value === null) return true;
  if (typeof value === "undefined") return true;
  if (typeof value === "string" && value === "") return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "Object" &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value) < 1
  )
    return true;

  if (
    typeof value === "object" &&
    value.constructor.name === "String" &&
    Object.keys(value).length < 1
  )
    return true;

  return false;
};

const isBlank = value => {
  if (!value) return true;
  if (/^\s*$/.test(value)) return true;

  return false;
};

const isEmail = value => {
  let email = value;
  if (!email || email.lastIndexOf("@") < 1) {
    return false;
  }

  if (email.indexOf("<") > -1 && email.indexOf(">") > -1) {
    email = email.split("<")[1];
    email = email.split(">")[0];
  }

  // const regExp = /^[0-9a-zA-Z]([-_.#+]?[0-9a-zA-Z_])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  // const regExp = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  // const regExp = /^[0-9a-zA-Z]([-_.#+]?[0-9a-zA-Z_])+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  // const regExp = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // <이름이메일> 형식 유효하지 않도록 수정 ex) 이름test@crinity.com
  const regExp = /^[A-Z0-9._%+-^#!&*]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([A-Z0-9.-]+\.[A-Z]{2,}))$/i;

  return email.match(regExp) !== null;
};

// 객체끼리 같은지 비교
const isSameObj = (objectA, objectB) => {
  const isSame =
    Object.entries(objectA).toString() === Object.entries(objectB).toString();
  return isSame;
};

// JSON 여부판단
const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// 올바른 주소 형식인지 검사
const isUrl = url => {
  const regExp = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*", // port and path
    // "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    //   "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return url.match(regExp) !== null;
};

// 비밀번호 유효성 검사
const passwordValidation = value => {
  if (isBlank(value)) return false;

  /**
   * 숫자와 문자 포함 형태의 8~20자리 이내의 암호 정규식
   */
  const regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()+|=\-_])[A-Za-z\d~!@#$%^&*()+|=\-_]{8,16}$/;
  if (!regExp.test(value)) {
    return false;
  }

  return true;
};

/**
 * 이메일 일치 여부 검사
 */
const equalsIgnoreCaseEmail = (v1, v2) => {
  let left = v1;
  if (left.indexOf("<") > -1 && left.indexOf(">") > -1) {
    left = left.split("<")[1];
    left = left.split(">")[0];
  }

  let right = v2;
  if (right.indexOf("<") > -1 && right.indexOf(">") > -1) {
    right = right.split("<")[1];
    right = right.split(">")[0];
  }

  return left.toLocaleLowerCase() === right.toLocaleLowerCase();
};

export {
  isEmpty,
  isBlank,
  isEmail,
  isSameObj,
  isJson,
  isUrl,
  passwordValidation,
  equalsIgnoreCaseEmail
};
