import SockJS from "sockjs-client";
import Stomp from "stompjs";

import router from "@/commons/router";
import storage from "@/commons/api/storage";
import { isBlank } from "@/commons/utils/validation";

const state = {
  url: "",
  connected: false,
  connecting: false,
  retryCount: 0,
  timerId: null
};

const getters = {};

const mutations = {
  SET_URL: (state, url) => (state.url = url),
  SET_CONNECTING: (state, connecting) => {
    state.connecting = connecting;
  },
  SET_CONNECTED: (state, connected) => {
    state.connected = connected;
    state.connecting = false;
    state.retryCount = 0;
  },
  ADD_RETRY_COUNT: state => (state.retryCount += 1),
  SET_TIMER_ID: (state, timerId) => {
    if (state.timerId) {
      clearTimeout(state.timerId);
    }
    state.timerId = timerId;
  }
};

const actions = {
  // 초기화
  async initWebsocket({ commit, dispatch }, { host = "" }) {
    commit("SET_URL", host);
    dispatch("connect");
  },
  // 웹소켓 서버 연결
  async connect({ commit, dispatch, state }) {
    // URL 이 없거나 접속 중인 경우 예외
    if (isBlank(state.url)) return;
    if (state.connecting) return;

    commit("SET_CONNECTING", true);

    const client = Stomp.over(new SockJS(`${state.url}/ws`));
    client.debug = () => {};
    client.connect(
      {
        Authorization: `Bearer ${storage.getAccessToken()}`
      },
      // 연결 성공
      async () => {
        commit("SET_CONNECTED", true);
        commit("SET_TIMER_ID", null);
        window.stomClient = client;

        // 구독
        dispatch("subscribeAuthToken");
        dispatch("subscribeMail");
      },
      // 연결 오류
      async () => {
        commit("SET_CONNECTING", false);
        commit("ADD_RETRY_COUNT");

        if (state.retryCount >= 60) {
          // 3분간 접속이 불가능 하다면 취소
          return;
        }

        commit(
          "SET_TIMER_ID",
          setTimeout(() => {
            dispatch("connect");
          }, 3000)
        );
      }
    );
  },

  // 인증 토큰 구독
  async subscribeAuthToken({ rootGetters }) {
    if (!window.stomClient) return;

    const { username } = rootGetters["auth/getUserInfo"];
    window.stomClient.subscribe(
      `/topic/auth/user/${username}`,
      async data => {
        const { email, accessToken, refreshToken, type } = JSON.parse(
          data.body
        );
        /* console.info(
        `type:${type}, email:${email}, accessToken:${accessToken}, refreshToken:${refreshToken}`
      ); */

        if (email !== username) return;

        if (type === "TOKEN_ISSUED") {
          if (storage.getAccessToken() === accessToken) return;

          // 로컬스토지 데이터 갱신
          await storage.setAccessToken(accessToken);
          await storage.setRefreshToken(refreshToken);
        } else if (type === "TOKEN_DESTROY") {
          // 로그아웃
          await storage.clearToken();
          window.location.href = "/";
        }
      },
      {
        Authorization: `Bearer ${storage.getAccessToken()}`
      }
    );
  },
  // 인증 토큰 알림
  async notifyAuthToken({ rootGetters }, { accessToken, refreshToken }) {
    if (!window.stomClient) return;

    const { username } = rootGetters["auth/getUserInfo"];
    window.stomClient.send(
      `/publish/auth/user/${username}`,
      {
        Authorization: `Bearer ${storage.getAccessToken()}`
      },
      JSON.stringify({
        type: "TOKEN_ISSUED",
        accessToken: accessToken,
        refreshToken: refreshToken
      })
    );
  },
  // 로그아웃
  async notifyLogout({ rootGetters }) {
    if (!window.stomClient) return;

    const { username } = rootGetters["auth/getUserInfo"];
    window.stomClient.send(
      `/publish/auth/user/${username}`,
      {
        Authorization: `Bearer ${storage.getAccessToken()}`
      },
      JSON.stringify({
        type: "TOKEN_DESTROY",
        accessToken: storage.getAccessToken()
      })
    );
  },

  // 메일 구독
  async subscribeMail({ dispatch, rootGetters }) {
    if (!window.stomClient) return;

    const { username } = rootGetters["auth/getUserInfo"];
    window.stomClient.subscribe(
      `/topic/mail/user/${username}`,
      async data => {
        /* console.info(JSON.parse(data.body)); */

        const message = JSON.parse(data.body);
        const { type, folderId, mailId } = message;
        if (isBlank(folderId)) return;
        if (isBlank(mailId)) return;

        if (type === "NEW_MAIL") {
          const routeName = router.currentRoute.name || "";
          dispatch(
            "mailRoute/newMailNotification",
            { routeName, data: message },
            { root: true }
          );
        }
      },
      {
        Authorization: `Bearer ${storage.getAccessToken()}`
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
