<template>
  <Btn v-if="showBtn" v-bind="$props" />
  <MenuBtn
    v-else-if="showMenuBtn"
    v-bind="$props"
    @update:clickedMenuBtn="v => (clickedMenuBtn = v)"
  />
</template>

<style lang="scss" scoped>
.v-btn {
  min-width: 28px !important;
}
</style>

<script>
import Btn from "./Btn.vue";
import MenuBtn from "./MenuBtn.vue";

export default {
  components: { Btn, MenuBtn },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { clickedMenuBtn: false };
  },
  computed: {
    showBtn() {
      const { folderType } = this.item;
      return folderType.includes("FILTER");
    },
    showMenuBtn() {
      const { folderType } = this.item;

      if (this.showBtn) return false;
      if (this.clickedMenuBtn) return true;
      return folderType === "FILTER" && this.hover;
    }
  }
};
</script>
