import API from "@/approval/api/approval.api";
import { getBoxByKey } from "@/approval/constant/box";

// state 초기값
const initialState = {
  loading: false,
  documents: [],
  totalElements: 0
};

const state = { ...initialState };

const getters = {
  isLoading: ({ loading }) => loading,
  getTotalElements: ({ totalElements }) => totalElements,
  getDocumentItems: ({ documents }) =>
    documents.map(document => {
      let result = { ...document };
      // 속성값들 기타 처리
      for (const property in result) {
        // 표현할 수 없는 경우 '-' 처리
        if (!result[property]) {
          result[property] = "-";
        }
      }
      return result;
    })
};

const mutations = {
  // {state}를 초기값으로 reset
  RESET: state => Object.assign(state, initialState),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_DOCUMENTS: (state, documents) => {
    state.documents = [...documents];
  },
  SET_TOTAL_ELEMENTS: (state, totalElements) => {
    state.totalElements = totalElements;
  }
};

const actions = {
  // 페이지 초기화
  async init({ commit, dispatch }, routeProps) {
    commit("RESET");
    await dispatch("fetchDocumentList", routeProps);
  },
  // 문서 목록 로드
  async fetchDocumentList({ commit, dispatch }, routeProps = {}) {
    commit("SET_LOADING", true);
    // params 추출
    const {
      boxKey,
      page,
      size,
      sort,
      dir,
      draftFromDate,
      draftToDate,
      formId,
      keywordFlag,
      keyword
    } = routeProps;
    const { path } = getBoxByKey(boxKey) || {};
    const pageReq = { page, size, sort, dir };
    const searchDetail = {
      draftFromDate,
      draftToDate,
      formId,
      keywordFlag,
      keyword:
        keywordFlag === "TITLE"
          ? keyword?.title
          : keywordFlag === "DOC_NUM"
          ? keyword?.docNum
          : keywordFlag === "DRAFT_USER_ID"
          ? keyword?.userId
          : keywordFlag === "DRAFT_ORGAN_ID"
          ? keyword?.organId
          : undefined
    };
    // 목록 불러오기 API 호출
    const { status, data } = await API.getDocumentList(
      path,
      pageReq,
      searchDetail
    );
    // 실패시
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message: "기안 목록을 불러오는데 실패했습니다.",
          type: "ERROR"
        },
        { root: true }
      );
      commit("SET_LOADING", false);
      return;
    }
    // 성공시
    const { totalElements, content } = data;
    commit("SET_DOCUMENTS", content);
    commit("SET_TOTAL_ELEMENTS", totalElements);
    commit("SET_LOADING", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
