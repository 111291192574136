import Vue from "vue";
import { getDefaultFolders } from "../../api/folder.api";

const state = {
  folders: []
};

const getters = {};

const mutations = {
  SET_DEFAULT_FOLDERS: (state, folders) => {
    state.folders = folders;
  },
  SET_PROJECT: (state, project) => {
    const root = state.folders.find(f => f.folderType == "FAVORITE_ROOT");
    const idx = root.children.findIndex(p => p.id == project.id);

    if (idx === -1) return;
    Vue.set(root.children, idx, { ...project, folderType: "FAVORITE" });
  },
  ADD_PROJECT: (state, project) => {
    const root = state.folders.find(f => f.folderType == "FAVORITE_ROOT");
    root.children.push({ ...project, folderType: "FAVORITE" });
    root.children.sort((a, b) => a.sortOrder - b.sortOrder);
  },
  DELETE_PROJECT: (state, project) => {
    const root = state.folders.find(f => f.folderType == "FAVORITE_ROOT");
    const idx = root.children.findIndex(p => p.id == project.id);

    if (idx === -1) return;
    root.children.splice(idx, 1);
  }
};

const actions = {
  async getDefaultFolders({ commit }) {
    const { status, data } = await getDefaultFolders();
    if (status !== 200) {
      console.error("!!!!!!!!!!!!!!!!!!!!!!!!!!");
    }

    commit("SET_DEFAULT_FOLDERS", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
