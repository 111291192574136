import i18n from "@/_locales";

const state = {
  show: false,
  showCloseBtn: true,
  headline: "",
  message: "",
  callback: () => {},
  closeCallback: () => {},

  confirmStack: [],

  cancelButtonTitle: "",
  okButtonTitle: ""
};

const getters = {
  isShow: ({ show }) => show,
  isShowCloseBtn: ({ showCloseBtn }) => showCloseBtn,
  getHeadline: ({ headline }) => headline,
  getMessage: ({ message }) => message,

  getCancelButtonTitle: ({ cancelButtonTitle }) => cancelButtonTitle,
  getOkButtonTitle: ({ okButtonTitle }) => okButtonTitle
};

// state값 수정
const mutations = {
  INIT: (state, params) => {
    state.headline = params.headline;
    state.message = params.message;
    state.showCloseBtn = params.showCloseBtn ?? true;
    state.show = true;
    state.callback = params.callback;
    state.closeCallback = params.closeCallback;
    state.confirmStack = params.confirmStack ?? [];

    state.cancelButtonTitle = params.cancelButtonTitle ?? i18n.t("common.취소");
    state.okButtonTitle = params.okButtonTitle ?? i18n.t("common.확인");
  },
  SET_SHOW: (state, show) => (state.show = show),
  CHANGE_MESSAGE: state => {
    const { headline, message } = state.confirmStack.shift();
    state.headline = headline;
    state.message = message;
  }
};

// mutations 호출 및 비동기 처리
const actions = {
  async agree({ commit, state }) {
    if (state.confirmStack.length > 0) {
      commit("CHANGE_MESSAGE");
      return;
    }
    if (state.callback) {
      state.callback();
    }
    await commit("SET_SHOW", false);
  },
  async disagree({ commit, state }) {
    if (state.closeCallback) {
      state.closeCallback();
    }
    await commit("SET_SHOW", false);
  },
  async confirm({ commit }, params) {
    await commit("INIT", { ...params });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
