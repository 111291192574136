import Vue from "vue";
import {
  addGroup,
  updateGroupTitle,
  updateGroupColor,
  moveGroup,
  deleteGroup
} from "@/todo/api/group.api";

const state = {
  groups: [],
  closedGroup: {}
};

// state, getters, rootState, rootGetters
const getters = {
  orgGroups: ({ groups }) => groups,
  groups: ({ groups }, getters, rootState, rootGetters) => {
    const { isFilter, groupIds = [] } = rootGetters["todoFilter/filtersIds"];
    if (isFilter) {
      return groups.filter(g => groupIds.indexOf(g.id) > -1);
    }

    return groups;
  },
  closedGroup: ({ closedGroup }) => closedGroup
};

const mutations = {
  RESET_STATE: state => {
    state.groups = [];
    state.closedGroup = {};
  },
  SET_GROUPS: (state, groups) => {
    state.groups = groups;
  },
  UPDATE_GROUP: (state, { groupId, title, color }) => {
    const [group] = state.groups.filter(({ id }) => id == groupId);
    if (group) {
      group.title = title ?? group.title;
      group.color = color ?? group.color;
    }
  },
  SET_CLOSED_GROUP: (state, { id, closed }) => {
    Vue.set(state.closedGroup, id, closed);
  },
  MOVE_GROUP: (state, { groupId, targetGroupId, position }) => {
    const index1 = state.groups.findIndex(g => g.id == groupId);
    const tmp = state.groups[index1];
    // 드래그한 그룹 삭제
    Vue.delete(state.groups, index1);

    let index2 = state.groups.findIndex(g => g.id == targetGroupId);
    if (position == "after") {
      index2 += 1;
    }
    const { sortOrder = 0 } = state.groups[index2] ?? {};
    // 드래그한 그룹 추가
    state.groups.splice(index2, 0, { ...tmp, sortOrder });
    state.groups.forEach((i, idx) => {
      if (idx > index2) {
        i.sortOrder += 1;
      }
    });

    Vue.set(state, "groups", [...state.groups]);
  },
  MOVE_GROUP_ROLLBACK: (
    state,
    { groupId, targetGroupId, sortOrder, targetSortOrder }
  ) => {
    const index1 = state.groups.findIndex(g => g.id == groupId);
    const index2 = state.groups.findIndex(g => g.id == targetGroupId);
    if (index1 > -1) state.groups[index1].sortOrder = sortOrder;
    if (index2 > -1) state.groups[index2].sortOrder = targetSortOrder;

    state.groups.sort((a, b) => {
      if (a.sortOrder > b.sortOrder) {
        return 1;
      }
      if (a.sortOrder < b.sortOrder) {
        return -1;
      }

      return 0;
    });
  },
  DELETE_GROUP: (state, id) => {
    const index = state.groups.findIndex(g => g.id == id);
    if (index > -1) {
      Vue.delete(state.groups, index);
      delete state.items[id];
    }
  }
};

const actions = {
  async addGroup({ commit, dispatch }, { boardId, title }) {
    const { data, status } = await addGroup({ boardId, title });

    let message = `그룹을 추가하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `보드가 존재하지 않습니다.`;
        break;
      case 201: {
        if (data) {
          commit("SET_GROUPS", data);
          return;
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateGroupTitle(
    { commit, dispatch },
    { boardId, groupId, title, prevTitle }
  ) {
    commit("UPDATE_GROUP", { groupId, title });
    const { data, status } = await updateGroupTitle(boardId, groupId, title);

    let message = `그룹 타이틀을 변경하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `그룹이 존재하지 않습니다.`;
        break;
      case 201: {
        if (data) return;
      }
    }

    commit("UPDATE_GROUP", { groupId, title: prevTitle });
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateGroupColor(
    { commit, dispatch },
    { boardId, groupId, color, prevColor }
  ) {
    commit("UPDATE_GROUP", { groupId, color });
    const { data, status } = await updateGroupColor(boardId, groupId, color);

    let message = `그룹 색을 변경하지 못했습니다.`;
    let type = "ERROR";
    switch (status) {
      case 404:
        message = `그룹이 존재하지 않습니다.`;
        break;
      case 201: {
        if (data) return;
      }
    }

    commit("UPDATE_GROUP", { groupId, color: prevColor });
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async moveGroup(
    { state, commit, dispatch },
    { groupId, boardId, targetGroupId, position }
  ) {
    const [group] = state.groups.filter(g => g.id == groupId);
    const [targetGroup] = state.groups.filter(g => g.id == targetGroupId);
    if (!group || !targetGroup) return;
    const sortOrder = group.sortOrder;
    const targetSortOrder = targetGroup.sortOrder;
    commit("MOVE_GROUP", { groupId, targetGroupId, position });

    const { data, status } = await moveGroup(
      groupId,
      boardId,
      targetGroupId,
      position
    );
    let message = "그룹을 이동하는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404:
        message = "그룹이 존재하지 않습니다.";
        break;
      case 201:
        commit("SET_GROUPS", data);
        return;
    }

    commit("MOVE_GROUP_ROLLBACK", {
      groupId,
      targetGroupId,
      sortOrder,
      targetSortOrder
    });
    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteGroup({ state, commit, dispatch }, { boardId, groupId }) {
    let message = "보드는 하나 이상의 그룹이 있어야 합니다.";
    let type = "ERROR";
    if (state.groups.length > 1) {
      const { status } = await deleteGroup(boardId, groupId);

      message = "그룹을 삭제하지 못했습니다.";
      switch (status) {
        case 409:
          message = "보드는 하나 이상의 그룹이 있어야 합니다.";
          break;
        case 404:
          message = "그룹이 존재하지 않습니다.";
          break;
        case 204: {
          commit("DELETE_GROUP", groupId);
          return;
        }
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
