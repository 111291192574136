import {
  getLinkShareInfo,
  deleteLinkShare,
  updateLinkShare
} from "../../api/share.api";
const state = {
  show: false,
  moduleType: "",
  shareItem: {
    id: 0,
    name: "",
    icon: "",
    iconColor: ""
  },
  shareInfo: {
    id: 0,
    shareId: 0,
    permission: 2,
    validityDate: 0,
    linkScope: 1,
    accessCount: 0,
    linkUrl: "",
    pw: ""
  },
  initInfo: {
    linkScope: 1,
    permission: 2,
    validityDate: 0,
    accessCount: 0,
    pw: ""
  }
};

const getters = {
  isShow: ({ show }) => show,
  getModuleType: ({ moduleType }) => moduleType,
  getShareItem: ({ shareItem }) => shareItem,
  getShareInfo: ({ shareInfo }) => shareInfo,
  getInitInfo: ({ initInfo }) => initInfo
};

const mutations = {
  INIT: (state, { moduleType, shareItem, shareInfo, initInfo }) => {
    state.show = true;
    state.moduleType = moduleType;
    state.shareItem = shareItem;
    state.shareInfo = shareInfo;
    state.initInfo = initInfo;
  },
  SET_SHOW: (state, show) => (state.show = show),
  SET_LINKSCOPE: (state, value) => {
    state.shareInfo.linkScope = value;
  },
  SET_PERMISSION: (state, value) => {
    state.shareInfo.permission = value;
  },
  SET_VALIDITY_DATE: (state, value) => {
    state.shareInfo.validityDate = value;
  },
  SET_ACCESS_COUNT: (state, value) => {
    state.shareInfo.accessCount = value;
  },
  SET_PW: (state, value) => {
    state.shareInfo.pw = value;
  }
};

const actions = {
  //linkShare 초기값 세팅
  async linkShareDialog({ commit }, { moduleType, shareItem }) {
    // 링크가 있으면 조회, 없으면 링크 생성
    const { data, status } = await getLinkShareInfo({
      moduleType,
      objectId: shareItem.id
    });

    // 잘못된 응답으로인한 오류발생일 경우 false반환
    if (status !== 200) {
      return 0;
    }

    const { shareInfo, resultCode } = data;
    // 수정사항이 있는지 비교하기 위한 최초 링크공유 정보state에 저장
    const initInfo = {
      linkScope: shareInfo.linkScope,
      permission: shareInfo.permission,
      validityDate: shareInfo.validityDate,
      accessCount: shareInfo.accessCount,
      pw: shareInfo.pw
    };
    await commit("INIT", { moduleType, shareItem, shareInfo, initInfo });

    return resultCode;
  },

  async updateLinkScope({ commit }, value) {
    await commit("SET_LINKSCOPE", value);
  },
  async updatePermission({ commit }, value) {
    await commit("SET_PERMISSION", value);
  },
  async updateAccessCount({ commit }, value) {
    await commit("SET_ACCESS_COUNT", value);
  },
  async updateValidityDate({ commit }, value) {
    await commit("SET_VALIDITY_DATE", value);
  },
  async updatePw({ commit }, value) {
    await commit("SET_PW", value);
  },

  // 링크공유 삭제
  async deleteLinkShare({ getters }) {
    const { data, status } = await deleteLinkShare({
      id: getters.getShareInfo.id
    });
    if (status !== 200) {
      return -1;
    }
    return data ? 1 : 0;
  },

  // 링크공유 정보 수정
  async saveShareInfo({ getters }, shareInfo) {
    const { linkScope, permission, validityDate, accessCount, pw } = shareInfo;

    const { data, status } = await updateLinkShare({
      id: getters.getShareInfo.id,
      linkScope,
      permission,
      validityDate,
      accessCount,
      pw
    });

    if (status !== 200) {
      return -1;
    }
    return data ? 1 : 0;
  },

  // 다이얼로그 닫기
  async disagree({ commit }) {
    await commit("SET_SHOW", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
