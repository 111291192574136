var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-treeview',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
    DRAG_COMP: 'tree',
    genHelper: _vm.genHelper,
    genDropzone: _vm.genDropzone,
    markDropzone: _vm.markDropzone,
    mouseup: _vm.mouseup
  }),expression:"{\n    DRAG_COMP: 'tree',\n    genHelper,\n    genDropzone,\n    markDropzone,\n    mouseup\n  }"}],staticClass:"cr-todo-tree",class:{ mobile: _vm.mdAndDown },attrs:{"dense":"","shaped":"","hoverable":"","activatable":"","transition":"","return-object":"","draggable":"true","item-text":"title","items":_vm.boards,"active":_vm.activeItems,"open":_vm._openFolders},on:{"update:active":[function($event){_vm.activeItems=$event},_vm.onActive],"update:open":function($event){_vm._openFolders=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
  var item = ref.item;
return [_c('Icon',{attrs:{"item":item,"activeItems":_vm.activeItems}})]}},{key:"label",fn:function(ref){
  var item = ref.item;
return [_c('TreeItem',{attrs:{"item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }