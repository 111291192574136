<template>
  <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
    <v-btn icon @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-toolbar-title class="text-h6 pl-0">
      SirTEAM AI
    </v-toolbar-title>

    <v-spacer />

    <v-btn v-if="maximize" icon small @click="setMaximize(false)">
      <v-icon small>
        mdi-window-restore
      </v-icon>
    </v-btn>
    <v-btn v-if="!maximize" icon small @click="setMaximize(true)">
      <v-icon small>mdi-window-maximize</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters("ai", ["isAIDialogVisible", "maximize"]),
    sm() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 12;
      }
      return this.maximize ? 6 : 12;
    }
  },
  watch: {
    isAIDialogVisible(value) {
      this.show = value;
    }
  },
  methods: {
    ...mapActions("ai", ["hideAIDialog", "setMaximize"]),
    initialize() {
      this.show = this.isAIDialogVisible;
    },
    close() {
      this.hideAIDialog();
    }
  }
};
</script>
