<template>
  <v-tooltip bottom>
    <template #activator="{attrs, on}">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :color="value ? 'primary' : ''"
        icon
        @mousedown.stop
        @click="$emit('input', !value)"
      >
        <v-icon>
          mdi-airplane
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t("calendar.37") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
};
</script>
