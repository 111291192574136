import todoWorkspace from "./modules/workspace";
import todoBoard from "./modules/board";
import todoHeader from "./modules/header";
import todoGroup from "./modules/group";
import todoItem from "./modules/item";
import todoFilter from "./modules/filter";

import todoRoute from "./modules/route";
import todoDialog from "./modules/dialog";
import todoModal from "./modules/modal";
import todoLayout from "./modules/layout";
import todoTooltip from "./modules/tooltip";
import todoMenu from "./modules/menu";
import todoDetail from "./modules/detail";
import todoGanttSetting from "./modules/ganttSetting";
import todoKanban from "./modules/kanban";

export default {
  todoWorkspace,
  todoBoard,
  todoHeader,
  todoGroup,
  todoItem,
  todoFilter,

  todoRoute,
  todoDialog,
  todoModal,
  todoLayout,
  todoTooltip,
  todoMenu,
  todoDetail,
  todoGanttSetting,
  todoKanban
};
