import Vue from "vue";
import i18n from "@/_locales";

import {
  getDefaultFolders,
  getSuperParentFolder,
  getFoldersByParentId,
  getDriveList,
  addFolder,
  updateFileName,
  updateImportant,
  getMovableFolders,
  moveFile,
  copyFile,
  cleanDeleted,
  deleteFile
} from "../../api/drive.api";
import { getDateWithTime } from "@/commons/utils/moment";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";

const state = {
  folders: [],
  headers: [
    {
      text: "",
      value: "important",
      width: "32px",
      class: "px-1", //header 부분 class지정
      cellClass: "px-1", //헤더에 해당하는 cell부분 class지정
      sortable: false,
      use: true
    },
    {
      text: "이름",
      value: "fileName",
      sortable: true,
      use: true
    },
    {
      text: "소유자",
      value: "owner",
      width: "15%",
      cellClass: "grey--text",
      sortable: false,
      use: false
    },
    {
      text: "57",
      value: "usedTimeMillis",
      width: "15%",
      cellClass: "grey--text",
      sortable: true,
      use: true
    },
    {
      text: "58",
      value: "deleteTimeMillis",
      width: "15%",
      cellClass: "grey--text",
      sortable: true,
      use: false
    },
    {
      text: "크기",
      value: "fileSize",
      width: "15%",
      cellClass: "grey--text",
      sortable: false,
      use: true
    }
  ],
  list: [],
  activeItems: [],
  openItems: [],
  totalElements: 0,
  loading: false,
  // 파일 최대사이즈(1GB)
  maxFileSize: 1073741824,
  // 드래그앤드랍시 overlay
  overlayInfo: {
    show: false,
    zIndex: -1
  }
};

const getters = {
  getFolders: ({ folders }) => folders, // folders,
  getFolder: ({ folders }) => folderId => getFile(folders, folderId),
  getHeaders: ({ headers }) => {
    return headers
      .filter(({ use }) => use)
      .map(h => ({ ...h, text: h.text ? i18n.t(`drive.${h.text}`) : "" }));
  },
  getShareHeaders: ({ shareHeaders }) => shareHeaders,
  getList: ({ list }) => {
    const listData = list;
    let data = [];

    for (let i = 0; i < listData.length; i += 1) {
      const item = listData[i];
      // folder(DIRCETORY)일 경우 state에 folders에 있다면 갖고온다.
      if (item.fileType === "DIRECTORY" && item.deleteTimeMillis == 0) {
        const file = getFile(state.folders, item.id);
        !file ? data.push(item) : data.push(file);
      } else {
        data.push(item);
      }
    }

    const resultList = data.map(
      ({
        id,
        parentId,
        childCount,
        important,
        shareFlag,
        linkFlag,
        isShortcut,
        fileType,
        owner,
        fileName,
        extension,
        folderColor,
        fileSize,
        usedTimeMillis,
        deleteTimeMillis
      }) => {
        return {
          id,
          parentId,
          childCount,
          important,
          shareFlag,
          linkFlag,
          isShortcut,
          fileType,
          owner,
          fileName,
          extension,
          icon: shareFlag
            ? fileIcons["share"]
            : fileIcons[extension]
            ? fileIcons[extension]
            : fileIcons["file"],
          iconColor:
            fileType == "DIRECTORY"
              ? folderColor
              : fileIcons[extension]
              ? fileIconColors[extension]
              : fileIconColors["file"],
          fileSize: fileSize > 0 ? byteCalculation(fileSize) : "—",
          deleteTimeMillis:
            deleteTimeMillis > 0 ? getDateWithTime(deleteTimeMillis) : "—",
          usedTimeMillis:
            usedTimeMillis > 0 && fileType !== "DIRECTORY"
              ? getDateWithTime(usedTimeMillis)
              : "—"
        };
      }
    );

    return resultList;
  },
  getActiveItems: ({ activeItems }) => activeItems,
  getOpenItems: ({ openItems }) => openItems,
  getTotalElements: ({ totalElements }) => totalElements,
  getRootFolder: ({ folders }) =>
    folders.filter(({ fileType }) => fileType == "ROOT"),
  getDeletedFolder: ({ folders }) =>
    folders.filter(({ fileType }) => fileType == "DELETED"),
  getLoading: ({ loading }) => loading,
  getFullPath: ({ folders }) => folderId => getFullPath(folders, folderId),
  getOverlayInfo: ({ overlayInfo }) => overlayInfo
};

/**
 * 폴더 조회
 * @param {*} folders folder 목록
 * @param {*} fileId  찾을 folder id
 * @returns 폴더
 */
const getFile = (folders, fileId) => {
  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i];

    if (folder.id == fileId) return folder;

    if (folder.children) {
      const childrenResult = getFile(folder.children, fileId);
      if (childrenResult) return childrenResult;
    }
  }
};

/**
 * List에 있는 파일 조회
 * @param {*} list   찾을 list
 * @param {*} fileId 파일 id
 * @returns 파일
 */
const getListItem = (list, fileId) => {
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    if (item.id == fileId) return item;
  }
};

const getFullPath = (folders, folderId, result = []) => {
  const file = getFile(folders, folderId);
  result.push(file);
  if (file.parentId == 0) return result;

  return getFullPath(folders, file.parentId, result);
};

// children 제거 (tree에 화살표 표시를 없애기 위한 함수)
const deleteChildren = folders => {
  for (let i = 0; i < folders.length; i += 1) {
    // 폴더 조회
    const folder = folders[i];
    const { childCount, children } = folder;

    if (childCount == 0) delete folder.children;
    /* 
      자식폴더는 있지만 아직 서버로 부터 내려받지 못한 상태
      이때는 빈배열로 children을 세팅 해주어서 폴더 옆 하위폴더표시(화살표)를 만들어준다. 
    */
    if (childCount > 0 && !children) folder.children = [];
    // children이 있는 경우는 하위폴더도 마찬가지로 검사할 수 있게 재귀함수 형태로 처리.
    if (children) deleteChildren(children);
  }
};

const mutations = {
  INIT_DRIVE_DATA: state => {
    state.folders = [];
    state.list = [];
    state.activeItems = [];
    state.openItems = [];
    state.totalElements = 0;
    state.loading = false;
    state.maxFileSize = 1073741824;
    state.overlayInfo = {
      show: false,
      zIndex: -1
    };
  },
  // 기본 폴더함 목록 SET
  SET_DEFAULT_FOLDERS: (state, folders) => {
    state.folders = folders;
  },
  // 휴지통의 경우는 삭제날짜가 나오게 설정
  SET_HEADERS: (state, folderId) => {
    const folder = getFile(state.folders, folderId);
    if (!folder) return;
    if (folder.fileType === "DELETED") {
      for (let i = 0; i < state.headers.length; i++) {
        if (state.headers[i].value == "usedTimeMillis") {
          state.headers[i].use = false;
        } else if (state.headers[i].value == "deleteTimeMillis") {
          state.headers[i].use = true;
        }
      }
    } else {
      for (let i = 0; i < state.headers.length; i++) {
        if (state.headers[i].value == "usedTimeMillis") {
          state.headers[i].use = true;
        } else if (state.headers[i].value == "deleteTimeMillis") {
          state.headers[i].use = false;
        }
      }
    }
  },

  SET_CHILDREN: (state, { parentId, children, count = 0 }) => {
    const folder = getFile(state.folders, parentId);

    if (!folder) return;
    folder.childCount += count;

    let folderChildren = folder.children;

    if (folderChildren) {
      children.forEach(n => {
        const index = folder.children.findIndex(({ id }) => id == n.id);
        if (index == -1) {
          folderChildren.push(n);
        }
      });
      Vue.set(folder, "children", folderChildren);
    } else {
      Vue.set(folder, "children", children);
    }
  },

  SET_FAST_ACCESS: (state, fastAccess) => {
    state.fastAccess = fastAccess;
  },

  SET_PAGE: (state, { files, totalElements }) => {
    state.list = files;
    state.totalElements = totalElements;
    state.loading = false;
  },

  UPDATE_IS_EDIT: (state, fileId) => {
    const folder = getFile(state.folders, fileId);
    folder.isEdit = !folder.isEdit;
  },

  UPDATE_FILE_NAME: (state, { fileId, fileName, fileType, usedTimeMillis }) => {
    let file;
    // 폴더일 경우
    if (fileType == "DIRECTORY") {
      file = getFile(state.folders, fileId);
      // 파일일 경우
    } else {
      file = getListItem(state.list, fileId);
    }

    if (!file) return;
    // store에 이름을 변경해준다.
    file.fileName = fileName;
    file.usedTimeMillis = usedTimeMillis;
  },

  // 수정
  UPDATE_IMPORTANT: (state, { fileIds, important }) => {
    for (let i = 0; i < fileIds.length; i++) {
      const fileId = fileIds[i];
      const folder = getFile(state.folders, fileId);

      if (folder) {
        //폴더일 경우
        folder.important = important;
      } else {
        //파일일 경우
        const file = state.list.filter(({ id: listId }) => listId == fileId);
        file[0].important = important;
      }
    }
  },

  UPDATE_SHARE_FLAG: (state, { id, shareFlag }) => {
    const folder = getFile(state.folders, id);
    folder.shareFlag = shareFlag;
  },

  DELETE_FOLDER: (state, { folderId, parentId, count = 0 }) => {
    // 현재 파일(or 폴더) state.folders에 없는경우 (ex. 파일일경우) 는 리턴
    const file = getFile(state.folders, folderId);
    if (!file) return;

    // 그렇지 않은경우는 부모 폴더를 찾아서 자식폴더 카운트를 감소해준다.
    const folder = getFile(state.folders, parentId);
    if (!folder) return;
    folder.childCount -= count;

    // 그 후 부모폴더에서 해당 폴더를 찾아
    const index = folder.children.findIndex(({ id }) => id == folderId);

    // 만약 현재 부모폴더의 자식폴더 갯수가 1일 경우는 children 자체를 삭제, 그렇지 않은경우는 해당 폴더만 삭제
    if (folder.children.length == 1) Vue.delete(folder, "children");
    else Vue.delete(folder.children, index);
  },

  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },

  SET_ACTIVEITEMS: (state, { items }) => {
    state.activeItems = items;
  },

  SET_OPENITEMS: (state, { items }) => {
    state.openItems = items;
  },

  DELETE_OPENITEMS: (state, { index }) => {
    state.openItems.splice(index, 1);
  },

  INIT_OPENITEMS: state => {
    state.openItems = [];
  },

  SET_OVERLAY_INFO: (state, { show, zIndex }) => {
    state.overlayInfo.show = show;
    state.overlayInfo.zIndex = zIndex;
  }
};

const actions = {
  // 초기 드라이브 폴더 세팅 ok
  async getDefaultFolders({ commit }, folderId) {
    // 초기 기본 드라이브 폴더 가져오기
    // (나의드라이브(자식폴더), 휴지통, 최근문서, 중요폴더, 공유폴더)
    const { data = [] } = await getDefaultFolders();

    const icons = {
      ROOT: "mdi-folder-home",
      DELETED: "mdi-trash-can",
      RECENT: "mdi-history",
      IMPORTANT: "mdi-star",
      SHARE: "mdi-share-variant",
      GOOGLE: "mdi-google-drive"
    };
    const folders = data.map(
      ({
        id,
        parentId,
        childCount,
        important,
        shareFlag,
        linkFlag,
        fileType,
        owner,
        fileName,
        extension,
        folderColor,
        fileSize,
        usedTimeMillis,
        deleteTimeMillis,
        children = []
      }) => {
        // 다국어 처리를 위한 기본 폴더 맵핑
        if (
          [
            "ROOT", // 내 드라이브
            "DELETED", // 휴지통
            "RECENT", // 최근 문서함
            "IMPORTANT", // 중요 문서함
            "SHARE", // 공유 문서함
            "GOOGLE" // 구글 드라이브
          ].indexOf(fileType) > -1
        ) {
          fileName = i18n.t(`drive.folder_${fileType}`);
        }

        let childrenObj = {};
        const typeIsShare = fileType == "SHARE" ? true : false;
        if (childCount > 0) {
          const mappedChildren = children.map(
            ({
              id,
              parentId,
              childCount,
              important,
              shareFlag,
              linkFlag,
              fileType,
              owner,
              fileName,
              extension,
              folderColor,
              fileSize,
              usedTimeMillis,
              deleteTimeMillis
            }) => {
              const children = childCount > 0 ? { children: [] } : {};
              return {
                id,
                parentId,
                childCount,
                important,
                shareFlag,
                linkFlag,
                fileType,
                owner,
                fileName,
                extension,
                folderColor,
                fileSize,
                usedTimeMillis,
                deleteTimeMillis,
                isShare: typeIsShare,
                isEdit: false,
                icon: icons[fileType],
                ...children
              };
            }
          );
          childrenObj = { children: mappedChildren };
        }

        return {
          id,
          parentId,
          childCount,
          important,
          shareFlag,
          linkFlag,
          fileType,
          owner,
          fileName,
          extension,
          folderColor,
          fileSize,
          usedTimeMillis,
          deleteTimeMillis,
          isEdit: false,
          icon: icons[fileType],
          ...childrenObj
        };
      }
    );

    const routeFolder = getFile(folders, folderId);
    if (!routeFolder && folderId != -4) {
      const { data } = await getSuperParentFolder(folderId);

      if (data.children) {
        // data에 children 제거
        deleteChildren(data.children);

        for (let i = 0; i < folders.length; i += 1) {
          const folder = folders[i];
          if (folder.fileType == "ROOT") {
            for (let j = 0; j < folder.children.length; j += 1) {
              if (folder.children[j].id == data.id) {
                folder.children[j] = data;
                break;
              }
            }
            break;
          }
        }
      }
    }

    commit("SET_DEFAULT_FOLDERS", folders);
    commit("SET_HEADERS", folderId);
  },

  // 빠른엑세스
  async getFastAccess() {
    const { data = [], status } = await getDriveList({
      folderId: -1,
      pageSize: 10,
      page: 0,
      sort: "usedTimeMillis",
      dir: "desc"
    });

    const contents = {
      CREATE: i18n.t("drive.59"),
      RENAME: i18n.t("drive.60"),
      DOWNLOAD: i18n.t("drive.61"),
      OPEN: i18n.t("drive.62")
    };

    if (status == 200) {
      const { content = [] } = data;

      const defaultFiles = content.map(file => {
        return {
          ...file,
          icon: fileIcons[file.extension]
            ? fileIcons[file.extension]
            : fileIcons["file"],
          iconColor: fileIcons[file.extension]
            ? fileIconColors[file.extension]
            : fileIconColors["file"],
          typeContent: contents[file.usedType]
        };
      });

      return defaultFiles;
    } else {
      return false;
    }
  },

  // children 폴더 세팅 (하위 자식폴더 가져오기) ok
  async getFoldersByParentId({ commit }, { parentId }) {
    const { data = [] } = await getFoldersByParentId({ parentId });

    const children = data.map(
      ({
        id,
        parentId,
        childCount,
        important,
        shareFlag,
        linkFlag,
        fileType,
        owner,
        fileName,
        extension,
        folderColor,
        fileSize,
        usedTimeMillis,
        deleteTimeMillis
      }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          childCount,
          important,
          shareFlag,
          linkFlag,
          fileType,
          owner,
          fileName,
          extension,
          folderColor,
          fileSize,
          usedTimeMillis,
          deleteTimeMillis,
          isEdit: false,
          ...childrenObj
        };
      }
    );
    if (children.length > 0) commit("SET_CHILDREN", { parentId, children });
  },

  // 리스트 호출 ok
  async getDriveList(
    { commit },
    { folderId, page = 1, pageSize = 10, actionObj = "{}" }
  ) {
    commit("SET_LOADING", true);
    const {
      sort = "usedTimeMillis",
      dir = "desc",
      searchWord = ""
    } = JSON.parse(actionObj);

    const { data = [] } = await getDriveList({
      folderId,
      pageSize,
      page: page - 1,
      sort,
      dir,
      searchWord
    });

    /*
    content: 리스트, number: 페이지번호, size: 페이지사이즈, 
    totalElements: 해당리스트의 아이템 총 갯수
    */
    const { content = [], totalElements } = data;

    const isExist = getFile(state.folders, folderId);

    if (isExist) {
      if (isExist.fileType === "ROOT" || isExist.fileType === "DIRECTORY") {
        const folders = content.filter(
          ({ fileType }) => fileType == "DIRECTORY"
        );
        if (folders.length > 0) {
          const children = folders.map(
            ({
              id,
              parentId,
              childCount,
              important,
              shareFlag,
              linkFlag,
              isShortcut,
              fileType,
              owner,
              fileName,
              extension,
              folderColor,
              fileSize,
              usedTimeMillis,
              deleteTimeMillis
            }) => {
              const childrenObj = childCount > 0 ? { children: [] } : {};
              return {
                id,
                parentId,
                childCount,
                important,
                shareFlag,
                linkFlag,
                isShortcut,
                fileType,
                owner,
                fileName,
                extension,
                folderColor,
                fileSize,
                usedTimeMillis,
                deleteTimeMillis,
                isEdit: false,
                ...childrenObj
              };
            }
          );
          commit("SET_CHILDREN", { parentId: folderId, children });
        }
      }
    }
    commit("SET_HEADERS", folderId);

    commit("SET_PAGE", {
      files: content,
      totalElements
    });
  },

  // 새폴더 생성
  async addFolder({ dispatch, commit, rootState }, { name, parentId }) {
    /**
     * data.list : 부모폴더의 자식폴더들
     * */
    const { data = [], status } = await addFolder({ name, parentId });

    if (status != 201) return false;

    // 자식폴더들을 state.folders에 저장해줌.
    const children = data.list.map(
      ({
        id,
        parentId,
        childCount,
        important,
        shareFlag,
        linkFlag,
        fileType,
        owner,
        fileName,
        extension,
        folderColor,
        fileSize,
        usedTimeMillis,
        deleteTimeMillis
      }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          childCount,
          important,
          shareFlag,
          linkFlag,
          fileType,
          owner,
          fileName,
          extension,
          folderColor,
          fileSize,
          usedTimeMillis,
          deleteTimeMillis,
          isEdit: false,
          ...childrenObj
        };
      }
    );
    commit("SET_CHILDREN", { parentId, children, count: 1 });

    // 부모id가 현재 라우팅에 있는 id와 동일할 경우 list를 업데이트한다.
    if (parentId == rootState.driveRoute.drive_list.folderId) {
      await dispatch("listRefresh");
    }
    return true;
  },

  // 리스트 새로고침
  async listRefresh({ dispatch, rootState }) {
    await dispatch("getDriveList", {
      folderId: rootState.driveRoute.drive_list.folderId,
      page: rootState.driveRoute.drive_list.page,
      pageSize: rootState.driveRoute.pageSize,
      actionObj: rootState.driveRoute.drive_list.actionObj
    });
  },

  // 이름변경 할때 사용하는 isEdit 변경 ok
  updateIsEdit({ commit }, fileId) {
    commit("UPDATE_IS_EDIT", fileId);
  },

  // 파일(폴더 or 파일) 이름 변경 ok
  async updateFileName({ commit }, { fileName, fileId }) {
    const { data, status } = await updateFileName({ fileName, fileId });

    if (status === 200) {
      await commit("UPDATE_FILE_NAME", {
        fileId: data.id,
        fileName: data.fileName,
        fileType: data.fileType,
        usedTimeMillis: data.usedTimeMillis
      });
      return true;
    }
    return false;
  },

  // 이동가능한 폴더 조회
  async getMovableFolders() {
    const { data = [] } = await getMovableFolders();
    deleteChildren(data);

    return data;
  },

  // 이동기능 에서 새폴더 생성
  async addNewFolder(
    { state, commit, dispatch, rootState },
    { parentFolderId }
  ) {
    const { data = [] } = await addFolder({ parentId: parentFolderId });
    // state에 폴더 추가
    const children = data.list.map(
      ({
        id,
        parentId,
        childCount,
        important,
        shareFlag,
        linkFlag,
        fileType,
        owner,
        fileName,
        extension,
        folderColor,
        fileSize,
        usedTimeMillis,
        deleteTimeMillis
      }) => {
        const childrenObj = childCount > 0 ? { children: [] } : {};
        return {
          id,
          parentId,
          childCount,
          important,
          shareFlag,
          linkFlag,
          fileType,
          owner,
          fileName,
          extension,
          folderColor,
          fileSize,
          usedTimeMillis,
          deleteTimeMillis,
          isEdit: false,
          ...childrenObj
        };
      }
    );

    const { parentId } = data.folder;
    const parent = getFile(state.folders, parentId);
    if (parent) {
      commit("SET_CHILDREN", { parentId, children, count: 1 });
      if (parent.id === rootState.driveRoute.drive_list.folderId) {
        await dispatch("listRefresh");
      }
    }

    return data.folder;
  },

  // 이동가능한 폴더인지 검사
  validationMove(commit, { movableTree, selectItem, movedItems }) {
    const [{ id: selectItemId }] = selectItem;
    for (let i = 0; i < movedItems.length; i += 1) {
      const item = movedItems[i];

      const folder = getFile(movableTree, item.id);

      // 1. 자기 자신으로 이동x
      if (folder.id == selectItemId) {
        return false;
      }

      // 2. 이동할폴더의 자식폴더이면 이동x
      if (folder.children) {
        const childFolder = getFile(folder.children, selectItemId);
        if (childFolder) {
          return false;
        }
      }
    }
    return true;
  },

  // 파일(or 폴더) 복사, 이동
  async copyFiles(getters, { selectedItems, targetFolderId }) {
    let fileIds = [];
    selectedItems.forEach(item => {
      fileIds.push(item.id);
    });
    const { status } = await copyFile({ fileIds, targetFolderId });
    return status == 200 ? true : false;
  },

  /**
   * 파일(or 폴더) 이동
   * selectedItems -- 이동할 드라이브 목록
   * targetFolderId -- 이동할 폴더 id
   * parentId -- 현재의 부모 폴더 id
   */
  async moveFiles(
    { getters, dispatch, commit },
    { selectedItems, targetFolderId, parentId }
  ) {
    let fileIds = [];
    selectedItems.forEach(item => {
      fileIds.push(item.id);
    });

    const { status } = await moveFile({ fileIds, targetFolderId });

    if (status === 200) {
      for (let i = 0; i < fileIds.length; i += 1) {
        const fileId = fileIds[i];
        const drive = getFile(getters.getFolders, fileId);
        // 파일 일 경우는 따로 처리를 안해주어도 된다.
        if (!drive) continue;
        // 현재의 부모 폴더 id가 열려 있는 상태인지 확인
        const parentIndex = getters.getOpenItems.findIndex(
          ({ id: openId }) => openId === parentId
        );
        if (parentIndex > -1) {
          if (getters.getOpenItems[parentIndex].children.length == 1) {
            await commit("DELETE_OPENITEMS", { index: parentIndex });
          }
        }

        // 1. 이동한 폴더는 기존 부모폴더에서 삭제
        await commit("DELETE_FOLDER", { folderId: fileId, parentId, count: 1 });
      }
      // 2. 이동한 부모폴더에 추가(폴더만)

      // 이동한 부모폴더에 자식 불러오기
      const targetFolder = getFile(getters.getFolders, targetFolderId);
      if (targetFolder)
        await dispatch("getFoldersByParentId", { parentId: targetFolderId });

      await dispatch("listRefresh");
      return true;
    } else {
      return false;
    }
  },

  // 휴지통으로 이동
  async toDeleted(
    { getters, commit, dispatch, rootState },
    { fileIds, parentId }
  ) {
    // targetFolderId ->휴지통 id를 조회한다.
    const targetFolderId = getters.getDeletedFolder[0].id;
    const { status } = await moveFile({
      fileIds,
      targetFolderId
    });

    // 서버 통신 성공 일때,
    if (status === 200) {
      for (let i = 0; i < fileIds.length; i += 1) {
        const fileId = fileIds[i];
        const drive = getFile(getters.getFolders, fileId);
        if (!drive) continue;

        // 현재의 부모 폴더가 openItems 있는 지(열려 있는상태) 확인
        const parentIndex = getters.getOpenItems.findIndex(
          ({ id: openId }) => openId == parentId
        );

        if (parentIndex > -1) {
          if (getters.getOpenItems[parentIndex].children.length == 1) {
            await commit("DELETE_OPENITEMS", { index: parentIndex });
          }
        }
        // 2. 선택한 폴더 삭제
        const index = getters.getOpenItems.findIndex(
          ({ id: openId }) => openId === fileId
        );
        // 만약 openItems에 있다면 (열려 있는상태) 제거
        if (index > -1) await commit("DELETE_OPENITEMS", { index: index });

        // 1. 이동한 폴더는 기존 부모폴더에서 삭제
        await commit("DELETE_FOLDER", {
          folderId: fileId,
          parentId,
          count: 1
        });
      }
      if (parentId == rootState.driveRoute.drive_list.folderId) {
        await dispatch("listRefresh");
      }
      return true;
    }
    return false;
  },

  // 휴지통에서 완전 삭제
  async deleteFileOnTrash({ dispatch }, { deleteList }) {
    const { status } = await deleteFile({
      fileIds: deleteList
    });

    if (status == 200) {
      await dispatch("listRefresh");

      return true;
    }
    return false;
  },

  // 별표 변경 ok
  async updateImportant({ commit }, { fileIds, important }) {
    const { status } = await updateImportant({
      fileIds,
      important
    });

    if (status == 200) {
      await commit("UPDATE_IMPORTANT", {
        fileIds,
        important
      });
      return true;
    }
    return false;
  },

  // 공유표시 변경
  async updateShareFlag({ commit }, { id, shareFlag }) {
    await commit("UPDATE_SHARE_FLAG", { id, shareFlag });
  },

  // 휴지통 비우기(완전삭제)
  async cleanDeleted({ getters, dispatch, rootState }) {
    const deletedFolderId = getters.getDeletedFolder[0].id;
    const { status } = await cleanDeleted(deletedFolderId);

    if (status == 200) {
      if (rootState.driveRoute.drive_list.folderId == deletedFolderId) {
        await dispatch("listRefresh");
      }

      return true;
    }
    return false;
  },

  setOpenItems({ commit }, items) {
    commit("SET_OPENITEMS", { items });
  },

  setActiveItems({ commit }, items) {
    commit("SET_ACTIVEITEMS", { items });
  },

  initOpenItems({ commit }) {
    commit("INIT_OPENITEMS");
  },

  setDragDropOverlay({ commit }, { show, zIndex }) {
    commit("SET_OVERLAY_INFO", { show, zIndex });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
