const state = {
  dragMode: false,
  dragComp: ""
};

const getters = {
  dragMode: ({ dragMode }) => dragMode,
  dragComp: ({ dragComp }) => dragComp
};

// state값 수정
const mutations = {
  SET_DRAG_MODE: (state, dragMode) => {
    state.dragMode = dragMode;
  },
  SET_DRAG_COMP: (state, dragComp) => {
    state.dragComp = dragComp;
  }
};

// mutations 호출 및 비동기 처리
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
