<template>
  <div class="cr-flow-appbar">
    <TasksAppbar v-if="showTasksAppbar" />
    <DefaultAppbar v-else />
  </div>
</template>

<style lang="scss" scoped>
.cr-flow-appbar {
  display: flex;
  flex-grow: 20;
}
</style>

<script>
import TasksAppbar from "./tasks";
import DefaultAppbar from "./DefaultAppbar.vue";

export default {
  components: { TasksAppbar, DefaultAppbar },
  computed: {
    showTasksAppbar() {
      return this.$route.name?.includes("flow_tasks");
    }
  }
};
</script>
