import moment from "moment";

const setLocale = localeStr => {
  moment.locale(localeStr);
};

const getFullDate = timeMillis => {
  const date = moment.unix(timeMillis / 1000);
  return date.format("YYYY.MM.DD (dd) HH:mm");
};

const getDateWithoutTime = (timeMillis, format = "YYYY.MM.DD") => {
  if (!timeMillis) return "";
  return moment.unix(timeMillis / 1000).format(format);
};

const getDateWithTime = timeMillis =>
  moment.unix(timeMillis / 1000).format("YYYY.MM.DD HH:mm");

const getDateWithTimeSecond = timeMillis =>
  moment.unix(timeMillis / 1000).format("YYYY.MM.DD HH:mm:ss");

const getDateWithDayOfWeek = timeMillis =>
  moment.unix(timeMillis / 1000).format("YYYY.MM.DD dddd");

const getAmPm = timeMillis => moment.unix(timeMillis / 1000).format("A");
const getHours = timeMillis => moment.unix(timeMillis / 1000).format("HH");
const getMinutes = timeMillis => moment.unix(timeMillis / 1000).format("mm");
const getMonthAndDate = timeMillis =>
  moment.unix(timeMillis / 1000).format("MM월 DD");
const getMonthAndDateLowerCase = timeMillis =>
  moment.unix(timeMillis / 1000).format("M. D");

// 현재날짜와 차이 (단위 : 일)
const convertLastChangeDate = updatedTimeMillis => {
  const today = new Date();
  const recentUpdated = new Date(updatedTimeMillis);

  return Math.floor(
    (today.getTime() - recentUpdated.getTime()) / (1000 * 3600 * 24)
  );
};

const timezone = [
  {
    title: "한국, 서울(GMT+09:00)",
    value: "Asia/Seoul"
  }
];

export {
  getFullDate,
  getDateWithTime,
  getDateWithTimeSecond,
  getDateWithDayOfWeek,
  getDateWithoutTime,
  setLocale,
  convertLastChangeDate,
  getAmPm,
  getHours,
  getMinutes,
  getMonthAndDate,
  getMonthAndDateLowerCase,
  timezone
};
