<template>
  <div class="cr-autocomplete-wrapper" :data-vnodeKey="$vnode.key">
    <div
      v-if="showPlaceholder"
      class="cr-autocomplete-placeholder"
      v-text="placeholder"
    />
    <!-- 입력창 -->
    <v-text-field
      dense
      outlined
      hide-details
      autocomplete="off"
      :id="`textField_${$vnode.key}`"
      ref="textField"
      class="cr-autocomplete-input"
      v-model="value"
      @click="chipSelect = []"
      @keydown.stop="inputKeydown"
      @keyup.stop="inputKeyup"
      @input="inputHandler"
      @blur="inputBlur"
    >
      <!-- 이메일주소 선택시 선택chip -->
      <template v-if="useChips" slot="prepend-inner">
        <v-chip-group
          column
          multiple
          active-class="grey--text text--darken-2"
          v-model="chipSelect"
        >
          <Chip
            v-for="(item, index) in selections"
            :ref="`chipRefs`"
            :key="index"
            :value.sync="item.value"
            :chipSelect.sync="chipSelect"
            :valid="item.valid"
            :itemKey="item.key"
            :index="index"
            :directInput="directInput"
            :pressedControl="pressedControl"
            :pressedShift="pressedShift"
            :crDraggable="crDraggable"
            @inputFocus="inputFocus"
            @chipModify="chipModify"
            @chipClose="chipClose"
          />
        </v-chip-group>
      </template>
    </v-text-field>
    <!-- input width 계산용 -->
    <div style="position:absolute;top:-1000px;left:-1000px;">
      <span :id="`${$vnode.key}InputMeasure`"></span>
    </div>
    <!-- 자동완성 검색 결과 -->
    <div v-show="showList" class="cr-autocomplete-list-wrapper">
      <v-list
        dense
        outlined
        ref="listRef"
        class="cr-autocomplete-list"
        :style="`max-height: ${listHeight}px`"
      >
        <v-list-item-group color="primary" v-model="listItemSelect">
          <v-list-item
            v-for="(item, idx) in listItems"
            :key="idx"
            @click="
              () =>
                addSelection(
                  item,
                  `${item.name && `&quot;${item.name}&quot; `}<${item.email}>`
                )
            "
          >
            <v-list-item-title>
              {{ getListItem(item) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- 
            페이징
            page : 현재페이지
            pageSize : 한페이지당 아이템 수
            blockSize : 페이지 한 블럭당 갯수
            totalElements : 아이템 총 갯수
            pageClick : 페이지 이동시 라우팅 이벤트
           -->
      <Pagination
        v-if="totalPages > 1"
        type="autocomplete"
        :page.sync="page"
        :pageSize="10"
        :blockSize="blockSize"
        :totalElements="totalElements"
        @pageClick="pageClick"
      />
    </div>
    <!-- chip 수정 텍스트 필드 -->
    <v-text-field
      dense
      outlined
      hide-details
      autocomplete="off"
      ref="chipTextField"
      class="cr-chipTextField"
      :style="chipInputStyle"
      v-model="chipInputValue"
      @blur="chipInputBlur"
      @keydown.enter="chipInputBlur"
      @keydown.esc="chipInputEsc"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getEmails } from "@/commons/api/autocomplete.api";
import { isEmail, isEmpty } from "@/commons/utils/validation";
import Chip from "./Chip";
import Pagination from "@/commons/views/Pagination";

let blurTimeout;
let timeout;

export default {
  props: {
    typeList: {
      default: () => [
        "MEMBER",
        "RECENT",
        "ORGAN",
        "CONTACT",
        "GRADE",
        "TITLE",
        "CUSTOMER"
      ],
      type: Array
    },
    selection: {
      default: () => [],
      type: [Array, Object]
    },
    listHeight: {
      default: 300,
      type: Number
    },
    useChips: {
      default: true,
      type: Boolean
    },
    directInput: {
      default: true,
      type: Boolean,
      description: "직접입력 여부 (false일 경우 자동완성된 값만 가능)"
    },
    rsetData: {
      default: false,
      type: Boolean,
      description: "data "
    },
    crDraggable: {
      default: false,
      type: Boolean
    },
    excludeLoginUser: {
      default: false,
      type: Boolean,
      description: "로그인 사용자 제외 여부"
    },
    placeholder: {
      default: "",
      type: String,
      description: "placeholder 텍스트"
    }
  },

  components: { Chip, Pagination },
  data: () => ({
    inputTimeout: null,

    keydown: false,

    loading: false,
    pressedControl: false,
    pressedShift: false,
    pressedArrowLeft: false,
    pressedArrowRight: false,
    inputValue: "",

    listItemSelect: 0,
    listItems: [],
    page: 1,
    blockSize: 5,
    totalPages: 1,
    totalElements: 0,

    chipSelect: [],
    chipInputValue: "",
    chipInputStyle: "display: none"
  }),
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    value: {
      get() {
        return this.inputValue;
      },
      set(value) {
        // 2byte 문자버그로 인해 텍스트 입력 후 페이지 클릭시 두번탐 -> keydown이벤트로 감지
        if (!this.keydown) return;
        this.inputValue = value;
      }
    },
    showList() {
      return this.listItems.length > 0;
    },
    selections() {
      if (Array.isArray(this.selection)) return this.selection;
      if (Object.keys(this.selection).length == 0) return [];
      return [this.selection];
    },
    showPlaceholder() {
      if (!this.placeholder) return false;
      if (this.inputValue) return false;
      if (this.useChips && this.selections.length > 0) return false;
      return true;
    }
  },
  watch: {
    listItemSelect(listItemSelect) {
      if (listItemSelect == undefined) {
        setTimeout(() => {
          this.listItemSelect = 0;
        }, 0);
      }
    },
    inputValue(inputValue) {
      this.inputResize(inputValue);
      this.listItemSelect = undefined;
      this.page = 1;
      this.getAutocompleteList(inputValue);
    },
    chipSelect(chipSelect) {
      const { length: chipSelectLength } = chipSelect;

      // 선택한 chip이 한개일때 좌우 상태값 초기화
      if (chipSelectLength == 1) {
        this.pressedArrowLeft = false;
        this.pressedArrowRight = false;
      }
    }
  },
  methods: {
    inputHandler(value) {
      clearTimeout(this.inputTimeout);

      const newValue = value;
      this.inputTimeout = setTimeout(() => {
        this.processInput(newValue);
      }, 200);
    },
    processInput(newValue) {
      const array = newValue.split(/[\t\r\n,;]+/);
      if (!array || array.length === 0) return;
      if (array.length === 1) {
        this.inputValue = newValue;
        return;
      }

      for (let i = 0; i < array.length; i++) {
        const v = (array[i] || "").replace("　", "").trim();
        if (!v) continue;

        setTimeout(() => {
          this.addSelection({}, v);
        }, 0);
      }
    },
    // 검색결과
    getListItem({ email, name, jobTitle, jobGrade, organization, type }) {
      const typeName = {
        MEMBER: this.$t("common.조직도"),
        RECENT: this.$t("common.최근"),
        ORGAN: this.$t("common.부서"),
        CONTACT: this.$t("common.주소록"),
        GRADE: this.$t("account.직급"),
        TITLE: this.$t("account.직책"),
        CUSTOMER: this.$t("common.공용주소록")
      };

      let data = `${typeName[type]}: ${name && `"${name}"`} <${email}>`;
      if (organization) {
        data += ` ${organization}`;
      }
      if (jobGrade) {
        data += ` / ${jobGrade}`;
      }
      if (jobTitle) {
        data += ` / ${jobTitle}`;
      }
      return data;
    },
    // 자동완성 목록 가져오기
    getAutocompleteList(inputValue) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      if (!inputValue) {
        this.listItems = [];
        this.loading = false;
        return;
      }

      this.loading = true;
      timeout = setTimeout(() => {
        this.requestAutoComplete(inputValue);
      }, 200);
    },
    // 서버로 요청
    async requestAutoComplete(inputValue) {
      const { data } = await getEmails({
        page: this.page - 1,
        query: inputValue,
        typeList: this.typeList
      });
      const { content, totalPages, totalElements } = data;

      this.listItems = content.filter(
        u => !this.excludeLoginUser || u.userId !== this.getUserInfo.id
      );
      this.totalPages = totalPages;
      this.totalElements = totalElements;
      this.loading = false;
      this.keydown = false;
      this.inputFocus();
    },
    // 인풋창 포커스
    inputFocus() {
      setTimeout(() => {
        this.$refs.textField.focus();
      }, 0);
    },
    // 인풋창 크기조절
    inputResize(inputValue) {
      const input = document.getElementById(`textField_${this.$vnode.key}`);
      const measure = document.getElementById(`${this.$vnode.key}InputMeasure`);
      const textFieldEl = this.$refs.textField.$el;
      const textFieldWidth = textFieldEl.clientWidth || textFieldEl.offsetWidth;
      const inputMaxWidth = textFieldWidth - 24;

      if (measure.offsetWidth >= inputMaxWidth) {
        if (input.style.width != inputMaxWidth) {
          input.style.width = inputMaxWidth + "px";
        }

        if (!inputValue) {
          measure.innerText = inputValue;
          input.style.width = "60px";
        }
        return;
      }
      measure.innerText = inputValue;
      const measureWidth = measure.offsetWidth + 60;

      input.style.width =
        (measureWidth >= inputMaxWidth ? inputMaxWidth : measureWidth) + "px";
    },
    deleteSelectionObj() {
      this.$emit("update:selection", {});
    },
    chipClose(index) {
      if (Array.isArray(this.selection)) {
        const { key } = this.selection[index];
        const filteredSelection = this.selection.filter(v => v.key != key);
        this.$emit("update:selection", filteredSelection);
      }
    },
    inputKeydown(e) {
      this.keydown = true;
      const maxIndex = this.listItems.length - 1;
      const maxChipIndex = this.selections.length - 1;

      switch (e.keyCode) {
        // ArrowLeft
        case 37: {
          if (maxChipIndex == -1 || this.inputValue.length != 0) break;

          let [chipSelect = undefined] = this.chipSelect;
          if (chipSelect == undefined) {
            this.chipSelect = [maxChipIndex];
            break;
          }

          if (chipSelect == 0) break;
          chipSelect -= 1;

          if (this.pressedShift && this.pressedArrowRight) {
            this.chipSelect.shift();
            break;
          }

          if (this.pressedShift) {
            this.pressedArrowLeft = true;
            if (this.chipSelect.findIndex(i => i == chipSelect) == -1) {
              this.chipSelect.unshift(chipSelect);
            }
            break;
          }

          this.chipSelect = [chipSelect];
          break;
        }
        // ArrowRight
        case 39: {
          if (maxChipIndex == -1 || this.inputValue.length != 0) break;

          let [chipSelect = undefined] = this.chipSelect;
          if (chipSelect == undefined) break;

          chipSelect += 1;
          if (!this.pressedShift) {
            this.chipSelect = chipSelect > maxChipIndex ? [] : [chipSelect];
            break;
          }

          if (chipSelect > maxChipIndex) break;
          if (this.pressedArrowLeft) {
            this.chipSelect.shift();
          } else {
            this.pressedArrowRight = true;
            this.chipSelect.unshift(chipSelect);
          }
          break;
        }
        // ArrowUp
        case 38: {
          e.preventDefault();
          if (!this.showList) return;

          const {
            offsetHeight: itemHeight
          } = this.$refs.listRef.$el.querySelector(".v-list-item");
          const {
            scrollHeight,
            offsetHeight,
            scrollTop
          } = this.$refs.listRef.$el;
          const maxScrollTop = scrollHeight - offsetHeight;

          if (
            Math.abs(scrollTop - itemHeight * this.listItemSelect) <
              itemHeight ||
            this.listItemSelect == 0
          ) {
            this.$refs.listRef.$el.scrollTop -= itemHeight;
          }

          this.listItemSelect = this.listItemSelect - 1;

          if (this.listItemSelect < 0) {
            this.listItemSelect = maxIndex;
            this.$refs.listRef.$el.scrollTop = maxScrollTop;
          }
          break;
        }
        // ArrowDown
        case 40: {
          e.preventDefault();
          if (!this.showList) return;

          // scroll 이동
          const { offsetHeight } = this.$refs.listRef.$el;
          const {
            offsetHeight: itemHeight
          } = this.$refs.listRef.$el.querySelector(".v-list-item");

          if (this.listItemSelect + 1 > maxIndex) {
            this.listItemSelect = 0;
            this.$refs.listRef.$el.scrollTop = 0;
          } else {
            this.listItemSelect = this.listItemSelect + 1;
          }

          // 개수라서 +1
          if (itemHeight * (this.listItemSelect + 1) > offsetHeight) {
            this.$refs.listRef.$el.scrollTop += this.$refs.listRef.$el.querySelector(
              ".v-list-item"
            ).offsetHeight;
          }

          break;
        }
        // Space 32 Enter 13
        case 32:
        case 13: {
          e.preventDefault();
          clearTimeout(this.inputTimeout);

          if (this.loading) return;
          let item = {};
          let value = this.inputValue;
          // 자동완성 목록에서
          if (this.listItems.length > 0 && this.listItemSelect != undefined) {
            item = this.listItems[this.listItemSelect];
            value = `${item.name && `"${item.name}" `}<${item.email}>`;
          }

          if (value) return this.addSelection(item, value);
          break;
        }
        // Delete
        case 46: {
          if (maxChipIndex == -1 || this.inputValue.length != 0) break;

          if (Array.isArray(this.selection)) {
            const selectionKeys = [];
            this.chipSelect.forEach(i => {
              selectionKeys.push(this.selections[i].key);
            });
            const filteredSelections = this.selections.filter(
              v => selectionKeys.indexOf(v.key) == -1
            );
            this.$emit("update:selection", filteredSelections);
            this.chipSelect = [];
          }

          this.inputFocus();
          break;
        }
        // Backspace
        case 8: {
          if (maxChipIndex == -1 || this.inputValue.length != 0) break;
          if (this.chipSelect.length == 0) {
            this.chipSelect = [maxChipIndex];
            break;
          }

          if (Array.isArray(this.selection)) {
            const selectionKeys = [];
            this.chipSelect.forEach(i => {
              selectionKeys.push(this.selections[i].key);
            });
            const filteredSelections = this.selections.filter(
              v => selectionKeys.indexOf(v.key) == -1
            );
            this.$emit("update:selection", filteredSelections);
            this.chipSelect = [];
          }

          this.inputFocus();
          break;
        }
        // Shift
        case 16:
          this.keydown = false;
          this.pressedShift = true;
          break;
        // Control
        case 17:
          this.keydown = false;
          this.pressedControl = true;
          break;
        // Escape
        case 27:
          if (this.listItems.length) {
            this.listItems = [];
            break;
          }

          this.$emit("keydown:esc");
          break;
        // F2
        case 113:
          if (this.chipSelect.length == 1 && this.directInput) {
            this.$refs.chipRefs[this.chipSelect[0]].chipEditClick();
          }
          break;
        default:
          this.chipSelect = [];
          break;
      }
    },
    inputKeyup(e) {
      if (e.key == "Shift") this.pressedShift = false;
      if (e.key == "Control") this.pressedControl = false;
    },
    inputBlur() {
      clearTimeout(this.inputTimeout);

      if (this.inputValue) {
        blurTimeout = setTimeout(() => {
          this.addSelection({}, this.inputValue, true);
        }, 200);
      }
    },

    //구분자 체크 메서드
    checkSeparators(key, value) {
      if (!value) return [];

      const regex = /"([^"]*)"\s*<([^>]*)>/;
      const matches = value.match(regex);

      if (matches) {
        // 직접 "이름"<이메일>로 입력할 경우
        const name = matches[1];
        const email = matches[2];
        return [
          {
            key,
            value: `"${name}" <${email}>`,
            valid: isEmail(email)
          }
        ];
      } else {
        // 복사/붙여넣기의 경우
        return `${value}`
          .trim()
          .replace(/,/gi, ";")
          .replace(/\s/g, ";")
          .split(";")
          ?.filter(v => !isEmpty(v.trim()))
          ?.map((v, idx) => ({
            key: key + idx,
            value: v.trim(),
            valid: isEmail(v.trim())
          }));
      }
    },
    addSelection(item, value, blur) {
      if (blurTimeout) {
        clearTimeout(blurTimeout);
        blurTimeout = null;
      }

      const isEmptyItem = Object.keys(item).length == 0;
      // 선택된 자동완성 정보가없을때 직접입력 false일 경우 리턴
      if (!this.directInput && isEmptyItem) {
        this.inputValue = "";
        this.listItemSelect = 0;
        this.resetListState();
        if (!blur) this.inputFocus();
        return;
      }

      const valid = isEmail(value);
      // selection이 object일때
      if (!Array.isArray(this.selection)) {
        this.$emit("update:selection", { valid, value, ...item });
      } else {
        // 마지막 키값
        const { key: lastKey } = this.selection.at(-1) || { key: 0 };
        // 아이템 선택
        let selections = [{ key: lastKey + 1, valid, value, ...item }];
        // 직접 입력
        if (isEmptyItem) selections = this.checkSeparators(lastKey + 1, value);

        this.$emit("update:selection", [...this.selection, ...selections]);
      }

      const { key: vnodeKey } = this.$vnode;
      document.getElementById(`textField_${vnodeKey}`).style.width = "60px";
      this.inputValue = "";
      this.listItemSelect = 0;
      this.resetListState();
      if (!blur) this.inputFocus();
    },

    chipModify(e, el, index) {
      e.stopPropagation();

      const { value } = this.selections[index];
      this.chipSelect = [index];
      this.chipInputValue = value;
      this.chipInputStyle = `display: block; left: ${el.offsetLeft}px;
        top: ${el.offsetTop + 1}px; width: ${el.offsetWidth}px;`;

      setTimeout(() => {
        this.$refs.chipTextField.focus();
      }, 0);
    },
    chipInputBlur() {
      const [index] = this.chipSelect;
      this.selections[index] = {
        ...this.selections[index],
        value: this.chipInputValue,
        valid: isEmail(this.chipInputValue)
      };

      if (Array.isArray(this.selection)) {
        this.$emit("update:selection", [...this.selections]);
      } else {
        this.$emit("update:selection", { ...this.selections[index] });
      }

      this.chipInputStyle = "display: none";
      this.inputFocus();
    },
    chipInputEsc() {
      this.chipInputStyle = "display: none";
      this.inputFocus();
    },
    pageClick() {
      if (blurTimeout) {
        clearTimeout(blurTimeout);
        blurTimeout = null;
      }

      this.loading = true;
      // 검색결과 선택과 스크롤 초기화
      this.resetListState();
      this.requestAutoComplete(this.inputValue);
    },
    resetListState() {
      // 검색결과 선택과 스크롤 초기화
      this.listItemSelect = 0;
      this.$refs.listRef.$el.scrollTop = 0;
    }
  }
};
</script>

<style scoped>
.cr-autocomplete-wrapper {
  position: relative;
}
.cr-autocomplete-placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #b6b6b6;
  font-weight: lighter;
}

/* 텍스트 필드 */
.v-input.cr-autocomplete-input >>> .v-input__control .v-input__slot,
.v-input.cr-autocomplete-input >>> .v-input__control .v-text-field__slot {
  display: inline-block;
  padding: 0px;
}
/* 텍스트 필드 input 크기 조절 */
.v-input.cr-autocomplete-input
  >>> .v-input__control
  .v-text-field__slot
  > input {
  max-height: 40px;
  padding: 10px 5px;
}
/* chip */
.v-input.cr-autocomplete-input >>> .v-input__control .v-input__prepend-inner,
.v-input.cr-autocomplete-input >>> .v-input__control .v-item-group,
.v-input.cr-autocomplete-input >>> .v-input__control .v-slide-group__wrapper,
.v-input.cr-autocomplete-input >>> .v-input__control .v-slide-group__content {
  display: inline;
}
/* input border 색 */
.v-input.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  >>> .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.22);
}
/* input append inner 위치 (오른쪽 화살표) */
.v-input.cr-autocomplete-input >>> .v-input__control .v-input__append-inner {
  position: absolute;
  right: 12px;
  bottom: 8px;
}
/* input append inner 버튼 크기 (오른쪽 화살표) */
.v-input.cr-autocomplete-input
  >>> .v-input__control
  .v-input__append-inner
  > .v-btn {
  padding: 0px;
  min-width: 24px;
  width: 24px;
  height: 24px;
}
/* 자동완성 목록 */
.cr-autocomplete-list-wrapper {
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #fff;
}
.cr-autocomplete-list {
  height: 100%;
  overflow-y: auto;
}
.cr-autocomplete-list-pagination {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
}
/* chip 수정 필드 */
.cr-chipTextField {
  position: absolute;
  font-size: 14px;
  background-color: #fff;
}
.v-input.cr-chipTextField >>> .v-input__control {
  height: 40px;
}
.v-input.cr-chipTextField
  >>> .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  padding: 2px 0 2px;
}
</style>
