<template>
  <div
    class="icon-field-container"
    :style="{ 'min-height': sizeInfo.minHeight }"
  >
    <div
      class="field-icon"
      :style="{
        width: sizeInfo.fieldWidth,
        'max-height': sizeInfo.fieldMaxHeight
      }"
    >
      <slot name="icon">
        <v-icon v-bind="$attrs" :class="{ 'blank-icon': !icon }">
          {{ icon }}
        </v-icon>
      </slot>
    </div>
    <div class="flex-fill">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    spacing: {
      type: String,
      default: "md"
    }
  },
  computed: {
    sizeInfo() {
      switch (this.spacing) {
        case "xs":
          return {
            minHeight: "20px",
            fieldWidth: "28px",
            fieldMaxHeight: "40px"
          };
        case "sm":
          return {
            minHeight: "24px",
            fieldWidth: "32px",
            fieldMaxHeight: "44px"
          };
        case "dense":
          return {
            minHeight: "28px",
            fieldWidth: "36px",
            fieldMaxHeight: "48px"
          };
        case "lg":
          return {
            minHeight: "36px",
            fieldWidth: "44px",
            fieldMaxHeight: "56px"
          };
        case "md":
        default:
          return {
            minHeight: "32px",
            fieldWidth: "40px",
            fieldMaxHeight: "52px"
          };
      }
    }
  }
};
</script>

<style scoped lang="scss">
.icon-field-container {
  display: flex;
  align-items: center;
  width: 100%;

  .field-icon {
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  .blank-icon {
    visibility: hidden;
  }
}
</style>
