import { searchPost, searchReply, searchFile } from "@/board/api/board.api";
import router from "@/commons/router";
import Vue from "vue";

const state = {
  searchParam: {
    type: "",
    query: {}
  },
  postList: {
    loading: false,
    list: [],
    totalElements: 0,
    page: 0,
    totalPages: 0,
    last: true
  },
  replyList: {
    loading: false,
    list: [],
    totalElements: 0,
    page: 0,
    totalPages: 0,
    last: true
  },
  fileList: {
    loading: false,
    list: [],
    totalElements: 0,
    page: 0,
    totalPages: 0,
    last: true
  }
};
const getters = {
  getPostSearchList: ({ postList }) => postList,
  getReplySearchList: ({ replyList }) => replyList,
  getFileSearchList: ({ fileList }) => fileList,
  getSearchParam: ({ searchParam }) => searchParam
};
const mutations = {
  INIT_SEARCH: state => {
    state.searchParam = {
      type: "",
      query: {}
    };
    state.postList = {
      list: [],
      totalElements: 0,
      page: 0,
      totalPages: 0,
      last: true
    };
    state.replyList = {
      list: [],
      totalElements: 0,
      page: 0,
      totalPages: 0,
      last: true
    };
    state.fileList = {
      list: [],
      totalElements: 0,
      page: 0,
      totalPages: 0,
      last: true
    };
  },
  SET_POST_SEARCH_LIST: (state, data) => {
    const {
      content: list,
      number: page,
      totalElements,
      totalPages,
      last
    } = data;
    state.postList = { list, page, totalElements, totalPages, last };
  },
  SET_REPLY_SEARCH_LIST: (state, data) => {
    const {
      content: list,
      number: page,
      totalElements,
      totalPages,
      last
    } = data;
    state.replyList = { list, page, totalElements, totalPages, last };
  },
  SET_FILE_SEARCH_LIST: (state, data) => {
    const {
      content: list,
      number: page,
      totalElements,
      totalPages,
      last
    } = data;
    state.fileList = { list, page, totalElements, totalPages, last };
  },
  SET_SEARCH_PARAM: (state, param) => {
    state.searchParam = param;
  },
  SET_POST_SEARCH_LOADING: (state, loading) => {
    Vue.set(state.postList, "loading", loading);
  },
  SET_REPLY_SEARCH_LOADING: (state, loading) => {
    Vue.set(state.replyList, "loading", loading);
  },
  SET_FILE_SEARCH_LOADING: (state, loading) => {
    Vue.set(state.fileList, "loading", loading);
  }
};
const actions = {
  // 게시글 검색
  async searchPostList({ commit }, params) {
    commit("SET_POST_SEARCH_LOADING", true);
    const { status, data } = await searchPost(params);
    if (status === 200) {
      commit("SET_POST_SEARCH_LIST", data);
    }
    commit("SET_POST_SEARCH_LOADING", false);
  },
  // 댓글 검색
  async searchReplyList({ commit }, params) {
    commit("SET_REPLY_SEARCH_LOADING", true);
    const { status, data } = await searchReply(params);
    if (status === 200) {
      commit("SET_REPLY_SEARCH_LIST", data);
    }
    commit("SET_REPLY_SEARCH_LOADING", false);
  },
  // 첨부파일 검색
  async searchFileList({ commit }, params) {
    commit("SET_FILE_SEARCH_LOADING", true);
    const { status, data } = await searchFile(params);
    if (status === 200) {
      commit("SET_FILE_SEARCH_LIST", data);
    }
    commit("SET_FILE_SEARCH_LOADING", false);
  },
  // [임시] 검색페이지에서 게시글 변동 있을 경우
  async refreshPostList({ commit, getters }) {
    commit("SET_POST_SEARCH_LOADING", true);
    commit("SET_REPLY_SEARCH_LOADING", true);
    commit("SET_FILE_SEARCH_LOADING", true);
    const searchParam = getters.getSearchParam;
    // 삭제여부 색인 텀 대기
    setTimeout(() => {
      router.push({
        name: "board_search",
        params: {
          type: searchParam.type,
          query: JSON.stringify(searchParam.query)
        }
      });
    }, 1500);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
