<template>
  <div class="mt-3">
    <v-textarea
      :label="getSelected.placeholder"
      filled
      :counter="getMaxLength"
      rows="9"
      no-resize
      v-model="prompt"
      :rules="rules"
    ></v-textarea>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      rules: [this.getMaxLength === 0, v => v.length <= this.getMaxLength]
    };
  },
  computed: {
    ...mapGetters("ai", ["getQuestion", "getMaxLength", "getSelected"]),
    prompt: {
      get() {
        return this.getQuestion;
      },
      set(value) {
        this.SET_QUESTION(value);
      }
    }
  },
  methods: {
    ...mapMutations("ai", ["SET_QUESTION"])
  }
};
</script>
