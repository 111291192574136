<template>
  <v-list v-if="items.length > 0">
    <v-list-item-group
      v-model="selectIndex"
      :mandatory="selectIndex > -1"
      color="primary"
    >
      <v-list-item v-for="(item, idx) in items" :key="idx">
        <!-- 아이템 아이콘 -->
        <v-list-item-icon class="mr-2">
          <v-icon
            class="ml-3 mr-2"
            v-show="item.id != item.groupId && !isSearching"
            v-text="'mdi-subdirectory-arrow-right'"
          ></v-icon>
          <v-icon v-text="getIcon(item, idx)"></v-icon>
        </v-list-item-icon>
        <!-- 아이템 이름 -->
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
  <v-list class="cr-no-data" v-else @click.stop>
    <div v-text="$t('board.39')"></div>
  </v-list>
</template>

<script>
export default {
  mounted() {
    this.setSelectIndex();
  },
  data: () => ({
    selectIndex: -1,
    isSearching: false
  }),
  props: {
    inputValue: {
      default: "",
      type: String
    },
    openMenu: {
      default: false,
      type: Boolean
    },
    initItems: {
      description: "초기 아이템"
    },
    selectedItem: {
      type: Object,
      description: "선택된 아이템"
    }
  },
  watch: {
    selectIndex(next, prev) {
      if (next == -1) return;
      if (next == undefined) this.selectIndex = prev;
      this.$emit("update:selectedItem", this.items[this.selectIndex]);
    },
    // 검색 시 게시판 리스트는 하위표시 아이콘을 지운다.
    inputValue(value) {
      this.isSearching = value ? true : false;
    },
    openMenu(value) {
      if (value) {
        this.setSelectIndex();
      } else {
        this.selectIndex = -1;
      }
    },
    items() {
      this.setSelectIndex();
    }
  },
  computed: {
    items() {
      if (this.inputValue) {
        return this.initItems.filter(item =>
          item.name
            .toLocaleLowerCase()
            .includes(this.inputValue.toLocaleLowerCase())
        );
      }
      return this.initItems;
    }
  },
  methods: {
    // 선택된게 있으면 active효과를 주기 위해 selectIndex를 변경해준다.
    setSelectIndex() {
      if (this.selectedItem.id) {
        const index = this.items.findIndex(
          item => item.id == this.selectedItem.id
        );
        this.selectIndex = index;
      }
    },
    getIcon(item, idx) {
      if (item.boardType != "BOARD") return item.icon;

      let iconName = "mdi";
      let nameEl = ["folder"];

      if (item.isDeleted) {
        nameEl.push("remove"); // alert, off, remove, clock, cancel
      } else if (item.memberType === "MEMBER") {
        nameEl.push("lock"); // eye, lock, table, key, account
      }
      if (this.selectIndex !== idx) nameEl.push("outline");

      // 문자열 결합
      nameEl.forEach(char => {
        iconName += `-${char}`;
      });

      return iconName;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 56px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  overflow-y: auto;
}
.v-list.cr-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
