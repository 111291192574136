<template>
  <v-dialog
    :value="value"
    width="400"
    persistent
    @input="val => $emit('input', val)"
  >
    <v-card class="special-leave-dialog">
      <v-card-title>
        {{ $t("calendar.87") }}
      </v-card-title>
      <v-card-text class="pb-2">
        <v-container>
          <v-row align="center">
            <v-col cols="3">
              {{ $t("calendar.사유") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="reason"
                :placeholder="$t('calendar.92')"
                required
                autofocus
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="3" class="d-flex align-center">
              {{ $t("calendar.발생일") }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <ul>
                  <li>{{ $t("calendar.133") }}</li>
                  <li>{{ $t("calendar.134") }}</li>
                </ul>
              </v-tooltip>
            </v-col>
            <v-col cols="9">
              <FilledDateTimePicker
                :date.sync="creationDate"
                @update:date="adjustDateRange('end')"
              />
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="3" class="d-flex align-center">
              {{ $t("calendar.만료일") }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="ml-1">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <ul>
                  <li>{{ $t("calendar.136") }}</li>
                  <li>{{ $t("calendar.137") }}</li>
                </ul>
              </v-tooltip>
            </v-col>
            <v-col cols="9">
              <FilledDateTimePicker
                :date.sync="expiryDate"
                @update:date="adjustDateRange('start')"
              />
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="3">
              {{ $t("calendar.신청일수") }}
            </v-col>
            <v-col cols="9">
              <FlatTextField
                v-model="amount"
                class="application-amount"
                style="width: 80px"
                type="number"
                min="0.25"
                step="0.25"
                required
              />
            </v-col>
          </v-row>
          <div v-if="isExpiryDatePast" class="mt-4 text-center warning--text">
            {{ $t("calendar.139") }}
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="closeDialog">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn
          v-if="specialLeaveToUpdate"
          outlined
          color="accent"
          @click="updateSpecialLeave"
        >
          {{ $t("common.수정") }}
        </v-btn>
        <v-btn v-else outlined color="accent" @click="saveSpecialLeave">
          {{ $t("calendar.신청") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { saveSpecialLeave, updateSpecialLeave } from "@/calendar/api/leave.api";
import { mapActions, mapMutations } from "vuex";
import FilledDateTimePicker from "@/calendar/components/common/FilledDateTimePicker.vue";
import FlatTextField from "@/calendar/components/common/FlatTextField.vue";
import moment from "moment";

export default {
  components: { FlatTextField, FilledDateTimePicker },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    specialLeaveToUpdate: {
      type: Object
    }
  },
  data() {
    return {
      id: null,
      reason: "",
      amount: 1,
      creationDate: moment().format("YYYY-MM-DD"),
      expiryDate: moment()
        .add(1, "month")
        .format("YYYY-MM-DD"),
      isExpiryDatePast: false
    };
  },
  created() {
    if (this.specialLeaveToUpdate) {
      this.id = this.specialLeaveToUpdate.id;
      this.reason = this.specialLeaveToUpdate.reason;
      this.amount = this.specialLeaveToUpdate.amount;
      this.creationDate = this.specialLeaveToUpdate.createdDate;
      this.expiryDate = this.specialLeaveToUpdate.expiredDate;
    }
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapMutations("cal", ["SHOW_LOADING_OVERLAY", "HIDE_LOADING_OVERLAY"]),
    closeDialog() {
      this.$emit("input", false);
    },
    confirmDialog() {
      this.$emit("confirm");
      this.closeDialog();
    },
    adjustDateRange(adjustTarget) {
      const start = moment(this.creationDate, "YYYY-MM-DD");
      const end = moment(this.expiryDate, "YYYY-MM-DD");
      this.isExpiryDatePast = end.isBefore(moment(), "day");

      if (end.isAfter(start)) return;

      if (adjustTarget === "start") {
        this.creationDate = end.subtract(1, "day").format("YYYY-MM-DD");
      }

      if (adjustTarget === "end") {
        this.expiryDate = start.add(1, "day").format("YYYY-MM-DD");
      }
    },
    validate() {
      if (!this.reason) {
        this.openSnackbar({
          message: this.$t("calendar.94"),
          type: "ERROR"
        });
        return false;
      }

      if (!this.creationDate || !this.expiryDate) {
        this.openSnackbar({
          message: this.$t("calendar.135"),
          type: "ERROR"
        });
        return false;
      }

      return true;
    },
    async saveSpecialLeave() {
      if (!this.validate()) return;

      const param = {
        ownerId: this.userId,
        reason: this.reason,
        amount: this.amount,
        createdDate: this.creationDate,
        expiredDate: this.expiryDate
      };

      this.SHOW_LOADING_OVERLAY();
      const response = await saveSpecialLeave(param);
      this.HIDE_LOADING_OVERLAY();

      const result = response.status === 201;
      this.openSnackbar({
        message: result
          ? this.$t("calendar.11")
          : response.status === 404
          ? this.$t("calendar.95")
          : this.$t("calendar.12"),
        type: result ? "SUCCESS" : "ERROR"
      });

      if (result) {
        this.confirmDialog();
      }
    },
    updateSpecialLeave() {
      if (!this.validate()) return;

      const param = {
        id: this.id,
        ownerId: this.userId,
        amount: this.amount,
        reason: this.reason,
        createdDate: this.creationDate,
        expiredDate: this.expiryDate
      };

      this.confirm({
        headline: "",
        message: this.$t("calendar.96"),
        callback: async () => {
          this.SHOW_LOADING_OVERLAY();
          const response = await updateSpecialLeave(param);
          this.HIDE_LOADING_OVERLAY();

          const result = response.status === 200;
          this.openSnackbar({
            message: result ? this.$t("calendar.11") : this.$t("calendar.97"),
            type: result ? "SUCCESS" : "ERROR"
          });

          if (result) {
            this.confirmDialog();
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.flat-text-field.application-amount ::v-deep input {
  text-align: right;
}
</style>
