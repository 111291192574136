import http from "@/commons/api/http";

const prefix = "api/todo/workspace";

/**
 * workspace 목록 조회
 */
const getWorkspaces = () => http.get(prefix);

/**
 * 워크스페이스 유저 조회
 * @param {*} id 워크스페이스 id
 */
const getWorkspaceUsers = id => http.get(`${prefix}/${id}/users`);

/**
 * workspace 생성
 * @param {*} title 워크스페이스 타이틀
 * @param {*} color 워크스페이스 색상
 * @param {*} emoji 워크스페이스 이모지
 */
const addWorkspace = params => {
  const formData = new FormData();
  Object.keys(params).forEach(key => formData.append(key, params[key]));

  return http.post(`${prefix}`, formData);
};

/**
 * 워크스페이스 삭제
 * @param {*} id 워크스페이스 id
 */
const deleteWorkspace = id => http.delete(`${prefix}/${id}`);

/**
 * workspace 수정
 * @param {*} id 워크스페이스 id
 * @param {*} title 워크스페이스 타이틀
 * @param {*} color 워크스페이스 색상
 * @param {*} emoji 워크스페이스 이모지
 * @param {*} useEmoji 워크스페이스 이모지 사용여부
 */
const updateWorkspace = (id, params) => {
  const formData = new FormData();
  Object.keys(params).forEach(key => formData.append(key, params[key]));

  return http.put(`${prefix}/${id}`, formData);
};

/**
 * workspace 소유자 변경
 * @param {*} id 워크스페이스 id
 * @param {*} userId 워크스페이스 위임받을 유저 id
 */
const delegateWorkspaceOwner = (id, userId) =>
  http.put(`${prefix}/${id}/owner/${userId}`);

/**
 * 워크스페이스 팔로우
 * @param {*} id 워크스페이스 id
 */
const followWorkspace = id => http.post(`${prefix}/${id}`);

/**
 * 워크스페이스 팔로우 취소
 * @param {*} id 워크스페이스 id
 */
const unfollowWorkspace = id => http.delete(`${prefix}/${id}/user`);

/**
 * 워크스페이스 유저 팔로우 취소
 * @param {*} id 워크스페이스 id
 * @param {*} userId 팔로우 끊을 유저 id
 */
const unfollowWorkspaceUser = (id, userId) =>
  http.delete(`${prefix}/${id}/user/${userId}`);

export {
  getWorkspaces,
  getWorkspaceUsers,
  addWorkspace,
  deleteWorkspace,
  updateWorkspace,
  delegateWorkspaceOwner,
  followWorkspace,
  unfollowWorkspace,
  unfollowWorkspaceUser
};
