<template>
  <v-treeview
    dense
    hoverable
    activatable
    transition
    shaped
    class="cr-tree-wrapper"
    item-key="value"
    item-text="title"
    :items="getTypes"
    :active.sync="activeItems"
    @update:active="onActive"
  >
    <template v-slot:prepend="{ item }">
      <v-icon> {{ setIcon(item.value) }} </v-icon>
    </template>
  </v-treeview>
</template>

<script>
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    tree: [],
    prevActiveItem: {},
    openItems: []
  }),
  created() {
    // Splash 닫기
    this.setShowSplash(false);
    this.typeIcons = this.getTypeIcons;
  },
  watch: {},
  computed: {
    ...mapGetters("resource", ["getTypes", "getCurrentDate", "getActiveItems"]),
    activeItems: {
      get() {
        return this.getActiveItems;
      },
      set(value) {
        if (value.length < 1) return;
        const type = value[0];
        const currentDate = this.getCurrentDate;
        this.$router.push({
          name: "resource_list",
          params: {
            date: currentDate,
            type: type
          }
        });
      }
    }
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"]),
    ...mapActions("resource", ["findTypeIcon"]),
    setIcon(itemType) {
      return RESOURCE_CONSTANTS.typeIcons[itemType];
    },
    onActive([n = {}]) {
      // 이미 선택된 메일함을 또 선택했을때 active해제방지
      if (!n.length) return (this.activeItems = [this.prevActiveItem]);
      this.prevActiveItem = n;
    }
  }
};
</script>
<style lang="scss" scoped>
.cr-tree-wrapper {
  overflow-y: auto;
  position: absolute;
  top: 52px;
  bottom: 124px;
  left: 0px;
  right: 8px;

  &.mobile {
    top: 60px;
    bottom: 60px;
  }
}

.v-treeview ::v-deep {
  /* 트리 노드 */
  > .v-treeview-node.v-treeview-node--shaped {
    /* 노드 높이 */
    .v-treeview-node__root {
      min-height: 42px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 1px;
      /* 텍스트 왼쪽 여백 */
      .v-treeview-node__content {
        cursor: pointer;
        margin-left: 0px;
      }
      .notranslate.v-treeview-node__toggle {
        z-index: 9999;
      }
    }
    /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
    .v-treeview-node__children {
      overflow: hidden;
      .v-treeview-node__level {
        width: 12px;
      }
    }
  }

  /* 텍스트 */
  .v-treeview-node__label {
    font-size: 14px;
  }
  .v-treeview-node--active .v-treeview-node__label {
    font-weight: bold;
  }
}
</style>
