<template>
  <!-- 주메뉴 drawer -->
  <v-navigation-drawer
    app
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <!-- 버튼 -->
    <div class="pa-2 d-flex" :class="$vuetify.breakpoint.lgAndUp ? 'pt-0' : ''">
      <v-btn
        large
        depressed
        width="118"
        class="mr-1 primary drawer-menu-button"
        @click="e => openCardDialog(e)"
      >
        <h4 v-text="$t('contact.12')"></h4>
      </v-btn>
      <v-btn
        large
        depressed
        width="118"
        class="v-btn-light drawer-menu-button cr-tutorial-contact-my"
        @click="e => openGroupDialog(e)"
      >
        <h4 v-text="$t('contact.82')"></h4>
      </v-btn>
    </div>

    <!-- 주소록 그룹 트리 -->
    <TreeView />
  </v-navigation-drawer>
</template>

<style scoped>
.cr-tree-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations } from "vuex";
import TreeView from "./treeview/TreeView";
import { privateContactGroupType } from "@/contact/constant/contactGroupType";

export default {
  components: {
    TreeView
  },
  data: () => ({
    drawer: true
  }),
  computed: {
    ...mapGetters("group", ["getAllRoot"])
  },
  methods: {
    ...mapMutations("contactDialog", ["SET_DIALOG"]),
    // 그룹 추가
    openGroupDialog(e) {
      e.stopPropagation();

      const parentContactGroup = this.getAllRoot[0];
      this.SET_DIALOG({
        title: i18n.t("contact.47"),
        type: "addContactGroup",
        params: {
          parentContactGroup
        },
        confirmCallbackFunc: async () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          const {
            contactGroupType,
            childCount,
            children,
            id
          } = parentContactGroup;

          if (
            privateContactGroupType[contactGroupType] &&
            childCount > 0 &&
            childCount !== children.length
          ) {
            await this.getContactGroupListByParentId({ parentId: id });
          }
        }
      });
    },
    // 개인 주소록 추가
    openCardDialog(e) {
      e.stopPropagation();
      this.SET_DIALOG({
        title: i18n.t("contact.12"),
        type: "addCard",
        showExpand: true
      });
    }
  }
};
</script>
