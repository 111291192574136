import http from "@/commons/api/http";

const prefix = "/api/share/link/mails";

/**
 * 링크 공유 조회
 */
const getLinkShare = mailId => http.get(`${prefix}/${mailId}`);

/**
 * 링크 공유 추가
 *
 * @param url
 * @param expiryDate
 * @param permission
 * @param useComment
 * @param members
 */
const addLinkShare = params => http.post(prefix, params);

/**
 * 링크 공유 수정
 *
 * @param id
 * @param url
 * @param expiryDate
 * @param permission
 * @param useComment
 * @param members
 */
const updateLinkShare = params => http.put(prefix, params);

/**
 * 링크 공유 삭제
 */
const deleteLinkShare = id => http.delete(`${prefix}/${id}`);

/**
 * 여기서부터 공유화면
 */

/**
 * 공유화면에서 메일 공유 정보 요청
 */
const availableUrl = encryptStr =>
  http.get(`${prefix}/viewer/available/url`, { params: { encryptStr } });

/**
 * 인증코드 요청
 */
const sendVerificationCode = (email, encryptStr) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("encryptStr", encryptStr);

  return http.post(`${prefix}/viewer/verification`, formData);
};

/**
 * 메일보기
 */
const getMailView = (email, encryptStr, verificationCode) =>
  http.get(`${prefix}/viewer/view`, {
    params: { email, encryptStr, verificationCode }
  });

export {
  getLinkShare,
  addLinkShare,
  updateLinkShare,
  deleteLinkShare,
  availableUrl,
  sendVerificationCode,
  getMailView
};
