<template>
  <div>
    <div class="px-2 py-0 d-flex">
      <v-btn
        large
        block
        depressed
        height="48"
        class="mr-1 primary"
        @click="addProject"
      >
        <h4 class="d-flex align-center">
          <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
          새 프로젝트
        </h4>
      </v-btn>
    </div>

    <TreeView />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import TreeView from "./treeview";

export default {
  components: { TreeView },
  methods: {
    ...mapMutations("flowDialog", ["SHOW_DIALOG"]),
    addProject() {
      this.SHOW_DIALOG({
        headline: "새 프로젝트 생성",
        type: "addProject",
        params: {}
      });
    }
  }
};
</script>
