var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-tree-wrapper",class:_vm.$vuetify.breakpoint.lgAndUp ? '' : 'mobile'},[_c('v-treeview',{attrs:{"dense":"","hoverable":"","activatable":"","transition":"","shaped":"","return-object":"","items":_vm.treeItems,"active":_vm.activeItems,"open":_vm.openItems},on:{"update:active":[function($event){_vm.activeItems=$event},_vm.onActive],"update:open":function($event){_vm.openItems=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.showChildCount > 0 || item.id == item.groupId ? '' : 'ml-3',attrs:{"color":_vm.getIconColor(item)},domProps:{"textContent":_vm._s(_vm.getIcon(item))}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:_vm.draggableClass(item),staticStyle:{"margin-top":"1px"},attrs:{"data-id":item.id}},[_c('div',{staticClass:"cr-tree_node_click_area",on:{"click":function (e) { return _vm.nodeClick(e, item); }}}),(!item.isEdit)?_c('span',{staticClass:"cr-tree-label-text"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.isEdit)?_c('v-text-field',{attrs:{"autofocus":"","label":item.name,"loading":_vm.editTextLoading},on:{"blur":function (e) { return _vm.editBoardName(e, item); },"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) { return _vm.editBoardName(e, item); }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function (e) { return _vm.editBtnClick(e, item); }).apply(null, arguments)}]}}):_vm._e(),(item.boardType === 'TRASH')?_c('v-btn',{staticClass:"tree-append-right cr-tutorial-board-trash",attrs:{"icon":"","small":""},on:{"click":function (e) { return _vm.cleanBtnClick(e, item); }}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e(),(item.boardType === 'TAG')?_c('v-btn',{staticClass:"tree-append-right",attrs:{"icon":"","small":""},on:{"click":function (e) { return _vm.openTagDialog(e); }}},[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e(),(
              hover &&
                !item.isEdit &&
                !_vm.editTextLoading &&
                item.boardType == 'BOARD' &&
                !item.isDeleted
            )?_c('v-btn',{staticClass:"tree-append-right",class:item.boardType == 'BOARD' ? 'root-context' : '',attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.openPositioningMenu(e, item); }).apply(null, arguments)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-dots-vertical')}})],1):_vm._e()],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }