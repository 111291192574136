import router from "@/commons/router";

const state = {
  isLoaded: false,
  customer_config: {
    show: false,
    tabType: "import",
    pageSize: 20
  },
  customer_list: {
    type: "CUSTOMER",
    groupId: null,
    page: 1,
    actionObj: {
      sort: "userName",
      dir: "desc"
    }
  },
  customer_print: {
    groupId: null,
    actionObj: {}
  }
};

const getters = {
  getRouteInfo: ({ customer_list }) => customer_list,
  getPrintCardListInfo: ({ customer_print }) => customer_print,
  getPage: ({ customer_list }) => parseInt(customer_list.page),
  getPageSize: ({ customer_config }) => customer_config.pageSize,
  getFirstPage: (state, getters, rootState) =>
    rootState.contactConfig.firstPage,
  getDefaultAction: state => {
    return {
      pageSize: state.customer_list.pageSize,
      sort: state.customer_list.actionObj.sort,
      dir: state.customer_list.actionObj.dir
    };
  },
  getCustomerConfig: ({ customer_config }) => customer_config.show,
  getConfigTabType: ({ customer_config }) => customer_config.tabType
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    const { type, groupId, page, actionObj } = params;
    state[params.routeName] = { type, groupId, page, actionObj };
  },
  SET_CONTACT_CONFIG: (state, contactConfig) => {
    state.customer_config.show = contactConfig;
  },
  SET_CONFIG_TAB_TYPE: (state, type) => {
    state.customer_config.tabType = type;
  },
  SET_IS_LOADED: (state, isLoaded) => {
    state.isLoaded = isLoaded;
  }
};

const actions = {
  async setParams({ commit, dispatch, getters, rootGetters }, params) {
    // 다이얼로그 on 일 경우 닫기
    if (rootGetters["customerDialog/showDialog"]) {
      dispatch("customerDialog/close", {}, { root: true });
    }
    let parameter = params;

    // 그룹 데이터, 관리자 데이터 로드.
    await dispatch("customerGroup/loadCustomerGroupList", null, { root: true });
    await dispatch("customerGroup/loadCustomerAdminList", null, { root: true });

    // 환경설정 페이지일 경우
    if (parameter.routeName === "customer_config") {
      await commit("SET_CONTACT_CONFIG", true);
      return;
    }

    // actionObj가 있을 경우
    if (parameter.routeName === "customer_list_action") {
      parameter.routeName = "customer_list";
    }

    // 첫페이지일 경우
    if (params.routeName === "customer") {
      parameter = {
        routeName: "customer_list",
        page: 1,
        pageSize: getters.getPageSize,
        actionObj: JSON.stringify(getters.getDefaultAction)
      };
    }

    const {
      type,
      groupId,
      page,
      actionObj = JSON.stringify(getters.getDefaultAction)
    } = parameter;
    parameter.actionObj = JSON.parse(actionObj);

    const listParams = {
      type,
      groupId: groupId,
      page,
      ...parameter.actionObj
    };
    listParams.pageSize = getters.getPageSize;

    // id가 문자일 경우 변경
    parameter.groupId = parameter.groupId
      ? parseInt(parameter.groupId)
      : parameter.groupId;
    await commit("SET_CONTACT_CONFIG", false);
    await commit("SET_ROUTE_PARAMS", parameter);
    await dispatch("customerCard/getCardList", listParams, { root: true });
    await dispatch(
      "customerHistory/loadHistoryList",
      { groupId: parameter.groupId, page: 0, shouldReset: true },
      { root: true }
    );
  },
  async initData({ dispatch }) {
    await dispatch("customerGroup/loadCustomerGroupList", null, { root: true });
    await dispatch("customerGroup/loadCustomerAdminList", null, { root: true });
  },
  routeConfigTab(getters, { tabId }) {
    router.push({
      name: "customer_config",
      params: {
        tabType: tabId || "list"
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
