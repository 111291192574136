import {
  getList,
  getView,
  getRecentView,
  getRecentUpdated
} from "@/commons/api/announce.api";
import { getDateWithTime } from "@/commons/utils/moment";

import storage from "@/commons/api/storage";
import i18n from "@/_locales";

const state = {
  show: false,
  type: "",
  vadge: false,
  list: [],
  // 리스트 정보
  page: 0,
  pageSize: 10,
  blockSize: 10,
  totalElements: 0,
  totalPages: 0,
  // 상세보기 정보
  view: {
    id: 0,
    title: "",
    contents: "",
    registTime: ""
  }
};

const getters = {
  showDialog: ({ show }) => show,
  getType: ({ type }) => type,
  showVadge: ({ vadge }) => vadge,
  getList: ({ list }) => list,
  getNowPage: ({ page }) => page,
  getPageSize: ({ pageSize }) => pageSize,
  getBlockSize: ({ blockSize }) => blockSize,
  getTotalElements: ({ totalElements }) => totalElements,
  getTotalPages: ({ totalPages }) => totalPages,
  getViewId: ({ view }) => view.id,
  getView: ({ view }) => {
    return {
      title: view.title,
      contents: view.contents,
      registTime: getDateWithTime(view.registTime)
    };
  }
};

const mutations = {
  SET_SHOW: (state, { show, type = "" }) => {
    state.show = show;
    state.type = type;
  },
  SET_TYPE: (state, type) => (state.type = type),
  SET_PAGE: (state, page) => (state.page = page),
  SET_LIST: (state, { content = [], totalElements = 0, totalPages = 0 }) => {
    state.list = content;
    state.totalElements = totalElements;
    state.totalPages = totalPages;
  },
  SET_ANNOUNCE_VIEW: (state, { id, title, contents, registTime }) => {
    state.view.id = id;
    state.view.title = title;
    state.view.contents = contents;
    state.view.registTime = registTime;
  },
  SET_VIEW_RESET: state => {
    state.view = {
      id: 0,
      title: "",
      contents: "",
      registTime: ""
    };
  },
  SET_VADGE: (state, vadge) => (state.vadge = vadge),
  SET_RESET: state => {
    state.show = false;
    state.type = "";
    state.list = [];
    state.page = 0;
    state.totalElements = 0;
    state.totalPages = 0;
    state.view = {
      id: 0,
      title: "",
      contents: "",
      registTime: ""
    };
  }
};

const actions = {
  // 업데이트 내역 조회
  async getAnnounceList({ rootState, commit, getters, dispatch }, page = 1) {
    commit("SET_PAGE", page);

    const { data = [], status = 401 } = await getList({
      page: page - 1,
      pageSize: getters.getPageSize
    });

    if (status != 200) {
      commit("SET_LIST", { content, totalElements, totalPages });
      return dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("common.9"), type: "ERROR" },
        { root: true }
      );
    }
    const { content, totalElements, totalPages } = data;
    const list = content.map(item => {
      return {
        ...item,
        isNew:
          item.registTimeMillis >= rootState.auth.userInfo.prevLoginTimeMillis
            ? true
            : false
      };
    });
    commit("SET_LIST", { content: list, totalElements, totalPages });
  },

  // 해당 업데이트 내역 상세보기
  async getAnnounceView({ commit, dispatch }, { id }) {
    const { data = {}, status = 401 } = await getView(id);
    if (status !== 200) {
      dispatch(
        "snackbar/openSnackbar",
        { message: i18n.t("common.9"), type: "ERROR" },
        { root: true }
      );
      return commit("SET_RESET");
    }
    commit("SET_ANNOUNCE_VIEW", {
      id: data.id,
      title: data.title,
      contents: data.contents,
      registTime: data.registTimeMillis
    });
    commit("SET_TYPE", "VIEW");
  },

  // 최신 업데이트 내역 상세보기
  async getRecentAnnounce({ commit }) {
    const timeMillis = storage.getAnnounceTime();
    const { data } = await getRecentView(parseInt(timeMillis) || 0);
    if (!data) return commit("SET_VADGE", false);

    const { id, title, contents, registTimeMillis: registTime } = data || {};
    storage.setAnnounceTime(registTime);
    commit("SET_VADGE", true);
    commit("SET_ANNOUNCE_VIEW", { id, title, contents, registTime });
  },

  // badge 표시를 위한 최신 업데이트 내역 있는지 여부 조회
  async isUpdatedRecentAnnounce({ commit }) {
    const { data } = await getRecentUpdated();

    commit("SET_VADGE", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
