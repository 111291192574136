<template>
  <BoardTitle />
</template>

<script>
import BoardTitle from "../components/appbar/BoardTitle.vue";

export default {
  components: { BoardTitle }
};
</script>
