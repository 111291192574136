<template>
  <!-- 버튼 -->
  <div
    class="cr-tree-wrapper"
    :class="$vuetify.breakpoint.lgAndUp ? '' : 'mobile'"
  >
    <v-treeview
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      item-text="fileName"
      :items="getFolders"
      :load-children="getDriveFolderChildren"
      :active.sync="activeItems"
      :open.sync="openItems"
      @update:active="onActive"
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon
          v-if="item.icon"
          v-text="item.icon"
          :color="getIconColor(item)"
        ></v-icon>
        <v-icon
          v-else
          v-text="
            item.shareFlag
              ? 'mdi-folder-account'
              : open
              ? 'mdi-folder-open'
              : 'mdi-folder'
          "
          :class="item.childCount === 0 ? 'ml-3' : ''"
          :color="item.folderColor"
        ></v-icon>
      </template>

      <template v-slot:label="{ item }">
        <v-hover v-slot:default="{ hover }">
          <div
            :data-id="item.id"
            :class="draggableClass(item)"
            style="margin-top: 1px;"
          >
            <div
              class="cr-tree_node_click_area"
              @click="() => nodeClick(item)"
            />
            <!-- 폴더 이름 -->
            <span class="cr-tree-label-text" v-if="!item.isEdit">
              {{ item.fileName }}
            </span>
            <!-- 폴더이름 수정필드 -->
            <v-text-field
              autofocus
              :loading="editTextLoading"
              v-if="item.isEdit"
              :label="item.fileName"
              v-on:blur="e => editFolder(e, item)"
              v-on:keyup.enter="e => editFolder(e, item)"
              v-on:keyup.esc="e => editBtnClick(e, item)"
            ></v-text-field>
            <!-- 컨텍스트 메뉴 버튼 -->
            <div>
              <v-btn
                icon
                small
                plain
                class="tree-append-right"
                :class="item.fileType == 'ROOT' ? 'root-context' : ''"
                @click.stop="e => openPositioningMenu(e, item)"
                v-if="
                  hover &&
                    !item.isEdit &&
                    !editTextLoading &&
                    (item.fileType == 'DIRECTORY' || item.fileType == 'ROOT')
                "
              >
                <v-icon v-text="'mdi-dots-vertical'"></v-icon>
              </v-btn>

              <!-- 새폴더 생성 버튼 (ROOT) -->
              <v-btn
                icon
                small
                class="tree-append-right"
                @click="e => openDialog(e, item)"
                v-if="item.fileType === 'ROOT'"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <!-- 휴지통 비우기 버튼 (DELETED)-->
              <v-btn
                class="tree-append-right cr-tutorial-drive-trash"
                icon
                small
                v-if="item.fileType === 'DELETED'"
                @click="e => cleanBtnClick(e, item)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <!-- 구글 인증 버튼 -->
              <v-btn
                v-if="item.fileType === 'GOOGLE'"
                class="tree-append-right cr-tutorial-drive-connect"
                icon
                small
                :disabled="!haveTokenByExternalType('google')"
                v-bind:title="$t('drive.47')"
                @click="e => disconnectBtnClick(e, item)"
              >
                <v-icon size="20">mdi-connection</v-icon>
              </v-btn>
            </div>
          </div>
        </v-hover>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import i18n from "@/_locales";
import { mapGetters, mapMutations, mapActions } from "vuex";
import storage from "@/commons/api/storage";

export default {
  components: {},
  async destroyed() {
    this.initOpenItems();
    // 내일 트리로 이동
    await this.INIT_DRIVE_DATA();
    await this.UPLOAD_RESET();
    await this.SET_INIT_EXTERNAL_DATA();
    await this.SET_INIT_TOKENS();
    await this.INIT_SHARE_DATA();
    await this.INIT_DRIVE_ROUTE();
    await this.SET_INIT(false);
  },
  data: () => ({
    editTextLoading: false,
    prevActiveItem: {}
  }),
  watch: {
    getFolders: function() {
      let { folderId: routeFolderId } = this.getDriveListInfo;

      // 라우팅으로 share에 접근할 시 예외 처리
      if (routeFolderId == 0) {
        if (this.$route.name.includes("share")) {
          routeFolderId = -3;
        } else if (this.$route.name.includes("drive_external")) {
          // TODO 추후 외부 연동 프로그램들이 많아지면 변경 필요
          routeFolderId = -5;
        }
      }
      let routeOpen = [];

      if (routeFolderId > 0) {
        routeOpen = this.getFullPath(routeFolderId);
      }

      // root폴더 ,url 상위 폴더 오픈
      this.setOpenItems(routeOpen);
      // url 접속시 메일함 active
      const routeFolder = this.getFolder(routeFolderId);
      // 검색일 경우 active해제
      !routeFolder
        ? this.setActiveItems([])
        : this.setActiveItems([{ id: routeFolderId }]);
    },
    // route에 의해 폴더선택이 변경될때
    getDriveListInfo: function({ folderId: routeFolderId }) {
      // 검색일 경우 active해제
      routeFolderId == -4
        ? this.setActiveItems([])
        : this.setActiveItems([{ id: routeFolderId }]);
    },
    getShareListInfo() {
      const item = this.getFolder(-3);
      this.setActiveItems([item]);
    },
    getExternalListInfo() {
      const item = this.getFolder(-5);
      this.setActiveItems([item]);
    }
  },

  computed: {
    ...mapGetters("drive", [
      "getActiveItems",
      "getOpenItems",
      "getFolders",
      "getFullPath",
      "getFolder",
      "getRootFolder"
    ]),
    ...mapGetters("driveRoute", [
      "getDriveListInfo",
      "getShareListInfo",
      "getExternalListInfo"
    ]),
    ...mapGetters("driveDialog", ["params"]),
    ...mapGetters("driveExternalToken", ["haveTokenByExternalType"]),
    activeItems: {
      get() {
        return this.getActiveItems;
      },
      set(items) {
        this.setActiveItems(items);
      }
    },
    openItems: {
      get() {
        return this.getOpenItems;
      },
      set(items) {
        this.setOpenItems(items);
      }
    },
    draggableClass() {
      return () => {
        return "d-flex align-center justify-space-between";
      };
    }
  },
  methods: {
    ...mapMutations("driveDialog", ["SET_DIALOG"]),
    ...mapMutations("driveConfig", ["SET_INIT"]),
    ...mapMutations("drive", ["INIT_DRIVE_DATA"]),
    ...mapMutations("driveFile", ["UPLOAD_RESET"]),
    ...mapMutations("driveExternal", ["SET_INIT_EXTERNAL_DATA"]),
    ...mapMutations("driveExternalToken", ["SET_INIT_TOKENS"]),
    ...mapMutations("driveShare", ["INIT_SHARE_DATA"]),
    ...mapMutations("driveRoute", ["INIT_DRIVE_ROUTE"]),
    ...mapActions("drive", [
      "getFoldersByParentId",
      "getListById",
      "updateIsEdit",
      "updateFileName",
      "deleteFolder",
      "changeActiveItem",
      "toDeleted",
      "cleanDeleted",
      "setActiveItems",
      "setOpenItems",
      "initOpenItems"
    ]),
    ...mapActions("driveExternalToken", ["disconnectDrive"]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("auth", ["checkToken"]),

    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },

    // 폴더 명 옆 아이콘 색상 처리 함수
    getIconColor(item) {
      const [activeItem = {}] = this.getActiveItems;
      const { id } = activeItem;

      return id == item.id ? "primary" : "";
    },

    // 액티브 표시 처리하는 함수
    onActive([n = {}]) {
      const { id } = n;
      // 이미 선택된 메일함을 또 선택했을때 active해제방지
      if (!id) {
        // 단, 검색일 경우는 active해제
        if (this.getDriveListInfo.folderId == -4) return;
        return this.setActiveItems([this.prevActiveItem]);
      }
      this.prevActiveItem = n;
    },

    // 트리 노드 클릭시 해당 폴더로 라우팅 처리하는 함수
    async nodeClick({ id, fileType, children }) {
      let folderId = id;
      if (folderId == -5) {
        return this.$router.push({
          name: "drive_external",
          params: { externalType: "google" }
        });
      }
      switch (folderId) {
        case -1:
          folderId = "recent";
          break;
        case -2:
          folderId = "important";
          break;
        case -3:
          folderId = "share";
          break;
      }

      if (fileType === "DIRECTORY") {
        if (children) {
          await this.getFoldersByParentId({ parentId: id });
        }
      }
      if (folderId == "share") {
        this.$router.push({
          name: "drive_share_list",
          params: { page: 1 }
        });
      } else {
        this.$router.push({
          name: "drive_list",
          params: { folderId, page: 1 }
        });
      }
    },

    // 하위폴더 가져오기
    async getDriveFolderChildren(item) {
      if (item.fileType === "DIRECTORY") {
        await this.getFoldersByParentId({ parentId: item.id });
      }
    },

    // 폴더명 수정 버튼 클릭
    editBtnClick(e, { id }) {
      e.stopPropagation();
      this.closePositioningMenu();
      this.updateIsEdit(id);
    },

    // 폴더 이름 수정
    async editFolder(e, { id, fileName }) {
      const value = e.target.value.trim();

      // 변경할 이름이 없거나, 전과 동일하면 원상태로 돌려준다.
      if (!value || fileName == value) {
        await this.updateIsEdit(id);
        this.editTextLoading = false;
      } else if (!this.editTextLoading) {
        this.editTextLoading = true;
        const result = await this.updateFileName({
          fileName: value,
          fileId: id
        });

        if (result) {
          await this.updateIsEdit(id);
          this.editTextLoading = false;
        }
      }
    },

    // 컨텍스트 메뉴
    openPositioningMenu(e, item) {
      // 우클릭한 곳이 root(ROOT)폴더인지 체크
      const isRoot = item.fileType === "ROOT" ? true : false;
      if (item.fileType !== "DIRECTORY" && item.fileType !== "ROOT") return;
      this.closePositioningMenu();
      // 하위폴더추가, 폴더삭제, 이름바꾸기, (중요설정, 내려받기, 공유설정, 색상변경)
      const itemList = [
        {
          label: i18n.t("drive.48"),
          func: e => this.openDialog(e, item)
        },
        {
          label: i18n.t("drive.내려받기"),
          func: e => this.downloadFileTreeContext(e, item)
        },
        {
          label: i18n.t("drive.폴더삭제"),
          hideItem: isRoot,
          func: e => this.movedToDeleted(e, item)
        },
        {
          label: i18n.t("drive.이름바꾸기"),
          hideItem: isRoot,
          func: e => this.editBtnClick(e, item)
        }
      ];

      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList
      });
    },

    // 폴더 추가 Dialog 열기
    openDialog(e, folder) {
      e.stopPropagation();
      this.closePositioningMenu();
      this.SET_DIALOG({
        headline: i18n.t("drive.30"),
        type: "add",
        params: { parentFolder: folder },
        confirmCallbackFunc: () => {
          // 폴더 추가 후 해당 부모 폴더 열기
          const { parentFolder } = this.params;

          const openItems = this.openItems;
          this.setOpenItems([...openItems, parentFolder]);
        }
      });
    },

    // 폴더삭제(휴지통으로 이동)
    movedToDeleted(e, { fileName, id, parentId }) {
      e.stopPropagation();
      this.closePositioningMenu();

      this.openConfirmDialog(
        "",
        i18n.t("drive.49", { value: fileName }),
        async () => {
          this.disagree();

          const result = await this.toDeleted({
            fileIds: [id],
            parentId
          });

          if (!result) {
            this.openSnackbar({
              message: i18n.t("drive.38"),
              type: "ERROR"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("drive.35"),
              type: "SUCCESS"
            });
          }
        }
      );
    },

    // 휴지통 비우기
    cleanBtnClick(e) {
      e.stopPropagation();
      this.closePositioningMenu();

      this.openConfirmDialog(
        i18n.t("drive.36"),
        i18n.t("drive.37"),
        async () => {
          this.disagree();
          const result = await this.cleanDeleted();

          if (!result) {
            this.openSnackbar({
              message: i18n.t("drive.38"),
              type: "ERROR"
            });
          } else {
            this.openSnackbar({
              message: i18n.t("drive.50"),
              type: "SUCCESS"
            });
          }
        }
      );
    },
    // 구글 드라이브 연결 해제
    async disconnectBtnClick(e, item) {
      e.stopPropagation();
      this.closePositioningMenu();
      const serviceTitle = {
        GOOGLE: "Google Drive"
      };
      const externalType = {
        GOOGLE: "GOOGLE_DRIVE"
      };

      this.openConfirmDialog(
        "",
        i18n.t("drive.51", { value: serviceTitle[item.fileType] }),
        async () => {
          await this.disconnectDrive(externalType[item.fileType]);
        }
      );
    },

    // 파일 다운로드
    async downloadFileTreeContext(e, item) {
      await this.checkToken();
      e.stopPropagation();
      this.closePositioningMenu();

      const fileIds = [item.id];
      const token = storage.getAccessToken();
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/drive/download?fileIds=${fileIds}&access_token=${token}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-tree-wrapper {
  position: absolute;
  top: 52px;
  bottom: 124px;
  left: 0px;
  right: 8px;
  overflow-y: auto;

  &.mobile {
    top: 60px;
    bottom: 60px;
  }

  .v-treeview ::v-deep {
    /* 트리 노드 */
    .v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          margin-left: 0px;
        }
      }
      /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
      .v-treeview-node__children {
        overflow: hidden;
      }
    }
    /* 텍스트 */
    .cr-tree-label-text {
      font-size: 14px;
    }
    .v-treeview-node--active .cr-tree-label-text {
      font-weight: bold;
    }

    > .v-treeview-node {
      .v-treeview-node__root {
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }

        .v-treeview-node__content {
          cursor: pointer;
          .v-treeview-node__label {
            .cr-tree_node_click_area {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }

            .cr-tree-label-text {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              min-width: 0px;
            }
          }
        }
      }
      > .v-treeview-node__children {
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>
