<template>
  <div class="ai-dialog elevation-6" :class="dialogClass">
    <v-card>
      <WindowBar />

      <div class="ai-dialog-content px-3">
        <v-row no-gutters :class="rowClass">
          <v-col cols="12" :sm="sm">
            <QuestionForm class="question-form" />
          </v-col>
          <v-col cols="12" :sm="sm">
            <ResponsePanel class="response-panel" />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WindowBar from "./WindowBar";
import ResponsePanel from "@/commons/views/ai/dialog/ResponsePanel.vue";
import QuestionForm from "@/commons/views/ai/dialog/QuestionForm";

export default {
  components: { WindowBar, ResponsePanel, QuestionForm },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters("ai", ["isAIDialogVisible", "maximize"]),
    dialogClass() {
      const slide = this.show
        ? "slide-right-enter-active"
        : "slide-right-leave-active";
      return slide + (this.maximize ? " maximize" : "");
    },
    rowClass() {
      if (!this.$vuetify.breakpoint.mdAndDown && this.maximize) {
        return "full";
      }
      return "";
    },
    sm() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 12;
      }
      return this.maximize ? 6 : 12;
    }
  },
  watch: {
    isAIDialogVisible(value) {
      this.show = value;
    }
  },
  methods: {
    ...mapActions("ai", ["showAIDialog", "setMaximize"]),
    initialize() {
      this.show = this.isAIDialogVisible;
    }
  }
};
</script>

<style scoped lang="scss">
/* 오른쪽에서 나타나는 애니메이션 */
@keyframes slideRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

/* 오른쪽으로 사라지는 애니메이션 */
@keyframes slideRightOut {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

.ai-dialog.slide-right-enter-active {
  animation: slideRight 0.3s;
  transform: translateX(0);
}

.ai-dialog.slide-right-leave-active {
  animation: slideRightOut 0.3s;
  transform: translateX(110%);
}
</style>

<style scoped lang="scss">
.ai-dialog {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 500px;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
  margin: 0;
  z-index: 10;

  &.maximize {
    width: auto;
    height: auto;
    left: 0;
    padding: 20px 50px;
  }

  .row .col-sm-6 {
    position: relative;
  }
  .row.full {
    height: 100%;
    padding-bottom: 12px;

    .question-form {
      overflow-y: auto !important;
    }
    .question-form,
    .response-panel {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
  }

  .v-card {
    position: relative;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
  }

  .ai-dialog-content {
    position: absolute;
    top: 47px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  .ai-area {
    textarea {
      resize: none;
    }

    .v-text-field > .v-input__control > .v-input__slot:before,
    .v-text-field > .v-input__control > .v-input__slot:after {
      border-width: 0;
    }
  }
}
</style>
