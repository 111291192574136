import moment from "moment";

export default [
  {
    path: "/cal",
    name: "cal",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarMain.vue"),
    redirect: () => {
      return {
        name: "cal_frame",
        params: {
          type: "month",
          focus: moment().format("YYYY-MM-DD")
        }
      };
    },
    children: [
      {
        path: ":type/:focus",
        name: "cal_frame"
      }
    ]
  }
];
