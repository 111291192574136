var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"show-select":"","fixed-header":"","hide-default-footer":"","item-class":function () { return 'item-row'; },"loading":_vm.getLoading,"headers":_vm.headers,"hide-default-header":_vm.hideHeader,"items":_vm.getList,"item-key":_vm.chooseDataTableKey,"items-per-page":_vm.getPageSize,"server-items-length":_vm.getTotalElements},on:{"page-count":function($event){_vm.pageCount = $event},"item-selected":_vm.itemSelected,"toggle-select-all":_vm.selectAll,"click:row":function (r, ref) {
	var select = ref.select;
	var isSelected = ref.isSelected;

	return select(!isSelected);
}},scopedSlots:_vm._u([{key:"header.name",fn:function(){return [_c('ListHeader',_vm._g(_vm._b({},'ListHeader',_vm.$props,false),_vm.$listeners))]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('ListItem',_vm._b({attrs:{"item":item}},'ListItem',_vm.$props,false))]}},{key:"footer",fn:function(){return [(_vm.pageCount > 0)?_c('Pagination',{attrs:{"type":"list","page":_vm.page,"pageSize":_vm.getPageSize,"blockSize":3,"totalElements":_vm.getTotalElements},on:{"update:page":function($event){_vm.page=$event},"pageClick":_vm.pageClick}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }