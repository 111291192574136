<template>
  <div
    class="side-drawer"
    :class="{ 'md-down': $vuetify.breakpoint.mdAndDown }"
  >
    <!-- 오른쪽 네비게이션 Drawer -->
    <Navigation v-if="drawer" :drawer.sync="drawer" />

    <!-- AI 버튼 -->
    <AIBtn v-if="drawer && useAI" />

    <!-- 사용자 테마 설정버튼 -->
    <ThemeBtn v-if="drawer" />

    <!-- 열렸을때 닫기버튼 -->
    <div v-if="showToggleButton">
      <CloseBtn v-if="drawer" @closeDrawer="setShowSidePanel(false)" />
    </div>

    <!-- 닫혔을때 열기버튼 -->
    <DeactivatedBtn v-if="!drawer" @openDrawer="setShowSidePanel(true)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import storage from "@/commons/api/storage";
import Navigation from "./Navigation.vue";
import ThemeBtn from "./ThemeBtn.vue";
import AIBtn from "./AIBtn.vue";
import CloseBtn from "./CloseBtn.vue";
import DeactivatedBtn from "./DeactivatedBtn.vue";

export default {
  components: { Navigation, ThemeBtn, AIBtn, CloseBtn, DeactivatedBtn },
  props: {
    route: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (
      this.getCompanyInfo.planType === "CUSTOM" &&
      this.getCompanyInfo.customizeUI.flagAppFolder === 0
    ) {
      this.SET_SHOW_SIDE_PANEL(true);
    }
  },
  computed: {
    ...mapGetters("sidePanel", ["showSidePanel", "sidePanelWidth"]),
    ...mapGetters("auth", ["getCompanyInfo"]),
    ...mapGetters("ai", ["useAI"]),
    drawer: {
      get() {
        return this.showSidePanel;
      },
      set(drawer) {
        this.SET_SHOW_SIDE_PANEL(drawer);
      }
    },
    showToggleButton: {
      get() {
        if (this.getCompanyInfo.planType === "BASIC") {
          return false;
        }
        if (this.getCompanyInfo.planType === "CUSTOM") {
          return this.getCompanyInfo.customizeUI.flagAppFolder === 1;
        }
        return true;
      }
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    }
  },
  watch: {
    mdAndDown(mdAndDown) {
      if (mdAndDown) return;
      const show = (storage.getSidePanelOpen() ?? "true") === "true";
      this.SET_SHOW_SIDE_PANEL(show);
    }
  },
  methods: {
    ...mapMutations("sidePanel", ["SET_SHOW_SIDE_PANEL"]),
    setShowSidePanel(bool) {
      storage.setSidePanelOpen(bool);
      this.SET_SHOW_SIDE_PANEL(bool);
    }
  }
};
</script>

<style lang="scss" scoped>
.side-drawer::v-deep {
  display: contents;
  .v-navigation-drawer {
    z-index: 0;
  }

  &.md-down {
    .v-navigation-drawer {
      z-index: 6;
    }
  }
}
</style>
