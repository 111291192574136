import Vue from "vue";
import { getMessage } from "@/flow/store/utils/messageUtils";
import {
  getGroups,
  addGroup,
  updateGroupTitle,
  moveGroup,
  deleteGroup
} from "@/flow/api/group.api";

const state = {
  groups: [],
  collapsedGroupList: []
};

const getters = {
  groups: state => {
    return state.groups.map(group => {
      // 다국어
      const title = group.title === "default" ? "미지정" : group.title;
      return { ...group, text: title, value: group.id };
    });
  },
  defaultGroupId: state => state.groups.find(g => g.isDefault)?.id
};

const mutations = {
  SET_GROUPS: (state, groups) => {
    state.groups = groups;
  },
  UPDATE_GROUP_TITLE: (state, group) => {
    const index = state.groups.findIndex(g => g.id === group.id);
    if (index === -1) return;

    Vue.set(state.groups, index, group);
  },
  DELETE_GROUP: (state, groupId) => {
    const index = state.groups.findIndex(g => g.id === groupId);
    if (index === -1) return;

    Vue.delete(state.groups, index);
    Vue.delete(state.LIST, groupId);
  },
  TOGGLE_COLLAPSED_GROUP: (state, id) => {
    const index = state.collapsedGroupList.findIndex(i => i === id);
    if (index === -1) state.collapsedGroupList.push(id);
    else state.collapsedGroupList.splice(index, 1);
  }
};

const actions = {
  async getGroups({ commit, dispatch }, projectId) {
    const { status, data: groups } = await getGroups(projectId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_GROUPS", groups);
  },
  async addGroup({ rootState, commit, dispatch }) {
    const { projectId } = rootState.flowRoute;
    const { status, data: groups } = await addGroup(projectId);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("SET_GROUPS", groups);
  },
  async updateGroupTitle({ rootState, commit, dispatch }, { groupId, title }) {
    const { projectId } = rootState.flowRoute;
    const params = { projectId, groupId, title };
    const { status, data } = await updateGroupTitle(params);
    const message = getMessage(status);
    if (message) return dispatch("snackbar", { message });

    commit("UPDATE_GROUP_TITLE", data);
  },
  async deleteGroup({ rootState, getters, commit, dispatch }, groupId) {
    let _message = "업무가 존재하는 그룹은 삭제할 수 없습니다.";
    if (getters.LIST_tasks[groupId]?.length) {
      return dispatch("snackbar", { message: _message });
    }

    const callback = async () => {
      const { projectId } = rootState.flowRoute;
      const { status, data } = await deleteGroup(projectId, groupId);

      if (data?.error == "default_group") {
        _message = "기본 그룹은 삭제할 수 없습니다.";
      }
      const additionalMsg = { 409: _message };
      const message = getMessage(status, additionalMsg);
      if (message) return dispatch("snackbar", { message });

      commit("DELETE_GROUP", groupId);
    };

    const message = `해당 그룹을 삭제 하시겠습니까?<br />`;
    return dispatch("confirm", { message, callback });
  },
  async moveGroup(
    { rootState, commit, dispatch },
    { dragGi: groupId, dropGi: targetGroupId, position }
  ) {
    const { projectId } = rootState.flowRoute;
    const params = { projectId, groupId, targetGroupId, position };
    const { status, data } = await moveGroup(params);
    const additionalMsg = { 409: "기본 그룹은 이동할 수 없습니다." };
    const message = getMessage(status, additionalMsg);
    if (message) return dispatch("snackbar", { message });

    commit("SET_GROUPS", data);
  }
};

export default { state, getters, mutations, actions };
