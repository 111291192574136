<template>
  <div>
    <v-tabs height="30" show-arrows v-model="tab">
      <v-tab>색상</v-tab>
      <v-tab>이모지</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <ColorPicker :color.sync="_color" />
      <EmojiPicker :emoji.sync="_emoji" />
    </v-tabs-items>
  </div>
</template>

<script>
import ColorPicker from "./ColorPicker.vue";
import EmojiPicker from "./EmojiPicker.vue";

export default {
  components: { ColorPicker, EmojiPicker },
  props: {
    avatar: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { tab: 0 };
  },
  computed: {
    _color: {
      get() {
        return this.avatar.color;
      },
      set(color) {
        this.$emit("update:avatar", { ...this.avatar, color, emoji: "" });
      }
    },
    _emoji: {
      get() {
        return this.avatar.emoji;
      },
      set(emoji) {
        this.$emit("update:avatar", { ...this.avatar, emoji });
      }
    }
  }
};
</script>
