<template>
  <v-card :min-width="$vuetify.breakpoint.width < 412 ? '100%' : '412px'">
    <v-card-title
      class="d-flex align-center pa-2"
      :style="{ cursor: dragging ? 'grabbing' : 'grab' }"
      @mousedown="e => $emit('startDrag', e)"
    >
      <v-spacer></v-spacer>

      <slot name="header"></slot>

      <v-btn icon @mousedown.stop @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-6">
      <v-row>
        <v-col cols="12" :md="detailMode ? 4 : 12" :sm="detailMode ? 6 : 12">
          <!-- 제목 -->
          <v-row>
            <v-col cols="12">
              <IconField>
                <v-text-field
                  v-model="summary"
                  class="text-h6"
                  full-width
                  dense
                  autofocus
                  autocomplete="off"
                  hide-details="auto"
                  :placeholder="$t('calendar.eventTitle')"
                  @input="val => $emit('changeName', val)"
                  @keydown.enter="onEnter"
                ></v-text-field>
              </IconField>
            </v-col>
          </v-row>

          <!-- 사유 -->
          <v-row no-gutters class="mt-2">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-text">
                <FlatTextInput
                  v-model="description"
                  area
                  :placeholder="$t('calendar.setReason')"
                />
              </IconField>
            </v-col>
          </v-row>

          <!-- 연차 승인자-->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField
                dense
                spacing="dense"
                icon="mdi-check-underline-circle-outline"
              >
                <div class="d-flex align-center">
                  <div class="flex-fill">
                    <FlatMemberSearch
                      :value="approver"
                      :page-size="3"
                      :placeholder="$t('calendar.setApprover')"
                      @input="changeApprover"
                    />
                  </div>
                  <v-btn
                    small
                    icon
                    class="ml-1"
                    @click="showSearchDialog = true"
                  >
                    <v-icon size="20">
                      mdi-sitemap-outline
                    </v-icon>
                  </v-btn>
                </div>
              </IconField>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="approver.length">
            <v-col cols="12">
              <IconField spacing="dense">
                <MemberList v-model="approver" />
              </IconField>
            </v-col>
          </v-row>

          <v-divider class="my-2"></v-divider>

          <!-- 휴가 유형 및 정보 -->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-briefcase-outline">
                <div class="d-flex align-center">
                  <FlatSelect v-model="leaveType" :items="getLeaveTypes" />
                  <span class="ml-2 text-body-2 text--primary">
                    <template v-if="leaveType === 'BASIC'">
                      {{ restLeaveCnt }}/{{ annualLeaveCnt }}
                    </template>
                    <template v-else>
                      {{ restSpecialLeaveCnt }}/{{ specialLeaveCnt }}
                    </template>
                    <span class="text-caption">
                      ( {{ $t("calendar.잔여") }}/{{ $t("calendar.전체") }} )
                    </span>
                  </span>
                </div>
              </IconField>
            </v-col>
          </v-row>

          <!-- 휴가 기간 -->
          <v-row no-gutters class="small-row">
            <v-col cols="12">
              <IconField dense spacing="dense" icon="mdi-clock-outline">
                <div class="d-flex align-center">
                  <FlatSelect
                    v-model="durationType"
                    :items="getDurationOptions"
                  />
                  <FilledDateTimePicker
                    :date.sync="startDate"
                    :time="allDay ? null : startTime"
                    @update:time="changeStartTime"
                    :date-menu-props="{
                      top: true,
                      right: true,
                      origin: 'bottom left'
                    }"
                  />
                  <v-icon x-small>mdi-tilde</v-icon>
                  <!-- 하루 단위 연차면 기간 선택 -->
                  <FilledDateTimePicker
                    v-if="allDay"
                    :date.sync="endDate"
                    :date-menu-props="{
                      top: true,
                      right: true,
                      origin: 'bottom left'
                    }"
                  />
                  <!-- 시간 단위 연차면 종료 시간 표시 -->
                  <FilledDateTimePicker
                    v-else
                    :time.sync="endTime"
                    :time-btn-props="{
                      disabled: true,
                      class: 'end-time-text'
                    }"
                  />
                </div>
              </IconField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center info--text">
              {{ $t("calendar.연차_신청_요약", { value: leaveCnt }) }}
            </v-col>
          </v-row>
        </v-col>

        <!-- 상세 모드 우측 패널
        특별휴가 보유 여부, 잔여 연차 등 연차 정보는 항상 필요하므로 컴포넌트를 로드하여 관련 정보를 불러오고,
        기본적으로 요약하여 표시한 후 상세 모드로 전환 시 전체 정보를 표시합니다.
         -->
        <v-col
          cols="12"
          md="8"
          sm="6"
          class="pa-0"
          :class="{ 'd-none': !detailMode }"
        >
          <div style="max-width: 900px">
            <LeaveInfo
              :userId="getUserInfo.id"
              :searchMilli="searchMilli"
              :specialLeaveCnt.sync="specialLeaveCnt"
              :restSpecialLeaveCnt.sync="restSpecialLeaveCnt"
              :annualLeaveCnt.sync="annualLeaveCnt"
              :restLeaveCnt.sync="restLeaveCnt"
              userType="personal"
            ></LeaveInfo>
          </div>
        </v-col>
      </v-row>

      <AttendeeSelector
        v-if="showSearchDialog"
        :dialog.sync="showSearchDialog"
        :exist-attendee="[]"
        @setSearchResultFromDialog="changeApprover"
        @closeDialog="showSearchDialog = false"
      ></AttendeeSelector>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        :class="{ 'grey lighten-2': detailMode }"
        class="text-none"
        @click="$emit('update:detailMode', !detailMode)"
      >
        {{ $t("common.상세") }}
      </v-btn>
      <v-btn
        v-if="isUpdateMode"
        depressed
        color="primary"
        class="text-none"
        @click="updateEvent"
      >
        {{ $t("common.수정") }}
      </v-btn>

      <v-btn
        v-else
        depressed
        color="primary"
        class="text-none"
        @click="saveEvent"
      >
        {{ $t("common.저장") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LeaveInfo from "@/calendar/components/event/leave/LeaveInfo.vue";
import { CALENDAR_TYPE } from "@/calendar/constant/calConstants";
import EventViewMixin from "@/calendar/mixins/EventViewMixin";
import { mapActions, mapGetters, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { getApprover } from "@/calendar/api/leave.api";
import AttendeeSelector from "../attendee/dialog/index.vue";
import IconField from "@/calendar/components/common/IconField.vue";
import FlatTextInput from "@/calendar/components/common/FlatTextInput.vue";
import FilledDateTimePicker from "@/calendar/components/common/FilledDateTimePicker.vue";
import FlatMemberSearch from "@/calendar/components/common/FlatMemberSearch.vue";
import MemberList from "@/calendar/components/common/MemberList.vue";
import FlatSelect from "@/calendar/components/common/FlatSelect.vue";

const DURATION_TYPE = Object.freeze({
  DAY: {
    value: "DAY",
    text: "종일",
    duration: 1
  },
  HALF_DAY: {
    value: "HALF_DAY",
    text: "반차",
    duration: 0.5
  },
  QUARTER_DAY: {
    value: "QUARTER_DAY",
    text: "반반차",
    duration: 0.25
  }
});

export default {
  components: {
    FlatSelect,
    MemberList,
    FlatMemberSearch,
    FilledDateTimePicker,
    FlatTextInput,
    IconField,
    AttendeeSelector,
    LeaveInfo
  },
  props: {
    dragging: {
      type: Boolean
    },
    detailMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [EventViewMixin],
  data() {
    return {
      origin: {
        type: "",
        count: 0
      },
      durationType: null,
      leaveType: "BASIC",
      leaveStatus: "READY",
      leaveCnt: DURATION_TYPE.DAY.duration,
      approver: [],

      specialLeaveCnt: 0,
      restSpecialLeaveCnt: 0,
      annualLeaveCnt: 0,
      restLeaveCnt: 0,

      showSearchDialog: false,
      searchMilli: new Date().getTime()
    };
  },
  computed: {
    ...mapState("cal", ["editEvent"]),
    ...mapGetters("cal", ["getMyCalendars"]),
    ...mapGetters("auth", ["getUserInfo"]),
    getDurationOptions() {
      return Object.values(DURATION_TYPE);
    },
    getLeaveTypes() {
      return [
        {
          value: "BASIC",
          text: this.$t("calendar.연차")
        },
        {
          value: "SPECIAL",
          text: this.$t("calendar.특별휴가"),
          disabled: !this.isAvailableSpecialLeave
        }
      ];
    },
    isAvailableSpecialLeave() {
      return this.restSpecialLeaveCnt > 0 || this.origin.type === "SPECIAL";
    },
    getMaxLeaveCnt() {
      const restLeaveCount =
        this.leaveType === "SPECIAL"
          ? this.restSpecialLeaveCnt
          : /*
             * 일반 연차의 경우 내년 연차까지 사용할 수 있도록 제한을 해제합니다.
             * 올해 연차 개수를 초과한만큼 내년 연차의 지급량이 차감됩니다.
             */
            100;

      // 연차 일정을 수정하는 경우 기존 등록했던 개수까지는 허용합니다.
      if (this.isUpdateMode && this.leaveType === this.origin.type) {
        return restLeaveCount < this.origin.count
          ? this.origin.count
          : restLeaveCount;
      }

      return restLeaveCount;
    }
  },
  created() {
    // 연차 일정은 항상 기본 캘린더에 저장되므로 캘린더 뷰에 기본 캘린더로 표시합니다.
    const defaultCalendar = this.getMyCalendars.find(
      calendar => calendar.type === CALENDAR_TYPE.DEFAULT
    );
    this.$emit("changeCalendar", defaultCalendar || this.getMyCalendars[0]);

    if (this.isUpdateMode) {
      this.initViewByCurrentEvent();
    } else {
      this.durationType = DURATION_TYPE.DAY.value;
    }

    this.initApprover();
  },
  watch: {
    leaveType() {
      this.durationType = DURATION_TYPE.DAY.value;
    },
    durationType() {
      this.changeAllDay(this.durationType === DURATION_TYPE.DAY.value);
      this.adjustDateTime("end");
    },
    // 연차 편집기를 이용한 수정 시 캘린더 화면에 반영하거나,
    // 캘린더 화면에서 드래그 등을 이용한 수정 시 편집기에 반영합니다.
    startDate() {
      this.adjustDateTime("end");
    },
    startTime() {
      this.adjustDateTime("end");
    },
    endDate() {
      this.adjustDateTime("start");
    },
    endTime() {
      this.adjustDateTime("start");
    },
    allDay() {
      // 캘린더 화면 조작으로 종일 여부가 변경되는 경우 기간 유형을 호환되는 값으로 변경하거나, 종일 여부 값을 제어합니다.
      if (!this.allDay && this.durationType === DURATION_TYPE.DAY.value) {
        // 기본적으로 종일 사용하는 연차로 설정합니다.
        this.changeAllDay(true);
      } else if (this.allDay && this.durationType !== DURATION_TYPE.DAY.value) {
        this.durationType = DURATION_TYPE.DAY.value;
      } else if (
        !this.allDay &&
        this.durationType !== DURATION_TYPE.HALF_DAY.value &&
        this.durationType !== DURATION_TYPE.QUARTER_DAY.value
      ) {
        this.durationType = DURATION_TYPE.HALF_DAY.value;
      }

      this.transp = this.allDay ? "TRANSPARENT" : "OPAQUE";
    }
  },
  methods: {
    ...mapActions("cal", ["saveEventToServer", "updateEventToServer"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    initViewByCurrentEvent() {
      const event = this.editEvent;
      this.leaveType = event.detail.leaveType;
      this.leaveCnt = event.detail.leaveCnt;
      this.leaveStatus = event.detail.leaveStatus;
      if (event.detail.leaveCnt === DURATION_TYPE.HALF_DAY.duration) {
        this.durationType = DURATION_TYPE.HALF_DAY.value;
      } else if (event.detail.leaveCnt === DURATION_TYPE.QUARTER_DAY.duration) {
        this.durationType = DURATION_TYPE.QUARTER_DAY.value;
      } else {
        this.durationType = DURATION_TYPE.DAY.value;
      }

      this.origin.type = event.detail.leaveType;
      this.origin.count = event.detail.leaveCnt;
    },
    changeAllDay(value) {
      this.$emit("changeAllDay", value);
    },
    async initApprover() {
      const { data = [] } = await getApprover();
      if (!data) {
        return;
      }

      this.approver = [
        {
          userId: data.id,
          valid: true,
          value: `"${data.accountName}" <${data.username}>`,
          type: "MEMBER",
          name: data.accountName,
          email: data.username,
          organization: data.organizationName
        }
      ];
    },
    changeApprover(members) {
      if (!members?.length) return;
      if (members.length >= 1) {
        this.approver = [members[members.length - 1]];
      }
    },
    changeStartTime(time) {
      this.startTime = time;
    },
    adjustDateTime(adjustTarget) {
      let startDateTime;
      let endDateTime;
      if (this.durationType === DURATION_TYPE.DAY.value) {
        startDateTime = moment(`${this.startDate} 00:00`, "YYYY-MM-DD HH:mm");
        endDateTime = moment(`${this.endDate} 00:00`, "YYYY-MM-DD HH:mm");

        if (!startDateTime.isSameOrBefore(endDateTime)) {
          if (adjustTarget === "start") {
            startDateTime.set({
              year: endDateTime.get("year"),
              month: endDateTime.get("month"),
              date: endDateTime.get("date")
            });
          }

          if (adjustTarget === "end") {
            endDateTime.set({
              year: startDateTime.get("year"),
              month: startDateTime.get("month"),
              date: startDateTime.get("date")
            });
          }
        }

        this.leaveCnt = endDateTime.diff(startDateTime, "days") + 1;
      } else {
        startDateTime = moment(
          `${this.startDate} ${this.startTime}`,
          "YYYY-MM-DD HH:mm"
        );
        endDateTime = startDateTime
          .clone()
          .add({ hours: this.calcAppliedHours(startDateTime) });

        this.leaveCnt = DURATION_TYPE[this.durationType].duration;
      }

      this.$emit("changeDateTime", {
        start: startDateTime.toDate(),
        end: endDateTime.toDate()
      });
    },
    calcAppliedHours(dateTime) {
      const isAm = dateTime.locale("en").format("A") === "AM";
      if (this.durationType === DURATION_TYPE.HALF_DAY.value) {
        return isAm ? 4 : 5;
      } else if (this.durationType === DURATION_TYPE.QUARTER_DAY.value) {
        return 2;
      } else return 0;
    },
    async saveEvent() {
      if (!this.validateFields()) return;
      const eventDetail = this.makeEventDetail();
      const param = {
        // 연차 이벤트는 항상 기본 캘린더에 저장되므로 캘린더 ID 필드는 사용되지 않습니다.
        calendarId: 0,
        eventUId: uuidv4(),
        detail: eventDetail,
        confirmedSendMail: false,
        approverId: this.approver[0]?.userId
      };

      this.confirm(await this.saveEventToServer(param));
    },
    async updateEvent() {
      if (!this.validateFields()) return;
      const eventDetail = this.makeEventDetail();
      const param = {
        originCalendarId: this.editEvent.calendar.id,
        // 연차 이벤트는 항상 기본 캘린더에 저장되므로 캘린더 ID 필드는 사용되지 않습니다.
        calendarId: 0,
        eventUId: this.eventUId,
        detail: eventDetail,
        owner: this.editEvent.source.owner,
        confirmedSendMail: false,
        approverId: this.approver[0]?.userId
      };

      this.confirm(await this.updateEventToServer(param));
    },
    makeEventDetail() {
      let eventDetail = this.makeBaseEventDetail();
      eventDetail.isLeave = true;
      eventDetail.leaveCnt = this.leaveCnt;
      eventDetail.leaveType = this.leaveType;
      eventDetail.leaveStatus = this.leaveStatus;

      return eventDetail;
    },
    confirm(status) {
      this.$emit("confirm", status);
    },
    cancel() {
      this.$emit("cancel");
    },
    onEnter() {
      this.isUpdateMode ? this.updateEvent() : this.saveEvent();
    },
    validateFields() {
      let errorMessage;

      if (!this.summary) {
        errorMessage = this.$t("calendar.50");
      } else if (this.leaveCnt < DURATION_TYPE[this.durationType].duration) {
        errorMessage = this.$t("calendar.51");
      } else if (this.getMaxLeaveCnt < this.leaveCnt) {
        errorMessage = this.$t("calendar.52");
      } else if (this.approver.length < 1) {
        errorMessage = this.$t("calendar.140");
      }

      if (errorMessage) {
        this.openSnackbar({
          message: errorMessage,
          type: "ERROR"
        });
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/simple-scrollbar.scss";
@import "vuetify/src/styles/styles.sass";

.v-card__text {
  min-height: 380px;
  max-height: 448px;
  overflow-x: hidden;
  overflow-y: auto;

  // 반차/반반차 선택 시 종료 시간 표시
  ::v-deep .end-time-text {
    color: map-get($grey, base) !important;
  }

  .small-row {
    margin-top: 2px;
  }
}
</style>
