<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    class="flat-text-field text-body-2"
    hide-details
    filled
    dense
    @input="val => $emit('input', val)"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.flat-text-field.v-text-field--filled {
  border-radius: 4px;

  &.v-input:not(.v-input--is-focused):not(.v-input--has-state) {
    ::v-deep .v-input__control .v-input__slot:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.v-input ::v-deep {
    .v-input__slot {
      background: rgba(0, 0, 0, 0.04);
      min-height: 36px !important;
      padding: 0 4px 0 8px !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: hidden;

      &:after {
        bottom: 0 !important;
      }
      &::before {
        border: none;
      }
    }
  }
}
</style>
