import {
  saveBooking,
  updateBooking,
  cancelBooking
} from "@/resource/api/resource.api";
import i18n from "@/_locales";
import moment from "moment-timezone";
import { RESOURCE_CONSTANTS } from "@/resource/constant/resourceConstants";

const state = {
  show: false,
  resource: {},
  booking: {},
  resourceId: 0,
  mode: RESOURCE_CONSTANTS.mode.save
};

const getters = {
  getShow: ({ show }) => show,
  getMode: ({ mode }) => mode,
  getBooking: ({ booking }) => booking,
  getResource: ({ resource }) => resource,
  getResourceId: ({ resourceId }) => resourceId
};

const mutations = {
  SET_SHOW: (state, show) => (state.show = show),
  SET_MODE: (state, mode) => (state.mode = mode),
  SET_RESOURCE: (state, resource) => (state.booking = resource),
  SET_RESOURCE_ID: (state, resourceId) => (state.resourceId = resourceId),
  SET_BOOKING: (state, booking) => (state.booking = booking),
  SET_TIME_DATA: (state, timeData) => {
    state.booking.startDate = timeData.startDate;
    state.booking.endDate = timeData.endDate;
    state.booking.startTime = timeData.startTime;
    state.booking.endTime = timeData.endTime;
  }
};

const actions = {
  async openResourceBookingDialog(
    { commit, rootGetters },
    { resourceId, mode, booking, timeData }
  ) {
    if (resourceId) {
      await commit("SET_RESOURCE_ID", resourceId);
    }
    const isUpdate = mode == RESOURCE_CONSTANTS.mode.update;
    if (isUpdate) {
      await commit("SET_BOOKING", booking);
    } else {
      if (!timeData) {
        const currentDate = rootGetters["resource/getCurrentDate"];
        const currentTime = moment();
        const newTimeData = {
          startDate: currentDate,
          startTime: currentTime.format("HH:mm"),
          endDate: currentDate,
          endTime: currentTime.add(1, "hours").format("HH:mm")
        };
        timeData = newTimeData;
      }
      await commit("SET_TIME_DATA", timeData);
    }

    await commit("SET_MODE", mode);
    await commit("SET_SHOW", true);
  },
  async closeResourceBookingDialog({ dispatch, commit }) {
    await commit("SET_SHOW", false);
    await dispatch("resetData");
  },
  async saveBooking({ dispatch, commit }, booking) {
    let message, type;

    await commit("resource/SET_SHOW_LOADING_OVERLAY", true, { root: true });
    const response = await saveBooking(booking);

    if (response.status == 201) {
      message = i18n.t("resource.alert.success", {
        request: i18n.t("resource.request.save")
      });
      type = "SUCCESS";
      // 성공시에만 다이얼로그 닫고 데이터 리프레쉬.
      await dispatch("closeResourceBookingDialog");
      await dispatch("resource/loadResourceData", null, { root: true });
    } else if (response.status == 409) {
      // 해당 시간에 예약 존재
      message = i18n.t("resource.alert.conflict");
      type = "ERROR";
    } else {
      // 서버 에러
      message = i18n.t("resource.alert.fail", {
        request: i18n.t("resource.request.save")
      });
      type = "ERROR";
    }

    dispatch(
      "snackbar/openSnackbar",
      { message: message, type: type },
      { root: true }
    );
    await commit("resource/SET_SHOW_LOADING_OVERLAY", false, { root: true });
  },
  async updateResourceBooking({ dispatch, commit }, booking) {
    await commit("resource/SET_SHOW_LOADING_OVERLAY", true, { root: true });
    const response = await updateBooking(booking);

    let message, type;
    if (response.status == 200) {
      // 수정 성공
      message = i18n.t("resource.alert.success", {
        request: i18n.t("resource.request.update")
      });
      type = "SUCCESS";
      await dispatch("closeResourceBookingDialog");
      await dispatch("resource/loadResourceData", null, { root: true });
    } else if (response.status == 409) {
      // 해당 시간에 예약 존재
      message = i18n.t("resource.alert.conflict");
      type = "ERROR";
    } else {
      // 서버 에러
      message = i18n.t("resource.alert.fail", {
        request: i18n.t("resource.request.update")
      });
      type = "ERROR";
    }
    await commit("resource/SET_SHOW_LOADING_OVERLAY", false, { root: true });
    dispatch(
      "snackbar/openSnackbar",
      { message: message, type: type },
      { root: true }
    );
  },
  async cancelBooking({ dispatch, commit }, bookingId) {
    await commit("resource/SET_SHOW_LOADING_OVERLAY", true, { root: true });
    let message = i18n.t("resource.alert.success", {
      request: i18n.t("resource.request.cancel")
    });
    let type = "SUCCESS";
    const response = await cancelBooking(bookingId);
    if (response.status != 200) {
      message = i18n.t("resource.alert.fail", {
        request: i18n.t("resource.request.cancel")
      });
      type = "ERROR";
    }
    await dispatch("closeResourceBookingDialog");
    await dispatch("resource/loadResourceData", null, { root: true });
    await commit("resource/SET_SHOW_LOADING_OVERLAY", false, { root: true });
    dispatch(
      "snackbar/openSnackbar",
      { message: message, type: type },
      { root: true }
    );
  },
  async resetData({ commit }) {
    await commit("SET_BOOKING", {});
    await commit("SET_RESOURCE", {});
    await commit("SET_RESOURCE_ID", 0);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
