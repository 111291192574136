import http from "@/commons/api/http";

const prefix = "/api/users/dashboard/config";

/**
 * 대시보드 정보 가져오기
 */
const getDashboardConfig = () => {
  return http.get(prefix);
};

/**
 * 사용자 대시보드 메일함 설정 변경
 */
const updateMailboxId = mailboxId => {
  return http.put(`${prefix}/mailboxId/${mailboxId}`);
};

/**
 * 사용자 대시보드 북마크 추가
 */
const addBookmark = ({ link, title }) => {
  const formData = new FormData();
  formData.append("link", link);
  formData.append("title", title);
  return http.post(`${prefix}/bookmark`, formData);
};

/**
 * 사용자 대시보드 북마크 삭제
 */
const deleteBookmark = id => {
  return http.delete(`${prefix}/bookmark/${id}`);
};

/**
 * 사용자 대시보드 북마크 수정
 */
const modifyBookmark = ({ id, link, title }) => {
  const formData = new FormData();
  formData.append("link", link);
  formData.append("title", title);
  return http.put(`${prefix}/bookmarkModify/${id}`, formData);
};

/**
 * 사용자 대시보드 워크플로 바로가기 추가
 */
const addWorkflow = id => {
  return http.put(`${prefix}/workflow/${id}`);
};

/**
 * 사용자 대시보드 워크플로 바로가기 삭제
 */
const deleteWorkflow = id => {
  return http.delete(`${prefix}/workflow/${id}`);
};

export {
  getDashboardConfig,
  updateMailboxId,
  addBookmark,
  modifyBookmark,
  deleteBookmark,
  addWorkflow,
  deleteWorkflow
};
