import Vue from "vue";
import axios from "axios";
import router from "@/commons/router";
import { uploadFile } from "../../api/drive.api";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import i18n from "@/_locales";

const state = {
  showDialog: false,
  showSnackbar: false,
  key: 0, // 키
  files: [], // 파일목록
  gridItems: [
    // {
    //   name,
    //   size,
    //   loaded,
    //   progress,
    //   status -> 0 대기중 / 1 전송 중 / 2 전송완료 / 3 전송실패 / 4 취소
    // }
  ], // 테이블 에 나올 파일 내용
  cancelTokenSource: {}, // ?
  totalProgress: 0, // ?
  totalSize: 0, // 총 사이즈
  totalLoaded: 0, // 로딩된 사이즈
  remainingTime: 0, // 남아있는시간
  uploading: false // 업로드 상태
};

const getters = {
  isShowDialog: ({ showDialog }) => showDialog,
  isShowSnackbar: ({ showSnackbar }) => showSnackbar,
  getFiles: ({ files }) => files,
  getGridItems: ({ gridItems }) => gridItems,
  getTotalCount: ({ gridItems }) => gridItems.length,
  getCompletedCount: ({ gridItems }) =>
    gridItems.filter(({ status }) => status == 2).length,
  getCancelTokenSource: ({ cancelTokenSources }) => cancelTokenSources,
  getTotalProgress: ({ totalProgress }) => totalProgress,
  getTotalSize: ({ totalSize }) => totalSize,
  getTotalLoadedSize: ({ totalLoaded }) => totalLoaded,
  getRemainingTime: ({ remainingTime }) => remainingTime,
  getFileIds: ({ gridItems }) => gridItems.map(({ id }) => id),
  isUploadComplete: ({ gridItems }) => {
    const uploadedCount = gridItems.filter(({ status }) => status > 1);

    return uploadedCount.length == gridItems.length;
  },
  isUploading: ({ uploading }) => uploading
};
const mutations = {
  SET_SHOW: (state, { dialog, snackbar }) => {
    state.showDialog = dialog;
    state.showSnackbar = snackbar;
  },
  SET_FILES: (state, files) => {
    Array.from(files).forEach(file => {
      file.key = state.key;
      const { key, name, size } = file;

      state.gridItems.push({
        key,
        name,
        size: size,
        loaded: 0,
        progress: 0,
        status: 0
      });
      state.files.push(file);
      state.key += 1;
    });

    // file 들어오면 totalSize update
    state.totalSize = state.files.reduce((accumulator, currentValue) => {
      return { size: accumulator.size + currentValue.size };
    }).size;
  },
  SET_UPLOAD_STATE: (state, { totalProgress, totalLoaded }) => {
    state.totalProgress = totalProgress;
    state.totalLoaded = totalLoaded;
  },
  SET_UPLOAD_REMAINING_TIME: (state, remainingTime) => {
    const hour = parseInt(remainingTime / 3600);
    const min = parseInt((remainingTime % 3600) / 60);
    const sec = remainingTime % 60;

    if (hour) {
      state.remainingTime = i18n.t("drive.70", {
        hour: hour,
        min: min,
        sec: sec
      });
    } else if (min) {
      state.remainingTime = i18n.t("drive.71", {
        min: min,
        sec: sec
      });
    } else {
      state.remainingTime = i18n.t("drive.72", {
        sec: sec
      });
    }
  },
  SET_UPLOAD_FILE_STATE: (state, { idx, id, progress, loaded, status }) => {
    const {
      progress: stateProgress,
      loaded: stateLoaded,
      status: stateStatus
    } = state.gridItems[idx];
    const fileState = {
      id,
      progress: progress == undefined ? stateProgress : progress,
      loaded: loaded == undefined ? stateLoaded : loaded,
      status: status == undefined ? stateStatus : status
    };

    if (state.totalLoaded == state.totalSize) {
      state.remainingTime = i18n.t("drive.72", { sec: 0 });
    }
    Vue.set(state.gridItems, idx, { ...state.gridItems[idx], ...fileState });
  },
  SET_CANCEL_TOKEN_SOURCE: (state, cancelTokenSource) => {
    state.cancelTokenSource = cancelTokenSource;
  },
  SET_UPLOADING: (state, uploading) => {
    state.uploading = uploading;
  },
  UPLOAD_RESET: state => {
    state.showDialog = false;
    state.showSnackbar = false;
    state.key = 0;
    state.files = [];
    state.gridItems = [];
    state.cancelTokenSources = [];
    state.totalProgress = 0;
    state.totalSize = 0;
    state.totalLoaded = 0;
    state.remainingTime = 0;
    state.uploading = false;
  }
};
const actions = {
  // 업로드 할 파일 설정
  async setFiles(
    { commit, dispatch, rootState, rootGetters, state },
    { files }
  ) {
    try {
      if (rootGetters["auth/isOverQuota"]) {
        return dispatch(
          "snackbar/openSnackbar",
          {
            message: i18n.t("drive.73"),
            type: "ERROR",
            setTop: true
          },
          { root: true }
        );
      }

      // let uploadTotalSize = 0;
      Array.from(files).forEach(file => {
        // 1. 개별용량 체크
        if (file.size > rootState.drive.maxFileSize) {
          throw new Error(
            i18n.t("drive.74", {
              value: byteCalculation(rootState.drive.maxFileSize)
            })
          );
        }
      });
    } catch ({ message }) {
      dispatch(
        "snackbar/openSnackbar",
        {
          message,
          type: "VALIDATION"
        },
        { root: true }
      );
      return false;
    }
    commit("SET_FILES", files);

    if (!state.uploading) {
      // folderId
      const folderId = router.currentRoute.name.includes("shared")
        ? rootGetters["driveRoute/getShareListInfo"].folderId
        : rootGetters["drive/getActiveItems"][0].id;
      dispatch("uploadFile", folderId);
    }
  },
  async uploadFile({ commit, state, dispatch }, folderId) {
    // file이 없으면 return;
    if (state.files.length == 0) return;

    /* 처음에 토스트, 다이얼로그 선택 */
    commit("SET_SHOW", { dialog: true, snackbar: false });

    const startTime = new Date().getTime();
    const beforeLoadedArray = [];
    let beforeProgressTime = 0;

    let idx = 0;
    commit("SET_UPLOADING", true);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      let { files, gridItems, totalSize } = state;

      const file = files[idx];
      if (gridItems[idx].status == 0) {
        // axios를 취소할 때 사용하는 token 생성
        const cancelTokenSource = axios.CancelToken.source();
        commit("SET_CANCEL_TOKEN_SOURCE", cancelTokenSource);

        const config = {
          cancelToken: cancelTokenSource.token,
          onUploadProgress: function(e) {
            let { totalSize: fileTotalSize, totalLoaded } = state;
            const beforeLoaded = beforeLoadedArray[idx]
              ? beforeLoadedArray[idx]
              : 0;
            totalLoaded += e.loaded - beforeLoaded;
            beforeLoadedArray[idx] = e.loaded;

            const totalProgress =
              totalLoaded / fileTotalSize >= 1
                ? 100
                : (totalLoaded / fileTotalSize) * 100;

            // 업로드 전체 progress 퍼센트, 전송량
            commit("SET_UPLOAD_STATE", {
              totalProgress,
              totalLoaded:
                totalLoaded > fileTotalSize ? fileTotalSize : totalLoaded
            });

            // 전송시간
            const progressTime = Math.floor(
              (new Date().getTime() - startTime + 1000) / 1000
            );

            if (progressTime != beforeProgressTime) {
              const remainingTime = Math.floor(
                (fileTotalSize - totalLoaded) / ((e.loaded - beforeLoaded) * 10)
              );
              // 남은 시간
              commit(
                "SET_UPLOAD_REMAINING_TIME",
                remainingTime < 0 ? 0 : remainingTime
              );
            }
            beforeProgressTime = progressTime;

            // 각 파일 progress 퍼센트
            const progress =
              e.loaded / e.total >= 1 ? 100 : (e.loaded / e.total) * 100;
            commit("SET_UPLOAD_FILE_STATE", {
              idx,
              progress,
              loaded: e.loaded > file.size ? file.size : e.loaded,
              status: 1
            });
          }
        };

        const { data, status, message } = await uploadFile(
          file,
          folderId,
          config
        );
        if (message == "cancel") {
          // 이미 전송완료된 파일 사이즈
          const { size: totalLoaded } = gridItems.reduce(
            (accumulator, currentValue) => {
              if (accumulator.status < 2) {
                return { size: 0 };
              } else if (currentValue.status == 2) {
                return { size: accumulator.size + currentValue.size };
              }
              return { size: accumulator.size };
            }
          );
          // 이미 전송완료된 progress 퍼센트
          const totalProgress =
            totalLoaded / totalSize >= 1
              ? 100
              : (totalLoaded / totalSize) * 100;

          commit("SET_UPLOAD_STATE", { totalProgress, totalLoaded });
          commit("SET_UPLOAD_FILE_STATE", {
            idx,
            progress: 0,
            loaded: 0,
            status: 0
          });
          commit("UPLOAD_RESET");
          break;
        } else if (status == 200) {
          // TODO :: 업로드 실패 처리
          const { status, id } = data;
          commit("SET_UPLOAD_FILE_STATE", {
            idx,
            id,
            status
          });
        }
      }

      const fileCount = files.length;
      if (idx + 1 >= fileCount) {
        commit("SET_UPLOADING", false);
        // 용량 업데이트
        await dispatch("auth/setUserQuotaInfo", true, { root: true });
        break;
      }
      idx++;
    }
  },

  // 다이얼로그 -> 토스트바
  minimize({ commit }) {
    commit("SET_SHOW", { dialog: false, snackbar: true });
  },

  // 토스트바 -> 다이얼로그
  maximize({ commit }) {
    commit("SET_SHOW", { dialog: true, snackbar: false });
  },

  close({ commit, state, getters }) {
    const { totalProgress, cancelTokenSource } = state;
    if (totalProgress < 100) {
      cancelTokenSource.cancel("cancel");
    }
    if (getters.isUploadComplete) {
      commit("UPLOAD_RESET");
    }
    commit("SET_UPLOADING", false);
    commit("SET_SHOW", { dialog: false, snackbar: false });
  },
  uploadReset({ commit }) {
    commit("UPLOAD_RESET");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
