<template>
  <div class="cr-todo-menu-btn-wrapper">
    <AddWorkspaceBtn :block="true" :outlined="false" cls="cr-todo-menu-btn" />
  </div>
</template>

<script>
import AddWorkspaceBtn from "@/todo/views/components/main/workspace/AddWorkspaceBtn.vue";

export default {
  components: { AddWorkspaceBtn }
};
</script>
