import http from "@/commons/api/http";
const prefix = "api/flow/projects";

// 프로젝트 멤버 초대
const inviteMembers = (projectId, params) =>
  http.post(`${prefix}/${projectId}/members/invite`, params);

// 프로젝트 멤버 초대 알림 재전송
const resendInvitation = (projectId, memberIds) => {
  const formData = new FormData();
  formData.append("memberIds", memberIds);
  return http.post(`${prefix}/${projectId}/members/invite/resend`, formData);
};

// 프로젝트 참여
const applyProject = projectId =>
  http.post(`${prefix}/${projectId}/members/apply`);

// 프로젝트 참여 알림 재전송
const resendApplication = projectId =>
  http.post(`${prefix}/${projectId}/members/apply/resend`);

// 즐겨찾기 상태 변경
const updateFavoriteStatus = ({ projectId, favoriteStatus }) => {
  const formData = new FormData();
  formData.append("favoriteStatus", favoriteStatus);
  return http.put(`${prefix}/${projectId}/members/favorite`, formData);
};

// 프로젝트 멤버 권한 수정
const updateMemberAuthority = ({ projectId, memberId, authority }) => {
  const formData = new FormData();
  formData.append("memberId", memberId);
  formData.append("authority", authority);
  return http.put(`${prefix}/${projectId}/members/authority`, formData);
};

// 프로젝트 위임
const delegateProject = ({ projectId, memberId }) => {
  const formData = new FormData();
  formData.append("memberId", memberId);
  return http.put(`${prefix}/${projectId}/delegate`, formData);
};

// 프로젝트 초대 수락
const acceptProjectMember = projectId =>
  http.put(`${prefix}/${projectId}/members/accept`);

// 프로젝트 참여 승인
const approveProjectMembers = ({ projectId, memberIds }) => {
  const formData = new FormData();
  formData.append("memberIds", memberIds);
  return http.put(`${prefix}/${projectId}/members/approve`, formData);
};

// 프로젝트 멤버 제외(삭제)
const deleteProjectMembers = ({ projectId, memberIds }) =>
  http.delete(`${prefix}/${projectId}/members`, {
    params: { memberIds }
  });

// 프로젝트 나가기
const leaveProject = projectId => http.delete(`${prefix}/${projectId}/leave`);

export {
  inviteMembers,
  resendInvitation,
  applyProject,
  resendApplication,
  updateFavoriteStatus,
  updateMemberAuthority,
  delegateProject,
  acceptProjectMember,
  approveProjectMembers,
  deleteProjectMembers,
  leaveProject
};
