const secure_state = {
  isSecure: false,
  securePw: "",
  securePwHint: ""
};

const secure_getters = {
  isSecure: ({ isSecure }) => isSecure,
  securePw: ({ securePw }) => securePw,
  securePwHint: ({ securePwHint }) => securePwHint,
  secureState: state => state
};

const secure_mutation = {
  SET_SECURE_STATE: (state, { isSecure, securePw, securePwHint }) => {
    state.isSecure = isSecure;
    state.securePw = securePw;
    state.securePwHint = securePwHint;
  },
  RESET_SECURE_STATE: state => {
    state.isSecure = false;
    state.securePw = "";
    state.securePwHint = "";
  }
};

export { secure_state, secure_getters, secure_mutation };
