<template>
  <v-menu
    ref="menu"
    v-model="show"
    :position-x="x"
    :position-y="y"
    offset-x
    :close-on-click="false"
    :close-on-content-click="false"
    rounded="lg"
    max-width="100%"
    content-class="elevation-24"
    transition="slide-x-transition"
    @input="val => !val && confirmClose()"
  >
    <!-- keydown.stop 미설정 시 탭을 입력하면 메뉴가 닫힙니다. -->
    <div v-if="value" ref="content" @keydown.stop @keydown.esc="confirmClose">
      <LeaveEventEditor
        v-if="isLeave"
        ref="editor"
        :dragging="isDragging"
        :detailMode.sync="detailMode"
        @startDrag="startDrag"
        @changeName="val => handleChange('name', val)"
        @changeCalendar="val => handleChange('calendar', val)"
        @changeAllDay="val => handleChange('isAllDay', val)"
        @changeDateTime="
          val => {
            handleChange('start', val.start);
            handleChange('end', val.end);
          }
        "
        @confirm="close"
        @cancel="confirmClose"
      >
        <template #header>
          <EventTypeToggle
            v-if="!updateMode && isAdvancedPlan"
            v-model="isLeave"
          />
        </template>
      </LeaveEventEditor>
      <GeneralEventEditor
        v-else
        ref="editor"
        :dragging="isDragging"
        :detailMode.sync="detailMode"
        @startDrag="startDrag"
        @changeName="val => handleChange('name', val)"
        @changeCalendar="val => handleChange('calendar', val)"
        @changeAllDay="val => handleChange('isAllDay', val)"
        @changeDateTime="
          val => {
            handleChange('start', val.start);
            handleChange('end', val.end);
          }
        "
        @confirm="close"
        @cancel="confirmClose"
      >
        <template #header>
          <EventTypeToggle
            v-if="!updateMode && isAdvancedPlan"
            v-model="isLeave"
          />
        </template>
      </GeneralEventEditor>
    </div>

    <!-- 편집 후 닫는 경우 확인 다이얼로그 -->
    <v-dialog
      v-model="cancelConfirmDialog"
      width="auto"
      @keydown.enter.stop="close"
    >
      <v-card width="360px">
        <v-card-title>
          {{ $t("calendar.cancelEventEdit") }}
        </v-card-title>
        <v-card-text>
          {{ $t("calendar.confirmCancelEventEdit") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelConfirmDialog = false">
            {{ $t("common.계속") }}
          </v-btn>
          <v-btn outlined color="primary" @click="close">
            {{ $t("common.확인") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import storage from "@/commons/api/storage";
import GeneralEventEditor from "@/calendar/components/event/GeneralEventEditor.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import LeaveEventEditor from "@/calendar/components/event/leave/LeaveEventEditor.vue";
import EventTypeToggle from "@/calendar/components/event/EventTypeToggle.vue";

export default {
  components: { EventTypeToggle, LeaveEventEditor, GeneralEventEditor },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    centerRelativeTo: {
      type: String,
      default: null
    },
    // 메뉴가 화면을 넘어가는 경우, 특정 요소의 중앙에 위치시킵니다.
    // 예를 들어 '.cr-calendar-main'을 지정하면 캘린더 화면의 중앙에 메뉴가 위치합니다.
    centerOverflowRelativeTo: {
      type: String,
      default: null
    },
    centerHorizontallyOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("cal", ["editEvent", "eventEditor"]),
    ...mapGetters("auth", ["getPlanType", "getCompanyInfo"]),
    isAdvancedPlan() {
      if (this.getPlanType === "ADVANCED") return true;
      return (
        this.getPlanType === "CUSTOM" &&
        this.getCompanyInfo?.customizeUI?.flagHR === 1
      );
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      show: false,
      isLeave: false,
      sourceEvent: null,
      resizeObserver: null,
      overflowed: false,
      dragStart: {
        x: 0,
        y: 0
      },
      isDragging: false,
      cancelConfirmDialog: false,
      detailMode: false,
      updateMode: false
    };
  },
  watch: {
    value() {
      this.show = this.value;
      if (this.value) {
        this.init();
        this.$nextTick(() => this.observeResize());
      } else {
        this.clear();
      }
    },
    detailMode(state) {
      storage.setEventDialogDetail(state ? 1 : 0);
    },
    eventEditor: {
      handler: function() {
        // 메뉴가 열려있을 때 위치 변경 시 메뉴 위치를 조정합니다.
        if (!this.value) return;

        if (this.updatePosition()) {
          this.show = false;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.show = true))
          );
        }
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  mounted() {
    // 편집기 드래그 이동을 위한 이벤트 감지 시작
    window.addEventListener("mousemove", this.handleDrag);
    window.addEventListener("mouseup", this.endDrag);

    // 이벤트 다이얼로그 마지막 상태값
    const detailState = storage.getEventDialogDetail();
    this.detailMode = detailState == 1;
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleDrag);
    window.removeEventListener("mouseup", this.endDrag);
    if (this.show) {
      this.clear();
    }
  },
  methods: {
    ...mapMutations("cal", [
      "SET_EVENT_EDITOR",
      "REPLACE_EDIT_EVENT_PROPS",
      "UPDATE_EDIT_EVENT",
      "REMOVE_EDIT_EVENT"
    ]),
    init() {
      this.show = this.value;
      this.updatePosition();

      this.updateMode = this.editEvent && !this.editEvent.isCreating;
      if (this.updateMode) {
        this.sourceEvent = { ...this.editEvent };

        if (this.editEvent.detail.isLeave) {
          this.isLeave = true;
        }
      }
    },
    clear() {
      // 수정 모드의 편집기를 닫는 경우 수정 전 데이터로 원복합니다.
      if (this.updateMode) {
        this.REPLACE_EDIT_EVENT_PROPS(this.sourceEvent);
      }
      this.sourceEvent = null;

      this.REMOVE_EDIT_EVENT();
      this.SET_EVENT_EDITOR({
        x: 0,
        y: 0,
        isExceptionRequest: false,
        fixed: false
      });

      this.isLeave = false;
      this.overflowed = false;
      this.resizeObserver?.disconnect();
    },
    confirmClose() {
      if (this.isChanged()) {
        this.$refs.menu.isActive = true;
        this.cancelConfirmDialog = true;
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("input", false);
    },
    /**
     * 메뉴 콘텐츠 크기 변경 시 메뉴 위치를 재계산합니다.
     * 바쁨 여부 등 세부 정보를 표시할 때 메뉴가 화면 밖으로 나가는 것을 방지합니다.
     */
    observeResize() {
      this.resizeObserver?.disconnect();
      this.resizeObserver = new ResizeObserver(() =>
        this.$refs.menu.onResize()
      );
      this.resizeObserver.observe(this.$refs.content);
    },
    /**
     * 편집기에서 값을 변경하면 편집 중인 일정에도 변경사항을 적용합니다.
     */
    handleChange(propName, value) {
      if (this.editEvent) {
        this.UPDATE_EDIT_EVENT({ propName, value });
      }
    },
    isChanged() {
      if (this.updateMode) {
        return (
          this.sourceEvent.name !== this.$refs.editor.summary ||
          this.sourceEvent.location !== this.$refs.editor.location ||
          this.sourceEvent.description !== this.$refs.editor.description ||
          this.sourceEvent.attendees.length !==
            (this.$refs.editor.attendees
              ? this.$refs.editor.attendees.length
              : 0)
        );
      } else if (this.editEvent) {
        return (
          !!this.$refs.editor.summary ||
          !!this.$refs.editor.location ||
          !!this.$refs.editor.description ||
          !!this.$refs.editor.attendees?.length
        );
      }
      return false;
    },
    startDrag(e) {
      this.overflowed = false;
      this.isDragging = true;
      const { x, y } = this.calculateAdjustedScreenPosition({
        x: this.x,
        y: this.y
      });
      this.dragStart.x = e.clientX - x;
      this.dragStart.y = e.clientY - y;
    },
    handleDrag(e) {
      if (this.isDragging) {
        const { x, y } = this.calculateAdjustedScreenPosition({
          x: e.clientX - this.dragStart.x,
          y: e.clientY - this.dragStart.y
        });

        this.x = x;
        this.y = y;
      }
    },
    endDrag() {
      this.isDragging = false;
    },
    updatePosition() {
      let { x, y, overflowed } = this.calculateAdjustedScreenPosition({
        x: this.eventEditor.x,
        y: this.eventEditor.y
      });
      if (overflowed) {
        this.overflowed = true;
      }

      if (
        this.centerRelativeTo ||
        (this.centerOverflowRelativeTo && this.overflowed)
      ) {
        const center = this.calculateCenterPosition();
        x = center.x;
        if (!this.centerHorizontallyOnly) {
          y = center.y;
        }
      }

      if (this.x === x && this.y === y) return false;

      this.x = x;
      this.y = y;
      return true;
    },
    /**
     * 편집기가 화면 밖으로 나가지 않도록 조정된 위치 정보를 반환합니다.
     */
    calculateAdjustedScreenPosition({ x, y }) {
      let overflowed = false;
      const { width, height } = this.getContentSize();

      if (x + width > window.innerWidth) {
        x = window.innerWidth - width - 8;
        overflowed = true;
      } else if (x < 8) {
        x = 8;
      }

      if (y + height > window.innerHeight) {
        y = window.innerHeight - height - 8;
      } else if (y < 8) {
        y = 8;
      }

      return { x, y, overflowed };
    },
    /**
     * 편집기가 화면 중앙에 위치하도록 하는 위치 정보를 반환합니다.
     */
    calculateCenterPosition() {
      const view = document.querySelector(
        this.centerRelativeTo ?? this.centerOverflowRelativeTo
      );
      const viewRect = view.getBoundingClientRect();
      const { width, height } = this.getContentSize();

      return {
        x: viewRect.left + view.clientWidth / 2 - width / 2,
        y: viewRect.top + view.clientHeight / 2 - height / 2
      };
    },
    /**
     * 메뉴 콘텐츠 크기를 반환합니다. 콘텐츠가 아직 렌더링되지 않은 경우 기본 크기를 반환합니다.
     */
    getContentSize() {
      return {
        width: this.$refs.content?.clientWidth
          ? this.$refs.content?.clientWidth
          : 412,
        height: this.$refs.content?.clientHeight
          ? this.$refs.content.clientHeight
          : 490
      };
    }
  }
};
</script>

<style scoped lang="scss">
.v-menu__content {
  padding: 0;
}
</style>
