import http from "./http";

const AUTH_KEY_REQUEST = "/oauth/key";
const AUTH_REQUEST = "/oauth/token";

const authKey = () => http.post(AUTH_KEY_REQUEST);

// 로그인
const login = (
  email,
  password,
  authentication_code,
  grant_type = "password"
) => {
  const formData = new FormData();
  formData.append("username", email);
  formData.append("password", password);
  formData.append("grant_type", grant_type);
  if (authentication_code) {
    formData.append("authentication_code", authentication_code);
  }

  // 로그인에서 사용하는 기본 해더
  http.defaults.headers.common[
    "Authorization"
  ] = `Basic ${process.env.VUE_APP_API_CLIENT}`;

  return http.post(AUTH_REQUEST, formData);
};

// 유저리스트 조회
const getUserDetail = () => http.get("/api/users/detail");

/**
 * 유저 용량 정보
 * @returns
 */
const getUserQuota = () => http.get("/api/users/quota");

// 로그인 한 유저의 회사 정보 조회
const getCompanyInfo = () => http.get("/api/company");

// 사용자 서비스 설정 조회
const getUserServiceConfig = () => http.get("/api/users/service/config");

// 튜토리얼 정보 수정
const updateTutorialStatus = ({ module, status }) => {
  return http.put(`/api/users/service/config/${module}/tutorial/${status}`);
};

// 사용자 테마 변경
const updateTheme = ({ theme }) => {
  return http.put(`/api/users/theme/${theme}`);
};

const checkToken = () => http.get("/api/tokenChecker");

const logout = () => http.get("/oauth/logout");

export {
  authKey,
  login,
  logout,
  getUserDetail,
  getUserQuota,
  getCompanyInfo,
  getUserServiceConfig,
  checkToken,
  updateTutorialStatus,
  updateTheme
};
