import http from "@/commons/api/http";

const prefix = "api/mail/history";

// 메일검색 히스토리 조회
const getMailSearchHistory = () => http.get(prefix);

const deleteMailSearchHistory = (id, isAll) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("isAll", isAll);
  return http.delete(prefix, { data: formData });
};

export { getMailSearchHistory, deleteMailSearchHistory };
