import http from "@/commons/api/http";

const prefix = "api/contact/detail";

/**
 * 연락처 상세 정보 조회
 *
 * @param {*} param0
 * @returns
 */
const getContactDetail = ({ id }) => http.get(`${prefix}/${id}`);

/**
 * 연락처 상세 정보 리스트 조회
 *
 * @param {*} params
 * @returns
 */
const getContactDetailList = params => http.get(prefix, { params });

/**
 * 연락처 상세 정보 - 메일 히스토리
 * @param id
 * @returns
 */
const getContactMailHistory = ({ id }) =>
  http.get(`${prefix}/${id}/mail/history`);

export { getContactDetail, getContactDetailList, getContactMailHistory };
