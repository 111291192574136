<template>
  <Appbar />
</template>

<script>
import Appbar from "../components/appbar";

export default {
  components: { Appbar }
};
</script>
