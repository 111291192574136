import http from "@/commons/api/http";
const prefix = "api/flow/projects";

// 그룹 목록 조회
const getGroups = projectId => http.get(`${prefix}/${projectId}/groups`);

// 그룹 추가
const addGroup = projectId => http.post(`${prefix}/${projectId}/groups`);

// 그룹명 수정
const updateGroupTitle = ({ projectId, groupId, title }) => {
  const formData = new FormData();
  formData.append("title", title);
  return http.put(`${prefix}/${projectId}/groups/${groupId}/title`, formData);
};

// 그룹 이동(순서변경)
const moveGroup = ({ projectId, groupId, targetGroupId, position }) => {
  const formData = new FormData();
  formData.append("targetGroupId", targetGroupId);
  formData.append("position", position);

  return http.put(`${prefix}/${projectId}/groups/${groupId}/move`, formData);
};

// 그룹 삭제
const deleteGroup = (projectId, groupId) =>
  http.delete(`${prefix}/${projectId}/groups/${groupId}`);

export { getGroups, addGroup, updateGroupTitle, moveGroup, deleteGroup };
