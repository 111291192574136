import Vue from "vue";
import axios from "axios";
import {
  getItemDetail,
  saveContent,
  addCheckList,
  updateDetailCheckListTitle,
  updateDetailCheckListChecked,
  deleteDetailCheckList,
  uploadFile,
  deleteFile,
  addComment,
  updateComment,
  deleteComment
} from "@/todo/api/detail.api";
import { isBlank } from "@/commons/utils/validation";

const state = {
  show: false,
  contentObj: {},
  checkList: [],
  files: [],
  cancelTokenSources: {},
  commentList: []
};

const getters = {
  show: ({ show }) => show,
  contentObj: ({ contentObj }) => contentObj,
  emptyContent: ({ contentObj }) =>
    !contentObj?.content || contentObj?.content?.length === 0,
  checkList: ({ checkList }) => checkList,
  files: ({ files }) => files,
  cancelTokenSources: ({ cancelTokenSources }) => cancelTokenSources,
  commentList: ({ commentList }) => commentList
};

const mutations = {
  RESET_DETAIL: state => {
    state.contentObj = {};
    state.checkList = [];
    state.files = [];
    state.cancelTokenSources = {};
    state.commentList = [];
  },
  SET_DETAIL: (state, { show }) => {
    state.show = show;
  },
  SET_CONTEN_OBJ: (state, contentObj) => {
    state.contentObj = contentObj ?? {};
  },
  SET_CHECK_LIST: (state, checkList) => {
    state.checkList = checkList;
  },
  SET_FILES: (state, files) => {
    state.files = files;
  },
  ADD_FILE: (state, file) => {
    state.files.unshift(file);
  },
  CHANGE_FILE: (state, { key, file }) => {
    const index = state.files.findIndex(f => f.key === key);
    if (index === -1) return;

    Vue.set(state.files, index, file);
  },
  DELETE_FILE: (state, id) => {
    const index = state.files.findIndex(f => f.id === id);
    if (index === -1) return;

    Vue.delete(state.files, index);
  },
  CHANGE_FILE_PROGRESS: (state, { key, progress }) => {
    const index = state.files.findIndex(f => f.key === key);
    if (index === -1) return;

    state.files[index].progress = progress;
  },
  SET_CANCEL_TOKEN_SOURCES: (state, cancelTokenSources) => {
    state.cancelTokenSources = cancelTokenSources;
  },
  SET_COMMENT_LIST: (state, commentList) => {
    state.commentList = commentList.map(c => ({ ...c, edit: false }));
  },
  SET_COMMENT_EDIT: (state, { id, edit }) => {
    const index = state.commentList.findIndex(c => c.id === id);
    if (index === -1) return;

    Vue.set(state.commentList[index], "edit", edit);
  }
};

const actions = {
  async getItemDetail(
    { commit, dispatch },
    { itemId, parentId, groupId, boardId }
  ) {
    const { status, data } = await getItemDetail(itemId, boardId);
    const { content, checkList, fileList, commentList } = data;

    let message = "아이템 상세정보를 가져오는데 실패했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 200: {
        commit("SET_CONTEN_OBJ", content);
        commit("SET_CHECK_LIST", checkList);
        commit("SET_FILES", fileList);
        commit("SET_COMMENT_LIST", commentList);
        commit(
          "todoItem/SET_ITEM_ATTRS",
          {
            itemId,
            groupId,
            parentId,
            keyValues: [
              { key: "existContent", value: !isBlank(content) },
              { key: "checkListCnt", value: checkList.length },
              { key: "attachCnt", value: fileList.length },
              { key: "commentCnt", value: commentList.length }
            ]
          },
          { root: true }
        );
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async saveContent(
    { commit, dispatch },
    { itemId, parentId, groupId, boardId, content }
  ) {
    const { status, data } = await saveContent(itemId, boardId, content);

    let message = "아이템 상세 내용을 저장하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 201: {
        commit("SET_CONTEN_OBJ", data);
        commit(
          "todoItem/SET_ITEM_ATTRS",
          {
            itemId,
            groupId,
            parentId,
            keyValues: [{ key: "existContent", value: !isBlank(content) }]
          },
          { root: true }
        );
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async addCheckList({ commit, dispatch }, { itemId, boardId, title }) {
    const { status, data } = await addCheckList(itemId, boardId, title);

    let message = "아이템 상세 체크리스트 내용을 저장하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 201: {
        commit("SET_CHECK_LIST", data);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateDetailCheckListTitle(
    { commit, dispatch },
    { id, itemId, boardId, title }
  ) {
    const { status, data } = await updateDetailCheckListTitle(
      id,
      itemId,
      boardId,
      title
    );

    let message = "아이템 상세 체크리스트 타이틀을 수정하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 201: {
        commit("SET_CHECK_LIST", data);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async updateDetailCheckListChecked(
    { commit, dispatch },
    { id, itemId, boardId, checked }
  ) {
    const { status, data } = await updateDetailCheckListChecked(
      id,
      itemId,
      boardId,
      checked ? 1 : 0
    );

    let message = "아이템 상세 체크리스트를 수정하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 201: {
        commit("SET_CHECK_LIST", data);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async deleteDetailCheckList({ commit, dispatch }, { id, itemId, boardId }) {
    const { status, data } = await deleteDetailCheckList(id, itemId, boardId);

    let message = "아이템 상세 체크리스트를 삭제하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다";
        break;
      }
      case 200: {
        commit("SET_CHECK_LIST", data);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  setFiles({ commit, dispatch }, { itemId, boardId, files }) {
    // 최대 25MB (26214400)
    try {
      const cancelTokenSources = {};
      Array.from(files).forEach((file, idx) => {
        if (file.name.length > 200) {
          throw new Error("파일의 이름은 200글자 이하여야 합니다.");
        }

        const fileObj = {
          key: `${file.name}_${idx}`,
          name: file.name,
          size: file.size,
          contentType: file.type,
          uploadType: "DETAIL_FILE",
          status: 0,
          progress: 0
        };

        // 파일 용량 확인
        if (fileObj.size > 26214400) {
          throw new Error("업로드 가능한 파일 크기는 25MB입니다.");
        }

        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSources[fileObj.key] = cancelTokenSource;
        commit("ADD_FILE", fileObj);

        // progress event
        const config = {
          cancelToken: cancelTokenSource.token,
          onUploadProgress: function(e) {
            cancelTokenSource.token,
              // 업로드 progress 퍼센트
              commit("CHANGE_FILE_PROGRESS", {
                key: fileObj.key,
                progress: Math.floor(
                  e.loaded / e.total >= 1 ? 100 : (e.loaded / e.total) * 100
                )
              });
          }
        };

        uploadFile(itemId, boardId, fileObj.uploadType, file, config)
          .then(response => {
            if (response.status == 200) {
              // TODO:: 업로드 실패처리
              setTimeout(() => {
                commit("CHANGE_FILE", {
                  key: fileObj.key,
                  file: {
                    ...(response.data?.file ?? fileObj),
                    status: response.data.status
                  }
                });
              }, 500);
            }
          })
          .catch(() => {
            // console.log("error = ", error);
          });
      });

      commit("SET_CANCEL_TOKEN_SOURCES", cancelTokenSources);
    } catch ({ message }) {
      dispatch(
        "snackbar/openSnackbar",
        { message, type: "ERROR" },
        { root: true }
      );
    }
  },
  async deleteFile({ commit, dispatch }, { itemId, boardId, id }) {
    const { status } = await deleteFile(itemId, boardId, id);

    let message = "파일을 삭제하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 파일이 존재하지 않습니다.";
        break;
      }
      case 204: {
        commit("DELETE_FILE", id);
        return;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
  },
  async addComment({ commit, dispatch }, { itemId, boardId, content }) {
    const { status, data } = await addComment(itemId, boardId, content);

    let message = "댓글을 등록하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 404: {
        message = "해당 아이템이 존재하지 않습니다.";
        break;
      }
      case 201: {
        commit("SET_COMMENT_LIST", data);
        return true;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return false;
  },
  async updateComment({ commit, dispatch }, { itemId, boardId, id, content }) {
    const { status, data } = await updateComment(itemId, boardId, id, content);

    let message = "댓글을 수정하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 403: {
        message = "해당 댓글에 대한 권한이 없습니다.";
        break;
      }
      case 404: {
        message = "해당 댓글이 존재하지 않습니다.";
        break;
      }
      case 201: {
        commit("SET_COMMENT_LIST", data);
        return true;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return false;
  },
  async deleteComment({ commit, dispatch }, { itemId, boardId, id }) {
    const { status, data } = await deleteComment(itemId, boardId, id);

    let message = "댓글을 삭제하지 못했습니다.";
    let type = "ERROR";
    switch (status) {
      case 403: {
        message = "해당 댓글에 대한 권한이 없습니다.";
        break;
      }
      case 404: {
        message = "해당 댓글이 존재하지 않습니다.";
        break;
      }
      case 200: {
        commit("SET_COMMENT_LIST", data);
        return true;
      }
    }

    dispatch("snackbar/openSnackbar", { message, type }, { root: true });
    return false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
