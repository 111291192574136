import axios from "axios";
import qs from "query-string";
import store from "@/commons/store";
import http from "@/commons/api/http.js";

const googleHttp = axios.create({
  baseURL: "https://www.googleapis.com"
});

/**
 * request interceptor
 * @param {*} config
 * @returns
 */
const requestInterceptor = config => {
  const token = store.getters["driveExternalToken/getTokenByExternalType"](
    "google"
  );
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  // [] 대괄호 인코딩
  config.paramsSerializer = params =>
    qs.stringify(params, { arrayFormat: "brackets" });
  return config;
};

googleHttp.interceptors.request.use(requestInterceptor, error => {
  Promise.reject(error);
});

const refreshToken = async () => {
  const result = await http
    .put("/api/external/token/GOOGLE_DRIVE")
    .then(res => {
      if (res.status === 200) {
        store.commit("driveExternalToken/SET_EXTERNAL_TOKEN", [res.data]);
        return true;
      }
    })
    .catch(() => {
      return false;
    });
  if (!result) store.commit("driveExternalToken/DELETE_TOKEN", "GOOGLE_DRIVE");
  return result;
};

/**
 * response interceptor
 */
googleHttp.interceptors.response.use(
  response => {
    return response;
  },
  async ({ config: originalRequest = {}, response = {}, message }) => {
    const { status } = response;
    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (await refreshToken()) {
        const res = await googleHttp(originalRequest);
        return res;
      }
    }

    return { message, ...response };
  }
);

export default googleHttp;
