const state = {
  show: false,
  type: "",
  params: {}
};

const getters = {
  show: ({ show }) => show,
  type: ({ type }) => type,
  params: ({ params }) => params
};

const mutations = {
  SET_MODAL: (state, { type, params }) => {
    state.show = true;
    state.type = type ?? "";
    state.params = params ?? {};
  },
  SET_PARAMS: (state, params) => {
    state.params = params;
  },
  CLOSE_MODAL: state => {
    state.show = false;
    state.type = "";
    state.params = {};
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
