import { getShareList } from "../../api/drive.api";
import { getDateWithTime } from "@/commons/utils/moment";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import i18n from "@/_locales";

const state = {
  shareList: [],
  shareHeaders: [
    {
      text: "이름",
      value: "fileName",
      sortable: true,
      use: true
    },
    {
      text: "소유자",
      value: "owner",
      width: "15%",
      sortable: false
    },
    {
      text: "57",
      value: "usedTimeMillis",
      width: "15%",
      sortable: true
    },
    {
      text: "크기",
      value: "fileSize",
      width: "10%",
      sortable: false
    }
  ],
  totalElements: 0,
  loading: false,
  permission: 0
};

const getters = {
  getShareList: ({ shareList }) => {
    const resultList = shareList.map(
      ({
        id,
        fileType,
        fileName,
        owner,
        extension,
        isShortcut,
        fileSize,
        usedTimeMillis
      }) => {
        return {
          id,
          fileType,
          owner,
          fileName,
          extension,
          isShortcut,
          icon: fileIcons[extension] ? fileIcons[extension] : fileIcons["file"],
          iconColor:
            fileType == "DIRECTORY"
              ? ""
              : fileIcons[extension]
              ? fileIconColors[extension]
              : fileIconColors["file"],
          fileSize: fileSize > 0 ? byteCalculation(fileSize) : "—",
          usedTimeMillis:
            usedTimeMillis > 0 ? getDateWithTime(usedTimeMillis) : "—"
        };
      }
    );

    return resultList;
  },
  getShareHeaders: ({ shareHeaders }) =>
    shareHeaders.map(h => ({ ...h, text: i18n.t(`drive.${h.text}`) })),
  getTotalElements: ({ totalElements }) => totalElements,
  getLoading: ({ loading }) => loading,
  getPermission: ({ permission }) => permission
};

const mutations = {
  SET_PAGE: (state, { files, totalElements, permission = 0 }) => {
    state.shareList = files;
    state.totalElements = totalElements;
    state.loading = false;
    state.permission = permission;
  },
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_PERMISSION: (state, permission) => (state.permission = permission),
  INIT_SHARE_DATA: state => {
    state.shareList = [];
    state.totalElements = 0;
    state.loading = false;
    state.permission = 0;
  }
};

const actions = {
  async getShareDriveList(
    { commit, dispatch },
    { folderId, page = 1, pageSize = 10, actionObj = "{}" }
  ) {
    commit("SET_LOADING", true);
    const {
      sort = "usedTimeMillis",
      dir = "desc",
      searchWord = ""
    } = actionObj;

    const { status = 401, data = [] } = await getShareList({
      folderId,
      pageSize,
      page: page - 1,
      sort,
      dir,
      searchWord
    });

    const type = status != 200 ? "ERROR" : data.result ? "SUCCESS" : "ERROR";
    if (type == "ERROR") {
      let msg = i18n.t("drive.65");
      if (!data.result) {
        switch (data.message) {
          case "permission":
            msg = i18n.t("drive.66");
            break;
          default:
            break;
        }
      }
      commit("SET_LOADING", false);
      commit("SET_PAGE", {
        files: [],
        totalElements: 0,
        permission: 0
      });
      return dispatch(
        "snackbar/openSnackbar",
        {
          message: msg,
          type
        },
        { root: true }
      );
    }
    const { content = [], totalElements } = data.list;
    commit("SET_PAGE", {
      files: content,
      totalElements,
      permission: data.permission
    });

    commit("SET_LOADING", false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
