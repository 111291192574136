import router from "@/commons/router";

const root = { root: true };
const state = {
  projectId: 0,
  actionObj: "{}"
};

const getters = {
  filterId: ({ actionObj }) => {
    try {
      return JSON.parse(actionObj)?.filterId;
    } catch (e) {
      return undefined;
    }
  },
  searchWord: ({ actionObj }) => {
    try {
      return JSON.parse(actionObj)?.searchWord;
    } catch (e) {
      return "";
    }
  },
  viewType: ({ actionObj }, { viewTypes }, y, RG) => {
    try {
      // 프로젝트 첫 화면 설정
      let { mainViewTab: viewType } = RG["flowProjects/selectedProject"] || {};
      viewType = (JSON.parse(actionObj)?.viewType || viewType).toUpperCase();

      if (!viewTypes.find(v => v.type === viewType)) return "LIST";
      return viewType;
    } catch (e) {
      return "LIST";
    }
  },
  viewTypes: () => [
    { type: "LIST", title: "리스트" },
    { type: "KANBAN", title: "칸반보드" },
    { type: "GANTT", title: "간트차트" }
  ]
};

const mutations = {
  SET_ROUTE_PARAMS: (state, params) => {
    Object.keys(params).forEach(key => (state[key] = params[key]));
  }
};

const actions = {
  async setParams({ rootState, commit, dispatch }, params) {
    let { routeName, projectId, actionObj = "{}" } = params;
    console.log("setParams start -- ", params, routeName);

    const { flowFolder } = rootState;
    if (!flowFolder.folders.length) {
      await dispatch("flowFolder/getDefaultFolders", null, root);
    }

    // 내 프로젝트 목록 호출
    if (routeName === "flow_projects") {
      commit("flowProjects/INIT_PROJECTS", projectId, root);
      dispatch("flowProjects/getMyProjects", null, root);
    }

    commit("SET_ROUTE_PARAMS", { projectId, actionObj });
  },

  routeDashboard() {
    router.push({ name: "flow" });
  },
  routeProjects() {
    router.push({ name: "flow_projects" });
  },
  routeTasks(actions, { projectId, actionObj }) {
    let name = "flow_tasks";
    const params = { projectId };
    if (actionObj && typeof actionObj === "object") {
      name = "flow_tasks_action";
      params["actionObj"] = JSON.stringify(actionObj);
    }

    router.push({ name, params });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
