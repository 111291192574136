<template>
  <v-treeview
    dense
    hoverable
    activatable
    transition
    return-object
    class="pt-2"
    item-text="name"
    :items="items"
    :active.sync="activeItems"
    :open.sync="openItems"
    :load-children="loadChildren"
    @update:active="onActive"
  >
    <template v-slot:label="{ item }">
      <div class="cr-node-click" :title="item.name" @click="nodeClick(item)" />
      <div class="text-truncate">
        <span>{{ item.name }}</span>
      </div>
    </template>
  </v-treeview>
</template>

<style lang="scss" scoped>
.v-treeview::v-deep {
  height: calc(100% - 40px);
  overflow-y: auto;
  // background: #f9f9f9;

  .v-treeview-node__root {
    padding-left: 4px;
    padding-right: 4px;

    .v-treeview-node__level {
      width: 20px;
    }
    .v-treeview-node__toggle {
      z-index: 2;
      font-size: 20px;
      width: 20px;
    }
    .v-treeview-node__content {
      margin-left: 3px;
      cursor: pointer;
      .v-treeview-node__prepend {
        min-width: 20px;
        .v-icon {
          font-size: 20px;
        }
      }
      .v-treeview-node__label {
        height: 20px;
        .cr-node-click {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    selectorType: {
      type: String,
      default: "contact"
    }
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      openItems: [],
      activeItems: [],
      prevActiveItem: null
    };
  },
  computed: {
    ...mapGetters("organ", {
      activeNode: "activeNode",
      organs: "getOrgan",
      contacts: "getContact",
      customers: "getCustomer",
      organFullCode: "getFullCode"
    }),
    items() {
      return this[`${this.selectorType}s`];
    }
  },
  watch: {
    selectorType() {
      this.loadData();
    },
    activeNode(node) {
      if (node?.id === "search") {
        this.prevActiveItem = null;
        this.activeItems = [];
      }
    }
  },
  methods: {
    ...mapMutations("organ", ["SET_LIST", "SET_ACTIVE_NODE"]),
    ...mapActions("organ", [
      "getCardList",
      "getContactGroupData",
      "getGroupsByParentId",
      "getOrganData",
      "getUserOrganCode",
      "getOrganByParentId",
      "getOrganUserList",
      "getCustomerDefaultList",
      "getCustomerChildrenByParentId",
      "getCustomerCardList"
    ]),
    onActive([n = {}]) {
      const PR = "PRIVATE_ROOT";

      // 선택된 노드를 한번 더 클릭할 시 액티브 사라짐을 방지
      if (!n?.id) {
        if (this.prevActiveItem) this.activeItems = [this.prevActiveItem];
        return;
      }

      if (n?.contactGroupType === PR) {
        const item = this.openItems.find(i => i.contactGroupType == PR);

        if (!item) {
          this.openItems.push(n);
        } else {
          this.openItems = this.openItems.filter(i => i.contactGroupType != PR);
        }

        // 메일함이 선택된 상태에서 root폴더함을 눌렀을때 기존 선택된 메일함 active해제방지
        this.activeItems = [this.prevActiveItem];
        return;
      }

      this.prevActiveItem = n;
      this.SET_ACTIVE_NODE(n);
    },

    async loadData() {
      this.SET_LIST({ list: [], totalElements: 0, page: 1 });
      this.openItems = [];
      this.activeItems = [];

      let root;
      switch (this.selectorType) {
        case "organ":
          if (!this.items.length) await this.getOrganData();
          if (!this.organFullCode) await this.getUserOrganCode();

          root = this.items.find(o => o.parentId == "ROOT");
          if (!root) return;
          if (this.organFullCode) return this.userOrganActive();

          this.openItems = [root];
          this.activeItems = [root];
          await this.getOrganUserList({ organId: root.id, page: 1 });
          return;
        case "customer":
          if (!this.items.length) await this.getCustomerDefaultList();
          if (!this.items.length) {
            this.SET_ACTIVE_NODE({});
            return;
          }
          await this.getCustomerCardList({
            customerGroupId: this.items[0].id,
            page: 1
          });
          this.activeItems = [this.items[0]];
          return;
        default:
          // 주소록 데이터 가져오기
          if (!this.items.length) await this.getContactGroupData();
          // 전체 폴더 활성화
          this.activeItems = this.items.filter(
            c => c.contactGroupType == "ALL"
          );
          await this.getCardList({ contactGroupId: -1, page: 1 });
          // 폴더 오픈
          root = this.items.find(c => c.contactGroupType == "ALL");
          if (root?.childCount) this.openItems = [root];
          return;
      }
    },
    async loadChildren(item) {
      switch (this.selectorType) {
        case "organ":
          await this.getOrganByParentId({ parentId: item.id });
          return;
        case "customer":
          await this.getCustomerChildrenByParentId({ parentId: item.id });
          return;
        default:
          await this.getGroupsByParentId({ parentId: item.id });
          return;
      }
    },
    async userOrganActive() {
      if (!this.items.length) return;
      const codes = this.organFullCode.split("/");
      let parentOrgan = this.items;

      for (let i = 0; i <= codes.length; i += 1) {
        const code = codes[i];
        const organ = parentOrgan?.find(o => o.id == code);
        if (!organ) return;
        if (organ.childCount && !organ.children.length) {
          await this.getOrganByParentId({ parentId: code });
        }

        parentOrgan = organ.children;
        const idx = this.openItems.findIndex(o => o.id == code);
        if (idx !== -1) continue;

        this.openItems.push(organ);
        if (i === codes.length - 1) {
          this.activeItems = [organ];
          await this.getOrganUserList({ organId: organ.id, page: 1 });
        }
      }
    },
    nodeClick(item) {
      if (item?.contactGroupType === "PRIVATE_ROOT") return;
      switch (this.selectorType) {
        case "organ":
          this.getOrganUserList({ organId: item.id, page: 1 });
          return;
        case "customer":
          this.getCustomerCardList({ customerGroupId: item.id, page: 1 });
          return;
        default:
          this.getCardList({ contactGroupId: item.id, page: 1 });
          return;
      }
    }
  }
};
</script>
