<template>
  <div
    class="cr-tree-wrapper"
    :class="$vuetify.breakpoint.lgAndUp ? '' : 'mobile'"
  >
    <v-treeview
      dense
      hoverable
      activatable
      transition
      shaped
      return-object
      :items="treeItems"
      :active.sync="activeItems"
      :open.sync="openItems"
      @update:active="onActive"
    >
      <template v-slot:prepend="{ item }">
        <v-icon
          :class="
            item.showChildCount > 0 || item.id == item.groupId ? '' : 'ml-3'
          "
          :color="getIconColor(item)"
          v-text="getIcon(item)"
        ></v-icon>
      </template>
      <template v-slot:label="{ item }">
        <v-hover v-slot:default="{ hover }">
          <div
            :data-id="item.id"
            :class="draggableClass(item)"
            style="margin-top: 1px;"
          >
            <div
              class="cr-tree_node_click_area"
              @click="e => nodeClick(e, item)"
            />
            <!-- 게시판 이름 -->
            <span class="cr-tree-label-text" v-if="!item.isEdit">
              {{ item.name }}
            </span>
            <!-- 게시판 이름 수정필드 -->
            <v-text-field
              autofocus
              v-if="item.isEdit"
              :label="item.name"
              :loading="editTextLoading"
              v-on:blur="e => editBoardName(e, item)"
              v-on:keyup.enter="e => editBoardName(e, item)"
              v-on:keyup.esc="e => editBtnClick(e, item)"
            ></v-text-field>
            <!-- 휴지통 비우기 버튼 -->
            <v-btn
              class="tree-append-right cr-tutorial-board-trash"
              icon
              small
              v-if="item.boardType === 'TRASH'"
              @click="e => cleanBtnClick(e, item)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <!-- 태그 관리 버튼 -->
            <v-btn
              class="tree-append-right"
              icon
              small
              v-if="item.boardType === 'TAG'"
              @click="e => openTagDialog(e)"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <!-- 컨텍스트 메뉴 -->
            <v-btn
              icon
              small
              v-if="
                hover &&
                  !item.isEdit &&
                  !editTextLoading &&
                  item.boardType == 'BOARD' &&
                  !item.isDeleted
              "
              class="tree-append-right"
              :class="item.boardType == 'BOARD' ? 'root-context' : ''"
              @click.stop="e => openPositioningMenu(e, item)"
            >
              <v-icon v-text="'mdi-dots-vertical'"></v-icon>
            </v-btn>
          </div>
        </v-hover>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { cleanDeleted } from "@/board/api/board.api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { virtualBoardConverter } from "@/board/constant/boardType";
import i18n from "@/_locales";

export default {
  data: () => ({
    editTextLoading: false,
    prevActiveItem: {}
  }),
  mounted() {
    const { id: routeId } = this.getRouteListInfo;
    this.setActiveAndOpenedItem(routeId);
  },
  watch: {
    // route에 의해 폴더선택이 변경될때
    getRouteListInfo({ id: routeId }) {
      if (routeId == 0) return;
      this.setActiveAndOpenedItem(routeId);
    },
    // 트리 액티브 효과 제어
    getTreeActive(config) {
      if (!config) {
        this.setActiveAndOpenedItem(0);
      }
    }
  },
  computed: {
    ...mapGetters("board", [
      "getBoards",
      "getBoardById",
      "getActiveItems",
      "getOpenItems"
    ]),
    ...mapGetters("boardRoute", ["getRouteListInfo", "getTreeActive"]),
    ...mapGetters("boardConfig", ["getViewType", "getTags"]),
    draggableClass() {
      return () => {
        return "d-flex align-center justify-space-between";
      };
    },
    // 최종 트리 아이템 (기본 게시판 + ...가상 아이템)
    treeItems() {
      let result = [...this.getBoards];

      // 태그 게시판
      const tagRoot = {
        id: -100,
        userId: 1,
        groupId: -100,
        name: "태그",
        description: "태그",
        isDeleted: false,
        deletedTimeMillis: null,
        boardType: "TAG",
        userPrivilege: null,
        memberType: null,
        icon: "mdi-tag-multiple",
        isEdit: false,
        childCount: 0,
        showChildCount: 0,
        children: null
      };
      const tagItems = this.getTags.map(t => this.convertTagToBoard(t));
      tagRoot.children = tagItems;

      // 휴지통 뒤에 추가
      const index = result.findIndex(b => b.boardType == "TRASH");
      result.splice(index + 1, 0, tagRoot);

      return result;
    },
    activeItems: {
      get() {
        return this.getActiveItems;
      },
      set(items) {
        if (items.length > 0) {
          if (this.activeItems[0].id == items[0].id) return;
        }
        this.SET_ACTIVE_ITEMS(items);
      }
    },
    openItems: {
      get() {
        return this.getOpenItems;
      },
      set(items) {
        this.SET_OPEN_ITEMS(items);
      }
    }
  },
  methods: {
    ...mapActions("positioningMenu", [
      "positioningMenu",
      "closePositioningMenu"
    ]),
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("board", [
      "loadBoardList",
      "setActiveAndOpenedItem",
      "updateBoardName",
      "hideBoard",
      "deleteBoard"
    ]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapMutations("board", [
      "SET_ACTIVE_ITEMS",
      "SET_OPEN_ITEMS",
      "UPDATE_IS_EDIT"
    ]),
    ...mapMutations("tag", ["SHOW_TAG_DIALOG"]),
    onActive([item = {}]) {
      if (!this.getTreeActive) return;
      if (Object.keys(item).length == 0) {
        return this.SET_ACTIVE_ITEMS([this.prevActiveItem]);
      }
      this.prevActiveItem = item;
    },
    // 폴더 명 옆 아이콘 색상 처리 함수
    getIconColor(item) {
      if (item.color) return item.color;
      const [active = {}] = this.getActiveItems;
      const { id } = active;
      return id == item.id ? "primary" : "";
    },

    getIcon(item) {
      if (item.boardType != "BOARD") return item.icon;

      let iconName = "mdi";
      let nameEl = ["folder"];

      const [activeItem = {}] = this.getActiveItems;
      if (item.isDeleted) {
        nameEl.push("remove"); // alert, off, remove, clock, cancel
      } else if (item.memberType === "MEMBER") {
        nameEl.push("lock"); // eye, lock, table, key, account
      }
      if (activeItem.id !== item.id) nameEl.push("outline");

      // 문자열 결합
      nameEl.forEach(char => {
        iconName += `-${char}`;
      });

      return iconName;
    },

    // 트리 클릭 시 라우팅 하는 함수
    nodeClick(e, board) {
      // 해당 노드 토글 (Active 처리 X)
      if (board.boardType == "TAG") {
        e.stopPropagation();
        const { length } = this.openItems.filter(item => item.id == board.id);
        if (length == 0) {
          this.openItems = [...this.openItems, board];
        } else {
          this.openItems = this.openItems.filter(item => item.id != board.id);
        }
        return;
      }
      if (board.boardType == "TAG_ITEM") {
        e.stopPropagation();
        this.$router.push({
          name: "board_search",
          params: {
            type: "post",
            query: JSON.stringify({ searchType: "ALL", tags: [board.name] })
          }
        });
        return;
      }
      this.$router.push({
        name: "board_list",
        params: {
          boardId: virtualBoardConverter(board.id)
        }
      });
    },
    // 컨텍스트 메뉴
    openPositioningMenu(e, item) {
      e.preventDefault();
      this.closePositioningMenu();

      const isNotAdmin = item.userPrivilege != "ADMIN"; // 관리자 가 아닌경우
      const isNotParent = item.id != item.groupId; // 상위게시판이 아닌 경우
      const isPublic = item.memberType === "ANY"; // 공개 게시판인 경우

      const itemList = [
        {
          // 정보 보기
          label: "상세정보",
          func: e => {
            this.openBoardDialog(e, "boardDetail", item);
          }
        },
        {
          // 이름 변경
          label: i18n.t("board.103"),
          hideItem: isNotAdmin,
          func: e => {
            this.editBtnClick(e, item);
          }
        },
        {
          // 하위 게시판 추가
          label: i18n.t("board.104"),
          hideItem: isNotParent || isNotAdmin || !isPublic,
          func: () => {
            this.openBoardDialog(e, "addBoard", item);
          }
        },
        {
          label: i18n.t("common.수정"),
          hideItem: isNotAdmin,
          func: () => this.openBoardDialog(e, "editBoard", item)
        },
        {
          label: i18n.t("common.삭제"),
          hideItem: isNotAdmin,
          func: () => this.deleteBtnClick(item)
        },
        {
          label: i18n.t("board.숨기기"),
          func: () => this.hideBtnClick(item)
        }
      ];

      this.positioningMenu({
        x: e.target.getBoundingClientRect().left,
        y: e.target.getBoundingClientRect().top,
        itemList
      });
    },

    // 휴지통 비우기
    cleanBtnClick(e) {
      e.stopPropagation();
      this.closePositioningMenu();

      this.openConfirmDialog("", i18n.t("board.160"), async () => {
        this.disagree();
        const { status } = await cleanDeleted();

        if (status != 200) {
          this.openSnackbar({
            message: i18n.t("board.161"),
            type: "ERROR"
          });
        } else {
          const [active = null] = this.getActiveItems;
          // 휴지통 열려있을 경우
          if (active && active.boardType === "TRASH") {
            this.$router.push({
              name: "board_list",
              params: {
                boardId: active.id
              }
            });
          }
          this.openSnackbar({
            message: i18n.t("board.162"),
            type: "SUCCESS"
          });
        }
      });
    },

    // TAG 다이얼로그
    openTagDialog(e) {
      e.stopPropagation();
      this.SHOW_TAG_DIALOG({
        myTags: "boardConfig/getTags",
        addAction: "boardConfig/addTag",
        deleteAction: "boardConfig/deleteTag",
        editAction: "boardConfig/updateTag"
      });
      return;
    },

    // 게시판 hover 수정 버튼 클릭
    editBtnClick(e, { id }) {
      e.stopPropagation();
      this.closePositioningMenu();
      this.UPDATE_IS_EDIT(id);
    },

    // 게시판 이름 수정
    async editBoardName(e, { id, name }) {
      const value = e.target.value.trim();

      // 변경할 이름이 없거나, 전과 동일하면 원상태로 돌려준다.
      if (!value || name == value) {
        await this.UPDATE_IS_EDIT(id);
        this.editTextLoading = false;
      } else if (!this.editTextLoading) {
        this.editTextLoading = true;
        await this.updateBoardName({
          id: id,
          name: value
        });
        this.editTextLoading = false;
      }
    },
    // 게시판 다이얼로그
    openBoardDialog(e, type, item) {
      let title, params;
      switch (type) {
        case "addBoard":
          e.stopPropagation();
          title = i18n.t("board.105");
          params = { mode: "ADD_GROUP", parentBoard: item };
          break;
        case "editBoard":
          e.stopPropagation();
          title = i18n.t("board.106");
          params = { board: item };
          break;
        case "boardDetail":
          title = "게시판 정보";
          params = { board: item };
          this.SET_DIALOG({
            title,
            type,
            params,
            confirmBtn: { show: false },
            cancelBtn: { show: true, text: i18n.t("common.닫기") },
            leftBtn: { show: true, text: "편집" }
          });
          return;
      }

      this.SET_DIALOG({ title, type, params });
    },
    // 게시판 숨기기
    hideBtnClick(board) {
      this.confirm({
        headline: i18n.t("board.163"),
        message: i18n.t("board.164"),
        callback: () => {
          this.hideBoard(board);
        }
      });
    },
    // 게시판 삭제
    async deleteBtnClick(board) {
      this.SET_DIALOG({
        title: i18n.t("board.107"),
        type: "deleteBoard",
        confirmBtn: {
          show: true,
          text: i18n.t("common.삭제")
        },
        params: {
          board
        }
      });
    },
    // 태그를 가상 Item으로 변환
    convertTagToBoard({ tag, color }) {
      return {
        id: `t_${tag}`,
        name: tag,
        tag: tag,
        color: color,
        icon: "mdi-tag",
        boardType: "TAG_ITEM"
      };
    },
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    }
  },
  destroyed() {
    this.openItems = [];
  }
};
</script>

<style lang="scss" scoped>
.cr-tree-wrapper {
  position: absolute;
  top: 52px;
  bottom: 0px;
  left: 0px;
  right: 1px;
  overflow-y: auto;
  padding-right: 7px;

  &.mobile {
    top: 60px;
  }
  .v-treeview ::v-deep {
    /* 트리 노드 */
    .v-treeview-node--shaped {
      /* 노드 높이 */
      .v-treeview-node__root {
        min-height: 42px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 1px;
        /* 텍스트 왼쪽 여백 */
        .v-treeview-node__content {
          margin-left: 0px;
        }
      }
      /*
       * 트리 속성 중 transition, shaped 를 같이 설정할 경우
       * 자식 노드가 있는 노드를 expand 시 위치를 잘못 잡는 문제 수정
       */
      .v-treeview-node__children {
        overflow: hidden;
      }
    }
    /* 텍스트 */
    .cr-tree-label-text {
      font-size: 14px;
    }
    .v-treeview-node--active .cr-tree-label-text {
      font-weight: bold;
    }

    > .v-treeview-node {
      .v-treeview-node__root {
        .notranslate.v-treeview-node__toggle {
          z-index: 9999;
        }

        .v-treeview-node__content {
          cursor: pointer;
          .v-treeview-node__label {
            .cr-tree_node_click_area {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }

            .cr-tree-label-text {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              min-width: 0px;
            }
          }
        }
      }
      > .v-treeview-node__children {
        .v-treeview-node__level {
          width: 12px;
        }
      }
    }
  }
}
</style>
