<template>
  <div :class="tagCls" @click="tagClick">
    <div class="cr-tag" :style="`background: ${color}; color: ${textColor}`">
      <!-- 태그명 -->
      <div class="cr-tag-title">
        {{ `${tag.tag}` }}
      </div>

      <!-- 나의 태그 목록: 색상변경 버튼, 삭제 버튼 -->
      <div v-if="isMyTags" class="d-flex">
        <v-btn icon x-small class="mr-1" @click.stop="toggleColorPicker">
          <v-icon :color="iconColor">mdi-brush-variant</v-icon>
        </v-btn>
        <v-btn icon x-small @click.stop="deleteModuleTagList(tag)">
          <v-icon :color="iconColor">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <!-- 색상 선택 -->
    <v-color-picker
      v-if="showColorPicker"
      hide-canvas
      hide-mode-switch
      hide-sliders
      hide-inputs
      show-swatches
      class="cr-tag-color-picker"
      v-model="color"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-tag-wrapper {
  display: inline-flex;
  margin: 0px 8px 8px 0px;
  cursor: pointer;

  .cr-tag {
    display: flex;
    background: #e0e0e0;
    width: 100%;
    padding: 6px 12px;
    line-height: 20px;
    border-radius: 4px;
  }
  &.flex-column .cr-tag {
    border-radius: 4px 4px 0px 0px;
  }
}

.cr-tag-wrapper.cr-my-tag {
  display: flex;
  margin: 0px;
  cursor: default;
  .cr-tag-title {
    width: calc(100% - 44px);
  }
  .cr-tag-color-picker {
    max-width: 100% !important;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";

export default {
  props: {
    tag: {
      type: Object,
      default: () => ({})
    },
    isMyTags: {
      type: Boolean,
      default: false
    },
    lastChild: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showColorPicker: false
    };
  },
  computed: {
    color: {
      get() {
        return this.tag.color || "#E0E0E0";
      },
      set(color) {
        this.updateModuleTagColor({ ...this.tag, color });
      }
    },
    textColor() {
      return invertColor(this.color);
    },
    iconColor() {
      const color = invertColor(this.color);
      if (color === "#000000") return "#9b9b9b";
      return color;
    },
    tagCls() {
      let cls = "cr-tag-wrapper";
      if (this.isMyTags) cls += " cr-my-tag";
      if (!this.lastChild) cls += " mb-2";
      if (this.showColorPicker) cls += " elevation-2 flex-column pb-2";
      return cls;
    }
  },
  methods: {
    ...mapActions("tag", [
      "addModuleTagList",
      "updateModuleTagColor",
      "deleteModuleTagList"
    ]),
    tagClick() {
      if (this.isMyTags) return;
      this.addModuleTagList({ ...this.tag, color: "#E0E0E0" });
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    }
  }
};
</script>
