<template>
  <div class="pa-3 pt-0 d-flex justify-space-between">
    <v-btn large depressed width="112" color="primary" @click="addBoard(true)">
      <h4>{{ $t("todo.보드 추가") }}</h4>
    </v-btn>

    <v-btn large depressed width="112" color="#f1f2f5" @click="addBoard(false)">
      <h4>폴더 추가</h4>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("todoRoute", ["routeWorkspaceId"])
  },
  methods: {
    ...mapMutations("todoDialog", ["SET_DIALOG"]),
    ...mapActions("todoRoute", ["routeWorkspace"]),
    addBoard(isBoard) {
      // 다이얼로그 파라미터 셋팅
      const { routeWorkspaceId: workspaceId = 0 } = this;
      const type = isBoard ? "BOARD" : "FOLDER";
      const params = { type, parentId: 0, workspaceId };
      // 다이얼로그 셋팅
      const dParams = { show: true, type: "addBoard", params };
      dParams["headline"] = isBoard ? "보드 추가" : "폴더 추가";
      dParams["fullscreen"] = isBoard;
      dParams["showConfirmBtn"] = !isBoard;

      this.SET_DIALOG(dParams);
    }
  }
};
</script>
