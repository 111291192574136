import i18n from "@/_locales";
import http from "@/commons/api/http";
import { privateContactGroupType } from "@/contact/constant/contactGroupType";

const prefix = "api/contact/group";

/**
 * 기본 주소록 그룹 리스트 조회
 *
 * @returns
 */
const getDefaultContactGroupList = () => http.get(prefix);

/**
 * 자식 주소록 그룹 리스트 조회
 *
 * @param {*} parentId -- 부모 주소록 그룹 id
 * @returns
 */
const getContactGroupListByParentId = ({ parentId = "0" }) =>
  http.get(`${prefix}/${parentId}/children`);

/**
 * 개인 주소록 그룹 리스트 조회
 *
 * @returns
 */
const getPrivateContactGroupList = () => http.get(`${prefix}/private`);

/**
 * 주소록 그룹 id 기준 최상위 부모 주소록 그룹 조회
 *
 * @param {*} id -- 주소록 그룹 id
 */
const getSuperParentContactGroup = id =>
  http.get(`${prefix}/${id}/super/parent`);

/**
 * 주소록 그룹 생성
 *
 * @param {*} groupName     -- 주소록 그룹명
 * @param {*} contactGroupType -- 주소록 그룹 타입
 * @param {*} parentId      -- 주소록 그룹 부모 id
 * @returns
 */
const addContactGroup = ({
  groupName = i18n.t("contact.50"),
  contactGroupType = privateContactGroupType.PRIVATE,
  parentId = ""
}) => http.post(prefix, { groupName, contactGroupType, parentId });

/**
 * 주소록 그룹명 수정
 *
 * @param {*} groupName   -- 주소록 그룹명
 * @param {*} contactGroupId -- 주소록 그룹 id
 * @returns
 */
const updateContactGroupName = ({
  groupName = i18n.t("contact.50"),
  contactGroupId
}) => {
  const formData = new FormData();
  formData.append("id", contactGroupId);
  formData.append("groupName", groupName);
  groupName;

  return http.put(`${prefix}/${contactGroupId}/groupName`, formData);
};

/**
 * 주소록 그룹 삭제
 *
 * @param {*} contactGroupId
 * @returns
 */
const deleteContactGroup = contactGroupId =>
  http.delete(`${prefix}/${contactGroupId}`);

/**
 * 개인 주소록 그룹 트리 조회
 *
 * @returns
 */
const getPrivateContactGroupTree = () => http.get(`${prefix}/private/tree`);

/**
 * 주소록 그룹 조회
 *
 * @param {*} id 그룹 id
 */
const getGroupById = id => http.get(`${prefix}/${id}`);

export {
  getDefaultContactGroupList,
  getContactGroupListByParentId,
  getPrivateContactGroupList,
  getSuperParentContactGroup,
  addContactGroup,
  updateContactGroupName,
  deleteContactGroup,
  getPrivateContactGroupTree,
  getGroupById
};
